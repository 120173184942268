import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { RouterModule } from "@angular/router";
import { FuseHighlightModule, FuseProgressBarModule } from "@fuse/components";
import { FuseDemoModule } from "@fuse/components/demo/demo.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { LayoutModule } from "app/layout/layout.module";
import { DiscapacidadindexComponent } from "./discapacidadindex.component";
import { ModalInformacionDocumentacionBasicaComponent } from "../modal-informacion-documentacion-basica/modal-informacion-documentacion-basica.component";
import { ModalAltaContableComponent } from "../modal-alta-contable/modal-alta-contable.component";
import { ModalNotificacionRedMedifeComponent } from "../modal-notificacion-red-medife/modal-notificacion-red-medife.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

const routes = [
  {
    path: "discapacidad",
    component: DiscapacidadindexComponent,
  },
];

@NgModule({
  declarations: [
    DiscapacidadindexComponent,
    ModalInformacionDocumentacionBasicaComponent,
    ModalAltaContableComponent,
    ModalNotificacionRedMedifeComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatDialogModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    FuseSharedModule,
    FuseDemoModule,
    FuseHighlightModule,
    FuseProgressBarModule,
    MatCardModule,
    LayoutModule,
    MatProgressSpinnerModule,
  ],
})
export class DiscapacidadindexModule {}
