<div class="page-layout page-layout-water-mark blank p-24" fusePerfectScrollbar>

    <div class="breadcrumbs">
        <a href="/">Inicio</a> » <span>CONTACTO</span>
    </div>

    <h1 class="page-title">Contacto</h1>
    
    <p>Si sos beneficiario de la Obra Social, iniciá sesión para enviarnos tu consulta. De lo contrario, completá el siguiente formulario para enviarnos tu consulta o comunícate al 0810 3333 273</p>
    
    
    <form #form name="" [formGroup]="formContacto" action="" method="POST" novalidate>
       
        <div class="data">
                <div class="tw">
                    <mat-form-field  class="mat-fullwidth">
                        <mat-label>Nombre</mat-label>
                        <input matInput [formControl]="nombre" required>
                        <mat-error *ngIf="nombre.invalid">Debe ingresar su nombre.</mat-error>
                    </mat-form-field>
                </div>
                <div class="tw">
                    <mat-form-field  class="mat-fullwidth">
                        <mat-label>Apellido</mat-label>
                        <input matInput [formControl]="apellido" required>
                        <mat-error *ngIf="apellido.invalid">Debe ingresar su apellido.</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="data">  
                <div class="tw">
                    <mat-form-field  class="mat-fullwidth">
                        <mat-label>Email</mat-label>
                        <input matInput [formControl]="email" required>
                        <mat-error *ngIf="email.invalid && email.hasError('required')">Debe ingresar su email.</mat-error>
                        <mat-error *ngIf="email.invalid && email.hasError('email')">Ingrese un email válido.</mat-error>
                    </mat-form-field>
                </div>
            
                <div class="tw">
                    <mat-form-field  class="mat-fullwidth">
                        <mat-label>Asunto</mat-label>
                        <mat-select [formControl]="asunto" required (selectionChange)="changeAsunto(asunto)">
                            <mat-option value="otras">Otras consultas</mat-option>
                            <mat-option value="monotributista">Consulta Monotributista</mat-option>
                        </mat-select>
                        <mat-error *ngIf="asunto.invalid">Debe seleccionar el asunto  .</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="data">  
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Cod. Área</mat-label>
                        <input matInput [formControl]="codigoarea" maxlength="5" required>
                        <mat-error *ngIf="codigoarea.invalid">Debe ingresar el Cod. Área.</mat-error>
                    </mat-form-field>
                </div>

                <div class="tw">
                    <mat-form-field  class="mat-fullwidth">
                        <mat-label>Teléfono</mat-label>
                        <input matInput [formControl]="telefono" maxlength="20" required>
                        <mat-error *ngIf="telefono.invalid">Debe ingresar el teléfono sin guiones.</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="data" *ngIf="showCUIT">
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>CUIT</mat-label>
                        <input placeholder="Ejemplo 37508887002" matInput type="number" [formControl]="CUIT" required maxlength="11" oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)">
                        <mat-error *ngIf="CUIT.invalid ">Debe ingresar el CUIT.</mat-error>                        
                        <mat-error *ngIf="CUIT.hasError('cuit') ">{{ CUIT.errors.cuit.value }}</mat-error>
                    </mat-form-field>
                </div> 
                <div class="tw" fxHide.xs></div>               
            </div>
            <div class="data">
                
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Mensaje</mat-label>
                        <textarea matInput [formControl]="mensaje" rows="6" required></textarea>
                        <mat-error *ngIf="mensaje.invalid">Debe ingresar el mensaje.</mat-error>
                    </mat-form-field>
                </div>
        </div>

    </form>

    <div>
        <re-captcha (resolved)="captchaResolved($event)" 
                    siteKey="6Lci2cUZAAAAAKfhe3JqVTih6eDVsgbbSz_fT50X">  
        </re-captcha>
    </div>

    <div>
        <button mat-raised-button class="submit-button" aria-label="ENVIAR" name="yt0"
        (click)="sendForm()" [disabled]="!formContacto.valid || !captchares">
            Enviar
        </button>
    </div>    
    
</div>