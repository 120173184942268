<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row"> <!-- ASIDE -->
  <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
    <div fxHide.xs>
      <img src="assets/images/iconos/icono_altausuarios.svg" class="icon" />
    </div>
  </div>
  <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <span>Alta Proveedor</span>
        </div>

        <h2 class="page-subtitle">Alta Proveedor</h2>

    <p>Por favor, completá el siguiente formulario.</p>

    <form action="" [formGroup]="formAltaProveedor" method="POST" novalidate enctype="multipart/form-data">
      <div fxLayout="row">
        <mat-form-field class="mat-fullwidth" fxFlex="100" fxFlex.lt-md="100">
          <mat-label>Razón social</mat-label>
          <input matInput formControlName="razonSocial" required />
          <mat-error *ngIf="formAltaProveedor.get('razonSocial').invalid">Debe ingresar la Razón Social.</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field class="mat-fullwidth" fxFlex="100" fxFlex.lt-md="100">
          <mat-label>Domicilio Profesional</mat-label>
          <input matInput formControlName="domicilio" type="text" maxlength="30" required />
          <mat-error *ngIf="formAltaProveedor.get('domicilio').invalid">Debe ingresar su domicilio
            profesional.</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column">
        <mat-form-field class="mat-fullwidth" fxFlex="50" fxFlex.lt-md="100">
          <mat-label>Localidad</mat-label>
          <input matInput formControlName="localidad" maxlength="30" type="text" required />
          <mat-error *ngIf="formAltaProveedor.get('localidad').invalid">Debe ingresar la localidad.</mat-error>
        </mat-form-field>

        <mat-form-field class="mat-fullwidth" fxFlex="50" fxFlex.lt-md="100">
          <mat-label>Teléfono</mat-label>
          <input matInput formControlName="telefono"
            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" type="tel"
            maxlength="20" required />
          <mat-error *ngIf="formAltaProveedor.get('telefono').invalid">Debe ingresar su teléfono.</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field fxFlex="50" fxFlex.lt-md="100">
          <mat-label>Elegí tu provincia de acuerdo con tu domicilio fiscal</mat-label>
          <mat-select name="provincia" formControlName="provincia" [(ngModel)]="provinciaSeleccionada" required>
            <mat-option *ngFor="let option of provincias" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="
              formAltaProveedor.get('provincia').invalid &&
              formAltaProveedor.get('provincia').hasError('required')
            ">Selecciona provincia.</mat-error>
        </mat-form-field>
      </div>
      <br />

      <mat-accordion multi="true" id="accordion">
        <mat-expansion-panel disabled expanded>
          <mat-expansion-panel-header class="fixed-header">
            <mat-panel-title>
              <p>
                <mat-icon matSuffix>folder</mat-icon><strong>Adjuntar Documentación</strong> (formatos válidos: JPG,
                GIF, BMP, PNG, DOC, XLS y PDF, 3MB máximo)
              </p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="data">
            <div class="tw">
              <mat-form-field appearance="standard" class="mat-fullwidth">
                <mat-label>Formulario de exención de Ingresos Brutos, en caso de CABA y
                  corresponder.</mat-label>
                <ngx-mat-file-input formControlName="adjunto1" #adjunto1 [accept]="
                    '.jpeg, .gif, .bmp, .png, .xls, .xlsx, .pdf, .doc, .docx'
                  ">
                </ngx-mat-file-input>
                <mat-icon matSuffix *ngIf="adjunto1.empty">add</mat-icon>
                <button mat-icon-button matSuffix *ngIf="!adjunto1.empty" (click)="adjunto1.clear($event)">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-error *ngIf="
                    formAltaProveedor
                      .get('adjunto1')
                      .hasError('maxContentSize') ||
                    formAltaProveedor.get('adjunto1').hasError('mimeType')
                  ">Debe adjuntar un archivo (JPG, GIF, BMP, PNG, DOC, XLS ó PDF,
                  3MB máximo).
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="data">
            <div class="tw">
              <mat-form-field appearance="standard" class="mat-fullwidth">
                <mat-label>Certificado de exención Santa Fe.</mat-label>
                <ngx-mat-file-input formControlName="adjunto2" #adjunto2 [accept]="
                    '.jpeg, .gif, .bmp, .png, .xls, .xlsx, .pdf, .doc, .docx'
                  ">
                </ngx-mat-file-input>
                <mat-icon matSuffix *ngIf="adjunto2.empty">add</mat-icon>
                <button mat-icon-button matSuffix *ngIf="!adjunto2.empty" (click)="adjunto2.clear($event)">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-error *ngIf="
                    formAltaProveedor
                      .get('adjunto2')
                      .hasError('maxContentSize') ||
                    formAltaProveedor.get('adjunto2').hasError('mimeType')
                  ">Debe adjuntar un archivo (JPG, GIF, BMP, PNG, DOC, XLS ó PDF,
                  3MB máximo).
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="data">
            <div class="tw">
              <mat-form-field appearance="standard" class="mat-fullwidth">
                <mat-label>Copia DNI (Frente)</mat-label>
                <ngx-mat-file-input formControlName="adjunto3" #adjunto3 [accept]="
                    '.jpeg, .gif, .bmp, .png, .xls, .xlsx, .pdf, .doc, .docx'
                  ">
                </ngx-mat-file-input>
                <mat-icon matSuffix *ngIf="adjunto3.empty">add</mat-icon>
                <button mat-icon-button matSuffix *ngIf="!adjunto3.empty" (click)="adjunto3.clear($event)">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-error *ngIf="
                    formAltaProveedor
                      .get('adjunto3')
                      .hasError('maxContentSize') ||
                    formAltaProveedor.get('adjunto3').hasError('mimeType')
                  ">Debe adjuntar un archivo (JPG, GIF, BMP, PNG, DOC, XLS ó PDF,
                  3MB máximo).
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="data">
            <div class="tw">
              <mat-form-field appearance="standard" class="mat-fullwidth">
                <mat-label>Copia DNI (Dorso)</mat-label>
                <ngx-mat-file-input formControlName="adjunto4" #adjunto4 [accept]="
                    '.jpeg, .gif, .bmp, .png, .xls, .xlsx, .pdf, .doc, .docx'
                  ">
                </ngx-mat-file-input>
                <mat-icon matSuffix *ngIf="adjunto4.empty">add</mat-icon>
                <button mat-icon-button matSuffix *ngIf="!adjunto4.empty" (click)="adjunto4.clear($event)">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-error *ngIf="
                    formAltaProveedor
                      .get('adjunto4')
                      .hasError('maxContentSize') ||
                    formAltaProveedor.get('adjunto4').hasError('mimeType')
                  ">Debe adjuntar un archivo (JPG, GIF, BMP, PNG, DOC, XLS ó PDF,
                  3MB máximo).
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="data">
            <div class="tw">
              <mat-form-field appearance="standard" class="mat-fullwidth">
                <mat-label>Constancia AFIP</mat-label>
                <ngx-mat-file-input formControlName="adjunto5" #adjunto5 [accept]="
                    '.jpeg, .gif, .bmp, .png, .xls, .xlsx, .pdf, .doc, .docx'
                  ">
                </ngx-mat-file-input>
                <mat-icon matSuffix *ngIf="adjunto5.empty">add</mat-icon>
                <button mat-icon-button matSuffix *ngIf="!adjunto5.empty" (click)="adjunto5.clear($event)">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-error *ngIf="
                    formAltaProveedor
                      .get('adjunto5')
                      .hasError('maxContentSize') ||
                    formAltaProveedor.get('adjunto5').hasError('mimeType')
                  ">Debe adjuntar un archivo (JPG, GIF, BMP, PNG, DOC, XLS ó PDF,
                  3MB máximo).
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="data">
            <div class="tw">
              <mat-form-field appearance="standard" class="mat-fullwidth">
                <mat-label>Título ó matrícula según corresponda.</mat-label>
                <ngx-mat-file-input formControlName="adjunto6" #adjunto6 [accept]="
                    '.jpeg, .gif, .bmp, .png, .xls, .xlsx, .pdf, .doc, .docx'
                  ">
                </ngx-mat-file-input>
                <mat-icon matSuffix *ngIf="adjunto6.empty">add</mat-icon>
                <button mat-icon-button matSuffix *ngIf="!adjunto6.empty" (click)="adjunto6.clear($event)">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-error *ngIf="
                    formAltaProveedor
                      .get('adjunto6')
                      .hasError('maxContentSize') ||
                    formAltaProveedor.get('adjunto6').hasError('mimeType')
                  ">Debe adjuntar un archivo (JPG, GIF, BMP, PNG, DOC, XLS ó PDF,
                  3MB máximo).
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div>
        <button type="button" mat-raised-button id="btnEnviar" [ngClass]="
            formAltaProveedor.valid ? 'valid-button' : 'invalid-button'
          " (click)="btnEnviar()">
          Enviar
        </button>
      </div>
    </form>
  </div>
</div>