import { Component, Input, OnInit } from '@angular/core';import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CambiarEmailDialogComponent } from './cambiar-email-dialog/cambiar-email-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from 'app/services/login.service';
import { MisdatosProveedorService } from 'app/services/misdatos-proveedor.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cambiar-email-proveedor',
  templateUrl: './cambiar-email-proveedor.component.html',
  styleUrls: ['./cambiar-email-proveedor.component.scss'],
})
export class CambiarEmailProveedorComponent implements OnInit {
  SABCUIT = window.history.state.consultaCUIT;
  datosProveedor = {
    razonSocial: '',
    CUIT: '',
    email: '',
    ultimoLogin: '',
  };

  @Input() cuit: string;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private getSessionData: LoginService,
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _MisdatosProveedorService: MisdatosProveedorService
  ) {
    if (!this.SABCUIT) {
      this.activatedRoute.queryParams.subscribe((params) => {
        this.SABCUIT = params['cuit'];
        if (!this.SABCUIT) this.router.navigate(['/adm-usuarios-proveedor']);
      });
    }

    this._MisdatosProveedorService
      .getMisDatosProveedor(this.SABCUIT)
      .subscribe((resultado) => {
        this.datosProveedor.razonSocial = resultado['RazonSocial'];
        this.datosProveedor.CUIT = resultado['CUIT'];

        this._MisdatosProveedorService
          .getEmailProveedor(this.SABCUIT)
          .subscribe((result) => {
            if (resultado['Email'] != result[0].correo)
              this.datosProveedor.email = result[0].correo;
            else this.datosProveedor.email = resultado['Email'];
          });
      });
  }

  ngOnInit(): void {
    this.getSessionData.getSessionData().subscribe((result) => {});
    this._MisdatosProveedorService
      .getlastLoginProveedor(this.SABCUIT)
      .subscribe((resultado) => {
        let year = resultado[0].lastlogin.slice(0, 4);
        let month = resultado[0].lastlogin.slice(4, 6);
        let day = resultado[0].lastlogin.slice(6, 8);
        let hh = resultado[0].lastlogin.slice(8, 10);
        let mm = resultado[0].lastlogin.slice(10, 12);
        let ss = resultado[0].lastlogin.slice(12, 14);
        this.datosProveedor.ultimoLogin =
          day + '/' + month + '/' + year + ' ' + hh + ':' + mm + ':' + ss;
      });
  }

  cambiarEmail() {
    let dialogRef = this.dialog
      .open(CambiarEmailDialogComponent, {
        data: { cuit: this.SABCUIT },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.datosProveedor.email = result;
          this._snackBar.open(
            'La cuenta de correo ha sido modificada',
            'Cerrar',
            {
              duration: undefined,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            }
          );
        }
      });
  }

  linkPanel() {
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  }
  linkSeleccionDatos() {
    this.router.navigate(['seleccionar-datos'], {
      queryParams: { cuit: this.SABCUIT },
    });
  }
}
