import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CsvGenerationService } from "app/services/csv-generation.service";
import { LiquidadorService } from "app/services/liquidador.service";

@Component({
  selector: "app-modal-filtros-excel",
  templateUrl: "./modal-filtros-excel.component.html",
  styleUrls: ["./modal-filtros-excel.component.scss"],
})

export class ModalFiltrosExcelComponent implements OnInit {
    filtros: FormGroup;
    closeModal: () => void;
    showTooltip = false;

    obraSocial = new FormControl('', Validators.maxLength(100));
    solapa = new FormControl('', Validators.maxLength(100));
    CUIT = new FormControl('', [Validators.pattern(/^([0-9])*$/), Validators.maxLength(11), this.validateCuit])
    fechaTramiteDesde = new FormControl('');
    fechaTramiteHasta = new FormControl('');
    periodoDesde = new FormControl('');
    periodoHasta = new FormControl('');
    minFechaTramite = new Date();
    maxFechaLimite = new Date();

    periodos = []
    solapas = [
      {solapaRaw: 'Principal'},
      {solapaRaw: 'Modf. SGI'},
      {solapaRaw: 'Liquidados'},
      {solapaRaw: 'Pendiente'},
      {solapaRaw: 'Judicializados'},
    ];
    obrasSociales = [
      {tipo: 'ASE'},
      {tipo: 'OSEN'},
      {tipo: 'OSPM'}, 
    ]


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _liquidadorService: LiquidadorService,
    private _snackBar: MatSnackBar,
    private _csvGenerationService: CsvGenerationService,
    ) {}
    
  validateCuit(control: AbstractControl): { [key: string]: any } | null {

    let cuit = String(control.value);
    if (!control.value) {
        return null;
    }
    if (cuit.length !== 11) {
        return { cuit: { value: 'El campo CUIT debe tener 11 caracteres.' } };
    }

    let acumulado = 0;
    let digitos = cuit.split('');
    let digito = digitos.pop();

    for (let i = 0; i < digitos.length; i++) {
        acumulado += Number(digitos[9 - i]) * (2 + (i % 6));
    }

    let verif = 11 - (acumulado % 11);
    if (verif === 11) {
        verif = 0;
    } else if (verif === 10) {
        verif = 9;
    }

    return (Number(digito) == Number(verif)) ? null : { cuit: { value: 'El CUIT ingresado es inválido. ' } };
}


  validarPeriodo(control: AbstractControl): ValidationErrors | null {
    const periodoDesdeControl = control.get("periodoDesde");
    const periodoHastaControl = control.get("periodoHasta");

    const periodoDesde = periodoDesdeControl.value;
    const periodoHasta = periodoHastaControl.value;

    if (!periodoDesde || !periodoHasta) {
        return null;
    }

    const [anioDesde, mesDesde] = [periodoDesde.substring(0, 4), periodoDesde.substring(4, 6)];
    const [anioHasta, mesHasta] = [periodoHasta.substring(0, 4), periodoHasta.substring(4, 6)];

    const desde = new Date(anioDesde, mesDesde);
    const hasta = new Date(anioHasta, mesHasta);

    return desde.getTime() > hasta.getTime()  ? { periodo: true } : null;
  }

  validarFechaTramite(control: AbstractControl): ValidationErrors | null {
    const desde = control.get('fechaTramiteDesde').value;
    const hasta = control.get('fechaTramiteHasta').value;

    if (desde && hasta && desde > hasta) {
        return { fechaTramite: true };
    }

    return null;
  }

  onAccept() {
    this.filtros.markAllAsTouched();
    const validation = this.atLeastOneFilterCompleted(this.filtros);
    if (!validation.valid) {
      return this._snackBar.open(validation.errorType, 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
    const dataToSend = {
      cuit: this.CUIT.value,
      obra_social: this.obraSocial.value,
      solapa: this.solapa.value,
      periodo_desde: this.periodoDesde.value,
      periodo_hasta: this.periodoHasta.value,
      fecha_tramite_desde: this.fechaTramiteDesde.value,
      fecha_tramite_hasta: this.fechaTramiteHasta.value,
    };

    let hasError = false

    if (dataToSend.periodo_desde !== "" && dataToSend.periodo_hasta === "") {
      this.filtros.get('periodoHasta').setErrors({ 'required': true });
      hasError = true;
    } else if (dataToSend.periodo_desde === "" && dataToSend.periodo_hasta !== "") {
      this.filtros.get('periodoDesde').setErrors({ 'required': true });
      hasError = true;
    }
    
    if (dataToSend.fecha_tramite_desde !== "" && dataToSend.fecha_tramite_hasta === "") {
      this.filtros.get('fechaTramiteHasta').setErrors({ 'required': true });
      hasError = true; 
    } else if (dataToSend.fecha_tramite_desde === "" && dataToSend.fecha_tramite_hasta !== "") {
      this.filtros.get('fechaTramiteDesde').setErrors({ 'required': true });
      hasError = true; 
    }

    if (hasError) return;

    this._liquidadorService.getReporte(dataToSend).subscribe(result => {
      const formattedDate = this.getCurrentDateInFormat();

      if (result.data.length === 0) {
        return this._snackBar.open('No hay registros disponibles con la combinación de filtros', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }

      this._csvGenerationService.generateCSV(result.data, `rl_general_${formattedDate}.xlsx`);
    });
  
    this.clear();
  }

  clear() {
    this.filtros.setValue({
      CUIT: '',
      obra_social: '',
      solapa: '',
      periodoDesde: '',
      periodoHasta: '',
      fechaTramiteDesde: '',
      fechaTramiteHasta: '',
    });
  }

  atLeastOneFilterCompleted(form: FormGroup): { valid: boolean; errorType?: string } {
    const cuilFilled = form.get('CUIT').value !== '' && form.get('CUIT').valid && form.get('CUIT').value !== null;
    const solapaFilled = form.get('solapa').value !== '' && form.get('solapa').value !== null;
    const obraSocialFilled = form.get('obra_social').value !== '' && form.get('obra_social').value !== null;
    const fechaTramiteFilled = (form.get('fechaTramiteDesde').value !== '' && form.get('fechaTramiteDesde').value !== null) && (form.get('fechaTramiteHasta').value !== '' && form.get('fechaTramiteHasta').value !== null);
    const periodoFilled = (form.get('periodoDesde').value !== '' && form.get('periodoDesde').value !== null) && (form.get('periodoHasta').value !== '' && form.get('periodoHasta').value !== null);

    return (
      cuilFilled ||
      (obraSocialFilled && solapaFilled) ||
      (obraSocialFilled && fechaTramiteFilled) ||
      (obraSocialFilled && periodoFilled) ||
      (fechaTramiteFilled && solapaFilled) ||
      (fechaTramiteFilled && cuilFilled) ||
      (periodoFilled && solapaFilled) ||
      (periodoFilled && cuilFilled)
    ) ? { valid: true } : { valid: false, errorType: 'Debe completar al menos un filtro' };
  }

  isEnabled(form: FormGroup): boolean {
    const cuilFilled = form.get('CUIT').value !== '' && form.get('CUIT').valid && form.get('CUIT').value !== null;
    const solapaFilled = form.get('solapa').value !== '' && form.get('solapa').value !== null;
    const obraSocialFilled = form.get('obra_social').value !== '' && form.get('obra_social').value !== null;
    const fechaTramiteFilled = (form.get('fechaTramiteDesde').value !== '' && form.get('fechaTramiteDesde').value !== null) && (form.get('fechaTramiteHasta').value !== '' && form.get('fechaTramiteHasta').value !== null);
    const periodoFilled = (form.get('periodoDesde').value !== '' && form.get('periodoDesde').value !== null) && (form.get('periodoHasta').value !== '' && form.get('periodoHasta').value !== null);
  
    return (
      cuilFilled ||
      (obraSocialFilled && solapaFilled) ||
      (obraSocialFilled && fechaTramiteFilled) ||
      (obraSocialFilled && periodoFilled) ||
      (fechaTramiteFilled && solapaFilled) ||
      (fechaTramiteFilled && cuilFilled) ||
      (periodoFilled && solapaFilled) ||
      (periodoFilled && cuilFilled)
    );
  }

  isDisabled() {
    return !this.isEnabled(this.filtros);
  }

  getCurrentDateInFormat() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;
    return formattedDate;
  }
  
  ngOnInit() {
    this.filtros = new FormGroup({
      CUIT: this.CUIT,
      obra_social: this.obraSocial,
      solapa: this.solapa,
      periodoDesde: this.periodoDesde,
      periodoHasta: this.periodoHasta,
      fechaTramiteDesde: this.fechaTramiteDesde,
      fechaTramiteHasta: this.fechaTramiteHasta,
    }, { validators: [this.validarPeriodo, this.validarFechaTramite] });

    this.periodos = this.data.periodos;
  }
}