<div class="page-layout page-layout-water-mark blank p-24" fusePerfectScrollbar>

    <div class="breadcrumbs">
        <a [routerLink]="['/home']">Inicio</a> » <span>Turismo</span>
    </div>

    <h1 class="page-title">Turismo</h1>
    <h4>Te acercamos ofertas exclusivas y paquetes turísticos pensados especialmente para vos.</h4>

    <p><strong>A través de Geisa Travel te ofrecemos:</strong></p>

    <ul class="listado">
        <li>Reservas y venta de hoteles en el país y en el exterior</li>
        <li>Venta de pasajes aéreos</li>
        <li>Venta de paquetes nacionales e internacionales</li>
        <li>Alquiler de autos</li>
        <li>Venta de estadías en cruceros, spa, estancias y pasajes de tren</li>
        <li>Venta de seguros de asistencia en el exterior</li>
    </ul>

    <h4>GEISA TRAVEL</h4>

    <div class="data">
        <div class="tw"><span class="span-title">Ejecutivo:</span> Pablo Sanchez</div>
        <div class="tw"><span class="span-title">Mail: </span> <a href="mailto:info@geisatravel.com.ar" class="linknaranja" >info@geisatravel.com.ar</a></div>
    </div>
    <div class="data">
        <div class="tw"><span class="span-title">Dirección:</span> Pedro Calderón de la Barca 2776  7A - CP 1417 - C.A.B.A.</div>
        <div class="tw"><span class="span-title">Teléfono:</span> +54 911 4935-5438 / 11-6165-9078</div>
    </div>
    <div class="data">
        <div class="tw"><span class="span-title">Web: </span> <a target="_blank" class="linknaranja" href="http://www.geisatravel.com.ar">www.geisatravel.com.ar</a></div>
    </div>
</div>