<div class="page-layout blank p-24" fusePerfectScrollbar>

    <div id="novedad" *ngFor="let nov of novedad">
        
        <p class="breadcrumbs"><a [routerLink]="['/novedades']">NOVEDADES</a> » {{nov.titulo |tildes| uppercase }}</p>
        
        <h1>{{ nov.titulo | tildes }}</h1>  

        <img *ngIf="nov.detalle===''" [src]="env.externalImagesNovedadesUrl + nov.imagen_destacada" class="imagen_destacada" alt="{{ nov.titulo | tildes }}">
        
        <div class="detalle" [innerHTML]="nov.detalle"></div>

    </div>

</div>
