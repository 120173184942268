<div class="page-layout blank p-24" fusePerfectScrollbar>

    <div class="breadcrumbs">
        <a href="/">Inicio</a> » <a [routerLink]="['/planes']">PLANES</a> » <a [routerLink]="['/planes/eco-plus']">PLAN ECO PLUS</a> » <span>CONOCÉ MÁS</span>
    </div>

    <h1 class="page-title">BENEFICIOS PLANES PLUS</h1>

    <p>A través de nuestros <b>Planes Plus</b> te ofrecemos coberturas suplementarias e
        indemnizatorias que tienen como objetivo brindarte contención económica 
        y logística ante casos críticos.<br>
    </p>
    <p>Todos los que trabajamos en <b>ASE</b> conocemos la importancia de tu elección,
        por eso queremos recompensarte renovando nuestro compromiso de ofrecerte
        siempre la mejor atención.
    </p>

        <p><b>Conocé nuestros beneficios:</b></p>

        <p>EDUCACIÓN</p>
        <ul>
            <li>Universidad Siglo XXI 20% de descuento para nuevos ingresantes.</li>
            <li>ESEADE hasta 35% de descuento en Maestrías, Carreras de grado, Posgrado y Programas Ejecutivos.</li>
            <li>CUI 25% de descuento en inscripción.</li>
        </ul>
        
        <p>BENEFICIOS PARA TU BEBÉ</p>
        <ul>
            <li>Aero Cámara.</li>
            <li>Leches maternizadas.</li>
        </ul>
        
        <p>CHEQUEOS PREVENTIVOS DE SALUD</p>
        <ul>
            <li>20% de descuento en la realización de chequeos preventivos de salud en el Sanatorio Finochietto.</li>
        </ul>
        
        <p>PRESTACIONES PARA VIAJES</p>
        <ul>
            <li>Asistencia al viajero en países limítrofes.</li>
            <li>Consulta médica pre-viaje al exterior. Vacunas, medidas de prevención, medicamentos, etc.</li>
        </ul>
        
        <p>PRESTACIONES DE SALUD</p>
        <ul>
            <li>Reintegros en gastos ortopédicos (Diferencia de prótesis, plantillas, bota Walker, muletas, corset, silla de ruedas, alquier de CPAP).</li>
            <li>Reintegro en gastos de enfermería ante enfermedades graves.</li>
            <li>Reforma y adaptación de viviendas ante enfermedades graves.</li>
            <li>Indemnización por trasplantes.</li>
            <li>Indemnización por cirugías.</li>
            <li>Reintegro en gastos de ortodoncia o implantes.</li>
            <li>Reintegros en sesiones de kinesiología a domicilio.</li>
            <li>Óptica: cobertura por pérdida o rotura, de un par de cristales o lentes de contacto recetados.</li>
        </ul>
        
        <p>ASISTENCIAS ANTE ROBO, PÉRDIDA O HURTO </p>
        <ul>
            <li>Pasaje de regreso a domicilio ante robo.</li>
            <li>Costo de Taxi o Remis ante robo.</li>
            <li>Indemnización del costo de reposición de DNI, tarjeta de crédito y/o débito.</li>
        </ul>
        
        <p>ASISTENCIA DE PERSONAL ESPECIALIZADO</p>
        <ul>
            <li>Profesor particular para beneficiarios en edad escolar y ante tratamientos prolongados.</li>
            <li>Babysitters en caso de tratamiento médico.</li>
            <li>Asistencia a mascotas en caso de tratamiento médico.</li>
            <li>Asesoramiento legal.</li>
        </ul>
        
        <p>ASISTENCIAS COMPLEMENTARIAS</p>
        <ul>
            <li>Delivery de Farmacia: ASE pondrá a su disposición el listado de farmacias con servicio de delivery. 
            El costo del delivery estará a cargo del beneficiario.</li>
        </ul>
        
        <p>Además, accedé a <a href='http://somosase.com.ar' target='_blank'>Somos ASE</a> y disfrutá beneficios en
            gastronomía, entretenimiento, turismo y mucho más.</p>
        
        <p>CONOCÉ</p>
        <ul>
            <li><a href='https://storageasemarketing01.blob.core.windows.net/asenacional/Marketing/Plus/reintegro-planes-plus.pdf' 
                target='_blank'>Topes de reintegro e indemnizaciones</a></li>
            <li><a href='https://storageasemarketing01.blob.core.windows.net/asenacional/Marketing/Plus/Condiciones-de-Acceso-Planes-Plus.pdf' 
                target='_blank'>Detalles y condiciones de acceso a los beneficios</a></li>
            <li><a href='https://storageasemarketing01.blob.core.windows.net/asenacional/Marketing/Plus/ase-plus-nomenclador-cirugia.pdf' 
                target='_blank'>Nomenclador de cirugías y trasplantes</a></li>
            <li><a href='https://storageasemarketing01.blob.core.windows.net/asenacional/Marketing/Plus/Asistencia-al-viajero.pdf' 
                target='_blank'>Detalle de cobertura en Asistencia al viajero</a></li>
        </ul>
        
        <p>Brindamos ayuda y contención en los momentos que más lo necesitas.</p>

</div>    
