<div class="page-layout page-layout-water-mark blank p-24" fusePerfectScrollbar>

    <div class="breadcrumbs">
        <a [routerLink]="['/home']">Inicio</a> » <span>Club ASE</span>
    </div>

    <h1 class="page-title">Club ASE</h1>
    <h2>¿Qué es Club ASE?</h2>
    <h4>Es un programa de beneficios a nivel nacional pensado exclusivamente para nuestros beneficiarios.</h4>

    

    <div class="data">
        <div class="tw image-logo"><a href="https://beneficios-ase.gointegro.com/" target="blank"><img class=" borde-logo" src="assets/images/logos/clubase_logo.jpg"></a></div>
        <div class="tw">Porque queremos que disfrute de su tiempo libre como más desee, le ofrecemos múltiples propuestas a las que pueda acceder con solo ingresar su número de documento. Seleccione el beneficio que prefiera y presente el voucher en los comercios adheridos.</div>
    </div>
    
    
    <div class="banners">
        <div><a [routerLink]="[ '/ejecutivocuenta' ]"><img src="assets/images/ejecutivo_cuenta.png"></a></div>
        <div><a href="{{ env.urlWebSitePhp }}site/beneficios"><img src="assets/images/beneficios_thumb.png"></a></div>
        <div><a [routerLink]="[ '/turismo' ]"><img src="assets/images/turismo_thumb.png"></a></div>
    </div>
</div>