<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxHide.xs class="aside" fxHide.lt-md fxShow.gt-sm>
               
        <div>
            <img src="assets/images/iconos/icono_altausuarios.svg" class="icon">
        </div>         

    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a routerLink="/misdatos">Mis datos</a> » <a routerLink="/misdatos/solicitar-rol">Solicitar Rol</a> » <span>Seleccionar Red</span>
        </div>

        <h2 class="page-subtitle">Seleccione la Red</h2>
       
        
        <form #form name="" action="" method="POST" novalidate enctype="multipart/form-data">
            <div fxLayout="row">
                <mat-form-field fxFlex="37" fxFlex.xs="100">
                    <mat-label>Seleccione Red</mat-label>
                    <mat-select name="red" [formControl]="red" disableOptionCentering>
                        <mat-option *ngFor="let option of redes" [value]="option.vnume">
                            {{option.ley}}
                        </mat-option>                    
                        
                    </mat-select>
                    <mat-error *ngIf="red.invalid ">Debe seleccionar una red.</mat-error>  
                  </mat-form-field>
            </div>
            <div fxLayout="row" >
                <button mat-raised-button id="btnEnviarRed" (click)="btnEnviarRed()" [disabled]="botonDeshabilitado">Enviar</button>
                <button mat-raised-button id="btnCancelar" routerLink="/misdatos/solicitar-rol">Cancelar</button>
            </div>
        </form>
        
         
        
    </div>


</div>



