import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AsebienestarService {

  constructor(private http: HttpClient) { }

  hasAsebienestar(cuil): Observable<any[]> {
    return this.http.get<any[]>(environment.urlWebSitePhp + 'api/hasAseBienestar');
  }

  hasNotification(cuil): Observable<any[]> {
    return this.http.get<any[]>(environment.urlAseBienestar +'user/auth/notifications?cuil=' + cuil);
  }
}