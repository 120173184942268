import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-eco-plus-mas',
  templateUrl: './eco-plus-mas.component.html',
  styleUrls: ['./eco-plus-mas.component.scss']
})
export class EcoPlusMasComponent implements OnInit {

  constructor(private meta: Meta) { 
    const description = this.meta.getTag('name=description');
    this.meta.removeTagElement(description);
    const keywords = this.meta.getTag('name=keywords');
    this.meta.removeTagElement(keywords);
   }

  ngOnInit(): void {
  }

}
