import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MisDatosComponent } from './mis-datos.component';
import { RouterModule } from '@angular/router';

//modulos angular material
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';

// modulos del template
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseHighlightModule } from '@fuse/components';


const routes = [
  {
      path     : 'proveedores/mis-datos',
      component: MisDatosComponent
  }
];

@NgModule({
  declarations: [
    MisDatosComponent    
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    FuseHighlightModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatMenuModule
  ]
})
export class MisDatosModule { }
