<div class="footer">    
    <div class="page-layout container-footer" fxLayout="column" [ngClass.lt-lg]="'p-24'">
        <div class="footer-top" fxLayout="row" fxLayout.lt-lg="column" fxFlex="100" fxLayoutAlign="space-between">
            <div class="box-logo-redes" fxLayoutAlign.sm="space-between" fxLayoutAlign.md="space-between" fxLayout.xs="row">
                <div fxFlex.xs="50" class="logo-ase-gris">             
                    <img src="assets/images/logos/logo-ase.webp" alt="ASE">                            
                </div>
                <div class="linea-vertical" fxShow.lt-lg fxHide.gt-md></div>
                <div class="telefono-tablet" fxFlex.sm="30" fxLayoutGap="10px" fxLayout.sm="row" fxLayout.md="row" fxLayoutAlign="center" fxHide.xs fxShow.sm fxShow.md fxHide.gt-md>           
                    <div class="icon-telefono">          
                        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="21" cy="21" r="21" fill="#E65300"/>
                            <g clip-path="url(#clip0_945_15457)">
                            <path d="M15.54 14C15.6 14.89 15.75 15.76 15.99 16.59L14.79 17.79C14.38 16.59 14.12 15.32 14.03 14H15.54ZM25.4 26.02C26.25 26.26 27.12 26.41 28 26.47V27.96C26.68 27.87 25.41 27.61 24.2 27.21L25.4 26.02ZM16.5 12H13C12.45 12 12 12.45 12 13C12 22.39 19.61 30 29 30C29.55 30 30 29.55 30 29V25.51C30 24.96 29.55 24.51 29 24.51C27.76 24.51 26.55 24.31 25.43 23.94C25.33 23.9 25.22 23.89 25.12 23.89C24.86 23.89 24.61 23.99 24.41 24.18L22.21 26.38C19.38 24.93 17.06 22.62 15.62 19.79L17.82 17.59C18.1 17.31 18.18 16.92 18.07 16.57C17.7 15.45 17.5 14.25 17.5 13C17.5 12.45 17.05 12 16.5 12Z" fill="white"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_945_15457">
                            <rect width="24" height="24" fill="white" transform="translate(9 9)"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </div>  
                    <div class="tenes-consultas">
                        <p class="frase">¿ Tenés consultas ?</p>
                        <p><a class="link-tel" href="tel:0810333273">0810 3333 273</a></p>
                        <p class="horario">Lunes a viernes de 9 a 17hs.</p>
                    </div>				
                </div>

                <div class="redes-sociales" fxShow.xs fxShow.sm fxShow.md fxHide.lg fxHide.xl fxFlex.xs="50" fxLayoutGap="16px" fxLayoutAlign="end">
                    <a target="_blank" aria-label="Linkedin" href="https://www.linkedin.com/company/ase-obra-social">					
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM6.15 9.975V18H8.7V9.975H6.15ZM6 7.425C6 8.25 6.6 8.85 7.425 8.85C8.25 8.85 8.85 8.25 8.85 7.425C8.85 6.6 8.25 6 7.425 6C6.675 6 6 6.6 6 7.425ZM15.45 18H17.85V13.05C17.85 10.575 16.35 9.75 14.925 9.75C13.65 9.75 12.75 10.575 12.525 11.1V9.975H10.125V18H12.675V13.725C12.675 12.6 13.425 12 14.175 12C14.925 12 15.45 12.375 15.45 13.65V18Z" fill="#583071"/>
                        </svg>
                    </a>
                    <a target="_blank" aria-label="Instagram" href="https://www.instagram.com/asenacional/">					
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 14.1C10.875 14.1 9.9 13.2 9.9 12C9.9 10.875 10.8 9.9 12 9.9C13.125 9.9 14.1 10.8 14.1 12C14.1 13.125 13.125 14.1 12 14.1Z" fill="#583071"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.55 6.9H9.45C8.85 6.975 8.55 7.05 8.325 7.125C8.025 7.2 7.8 7.35 7.575 7.575C7.39696 7.75304 7.31284 7.93108 7.21116 8.14628C7.18437 8.20299 7.15628 8.26244 7.125 8.325C7.1134 8.35981 7.1 8.39641 7.08564 8.43564C7.00718 8.65 6.9 8.94282 6.9 9.45V14.55C6.975 15.15 7.05 15.45 7.125 15.675C7.2 15.975 7.35 16.2 7.575 16.425C7.75304 16.603 7.93108 16.6872 8.14628 16.7888C8.20304 16.8157 8.26239 16.8437 8.325 16.875C8.35981 16.8866 8.39641 16.9 8.43564 16.9144C8.65 16.9928 8.94282 17.1 9.45 17.1H14.55C15.15 17.025 15.45 16.95 15.675 16.875C15.975 16.8 16.2 16.65 16.425 16.425C16.603 16.247 16.6872 16.0689 16.7888 15.8537C16.8156 15.797 16.8437 15.7376 16.875 15.675C16.8866 15.6402 16.9 15.6036 16.9144 15.5644C16.9928 15.35 17.1 15.0572 17.1 14.55V9.45C17.025 8.85 16.95 8.55 16.875 8.325C16.8 8.025 16.65 7.8 16.425 7.575C16.247 7.39696 16.0689 7.31284 15.8537 7.21116C15.797 7.18439 15.7375 7.15625 15.675 7.125C15.6402 7.1134 15.6036 7.1 15.5644 7.08564C15.35 7.00718 15.0572 6.9 14.55 6.9ZM12 8.775C10.2 8.775 8.775 10.2 8.775 12C8.775 13.8 10.2 15.225 12 15.225C13.8 15.225 15.225 13.8 15.225 12C15.225 10.2 13.8 8.775 12 8.775ZM16.05 8.7C16.05 9.11421 15.7142 9.45 15.3 9.45C14.8858 9.45 14.55 9.11421 14.55 8.7C14.55 8.28578 14.8858 7.95 15.3 7.95C15.7142 7.95 16.05 8.28578 16.05 8.7Z" fill="#583071"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM9.45 5.775H14.55C15.225 5.85 15.675 5.925 16.05 6.075C16.5 6.3 16.8 6.45 17.175 6.825C17.55 7.2 17.775 7.575 17.925 7.95C18.075 8.325 18.225 8.775 18.225 9.45V14.55C18.15 15.225 18.075 15.675 17.925 16.05C17.7 16.5 17.55 16.8 17.175 17.175C16.8 17.55 16.425 17.775 16.05 17.925C15.675 18.075 15.225 18.225 14.55 18.225H9.45C8.775 18.15 8.325 18.075 7.95 17.925C7.5 17.7 7.2 17.55 6.825 17.175C6.45 16.8 6.225 16.425 6.075 16.05C5.925 15.675 5.775 15.225 5.775 14.55V9.45C5.85 8.775 5.925 8.325 6.075 7.95C6.3 7.5 6.45 7.2 6.825 6.825C7.2 6.45 7.575 6.225 7.95 6.075C8.325 5.925 8.775 5.775 9.45 5.775Z" fill="#583071"/>
                        </svg>
                    </a>
                    <a target="_blank" aria-label="Facebook" href="https://www.facebook.com/ASENacional/">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12 6C15.3 6 18 8.7 18 12C18 15 15.825 17.55 12.825 18V13.725H14.25L14.55 12H12.9V10.875C12.9 10.425 13.125 9.975 13.875 9.975H14.625V8.475C14.625 8.475 13.95 8.325 13.275 8.325C11.925 8.325 11.025 9.15 11.025 10.65V12H9.525V13.725H11.025V17.925C8.175 17.475 6 15 6 12C6 8.7 8.7 6 12 6Z" fill="#583071"/>
                        </svg>
                    </a>
                    <a target="_blank" aria-label="Youtube" href="https://www.youtube.com/channel/UCtY2zjVpxtIFAac6bNstROg">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.95 12L10.8 10.2V13.8L13.95 12Z" fill="#583071"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM16.65 8.025C17.175 8.175 17.55 8.55 17.7 9.075C18 10.05 18 12 18 12C18 12 18 13.95 17.775 14.925C17.625 15.45 17.25 15.825 16.725 15.975C15.75 16.2 12 16.2 12 16.2C12 16.2 8.175 16.2 7.275 15.975C6.75 15.825 6.375 15.45 6.225 14.925C6 13.95 6 12 6 12C6 12 6 10.05 6.15 9.075C6.3 8.55 6.675 8.175 7.2 8.025C8.175 7.8 11.925 7.8 11.925 7.8C11.925 7.8 15.75 7.8 16.65 8.025Z" fill="#583071"/>
                        </svg>	
                    </a>					
                </div>
            </div>	

            <div class="linea-vertical" fxHide.lt-lg fxShow.gt-md></div>
            <div class="linea" fxShow.xs fxHide.sm fxHide.md fxHide.gt-md fxFlexOrder.lt-lg="4"></div>
            <div class="telefono" fxFlex="22" fxFlex.xs="100" fxLayout="row" fxHide.sm fxHide.md fxFlexOrder.lt-lg="5" fxLayoutGap="15px">           
                <div class="icon-telefono">          
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="21" cy="21" r="21" fill="#E65300"/>
                        <g clip-path="url(#clip0_945_15457)">
                        <path d="M15.54 14C15.6 14.89 15.75 15.76 15.99 16.59L14.79 17.79C14.38 16.59 14.12 15.32 14.03 14H15.54ZM25.4 26.02C26.25 26.26 27.12 26.41 28 26.47V27.96C26.68 27.87 25.41 27.61 24.2 27.21L25.4 26.02ZM16.5 12H13C12.45 12 12 12.45 12 13C12 22.39 19.61 30 29 30C29.55 30 30 29.55 30 29V25.51C30 24.96 29.55 24.51 29 24.51C27.76 24.51 26.55 24.31 25.43 23.94C25.33 23.9 25.22 23.89 25.12 23.89C24.86 23.89 24.61 23.99 24.41 24.18L22.21 26.38C19.38 24.93 17.06 22.62 15.62 19.79L17.82 17.59C18.1 17.31 18.18 16.92 18.07 16.57C17.7 15.45 17.5 14.25 17.5 13C17.5 12.45 17.05 12 16.5 12Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_945_15457">
                        <rect width="24" height="24" fill="white" transform="translate(9 9)"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>  
                <div class="tenes-consultas">
                    <p class="frase" fxHide.xs fxShow.gt.xs>¿ Tenés consultas ?</p>
                    <p class="frase" fxShow.xs fxHide.gt-xs>Teléfono de consultas</p>                    
                    <p><a class="link-tel" href="tel:0810333273">0810 3333 273</a></p>
                    <p class="horario">Lunes a viernes de 9 a 17hs.</p>
                </div>
            </div>
            <div class="linea" fxShow.xs fxHide.sm fxHide.md fxHide.gt-md fxFlexOrder.lt-lg="6"></div> 
            <div class="linea-vertical" fxHide.lt-lg></div>
            <div class="sitemap-secciones" fxFlex="20" fxFlex.xs="100" fxFlexOrder.xs="2" fxShow.xs fxHide.sm fxHide.md fxShow.gt-md>
                <ul>
                    <li><a href="/">Inicio</a></li>
                    <li><a href="/informacion-institucional">Información Institucional</a></li>
                    <li><a href="/filiales">Filiales</a></li>
                    <li><a href="/contacto">Contacto</a></li>
                </ul>            
            </div>

            <div class="sitemap-portales" fxFlex="25" fxFlex.xs="100" fxFlexOrder.xs="3" fxShow.xs fxHide.sm fxHide.md fxShow.gt-md>
                <ul class="sitemap">                
                    <li><a target="_blank" href="https://portalproveedores.ase.com.ar/">Portal de Pagos Proveedores</a></li>
                    <li><a target="_blank" href="https://egtq.login.us2.oraclecloud.com/">Portal de Compras Proveedores</a></li>
                    <li><a target="_blank" href="https://egtq.fa.us2.oraclecloud.com/fscmUI/faces/PrcPosRegisterSupplier?prcBuId=300000002814052&lang=es">Registro Proveedores</a></li>
                    <li><a target="_blank" href="https://medife.hiringroom.com/jobs/microsite/ase">Sumate a Nuestro Equipo (RRHH)</a></li>
                    <li><a [routerLink]="[ '/monotributistas' ]">Portal del Monotributista</a></li>
                </ul>  
            </div>       
        </div>  

        <div class="menu-tablet" fxShow.sm fxShow.md fxHide.xs fxHide.gt-md fxLayout.sm="row" fxLayout.md="row" fxLayoutAlign-sm="end start" fxLayoutAlign="end start">						
			<div class="sitemap-secciones" fxFlex.sm="35" fxFlex.md="30">
				<ul>
					<li><a href="/">Inicio</a></li>
					<li><a href="/informacion-institucional">Información Institucional</a></li>
					<li><a href="/filiales">Filiales</a></li>
					<li><a href="/contacto">Contacto</a></li>
				</ul>            
			</div>
			<div class="sitemap-portales">
				<ul class="ul-sitemap">                
					<li><a target="_blank" href="https://portalproveedores.ase.com.ar/">Portal de Pagos Proveedores</a></li>
					<li><a target="_blank" href="https://egtq.login.us2.oraclecloud.com/">Portal de Compras Proveedores</a></li>
					<li><a target="_blank" href="https://egtq.fa.us2.oraclecloud.com/fscmUI/faces/PrcPosRegisterSupplier?prcBuId=300000002814052&lang=es">Registro Proveedores</a></li>
					<li><a target="_blank" href="https://medife.hiringroom.com/jobs/microsite/ase">Sumate a Nuestro Equipo (RRHH)</a></li>
					<li><a [routerLink]="[ '/monotributistas' ]">Portal del Monotributista</a></li>
				</ul>  
			</div> 		
		</div>

        <div class="linea" fxHide.xs fxShow.gt-sm></div>
        <div class="footer-bottom" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxFlex="100" fxLayoutGap="20px">

            <div class="ssl" fxFlex="80" fxFlex.sm="80" fxFlex.md="80">
                <p>Superintendencia de Servicios de Salud - Órgano de Control de Obras Sociales y Entidades de Medicinas Prepagas 0800 222 SALUD (72583)</p>
                <p><a href="http://www.sssalud.gob.ar" target="blank">www.sssalud.gob.ar</a> - R.N.O.S. 400909</p>
                <p><a>Política de Privacidad</a></p>
                <p><a (click)="openPopup()">Resolución 274/2021 – Ventanilla Única Federal</a></p>
            </div>

            <div class="box-logos-bd-df" fxFlex="20" fxFlex.sm="20" fxFlex.md="20" fxLayout="row" fxLayoutAlign="end" fxLayoutAlign.xs="start" fxLayoutGap="10px" fxLayoutGap.lt-lg="20px">
                <div class="basedatos">
                    <a href="https://www.argentina.gob.ar/aaip/datospersonales/reclama/30578079994--RL-2021-99091013-APN-DNPDP#AAIP" target="_blank"> 
                        <img src="assets/images/logos/logo_aiap.jpg" alt="AIAP">
                    </a>                
                </div>
                <div class="datafiscal">
                        <a href="https://servicios1.afip.gov.ar/clavefiscal/qr/response.aspx?qr=McKo11WX0LkYR5FveuznJg,," target="_F960AFIPInfo">                            
                            <img src="assets/images/logos/logo_datafiscal.jpg" alt="Data Fiscal">
                        </a>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="footer-copyright">
    <div class="copyright" fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
        <p>© {{ year }} ASE Nacional. Todos los derechos reservados.</p>
    </div>   
</div>
<!-- 
<mat-toolbar fxHide fxShow.gt-md>
</mat-toolbar> -->
