<mat-accordion>
    <mat-expansion-panel  class="form-prestacion-box" #viewport (opened)="onOpened()" [expanded]="(isNewPrestacion && openModalNewPrestacion)">

        <mat-expansion-panel-header class="form-prestacion-header">
            <mat-panel-title>
                <div class="card-tittle-message-container">
                    <h2 class="nueva-prestacion">{{ cardTitle }}</h2>
                    <h3 *ngIf="cardAdvice" title="{{ cardAdvice.mensaje }}"
                        [ngClass]="{'aviso-prestacion-error': cardAdvice.codigo == 2, 'aviso-prestacion-completada': cardAdvice.codigo == 1}">
                        {{ cardAdvice.mensaje }}</h3>
                </div>
                <h3 class="tipo-prestacion" title="{{ prestacionType }}">{{ prestacionType }}</h3>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="!dataPrestacionRequiereAtencion">
            <div class="contenedor-tipo-numero-prestacion">
                <span class="texto-tipo-numero-prestacion">{{textoTipoNumeroPrestacion}}
                    {{numeroPrestacion + 1}}</span>
            </div>
        </ng-container>
        <!-- <h2 class="nueva-prestacion"><span><img class="flecha-nueva-prestacion" src="./assets/icons/DA-icons/down-arrow-linea.svg"></span>Nueva Prestación</h2> -->

        <div *ngIf="cuitNoActivo === true">
            <div class="alerta-prestador-inactivo">
                <div class="contenido-alerta">
                    <div class="icono">
                        <img class="icono-alerta-prestador" src="./assets/icons/DA-icons/icono-alerta-rojo.svg">
                    </div>
                    <div class="textos">
                        <div class="titulo-alerta">El prestador no se encuentra activo</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <ng-container *ngIf="loadingPrestaciones">
            <div class="container-spinner">
              <span class="loader"></span>
            </div>
        </ng-container>
        <ng-container *ngIf="!loadingPrestaciones">

        </ng-container> -->
        <ng-container *ngIf="loadingPrestaciones">
            <div class="container-spinner">
              <span class="loader"></span>
            </div>
        </ng-container>
        <ng-container *ngIf="!loadingPrestaciones">
        <div class="contenedor-icon-y-form">

            <div class="contenedor-icon-form">
                <img class="adress-book-icon" src="./assets/icons/DA-icons/adress-book.svg">
            </div>

            <!--Form-->
            <div [id]="scrollSection" [ngClass]="scrollSection ? scrollSection : ''" class="formulario-container">
                <span class="subtitulo-form-prestacion-box">Datos de la prestación y del prestador</span>
                <form class="formulario" [formGroup]="formularioPrestacion" enctype="multipart/form-data">

                    <!--Fila 1 form-->

                    <div class="fila1-form-container">

                        <div class="input-container">
                            <label class="label-form" for="tipo-prestacion-input-form">Tipo de prestación<span
                                    class="label-obligatorio">*</span></label>
                            <select #selectTipoPrestacion class="select-input-form tipo-prestacion-input-form"
                                id="tipo-prestacion-input-form" name="tipo-prestacion-input-form"
                                (change)="seleccionarTipo($event)" formControlName="tipoPrestacionInputForm">
                                <option value="" disabled selected>Seleccionar</option>
                                <ng-container *ngIf="dataPrestacionRequiereAtencion;else tipoPrestacionNueva">
                                    <!-- <option [value]="dataPrestacionRequiereAtencion.tipogrupodescripcion" selected>{{
                                        dataPrestacionRequiereAtencion.tipogrupodescripcion }}</option> -->
                                </ng-container>
                                <ng-template #tipoPrestacionNueva>
                                    <!-- <option value="" disabled selected>Seleccionar</option> -->
                                </ng-template>
                                <option *ngFor="let tipo of tiposYDescripcionesNomenclador" [value]="tipo.TipoGrupoId">
                                    {{ tipo.TipoGrupoDescripcion }}</option>

                            </select>
                            <div class="error-input-texto"
                                *ngIf="formularioPrestacion.get('tipoPrestacionInputForm').hasError('required') && formularioPrestacion.get('tipoPrestacionInputForm').touched">
                                Tipo de prestación es requerida
                            </div>
                        </div>

                        <div class="input-container">
                            <label class="label-form" for="prestacion-input-form">Prestación<span
                                    class="label-obligatorio">*</span></label>
                            <select #selectPrestacion class="select-input-form prestacion-input-form"
                                id="prestacion-input-form" name="prestacion-input-form"
                                (change)="seleccionarPrestacion($event)" formControlName="prestacionInputForm">
                                <!-- <ng-container *ngIf="dataPrestacionRequiereAtencion;else prestacionNueva"> -->
                                    <!-- <option [value]="dataPrestacionRequiereAtencion.nomencladordescripcion" selected>{{
                                        dataPrestacionRequiereAtencion.nomencladordescripcion }}</option> -->
                                <!-- </ng-container> -->
                                <!-- <ng-template #prestacionNueva> -->
                                <option value="" disabled selected>Seleccionar</option>
                                <!-- </ng-template> -->

                                <option *ngFor="let opcion of opcionesSegundoSelect"
                                    [value]="opcion.NomencladorId + ',' + opcion.CapituloNomencladorId">
                                    {{ opcion.NomencladorDescripcion }}</option>
                            </select>
                            <div class="error-input-texto"
                                *ngIf="formularioPrestacion.get('prestacionInputForm').hasError('required') && formularioPrestacion.get('prestacionInputForm').touched">
                                Prestación es requerida
                            </div>
                        </div>

                        <div class="input-container">
                            <label class="label-form" for="beneficiario-input-form">Beneficiario</label>
                            <input class="input-form beneficiario-input-form" id="beneficiario-input-form"
                                name="beneficiario-input-form" [(ngModel)]="beneficiario"
                                [ngModelOptions]="{standalone: true}" disabled>
                        </div>

                        <div class="input-container contenedor-input-cuit">
                            <label class="label-form" for="cuit-input-form">CUIT prestador<span
                                    class="label-obligatorio">*</span></label>

                            <input type="text" class="input-form cuit-input-form" id="cuit-input-form" maxlength="11"
                                name="cuit-input-form" placeholder="Ingresar CUIT" (keydown)="onCuitKeydown($event)" (paste)="onCuitPaste($event)"
                                oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                                [(ngModel)]="cuit" (ngModelChange)="onCuitChange()"
                                [ngModelOptions]="{standalone: true}"
                                [ngClass]="{'cuit-activo': cuitActivo, 'cuit-no-activo': cuitNoActivo, 'cuit-invalido': cuitInvalido}"
                                [disabled]="getValorSelectPrestacion() == ''"
                                (blur)="cuitTouched = true">
                            <button *ngIf="cuitNoActivo" class="boton-x-cuit" (click)="limpiarCuit()">x</button>
                            <img *ngIf="cuitActivo" class="img-tilde-cuit" src="./assets/icons/DA-icons/icono-ok.svg">
                            <div class="error-input-texto" *ngIf="cuitInvalido">
                                CUIT inválido
                            </div>
                            <div class="error-input-texto" *ngIf="cuitTouched == true && !cuit ">
                                CUIT prestador es requerido
                            </div>
                        </div>
                    </div>

                    <div class="fila2-form-container">
                        <div class="input-container">
                            <label class="label-form" for="periodo-desde">Período desde<span
                                    class="label-obligatorio">*</span></label>
                            <input type="date" class="datepicker-input" id="periodo-desde"
                                formControlName="periodoDesde" name="periodoDesde" [min]="formattedFirstDay" [max]="formattedLastDay"
                                value="2023-02-23" [(ngModel)]="periodoDesde" (input)="this.formActivo = true" onkeydown="return false;"/>
                            <div class="error-input-texto"
                                *ngIf="formularioPrestacion.get('periodoDesde').hasError('required') && formularioPrestacion.get('periodoDesde').touched">
                                Período desde es requerido
                            </div>
                            <div class="error-input-texto" *ngIf="formularioPrestacion.hasError('fechaIncoherente')">
                                El período desde debe ser anterior al período hasta.
                            </div>
                            <div class="error-input-texto" *ngIf="formularioPrestacion.hasError('periodoMayor12Meses')">
                                El período entre las fechas no puede ser mayor a 12 meses.
                            </div>     
                            <div class="error-input-texto" *ngIf="formularioPrestacion.hasError('fechaLimiteMinimo') && formularioPrestacion.get('periodoDesde').touched">
                                La fecha no puede ser menor al primer día del año anterior.
                            </div>                            
                        </div>

                        <div class="input-container">
                            <label class="label-form" for="periodo-hasta">Período hasta<span
                                    class="label-obligatorio">*</span></label>
                            <input type="date" class="datepicker-input" id="periodo-hasta"
                                formControlName="periodoHasta" name="periodoHasta" [min]="formattedFirstDay" [max]="formattedLastDay"
                                [(ngModel)]="periodoHasta" (input)="this.formActivo = true" onkeydown="return false;"/>
                            <div class="error-input-texto"
                                *ngIf="formularioPrestacion.get('periodoHasta').hasError('required') && formularioPrestacion.get('periodoHasta').touched">
                                Período hasta es requerido
                            </div>
                            <div class="error-input-texto" *ngIf="formularioPrestacion.hasError('fechaLimiteMaximo') && formularioPrestacion.get('periodoHasta').touched">
                                La fecha no puede ser mayor al último día del año siguiente.
                            </div>      
                        </div>

                        <div class="input-container input-container-large">
                            <label class="label-form" for="prestador-input-form">Prestador</label>
                            <input type="text" class="input-form prestador-input-form" id="prestador-input-form"
                                name="prestador-input-form" placeholder="" [(ngModel)]="prestador"
                                [ngModelOptions]="{standalone: true}" disabled>

                        </div>
                    </div>

                    <div class="fila3-form-container">
                        
                        <div class="input-container" *ngIf="mostrarSelectEspecialidad">
                            <label class="label-form" for="especialidad-input-form">Especialidad<span
                                    class="label-obligatorio">*</span></label>
                            <select #selectEspecialidad (change)="seleccionarEspecialidad()" class="select-input-form especialidad-input-form"
                                id="especialidad-input-form" name="especialidad-input-form"
                                formControlName="especialidadInputForm">
                                <option value="" disabled selected>Seleccionar</option>
                                
                                <option *ngFor="let especialidad of opcionesSelectEspecialidad" [value]="especialidad.value">
                                {{ especialidad.description }}</option>

                            </select>
                            <div class="error-input-texto"
                                *ngIf="formularioPrestacion.get('tipoPrestacionInputForm').hasError('required') && formularioPrestacion.get('tipoPrestacionInputForm').touched">
                                Tipo de especialidad es requerida
                            </div>
                        </div>

                        <div class="input-container" *ngIf="mostrarSelectEspecialidad">
                            <label class="label-form" for="sub-especialidad-input-form">Sub Especialidad:<span
                                    class="label-obligatorio">*</span></label>
                            <select #selectEspecialidad (change)="seleccionarSubEspecialidad()" class="select-input-form especialidad-input-form"
                                id="especialidad-input-form" name="subespecialidad-input-form"
                                formControlName="subEspecialidadInputForm">
                                <option value="" disabled selected>Seleccionar</option>
                                
                                <option *ngFor="let subEspecialidad of opcionesSelectSubEspecialidad" [value]="subEspecialidad.value">
                                {{ subEspecialidad.description }}</option>

                            </select>
                            <div class="error-input-texto"
                                *ngIf="formularioPrestacion.get('tipoPrestacionInputForm').hasError('required') && formularioPrestacion.get('tipoPrestacionInputForm').touched">
                                Tipo de Subespecialidad es requerida
                            </div>
                        </div>

                    </div>
                </form>
            </div>

        </div>
        <ng-container *ngIf="loadingDocuments">
            <div class="container-spinner">
              <span class="loader"></span>
            </div>
        </ng-container>

        <ng-container *ngIf="!loadingDocuments">


        <!--Adjuntar Documentos-->

        <div fxLayout="row" class="header-adjuntar-documentos">
            <div class="img-adjuntar-documentos">
                <img [class.img-form-completado]="camposCompletados" src="./assets/icons/DA-icons/file_logo_gris.svg"
                    alt="file-icon">
            </div>
            <div fxLayout="column" class="lista-titulo-container">
                <div>
                    <h3 class="titulo-adjuntar-documentos">Adjuntar Documentos</h3>
                </div>
                <div>
                    <ul class="lista-adjuntar-documentos">
                        <li><span>La carga de documentación opcional será considerada como información adicional a la solicitud de la prestación.</span></li>
                        <li><span>La recepción de la documentación no implica la aceptación de la solicitud. La misma
                                está sujeta a la verificación de auditoría.</span></li>
                        <li><span>Formato permitido: PDF. Peso máximo: 5MB.</span></li>
                    </ul>
                </div>
            </div>
        </div>

        <div fxLayout="row" class="seccion-certificados-container">
            <div *ngFor="let element of documents; let i = index" class="certificado-container">
                <div class="titulo-certificado">
                    <h3 title="{{element.title}}">{{element.title}}</h3>
                </div>
                <div fxLayout="column" [id]="'box-archivo-' + (i + 1)"
                    [ngClass]="{'box-archivo': true, 'active': element.activeElement === i+1, 'error': element.errorElement === i+1, 'box-campos-completos': camposCompletados}"
                    appDrag (files)="filesDropped($event,i+1)">
                    <div *ngIf="element.file.length || element.errorElement === i+1" class="container-close-icon">
                        <button class="btn-close" type="button" (click)="removeFiles(i+1)">
                            <img src="./assets/icons/DA-icons/XCircle.svg" alt="close-icon">
                        </button>
                    </div>
                    <img [class.img-campos-completos]="camposCompletados" [src]="element.urlImg" alt="upload-icon">
                    <div class="texto-adjuntar-documentos">
                        <span class="linea1">
                            {{element.textoCard}}
                        </span>
                        <label *ngIf="element.segundoTextoCard" [class.span-campos-completos]="camposCompletados"
                            for="archivo" class="linea2">{{element.segundoTextoCard}}</label>
                    </div>
                    <input *ngIf="!element.file.length && element.errorElement !== i+1 && camposCompletados" type="file"
                        [id]="'archivo' + (i + 1)" [name]="'archivo' + (i + 1)" class="file-input" accept="application/pdf"
                        (change)="handleFileSelection($event, i + 1)">
                </div>
            </div>
        
            <div *ngFor="let element of documentsPrestador; let i = index" class="certificado-container">
                <div class="titulo-certificado">
                    <h3 title="{{element.tipoDocumentacionDescripcion}}">{{element.tipoDocumentacionDescripcion}}</h3>
                </div>
                <div fxLayout="column" [id]="'box-archivo-' + (i + 1)"
                    [ngClass]="{'box-archivo': true, 'active': element.activeElement === i+1, 'error': element.errorElement === i+1, 'box-campos-completos': camposCompletados}"
                    appDrag (files)="filesDroppedPrestador($event,i+1)">
                    <div *ngIf="element.file.length || element.errorElement === i+1" class="container-close-icon">
                        <button class="btn-close" type="button" (click)="removeFilesPrestador(i+1)">
                            <img src="./assets/icons/DA-icons/XCircle.svg" alt="close-icon">
                        </button>
                    </div>
                    <img [class.img-campos-completos]="camposCompletados" [src]="element.urlImg" alt="upload-icon">
                    <div class="texto-adjuntar-documentos">
                        <span class="linea1">
                            {{element.textoCard}}
                        </span>
                        <label *ngIf="element.segundoTextoCard" [class.span-campos-completos]="camposCompletados"
                            for="archivo" class="linea2">{{element.segundoTextoCard}}</label>
                    </div>
                    <input *ngIf="!element.file.length && element.errorElement !== i+1 && camposCompletados" type="file"
                        [id]="'archivo' + (i + 1)" [name]="'archivo' + (i + 1)" class="file-input" accept="application/pdf"
                        (change)="handleFileSelectionPrestador($event, i + 1)">
                </div>
            </div>

            <div *ngFor="let element of documentsOpcionales; let i = index" class="certificado-container">
                <div class="titulo-certificado">
                    <h3 title="OPCIONAL - {{element.title}}">OPCIONAL - {{element.title}}</h3>
                </div>
                <div fxLayout="column" [id]="'box-archivo-' + (i + 1)"
                    [ngClass]="{'box-archivo': true, 'active': element.activeElement === i+1, 'error': element.errorElement === i+1, 'box-campos-completos': camposCompletados}"
                    appDrag (files)="filesDroppedOpcionales($event,i+1)">
                    <div *ngIf="element.file.length || element.errorElement === i+1" class="container-close-icon">
                        <button class="btn-close" type="button" (click)="removeFilesOpcionales(i+1)">
                            <img src="./assets/icons/DA-icons/XCircle.svg" alt="close-icon">
                        </button>
                    </div>
                    <img [class.img-campos-completos]="camposCompletados" [src]="element.urlImg" alt="upload-icon">
                    <div class="texto-adjuntar-documentos">
                        <span class="linea1">
                            {{element.textoCard}}
                        </span>
                        <label *ngIf="element.segundoTextoCard" [class.span-campos-completos]="camposCompletados"
                            for="archivo" class="linea2">{{element.segundoTextoCard}}</label>
                    </div>
                    <input *ngIf="!element.file.length && element.errorElement !== i+1 && camposCompletados" type="file"
                        [id]="'archivo' + (i + 1)" [name]="'archivo' + (i + 1)" class="file-input" accept="application/pdf"
                        (change)="handleFileSelectionOpcional($event, i + 1)">
                </div>
            </div>
        </div>
        <div class="container-btns">
            <div class="container-btn-eliminar" *ngIf="dataPrestacionRequiereAtencion">
                <button-secundary-blue-light-component [label]="eliminandoPrestacion ? 'Eliminando...' : 'Eliminar prestación'" [buttonClass]="'eliminar-prestacion'"
                    [disabled]="eliminandoPrestacion" (click)="deletePrestacion(dataPrestacionRequiereAtencion.id)"></button-secundary-blue-light-component>
            </div>
            <div class="container-btn-descartar-guardar">
                <button class="descartar-cambios" *ngIf="formActivo" (click)="resetDataForm()">Descartar cambios 
                    <!-- <img src="./assets/icons/DA-icons/Trash.svg" alt=""> -->
                </button>
                <button-primary-orange-component  [label]="enviandoPrestacion ? 'Guardando...' : 'Finalizar cambios'" [buttonClass]="'finalizar-cambios'"
                    [disabled]="!camposCompletados || enviandoPrestacion || !periodoFechasValido || (dataPrestacionRequiereAtencion && !formActivo)" (onClick)="sendData()"></button-primary-orange-component>
            </div>
        </div>
    </ng-container>
    </ng-container>
    </mat-expansion-panel>
</mat-accordion>