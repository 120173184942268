<div id="forgot-password" fxLayout="column">

    <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="forgot-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <a href="/"><img src="assets/images/logos/logo_ase.png" ></a>
            </div>

            <div class="title">OLVIDO DE CONTRASEÑA</div>

            <p>Por favor, ingrese su usuario y le enviaremos un correo para que complete el proceso.</p>

            <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Usuario (CUIL o CUIT sin guiones)</mat-label>
                    <input matInput [formControl]="cuil">
                    <mat-icon matSuffix class="secondary-text">receipt</mat-icon>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" color="accent"
                        aria-label="SEND RESET LINK" [disabled]="forgotPasswordForm.invalid">
                    ENVIAR
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" href="/">Volver al login</a>
            </div>

        </div>

    </div>

</div>
