import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InstructivosDiscapacidadComponent } from './instructivos-discapacidad.component';
import { MatIconModule } from '@angular/material/icon';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseHighlightModule } from '@fuse/components';

const routes = [
    {
        path: 'instructivos-discapacidad',
        component: InstructivosDiscapacidadComponent,
    }
]

@NgModule({
  declarations: [InstructivosDiscapacidadComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatIconModule,
    FuseSharedModule,
    FuseHighlightModule
  ]
})
export class InstructivosDiscapacidadModule { }
