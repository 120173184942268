import { FormularioRegistro } from './../../../../../models/registro-formulario.model';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
//import { FormularioRegistro } from '../../../../../models/registro-formulario.model'
import { FormularioPDFService } from 'app/services/formulario-pdf.service';
import { fuseAnimations } from '@fuse/animations';
import { cuitValidator } from 'app/shared/cuit-validator.directive';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FuseConfigService } from '@fuse/services/config.service';

@Component({
  selector: 'app-registro-formulario',
  templateUrl: './registro-formulario.component.html',
  styleUrls: ['./registro-formulario.component.scss'], 
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations  
})
export class RegistroFormularioComponent implements OnInit {

    
  formRegistro: FormGroup;
  FormularioRegistro: FormularioRegistro = {} as FormularioRegistro;

  showMe:number         = 0;
  showForm              = false;
  captchares: boolean   = false;  
  today = new Date();
  max18 = new Date(
    this.today.getFullYear() - 18,
    this.today.getMonth(),
    this.today.getDate()
  );
  min100 = new Date(
    this.today.getFullYear() - 100,
    this.today.getMonth(),
    this.today.getDate()
  );
  cuil                  = new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(11), cuitValidator()]);
  fecha_de_nacimiento   = new FormControl('', [Validators.required]);
  correoElectronico     = new FormControl('', [Validators.required, Validators.pattern('^[^@]+@[^@]+\.[a-zA-Z]{2,}$')]);  
  correoElectronico2    = new FormControl('', [Validators.required, Validators.pattern('^[^@]+@[^@]+\.[a-zA-Z]{2,}$')]);  
  
  
  constructor( private _formBuilder: FormBuilder, private _fuseConfigService: FuseConfigService, private dateAdapter: DateAdapter<Date>, private _formPDFService: FormularioPDFService,private _snackBar: MatSnackBar) {
    this.dateAdapter.setLocale('es');

    
    // Configure the layout
    this._fuseConfigService.config = {
        layout: {
            navbar   : {
                hidden: false
            },
            toolbar  : {
                hidden: false
            },
            footer   : {
                hidden: false
            },
            sidepanel: {
                hidden: false
            }
        }
    };
    }

  ngOnInit(): void {
    this.formRegistro = this._formBuilder.group({
        beneficiario : false,
        empresa : false,
        redPrestadora: false
    });
  }

  toggleView(show : number): void {
    if(show == this.showMe)
      this.showMe = 0;
    else
      this.showMe = show;
      this.showForm = false;
    }

  captchaResolved(captchaResponse: string){
      this.captchares = true;
      this.FormularioRegistro.captcharesponse = captchaResponse; //Agregamos el response aca para ser validado en node y no recibir cualquier solicitud de contacto
  }

  reload(): void{
    setTimeout(function(){
      window.location.reload();
    },3000);
  }

  sendFormulario(): void {

    this.FormularioRegistro.cuil = this.cuil.value;
    this.FormularioRegistro.email = this.correoElectronico.value; 
    this.FormularioRegistro.conf_email = this.correoElectronico2.value;
    this.FormularioRegistro.fecha_de_nacimiento = this.fecha_de_nacimiento.value.toLocaleDateString('en-GB').split('/').reverse().join(''); 

    this._formPDFService.sendFormulario(this.FormularioRegistro)
    .subscribe(resp => {
      this.formRegistro.markAllAsTouched();
      if (!this.formRegistro.valid){
         this._snackBar.open('Recordá que debes completar todos los campos correctamente ', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        return;
      }
      else 
      {
      this._snackBar.open('Muchas Gracias por enviarnos su solicutud. Revise su correo para finalziar el resgistro.', 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
        this.reload();
    }
    },
    err => {
        this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.reload();
    });      
  }
}
