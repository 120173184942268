import { Component, Injectable, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from 'app/services/login.service';
import { MisdatosService } from 'app/services/misdatos.service';
import { environment } from 'environments/environment';
import { MisdatosProveedorService } from '../../../../services/misdatos-proveedor.service';


@Component({
    selector: 'app-mis-datos',
    templateUrl: './mis-datos.component.html',
    styleUrls: ['./mis-datos.component.scss']
})
export class MisDatosComponent implements OnInit {

    @Input() cuit = window.history.state.consultaCUIT;
    SABCUIT =   window.history.state.consultaCUIT;    
    misdatos: any;
    lastLogin: any;
    CBU: any = ''
    especialidades: String = '';
    isMatriculaArray = false;
    isRolSAB = false;
    isLoading = true;

    constructor(
        private _MisdatosProveedorService: MisdatosProveedorService,
        private _loginService: LoginService,
        private _misdatos: MisdatosService,
        private router: Router,
        private _snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
    ) {
      if(! this.SABCUIT ) 
      this.activatedRoute.queryParams.subscribe((params) => {
        this.SABCUIT = params["cuit"];
       /*  if (!this.SABCUIT && !this.isRolSAB)
            this.router.navigate(['/seleccionar-datos']); */
      });
     }

    ngOnInit(): void {
        this._loginService.getAll().subscribe(resp => {
            if (!resp['isLogged'])
                this.router.navigate(['/login']);
        });

        this._loginService.getSessionData().subscribe(result => {

            if (result["rol"] && result["rol"] == "ASEPRV") {
              this.isRolSAB = true;
              this._MisdatosProveedorService.getMisDatosProveedor(this.SABCUIT).subscribe( async resultado => {
                this.misdatos = await resultado;
                if (resultado.Especialidad && resultado.Especialidad.EspecialidadDescripcion) {
                    let esp = ''
                    if (Array.isArray(resultado.Especialidad.EspecialidadDescripcion)) {
                        resultado.Especialidad.EspecialidadDescripcion.forEach(function (especialidad) {
                            if (especialidad && especialidad != 'undefined')
                                esp += especialidad + ',';
                        });

                        esp = esp.slice(0, -1);
                    }
                    else
                        esp = resultado.Especialidad.EspecialidadDescripcion;

                    this.especialidades = esp;
                }
                if (resultado.Matricula && resultado.Matricula.Matricula) {
                    if (Array.isArray(resultado.Matricula.Matricula))
                        this.isMatriculaArray = true;
                    else
                        this.isMatriculaArray = false;
                }

                if (this.misdatos.CBU)
                    this.CBU = '******************' + this.misdatos.CBU.substring(18,22);
                /*if (this.especialidades != '')
                    this.especialidades = this.especialidades.slice(0, -3);*/

                this._misdatos.getMisDatos().subscribe(resultado => {
                    let year  = resultado.ultimo_login.slice(0,4);
                    let month = resultado.ultimo_login.slice(4,6);
                    let day   = resultado.ultimo_login.slice(6,8);
                    let hh    = resultado.ultimo_login.slice(8,10);
                    let mm    = resultado.ultimo_login.slice(10,12);
                    let ss    = resultado.ultimo_login.slice(12,14);
                    this.misdatos.lastLogin      = day + '/' + month + '/' + year + ' ' + hh + ':' + mm + ':' + ss;
                });
              });
            }
            else if (result["rol"] && result["rol"] == "PRVGRL") {
              this._MisdatosProveedorService.getMisDatosProveedor(result['cuit']).subscribe(resultado => {
                console.log(resultado)
                  this.misdatos = resultado;
                  
                  if (resultado.Especialidad && resultado.Especialidad.EspecialidadDescripcion) {
                      let esp = ''
                      if (Array.isArray(resultado.Especialidad.EspecialidadDescripcion)) {
                          resultado.Especialidad.EspecialidadDescripcion.forEach(function (especialidad) {
                              if (especialidad && especialidad != 'undefined')
                                  esp += especialidad + ',';
                          });
  
                          esp = esp.slice(0, -1);
                      }
                      else
                          esp = resultado.Especialidad.EspecialidadDescripcion;
  
                      this.especialidades = esp;
                  }
                  if (resultado.Matricula && resultado.Matricula.Matricula) {
                      if (Array.isArray(resultado.Matricula.Matricula))
                          this.isMatriculaArray = true;
                      else
                          this.isMatriculaArray = false;
                  }
  
                  /*if (this.especialidades != '')
                      this.especialidades = this.especialidades.slice(0, -3);*/
  
                  this._misdatos.getMisDatos().subscribe(resultado => {
                      let year  = resultado.ultimo_login.slice(0,4);
                      let month = resultado.ultimo_login.slice(4,6);
                      let day   = resultado.ultimo_login.slice(6,8);
                      let hh    = resultado.ultimo_login.slice(8,10);
                      let mm    = resultado.ultimo_login.slice(10,12);
                      let ss    = resultado.ultimo_login.slice(12,14);
                      this.misdatos.lastLogin      = day + '/' + month + '/' + year + ' ' + hh + ':' + mm + ':' + ss;
                  });
              });
              
            }else               
              this.router.navigate(['/login']);        


            this.isLoading = false;

        });
    
    // escucho msjActualizarDatosProveedor que se emite desde actualizar-mis-datos
    this._misdatos.msjActualizarDatosProveedor.subscribe(msjActualizarDatosProveedor =>{
      if(msjActualizarDatosProveedor.data == 'filial'){               
        this._snackBar.open('La información ha sido actualizada con éxito', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });  
      }
      if(msjActualizarDatosProveedor.data == 'resto'){               
        this._snackBar.open('La información ha sido actualizada con éxito. Su solicitud está siendo procesada. En el transcurso de las 72 hs podrá ver sus datos actualizados.', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });  
      }
      if(msjActualizarDatosProveedor.data == 'todos'){               
        this._snackBar.open('Su solicitud está siendo procesada. En el transcurso de las 72 hs podrá ver sus datos actualizados.', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });  
      }
    })
  }
    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }
    linkSeleccionarDatos() {
      this.router.navigate(['seleccionar-datos'] ,{  queryParams: {cuit: this.SABCUIT}, state: { consultaCUIT: this.SABCUIT } });
    }

}
