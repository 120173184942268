import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { GetPrestacionesRequierenAtencionResponse } from "app/services/interfaces/prestaciones-requieren-atencion/get-prestaciones-atencion.interface";
import { FileHandle, DragDirective } from '../../directives/dragDrop.directive';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { FormPrestacionService } from '../../services/form-prestacion.service';
import { LoginService } from 'app/services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cloneDeep } from 'lodash';
import { ChecklistDoc } from 'app/services/interfaces/prestaciones-requieren-atencion/checklist-doc.interface';

@Component({
  selector: 'app-prestacion-observada',
  templateUrl: './prestacion-observada.component.html',
  styleUrls: ['./prestacion-observada.component.scss']
})
export class PrestacionObservadaComponent implements OnInit {
  @ViewChild(DragDirective) dragDirective: DragDirective;

  @Input() cardTitle: string;
  @Input() prestacionType: string;
  @Input() cardAdvice: { codigo: number, mensaje: string };
  @Input() dataPrestacionRequiereAtencion: GetPrestacionesRequierenAtencionResponse;
  // @Input() userData: any;
  @Output() loadPrestaciones = new EventEmitter<any>();

  documents: Array<any> = [];
  documentsPrestador: Array<any> = [];
  documentsOpcionales: Array<any> = [];
  documentosObservados: Array<any> = [];
  documentosPrestadorObservados: Array<any> = [];
  documentosOpcionalesObservados: Array<any> = [];
  activeElement: number | null;
  errorElement: number | null;
  textoCard1: string;
  segundoTextoCard1: string | null;
  textoCardDone: string;
  textoCardError: string;
  imgUrl: string;
  uploadIconUrl: string;
  doneIconUrl: string;
  errorIconUrl: string;
  sessionId: string = '';
  userData: any;
  datosNomenclador = [];
  isFilesLoaded: boolean = false;
  formActivo: boolean = false;

  backupDocumentosObservados:Array<any> = [];
  backupPrestadorObservados:Array<any> = [];
  backupOpcionalesObservados:Array<any> = [];
  enviandoPrestacion: boolean = false;

  private allowedFormats: string[];
  private maxFileSize: number;


  constructor(private sanitizer: DomSanitizer, private _formPrestacionService: FormPrestacionService, private loginService: LoginService, private _snackBar: MatSnackBar) {
    this.activeElement = null;
    this.errorElement = null;
    this.textoCard1 = 'Arrastra tu archivo o';
    this.segundoTextoCard1 = 'adjuntalo desde tu dispositivo';
    this.textoCardDone = '¡Documento cargado exitosamente!';
    this.textoCardError = 'Error. Reintenta cargar el archivo nuevamente';
    this.uploadIconUrl = './assets/icons/DA-icons/upload_icon.svg';
    this.doneIconUrl = './assets/icons/DA-icons/done_icon.svg';
    this.errorIconUrl = './assets/icons/DA-icons/error_icon.svg'
    this.allowedFormats = ['application/pdf'];
    this.maxFileSize = 5 * 1024 * 1024;
  }

  ngOnInit(): void {

    this.getSession() 
    
  }

  getSession(): void {
    this.loginService.getSessionData().subscribe(resp => {
      this.userData = resp;
      this.sessionId = resp['session_id'];
      this.obtenerDocumentos(resp['session_id'])
      this.getDocumentacionPrestador();
    });
  }


  private obtenerDocumentos(sessionId: string) {
    if (this.dataPrestacionRequiereAtencion) {
/*       this._formPrestacionService
      .getNomencladorChecklist(
        sessionId,
        this.dataPrestacionRequiereAtencion.rubronomencladorid,
        this.dataPrestacionRequiereAtencion.titulonomencladorid,
        this.dataPrestacionRequiereAtencion.capitulonomencladorid,
        this.dataPrestacionRequiereAtencion.nomencladorid
      )
      .subscribe((documentsData) => { */

      const documentosRequeridos: Array<ChecklistDoc> = [];
      const documentosNoRequeridos: Array<ChecklistDoc> = [];

      const documentsData = { documentosRequeridos: documentosRequeridos, documentosNoRequeridos: documentosNoRequeridos}



        documentsData.documentosRequeridos = this.dataPrestacionRequiereAtencion.checklistWS.filter( doc => doc.HPBEnfermedadDocumentoRequerido == '1');
        documentsData.documentosNoRequeridos = this.dataPrestacionRequiereAtencion.checklistWS.filter( doc => doc.HPBEnfermedadDocumentoRequerido == '0');

        let i=1;
        let j=1;
        for(const itemDocumentData of documentsData.documentosRequeridos){
  
          let finded = this.dataPrestacionRequiereAtencion.files.find(item => item.documentacionid == String(itemDocumentData.HPBEnfermedadTipoDocId));

          if (finded) {
            //Si no tiene numero de path la documentacion que se visualiza es la que viene del ws, de lo contrario es la cargada por el usuario por lo tanto es documentacion observada.
            if(finded.path == '' || finded.idprestacionenviada != null){
              this.documents.push({
                id: itemDocumentData.HPBEnfermedadTipoDocId,
                title: itemDocumentData.DocumentoDescripcion,
                file: finded.path,
                urlImg: this.uploadIconUrl,
                textoCard: this.textoCard1,
                segundoTextoCard: this.segundoTextoCard1,
                activeElement: null,
                errorElement: i,
                observada: false
              })  
            }else{
              this.documents.push({
                id: itemDocumentData.HPBEnfermedadTipoDocId,
                title: itemDocumentData.DocumentoDescripcion,
                file: finded.path,
                urlImg: this.doneIconUrl,
                textoCard: this.textoCardDone,
                segundoTextoCard: null,
                activeElement: i,
                errorElement: null,
                observada: true
              }) 
              i++;
            }
           
          }else{
            this.documents.push(
              {
                id: itemDocumentData.HPBEnfermedadTipoDocId,
                title: itemDocumentData.DocumentoDescripcion,
                file: [],
                urlImg: this.uploadIconUrl,
                textoCard: this.textoCard1,
                segundoTextoCard: this.segundoTextoCard1,
                activeElement: this.activeElement,
                errorElement: this.errorElement,
                observada: true
              }
            );
            i++;
          }
      
        }

        this.documentosObservados = this.documents.filter((document)=> document.observada);
        //Generamos backup de documentos.
        this.backupDocumentosObservados = cloneDeep(this.documentosObservados);
        
        
        for(const itemDocumentData of documentsData.documentosNoRequeridos){
          let finded = this.dataPrestacionRequiereAtencion.files.find(item => item.documentacionid == String(itemDocumentData.HPBEnfermedadTipoDocId));
          if (finded) {

            if(finded.path == '' || finded.idprestacionenviada != null){
              this.documentsOpcionales.push({
                id: itemDocumentData.HPBEnfermedadTipoDocId,
                title: itemDocumentData.DocumentoDescripcion,
                file: finded.path,
                urlImg: this.uploadIconUrl,
                textoCard: this.textoCard1,
                segundoTextoCard: this.segundoTextoCard1,
                activeElement: null,
                errorElement: j,
                observada: false
              })  
            }else{
              this.documentsOpcionales.push({
                id: itemDocumentData.HPBEnfermedadTipoDocId,
                title: itemDocumentData.DocumentoDescripcion,
                file: finded.path,
                urlImg: this.doneIconUrl,
                textoCard: this.textoCardDone,
                segundoTextoCard: null,
                activeElement: j,
                errorElement: null,
                observada: true
              }) 
              j++;
            }
          }else{
            this.documentsOpcionales.push(
              {
                id: itemDocumentData.HPBEnfermedadTipoDocId,
                title: itemDocumentData.DocumentoDescripcion,
                file: [],
                urlImg: this.uploadIconUrl,
                textoCard: this.textoCard1,
                segundoTextoCard: this.segundoTextoCard1,
                activeElement: this.activeElement,
                errorElement: this.errorElement,
                observada: true
              }
            );
            j++;      
          }
        }
        this.documentosOpcionalesObservados = this.documentsOpcionales.filter((document)=> document.observada);

        //Generamos backup de documentos.
        this.backupOpcionalesObservados = cloneDeep(this.documentosOpcionalesObservados);

      
    } 
  }
  

  getDocumentacionPrestador(){
   this.documentsPrestador = [];

      let jsonData = {
        'rubro': 'DS',
        'titulo': this.dataPrestacionRequiereAtencion.titulonomencladorid,
        'capitulo': this.dataPrestacionRequiereAtencion.capitulonomencladorid,
        'id': this.dataPrestacionRequiereAtencion.nomencladorid,
        'cuit': this.dataPrestacionRequiereAtencion.cuit ,
        'cuil': this.dataPrestacionRequiereAtencion.cuil ,
        'sessionId': this.sessionId,
      }

      let checkListPrestador:any = [];
      this._formPrestacionService.getDocumentacionPrestador(jsonData).subscribe(
        response => { 
          checkListPrestador = response.documentacion;
          let i = 1;
          checkListPrestador.forEach( checkListDoc => {
            let finded = this.dataPrestacionRequiereAtencion.filesPrestador.find( filePrestador => filePrestador.tipodocumentacionid == checkListDoc.TipoDocumentacionId);
            if (finded) {
              if(finded.idprestacionenviada != null){
                this.documentsPrestador.push({
                  id: checkListDoc.TipoDocumentacionId,
                  prestadorEspecialidadId: checkListDoc.PrestadorEspecialidadId,
                  prestadorSubEspecialidadId: checkListDoc.PrestadorSubEspecialidadId,
                  tipoDocumentacionDescripcion: checkListDoc.TipoDocumentacionDescripcion,
                  tipoDocumentacionId: checkListDoc.TipoDocumentacionId,
                  title: checkListDoc.TipoDocumentacionDescripcion,
                  file: finded.path,
                  urlImg: this.doneIconUrl,
                  textoCard: this.textoCardDone,
                  segundoTextoCard: null,
                  activeElement: i,
                  errorElement: this.errorElement,
                  observada: false
                });
              }else{
                this.documentsPrestador.push(
                  {
                    id: checkListDoc.TipoDocumentacionId,
                    prestadorEspecialidadId: checkListDoc.PrestadorEspecialidadId,
                    prestadorSubEspecialidadId: checkListDoc.PrestadorSubEspecialidadId,
                    tipoDocumentacionDescripcion: checkListDoc.TipoDocumentacionDescripcion,
                    tipoDocumentacionId: checkListDoc.TipoDocumentacionId,
                    file: finded.path,
                    urlImg: this.doneIconUrl,
                    textoCard: this.textoCardDone,
                    segundoTextoCard: null,
                    activeElement: i,
                    errorElement: null,
                    deleted: false,
                    observada: true
                  }
                );
              }
              
            } else {
              this.documentsPrestador.push(
                {
                  id: checkListDoc.TipoDocumentacionId,
                  prestadorEspecialidadId: checkListDoc.PrestadorEspecialidadId,
                  prestadorSubEspecialidadId: checkListDoc.PrestadorSubEspecialidadId,
                  tipoDocumentacionDescripcion: checkListDoc.TipoDocumentacionDescripcion,
                  tipoDocumentacionId: checkListDoc.TipoDocumentacionId,
                  file: [],
                  urlImg: this.uploadIconUrl,
                  textoCard: this.textoCard1,
                  segundoTextoCard: this.segundoTextoCard1,
                  activeElement: this.activeElement,
                  errorElement: this.errorElement,
                  deleted: false,
                  observada: true
                }
              );
            }
            i++;
          });
          this.documentosPrestadorObservados = this.documentsPrestador.filter((document)=> document.observada)
          //Generamos backup de documentos.

          this.backupPrestadorObservados = cloneDeep(this.documentosPrestadorObservados);
        })   
  }


  private isValidFile(file: File): boolean {
    if (!this.allowedFormats.includes(file.type)) {
      return false;
    }

    if (file.size > this.maxFileSize) {
      return false;
    }

    return true;
  }

  filesDropped(files: FileHandle[], inputIndex: number): void {
    let documents = this.documentosObservados
    this._handleFileDropped(inputIndex, documents, files);
  }

  filesDroppedPrestador(files: FileHandle[], inputIndex: number): void {
    console.log('entro aca')

    const documents = this.documentosPrestadorObservados;
    this._handleFileDropped(inputIndex, documents, files);

  }

  filesDroppedOpcionales(files: FileHandle[], inputIndex: number): void {
    let documents = this.documentosOpcionalesObservados
    this._handleFileDropped(inputIndex, documents, files);
 
  }


  removeFiles(inputIndex: number): void {
    let documents = this.documentosObservados

    documents[inputIndex - 1].file = [];
    documents[inputIndex - 1].deleted = true;
    documents[inputIndex - 1].activeElement = null;
    documents[inputIndex - 1].errorElement = null;
    documents[inputIndex - 1].textoCard = this.textoCard1;
    documents[inputIndex - 1].segundoTextoCard = this.segundoTextoCard1;
    documents[inputIndex - 1].urlImg = this.uploadIconUrl;

    this.formActivo = true;
    this.isFilesLoaded = this.checkDocumentFiles();

  }


  removeFilesPrestador(inputIndex: number): void {
    let documents = this.documentosPrestadorObservados;
    this._removeFile(inputIndex, documents);
  }

  removeFilesOpcionales(inputIndex: number): void {
    let documents = this.documentosOpcionalesObservados

    this._removeFile(inputIndex, documents);
  }

  _removeFile(inputIndex, documents){
    documents[inputIndex - 1].deleted = true;
    documents[inputIndex - 1].file = [];
    documents[inputIndex - 1].activeElement = null;
    documents[inputIndex - 1].errorElement = null;
    documents[inputIndex - 1].textoCard = this.textoCard1;
    documents[inputIndex - 1].segundoTextoCard = this.segundoTextoCard1;
    documents[inputIndex - 1].urlImg = this.uploadIconUrl;
    this.formActivo = true;
    this.isFilesLoaded = this.checkDocumentFiles();
  }


  handleFileSelection(event: Event, inputIndex: number): void {
    let documents = this.documentosObservados
    this._handleFileSelection(event, inputIndex, documents);
    console.log("DOCUMENTOS OBSERVADOS", this.documentosObservados);
 
  }

  handleFileSelectionPrestador(event: Event, inputIndex: number): void {
    const documents = this.documentosPrestadorObservados;
    this._handleFileSelection(event, inputIndex, documents);
  
  }

  handleFileSelectionOpcionales(event: Event, inputIndex: number): void {
    let documents = this.documentosOpcionalesObservados;
    this._handleFileSelection(event, inputIndex, documents);

  }

  _handleFileDropped(inputIndex, documents, files){
    if (documents[inputIndex - 1].file.length || documents[inputIndex - 1].errorElement || documents[inputIndex - 1].activeElement) {
      return
    }

    if (!this.isValidFile(files[0].file)) {
      documents[inputIndex - 1].errorElement = inputIndex;
      documents[inputIndex - 1].urlImg = this.errorIconUrl;
      documents[inputIndex - 1].textoCard = this.textoCardError;
      documents[inputIndex - 1].segundoTextoCard = null;
      return;
    }

    documents[inputIndex - 1].file = [files[0].file];
    documents[inputIndex - 1].deleted = false;
    documents[inputIndex - 1].urlImg = this.doneIconUrl;
    documents[inputIndex - 1].textoCard = this.textoCardDone;
    documents[inputIndex - 1].segundoTextoCard = null;
    documents[inputIndex - 1].textoCardDone = null;
    documents[inputIndex - 1].activeElement = inputIndex;
    this.isFilesLoaded = this.checkDocumentFiles();
    this.formActivo = true;

    
  }

  _handleFileSelection(event, inputIndex, documents ){
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0 && !documents[inputIndex - 1].file.length) {
      const selectedFile = inputElement.files[0];

      if (!this.isValidFile(selectedFile)) {

        documents[inputIndex - 1].errorElement = inputIndex;
        documents[inputIndex - 1].urlImg = this.errorIconUrl;
        documents[inputIndex - 1].textoCard = this.textoCardError;
        documents[inputIndex - 1].segundoTextoCard = null;
        return;
      }
      const url: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(selectedFile)
      );

      documents[inputIndex - 1].file.push(selectedFile);
      documents[inputIndex - 1].deleted = false;
      documents[inputIndex - 1].urlImg = this.doneIconUrl
      documents[inputIndex - 1].textoCard = this.textoCardDone;
      documents[inputIndex - 1].segundoTextoCard = null;
      documents[inputIndex - 1].activeElement = inputIndex;
      this.isFilesLoaded = this.checkDocumentFiles();
      this.formActivo = true;  
    }
  }

  checkDocumentFiles(): boolean {
    let documents = [...this.documentosObservados, ...this.documentosOpcionalesObservados, ...this.documentosPrestadorObservados];
    return documents.some(document => document.hasOwnProperty('file') && document['file'] !== undefined && document['file'] !== null && document['file'].length > 0);
  }

  resetDocuments(): void {
    this.documentosObservados = cloneDeep(this.backupDocumentosObservados);
    this.documentosPrestadorObservados = cloneDeep(this.backupPrestadorObservados);
    this.documentosOpcionalesObservados = cloneDeep(this.backupOpcionalesObservados); 
    this.formActivo = false;
  }

  sendPrestacionObservada(): void {
      this.enviandoPrestacion = true;
      const formData = new FormData();
      
      formData.append('sessionId', this.sessionId);
      formData.append('id', this.dataPrestacionRequiereAtencion.id)
      formData.append('cuil', this.dataPrestacionRequiereAtencion.cuil);
      formData.append('nroOrden', this.dataPrestacionRequiereAtencion.nroorden);
      formData.append('nroBeneficiario', this.dataPrestacionRequiereAtencion.nrobeneficiario);
      formData.append('periodoDesde', this.dataPrestacionRequiereAtencion.periododesde);
      formData.append('periodoHasta', this.dataPrestacionRequiereAtencion.periodohasta);
      formData.append('razonsocial', this.dataPrestacionRequiereAtencion.razonsocial);
      formData.append('cuit', this.dataPrestacionRequiereAtencion.cuit);
      formData.append('tipogrupodescripcion', this.dataPrestacionRequiereAtencion.tipogrupodescripcion);
      formData.append('nomencladordescripcion', this.dataPrestacionRequiereAtencion.nomencladordescripcion);
      formData.append('rubronomencladorid', this.dataPrestacionRequiereAtencion.rubronomencladorid);
      formData.append('titulonomencladorid', this.dataPrestacionRequiereAtencion.titulonomencladorid);
      formData.append('capitulonomencladorid', this.dataPrestacionRequiereAtencion.capitulonomencladorid);
      formData.append('nomencladorid', this.dataPrestacionRequiereAtencion.nomencladorid);


      const nombresArchivosArray = this.documents.map((document) => {
        const adjuntado = Array.isArray(document.file) && document.file.length > 0  ? true : false;
        return {
          title: document.title,
          adjuntado: adjuntado,
          id: document.id,
          deleted: document.deleted
        }
      });

      const nombresArchivosOpcionalesArray = this.documentsOpcionales.map((document) => {
        const adjuntado = Array.isArray(document.file) && document.file.length > 0  ? true : false;
        return {
          title: document.title,
          adjuntado: adjuntado,
          id: document.id,
          deleted: document.deleted
        }
      });

      const nombresArchivosPrestadorArray = this.documentsPrestador.map((document) => {
        const adjuntado = Array.isArray(document.file) && document.file.length > 0 ? true : false;
        return { 
          adjuntado: adjuntado,
          id: document.id,
          deleted: document.deleted,
          prestadorEspecialidadId: document.prestadorEspecialidadId,
          prestadorSubEspecialidadId: document.prestadorSubEspecialidadId,
          tipoDocumentacionDescripcion: document.tipoDocumentacionDescripcion,
          tipoDocumentacionId: document.tipoDocumentacionId,
        }
      })


      

      formData.append('nombresarchivos', JSON.stringify(nombresArchivosArray));
      formData.append('nombresArchivosPrestador', JSON.stringify(nombresArchivosPrestadorArray));
      formData.append('nombresArchivosOpcionales', JSON.stringify(nombresArchivosOpcionalesArray))

      for (const document of this.documents) {
        formData.append('archivo', document.file.length > 0 ? document.file[0] : document.file);
      }
      for (const document of this.documentsPrestador) {
        formData.append('archivo', document.file.length > 0 ? document.file[0] : document.file);
      }
      for (const document of this.documentsOpcionales) {
        formData.append('archivo', document.file.length > 0 ? document.file[0] : document.file);
      }

      this._formPrestacionService.onSubmit(formData).subscribe(
        (response) => {
          this._snackBar.open('Cambios realizados con éxito', 'Cerrar', {
            duration: undefined,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          this.enviandoPrestacion = false;
          this.loadPrestaciones.emit({ envioPrestacion: true, idPrestacionCreada: this.dataPrestacionRequiereAtencion.id, nombrePrestacion: this.dataPrestacionRequiereAtencion.nomencladordescripcion, update: 0})
/*           setTimeout(() => {
            window.location.reload();
          }, 2000); */
        },
        (error) => {
            this._snackBar.open('Error al enviar la prestación', 'Aceptar', {
              duration: undefined,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
      );

  }

  tienePrestacionCompletaPeroDocBasicaObservada() {
    if (this.documentosObservados.length == 0 && this.documentosPrestadorObservados.length == 0 ){
      if (this._formPrestacionService.documentacionListaParaEnviar == false) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  tieneDocBasicaObservada() {
    if (this._formPrestacionService.documentacionListaParaEnviar == false) {
      return true;
    } else {
      return false;
    }
  }


}
