<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxHide.xs class="aside" fxHide.lt-md fxShow.gt-sm>
               
        <div>
            <img src="assets/images/iconos/icono_altausuarios.svg" class="icon">
        </div>         

    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a routerLink="/misdatos">Mis datos</a> » <a routerLink="/misdatos/solicitar-rol">Solicitar Rol</a> » <span>Solicitar Rol Empresa</span>
        </div>

        <h2 class="page-subtitle">Informe el CUIT de la Empresa</h2>
       
        <form #form name="" action="" method="POST" novalidate enctype="multipart/form-data">
            <div fxLayout="row">
                <mat-form-field fxFlex.lt-md="100" fxFlex="40">
                    <mat-label>CUIT ( sin guiones )</mat-label>
                    <input type="number" matInput [formControl]="CUIT" placeholder="CUIT" maxlength="11" required oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)">
                    <mat-error *ngIf="CUIT.invalid ">Debe ingresar el CUIT.</mat-error>                        
                    <mat-error *ngIf="CUIT.hasError('cuit') ">{{ CUIT.errors.cuit.value }}</mat-error>
                </mat-form-field>
                
            </div>
            <div fxLayout="row">
                <button mat-raised-button id="btnContinuar" (click)="btnEnviarCuit()">Enviar</button>
                <button mat-raised-button id="btnCancelar" routerLink="/misdatos">Cancelar</button>
            </div>
        </form>

         
        
    </div>


</div>


