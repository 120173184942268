import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

@Component({
    selector     : 'login',
    templateUrl  : './login.component.html',
    styleUrls    : ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class LoginComponent implements OnInit
{
    loginForm: FormGroup;
    http: any;
    env = environment;
    returnUrl = this.env.urlWebsite + 'pages/auth/login?er=';
    error: String;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.loginForm = this._formBuilder.group({
            /*email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]*/
            'AuthLoginForm[username]' : ['', [Validators.required]],
            'AuthLoginForm[contraseña]' : ['', [Validators.required]]
        });

        this.error = this.route.snapshot.queryParamMap.get('er');
    }

    onSubmit(customerData) {
        // Process checkout data here

        //customerData.target.submit();
        console.warn('Your order has been submitted');
    }
    data(data: any): any {
        throw new Error("Method not implemented.");
    }
}
