<table mat-table [dataSource]="prestaciones" *ngIf="showTable">
  <ng-container matColumnDef="Prestacion">
    <th mat-header-cell *matHeaderCellDef>Prestación</th>
    <td
      mat-cell
      *matCellDef="let prestacion"
      class="truncate-cell prestacion-cell"
      title="{{ prestacion?.nomencladordescripcion }}">
      {{ prestacion?.nomencladordescripcion }}
    </td>
  </ng-container>
  <ng-container matColumnDef="Prestador">
    <th mat-header-cell *matHeaderCellDef>Prestador</th>
    <td
      mat-cell
      *matCellDef="let prestacion"
      class="truncate-cell prestador-cell"
      title="{{ prestacion.razonsocial }}">
      {{ prestacion.razonsocial }}
    </td>
  </ng-container>
  <ng-container matColumnDef="Desde">
    <th mat-header-cell *matHeaderCellDef>Desde</th>
    <td mat-cell *matCellDef="let prestacion">
      {{ prestacion.periododesde | date : "dd/MM/yyyy" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="Hasta">
    <th mat-header-cell *matHeaderCellDef>Hasta</th>
    <td mat-cell *matCellDef="let prestacion">
      {{ prestacion.periodohasta | date : "dd/MM/yyyy" }}
    </td>
  </ng-container>
  <ng-container *ngIf="!isEnviadas">
    <ng-container matColumnDef="Sesiones">
      <th mat-header-cell *matHeaderCellDef>Sesiones o Km</th>
      <td
        mat-cell
        *matCellDef="let prestacion"
        class="truncate-cell"
        title="{{ prestacion.cantidad }}">
        {{ prestacion.cantidad }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Accion">
      <th mat-header-cell *matHeaderCellDef>Acción requerida</th>
      <td mat-cell *matCellDef="let prestacion">
        <ng-container *ngIf="getDataOnStatus(prestacion) as data">
          <img src="{{ data.src }}" class="logo-icon prestacion-icon" /><span
            class="prestacion__{{ data.classText }}">
            {{ data.textNode }}
          </span>
        </ng-container>
      </td>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isEnviadas">
    <ng-container matColumnDef="fechaSolicitud">
      <th mat-header-cell *matHeaderCellDef>Fecha solicitud</th>
      <td mat-cell *matCellDef="let prestacion">
        {{ prestacion.fechaenvio | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="nroTramite">
      <th mat-header-cell *matHeaderCellDef>Trámite</th>
      <td mat-cell *matCellDef="let prestacion">
        {{ prestacion.idprestacionenviada }}
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columndefs"></tr>
  <tr mat-row *matRowDef="let row; columns: columndefs"></tr>
</table>
