import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
import { MisdatosService } from '../../../../../services/misdatos.service';
import { LoginService } from 'app/services/login.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FileInput, FileValidator } from 'ngx-material-file-input';
import { CargaFacturaService } from 'app/services/carga-factura.service';
import * as dayjs from 'dayjs';
import { max } from 'lodash';
import { formatDate } from '@angular/common';
import { CargaComprobantesService } from 'app/services/carga-comprobantes.service';
import { MensajesHtmlComponent } from 'app/main/private/mensajes-html/mensajes-html.component';
import { MisdatosProveedorService } from 'app/services/misdatos-proveedor.service';
import { subMonths } from 'date-fns';



@Component({
    selector: 'app-carga-factura',
    templateUrl: './carga-factura.component.html',
    styleUrls: ['./carga-factura.component.scss']
})
export class CargaFacturaComponent implements OnInit {

    constructor(private router: Router,
        private _misdatos: MisdatosService,
        private _cargaFactura: CargaFacturaService,
        private getSessionData: LoginService,
        private _snackBar: MatSnackBar,
        private dateAdapter: DateAdapter<Date>,
        private activatedRoute: ActivatedRoute,
        private _cargaComprobantes: CargaComprobantesService,
        private _MisdatosProveedorService: MisdatosProveedorService) {
        this.dateAdapter.setLocale('en-GB');
    }

    misdatos: any;
    meses: any = '';
    BenTNomApe = window.history.state.BenTNomApe; // viene de comprobantes
    BenNroAse = window.history.state.BenNroAse; // viene de comprobantes
    BenNroOrd = window.history.state.BenNroOrd; // viene de comprobantes
    DiscaExAnio = window.history.state.DiscaExAnio; // viene de comprobantes
    DiscaTramSec = window.history.state.DiscaTramSec; // viene de comprobantes
    FechaDesde = window.history.state.FechaDesde; // viene de comprobantes
    FechaHasta = window.history.state.FechaHasta; // viene de comprobantes 
    Cantidad = window.history.state.Cantidad; // viene de comprobantes
    TipoCantidad = window.history.state.TipoCantidad; // viene de comprobantes
    NumeroDocumento = window.history.state.NumeroDocumento; // viene de comprobantes
    Prestacion = window.history.state.Prestacion;
    //razonSocial = window.history.state.RazonSocial; // viene de comprobantes
    razonSocial = 'Proveedor';
    condicionImpositiva = '';

    cuit = '';
    tipoCAEselected = "CAE";
    minFactCAE: any;
    maxFactCAE: any;
    btnEnviarDisabled: boolean = false;
    vencimientoCAEDisabled = true;
    cuatroMeses: any;
    mesAnterior: any;
    isAse: Boolean = false;
    tipoSeries = [];

    readonly maxSize = 3145728;

    formCarga: FormGroup;
    obraSocial = new FormControl('', [Validators.required]);
    tipoTramite = new FormControl('', [Validators.required]);
    tipoComprobante = new FormControl('', [Validators.required]);
    tipoFactura = new FormControl('', [Validators.required]);
    fechaEmisionComprobante = new FormControl('', [Validators.required]);
    puntoVenta = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{1,5}$')]);
    comprobante = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{1,8}$')]);
    importeTotal = new FormControl('', [Validators.required, Validators.min(0), Validators.pattern('^[0-9]*\.[0-9]{1,2}$')]);
    mes = new FormControl('', [Validators.required, Validators.min(0)]);
    kmSesiones = new FormControl('', [Validators.required, Validators.min(0)]);
    codAutCAE = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{14,14}$')]);
    tipoCAE = new FormControl('');
    fechaVencimientoCAE = new FormControl('', [Validators.required]);
    adjComprobante = new FormControl('', [Validators.required, FileValidator.maxContentSize(this.maxSize), this.pdfDocValidator]);
    adjPlanilla = new FormControl('', [FileValidator.maxContentSize(this.maxSize), this.inputPlanillaDeAsistencia]);
    validAfip = false;
    cbu = '';
    Ddomicilio = '';
    Telefono = '';
    Email = '';
    encoder = new TextEncoder();
    decoder = new TextDecoder();

    pdfDocValidator(control: FormControl) {
        if (!control.value)
            return;
        const type = control.value.files[0].type;
        if (type && type != 'application/pdf') {
            return {
                mimeType: {
                    type: type
                }
            }
        }
        return null;
    }

    inputPlanillaDeAsistencia(control: FormControl) {
      if (!control.value)
          return;
      const type = control.value.files[0].type;
      if (type &&
          type != 'image/png' &&
          type != 'image/jpeg' &&
          type != 'application/pdf' &&
          type != 'application/msword' &&
          type != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          return {
              mimeType: {
                  type: type
              }
          }
      }
      return null;
  }

    redirect(): void{
      setTimeout(function(){
        window.location.href= environment.urlWebsite + 'proveedores/comprobantes/carga/inicio';
      },3000);
    }

    btnActualizarData(): void {
        this.router.navigate(['proveedores/mis-datos']);
    }

    formatToDot(x) {
        return Number.parseFloat(x).toFixed(2);
    }

    //Función para solo permitir numeros
    keyPressNumbers(event) {
        let charCode = (event.which) ? event.which : event.keyCode;
        if ((charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
        } else {
        return true;
        }
    }

    //Funcion para agregar 0
    puntoVentaAddCharacter(){
        let valor = this.puntoVenta.value;
        
        //Si no es un numero, se vaciará el contenido del input (previene copy-paste de letras)
        if(isNaN(valor) || valor == 0){
            valor= '';
            this.formCarga.controls['puntoVenta'].setValue(valor);
        }

        switch(this.puntoVenta.value.length){
            case 1:
                valor = '0000'+this.puntoVenta.value;
                this.formCarga.controls['puntoVenta'].setValue(valor);
                break;
            case 2:
                valor = '000'+this.puntoVenta.value;
                this.formCarga.controls['puntoVenta'].setValue(valor);
                break;
            case 3:
                valor = '00'+this.puntoVenta.value;
                this.formCarga.controls['puntoVenta'].setValue(valor);
                break;
            case 4:
                valor = '0'+this.puntoVenta.value;
                this.formCarga.controls['puntoVenta'].setValue(valor);
                break;
            case 5:
                break;
            default:
                break;
        }
    }

    //Funcion para agregar 0
    comprobanteAddCharacter(){
        let valor = this.comprobante.value;

        //Si no es un numero, se vaciará el contenido del input (previene copy-paste de letras)
        if(isNaN(valor) || valor == 0){
            valor= '';
            this.formCarga.controls['comprobante'].setValue(valor);
        }

        switch(this.comprobante.value.length){
            case 1:
                valor = '0000000'+this.comprobante.value;
                this.formCarga.controls['comprobante'].setValue(valor);
                break;
            case 2:
                valor = '000000'+this.comprobante.value;
                this.formCarga.controls['comprobante'].setValue(valor);
                break;
            case 3:
                valor = '00000'+this.comprobante.value;
                this.formCarga.controls['comprobante'].setValue(valor);
                break;
            case 4:
                valor = '0000'+this.comprobante.value;
                this.formCarga.controls['comprobante'].setValue(valor);
                break;
            case 5:
                valor = '000'+this.comprobante.value;
                this.formCarga.controls['comprobante'].setValue(valor);
                break;
            case 6:
                valor = '00'+this.comprobante.value;
                this.formCarga.controls['comprobante'].setValue(valor);
                break;
            case 7:
                valor = '0'+this.comprobante.value;
                this.formCarga.controls['comprobante'].setValue(valor);
                break;
            case 8:
                break;
            default:
                break;
        }
    }

    ngOnInit(): void {

        this.getSessionData.getAll().subscribe(resp => {
            if (!resp['isLogged'])
                this.router.navigate(['/login']);

            this.getSessionData.getSessionData().subscribe(response => {
                if (!response['rol'] || response['rol'] != 'PRVGRL')
                    this.router.navigate(['/login']);

                this._MisdatosProveedorService.getMisDatosProveedor(response['cuit']).subscribe(resultado => {
                    
                    if (resultado && resultado.CBU)
                        this.cbu = '****************' + resultado.CBU.substring(18,22);
                    else
                        this.cbu = '';

                    this.Ddomicilio = resultado.Ddomicilio;
                    this.Telefono = resultado.Telefono;
                    this.Email = resultado.Email;
                    this.condicionImpositiva = resultado.CondicionImpositiva;
                    if (!this.cbu) {


                        this._snackBar.openFromComponent(MensajesHtmlComponent, {
                            duration: undefined,
                            horizontalPosition: 'center',
                            verticalPosition: 'top',
                            data: 'faltaCBU'
                        });
                        // deshabilito el boton de enviar
                        this.btnEnviarDisabled = true;
                    }
                });
            });

            if (!this.Prestacion || !this.BenTNomApe || !this.BenNroAse || !this.BenNroOrd || !this.DiscaExAnio || !this.DiscaTramSec || !this.FechaDesde || !this.FechaHasta) {
                this.activatedRoute.queryParams.subscribe((params) => {
                    const Index = params["index"];
                    this.Prestacion = params['prestacion'];
                    this.BenTNomApe = params['beneficiarioName'];
                    if (Index) {
                        this.getSessionData.getSessionData().subscribe(result => {
                            this._cargaComprobantes.getAllPrestaciones(result.cuit)
                                .subscribe(data => {
                                    var prestaciones = data;
                                    if (!prestaciones[Index])
                                        this.router.navigate(['/proveedores/comprobantes/carga']);
                                    this.BenTNomApe = prestaciones[Index]['BenTNomApe'];
                                    this.BenNroAse = prestaciones[Index]['BenNroAse'];
                                    this.BenNroOrd = prestaciones[Index]['BenNroOrd'];
                                    this.DiscaExAnio = prestaciones[Index]['DiscaExAnio'];
                                    this.DiscaTramSec = prestaciones[Index]['DiscaTramSec'];
                                    this.FechaDesde = prestaciones[Index]['FechaDesde'];
                                    this.FechaHasta = prestaciones[Index]['FechaHasta'];
                                    this.Cantidad = prestaciones[Index]['Cantidad'];
                                    this.TipoCantidad = prestaciones[Index]['TipoCantidad'];
                                    this.NumeroDocumento = prestaciones[Index]['BenTNroDoc'];
                                    this.Prestacion = prestaciones[Index]['Prestacion'];
                                    /*this._cargaFactura.getPeriodosPrestaciones(this.BenNroAse, this.BenNroOrd, this.DiscaExAnio, this.DiscaTramSec).subscribe(resultado => {
                                        this.meses = resultado;
                                    });*/
                                    this.setPeriodos();
                                })
                        });
                    }
                });
            }

            this.getSessionData.getSessionData().subscribe(result => {
                this.cuit = result['cuit'];
                this._misdatos.getMisDatos().subscribe(resultado => {
                    this.misdatos = resultado;
                });
            });

            this.tipoSeries = [{ serie: "A" }, { serie: "B" }, { serie: "C" }, { serie: "M" }];
            /*this.formCarga.get('obraSocial').valueChanges.subscribe((event) => {
                if (event !== undefined) {
                    if (this.formCarga.get('obraSocial').value == "ASE") {
                        if (this.condicionImpositiva == 'R.I.')
                            this.tipoSeries = [{ serie: "A" }, { serie: "M" }];
                        else if (this.condicionImpositiva == 'IVA EX.' || this.condicionImpositiva == 'R.M.')
                            this.tipoSeries = [{ serie: "C" }];
                    }
                    else if (this.formCarga.get('obraSocial').value == "OSPM" || this.formCarga.get('obraSocial').value == "OSEN") {
                        if (this.condicionImpositiva == 'R.I.')
                            this.tipoSeries = [{ serie: "B" }, { serie: "M" }];
                        else if (this.condicionImpositiva == 'IVA EX.' || this.condicionImpositiva == 'R.M.')
                            this.tipoSeries = [{ serie: "C" }];
                    }
                }
            });*/

            if (this.meses == '' && this.FechaDesde && this.FechaHasta){
                this.setPeriodos();
            };

        });

        this.formCarga = new FormGroup({
          tipoFactura: this.tipoFactura,
          puntoVenta: this.puntoVenta,
          obraSocial: this.obraSocial,
          comprobante: this.comprobante,
          fechaEmisionComprobante: this.fechaEmisionComprobante,
            tipoTramite: this.tipoTramite,
            tipoComprobante: this.tipoComprobante,
            importeTotal: this.importeTotal,
            mes: this.mes,
            kmSesiones: this.kmSesiones,
            codAutCAE: this.codAutCAE,
            tipoCAE: this.tipoCAE,
            fechaVencimientoCAE: this.fechaVencimientoCAE,
            adjComprobante: this.adjComprobante,
            adjPlanilla: this.adjPlanilla,
        });

        //min fechaEmisionComprobante
        var d = new Date();
        d.setMonth(d.getMonth() - 4);
        this.cuatroMeses = new Date(d.getFullYear(), d.getMonth(), 1);
        //max fechaEmisionComprobante
        var fechaCarga = new Date();
        this.mesAnterior = new Date(fechaCarga.getFullYear(), fechaCarga.getMonth(), 0);

        //me suscribo a fechaEmisionComprobante para definir en fact CAE los min y max + 10dias
        this.formCarga.get('fechaEmisionComprobante').valueChanges.subscribe((evento) => {
            if (evento instanceof Date) {
                this.vencimientoCAEDisabled = false;
                this.minFactCAE = evento;
                var dateCopy = new Date(evento.getTime());
                dateCopy.setDate(dateCopy.getDate() + 10);
                this.maxFactCAE = dateCopy;
            }
            else
                this.vencimientoCAEDisabled = true;
        });
    }

    superaPrestacion(value) {

        if (value > this.Cantidad) {

            this._snackBar.open('La cantidad de ' + this.TipoCantidad + ' supera lo autorizado', 'Cerrar', {
                duration: undefined,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
        }

    }


    showSnackNoSeraVisualizado(){
        this._snackBar.open('El comprobante cargado no será visualizado en Mis Pagos', 'Cerrar', {
            duration: undefined,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }



    btnEnviar() {
        this.formCarga.enable();
        this.formCarga.markAllAsTouched();
        if (!this.formCarga.valid) {
            this._snackBar.open('Recordá que debes completar todos los campos correctamente ', 'Cerrar', {
                duration: undefined,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
            return;
        }

        var d = this.fechaEmisionComprobante.value;
        var month = d.getMonth();
        month += 1;
        if (month < 10)
            month = '0' + month.toString();

        var day = d.getDate();
        if (day < 10)
            day = '0' + day.toString();
        var fechaEmisionComprobanteFormateada = day + '/' + month + '/' + d.getFullYear();
        
        var d = this.fechaVencimientoCAE.value;
        month = d.getMonth();
        month += 1;
        if (month < 10)
            month = '0' + month.toString();

        var day = d.getDate();
        if (day < 10)
            day = '0' + day.toString();
        var fechaVencimientoCAEFormateada = day + '/' + month + '/' + d.getFullYear();

        const formData = new FormData();
        formData.append('obraSocial', this.obraSocial.value);
        formData.append('tipoTramite', this.tipoTramite.value);
        formData.append('tipoComprobante', this.tipoComprobante.value);
        formData.append('tipoFactura', this.tipoFactura.value);
        formData.append('puntoVenta', this.puntoVenta.value);
        formData.append('comprobante', this.comprobante.value);
        formData.append('fechaEmisionComprobante', fechaEmisionComprobanteFormateada);
        formData.append('importeTotal', this.importeTotal.value);
        formData.append('mes', this.mes.value);
        formData.append('kmSesiones', this.kmSesiones.value);
        formData.append('codAutCAE', this.codAutCAE.value);
        formData.append('tipoCAE', this.tipoCAE.value);
        formData.append('fechaVencimientoCAE', fechaVencimientoCAEFormateada);
        formData.append('cuit', this.cuit);
        formData.append('puntoDeVenta', this.puntoVenta.value);
        formData.append('periodo', this.mes.value);
        formData.append('razonSocial', this.razonSocial);
        formData.append('numeroDocumento', this.NumeroDocumento);
        formData.append('prestacion',this.Prestacion);
        formData.append('beneficiario', this.BenTNomApe);

        const adj1_form: FileInput = this.adjComprobante.value;
        if (adj1_form) {
            const adj1 = adj1_form.files[0];
            formData.append('adjComprobante', adj1);
        }

        const adj2_form: FileInput = this.adjPlanilla.value;
        if (adj2_form) {
            const adj2 = adj2_form.files[0];
            formData.append('adjPlanilla', adj2);
        }

        formData.forEach((value, key) => {
            console.log(JSON.stringify(key) + JSON.stringify(value))
        });

        this._cargaFactura.sendForm(formData)
            .subscribe(resp => {
            //    let snackbarRef = this._snackBar.open('Tu requerimiento se cargó correctamente bajo el número de incidente ' + resp['body'] + ', podrás visualizarlo en la sección de Mis Pagos', 'Cerrar', {
            //         duration: undefined,
            //         horizontalPosition: 'center',
            //         verticalPosition: 'top',
            //     });
            //     //Al precionar el botón cerrar será redirigido a comprobantes/carga
            //     snackbarRef.afterDismissed().subscribe(() => {
            //         window.location.href = environment.urlWebsite + '/proveedores/comprobantes/carga';
            //     });

                let numIncidente = resp['body'];
                let dataSnackbar;

                if(this.obraSocial.value == 'OSEN' || this.tipoComprobante.value == 'NC'){
                    dataSnackbar = ['envioOk', numIncidente];
                }else{
                    dataSnackbar =  ['envioOKMisPagos', numIncidente];
                }

                this._snackBar.openFromComponent(MensajesHtmlComponent, {
                    duration: undefined,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                    data: dataSnackbar
                });
            },
                err => {
                    if (err['error'].error == 2){
                        this.validAfip = false;
                        this._snackBar.open('El comprobante ha sido cargado previamente', 'Cerrar', {
                            duration: undefined,
                            horizontalPosition: 'center',
                            verticalPosition: 'top',
                        });
                    }
                    else{
                        this.validAfip = false;
                        this._snackBar.open('Ha ocurrido un error: ' + err['message'], 'Cerrar', {
                            duration: undefined,
                            horizontalPosition: 'center',
                            verticalPosition: 'top',
                        });
                    }
                });
    }

    reload(): void{
      setTimeout(function(){
        this.linkCargaComprobante();
      },6000);
    }

    validateAfip() {
        this.formCarga.markAllAsTouched();
        if (!this.formCarga.valid) {
            this._snackBar.open('Recordá que debes completar todos los campos correctamente ', 'Cerrar', {
                duration: undefined,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
            return;
        }

        var date = this.fechaEmisionComprobante.value;
        var year = date.getFullYear();
        var month = date.getMonth();
        month += 1;
        if (month < 10)
            month = '0' + month.toString();

        var day = date.getDate();
        if (day < 10)
            day = '0' + day.toString();

        var fechaEmisionComprobanteFormateada = year.toString() + month.toString() + day.toString();

        const formData = new FormData();
        formData.append('tipoCAE', this.tipoCAE.value);
        formData.append('cuit', this.cuit);
        formData.append('puntoVenta', this.puntoVenta.value);
        formData.append('tipoComprobante', this.tipoComprobante.value);
        formData.append('tipoFactura', this.tipoFactura.value);
        formData.append('comprobante', this.comprobante.value);
        formData.append('fechaEmisionComprobante', fechaEmisionComprobanteFormateada);
        formData.append('importeTotal', this.importeTotal.value);
        formData.append('codAutCAE', this.codAutCAE.value);
        formData.append('obraSocial', this.obraSocial.value);

        formData.forEach((value, key) => {
            console.log(JSON.stringify(key) + JSON.stringify(value))
        });

        this._cargaFactura.validateAfip(formData)
            .subscribe(resp => {
                if (resp.info == 'Solicitud Aprobada')
                {
                    this.validAfip = true;
                    this.formCarga.disable();
                    this._snackBar.open('Validado correctamente', 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                }
            },
                err => {
                    var msje = err['error']['message'];
                    this.transform(msje);
                    this._snackBar.open(msje, 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                });
    }
    
    transform(value: string): string {
      value = value.replace("v�lida", "válida");   
      value = value.replace("emisi�n", "emisión");
      value = value.replace("n�mero", "número");
      value = value.replace("N�", "N°");
      
      return value;
    }
    linkCargaComprobante () {
      window.location.href = environment.urlWebsite + '/proveedores/comprobantes/carga';
      console.log(window.location.href);
    }

    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }

    getMonthNameSpanish(month) {
        switch (month.toString()) {
            case '1':
            case '01':
                return 'Enero';
            case '2':
            case '02':
                return 'Febrero';
            case '3':
            case '03':
                return 'Marzo';
            case '4':
            case '04':
                return 'Abril';
            case '5':
            case '05':
                return 'Mayo';
            case '6':
            case '06':
                return 'Junio';
            case '7':
            case '07':
                return 'Julio';
            case '8':
            case '08':
                return 'Agosto';
            case '9':
            case '09':
                return 'Septiembre';
            case '10':
                return 'Octubre';
            case '11':
                return 'Noviembre';
            case '12':
                return 'Diciembre';
            default:
                return '';
        }
    }

    setPeriodos(){
        let date = new Date();
        let periodos = [];
        let desde = new Date;
        desde.setDate(this.FechaDesde.substring(0, 2));
        desde.setMonth(this.FechaDesde.substring(3, 5) -1);
        desde.setDate(1);
        desde.setFullYear(this.FechaDesde.substring(6, 10));
        desde.setHours(0,0,0,0);

        let hasta = new Date;
        hasta.setDate(this.FechaHasta.substring(0, 2));
        hasta.setMonth(this.FechaHasta.substring(3, 5));
        hasta.setDate(0);
        hasta.setFullYear(this.FechaHasta.substring(6, 10));
        hasta.setHours(23,59,59,0);

        for (var i = 1; i < 5; i++) {
            let dateCompare = new Date();
            dateCompare = subMonths(dateCompare, i);

            if (dateCompare < desde || dateCompare > hasta)
                continue;

            let year = dateCompare.getFullYear();
            let month = dateCompare.getMonth() + 1;
            let textMonth = this.getMonthNameSpanish(month);

            let info = {
                'year': year,
                'month': textMonth,
                'fullValue': year.toString() + month.toString(),
            };
            periodos.push(info);
        }

        this.meses = periodos;
    }

    reminder(){
        this._snackBar.open('Antes de enviar, verifique estén correctos los archivos adjuntos.', 'Cerrar', {
            duration: undefined,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }
    
}