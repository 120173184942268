import { NgModule } from "@angular/core";
import { BannerComponent } from "./banner.component";
import { MatCardModule } from "@angular/material/card";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [BannerComponent],
  imports: [MatCardModule, CommonModule],
  exports: [BannerComponent],
})
export class BannerModule {}
