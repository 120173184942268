import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NovedadesService } from 'app/services/novedades.service';
import { environment } from 'environments/environment';
import { Novedad } from 'app/models/novedad.model';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-novedad',
  templateUrl: './novedad.component.html',
  styleUrls: ['./novedad.component.scss']
})
export class NovedadComponent implements OnInit {

  env= environment;
  novedad: Novedad[] = [];

  constructor(private activatedRoute: ActivatedRoute,
              private _novedadesService: NovedadesService,
              private meta: Meta) {
                const description = this.meta.getTag('name=description');
                this.meta.removeTagElement(description);
                const keywords = this.meta.getTag('name=keywords');
                this.meta.removeTagElement(keywords);
   }


  ngOnInit(){

    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this._novedadesService.getById(id)
    .subscribe(resp => {
      this.novedad = resp;     
    },
    err => console.log(err)
    );

  }

}
