<div id="error-500" fxLayout="column" fxLayoutAlign="center center">

    <div class="content" fxLayout="column" fxLayoutAlign="center center">

        <div class="error-code">500</div>

        <div class="message">Well, you broke the internet!</div>

        <div class="sub-message">
            Just kidding, looks like we have an internal issue, please try again in couple minutes
        </div>

        <a class="report-link" [routerLink]="'/apps/dashboards/project'">Report this problem</a>

    </div>

</div>
