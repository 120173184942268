<div id="maintenance" fxLayout="column">

    <div id="maintenance-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="maintenance-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/fuse.svg">
            </div>

            <div class="title">Closed for scheduled maintenance!</div>

            <div class="subtitle">
                We're sorry for the inconvenience. <br> Please check back later.
            </div>

        </div>

    </div>

</div>
