<div class="page-layout blank p-24" fusePerfectScrollbar>

    <div class="breadcrumbs">
        <a href="/">Inicio</a> » <span>Planes</span>
    </div>

    <h1 class="page-title">Planes</h1>

    <h3>Contamos con planes pensados para ofrecerte mayor bienestar y calidad de vida.</h3>

    <div id="planes" fxLayout="row wrap" fxLayou.xs="column" fxLayoutAlign="space-between">

        <mat-card class="card" fxFlex="24%" fxFlex.xs="100%" fxFlex.sm="49%">
            <img mat-card-image src="https://www.ase.com.ar/themes/ASE/images/eco.jpg">
        
            <mat-card-header>
                <mat-card-title>
                    <h1>ECO</h1>
                </mat-card-title>                                
            </mat-card-header>
            <mat-card-actions>
                <button mat-raised-button class="vermas" [routerLink]="['eco']">Leer más</button>
            </mat-card-actions>
        </mat-card>

        <mat-card class="card" fxFlex="24%" fxFlex.xs="100%" fxFlex.sm="49%">
            <img mat-card-image src="https://www.ase.com.ar/themes/ASE/images/eco_plus.jpg">
        
            <mat-card-header>
                <mat-card-title>
                    <h1>ECO PLUS</h1>
                </mat-card-title>                
            </mat-card-header>
            <mat-card-actions>
                <button mat-raised-button class="vermas" [routerLink]="['eco-plus']">Leer más</button>
            </mat-card-actions>
        </mat-card>

        <mat-card class="card" fxFlex="24%" fxFlex.xs="100%" fxFlex.sm="49%">
            <img mat-card-image src="https://www.ase.com.ar/themes/ASE/images/vision.jpg">
        
            <mat-card-header>
                <mat-card-title>
                    <h1>VISION</h1>
                </mat-card-title>                
            </mat-card-header>
            <mat-card-actions>
                <button mat-raised-button class="vermas" [routerLink]="['vision']">Leer más</button>
            </mat-card-actions>
            
        </mat-card>

        <mat-card class="card" fxFlex="24%" fxFlex.xs="100%" fxFlex.sm="49%">
            <img mat-card-image src="https://www.ase.com.ar/themes/ASE/images/vision_plus.jpg">
        
            <mat-card-header>
                <mat-card-title>
                    <h1>VISION PLUS</h1>
                </mat-card-title>                
            </mat-card-header>
            <mat-card-actions>
                <button mat-raised-button class="vermas" [routerLink]="['vision-plus']">Leer más</button>
            </mat-card-actions>
        </mat-card>
        
    </div>

</div>

