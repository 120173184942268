<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>               
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_reintegros.svg" class="icon">
        </div>

        <div class="menu-aside">
            <a fxHide.lt-md (click)="exportarMisPagosPDF()"><mat-icon matTooltip="EXPORTAR PAGOS A PDF">picture_as_pdf</mat-icon></a>              
        </div>

    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <span *ngIf="esSABPRV"><a [routerLink]="['/consulta-mis-pagos']">Consultar Pagos</a> » </span> <span>Mis pagos</span>
        </div>        

        <h2 class="page-subtitle">Mis Pagos</h2>

        <div fxShow.lt-md fxHide.gt-sm>
            <button id="btn-exportarPDFMobile" [disabled]="empty" mat-raised-button (click)="exportarMisPagosPDF()">Exportar Pagos<mat-icon>picture_as_pdf</mat-icon></button>
        </div>        

        <mat-form-field id="filtro" fxFlex.gt-xs="50" fxFlex.xs="100" fxFlex="50" *ngIf="isDataLoaded">
          <input (keyup)="applyFilter($event)" matInput placeholder="Buscar" #input>
        </mat-form-field>

        <div *ngIf="!isDataLoaded" id="spinner">
            <mat-spinner diameter="60"></mat-spinner>
        </div>

        <div class="mat-elevation-z0 data-table" *ngIf="isDataLoaded">
            <div id="export" #export>
                <table mat-table [dataSource]="dataSource">

                    <ng-container matColumnDef="Mes">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Mes                
                    </th>
                    <td mat-cell *matCellDef="let row"> {{row.periodoFactura}}</td>
                    </ng-container>          
                    
                    <ng-container matColumnDef="Tipo de Factura">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo <br>de Comprobante                 
                    </th>
                    <td mat-cell *matCellDef="let row"> {{row.TipoFactura}}</td>
                    </ng-container>          
                    
                    <ng-container matColumnDef="Punto de Venta">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-align-right"> Punto de <br> Venta </th>
                    <td mat-cell *matCellDef="let row" class="right"> {{row.SucurFactura}} </td>
                    </ng-container>         
                    
                    <ng-container matColumnDef="Número de Factura">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-align-right"> Comprobante </th>
                    <td mat-cell *matCellDef="let row" class="right"> {{row.NroFactura}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Importe de la Factura">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-align-right"> Importe total </th>
                    <td mat-cell *matCellDef="let row" class="right"> $ {{ row.ImpFactura }}  </td>
                    </ng-container>

                    <ng-container matColumnDef="Estado">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                    <td mat-cell *matCellDef="let row"> {{row.Estado}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Número de Trámite">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-align-right"> Número de <br> Trámite </th>
                    <td mat-cell *matCellDef="let row" class="right"> {{row.InciWeb}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Número de Liquidación">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-align-right"> Número de <br> Liquidación </th>
                    <td mat-cell *matCellDef="let row" class="right"> {{row.NroReintegro}} </td>
                    </ng-container>  
                    
                    <ng-container matColumnDef="Fecha de Pago">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de<br>Pago Estimada</th>
                    <td mat-cell *matCellDef="let row"> {{row.FechaPago}} </td>
                    </ng-container>   

                    <ng-container matColumnDef="Adjuntos">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Adjuntos </th>
                    <td mat-cell *matCellDef="let row"><a (click)="verAdjuntos(row.NroFactura,row.NroReintegro,row.InciWeb,row.TipoFactura)"><mat-icon>remove_red_eye</mat-icon></a>  </td>
                    </ng-container>

                    <ng-container matColumnDef="Fecha de Carga">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha <br>de carga </th>
                    <td mat-cell *matCellDef="let row"> {{row.FchReintegro}} </td>
                    </ng-container>   
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                    <!-- Si no se encontraron resultados -->
                    <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No se encontraron resultados para "{{input.value}}"</td>
                    </tr>
                </table>
                
            </div>
            <div *ngIf="noData" id="sinPagos">
                <b>No se encontraron pagos</b>
            </div>
        
          <mat-paginator [pageSizeOptions]="[5, 10, 25]" aria-label="Selecciona"></mat-paginator>
      </div>
    </div>
</div>





