<div id="knowledgebase" class="page-layout simple full-width">

    <!-- HEADER -->
    <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="center center">

        <div class="hero-text mb-32">
            <h1>How can we help?</h1>
            <h3>Welcome to our knowledge base</h3>
        </div>

    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">

        <div fxLayout="row wrap" fxLayoutAlign="center">

            <mat-nav-list class="articles-list" *ngFor="let category of knowledgeBase">

                <h3 mat-subheader>{{category.title}}</h3>

                <a mat-list-item *ngFor="let article of category.featuredArticles"
                   (click)="readArticle(article)">
                    <mat-icon class="hint-text mr-8">insert_drive_file</mat-icon>
                    {{ article.title }}
                </a>

                <a mat-list-item class="see-all-link accent-fg" [routerLink]="category.path">
                    See all articles ({{category.articlesCount}})
                </a>

            </mat-nav-list>

        </div>

    </div>
    <!-- / CONTENT -->

</div>
