import { ConfirmarPasswordModule } from './main/pages/authentication/confirmar-password/confirmar-password/confirmar-password.module';
import { RegisterModule } from 'app/main/pages/authentication/register/register.module';
import { RegistroFormularioModule } from './main/pages/authentication/registro-formulario/registro-formulario/registro-formulario.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { HomeModule } from 'app/main/home/home.module';
import { InstitucionalModule } from 'app/main/institucional/institucional.module';
import { FilialesModule } from 'app/main/filiales/filiales.module';
import { NovedadesModule } from 'app/main/novedades/novedades.module';
import { TurismoModule } from './main/turismo/turismo.module';
import { ClubaseModule } from './main/clubase/clubase.module';
import { EjecutivocuentaModule } from './main/ejecutivocuenta/ejecutivocuenta.module';
import { NovedadModule } from './main/novedad/novedad.module';
import { PlanesModule } from './main/planes/planes.module';
import { ContactoModule } from './main/contacto/contacto.module';
import { SeccionModule } from './main/seccion/seccion.module';
import { MonotributistasModule } from './main/monotributistas/monotributistas.module';
import { PoliticasModule } from './main/politicasprivacidad/politicas/politicas.module';
import { TerminosModule } from './main/politicasprivacidad/terminos/terminos.module';
import { FormularioPdfModule } from './main/monotributistas/formulario-pdf/formulario-pdf.module';
import { PopupModule } from "./main/popup/popup.module";

import { FlexLayoutModule } from "@angular/flex-layout";
import { PrivateModule } from './main/private/private.module';
import { RolSelectModule } from './main/private/rolSelect/rolSelect.module';
import { AuthGuard } from './services/auth.guard';
import { MonotributistasComponent } from './main/monotributistas/monotributistas.component';
import { PagesModule } from './main/pages/pages.module';
import { ConfirmModule } from './main/confirm/confirm.module';
import { AuthInterceptor } from './services/auth-services/auth-interceptor.service';


const appRoutes: Routes = [
    {
        path: 'home',
        redirectTo: '/home'
    },
    {
        path: 'institucional',
        redirectTo: '/institucional'
    },
    {
        path: 'filiales',
        redirectTo: '/filiales'
    },
    {
        path: 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path:'aportes-derivados',
        loadChildren: () => import('./main/private/aportes-derivados/aportes-derivados.module').then(m => m.AportesDerivadosModule),
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: '/home'
    },
    {
        path: 'turismo',
        redirectTo: '/turismo'
    },
    {
        path: 'clubase',
        redirectTo: '/clubase'
    },
    {
        path: 'ejecutivocuenta',
        redirectTo: '/ejecutivocuenta'
    },
    {
        path: 'contacto',
        redirectTo: '/contacto'
    },
    {
        path: 'monotributistas',
        component: MonotributistasComponent, 
        canActivate: [AuthGuard]
    },
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        // fxlayout
        FlexLayoutModule,

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // FxLayout
        FlexLayoutModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,
        HomeModule,
        InstitucionalModule,
        FilialesModule,
        NovedadesModule,
        TurismoModule,
        ClubaseModule,
        EjecutivocuentaModule,
        PlanesModule,
        ContactoModule,
        NovedadModule,
        SeccionModule,
        MonotributistasModule,
        PoliticasModule,
        TerminosModule,
        FormularioPdfModule,
        PopupModule,
        RegisterModule,
        RegistroFormularioModule,
        ConfirmarPasswordModule,

        // private module
        PrivateModule,
        RolSelectModule,
        PagesModule,
        ConfirmModule
       
        
    ],
    exports: [RouterModule],
    providers: [AuthGuard, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
