
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { LoginService } from 'app/services/login.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FormularioPassword } from './../../../../../models/formularioPassword.model';
import { FormularioPDFService } from 'app/services/formulario-pdf.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-confirmar-password',
  templateUrl: './confirmar-password.component.html',
  styleUrls: ['./confirmar-password.component.scss']
})
export class ConfirmarPasswordComponent implements OnInit {


  formPassword : FormGroup;
  FormularioPassword: FormularioPassword = {} as FormularioPassword;

  usuario = new FormControl('');
  password = new FormControl('', [Validators.required]);
  password2 = new FormControl('', [Validators.required]);

  isLogged: boolean = false;
  datos :any;
  isPublic: boolean = true;
  haveData: boolean = false;
  hash : string;
  cuil :any;
  proveedor: any = 0;

  constructor(private _formBuilder: FormBuilder,private _loginService: LoginService,private _changeDetectorRef: ChangeDetectorRef,
    private _formPDFService: FormularioPDFService,private _snackBar: MatSnackBar, private _route: ActivatedRoute) { 

  }

  ngOnInit(): void {

    this.formPassword = new FormGroup({
        usuario: this.usuario,
        password: this.password,
        password2: this.password2,
        });

    this._route.queryParams
    .subscribe(params => {
        this.hash = params.hash;
    })

    this._route.queryParams
    .subscribe(params => {
        if (params.proveedor !== undefined && params.proveedor == 1)
        this.proveedor = params.proveedor;
    })
    this._loginService.getCuil(this.hash)
    .subscribe(resp => {
        this.FormularioPassword.usuario = resp;
    })
    
  }

  reload(): void{
    setTimeout(function(){
      window.location.reload();
    },6000);
  }

  onSubmit(): void {

    this.FormularioPassword.usuario   = this.FormularioPassword.usuario;
    this.FormularioPassword.password  = this.password.value;
    this.FormularioPassword.password2 = this.password2.value;
    this.FormularioPassword.proveedor = this.proveedor;


    this._formPDFService.sendFormularioPassword(this.FormularioPassword)
    .subscribe(resp => {
      this.formPassword.markAllAsTouched();
      if (!this.formPassword.valid){
         this._snackBar.open('Recordá que debes completar todos los campos correctamente ', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        return;
      }
      else 
      {
      this._snackBar.open('El registro ha sido completado con éxito. Por favor inicie sesión con su usuario y contraseña.', 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
        this.reload();
    }
    },
    err => {
        this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.reload();
    });    

  }
}
