<div class="page-layout blank p-24" fusePerfectScrollbar>

   
    <div fxFlex="20" fxHide.xs class="aside-list">        
            <img src="assets/images/iconos/icono_altausuarios.svg">        
    </div>

    <div fxFlex="80" fxFlex.xs="100" fxLayout.xs="column"> 

        <div class="breadcrumbs" fxFlex.xs="100"> 
            <a [routerLink]="['/home']">Inicio</a> » <span>Registro de Usuario</span>
        </div>

        <div class="" fxFlex.xs="100">
            
            <div class="form-password">
                <h1>Bienvenido/a</h1>
                <p>Por favor, complete los siguientes datos para finalizar el registro. En el nombre de usuario debe ingresar su número de CUIL o CUIT</p>
                <form action="" method="post">
                    <span id="tipo">
                        <div class="tw">
                            <mat-form-field class="w40">
                                <mat-label>Usuario*</mat-label>
                                <input readonly matInput [formControl]="usuario" value='{{ FormularioPassword.usuario }}'>
                            </mat-form-field>
                        </div>
                        <div class="tw">
                            <mat-form-field class="w40">
                                <mat-label>Contraseña</mat-label>
                                <input type="password" matInput [formControl]="password" required>
                                <mat-error *ngIf="password.invalid">Debe ingresar contraseña</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="tw">
                            <mat-form-field class="w40">
                                <mat-label>Confirmar Contraseña</mat-label>
                                <input type="password" matInput [formControl]="password2" required>
                                <mat-error *ngIf="password.invalid">Confirme su contraseña</mat-error>
                            </mat-form-field>
                        </div>
                    </span><br>
                </form>
                <button (click)="onSubmit()" class="btn-continuar submit-button ng-tns-c392-13 mat-raised-button mat-button-base">
                    <span class="mat-button-wrapper"> Continuar </span>
                </button>
            </div>
        </div>
    </div>
    

</div>