import { Component } from '@angular/core';
import { PopupFooterComponent } from './popup-footer/popup-footer.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})
export class FooterComponent
{
    date = new Date();
    year = this.date.getFullYear();
    
    /**
     * Constructor
     */
    constructor(public dialog: MatDialog)
    {
    }

    openPopup(){
        this.dialog.open(PopupFooterComponent); // popup
    }
    
    

}
