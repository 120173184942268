import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CambiarEmailDialogComponent } from './cambiar-email-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

const routes = [
  {
      path     : 'cambiar-email-dialog',
      component: CambiarEmailDialogComponent
  }
];

@NgModule({
  declarations: [CambiarEmailDialogComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatButtonModule
  ]
})
export class CambiarEmailDialogModule { }
