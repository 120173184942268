import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { CargaComprobantesService } from '../../../../../services/carga-comprobantes.service';
import { LoginService } from '../../../../../services/login.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { endOfMonth, startOfMonth, subMonths } from 'date-fns';
@Component({
    selector: 'app-carga-comprobantes',
    templateUrl: './carga-comprobantes.component.html',
    styleUrls: ['./carga-comprobantes.component.scss']
})

export class CargaComprobantesComponent implements OnInit {
    prestaciones = [];
    empty = true;
    filter = '';
    isDataLoaded= true;
    displayedColumns: string[] = ['Beneficiario', 'Prestación', 'Periodo desde', 'Periodo hasta', 'Cantidad de sesiones/Km', 'Estado', 'Exportar', 'Carga de factura', 'Ver detalle'];    
    dataSource: MatTableDataSource<any>;
    esSABPRV= false;
    SABCUIT; 
    
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('export') exportElement!: ElementRef;

    constructor(private _cargaComprobantes: CargaComprobantesService,
        private router: Router,
        private getSessionData: LoginService,
        private activatedRoute: ActivatedRoute,        
    ) {   
        // recibo el cuit desde consulta de prestaciones desde un rol SAB Proveedor (ASEPRV) 
        this.SABCUIT = history.state.consultaCUIT;
        
        if(this.SABCUIT) 
            this.esSABPRV = true;
        if(! this.SABCUIT ) {
          this.activatedRoute.queryParams.subscribe((params) => {
            this.SABCUIT = params["cuit"];
            /* if (!this.SABCUIT )
              this.router.navigate(['proveedores/comprobantes/carga']); */
          });
        }
        
        this.getSessionData.getSessionData().subscribe((result) => {
          if (result["rol"] || result["rol"] == "PRVGRL" || result["rol"] == "ASEPRV") {
            if (result["rol"] != "ASEPRV" && result['rol'] == 'PRVGRL')              
                result.cuit;
            else {
              result.cuit = this.SABCUIT;           
              this.esSABPRV = true;
            }

            this._cargaComprobantes.getAllPrestaciones(result.cuit).subscribe((data) => {                
                this.prestaciones = data;
                if (this.prestaciones[0]) this.empty = false;
                this.dataSource = new MatTableDataSource<any>(data);
                this.isDataLoaded = true;
                this.dataSource.paginator = this.paginator;
            });
          } else {            
            this.router.navigate(["/"]);
          }
        });   
        console.log(this.esSABPRV);
      }    
    
    ngOnInit(): void {        
        this.getSessionData.getAll().subscribe(resp => {
            if(!resp['isLogged'])
                this.router.navigate(['/login'])
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        let newSource = []
        this.prestaciones.forEach(function (prestacion) {

        if (filterValue != '' &&
                !prestacion['BenTNomApe'].toString().toLowerCase().includes(filterValue) &&
                !prestacion['Prestacion'].toString().toLowerCase().includes(filterValue) &&
                !prestacion['Prestador'].toString().toLowerCase().includes(filterValue) &&
                !prestacion['FechaDesde'].toString().toLowerCase().includes(filterValue) &&
                !prestacion['FechaHasta'].toString().toLowerCase().includes(filterValue) &&
                !prestacion['CantidadYTipo'].toString().toLowerCase().includes(filterValue) &&
                !prestacion['Estado'].toString().toLowerCase().includes(filterValue)
            )
                return;

            newSource.push(prestacion);
        })

        this.dataSource = new MatTableDataSource(newSource);
        this.dataSource.paginator = this.paginator;
    }

    // tslint:disable-next-line: typedef
    exportarPrestacion(index) {
        var content = [];
        for (var i in this.prestaciones) {
            if (this.prestaciones[i].Index === index) {
                var reg = [
                    this.prestaciones[i]['BenTNomApe'],
                    this.prestaciones[i]['Prestacion'],
                    this.prestaciones[i]['Prestador'],
                    this.prestaciones[i]['FechaDesde'],
                    this.prestaciones[i]['FechaHasta'],
                    this.prestaciones[i]['CantidadYTipo'],
                    this.prestaciones[i]['Estado'],
                ];

                content.push(reg);
            }
        }
        const doc = new jsPDF('landscape');
        var img = new Image();
        img.src = 'assets/images/logos/logo_ase.png'
        doc.addImage(img, 'png', 10, 10, 44, 15);

        autoTable(doc, {
            headStyles: { halign: 'center', fillColor: '#e75300', textColor: '#ffffff' },
            startY: 30,
            head: [["CONSULTA DE PRESTACIONES"]],
            body: [],
        })

        autoTable(doc, {
            theme: 'grid',
            startY: 40,
            headStyles: { halign: 'center', fillColor: '#e75300', textColor: '#ffffff' },
            head: [['Beneficiario', 'Prestación', 'Prestador', 'Desde', 'Hasta', 'Sesiones/Km', 'Estado']],
            body: content,
        })

        var date = new Date()
        autoTable(doc, {
            headStyles: { halign: 'right', fillColor: '#ffffff', textColor: '#000000' },
            head: [["Fecha: " + date.toLocaleDateString()]],
            body: [],
        })

        doc.setFontSize(10);
        var str = "Se deja constancia que la información de la presenta plantilla se encuentra actualizada a la última\n fecha de cruce de datos entre la entidad sanitaria y la Administración General de Ingresos Públicos, S.E.U.O.";
        str = str + "\nAcción Social de Empresarios | Lima 87 8° piso - Tel 4124 2800 | Buenos Aires - (C1073AAA)";
        str = str + "\n0810 3333 273 | www.ase.com.ar"
        str = str + "\nSuperindentendencia de Servicios de Salud - Órgano de Control de Obras Sociales y Entidades de Medicinas\n Prepagas 0800 222 SALUD (72583) www.sssalud.gov.ar | R.N.O.S. 400909"
        str = str + "\nPágina " + (doc as any).internal.getNumberOfPages();

        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
        doc.text(str, 150, pageHeight - 26, {align: 'center'});
        doc.save('Prestaciones Proveedor.pdf');

        doc.save('Prestaciones Proveedor.pdf');
    }

    exportarPrestacionesPDF() {
        if (this.empty)
            return;

        var content = [];
        for (var i in this.prestaciones) {
            if (this.filter != '' &&
                !this.prestaciones[i]['BenTNomApe'].toString().toLowerCase().includes(this.filter) &&
                !this.prestaciones[i]['Prestacion'].toString().toLowerCase().includes(this.filter) &&
                !this.prestaciones[i]['Prestador'].toString().toLowerCase().includes(this.filter) &&
                !this.prestaciones[i]['FechaDesde'].toString().toLowerCase().includes(this.filter) &&
                !this.prestaciones[i]['FechaHasta'].toString().toLowerCase().includes(this.filter) &&
                !this.prestaciones[i]['CantidadYTipo'].toString().toLowerCase().includes(this.filter) &&
                !this.prestaciones[i]['Estado'].toString().toLowerCase().includes(this.filter)
            )
                continue;
                
            var reg = [
                this.prestaciones[i]['BenTNomApe'],
                this.prestaciones[i]['Prestacion'],
                this.prestaciones[i]['Prestador'],
                this.prestaciones[i]['FechaDesde'],
                this.prestaciones[i]['FechaHasta'],
                this.prestaciones[i]['CantidadYTipo'],
                this.prestaciones[i]['Estado'],
            ];

            content.push(reg);
        }

        const doc = new jsPDF('landscape');
        var rowIndex = 0;
        var pdfContent = [];
        for (var j in content)
        {
            pdfContent.push(content[j]);
            rowIndex++;
            if (rowIndex % 10 == 0 || rowIndex == content.length)
            {
                var img = new Image()
                img.src = 'assets/images/logos/logo_ase.png'
                doc.addImage(img, 'png', 10, 10, 44, 15);

                autoTable(doc, {
                    headStyles: { halign: 'center', fillColor: '#e75300', textColor: '#ffffff' },
                    startY: 30,
                    head: [["CONSULTA DE PRESTACIONES"]],
                    body: [],
                })

                autoTable(doc, {
                    theme: 'grid',
                    startY: 40,
                    headStyles: { halign: 'center', fillColor: '#e75300', textColor: '#ffffff' },
                    head: [['Apellido y Nombre', 'Prestación', 'Prestador', 'Desde', 'Hasta', 'Cantidad de sesiones/Km', 'Estado']],
                    body: pdfContent,
                })

                if (rowIndex == content.length)
                {
                    var date = new Date()
                    autoTable(doc, {
                        headStyles: { halign: 'right', fillColor: '#ffffff', textColor: '#000000' },
                        head: [["Fecha: " + date.toLocaleDateString()]],
                        body: [],
                    })
                }

                doc.setFontSize(10);       
                var str = "Se deja constancia que la información de la presenta plantilla se encuentra actualizada a la última\n fecha de cruce de datos entre la entidad sanitaria y la Administración General de Ingresos Públicos, S.E.U.O.";
                str = str + "\nAcción Social de Empresarios | Lima 87 8° piso - Tel 4124 2800 | Buenos Aires - (C1073AAA)";
                str = str + "\n0810 3333 273 | www.ase.com.ar"
                str = str + "\nSuperindentendencia de Servicios de Salud - Órgano de Control de Obras Sociales y Entidades de Medicinas\n Prepagas 0800 222 SALUD (72583) www.sssalud.gov.ar | R.N.O.S. 400909"
                str = str + "\nPágina " + (doc as any).internal.getNumberOfPages();

                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height
                ? pageSize.height
                : pageSize.getHeight();
                doc.text(str, 150, pageHeight - 26, {align: 'center'});

                pdfContent = [];
                if (rowIndex < content.length)
                    doc.addPage();
            }
        }
        doc.save('Prestaciones Proveedor.pdf');
    }

    cargaFactura(Prestacion,Index, BenTNomApe, BenNroAse, BenNroOrd, DiscaExAnio, DiscaTramSec, FechaDesde, FechaHasta, Cantidad, TipoCantidad, NumeroDocumento) {
        this.router.navigate(['proveedores/comprobantes/carga/carga-comprobante'], { queryParams: { index: Index, beneficiarioName: BenTNomApe, prestacion: Prestacion },
           state: { BenTNomApe, BenNroAse, BenNroOrd, DiscaExAnio, DiscaTramSec,FechaDesde, FechaHasta, Cantidad, TipoCantidad, NumeroDocumento }
        });
    }

    // tslint:disable-next-line: typedef
    verDetalle(Index, BenTNomApe, DiscaExAnio, Prestacion, Prestador, Estado, Observaciones, Documentos) {
        this.router.navigate(['proveedores/comprobantes/carga/detalle'], { queryParams: {index: Index, cuit: this.SABCUIT},
            state: {  BenTNomApe, DiscaExAnio, Prestacion, Prestador, Estado, Observaciones, Documentos, }
        });

    }

    // tslint:disable-next-line: typedef
    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }

    puedeCargar(fechaDesde, fechaHasta){
        let date =  new Date;
        // Ultimo dia del mes anterior
        date = endOfMonth(subMonths(date, 1))
        let dateclone = new Date(date.getTime());

        //Primer dia 4 meses atras
        dateclone = startOfMonth(subMonths(dateclone, 3));

        let desde = new Date;
        desde.setDate(fechaDesde.substring(0, 2));
        desde.setMonth(fechaDesde.substring(3, 5) -1);
        desde.setFullYear(fechaDesde.substring(6, 10));

        let hasta = new Date;
        hasta.setDate(fechaHasta.substring(0, 2));
        hasta.setMonth(fechaHasta.substring(3, 5) -1);
        hasta.setFullYear(fechaHasta.substring(6, 10));

        date.setHours(0,0,0,0);
        dateclone.setHours(23,59,59,0);

        if (date >= desde && date <= hasta)
            return true;
        if (dateclone >= desde && dateclone <= hasta)
            return true;
        if (desde >= dateclone && desde <= date)
            return true;
        if (hasta >= dateclone && hasta <= date)
            return true;

        return false;
    }
}