import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-grilla-prestaciones-aprobadas-rechazadas',
  templateUrl: './grilla-prestaciones-aprobadas-rechazadas.component.html',
  styleUrls: ['./grilla-prestaciones-aprobadas-rechazadas.component.scss']
})
export class GrillaPrestacionesAprobadasRechazadasComponent implements OnInit {
  @Input() listPrestacionesRechasadasAprobadas: any;
  columndefs:any = ['Prestacion', 'Prestador', 'Desde','Hasta', 'Sesiones','Estado', 'Link'];
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  irADetalle(idPrestacion: any){
    this.router.navigate([`discapacidad/prestaciones/detalles-prestacion`], {queryParams: {id: idPrestacion}})
  }

}
