import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Seccion } from 'app/models/seccion.model';

@Injectable({
  providedIn: 'root'
})
export class SeccionesService {

  constructor(
    private http: HttpClient
  ) { }

  getHome(): Observable<Seccion[]>{
    return this.http.get<Seccion[]>(environment.urlApi + 'secciones/getHome');
  }

  getById(id): Observable<Seccion[]>{
    return this.http.get<Seccion[]>(environment.urlApi + `secciones/getById?id=${id}`);
  }
}
