import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MisdatosProveedorService } from 'app/services/misdatos-proveedor.service';
import { Router, ActivatedRoute } from '@angular/router';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'app-cambiar-email-dialog',
  templateUrl: './cambiar-email-dialog.component.html',
  styleUrls: ['./cambiar-email-dialog.component.scss'],
})
export class CambiarEmailDialogComponent implements OnInit {

  cambiarEmail: FormGroup;
  matcher = new MyErrorStateMatcher();
  @Input() cuit: string;
  SABCUIT = this.data.cuit; // cuit que llega desde cambiar-email-proveedor
  formActualizarDatos: FormGroup;


  constructor(
    public dialogRef: MatDialogRef<CambiarEmailDialogComponent>,
    private misdatosProveedorService: MisdatosProveedorService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: { cuit: string }
  ) {
    if (!this.SABCUIT)
      this.activatedRoute.queryParams.subscribe((params) => {
        this.SABCUIT = params['cuit'];
      });
  }

  correoElectronico = new FormControl('', [
    Validators.required,
    Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$'),
  ]);
  correoElectronico2 = new FormControl('', [
    Validators.required,
    Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$'),
  ]);

  ngOnInit(): void {
    console.log('llega el cuit? ' + this.SABCUIT);

    this.cambiarEmail = new FormGroup(
      {
        correoElectronico: this.correoElectronico,
        correoElectronico2: this.correoElectronico2,
      },
      { validators: this.validateEqualsMails }
    );

    this.cambiarEmail.get('correoElectronico2').valueChanges.subscribe(() => {
      const correo1 = this.cambiarEmail.get('correoElectronico').value;
      const correo2 = this.cambiarEmail.get('correoElectronico2').value;

      if (correo1 === correo2) {
        this.cambiarEmail.get('correoElectronico2').setErrors(null);
      } else {
        this.cambiarEmail
          .get('correoElectronico2')
          .setErrors({ notSame: true });
      }
      this.cambiarEmail.updateValueAndValidity();

    });
  }

  validateEqualsMails(control: AbstractControl): { [key: string]: any } | null {
    const correo1 = control.get('correoElectronico')?.value;
    const correo2 = control.get('correoElectronico2')?.value;

    if (correo1 !== correo2) {
      return { notSame: true };
    }
    return null;
  }

  sendFormulario() {
    const formData = new FormData();
    var email = this.correoElectronico2.value;
    formData.append('email', email);
    formData.append('cuit', this.SABCUIT);
    this.cambiarEmail.markAllAsTouched();
    if (!this.cambiarEmail.valid) {
      this._snackBar.open(
        'Recordá que debes completar todos los campos correctamente ',
        'Cerrar',
        {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
      return;
    }
    this.misdatosProveedorService.updateEmailProveedorBySAB(formData).subscribe(
      (rsp) => {},
      (err) => {
        this._snackBar.open(
          'Ha ocurrido un error: ' + err.error['message'],
          'Cerrar',
          {
            duration: undefined,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          }
        );
      }
    );
    this.dialogRef.close(email);
  }


  btnCancelar() {
    this.dialogRef.close();
  }
  
}
