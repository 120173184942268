<div id="about" class="p-24" fxLayout="row wrap">

    <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="65">

        <div class="profile-box info-box general" fxLayout="column">

            <header class="accent">
                <div class="title">General Information</div>
            </header>

            <div class="content">
                <div class="info-line">
                    <div class="title">Gender</div>
                    <div class="info">{{about.general.gender}}</div>
                </div>

                <div class="info-line">
                    <div class="title">Birthday</div>
                    <div class="info">{{about.general.birthday}}</div>
                </div>

                <div class="info-line">
                    <div class="title">Locations</div>
                    <div class="info location" fxLayout="row" fxLayoutAlign="start center"
                         *ngFor="let location of about.general.locations">
                        <span>{{location}}</span>
                        <mat-icon class="s-16 ml-4">location_on</mat-icon>
                    </div>
                </div>

                <div class="info-line">
                    <div class="title">About Me</div>
                    <div class="info">{{about.general.about}}</div>
                </div>
            </div>

        </div>

        <div class="profile-box info-box work" fxLayout="column">

            <header class="accent">
                <div class="title">Work</div>
            </header>

            <div class="content">
                <div class="info-line">
                    <div class="title">Occupation</div>
                    <div class="info">{{about.work.occupation}}</div>
                </div>

                <div class="info-line">
                    <div class="title">Skills</div>
                    <div class="info">{{about.work.skills}}</div>
                </div>

                <div class="info-line">
                    <div class="title">Jobs</div>
                    <table class="info jobs">
                        <tr class="job" *ngFor="let job of about.work.jobs">
                            <td class="company">{{job.company}}</td>
                            <td class="date">{{job.date}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div class="profile-box info-box contact" fxLayout="column">

            <header class="accent">
                <div class="title">Contact</div>
            </header>

            <div class="content">
                <div class="info-line">
                    <div class="title">Address</div>
                    <div class="info">{{about.contact.address}}</div>
                </div>
                <div class="info-line">
                    <div class="title">Tel.</div>
                    <div class="info" *ngFor="let tel of about.contact.tel">
                        <span>{{tel}}</span>
                    </div>
                </div>
                <div class="info-line">
                    <div class="title">Website</div>
                    <div class="info" *ngFor="let website of about.contact.websites">
                        <span>{{website}}</span>
                    </div>
                </div>
                <div class="info-line">
                    <div class="title">Emails</div>
                    <div class="info" *ngFor="let email of about.contact.emails">
                        <span>{{email}}</span>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="about-sidebar" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="35">

        <div class="profile-box friends" fxLayout="column">

            <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="title">Friends</div>
                <div class="more secondary-text">
                    <span>See 454 more...</span>
                </div>
            </header>

            <div class="content" fxLayout="row wrap">
                <div class="friend" fxFlex="20" *ngFor="let friend of about.friends">
                    <img [src]="friend.avatar">
                </div>
            </div>

        </div>

        <div class="profile-box groups" fxLayout="column">

            <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="title">Joined Groups</div>
                <div class="more secondary-text">
                    <span>See 6 more...</span>
                </div>
            </header>

            <div class="content">

                <div class="group" *ngFor="let group of about.groups" fxLayout="row"
                     fxLayoutAlign="space-between center">

                    <div fxLayout="row" fxLayoutAlign="start center">

                        <div class="logo">{{group.name.substring(0,1)}}</div>

                        <div>
                            <div class="name">{{group.name}}</div>
                            <div class="category">{{group.category}}</div>
                            <div class="members">{{group.members}} people</div>
                        </div>
                    </div>

                    <button mat-icon-button aria-label="More">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </div>
            </div>

        </div>

    </div>

</div>
