<div class="page-layout page-layout-water-mark blank p-24" fusePerfectScrollbar>

    <div class="breadcrumbs">
        <a href="/">Inicio</a> » <span>Información Institucional</span>
    </div>

    <h1 class="page-title">Información Institucional</h1>

    <p>Desde nuestros comienzos, en el año 1977, nos constituimos como una organización prestadora de servicios de salud, que administra aportes y contribuciones del personal de dirección de empresas, brindando cobertura médica en cualquier parte del país a través de las mejores&nbsp;&nbsp;redes de prestadores, asegurando calidad y compromiso.</p>
    <p>Pasaron muchos años desde nuestro nacimiento. Hoy, con la experiencia y trayectoria lograda, nos posicionamos como una obra social con una marcada orientación a la satisfacción de nuestros beneficiarios.</p>
    <p>Porque sabemos lo importante que es el personal jerárquico de su empresa y conocemos cómo protegerlo y garantizar su bienestar, ofrecemos una propuesta adaptada a sus necesidades. Nuestro sistema permite la libre elección entre&nbsp;&nbsp;redes de prestadores&nbsp; de reconocido prestigio, junto a las cuales, trabajamos para ofrecerles las mejores prestaciones médico-asistenciales y una amplia gama de beneficios complementarios.</p>
    <p>Nuestro directorio se encuentra integrado por representantes de las principales empresas del país, quienes dan garantía del correcto uso de los aportes y contribuciones administrados y de los resultados operativos.</p>
    <p>Somos una obra social de personal de dirección, nacional, sólida y dinámica.</p>

    <h2 class="page-subtitle">Misión</h2>
    <p>Brindar al Personal de Dirección el acceso a la más amplia y calificada oferta de redes prestacionales del sistema privado de salud, garantizando la plena cobertura de los beneficios establecidos para los beneficiarios de las Obras Sociales, basándonos en una organización ágil y eficiente que cubra, en forma adecuada, la totalidad del territorio nacional.</p>
    
    <h2 class="page-subtitle">Visión</h2>
    <p>Ser líder entre las Obras Sociales del Personal de Dirección que brinde a sus beneficiarios libre elección de redes prestacionales privadas. Fundamentando este liderazgo en nuestra superior eficiencia y calidad de servicios, que nos haga la elegida por beneficiarios, empresas y redes prestacionales de las empresas de medicina prepaga.</p>

</div>