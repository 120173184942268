<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">
    
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
        <div>
            <img src="assets/images/iconos/icono_medicamentos.svg" class="icon">
        </div>
    </div>
    <div fxFlex="81" class="content" fxFlex.xs="100" fxFlex.lt-md="100" fxLayout="column">
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a [routerLink]="['/medicamentos']">Medicamentos</a> » <span>Preguntas frecuentes</span>
        </div>

        <h2 class="page-subtitle">Preguntas frecuentes</h2>

        <p>Seleccioná la pregunta que deseás consultar:</p>

        <mat-accordion hideToggle id="accordion">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p class="listed-link">¿Cómo realizar un pedido?</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Una vez que hayas consultado tu cobertura en la sección <a (click)="miPlan()">MI PLAN</a> -> MEDICAMENTOS y descargado la documentación, ingresá a <a [routerLink]="['/solicitud-medicamentos']">SOLICITUD DE MEDICAMENTOS E INSUMOS</a> y envianos tu pedido. 
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p class="listed-link">¿Cuáles son los plazos del pedido?</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Podrás visualizar tu pedido a partir de las 48hs hábiles de que lo hayas realizado. 
                    Posteriormente a este plazo, dentro de las siguientes 48hs hábiles Auditoría Médica evalúa la solicitud.  
                    Una vez autorizado, la droguería te contacta dentro de las próximas 48hs hábiles para coordinar la entrega. 
                    Por este motivo, te solicitamos hacer tus pedidos 10 días antes de quedarte sin provisión/medicación (más informal).                    
                </p>
                
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p class="listed-link">¿Cómo recibo la medicación/insumos?</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Una vez coordinada la entrega, tendrás que dirigirte con la orden médica a la farmacia. Deberá estar vigente al momento de que te entreguen la medicación/insumos. 
                    Tené en cuenta que por normativa de A.N.M.A.T. no se entregan medicamentos en domicilio particular.
                    Para medicamentos por quimioterapia, la entrega se hace en la institución donde se realice el procedimiento, sin previa coordinación con el beneficiario. 
                    </p>                
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p class="listed-link">Me voy de vacaciones, ¿qué gestión debo realizar para recibir el pedido?</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ul>
                    <li><b>Si viajás dentro del país,</b> realizá tu pedido de manera habitual. Tenemos una red de farmacias amplia a nivel nacional. </li>
                    <li><b>Si viajas fuera del país</b> y necesitás adelantar tu pedido, presentá una orden médica por la cantidad de medicación/insumos que necesites, indicando la leyenda "Se adelanta pedido por viaje".</li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p class="listed-link">¿Qué documentos deben actualizarse y cada cuánto?</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Salvo estudios para arribar al diagnóstico, que no requieran volver a realizarse para el seguimiento de la enfermedad, todos los documentos tienen vigencia de 6 meses o menos. 
                    El consentimiento informado sólo debe actualizarse si se modifica la medicación. 
                    La constancia de notificación en SVNS (HIV) se presenta por única vez. 
                    </p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p class="listed-link">Si tengo un cambio de medicación o dosis, ¿cómo lo informo?</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ul>
                    <li><b>Para un cambio de medicación,</b> presentá el Consentimiento informado, protocolo de consumo mensual y orden médica. </li>
                    <li><b>Para un cambio de dosis,</b> presentá el protocolo de consumo mensual y orden médica. </li>
                </ul>
                <p class="underline">Casos particulares:</p>
                <ul>
                    <li><b>Oncológicos:</b> Resumen Historia Clínica justificando el cambio, informe de estudios de imágenes actualizados.</li>
                    <li><b>HIV:</b> Formulario Solicitud de Nuevo Esquema.
                        Auditoría Médica puede solicitar documentación adicional para evaluar el cambio. 
                    </li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p class="listed-link">¿Puedo solicitar una marca específica?</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>ASE entrega la medicación de acuerdo a Nombre Genérico de la droga, en marcas aprobadas por la entidad regulatoria que es ANMAT, lo que garantiza los estándares de calidad de esas marcas.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p class="listed-link">¿Qué pasa si un mes no hago mi pedido en la fecha indicada?</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Auditoría Médica podrá solicitar un justificativo médico para corroborar la continuidad del tratamiento.</p>
            </mat-expansion-panel>
        </mat-accordion>
 

    

</div>
