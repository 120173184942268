import { Component, OnInit } from '@angular/core';
import { SeccionesService } from '../../services/secciones.service';
import { ActivatedRoute } from '@angular/router';
import { Seccion } from 'app/models/seccion.model';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-seccion',
  templateUrl: './seccion.component.html',
  styleUrls: ['./seccion.component.scss']
})
export class SeccionComponent implements OnInit {

  seccion: Seccion[] = [];

  constructor(private _seccionesService: SeccionesService, private activatedRoute: ActivatedRoute,private meta: Meta
    ) { 
      const description = this.meta.getTag('name=description');
      this.meta.removeTagElement(description);
      const keywords = this.meta.getTag('name=keywords');
      this.meta.removeTagElement(keywords);
    }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');    
    this._seccionesService.getById(id)    
    .subscribe(resp => {
      this.seccion = resp;     
    },
    err => console.log(err)
    );
  }

}
