import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { LoginService } from 'app/services/login.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-medicamentosIndex',
    templateUrl: './medicamentosIndex.component.html',
    styleUrls: ['./medicamentosIndex.component.scss']
})
export class MedicamentosIndexComponent implements OnInit {

    constructor(private _loginService: LoginService,
        private router: Router) { }

    ngOnInit(): void {
        this._loginService.getAll().subscribe(resp => {
            if(!resp['isLogged'])
                this.router.navigate(['/login'])
        });
    }

  miPlan(){
    window.location.href = environment.urlWebSitePhp + 'planes/verPlanesB';
  }

  linkPanel(){
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  }

    btnEstado() {
        window.location.href = environment.urlWebSitePhp + 'medicamentos/ctaSolicitudes';
    }

}
