import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrestacionesAEnviarService } from '../../services/prestacion-aenviar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormPrestacionService } from '../../services/form-prestacion.service';
@Component({
  selector: 'app-prestaciones-aenviar',
  templateUrl: './prestaciones-aenviar.component.html',
  styleUrls: ['./prestaciones-aenviar.component.scss']
})
export class PrestacionesAEnviarComponent implements OnInit {

  @Input() prestacionesAEnviar= 0;
  @Input() userData: any;
  enviandoPrestaciones: boolean = false;
  @Input() cantidadDocBasica: any;
  @Input() docBasicaRequerida: any;
  @Input() cuilPrestacion: any;
  @Input() nroOrden: any;
  @Input() nroBeneficiario: any;

  constructor(private prestacionesAEnviarService: PrestacionesAEnviarService, private router:Router, 
    private _snackBar: MatSnackBar, private formPrestacionService: FormPrestacionService) { }

  ngOnInit(): void {
  }

  sendPrestacionesCompletas(){
    this.enviandoPrestaciones = true;
    const sessionId = this.userData.sessionId;
    const cuil = this.cuilPrestacion;
    
    this.prestacionesAEnviarService.sendPrestacionesCompletas(sessionId, cuil, this.nroBeneficiario, this.nroOrden)
    .subscribe(
      (response)=>{
        this.router.navigate(['/discapacidad/prestaciones/solicitud-enviada'], {queryParams: {incidencia: response.id} });
      },
      (error)=>{
        this._snackBar.open('Ocurrió un error al enviar las prestaciones completas', 'Aceptar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.enviandoPrestaciones = false;
      })
  }

  convertirNumeroAPalabras(numero: number): string {
    const unidades = ['cero', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
    const especiales = ['diez', 'once', 'doce', 'trece', 'catorce', 'quince', 'dieciséis', 'diecisiete', 'dieciocho', 'diecinueve'];
    const decenas = ['veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'];

    if (numero < 10) {
      return unidades[numero];
    } else if (numero < 20) {
      return especiales[numero - 10];
    } else if (numero < 30) {
      const unidad = numero % 10;
      return 'veinti' + unidades[unidad];
    } else {
      const decena = Math.floor(numero / 10);
      const unidad = numero % 10;
      if (unidad === 0) {
        return decenas[decena - 2];
      } else {
        return decenas[decena - 2] + ' y ' + unidades[unidad];
      }
    }
  }

  getEstadoDocumentacionListaEnviar(){
    return this.formPrestacionService.documentacionListaParaEnviar;
  }


}
