<div class="page-layout blank p-24" fusePerfectScrollbar>

    <div fxFlex="20" fxHide.xs class="aside-list">        
        <img src="assets/images/iconos/icono_altausuarios.svg">        
    </div>

    <div fxFlex="80" fxFlex.xs="100" fxLayout.xs="column">

        <div class="breadcrumbs">
            <a href="/">Inicio</a> » <span>REGISTRO DE USUARIO</span>
        </div>

        <h1 class="page-title">REGISTRO</h1>
        
        <p>Por favor, completá el siguiente formulario para generar su usuario:</p>
    
    
        <form #form name="" [formGroup]="formRegistro" action="" method="POST" novalidate>
        
            <div fxLayout="row" fxLayout.xs="column">
                
            
                    <mat-form-field class="mat-fullwidth" fxFlex="49" fxFlex.xs="100">
                        <mat-label>CUIL</mat-label>
                        <input placeholder="Ejemplo 20260001116" matInput  [formControl]="cuil" required maxlength="11" oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)">
                        <mat-error *ngIf="cuil.invalid ">Debe ingresar el CUIL.</mat-error>                        
                        <mat-error *ngIf="cuil.hasError('cuit') ">{{ cuil.errors.cuit.value }}</mat-error>
                    </mat-form-field>
 
                    <mat-form-field class="mat-fullwidth" fxFlex="49" fxFlex.xs="100"> 
                        <mat-label>FECHA DE NACIMIENTO</mat-label>
                        <input matInput [matDatepicker]="fecha_de_nacimiento_picker" 
                        [formControl]="fecha_de_nacimiento" [max]="max18" [min]="min100" required readonly="readonly"
                        name="fecha_de_nacimiento">
                        <mat-datepicker-toggle matSuffix [for]="fecha_de_nacimiento_picker"></mat-datepicker-toggle>
                        <mat-datepicker #fecha_de_nacimiento_picker></mat-datepicker>
                        <mat-error *ngIf="fecha_de_nacimiento.invalid">Debe seleccionar la fecha de alta en ASE.</mat-error>
                    </mat-form-field>  
                
            </div>        
            <div fxLayout="row" fxLayout.xs="column">
                   
                    <mat-form-field class="mat-fullwidth" fxFlex="49" fxFlex.xs="100">
                        <mat-label>EMAIL</mat-label>
                        <input matInput [formControl]="correoElectronico" required>
                        <mat-error *ngIf="correoElectronico.invalid && correoElectronico.hasError('required')">Debe ingresar su email.</mat-error>
                        <mat-error *ngIf="correoElectronico.invalid && correoElectronico.hasError('pattern')">Ingrese un email válido.</mat-error>
                    </mat-form-field>           
            
                    <mat-form-field class="mat-fullwidth" fxFlex="49" fxFlex.xs="100">
                        <mat-label>REINGRESE MAIL</mat-label>
                        <input matInput [formControl]="correoElectronico2" required>
                        <mat-error *ngIf="correoElectronico.invalid && correoElectronico2.hasError('required')">Debe ingresar su email.</mat-error>
                        <mat-error *ngIf="correoElectronico.invalid && correoElectronico2.hasError('pattern')">Ingrese un email válido.</mat-error>
                    </mat-form-field>

            </div>           
                
            

            <div fxLayout="column" class="submit-section">

                <div fxFlex="100">
                    <re-captcha (resolved)="captchaResolved($event)" siteKey="6Lci2cUZAAAAAKfhe3JqVTih6eDVsgbbSz_fT50X">
                    </re-captcha>
                </div>
                <div fxFlex="100">
                    <button mat-raised-button class="submit-button" aria-label="Confirmar" (click)="sendFormulario()"
                        [disabled]="!captchares && !formRegistro.invalid">
                        Crear
                    </button>
                    <button mat-raised-button type="button" id="btnCancelar" routerLink="/registro">Cancelar</button>
                </div>
            </div>
        </form>
    </div>
    
</div>