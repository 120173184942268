import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultaPrestacionesComponent } from './consulta-prestaciones.component';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

const routes = [
  {
      path     : 'consulta-prestaciones',
      component: ConsultaPrestacionesComponent
  }
];

@NgModule({
  declarations: [ConsultaPrestacionesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatButtonModule

  ]
})
export class ConsultaPrestacionesModule { }
