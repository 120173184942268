<div class="page-layout blank p-24" fusePerfectScrollbar>

    <div class="breadcrumbs">
        <a href="/">Inicio</a> » <a [routerLink]="['/planes']">PLANES</a> » <span>PLAN VISION</span>
    </div>

    <h1 class="page-title">Plan VISION</h1>

    <h4>Plan Médico Obligatorio (PMO) a cargo de la red prestadora</h4>

    <ul>
        <li>Obsequios por nacimiento y casamiento</li>
        <li>Beneficios</li>
        <li>Turismo</li>
        <li>Asesoramiento legal</li>
    </ul>

</div>
