<div class="page-layout blank p-24" fusePerfectScrollbar>

    <div class="breadcrumbs">
        <a href="/">Inicio</a> » <span>Politicas de privacidad</span>
    </div>

    <h1 class="page-title">Politicas de privacidad</h1>

    <h4>1. DECLARACIÓN GENERAL</h4>
<p>La protección de la privacidad de los beneficiarios, pacientes, proveedores y empleados y/o
colaboradores es importante para todas las acciones de OBRA SOCIAL DE ACCIÓN SOCIAL
DE EMPRESARIOS (en adelante ASE), así como la seguridad de la información relativa a
ellos. Toda información que reciba ASE de sus afiliados, pacientes, proveedores,
prestadores y empleados y/o colaboradores será debidamente resguardada, de manera tal
que no podrá comunicarse, modificarse o divulgarse públicamente, sino bajo las
condiciones y en los casos que la legislación vigente lo establezca o autorice y
consecuentemente, la misma empleará todos los medios técnicos y toma todos los
resguardos legales necesarios para asegurar la protección de los datos personales y la
privacidad de los mismos.</p>
<p>ASE realizará sus actuaciones de buena fe, basado en principios de confianza,
transparencia y brindando seguridad, con sujeción a la legislación vigente.</p>
<p>Las relaciones de ASE con sus beneficiarios, proveedores y empleados se desarrollarán en
un ambiente de cordialidad, equilibrio y armonía en cumplimiento del espíritu de esta
Política.</p>
<p>La confidencialidad se resguardará conforme a la POLITICA DE PRIVACIDAD de ASE,
siendo finalidad de la presente determinar el tratamiento que ASEdeberá brindarle a los
datos y a la información personal de sus afiliados, pacientes, proveedores, prestadores,
empleados y/o colaboradores a los fines de cumplir con las disposiciones de la legislación
vigente.</p>
<p>Para conocer más sobre los términos y definiciones que encontrará en esta política, haga
click <a [routerLink]="['/terminos']">aquí</a>.</p>
<h4>2. RECOLECCIÓN, VERIFICACIÓN, ADMINISTRACIÓN Y DESTRUCCIÓN DE DATOS PERSONALES</h4>
<p>La información que ASE recabe de sus beneficiarios, pacientes, proveedores, prestadores y
empleados y/o colaboradores, será a través de contratos y formularios de ingreso. En
cumplimiento de lo establecido en los arts. 2, 7 y 8 de la Ley 25.326, ASE no solicita
información que sea incompatible con la finalidad de sus actividades, ni que directa o
indirectamente revele datos sensibles, como ser datos que revelan origen racial y étnico,
opiniones políticas, convicciones religiosas, filosóficas o morales, afiliación sindical,
información referente a la salud o a la vida sexual, salvo aquellos datos estrictamente
necesarios para la confección de los correspondientes legajos personales de los empleados,
registros de proveedores y prestadores o en el caso de los afiliados y pacientes, aquella
información de salud solicitada en la Declaración Jurada al momento de su ingreso.</p>
<p>ASE utiliza la información de los beneficiarios, pacientes, proveedores, prestadores,
empleados y/o colaboradores a los fines de: (i) en cuanto a los empleados y/o
colaboradores, efectuar las altas en las obras sociales de los empleados y en los registros
correspondientes para el depósito de las remuneraciones y en los demás supuestos que
puedan derivar de una relación contractual y/o profesional del titular de los datos, y
resulten necesarios para su desarrollo o cumplimiento, (ii) respecto de los proveedores y
prestadores, de contar con un registro con información indispensable para la comunicación
y facturación de los servicios por este proporcionados y (iii) en cuanto a los beneficiarios y
pacientes, para el cumplimiento de la prestación de servicios de salud contratados y con
fines promocionales, de marketing y/o educativos.</p>
<p>ASE podrá contratar a terceros para llevar a cabo ciertas tareas tales como, envío de
correos postales y electrónicos, data entry, análisis estadístico de datos, entre otras. Dichos
terceros sólo contarán con el acceso a la información necesaria para cumplir con sus tareas
y funciones, no pudiendo utilizarla para otros fines.</p>
<p>En caso de cesión de datos y/o su utilización para una finalidad diferente a la mencionada,
ASE solicitará a los afiliados, pacientes, proveedores, prestadores y empleados y/o
colaboradores su consentimiento libre, expreso e informado.</p>
<h4>3. INSCRIPCIONES EN EL REGISTRO DEL ARCHIVO, REGISTRO, BASE O BANCO DE DATOS</h4>
<p>Conforme lo prevé el art. 3 de la ley 25.326, en su carácter de Responsable de las Bases de
Datos de Afiliados, Recursos Humanos y Proveedores y Prestadores, ASE ha inscripto ante
la Dirección Nacional de Protección de Datos Personales las Bases de Datos en cuestión, y
realiza anualmente las renovaciones conforme lo exige la normativa aplicable.</p>
<h4>4. CONFIDENCIALIDAD DE LOS DATOS PERSONALES</h4>
<p>En cumplimiento con el Art. 10 de la Ley 25.326, ASE no venderá, alquilará o compartirá la
información personal de beneficiarios, pacientes proveedores, prestadores, empleados y/o
colaboradores excepto en las formas establecidas en esta política.</p>
<p>Para dar cumplimiento a lo antes descripto, todos los empleados y/o colaboradores y
proveedores y prestadores de ASE, al momento de ingresar y/o prestar servicios a la
organización deberán firmar un Acuerdo de Confidencialidad, por medio del cual se
comprometen a mantener el mismo nivel de Confidencialidad y Privacidad de los datos que
mantiene ASE.</p>
<p>ASE podrá contratar a terceros para llevar a cabo ciertas tareas tales como, envío de
correos postales y electrónicos, análisis estadístico de datos, entre otras. Dichos terceros
sólo contarán con el acceso a la Información necesaria para cumplir con sus tareas y
funciones, no pudiendo utilizarla para otros fines <a id="linkToPrestaciones" (click)="toPrestaciones()">(Ver 8. Prestaciones de servicios de
    tratamiento de datos por cuenta de terceros).</a></p>
<p>ASE hará todo lo que esté a su alcance para proteger la privacidad de la información. Pero
eventualmente, puede suceder que en virtud de órdenes judiciales o de regulaciones
legales, se vea obligado a revelar información a las autoridades o terceras partes.</p>
<h4>5. SEGURIDAD DE LOS DATOS PERSONALES, APLICACIÓN DE LA DISPOSICIÓN 11/06</h4>
<p>ASE implementa todas las medidas necesarias para mantener la seguridad de la
información personal que brindan los beneficiarios, pacientes, proveedores, prestadores y
empleados y/o colaboradores, contemplando las medidas técnicas y organizativas internas
necesarias para garantizar la seguridad y confidencialidad de los datos, tratando por todos
los medios de evitar el acceso no autorizado a los mismos. Sin embargo, en razón del
estado actual de la tecnología, ASE no puede garantizar en un 100% que el acceso no
autorizado nunca ocurrirá.</p>
<p>La Gerencia de Coordinación se encarga del cumplimiento de toda la normativa relacionada
a la Seguridad de la Información, a través de las Políticas, Normas y Procedimientos
aprobados para tal fin.</p>
<h4>6. COOKIES Y WEB BEACONS</h4>
<p>Una "cookie" es un diminuto fragmento de datos que puede llegar desde el sitio web de
ASE al navegador del usuario y luego puede quedar almacenada en su disco rígido por un
período de tiempo limitado. El uso de cookies permite que ASE pueda personalizar ciertos
servicios y comunicaciones. Por esta razón, en ocasiones el sitio web de ASE, y todos los
relacionados, puede enviar cookies para que los usuarios puedan personalizar algunas
opciones, pero nunca utilizará cookies a fin de dejar registros de los contenidos elegidos
por el usuario que navegue por el sitio web.</p>
<p>En caso de requerirlo, el usuario puede modificar su programa de navegación para que
lance una advertencia en el momento en el que ingresa una cookie o bien deshabilitarlas.
Para ello deberá consultar el menú de Ayuda de su navegador.</p>
<p>Una “web beacon” es una imagen electrónica (también conocida como single-pixel, 1 x 1 o
pixel transparente) que es colocada en un sitio web y que tiene finalidades similares a las
cookies. Adicionalmente un web beacon también puede ser utilizado para medir patrones
de tráfico de un sitio a otro con el objeto de maximizar el flujo del tráfico a través de la
web. Los usuarios y visitantes de los sitios de ASE conocen y aceptan que eventualmente
ASE podrá implementar web beacons en su sitio web.</p>
<h4>7. ACTIVIDADES DE PUBLICIDAD O MARKETING DIRECTO</h4>
<p>En caso de que ASE decida efectuar una comunicación de publicidad directa no requerida o
consentida previamente por los beneficiarios, pacientes, proveedores, prestadores y
empleados y/o colaboradores, ASE le otorgará a los mismos la posibilidad de solicitar ser
retirado o bloqueado de dicha base de datos específica.</p>
<h4 id="prestaciones">8. PRESTACIONES DE SERVICIOS DE TRATAMIENTO DE DATOS POR CUENTA DE TERCEROS</h4>
<p>Conforme art. 25 de ley 25.326 y decreto 1558/2001 en caso de tratamiento de datos
personales por cuenta de terceros, ASE firmará con ellos un contrato en relación al
tratamiento el cual establezca que dicho tercero no podrá aplicar o utilizar con un fin
distinto al que figure en el contrato de servicios, ni cederlos a otras personas. Ese contrato 
también prevé que una vez cumplida la prestación contractual los datos personales
tratados deberán ser destruidos, salvo que media autorización expresa cuando se presuma
la posibilidad de ulteriores encargos, en cuyo caso se podrá almacenar con debidas
condiciones de seguridad por un periodo de hasta 10 años.</p>
<p>Dichos contratos deberán contener niveles de seguridad previstos en la ley 25.326 y
normativa complementaria, como así también las obligaciones que surgen para los
locatarios en orden a la confidencialidad y reserva que deben mantener sobre la
información obtenida.</p>
<p>Asimismo, tal contrato deberá indicar: (i) que el encargado del tratamiento sólo actúa
siguiendo instrucciones del responsable del tratamiento; (ii) que las obligaciones del
artículo 9º de la Ley Nº 25.326 incumben también al encargado del tratamiento.</p>
<h4>9. DERECHOS DE LOS TITULARES DE LOS DATOS Y PROCEDIMIENTOS PARA RESPONDER A SU
EJERCICIO</h4>
<p>Los titulares de los datos cuentan con los siguientes derechos:</p>
<ul>
<li><p><b>Derecho de Acceso:</b> El titular de los datos podrá obtener información de sus datos
personales incluidos en los bancos de datos públicos o privados destinados a proveer
informes.</p>
<p>La información solicitada se debe proporcionar dentro de los DIEZ días corridos de la
notificación.</p>
<p>Si no se satisface el pedido, quedará expedita la acción de hábeas data. El derecho de
acceso a que se refiere este artículo sólo puede ser ejercido en forma gratuita a
intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto.</p></li>
<li><p><b>Derecho de Rectificación, Actualización o Supresión de datos:</b> El responsable o
usuario del banco de datos, debe proceder a la rectificación, actualización o supresión
de los datos personales en el plazo máximo de CINCO días hábiles de recibido el
reclamo. El incumplimiento de esta obligación dentro del término acordado habilitará a
promover la acción de hábeas data.</p></li>
</ul>
<p>Si usted desea ejercer el Derecho de Acceso por favor haga <a target="blank" href="https://www.ase.com.ar/politica_privacidad/PDP-Procedimiento_Derecho_Acceso_v1.pdf">click aquí</a></p>
<p>Si usted desea ejercer el Derecho de Rectificación, Actualización o Supresión de datos por
favor haga <a target="blank" href="https://www.ase.com.ar/politica_privacidad/PDP-Procedimiento_Derecho_Actualizacion_Rectificacion_o_Supresion_de_Datos_Personales_v1.pdf">click aquí</a>.</p>
<h4>10. CAPACITACIÓN, PUBLICIDAD Y DIFUSIÓN DE LA POLÍTICA DE PRIVACIDAD</h4>
<p>ASE realiza capacitaciones frecuentes a todo empleado sobre la presente Política de
Privacidad. A efectos de acreditar tal capacitación a cada empleado que asista a la misma,
se registra la participación de un entrenamiento online o se le hace firmar una constancia
de asistencia que quedará en poder de ASE.</p>
<p>Por su parte, a efectos de hacer efectiva la publicidad y difusión de la Política de
Privacidad, ASE mantiene publicada su Política de Privacidad en la Intranet corporativa,
dentro de la sección de Políticas de RRHH. Adicionalmente, en la sección Capacitación
también se publica un documento con los lineamientos básicos que deben cumplir los
empleados para proteger los datos personales de beneficiarios, pacientes, proveedores,
prestadores y empleados y/o colaboradores.</p>

<h4>11. TERMINOS Y CONDICIONES PARA UTILIZAR APP ASE VENTAS </h4>
<p>Los presentes términos y condiciones particulares (en adelante, los “Términos y 
Condiciones”) rigen la relación entre la OBRA SOCIAL DEL PERSONAL DE 
DIRECCIÓN ACCIÓN SOCIAL DE EMPRESARIOS- ASE (en adelante ASE) y los 
ejecutivos de redes prestadoras (en adelante LAS REDES) de la aplicación 
denominada Aplicación ASE ventas, accesible para dispositivos móviles, la cual 
podrá descargarse de las tiendas de aplicaciones oficiales de Android e iOS (en 
adelante, la “Aplicación”).</p>
<p>El registro LAS REDES en la Aplicación implica la aceptación inmediata y sin 
reserva de los presentes Términos y Condiciones, quedando entendido que en 
caso de contradicción prevalecerán estos Términos y Condiciones. En 
consecuencia, LAS REDES manifiesta haber leído y aceptado en su totalidad los 
Términos y Condiciones; dicha aceptación es indispensable para la utilización de 
la Aplicación. Si no está de acuerdo con los presentes Términos y Condiciones 
rogamos no utilizar la Aplicación. </p>

<h5>A.	Descripción de la Aplicación</h5> 
<ul>
    <li><p>La Aplicación es una herramienta tecnológica que permite al vendedor de las redes prestadoras registrar el alta de un afiliado, fuera de convenio, en ASE. </p></li>
    <li><p>Contempla el inicio del alta y envío del formulario digitalizado.</p></li>  
    <li><p>Brinda a LAS REDES la posibilidad de realizar seguimiento de sus trámites y con el estado del mismo. </p></li>
    <li><p>La aplicación permite la descarga del formulario de afiliación de ASE, denominado f001 v.1</p></li> 
    <li><p>ASE podrá incluir funcionalidades en la Aplicación o gestionar cambios si considera necesarios ante modificaciones en el proceso, cambios en la documentación o nuevas normativas impulsadas por la SUPERINTENDENCIA DE SERVICIOS DE SALUD y/o EL MINISTERIO DE SALUD DE LA REPÚBLICA ARGENTINA</p></li>
</ul>

<h5>B.	Requisitos de uso de la Aplicación </h5>
<ul>
    <li><p>Podrán utilizar la Aplicación todos LAS REDES dadas de alta en ASE y con domicilio legal en la República Argentina. </p>
    <li><p>Para utilizar la Aplicación LAS REDES deberán registrarse siguiendo el procedimiento que se detalla en la Aplicación y aceptar los Términos y Condiciones. </p>
    <li><p>LAS REDES declara y garantiza que todos los datos personales suministrados en el proceso de registro son verdaderos, completos y se encuentran actualizados. </p>
    <li><p>Al registrarse, LAS REDES, lo harán con el correo electrónico y el mismo será su nombre de usuario. Definirán la contraseña que le permitirán acceder a la Aplicación. Esta información es personal e intransferible, debiendo LAS REDES evitar que terceras personas puedan acceder a ella, quedando entendido que cualquier acción realizada en la Aplicación mediante el nombre de usuario y contraseña elegidos por LAS REDES será imputada a dicho Usuario. Si LAS REDES advirtiera que su nombre de usuario y contraseña perdieron su condición de confidenciales y/o están siendo utilizados por terceros, deberá informar en forma inmediata a ASE mediante el envío de un correo electrónico a la siguiente dirección: comercial@ase.com.ar. Será responsabilidad de LAS REDES mantener actualizada su información personal. </p>
    <li><p>ASE utilizará la información suministrada por LAS REDES exclusivamente con el objeto de permitir a LAS REDES utilizar la Aplicación. </p>
    <li><p>El registro del Usuario y la posterior utilización de la Aplicación es gratuito, lo que implica que no deberá pagar por ello ningún arancel o retribución. </p>
    <li><p>Para poder utilizar la Aplicación se requiere conexión a Internet. La libre descarga y la gratuidad del acceso no comprenden las facilidades de conexión a Internet. En ningún caso ASE proveerá a LAS REDES la conectividad necesaria para que puedan utilizar la Aplicación. La conectividad a Internet será por exclusiva cuenta y cargo de LAS REDES. </p>
    <li><p>ASE podrá rechazar cualquier solicitud de registro o impedir, en cualquier momento, que ingrese a la Aplicación cualquiera de LAS REDES u otros siempre que tal ingreso pueda poner en riesgo la seguridad de la Aplicación y/o se haya violado los Términos y Condiciones. </p></li>
</ul>

<h5>C.	Información contenida en la Aplicación </h5>
<ul>
    <li><p>El contenido y/o el uso de la Aplicación está orientada exclusivamente a la gestión de altas de afiliados, por medio de los ejecutivos de ventas de las redes prestadoras, y seguimiento de la gestión de dichas afiliaciones. </p>
    <li><p>ASE, se encargará de chequear la situación de obra social de los CUILES a dar de alta, situación laboral (en convenio/fuera de convenio) y procesar los datos descriptos en el formulario f.001 de ASE v.1. No implica responsabilidad para ASE si los datos informados en el formulario 001 v.1 son falsos o contienen irregularidades </p>
</ul>

<h5>D.	Utilización de la Aplicación</h5> 
<ul>
    <li><p>LAS REDES deberán utilizar la Aplicación de conformidad con las disposiciones establecidas en estos Términos y Condiciones, con el ordenamiento jurídico vigente en la República Argentina, y según las pautas de conducta impuestas por la moral, las buenas costumbres y el debido respeto a los derechos de terceros. </p>
    <li><p>Queda prohibido el uso de la Aplicación con fines comerciales </p>
    <li><p>Queda terminante prohibido: (i) copiar, modificar, adaptar, traducir, realizar ingeniería inversa, descompilar o desensamblar cualquier parte del contenido y/o de la Aplicación; (ii) hacer uso del contenido en otro sitio web o entorno informático para cualquier propósito sin la autorización previa y por escrito de ASE; (iii) reproducir y/o comunicar por cualquier medio el contenido, dado que el acceso se otorga en forma personal y para uso exclusivo de LAS REDES; (iv) interferir o interrumpir el funcionamiento de la Aplicación; (v) vender, licenciar o explotar el contenido y/o cualquier tipo de acceso y/o uso de la Aplicación; (vi) utilizar la Aplicación con fines ilícitos o inmorales; e (vii) infringir de cualquier modo los presentes Términos y Condiciones. </p>
</ul>

<h5>E.	Protección de Datos Personales </h5>
<ul>
    <li><p>ASE cumple con lo establecido en la Ley 25.326 y normativa complementaria (en adelante, la “Normativa de Datos Personales”). </p>
    <li><p>LAS REDES presta su consentimiento expreso para que la Aplicación recolecte y procese información personal de LAS REDES y AFILIADO (nombre, DNI, CUIT/CUIL, edad, domicilio, geolocalización, acceso a cámara o galería de imágenes y otra información relevante), incluida información sensible referida a su salud (antecedentes o certificados médicos). El afiliado ya manifestó su consentimiento expreso e informado en la planilla de incorporación.</p>
    <li><p>La Aplicación es administrada por ASE, con domicilio en Lima 87, Piso 7° CABA, ante la cual LAS REDES podrá ejercer sus derechos de acceso, rectificación y supresión de sus datos personales enviando un correo a comercial@ase.com.ar Si LAS REDES advirtiera que su nombre de usuario y contraseña perdieron su condición de confidenciales y/o están siendo utilizados por terceros, deberá informar en forma inmediata a ASE mediante el envío de un correo electrónico a la siguiente dirección: <a href="mailto:comercial@ase.com.ar">comercial@ase.com.ar</a></p>
    <li><p>ASE se compromete a adoptar todos los recaudos que sean necesarios para garantizar la seguridad de los datos personales de LAS REDES y de los afiliados, inclusive la prevención de procesamientos no autorizados o ilegítimos, pérdida accidental, destrucción o daños a dichos datos personales. Asimismo, ASE se compromete a adoptar todas las medidas que sean necesarias para garantizar que toda persona que tenga acceso a los datos personales de LAS REDES y los afiliados les dé el tratamiento detallado en esta cláusula. Si LAS REDES advirtiera que su nombre de usuario y contraseña perdieron su condición de confidenciales y/o están siendo utilizados por terceros, deberá informar en forma inmediata, mediante a ASE el envío de un correo electrónico a la siguiente dirección: comercial@ase.com.ar </p>
</ul>

<h5>F.	Responsabilidad </h5>
<ul>    
    <li><p>La Aplicación se licencia en forma gratuita a LAS REDES en el marco de las políticas excepcionales derivadas de la emergencia sanitaria declarada por el DNU 260/2020. En consecuencia, ASE en ningún caso será responsable por lucro cesante ni por cualquier consecuencia mediata y/o causal, pérdida de datos o información, pérdida de chance, daños punitivos ni de cualquier otra naturaleza derivadas del uso de la Aplicación. </p>
    <li><p>ASE tampoco asume responsabilidad por ningún mal funcionamiento, imposibilidad de acceso o malas condiciones de uso de la Aplicación debido al uso de equipos inadecuados, interrupciones relacionadas con proveedores de servicio de internet, la saturación de la red de internet y/o por cualquier otra razón. </p>
</ul>

<h5>G.	Enlaces a otros sitios </h5>
<p>En la Aplicación podrían encontrarse enlaces a sitios web de terceros. En tal caso, ASE no es responsable del contenido de dichos sitios web. Además, la existencia de un vínculo entre la Aplicación y cualquier sitio de terceros de ninguna manera implica que ASE apruebe el contenido de dicho sitio, o, asimismo, cualquier uso que dicho contenido pueda tener. </p>

<h5>H.	Modificaciones de la Aplicación </h5>
<p>ASE podrá introducir todos los cambios y modificaciones que estime convenientes en la Aplicación, lo cual incluye, pero no se limita a: agregar, alterar, sustituir o suprimir cualquier contenido de la Aplicación en todo momento. </p>

<h5>I.	Propiedad Intelectual</h5>
<ul>
	<li><p>La Aplicación y el contenido de la Aplicación son de titularidad exclusiva de ASE tiene la autorización correspondiente para su utilización y licenciamiento. A título meramente enunciativo, se entenderán incluidos las imágenes, fotografías, diseños, gráficos, sonidos, compilaciones de datos, marcas, nombres, títulos, designaciones, signos distintivos, y todo otro material accesible a través de la Aplicación. </p></li>
	<li><p>ASE se reserva todos los derechos sobre la Aplicación y el contenido de la Aplicación, no cede ni transfiere a favor de LAS REDES y afiliados ningún derecho sobre su propiedad intelectual. </p></li>
	<li><p>Los derechos de propiedad intelectual respecto de los criterios de selección y/o disposición del contenido en la Aplicación corresponden exclusivamente a ASE, quedando estrictamente prohibido LAS REDES utilizar los contenidos, las categorías y/o cualquier información de la Aplicación con otra finalidad distinta a la indicada en los presentes Términos y Condiciones. </p></li>
	<li><p>En el caso de que la Aplicación permita descargar contenido para su posterior lectura por LAS REDES, la Secretaría otorga LAS REDES una licencia de uso gratuita, no transferible, no exclusiva y para uso estrictamente personal. </p></li>
</ul>

<h5>J.	Operatividad de la Aplicación </h5>
<ul>
    <li><p>ASE no garantiza el pleno acceso y operatividad de la Aplicación en forma ininterrumpida. </p></li>
    <li><p>ASE podrá suspender el acceso a la Aplicación y/o a determinado contenido por motivos de mantenimiento o de seguridad en cualquier momento. </p></li>
</ul>

<h5>K.	Notificaciones y comunicaciones </h5>
<p>Las notificaciones y comunicaciones cursadas por ASE a la casilla de correo electrónico que haya provisto LAS REDES al momento del registro se considerarán eficaces y plenamente válidas. Asimismo, se considerarán eficaces las comunicaciones que consistan en avisos y mensajes insertos en la Aplicación que tengan por finalidad informar a LAS REDES sobre determinada circunstancia, como, por ejemplo, un cambio en estos términos y condiciones o en la política de privacidad. </p>

<h5>L.	Legislación aplicable y jurisdicción </h5>
<ul>
    <li><p>A todos los efectos legales en relación con la Aplicación, será aplicable la legislación vigente en la República Argentina. </p></li>
    <li><p>La implementación de dicha aplicación garantiza que ASE tiene la posibilidad de acceder, cuando lo requiera, a los instrumentos originales de los formularios que firma el afiliado para su incorporación a la Obra Social conforme lo exige la legislación vigente, como así también la protección de los datos personales (ley 25326) todo ello previsto en el instrumento en análisis. </p></li>
    <li><p>Cualquier controversia que se suscite entre las partes con relación a estos Términos y Condiciones, su existencia, validez, calificación, interpretación, alcance, cumplimiento o resolución, se resolverá definitiva y exclusivamente por los Tribunales Nacionales en lo Civil y Comercial Federal. </p></li>
</ul>

</div>

