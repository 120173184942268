import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
import { FileInput, FileValidator } from 'ngx-material-file-input';
import { Observable } from 'rxjs';
import { AltaProveedorService } from 'app/services/alta-proveedor.service';
import { RegistroProveedorService } from 'app/services/registro-proveedor.service';
import { Router } from '@angular/router';
import { LoginService } from 'app/services/login.service';

@Component({
    selector: 'app-alta-proveedor',
    templateUrl: './alta-proveedor.component.html',
    styleUrls: ['./alta-proveedor.component.scss']
})
export class AltaProveedorComponent implements OnInit {

    reload(): void {
        setTimeout(function () {
            window.location.replace(environment.urlWebsite);
        }, 6000);
    }

    pdfDocValidator(control: FormControl) {
        if (!control.value)
            return;

        const type = control.value.files[0].type;

        if (!type)
        return {
            mimeType: {
                type: 'ERROR'
            }
        }

        if (type &&
            type != 'image/jpeg' &&
            type != 'image/png' &&
            type != 'image/gif' &&
            type != 'image/x-ms-bmp' &&
            type != 'image/bmp' &&
            type != 'application/pdf' &&
            type != 'application/msword' &&
            type != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
            type != 'application/vnd.ms-excel' &&
            type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return {
                mimeType: {
                    type: type
                }
            }
        }
        return null;
    }

    formAltaProveedor: FormGroup;
    provincias = [];
    readonly maxSize = 3145728;
    provinciaSeleccionada: any;
    cuit: any;
    email: any;
    state = '';
    sent : boolean = false;
    logged = false;


    constructor(private _snackBar: MatSnackBar,
        private _altaProveedor: AltaProveedorService,
        private _registroProveedor: RegistroProveedorService,
        private router: Router,
        private _loginService: LoginService,) {

    }

    ngOnInit(): void {
        this._loginService.getAll().subscribe(resp => {
            if (!resp['isLogged'])
                this.logged = false;
            else {
                this.logged = true;
                this._snackBar.open('No apareces en los registro de ASE por lo que debes completar el siguiente formulario.', 'Cerrar', {
                    duration: undefined,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                });
            }
        });

        this.provincias = ["BS.AS.", "CAPITAL", "CATAMARCA", "CHACO", "CHUBUT",
            "CORDOBA", "CORRIENTES", "ENTRE RIOS", "FORMOSA", "JUJUY", "LA PAMPA",
            "LA RIOJA", "MENDOZA", "MISIONES", "NEUQUEN", "RIO NEGRO", "SALTA",
            "SAN JUAN", "SAN LUIS", "SANTA CRUZ", "SANTA FE", "SGO. DEL ESTERO",
            "T. DEL FUEGO A.I.A.S.", "TUCUMAN"];

        //recibo de registro proveedor filialSeleccionada, email , cuit     
        this.provinciaSeleccionada = window.history.state.provincia;
        this.email = window.history.state.email;
        this.cuit = window.history.state.cuit;

        if (!this.cuit || !this.email || !this.provinciaSeleccionada)
            this.router.navigate(['registro-proveedor']);

        this.formAltaProveedor = new FormGroup({
            razonSocial: this.razonSocial,
            domicilio: this.domicilio,
            localidad: this.localidad,
            telefono: this.telefono,
            provincia: this.provincia || this.provinciaSeleccionada,
            adjunto1: this.adjunto1,
            adjunto2: this.adjunto2,
            adjunto3: this.adjunto3,
            adjunto4: this.adjunto4,
            adjunto5: this.adjunto5,
            adjunto6: this.adjunto6
        })
    }

    razonSocial = new FormControl('', [Validators.required]);
    domicilio = new FormControl('', [Validators.required]);
    localidad = new FormControl('', [Validators.required]);
    telefono = new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]);
    provincia = new FormControl('', [Validators.required]);
    adjunto1 = new FormControl('', [FileValidator.maxContentSize(this.maxSize), this.pdfDocValidator]);
    adjunto2 = new FormControl('', [FileValidator.maxContentSize(this.maxSize), this.pdfDocValidator]);
    adjunto3 = new FormControl('', [FileValidator.maxContentSize(this.maxSize), this.pdfDocValidator]);
    adjunto4 = new FormControl('', [FileValidator.maxContentSize(this.maxSize), this.pdfDocValidator]);
    adjunto5 = new FormControl('', [FileValidator.maxContentSize(this.maxSize), this.pdfDocValidator]);
    adjunto6 = new FormControl('', [FileValidator.maxContentSize(this.maxSize), this.pdfDocValidator]);

    btnEnviar() {

        this.formAltaProveedor.markAllAsTouched();
        if (!this.formAltaProveedor.valid) {
            this._snackBar.open('Recordá que debes completar todos los campos correctamente ', 'Cerrar', {
                duration: undefined,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
            return;
        }

        this.sent = true;
        const formData = new FormData();
        formData.append('razonSocial', this.razonSocial.value);
        formData.append('domicilio', this.domicilio.value);
        formData.append('localidad', this.localidad.value);
        formData.append('telefono', this.telefono.value);
        formData.append('filial', this.provinciaSeleccionada.toString());
        formData.append('cuit', this.cuit); //recibo desde registro proveedor
        formData.append('email', this.email); // recibo desde registro proveedor 

        const adj1_form: FileInput = this.adjunto1.value;
        if (adj1_form) {
            const adj1 = adj1_form.files[0];
            formData.append('adjunto1', adj1);
        }

        const adj2_form: FileInput = this.adjunto2.value;
        if (adj2_form) {
            const adj2 = adj2_form.files[0];
            formData.append('adjunto2', adj2);
        }

        const adj3_form: FileInput = this.adjunto3.value;
        if (adj3_form) {
            const adj3 = adj3_form.files[0];
            formData.append('adjunto3', adj3);
        }

        const adj4_form: FileInput = this.adjunto4.value;
        if (adj4_form) {
            const adj4 = adj4_form.files[0];
            formData.append('adjunto4', adj4);
        }

        const adj5_form: FileInput = this.adjunto5.value;
        if (adj5_form) {
            const adj5 = adj5_form.files[0];
            formData.append('adjunto5', adj5);
        }

        const adj6_form: FileInput = this.adjunto6.value;
        if (adj6_form) {
            const adj6 = adj6_form.files[0];
            formData.append('adjunto6', adj6);
        }

        this._altaProveedor.sendForm(formData)
            .subscribe(resp => {
                if (!this.logged) {
                    this._snackBar.open('Recibimos tu solicitud de alta con éxito. Próximamente te enviaremos un correo electrónico informándote la resolución.', 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                    this.router.navigate(['proveedores/registro-proveedor']);
                }
                else {
                    this._snackBar.open('Próximamente te enviaremos un correo electrónico informándote la resolución. Puedes intentar solicitar el rol nuevamente dentro de 72h', 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                    this.router.navigate(['misdatos']);
                }

            },
                err => {
                    this._snackBar.open('Ha ocurrido un error: ' + err['message'], 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                });

    }

    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }
}
