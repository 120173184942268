import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DescargarComprobantePrestacionService } from 'app/services/descargar-comprobante-prestacion.service';
import { LoginService } from 'app/services/login.service';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-solicitud-enviada',
  templateUrl: './solicitud-enviada.component.html',
  styleUrls: ['./solicitud-enviada.component.scss']
})
export class SolicitudEnviadaComponent implements OnInit {

  dataBreadcumbPages: Array<any> = [];
  dataBreadcumbTitle: any;
  nroIncidencia: any;
  sessionId: any;

  constructor(private router: Router, private descargarComprobanteService: DescargarComprobantePrestacionService, 
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService) {

    this.dataBreadcumbPages = [{ page: 'Formulario De Solicitud', route: '' }];
    this.dataBreadcumbTitle = [{ page: 'Discapacidad', route: '/discapacidad' }, { page: 'Mis Prestaciones', route: '/discapacidad/prestaciones' }];
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.nroIncidencia = params["incidencia"];
    });

    this.loginService.getSessionData().subscribe(resp => {
      this.sessionId = resp['session_id'];
    });
  }

  goToNewRequest() {
    this.router.navigate(['/discapacidad/prestaciones']);
  }

  downloadComprobante() {
    this.descargarComprobanteService.getBinarioDescargaComprobante(this.nroIncidencia, this.sessionId).subscribe((result) => {
      this.downloadFileFromBuffer(result.file, result.filename);
      setTimeout(()=>{
        this.router.navigate(['/discapacidad']);
      },5000)
    })
  }

  private downloadFileFromBuffer(file: any, filename: string) {
    const buffer = new Uint8Array(file.data);
    const blob = new Blob([buffer], { type: 'application/pdf' });
    saveAs(blob, filename);
  }
}
