<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
        <div>
            <img src="assets/images/iconos/icono_medicamentos.svg" class="icon">
        </div>
    </div>

    <div fxFlex="81" fxFlex.xs="100" fxFlex.lt-md="100" fxLayout="column" id="content">
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> »
            <a [routerLink]="['/medicamentos']">Medicamentos e Insumos</a> »
            <span>Formularios e Instructivos</span>
        </div>

        <h2 class="page-subtitle">Descarga de Formularios e Instructivos</h2>

        <div fxFlex="100" id="panel-one">
            <table class="forms">
                <mat-accordion hideToggle>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Acromegalia</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Acromegalia</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ACROMEGALIA/1-INSTRUCTIVO - ACROMEGALIA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ACROMEGALIA/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Acromegalia</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ACROMEGALIA/3-FORMULARIO HISTORIA CLINICA - ACROMEGALIA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ACROMEGALIA/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Artritis psoriásica</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Artritis Psoriásica</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ARTRITIS PSORIASICA/1-INSTRUCTIVO - ARTRITIS PSORIASICA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ARTRITIS PSORIASICA/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Artritis Psoriásica</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ARTRITIS PSORIASICA/3-FORMULARIO HISTORIA CLINICA - ARTRITIS PSORIASICA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ARTRITIS PSORIASICA/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Ficha de pacientes con Artritis Psoriásica</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ARTRITIS PSORIASICA/5-FICHA DE PACIENTES CON ARTRITIS PSORIÁSICA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Artritis Reumatoidea</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Artritis Reumatoidea</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ARTRITIS REUMATOIDEA/1-INSTRUCTIVO - ARTRITIS REUMATOIDEA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ARTRITIS REUMATOIDEA/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Artritis Reumatoidea</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ARTRITIS REUMATOIDEA/3-FORMULARIO HISTORIA CLINICA - ARTRITIS REUMATOIDEA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ARTRITIS REUMATOIDEA/4-FORMULARIO DE MEDICACION DE ALTA COMPLEJIDAD (PROTOCOLO) general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Ficha del paciente con Artritis Reumatoidea</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ARTRITIS REUMATOIDEA/5-FICHA DE PACIENTES CON ARTRITIS REUMATOIDEA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <!-- <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Asma severo</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Asma severo</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ASMA SEVERO/1-INSTRUCTIVO - ASMA SEVERO{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ASMA SEVERO/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Asma severo</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ASMA SEVERO/3-FORMULARIO DE HISTORIA CLINICA - ASMA SEVERO{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ASMA SEVERO/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Cuestionario AQLQ</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ASMA SEVERO/5-FORMULARIO - CUESTIONARIO AQLQ{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel> -->

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Bomba de insulina</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Bomba de insulina</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/BOMBA DE INSULINA/1- Instructivo - Bomba de Insulina{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/BOMBA DE INSULINA/2- Consentimiento Informado{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Bomba de insulina</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/BOMBA DE INSULINA/3- Formulario de Historia Clinica – Bomba de Insulina{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Colitis Ulcerosa</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Colitis Ulcerosa</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/COLITIS ULCEROSA/1-INSTRUCTIVO - COLITIS ULCEROSA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/COLITIS ULCEROSA/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Colitis Ulcerosa</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/COLITIS ULCEROSA/3-FORMULARIO HISTORIA CLÍNICA - COLITIS ULCEROSA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/COLITIS ULCEROSA/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Enfermedad de Fabry</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Enfermedad de Fabry</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ENFERMEDAD DE FABRY/1-INSTRUCTIVO - ENFERMEDAD DE FABRY{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ENFERMEDAD DE FABRY/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Enfermedad de Fabry</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ENFERMEDAD DE FABRY/3-FORMULARIO HISTORIA CLINICA - ENFERMEDAD DE FABRY{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ENFERMEDAD DE FABRY/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Enfermedad de Gaucher</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Enfermedad de Gaucher</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ENFERMEDAD DE GAUCHER/1-INSTRUCTIVO - ENFERMEDAD DE GAUCHER{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ENFERMEDAD DE GAUCHER/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Enfermedad de Gaucher</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ENFERMEDAD DE GAUCHER/3-FORMULARIO HISTORIA CLINICA - ENFERMEDAD DE GAUCHER{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ENFERMEDAD DE GAUCHER/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <!-- <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Esclerosis lateral amiotrófica (ELA)</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Esclerosis lateral amiotrófica (ELA)</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ESCLEROSIS LATERAL AMIOTRÓFICA (ELA)/1-INSTRUCTIVO - ESCLEROSIS LATERAL AMIOTRÓFICA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ESCLEROSIS LATERAL AMIOTRÓFICA (ELA)/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Esclerosis lateral amiotrófica (ELA)
                            </td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ESCLEROSIS LATERAL AMIOTRÓFICA (ELA)/3-FORMULARIO HISTORIA CLINICA - ESCLEROSIS LATERAL AMIOTROFICA (ELA){{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ESCLEROSIS LATERAL AMIOTRÓFICA (ELA)/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel> -->

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Esclerosis múltiple</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Esclerosis múltiple</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ESCLEROSIS MÚLTIPLE/1-INSTRUCTIVO - ESCLEROSIS MULTIPLE{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ESCLEROSIS MÚLTIPLE/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Esclerosis múltiple</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ESCLEROSIS MÚLTIPLE/3-FORMULARIO DE HISTORIA CLINICA - ESCLEROSIS MULTIPLE{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ESCLEROSIS MÚLTIPLE/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Espondiloartritis</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Espondiloartritis (ESPONDILITIS ANQUILOSANTE Y
                                ESPONDILITIS AXIAL NO RADIOGRAF)</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ESPONDILOARTRITIS/1-INSTRUCTIVO - ESPONDILOARTRITIS ANQUILOSANTE{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ESPONDILOARTRITIS/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Espondiloartritis (ESPONDILITIS
                                ANQUILOSANTE Y ESPONDILITIS AXIAL NO RADIOGRAF)</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ESPONDILOARTRITIS/3-FORMULARIO HISTORIA CLINICA - ESPONDILOARTRITIS{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ESPONDILOARTRITIS/4-FORMULARIO DE MEDICACION DE ALTA COMPLEJIDAD (PROTOCOLO) general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Fibrosis quística</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Fibrosis Quística</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/FIBROSIS QUÍSTICA/1-INSTRUCTIVO - FIBROSIS QUÍSTICA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/FIBROSIS QUÍSTICA/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Fibrosis Quística</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/FIBROSIS QUÍSTICA/3-FORMULARIO HISTORIA CLINICA - FIBROSIS QUISTICA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/FIBROSIS QUÍSTICA/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Hemofilia</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Hemofilia</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HEMOFILIA/1-INSTRUCTIVO{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clínica Ingreso</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HEMOFILIA/2-FORMULARIO DE HISTORIA CLINICA (INGRESO){{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clínica Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HEMOFILIA/3-FORMULARIO DE HISTORIA CLINICA MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario FPE</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HEMOFILIA/4-FORMULARIO F.P.E.{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Hoja de Ingreso</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}/formularios/medicamentos/HEMOFILIA/5-HOJA DE INGRESO{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Hoja de Seguimiento</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HEMOFILIA/6-HOJA DE SEGUIMIENTO{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Modelo de Orden Médica</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HEMOFILIA/7-MODELO ORDEN MEDICA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Planilla de uso domiciliario</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HEMOFILIA/8-PLANILLA DE USO DOMICILIARIO{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HEMOFILIA/9-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Hepatitis C</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Hepatitis B o C</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HEPATITIS B o C/1-INSTRUCTIVO - HEPATITIS B O C{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HEPATITIS B o C/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Hepatitis</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HEPATITIS B o C/3-HEPATITIS - FORMULARIO DE HISTORIA CLINICA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HEPATITIS B o C/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario 046-17</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HEPATITIS B o C/5-HEPATITIS C - FORMULARIO 046-17{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Hipertensión Pulmonar</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Hipertensión Pulmonar</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HIPERTENSION PULMONAR/1-INSTRUCTIVO - HIPERTENSION PULMONAR{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Hipertensión Pulmonar</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HIPERTENSION PULMONAR/2-FORMULARIO DE HISTORIA CLÍNICA - HIPERTENSIÓN PULMONAR{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HIPERTENSION PULMONAR/3-CONSENTIMIENTO INFORMADO{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HIPERTENSION PULMONAR/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">HIV</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - HIV</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HIV/1-INSTRUCTIVO - HIV{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HIV/2-CONSENTIMIENTO INFORMADO - HIV{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica Específica</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HIV/3-FORMULARIO DE HISTORIA CLINICA ESPECÍFICA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">IDocumento modelo – Constancia de notificación en SVNS 2.0</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HIV/4-Documento modelo - Constancia de notificación en SNVS 2.0{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Justificativo de esquema</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HIV/5-JUSTIFICATIVO ESQUEMA HIV{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Hormona de crecimiento</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Hormona de crecimiento</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HORMONA DE CRECIMIENTO/1-INSTRUCTIVO - HORMONA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clínica</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HORMONA DE CRECIMIENTO/2-FORMULARIO DE HISTORIA CLINICA - HORMONA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HORMONA DE CRECIMIENTO/3-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HORMONA DE CRECIMIENTO/4-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Hormona</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/HORMONA DE CRECIMIENTO/5-FORMULARIO HORMONA DE CRECIMIENTO{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Inmunosupresores</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Inmunosupresores</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/INMUNOSUPRESORES/1-INSTRUCTIVO - INMUNOSUPRESORES{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/INMUNOSUPRESORES/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Inmunosupresores</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/INMUNOSUPRESORES/3-FORMULARIO DE HISTORIA CLINICA - INMUNOSUPRESORES{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/INMUNOSUPRESORES/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Maculopatía</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Maculopatía</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/MACULOPATIA/1-INSTRUCTIVO - MACULOPATIA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/MACULOPATIA/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Maculopatía</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/MACULOPATIA/3-FORMULARIO DE HISTORIA CLINICA - MACULOPATÍA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/MACULOPATIA/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Oncológico</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Oncológico</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ONCOLOGICO/1-INSTRUCTIVO - ONCOLOGICO{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ONCOLOGICO/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Oncológico</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ONCOLOGICO/3-FORMULARIO_DE_HISTORIA_CLINICA_ONCOLOGICA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/ONCOLOGICO/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Prevención de Infección Severa por Virus Sincicial Respiratorio
                                    (VSR)</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Prevención de Infección Severa por Virus Sincicial
                                Respiratorio (VSR)</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/PREVENCION DE INFECCION SEVERA POR VIRUS SINCICIAL RESPIRATORIO (VSR)/1-INSTRUCTIVO – PREVENCION DE INFECCION SEVERA POR EL VIRUS SINCICIAL RESPIRATORIO (VSR){{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/PREVENCION DE INFECCION SEVERA POR VIRUS SINCICIAL RESPIRATORIO (VSR)/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Prevención de Infección Severa por
                                Virus Sincicial Respiratorio (VSR)</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/PREVENCION DE INFECCION SEVERA POR VIRUS SINCICIAL RESPIRATORIO (VSR)/3-FORMULARIO DE HISTORIA CLINICA PREVENCION DE INFECCION SEVERA POR VSR{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/PREVENCION DE INFECCION SEVERA POR VIRUS SINCICIAL RESPIRATORIO (VSR)/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Psoriasis en Placa</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Psoriasis en Placa</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/PSORIASIS EN PLACA/1-INSTRUCTIVO - PSORIASIS EN PLACA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/PSORIASIS EN PLACA/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Psoriasis en Placa</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/PSORIASIS EN PLACA/3-FORMULARIO DE HISTORIA CLINICA ESPECÍFICA - PSORIASIS EN PLACA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/PSORIASIS EN PLACA/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Ficha de pacientes con Psoriasis en placa</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/PSORIASIS EN PLACA/5-FICHA DE PACIENTES CON PSORIASIS EN PLACA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Retardo de Crecimiento Intrauterino</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Retardo de Crecimiento Intrauterino</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/RETARDO DE CRECIMIENTO INTRAUTERINO/1-INSTRUCTIVO - RETARDO DE CRECIMIENTO INTRAUTERINO{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/RETARDO DE CRECIMIENTO INTRAUTERINO/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Retardo de Crecimiento Intrauterino
                            </td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/RETARDO DE CRECIMIENTO INTRAUTERINO/3-FORMULARIO HISTORIA CLÍNICA - RETARDO DE CRECIMIENTO INTRAUTERINO{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/RETARDO DE CRECIMIENTO INTRAUTERINO/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Sobrecarga Crónica de Hierro</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Sobrecarga Crónica de Hierro</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/SOBRECARGA CRÓNICA DE HIERRO/1-INSTRUCTIVO - SOBRECARGA CRÓNICA DE HIERRO{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/SOBRECARGA CRÓNICA DE HIERRO/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Sobrecarga Crónica de Hierro</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/SOBRECARGA CRÓNICA DE HIERRO/3-FORMULARIO HISTORIA CLINICA - SOBRECARGA CRONICA DE HIERRO{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/SOBRECARGA CRÓNICA DE HIERRO/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="listed-link">Toxina Botulínica</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <tr class="table-info">
                            <td class="table-info">Instructivo - Toxina Botulínica</td>
                            <td class="table-info"><a class='retractable-item' target="_blank" target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/TOXINA BOTULÍNICA/1-INSTRUCTIVO - TOXINA BOTULINICA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Consentimiento Informado</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/TOXINA BOTULÍNICA/2-CONSENTIMIENTO INFORMADO general{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Formulario de Historia Clinica – Toxina Botulínica</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/TOXINA BOTULÍNICA/3-FORMULARIO HISTORIA CLINICA - TOXINA BOTULINICA{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                        <tr class="table-info">
                            <td class="table-info">Protocolo de Consumo Mensual</td>
                            <td class="table-info"><a class='retractable-item' target="_blank"
                                    href="{{ env.urlDownloads }}formularios/medicamentos/TOXINA BOTULÍNICA/4-PROTOCOLO DE CONSUMO MENSUAL{{ extension }}">
                                    <mat-icon class="icon_download">archive</mat-icon>
                                </a></td>
                        </tr>
                    </mat-expansion-panel>
                </mat-accordion>
            </table>
        </div>

    </div>
</div>