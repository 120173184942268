import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

//modulos angular material
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';


//componente
import { AltaProveedorComponent } from './alta-proveedor.component';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseHighlightModule } from '@fuse/components';

const routes = [
  {
      path     : 'proveedores/alta-proveedor',
      component: AltaProveedorComponent
  }
];

@NgModule({
  declarations: [
    AltaProveedorComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FuseSharedModule,
    FuseHighlightModule,
    MatExpansionModule,
    MaterialFileInputModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatIconModule,
    MatListModule
  ]
})
export class AltaProveedorModule { }
