import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import * as shape from 'd3-shape';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { NovedadesService } from 'app/services/novedades.service';
import { PageEvent } from '@angular/material/paginator';
import { Novedad } from 'app/models/novedad.model';
import { Meta } from '@angular/platform-browser';
import { LoginService } from 'app/services/login.service';

@Component({
    selector: 'app-novedades',
    templateUrl: './novedades.component.html',
    styleUrls: ['./novedades.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class NovedadesComponent implements OnInit {

    env = environment;
    view = 'preview';

    novedades: Novedad[] = [];
    pageIndex: number;

    pageSize: number = 4;
    pageNumber: number = 1;
    pageSizeOptions = [4, 10, 20, 50];
    isLogged = false;
    breadcrumbLogged= false;
    enie = '45 años';



    constructor(private _loginService: LoginService,
        private _novedadesService: NovedadesService,
        private router: Router,
        private route: ActivatedRoute,
        private meta: Meta) {
        this.pageNumber = Number(this.route.snapshot.queryParamMap.get('page'));

        const description = this.meta.getTag('name=description');
        this.meta.removeTagElement(description);
        const keywords = this.meta.getTag('name=keywords');
        this.meta.removeTagElement(keywords);
    }

    ngOnInit(): void {
        this._loginService.getAll().subscribe(resp => {
            this.isLogged = resp['isLogged'];
            if (this.isLogged) {
                this._loginService.getSessionData().subscribe(resp2 => {
                    let loggedRol = resp2.rol;
                    this._novedadesService.getAllLogged(this.pageSize, this.pageIndex, loggedRol)
                        .subscribe(data => {
                            this.novedades = data;
                        },
                            err => console.log(err)
                        );
                });
                this.breadcrumbLogged = true;
            }
            else {
                this._novedadesService.getAll(this.pageSize, this.pageIndex)
                    .subscribe(data => {
                        this.novedades = data;
                    },
                        err => console.log(err)
                    );
                this.breadcrumbLogged = false;
            }
        })
    }

    viewNovedad(id): void {
        this.router.navigate(['/novedad', id]);
    }


    handlePage(e: PageEvent): void {
        this.pageSize = e.pageSize;
        this.pageNumber = e.pageIndex + 1;
        // Cambiar el parametro page en query string
        const queryParams: Params = { page: this.pageNumber };
        this.router.navigate(
            [],
            {
                queryParams: queryParams,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
    }

    linkPanel(){
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
      } 


}


