import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarcarComprobanteComponent } from './marcar-comprobante.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { PdfViewerModule } from 'ng2-pdf-viewer';

const routes = [
  {
      path     : 'marcar-comprobante',
      component: MarcarComprobanteComponent
  }
];


@NgModule({
  declarations: [MarcarComprobanteComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    MatSelectModule,
    MatCardModule,
    PdfViewerModule
    
    
  ]
})
export class MarcarComprobanteModule { }
