<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">
    
        <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
            <div>
                <img src="assets/images/iconos/icono_medicamentos.svg" class="icon">
            </div>
        </div>

        <div fxFlex="81" fxFlex.xs="100" fxFlex.lt-md="100" fxLayout="column">
            <div class="breadcrumbs">
                <a (click)="linkPanel()">Panel de Inicio</a> » <a [routerLink]="['/medicamentos']">Medicamentos e insumos</a> » <span>Solicitud de Medicamentos</span>
            </div>

        <h2 class="page-subtitle">Solicitud de Medicamentos e Insumos</h2> 

        <form #form [formGroup]="formSolicitud" method="POST" novalidate enctype="multipart/form-data">    
        <div class="tw">
            <mat-form-field class="mat-fullwidth">
                <mat-label>Observaciones</mat-label>
                <p id="msg">(En caso de solicitar medicación por quimioterapia ingresar fecha y lugar de aplicación)</p>
                <textarea rows="3" matInput [formControl]="observaciones"></textarea>
                <mat-error *ngIf="observaciones.invalid">Debe ingresar sus observaciones.</mat-error>
            </mat-form-field>
        </div>

        <mat-accordion multi="true" id="accordion">
            <mat-expansion-panel disabled expanded>
                <mat-expansion-panel-header class="fixed-header">
                    <mat-panel-title>
                        <p>
                            <mat-icon matSuffix>folder</mat-icon><strong>Adjuntar Documentación</strong> (formatos válidos: JPG, GIF, BMP, PNG, DOC, XLS y PDF)
                        </p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="data">
                    <div class="tw">
                        <mat-form-field appearance="standard" class="mat-fullwidth">
                            <mat-label>Orden médica *</mat-label>
                            <ngx-mat-file-input formControlName="adjunto1" #adjunto1 
                            [accept]="'.jpeg, .gif, .bmp, .png, .xls, .xlsx, .pdf, .doc, .docx'">
                            </ngx-mat-file-input>
                            <mat-icon matSuffix *ngIf="adjunto1.empty">add</mat-icon>
                            <button mat-icon-button matSuffix *ngIf="!adjunto1.empty"
                                (click)="adjunto1.clear($event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                            <mat-error *ngIf="formSolicitud.get('adjunto1').hasError('maxContentSize')
                            || formSolicitud.get('adjunto1').hasError('required')
                            || formSolicitud.get('adjunto1').hasError('mimeType')">Debe adjuntar un archivo (JPG, GIF, BMP, PNG, DOC, XLS ó PDF, 3MB máximo).
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="data">
                    <div class="tw">
                        <mat-form-field appearance="standard" class="mat-fullwidth">
                            <mat-label>Documentacion complementaria</mat-label>
                            <ngx-mat-file-input formControlName="adjunto2" #adjunto2
                                [accept]="'.jpeg, .gif, .bmp, .png, .xls, .xlsx, .pdf, .doc, .docx'">
                            </ngx-mat-file-input>
                            <mat-icon matSuffix *ngIf="adjunto2.empty">add</mat-icon>
                            <button mat-icon-button matSuffix *ngIf="!adjunto2.empty"
                                (click)="adjunto2.clear($event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                            <mat-error *ngIf="formSolicitud.get('adjunto2').hasError('maxContentSize')                                    
                            || formSolicitud.get('adjunto2').hasError('mimeType')">Debe adjuntar un archivo (JPG, GIF, BMP, PNG, DOC, XLS ó PDF, 3MB máximo).
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>    
                
            </mat-expansion-panel>
        </mat-accordion>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" class="submit-section">
            <div>
                <button mat-raised-button class="submit-button" aria-label="Enviar" (click)="sendForm()"
                [disabled]="formSent">
                    Enviar
                </button> 
            </div>
        </div>
        
        </form>         

</div>

