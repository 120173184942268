<div class="container-alerta-observacion-documentacion" *ngIf="_documentacionListaParEnviar || _documentacionObservacion">
  <section class="accordion">
    <input type="checkbox" name="collapse" id="info-text" />
    <h2 class="handle">
      <label for="info-text" [ngClass]="{ 'success': _documentacionListaParEnviar, 'error': _documentacionObservacion }">
        <span class="mensaje">¡Atención! La documentación básica tiene observaciones</span>
        <span class="mensaje-existoso" *ngIf="_documentacionListaParEnviar">Cambios guardados exitosamente. Lista para enviar.</span>
      </label>
    </h2>
    <div [ngClass]="{'content': true, 'success': _documentacionListaParEnviar, 'error': _documentacionObservacion}">
      <ng-container *ngFor="let doc of resultDocumentObservacion">
        <ng-container>
          <p>
            <span>{{ doc.DocumentoDescripcion }}</span>
            {{ doc.observacion }}
          </p>
        </ng-container>
      </ng-container>
    </div>
  </section>
</div>
