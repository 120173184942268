<div class="page-layout blank p-24" fusePerfectScrollbar>

    
    <div id="novedad" *ngFor="let secc of seccion">
        
        <p class="breadcrumbs"><a [routerLink]="['/home']">INICIO</a> » {{ secc.titulo | uppercase }}</p>
        
        <h1>{{ secc.titulo  }}</h1>  

        <div [innerHTML]="secc.detalle"></div>
        

    </div>

</div> 
