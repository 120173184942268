import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'app/services/login.service';
import { MisPagosService } from 'app/services/mis-pagos.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-adjunto',
  templateUrl: './adjunto.component.html',
  styleUrls: ['./adjunto.component.scss']
})
export class AdjuntoComponent implements OnInit {

  NroFactura = window.history.state.NroFactura;
  NroReintegro = window.history.state.NroReintegro;
  Observaciones: any;
  
  constructor(
              private getSessionData: LoginService,
              private _cargaMisPagos: MisPagosService,
              private _loginService: LoginService,        
              private router: Router) { }

  ngOnInit(): void {
    this._loginService.getAll().subscribe(resp => {
      if (!resp['isLogged'])
          this.router.navigate(['/login']);
    });
/*     this.getSessionData.getSessionData().subscribe(result => {
      this._cargaMisPagos.getAllPagos(result.cuit)
          .subscribe(data => {
              this.Observaciones = data;              
          })
    }) */
  }

  linkPanel() {
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  }

}
