<div class="page-layout blank p-24" fusePerfectScrollbar>

    <h3 fxHide.gt-sm fxShow.lt-md class = 'formresponsive' class="page-title">Seleccione el rol con el que ingresará :</h3>
    <form fxHide.gt-sm fxShow.lt-md #form name="AuthCambioDeRolForm" action="{{ urlRoleSelect }}" method="POST" (ngSubmit)="onSubmit($event)"
w        [formGroup]="formRoles" novalidate>
        <input type="hidden" name="AuthCambioDeRolForm[rol]" value="{{rolSelected}}">
        <div fxLayout.xs="column" fxLayout="row">
            <div fxFlex.xs="100" fxFlex.sm="84" fxFlex.md="88" fxFlex.gt-sm="92" fxLayout="row">

                <mat-form-field class="mat-fullwidth">
                    <mat-label>Seleccione un rol</mat-label>
                    <mat-select required name="rol" formControlName="rol" [(ngModel)]="rolSelected" disableOptionCentering>
                        <mat-optgroup *ngFor="let rol of listRoles" label="{{rol.empresa}}">
                            <mat-option *ngFor="let rolInfo of rol.info" [value]="rolInfo.id" class="roles">{{
                                rolInfo.nombre_rol }}</mat-option>
                        </mat-optgroup>
                        
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxFlex.sm="16" fxFlex.md="12" fxFlex.gt-md="8" fxFlex.xs="100" style="height: 55px;">

                <button mat-raised-button class="submit-button" aria-label="ENVIAR" name="yt0" (click)="form.submit()"
                    [disabled]="!formRoles.valid">
                    ACCEDER
                </button>
            </div>
        </div>
    </form>

    <form fxShow.gt-sm fxHide.lt-md class = 'formweb' #form2 name="AuthCambioDeRolForm" action="{{ urlRoleSelect }}" method="POST" (ngSubmit)="onSubmit($event)"
        [formGroup]="formRoles" novalidate>
        <input type="hidden" name="AuthCambioDeRolForm[rol]" value="{{rolSelected}}">
        <div fxLayout.xs="column" fxLayout="row">
            <div fxFlex.xs="100" fxFlex.sm="84" fxFlex.md="88" fxFlex.gt-sm="92" fxLayout="row">

                <mat-form-field class="mat-fullwidth">
                    <mat-label>Seleccione un rol</mat-label>
                    <mat-select required name="rol" formControlName="rol" [(ngModel)]="rolSelected" disableOptionCentering>
                        <mat-optgroup *ngFor="let rol of listRoles">
                            <mat-option *ngFor="let rolInfo of rol.info" [value]="rolInfo.id" class="roles">{{
                                rol.empresa + ': ' + rolInfo.nombre_rol }}</mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxFlex.sm="16" fxFlex.md="12" fxFlex.gt-md="8" fxFlex.xs="100" style="height: 55px;">

                <button mat-raised-button class="submit-button" aria-label="ENVIAR" name="yt0" (click)="form2.submit()"
                    [disabled]="!formRoles.valid">
                    ACCEDER
                </button>
            </div>
        </div>
    </form>
</div>