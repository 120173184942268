import {
  ChangeDetectionStrategy,
  Component,
  Inject,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
} from "@angular/forms";

import {
  ModalDiscapacidadUserData,
} from "app/models/modal-documentacion-basica-data.model";
import { DocumentacionService } from "app/services/documentacion.service";
import { DocumentationModalData } from "../prestaciones/interfaces/documentation-modal.interface";
import { saveAs } from "file-saver";
import { MatSnackBar } from "@angular/material/snack-bar";
import { forkJoin } from "rxjs";

@Component({
  selector: "modal-documentacion-basica-component",
  templateUrl: "./modal-documentacion-basica.component.html",
  styleUrls: ["./modal-documentacion-basica.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDocumentacionBasicaComponent {
  form: FormGroup;
  documentation: DocumentationModalData[];
  cuil: string;
  nroOrden: string;
  nroBeneficiario: string;
  user_session_id: string;
  isLoading: boolean = false;
  closeModal: () => void;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalDiscapacidadUserData,
    private _fb: FormBuilder,
    private _documentacionService: DocumentacionService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ModalDocumentacionBasicaComponent>
  ) {
    this.documentation = [...this.data.documentation];
    this.cuil = this.data.cuilDoc;
    this.nroOrden = this.data.nroOrden;
    this.nroBeneficiario = this.data.nroBeneficiario;
    this.user_session_id = this.data.user_data.sessionId;

    this.form = this._fb.group({
        documentation: this._fb.array(
            this.documentation.length > 0 ?
                this.documentation.map(doc => this._createDocumentationFormGroup(doc))
                : []),
        documentacionPorAdjuntar: this._fb.array(
            this.documentation.length > 0 ?
                this.documentation.filter(doc => {
                  return doc?.document_puedeCargarDocumento == true;
                })
                .map(doc => this._createDocumentationFormGroup(doc))
                : []),
        documentacionEnTransito: this._fb.array(
            this.documentation.length > 0 ?
                this.documentation.filter(doc => doc?.document_enEvaluacion === true)
                .map(doc => this._createDocumentationFormGroup(doc))
                : []),
        documentacionVinculada: this._fb.array(
          this.documentation.length > 0 ?
              this.documentation.filter(doc => doc?.document_HPBEnfermedadDocumentoVinculado == '1' && doc?.document_puedeCargarDocumento == false)
              .map(doc => this._createDocumentationFormGroup(doc))
              : []),
    });

    console.log(this.documentation);
  }

  private _createDocumentationFormGroup(doc) {
    return this._fb.group({
        id: doc.id,
        doc_id: doc.documentation_id,
        document_required: doc.document_required,
        document_title: doc.document_title,
        document_name: doc.document_name,
        document_path: doc.document_path,
        document_file_type: doc.document_file_type,
        document_puedeCargarDocumento: doc.document_puedeCargarDocumento,
        document_observada: doc.document_observada,
        document_idprestacionenviada: doc.document_idprestacionenviada,
        file: null,
        is_deleted: false,
      })
  }

  get documentationFormArray() {
    return this.form.get("documentation") as FormArray;
  }

  get documentacionPorAdjuntarFormArray() {
    return this.form.get("documentacionPorAdjuntar") as FormArray;
  }

  get documentacionEnTransitoFormArray(){
    return this.form.get("documentacionEnTransito") as FormArray;
  }

  get documentacionVinculadaFormArray(){
    return this.form.get("documentacionVinculada") as FormArray;
  }

  async onChangeFile(event: any, doc_id: number, nroArray: number) {
    const file: File = event.target.files[0] as File;
    if (!file) return;

    if (!this.isValidFile(file)) {
        this._snackBar.open("Formato inválido o peso superior a 5MB", "Cerrar")
        return;
    }

    const documentControl = this._retrieveDocumentationControl({key: 'doc_id', value: doc_id, nroArray: nroArray});
    
    documentControl.patchValue({
        file: file,
        document_name: file.name,
        document_path: "",
        document_file_type: file.type,
    });

    documentControl.markAsDirty();
  }

  private isValidFile(file: File): boolean {
    const allowedFormats = ['application/pdf'];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB

    return allowedFormats.includes(file.type) && file.size <= maxFileSize;
  }

  removeFileFromDocumentation(doc_id: number, nroArray: number) {
    const documentControl = this._retrieveDocumentationControl({ key: 'doc_id', value: doc_id, nroArray: nroArray });       
    documentControl.patchValue({
        file: "",
        document_name: "",
        document_path: "",
        document_file_type: "",
        is_deleted: true,
    });
  }

  // onAccept() {
  //   this.isLoading = true;
  //   const documentacion = new FormArray([...this.documentacionPorAdjuntarFormArray.controls, ...this.documentacionEnTransitoFormArray.controls, 
  //                               ...this.documentacionVinculadaFormArray.controls]);

  //   if (this.form.valid) {
  //       const documentationToSend = this.documentationFormArray.controls.filter(control => control.value.file || control.value.is_deleted).map(control => ({
  //           file: control.value.file,
  //           doc_id: control.value.doc_id,
  //           is_deleted: control.value.is_deleted,
  //           documentDescription: control.value.document_title,
  //       }));

  //     if (documentationToSend.length === 0) {
  //       this.isLoading = false;
  //       return this._snackBar.open("Debe adjuntar al menos un archivo o hacer una modificación", "Cerrar");
  //     }

  //     const observables = documentationToSend.map((doc) => {
  //         const formData = new FormData();
  //         formData.append("documentacionId", doc.doc_id);
  //         formData.append("sessionId", this.user_session_id);
  //         formData.append("cuil", this.cuil);
  //         formData.append("nroBeneficiario", this.nroBeneficiario);
  //         formData.append("nroOrden", this.nroOrden);
  //         formData.append("documentDescription", doc.documentDescription)
  //         formData.append("is_deleted", String(doc.is_deleted));
  //         formData.append("file", doc.file);

  //         return this._documentacionService.sendDocumentacion(formData);
  //     });
  
  //     forkJoin(observables).subscribe(
  //       () => {
  //         this.isLoading = false;
  //         this.onCloseModal();
  //       },
  //       (error) => {
  //         this.isLoading = false;
  //         console.error('Error al enviar los archivos', error);
  //         this._snackBar.open('Ocurrió un error en la solicitud. Por favor, inténtelo de nuevo.', "Cerrar");
  //       }
  //     );
  //   } else {
  //       this.documentationFormArray.markAllAsTouched();
  //   }
  // }

  onAccept() {
    this.isLoading = true;
    if (this.form.valid) {
      const documents = new FormArray([...this.documentacionPorAdjuntarFormArray.controls, ...this.documentacionVinculadaFormArray.controls])
        
      const documentationToSend = documents.controls.filter(control => control.value.file || control.value.is_deleted).map(control => ({
            file: control.value.file,
            doc_id: control.value.doc_id,
            is_deleted: control.value.is_deleted,
            documentDescription: control.value.document_title,
        }));

      if (documentationToSend.length === 0) {
        this.isLoading = false;
        return this._snackBar.open("Debe adjuntar al menos un archivo o hacer una modificación", "Cerrar");
      }

      const observables = documentationToSend.map((doc) => {
          const formData = new FormData();
          formData.append("documentacionId", doc.doc_id);
          formData.append("sessionId", this.user_session_id);
          formData.append("cuil", this.cuil);
          formData.append("nroBeneficiario", this.nroBeneficiario);
          formData.append("nroOrden", this.nroOrden);
          formData.append("documentDescription", doc.documentDescription)
          formData.append("is_deleted", String(doc.is_deleted));
          formData.append("file", doc.file);

          return this._documentacionService.sendDocumentacion(formData);
      });
  
      forkJoin(observables).subscribe(
        () => {
          this.isLoading = false;
          this.onCloseModal();
        },
        (error) => {
          this.isLoading = false;
          console.error('Error al enviar los archivos', error);
          this._snackBar.open('Ocurrió un error en la solicitud. Por favor, inténtelo de nuevo.', "Cerrar");
        }
      );
    } else {
        this.documentacionPorAdjuntarFormArray.markAllAsTouched();
        this.documentacionVinculadaFormArray.markAllAsTouched();
    }
  }

  documentLoaded(documentId: number, nroArray: number): boolean {
    const documentControl = this._retrieveDocumentationControl({key:'doc_id',value: documentId, nroArray: nroArray});
    return !!documentControl.value.document_name
  }

  downloadFile(id: number, array: number): void {
    let documentControl: any;
    documentControl = this._retrieveDocumentationControl({key: 'id', value: id, nroArray: array});
    if (!documentControl.value.id) return; //previene llamada innecesaria cuando no hay doc en base de datos
    
    this._documentacionService.downloadDocumentacion(this.user_session_id, String(id)).subscribe((response) => {
        this.downloadFileFromBuffer({
          file: response.file,
          mime: documentControl.value.document_file_type,
          filename: documentControl.value.document_name,
        });
      });
  }

  private downloadFileFromBuffer(data: { file: any; mime: string; filename: string }) {
    const buffer = new Uint8Array(data.file.data);
    const blob = new Blob([buffer], { type: data.mime });
    saveAs(blob, data.filename);
  }

  onCloseModal() {
    if (this.closeModal) {
      this.closeModal();
    }
  }

  // private _retrieveDocumentationControl({ key, value }: { key: string, value: any }): FormControl {
  //   const control = this.documentationFormArray.controls.find(
  //     (control) => control.value[key] === value
  //   ) as FormControl
  //   if (!control) console.error("Documento no encontrado");
  //   return control;
  // }

  private _retrieveDocumentationControl({ key, value, nroArray }: { key: string, value: any, nroArray: number }): FormControl {
    let control: any;
    switch(nroArray){
      case 1:
        control = this.documentacionPorAdjuntarFormArray.controls.find(
          (control) => control.value[key] === value
        ) as FormControl
        if (!control) console.error("Documento no encontrado");
        return control;
      case 2:
        control = this.documentacionEnTransitoFormArray.controls.find(
          (control) => control.value[key] === value
        ) as FormControl
        if (!control) console.error("Documento no encontrado");
        return control;
      case 3:
        control = this.documentacionVinculadaFormArray.controls.find(
          (control) => control.value[key] === value
        ) as FormControl
        if (!control) console.error("Documento no encontrado");
        return control;
    }
  }
}
