import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class CsvGenerationService {
  constructor() {}

  async generateCSV<T>(data: T[], filename: string): Promise<void> {
    const ExcelJS = await import('exceljs');
    
    const csvContent = this.convertArrayToCSV(data, ExcelJS);
    this.downloadBlob(csvContent, filename);
  }

  private downloadBlob(content: any, filename: string): void {
    content.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, filename);
    });
  }

  private convertArrayToCSV<T>(data: T[], ExcelJS: any): any {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    const keys = Object.keys(data[0]);
    worksheet.addRow(keys);

    data.forEach((item: T) => {
      const values = keys.map((key: string) => item[key]);
      worksheet.addRow(values);
    });

    return workbook;
  }
}

