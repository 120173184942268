import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";

@Component({
  selector: "app-modal-notificacion-red-medife",
  templateUrl: "./modal-notificacion-red-medife.component.html",
  styleUrls: ["./modal-notificacion-red-medife.component.scss"],
})

export class ModalNotificacionRedMedifeComponent implements OnInit {
  constructor(
    private router: Router,
  ) {}

  onRedirectToPanel() {
    window.location.href = environment.urlWebSitePhp + "site/inicio";
  }

  ngOnInit(): void {}
}