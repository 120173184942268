import { environment } from './../../environments/environment';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RegistroProveedorService {

  constructor(private http: HttpClient) { }  

  registroProveedor(formData): Observable<any>{
    return this.http.post(environment.urlApi+'registroProveedor/sendForm', formData);
  }   
}
