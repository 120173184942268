import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { FormularioPDF } from '../../../models/formularioPDF.model';
import { FormularioPDFService } from 'app/services/formulario-pdf.service';
import { fuseAnimations } from '@fuse/animations';
import { cuitValidator } from 'app/shared/cuit-validator.directive';
import { MatSnackBar } from '@angular/material/snack-bar';
const FileSaver = require('file-saver');

@Component({
  selector: 'app-formulario-pdf',
  templateUrl: './formulario-pdf.component.html',
  styleUrls: ['./formulario-pdf.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations  
  
})

export class FormularioPDFComponent implements OnInit {
  
  tipoDocSelected1= '';
  tipoDocSelected2= '';
  tipoDocSelected3= '';
  tipoDocSelected4= ''; 
  tipoDocSelected5= ''; 
  tipoDocSelected6= ''; 
  checkedECivil= 'SOLTERO/A';
  checkedPMO= 'NO';
  maxDate: Date;


  formPDF: FormGroup;
  FormularioPDF: FormularioPDF = {} as FormularioPDF;

  cuil = new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(11), cuitValidator()]);
  apellido = new FormControl('', [Validators.required]);
  nombre = new FormControl('', [Validators.required]);
  tipoDocumento = new FormControl('', [Validators.required]);
  numero = new FormControl('', [Validators.required]);
  fcFechaAltaAse = new FormControl('', [Validators.required]);
  fcFechaNacimiento = new FormControl('', [Validators.required]);
  genero = new FormControl('', [Validators.required]);
  nacionalidad = new FormControl('', [Validators.required]);
  estadoCivil = new FormControl('', [Validators.required]);
  domicilio = new FormControl('', [Validators.required]);
  nroDomicilio = new FormControl('', [Validators.required]);
  piso = new FormControl();
  dto = new FormControl();
  telefono = new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]);
  localidad = new FormControl('', [Validators.required]);
  provincia = new FormControl('', [Validators.required]);
  cp = new FormControl('', [Validators.required]);
  correoElectronico = new FormControl('', [Validators.required, Validators.pattern('^[^@]+@[^@]+\.[a-zA-Z]{2,}$')]);
  coberturaPMO = new FormControl('', [Validators.required]);
  medicinaPrepagaSeleccionada = new FormControl();
  Opcion1 = new FormControl();
  Nacionalidad1 = new FormControl();
  NameSurname1 = new FormControl();
  TipoDoc1 = new FormControl();
  Cuil1 = new FormControl();
  Genero1 = new FormControl();
  fcFechaNacimientoFam1 = new FormControl();
  Opcion2 = new FormControl();
  Nacionalidad2 = new FormControl();
  NameSurname2 = new FormControl();
  TipoDoc2 = new FormControl();
  Cuil2 = new FormControl();
  Genero2 = new FormControl();
  fcFechaNacimientoFam2 = new FormControl();
  Opcion3 = new FormControl();
  Nacionalidad3 = new FormControl();
  NameSurname3 = new FormControl();
  TipoDoc3 = new FormControl();
  Cuil3 = new FormControl();
  Genero3 = new FormControl();
  fcFechaNacimientoFam3 = new FormControl();
  Opcion4 = new FormControl();
  Nacionalidad4 = new FormControl();
  NameSurname4 = new FormControl();
  TipoDoc4 = new FormControl();
  Cuil4 = new FormControl();
  Genero4 = new FormControl();
  fcFechaNacimientoFam4 = new FormControl();
  Opcion5 = new FormControl();
  Nacionalidad5 = new FormControl();
  NameSurname5 = new FormControl();
  TipoDoc5 = new FormControl();
  Cuil5 = new FormControl();
  Genero5 = new FormControl();
  fcFechaNacimientoFam5 = new FormControl();
  Opcion6 = new FormControl();
  Nacionalidad6 = new FormControl();
  NameSurname6 = new FormControl();
  TipoDoc6 = new FormControl();
  Cuil6 = new FormControl();
  Genero6 = new FormControl();
  fcFechaNacimientoFam6 = new FormControl();
  lugar = new FormControl('', [Validators.required]);
  fcFecha = new FormControl('', [Validators.required]);
  diaAltaEnASE = new FormControl(); // fecha alta en ASE
  mesAltaEnASE = new FormControl(); // fecha alta en ASE
  anioAltaEnASE = new FormControl(); // fecha alta en ASE
  diaNacimiento = new FormControl(); // fecha de nacimiento
  mesNacimiento = new FormControl(); // fecha de nacimiento
  anioNacimiento = new FormControl(); // año nacimiento
  
  constructor(private dateAdapter: DateAdapter<Date>, private _formPDFService: FormularioPDFService,private _snackBar: MatSnackBar) { 
    const currentYear = new Date().getFullYear();
    this.maxDate = new Date(currentYear, 11, 31);
    this.dateAdapter.setLocale('es'); 
  }

  sendForm(): void {


    this.FormularioPDF.cuil = this.cuil.value; 
    this.FormularioPDF.apellido = this.apellido.value;
    this.FormularioPDF.nombre = this.nombre.value;
    this.FormularioPDF.tipoDocumento = this.tipoDocumento.value;
    this.FormularioPDF.numero = this.numero.value;
    //fecha alta en ASE
    const diaAA = new Date (this.fcFechaAltaAse.value).getDate();
    const mesAA = new Date (this.fcFechaAltaAse.value).getMonth()+1;
    const anioAA = new Date (this.fcFechaAltaAse.value).getFullYear();         
    this.FormularioPDF.diaAltaEnASE = String(diaAA);
    this.FormularioPDF.mesAltaEnASE = String(mesAA);
    this.FormularioPDF.anioAltaEnASE = String(anioAA);
    // fecha nacimiento
    const diaFN = new Date (this.fcFechaNacimiento.value).getDate();
    const mesFN = new Date (this.fcFechaNacimiento.value).getMonth()+1;
    const anioFN = new Date (this.fcFechaNacimiento.value).getFullYear();
    this.FormularioPDF.diaNacimiento = String(diaFN);
    this.FormularioPDF.mesNacimiento = String(mesFN);
    this.FormularioPDF.anioNacimiento = String(anioFN);
    
    this.FormularioPDF.genero = this.genero.value;
    this.FormularioPDF.nacionalidad = this.nacionalidad.value;
    this.FormularioPDF.estadoCivil = this.estadoCivil.value;
    this.FormularioPDF.domicilio = this.domicilio.value;
    this.FormularioPDF.nroDomicilio = this.nroDomicilio.value;
    this.FormularioPDF.piso = this.piso.value || '';
    this.FormularioPDF.dto = this.dto.value  || '';
    this.FormularioPDF.telefono = this.telefono.value;
    this.FormularioPDF.localidad = this.localidad.value;
    this.FormularioPDF.provincia = this.provincia.value;
    this.FormularioPDF.cp = this.cp.value;
    this.FormularioPDF.correoElectronico = this.correoElectronico.value;
    this.FormularioPDF.coberturaPMO = this.coberturaPMO.value;
    this.FormularioPDF.medicinaPrepagaSeleccionada = this.medicinaPrepagaSeleccionada.value || ''; 
    this.FormularioPDF.Opcion1 = this.Opcion1.value || '';
    this.FormularioPDF.Nacionalidad1 = this.Nacionalidad1.value || '';
    this.FormularioPDF.NameSurname1 = this.NameSurname1.value || '';
    this.FormularioPDF.TipoDoc1 = this.TipoDoc1.value || '';
    this.FormularioPDF.Cuil1 = this.Cuil1.value || '';
    this.FormularioPDF.Genero1 = this.Genero1.value || '';
    // fecha nacimiento familiar 1
    const diaF1 = (this.fcFechaNacimientoFam1.value != null) ? this.fcFechaNacimientoFam1.value.getDate() : '';
    const mesF1 = (this.fcFechaNacimientoFam1.value != null) ? this.fcFechaNacimientoFam1.value.getMonth()+1 : '';
    const anioF1 = (this.fcFechaNacimientoFam1.value != null) ? this.fcFechaNacimientoFam1.value.getFullYear() : '';
    this.FormularioPDF.DiaFecha1 = String(diaF1);
    this.FormularioPDF.MesFecha1 = String(mesF1);
    this.FormularioPDF.AnioFecha1 = String(anioF1);

    this.FormularioPDF.Opcion2 = this.Opcion2.value || '';
    this.FormularioPDF.Nacionalidad2 = this.Nacionalidad2.value || '';
    this.FormularioPDF.NameSurname2 = this.NameSurname2.value || '';
    this.FormularioPDF.TipoDoc2 = this.TipoDoc2.value || '';
    this.FormularioPDF.Cuil2 = this.Cuil2.value || '';
    this.FormularioPDF.Genero2 = this.Genero2.value || '';
    // fecha nacimiento familiar 2
    const diaF2 = (this.fcFechaNacimientoFam2.value != null) ? this.fcFechaNacimientoFam2.value.getDate() : '';
    const mesF2 = (this.fcFechaNacimientoFam2.value != null) ? this.fcFechaNacimientoFam2.value.getMonth()+1 : '';
    const anioF2 = (this.fcFechaNacimientoFam2.value != null) ? this.fcFechaNacimientoFam2.value.getFullYear() : '';
    this.FormularioPDF.DiaFecha2 = String(diaF2);
    this.FormularioPDF.MesFecha2 = String(mesF2);
    this.FormularioPDF.AnioFecha2 = String(anioF2);

    this.FormularioPDF.Opcion3 = this.Opcion3.value || '';
    this.FormularioPDF.Nacionalidad3 = this.Nacionalidad3.value || '';
    this.FormularioPDF.NameSurname3 = this.NameSurname3.value || '';
    this.FormularioPDF.TipoDoc3 = this.TipoDoc3.value || '';
    this.FormularioPDF.Cuil3 = this.Cuil3.value || '';
    this.FormularioPDF.Genero3 = this.Genero3.value || '';    
    // fecha nacimiento familiar 3
    const diaF3 = (this.fcFechaNacimientoFam3.value != null) ? this.fcFechaNacimientoFam3.value.getDate() : '';
    const mesF3 = (this.fcFechaNacimientoFam3.value != null) ? this.fcFechaNacimientoFam3.value.getMonth()+1 : '';
    const anioF3 = (this.fcFechaNacimientoFam3.value != null) ? this.fcFechaNacimientoFam3.value.getFullYear() : '';
    this.FormularioPDF.DiaFecha3 = String(diaF3);
    this.FormularioPDF.MesFecha3 = String(mesF3);
    this.FormularioPDF.AnioFecha3 = String(anioF3);

    this.FormularioPDF.Opcion4 = this.Opcion4.value || '';
    this.FormularioPDF.Nacionalidad4 = this.Nacionalidad4.value || '';
    this.FormularioPDF.NameSurname4 = this.NameSurname4.value || '';
    this.FormularioPDF.TipoDoc4 = this.TipoDoc4.value || '';
    this.FormularioPDF.Cuil4 = this.Cuil4.value || '';
    this.FormularioPDF.Genero4 = this.Genero4.value || ''; 
    // fecha nacimiento familiar 4
    const diaF4 = (this.fcFechaNacimientoFam4.value != null) ? this.fcFechaNacimientoFam4.value.getDate() : '';
    const mesF4 = (this.fcFechaNacimientoFam4.value != null) ? this.fcFechaNacimientoFam4.value.getMonth()+1 : '';
    const anioF4 = (this.fcFechaNacimientoFam4.value != null) ? this.fcFechaNacimientoFam4.value.getFullYear() : '';
    this.FormularioPDF.DiaFecha4 = String(diaF4);
    this.FormularioPDF.MesFecha4 = String(mesF4);
    this.FormularioPDF.AnioFecha4 = String(anioF4);    

    this.FormularioPDF.Opcion5 = this.Opcion5.value || '';
    this.FormularioPDF.Nacionalidad5 = this.Nacionalidad5.value || '';
    this.FormularioPDF.NameSurname5 = this.NameSurname5.value || '';
    this.FormularioPDF.TipoDoc5 = this.TipoDoc5.value || '';
    this.FormularioPDF.Cuil5 = this.Cuil5.value || '';
    this.FormularioPDF.Genero5 = this.Genero5.value || '';    
    // fecha nacimiento familiar 5
    const diaF5 = (this.fcFechaNacimientoFam5.value != null) ? this.fcFechaNacimientoFam5.value.getDate() : '';
    const mesF5 = (this.fcFechaNacimientoFam5.value != null) ? this.fcFechaNacimientoFam5.value.getMonth()+1 : '';
    const anioF5 = (this.fcFechaNacimientoFam5.value != null) ? this.fcFechaNacimientoFam5.value.getFullYear() : '';
    this.FormularioPDF.DiaFecha5 = String(diaF5);
    this.FormularioPDF.MesFecha5 = String(mesF5);
    this.FormularioPDF.AnioFecha5 = String(anioF5);

    this.FormularioPDF.Opcion6 = this.Opcion6.value || '';
    this.FormularioPDF.Nacionalidad6 = this.Nacionalidad6.value || '';
    this.FormularioPDF.NameSurname6 = this.NameSurname6.value || '';
    this.FormularioPDF.TipoDoc6 = this.TipoDoc6.value || '';
    this.FormularioPDF.Cuil6 = this.Cuil6.value || '';
    this.FormularioPDF.Genero6 = this.Genero6.value || '';   
    // fecha nacimiento familiar 6
    const diaF6 = (this.fcFechaNacimientoFam6.value != null) ? this.fcFechaNacimientoFam6.value.getDate() : '';
    const mesF6 = (this.fcFechaNacimientoFam6.value != null) ? this.fcFechaNacimientoFam6.value.getMonth()+1 : '';
    const anioF6 = (this.fcFechaNacimientoFam6.value != null) ? this.fcFechaNacimientoFam6.value.getFullYear() : '';
    this.FormularioPDF.DiaFecha6 = String(diaF6);
    this.FormularioPDF.MesFecha6 = String(mesF6);
    this.FormularioPDF.AnioFecha6 = String(anioF6);

    this.FormularioPDF.lugar = this.lugar.value;  
    // fecha 
    const diaF = new Date (this.fcFecha.value).getDate();
    const mesF = new Date (this.fcFecha.value).getMonth()+1;
    const anioF = new Date (this.fcFecha.value).getFullYear();
    this.FormularioPDF.diaFecha = String(diaF);
    this.FormularioPDF.mesFecha = String(mesF);
    this.FormularioPDF.anioFecha = String(anioF);
    
    this._formPDFService.sendForm(this.FormularioPDF)
    .subscribe(resp => {
      this.formPDF.markAllAsTouched();
      if (!this.formPDF.valid){
         this._snackBar.open('Recordá que debes completar todos los campos correctamente ', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else 
        {
          FileSaver.saveAs(resp, 'Formulario003.pdf')
          this._snackBar.open('El formulario se ha generado con exito.', 'Cerrar', {
            duration: undefined,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
    },
    err => {
        this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });      
  }

  ngOnInit(): void {
    this.formPDF = new FormGroup({
      cuil: this.cuil,
      apellido: this.apellido,
      nombre: this.nombre,
      tipoDocumento: this.tipoDocumento,
      numero: this.numero, 
      fechaAltaAse: this.fcFechaAltaAse,
      fechaNacimiento: this.fcFechaNacimiento,      
      genero: this.genero,
      nacionalidad: this.nacionalidad,
      estadoCivil: this.estadoCivil,
      domicilio: this.domicilio,
      nroDomicilio: this.nroDomicilio,
      piso: this.piso,
      dto: this.dto,
      telefono: this.telefono,
      localidad: this.localidad,
      provincia: this.provincia,
      cp: this.cp,
      correoElectronico: this.correoElectronico,
      coberturaPMO: this.coberturaPMO,
      medicinaPrepagaSeleccionada: this.medicinaPrepagaSeleccionada,
      Opcion1: this.Opcion1,
      Nacionalidad1: this.Nacionalidad1,
      NameSurname1: this.NameSurname1,
      TipoDoc1: this.TipoDoc1,
      Cuil1: this.Cuil1,
      Genero1: this.Genero1, 
      fechaNacimientoFam1: this.fcFechaNacimientoFam1,     
      Opcion2: this.Opcion2,
      Nacionalidad2: this.Nacionalidad2,
      NameSurname2: this.NameSurname2,
      TipoDoc2: this.TipoDoc2,
      Cuil2: this.Cuil2,
      Genero2: this.Genero2,
      fechaNacimientoFam2: this.fcFechaNacimientoFam2,      
      Opcion3: this.Opcion3,
      Nacionalidad3: this.Nacionalidad3,
      NameSurname3: this.NameSurname3,
      TipoDoc3: this.TipoDoc3,
      Cuil3: this.Cuil3,
      Genero3: this.Genero3, 
      fechaNacimientoFam3: this.fcFechaNacimientoFam3,      
      Opcion4: this.Opcion4,
      Nacionalidad4: this.Nacionalidad4,
      NameSurname4: this.NameSurname4,
      TipoDoc4: this.TipoDoc4,
      Cuil4: this.Cuil4,
      Genero4: this.Genero4,
      fechaNacimientoFam4: this.fcFechaNacimientoFam4,      
      Opcion5: this.Opcion5,
      Nacionalidad5: this.Nacionalidad5,
      NameSurname5: this.NameSurname5,
      TipoDoc5: this.TipoDoc5,
      Cuil5: this.Cuil5,
      Genero5: this.Genero5, 
      fechaNacimientoFam5: this.fcFechaNacimientoFam5,      
      Opcion6: this.Opcion6,
      Nacionalidad6: this.Nacionalidad6,
      NameSurname6: this.NameSurname6,
      TipoDoc6: this.TipoDoc6,
      Cuil6: this.Cuil6,
      Genero6: this.Genero6, 
      fechaNacimientoFam6: this.fcFechaNacimientoFam6,     
      lugar: this.lugar,
      fecha: this.fcFecha,     
      });
  }

}
