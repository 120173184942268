import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IndexModule } from './reintegros/index/index.module';
import { SolicitudreintegrosModule } from './reintegros/solicitudreintegros/solicitudreintegros.module';
import { ProtesisindexModule } from './protesis/protesisindex/protesisindex.module';
import { SolicitudprotesisModule } from './protesis/solicitudprotesis/solicitudprotesis.module';
import { MedicamentosIndexModule } from './medicamentos/medicamentosIndex/medicamentosIndex.module';
import { SolicitudMedicamentosModule } from './medicamentos/solicitudMedicamentos/solicitudMedicamentos.module';
import { CasamientoModule } from './solicitudObsequios/casamiento/casamiento.module';
import { NacimientoModule } from './solicitudNacimiento/nacimiento/nacimiento.module';
import { MisdatosModule } from './misdatos/misdatos.module';
import { SolicitarRolModule } from './misdatos/solicitar-rol/solicitar-rol.module';
import { SolicitarRedPrestadoraModule } from './misdatos/solicitar-rol/solicitar-red-prestadora/solicitar-red-prestadora.module';
import { SolicitarRolEmpresaModule } from './misdatos/solicitar-rol/solicitar-rol-empresa/solicitar-rol-empresa.module';
import { ActualizarDatosModule } from './misdatos/actualizar-datos/actualizar-datos.module';
import { CambiarContraseniaModule } from './misdatos/cambiar-contrasenia/cambiar-contrasenia.module';
import { CambiarEmailModule } from './misdatos/cambiar-email/cambiar-email.module';
import { FormularioProtesisModule } from './protesis/formularios-protesis/formularios-protesis.module';
import { FormulariosModule } from './medicamentos/formularios/formularios.module';
import { DiscapacidadindexModule } from './discapacidad/discapacidadindex/discapacidadindex.module';
import { FormulariosDiscapacidadModule } from './discapacidad/formularios-discapacidad/formularios-discapacidad.module';
import { InstructivosDiscapacidadModule } from './discapacidad/instructivos-discapacidad/instructivos-discapacidad.module';
import { PreguntasFrecuentesModule } from './medicamentos/preguntas-frecuentes/preguntas-frecuentes.module';
import { RegistroProveedorModule } from './proveedores/registro-proveedor/registro-proveedor.module';
import { AltaProveedorModule } from './proveedores/alta-proveedor/alta-proveedor.module';
import { ComprobantesModule } from './proveedores/comprobantes/comprobantes.module';
import { CargaComprobantesModule } from './proveedores/comprobantes/carga-comprobantes/carga-comprobantes.module';
import { DetallePrestacionModule } from './proveedores/comprobantes/detalle-prestacion/detalle-prestacion.module';
import { CargaFacturaModule } from './proveedores/comprobantes/carga-factura/carga-factura.module';
import { DiscapacidadModule } from './proveedores/discapacidad/discapacidad.module';
import { MisPagosModule } from './proveedores/mis-pagos/mis-pagos.module';
import { MisDatosModule } from './proveedores/mis-datos/mis-datos.module';
import { AdjuntoModule } from './proveedores/mis-pagos/adjunto/adjunto.module';
import { AltaCBUModule } from './proveedores/mis-datos/alta-cbu/alta-cbu.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ActualizarMisDatosModule } from './proveedores/mis-datos/actualizar-mis-datos/actualizar-mis-datos.module';
import { LiquidadorModule } from './liquidador/liquidador.module';
import { MarcarComprobanteModule } from './liquidador/marcar-comprobante/marcar-comprobante.module';
import { CargaFacturaAdminModule } from './proveedores/comprobantes/carga-factura-admin/carga-factura-admin.module';
import { AdmJudicializadosModule } from './liquidador/adm-judicializados/adm-judicializados.module';
import { ConsultaPrestacionesModule } from './proveedores/comprobantes/carga-comprobantes/consulta-prestaciones/consulta-prestaciones.module';
import { ConsultaMisPagosModule } from './proveedores/mis-pagos/consulta-mis-pagos/consulta-mis-pagos.module';
import { AdmUsuariosProveedorModule } from './proveedores/adm-usuarios-proveedor/adm-usuarios-proveedor.module';
import { CambiarEmailProveedorModule } from './proveedores/adm-usuarios-proveedor/cambiar-email-proveedor/cambiar-email-proveedor.module';
import { CambiarEmailDialogModule } from './proveedores/adm-usuarios-proveedor/cambiar-email-proveedor/cambiar-email-dialog/cambiar-email-dialog.module';
import { SeleccionarDatosModule } from './proveedores/adm-usuarios-proveedor/seleccionar-datos/seleccionar-datos.module';
import { SolicitarRolProveedorModule } from './misdatos/solicitar-rol/solicitar-rol-proveedor/solicitar-rol-proveedor.module';
import { PrestacionesModule } from './discapacidad/prestaciones/prestaciones.module';

@NgModule({
  declarations: [  
],
  imports: [    
    CommonModule,   
    IndexModule,
    ReactiveFormsModule,
    SolicitudreintegrosModule,
    ProtesisindexModule,
    SolicitudprotesisModule,
    MedicamentosIndexModule,
    SolicitudMedicamentosModule,
    CasamientoModule,
    NacimientoModule,
    MisdatosModule,
    SolicitarRolModule,
    SolicitarRolEmpresaModule,
    SolicitarRedPrestadoraModule,
    ActualizarDatosModule,
    CambiarContraseniaModule,
    CambiarEmailModule,
    FormularioProtesisModule,
    FormulariosModule,
    DiscapacidadindexModule,
    FormulariosDiscapacidadModule,
    InstructivosDiscapacidadModule,
    PreguntasFrecuentesModule,
    FlexLayoutModule,
    RegistroProveedorModule,
    AltaProveedorModule,
    ComprobantesModule,
    CargaComprobantesModule,
    DetallePrestacionModule,
    CargaFacturaModule,
    DiscapacidadModule,
    MisPagosModule,
    MisDatosModule,
    AdjuntoModule,
    AltaCBUModule,
    ActualizarMisDatosModule,
    LiquidadorModule,
    MarcarComprobanteModule,
    CargaFacturaAdminModule,
    AdmJudicializadosModule,
    ConsultaPrestacionesModule,
    ConsultaMisPagosModule,
    LiquidadorModule,
    AdmUsuariosProveedorModule,
    CambiarEmailProveedorModule,
    CambiarEmailDialogModule,
    SeleccionarDatosModule,
    SeleccionarDatosModule,
    SolicitarRolProveedorModule,
    PrestacionesModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ]
})

export class PrivateModule { }
