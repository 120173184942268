
<div class="nav" [ngClass]="{'horizontal':layout === 'horizontal', 'vertical':layout === 'vertical'}">

    <!-- Vertical Navigation Layout -->
    <ng-container *ngIf="layout === 'vertical'">

        <ng-container *ngFor="let item of navigation">

            <fuse-nav-vertical-group *ngIf="item.type=='group'" [item]="item"></fuse-nav-vertical-group>
            <fuse-nav-vertical-collapsable *ngIf="item.type=='collapsable'"
                                           [item]="item"></fuse-nav-vertical-collapsable>
            <fuse-nav-vertical-item *ngIf="item.type=='item'" [item]="item"></fuse-nav-vertical-item>

        </ng-container>

    </ng-container>
    <!-- / Vertical Navigation Layout -->

    <!-- Horizontal Navigation Layout -->
    <ng-container *ngIf="layout === 'horizontal'">
        
        <a href="/"><img src="assets/images/logos/logo_ase.png"  class="logo"></a>

        <ng-container *ngIf="!isLogged">
            <ng-container *ngFor="let item of navigation" >
                <fuse-nav-horizontal-collapsable *ngIf="item.type=='group'" [item]="item"></fuse-nav-horizontal-collapsable>
                <fuse-nav-horizontal-collapsable *ngIf="item.type=='collapsable'"
                                                [item]="item"></fuse-nav-horizontal-collapsable>
                <fuse-nav-horizontal-item *ngIf="item.type=='item'" [item]="item"></fuse-nav-horizontal-item>
            </ng-container>
        </ng-container>
        
        <!-- <ng-container *ngIf="isLogged && hasRol && !companyMember && !proveedor">
            <div class="container-info">
                <div class="box-name">
                    <h1 class="name-user">{{ datos ? datos.nombre : '' }} <button (click)="btnMisDatos()" class="link-button mat-raised-button">Mis Datos</button></h1> 
                </div>
                <span *ngIf="uniqueRol" class="cuit-rol"><h6 class="cuit">Rol: {{ datos ? datos.nombre_rol : '' }}</h6></span>
                <span *ngIf="!uniqueRol" class="cuit-rol"><h6 class="cuit">Rol: {{ datos ? datos.nombre_rol : '' }}</h6> <button (click)="btnCambiarRol()" class="link-rol mat-flat-button">Cambiar Rol</button></span>
            </div> 
        </ng-container>

        <ng-container *ngIf="isLogged && hasRol && companyMember && !proveedor">
            <div class="container-info">
                <div class="box-name">
                    <h1 class="name-user">{{ datos ? datos.nombre : '' }} <button (click)="btnMisDatos()" class="link-button mat-raised-button">Mis Datos</button></h1> 
                </div>
                <span class="razon-social">{{ datos ? datos.razon_social : '' }}  </span>
                <span *ngIf="uniqueRol" class="cuit-rol"><h6 class="cuit">CUIT: {{ datos ? datos.cuit : '' }}  Rol: {{ datos ? datos.nombre_rol : '' }}</h6></span>
                <span *ngIf="!uniqueRol" class="cuit-rol"><h6 class="cuit">CUIT: {{ datos ? datos.cuit : '' }}  Rol: {{ datos ? datos.nombre_rol : '' }}</h6> <button (click)="btnCambiarRol()" class="link-rol mat-flat-button">Cambiar Rol</button></span>
            </div> 
        </ng-container>
        
        <ng-container *ngIf="isLogged && hasRol && companyMember && proveedor">
            <div class="container-info">
                <div class="box-name">
                    <h1 class="name-user">{{ proveedorData ? proveedorData.RazonSocial : '' }} <button (click)="btnMisDatos()" class="link-button mat-raised-button">Mis Datos</button></h1> 
                </div>
                <span *ngIf="uniqueRol" class="cuit-rol"><h6 class="cuit">CUIT: {{ datos ? datos.cuit : '' }}  Rol: {{ datos ? datos.nombre_rol : '' }}</h6></span>
                <span *ngIf="!uniqueRol" class="cuit-rol"><h6 class="cuit">CUIT: {{ datos ? datos.cuit : '' }}  Rol: {{ datos ? datos.nombre_rol : '' }}</h6> <button (click)="btnCambiarRol()" class="link-rol mat-flat-button">Cambiar Rol</button></span>
            </div> 
        </ng-container> -->
       
    </ng-container>
    <!-- / Horizontal Navigation Layout -->

</div>
