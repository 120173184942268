import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { EjecutivoCuenta } from 'app/models/ejecutivocuenta.model';

@Injectable({
  providedIn: 'root'
})
export class EjecutivocuentaService {

  constructor(
    private http: HttpClient
  ) { }

  sendForm(ejecutivoCuenta: FormData): Observable<any>{
    return this.http.post(`${environment.urlApi}ejecutivocuenta/sendPublicForm`, ejecutivoCuenta);
  }   
    
}
