<div id="search-modern" class="page-layout simple fullwidth">

    <!-- HEADER -->
    <div class="header accent p-24" fxLayout="row" fxLayoutAlign="start center">

        <div class="search-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center">

            <div class="search" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon>search</mat-icon>
                <input placeholder="Search" fxFlex>
            </div>

        </div>

    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">

        <div class="result-info" fxLayout="row" fxLayoutAlign="start center">

            <span class="result-count h3 secondary-text">
                <span>54</span>
                <span class="ml-4">Results</span>
            </span>
            
        </div>

        <div class="results">

            <div class="result-item" *ngFor="let item of searchItems">
                <div class="title blue-800-fg">{{item.title}}</div>
                <div class="url green-800-fg">{{item.url}}</div>
                <div class="excerpt">{{item.excerpt}}</div>
            </div>

            <div class="simple-pagination">

                <button mat-button class="pagination-item disabled" disabled="disabled"
                        aria-label="Go to previous page">
                    <mat-icon>chevron_left</mat-icon>
                </button>

                <button mat-button class="pagination-item active" [disableRipple]="true" aria-label="Go to page 1">
                    <span>1</span>
                </button>

                <button mat-button class="pagination-item" aria-label="Go to page 2">
                    <span>2</span>
                </button>

                <button mat-button class="pagination-item" aria-label="Go to page 3">
                    <span>3</span>
                </button>

                <button mat-button class="pagination-item" aria-label="Go to page 4">
                    <span>4</span>
                </button>

                <button mat-button class="pagination-item" aria-label="Go to page 5">
                    <span>5</span>
                </button>

                <button mat-button class="pagination-item">
                    <span>...</span>
                </button>

                <button mat-button class="pagination-item" aria-label="Go to page 10">
                    <span>10</span>
                </button>

                <button mat-button class="pagination-item" aria-label="Go to next page">
                    <mat-icon>chevron_right</mat-icon>
                </button>

            </div>

        </div>

    </div>
    <!-- / CONTENT -->

</div>
