// auth.interceptor.ts
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { AuthDialogComponent } from "app/layout/components/auth-dialog/auth-dialog.component";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _dialog: MatDialog) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {//error de autenticacion
          //modal de aviso de desloggeo
          this._dialog
            .open(AuthDialogComponent, {
              disableClose: true,
              panelClass: "custom-modalbox"
            })
            .afterClosed()
            .subscribe(() => (window.location.href = '/index.php/site/logout'));
        }
        return throwError(error);
      })
    );
  }
}
