import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cuitValidator } from '../../../../shared/cuit-validator.directive';
import { OlvidoPasswordService } from '../../../../services/olvido-password.service';


@Component({
  selector: 'app-olvido-password',
  templateUrl: './olvido-password.component.html',
  styleUrls: ['./olvido-password.component.scss']
})
export class OlvidoPasswordComponent implements OnInit {

  formPW: FormGroup;

  constructor(private _snackBar: MatSnackBar,
              private _olvidoPassword: OlvidoPasswordService) { }

  ngOnInit(): void {

    this.formPW = new FormGroup({
      usuario: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(11), cuitValidator()])
    })
  }

  EnviarUsuario(){
    this.formPW.markAllAsTouched();
    if (!this.formPW.valid){
      return;
    };

    this._olvidoPassword.sendUser(this.formPW.value.usuario)
    .subscribe(resp => {
      this._snackBar.open('Hemos enviado un email a su cuenta para que complete el proceso.', 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    },
    err => {
      this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });  

  }


}
