import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-terminos',
  templateUrl: './terminos.component.html',
  styleUrls: ['./terminos.component.scss']
})
export class TerminosComponent implements OnInit {

  constructor(private meta: Meta) { 
    const description = this.meta.getTag('name=description');
    this.meta.removeTagElement(description);
    const keywords = this.meta.getTag('name=keywords');
    this.meta.removeTagElement(keywords);
   }

  ngOnInit(): void {
  }

}
