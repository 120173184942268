import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
declare function imageMapResize(): void; // for image map responsive in popup

@Component({
  selector: 'app-popup-footer',
  templateUrl: './popup-footer.component.html',
  styleUrls: ['./popup-footer.component.scss']
})
export class PopupFooterComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PopupFooterComponent>) { }

  ngOnInit(): void {
  }

  goForm(){
    window.open("https://www.argentina.gob.ar/produccion/defensadelconsumidor/formulario");
  }
  closeDialog() {
    this.dialogRef.close();
  }
  
  imageResized() {
    imageMapResize(); // Javascript function in imageMapResizer.min.js 
  }
}
