import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<any[]>{    
    return this.http.get<any[]>(environment.urlWebSitePhp + 'site/isLogged');
  }
  getSessionData(): Observable<any>{
      let sessionData = this.http.get<any[]>(environment.urlWebSitePhp + 'api/GetSessionData');
      
        sessionData.subscribe(resp => {
            environment.sessionId = resp['session_id'];
            environment.cuit = resp['cuit'];
            environment.cuil = resp['username'];
            environment.userId = resp['id'];
        });

      return sessionData;
  }
  getMenu(): Observable<any>{
      return this.http.get<any[]>(environment.urlWebSitePhp + 'api/GetPrivateMenu')
  }
  getCuil(hash): Observable<any>{
      return this.http.get(`${environment.urlApi}blanqueoPassword/getHash?hash=`+ hash);
  }

  loginProveedor(cuit): Observable<any> {
    return this.http.post(`${environment.urlApi}aloginProveedor`, cuit);
  }

  menuPerms(): Observable<any>{
    return this.http.get<any[]>(environment.urlWebSitePhp + 'api/menuPerms/');
}
}
