import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector     : 'pricing-style-2',
    templateUrl  : './style-2.component.html',
    styleUrls    : ['./style-2.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PricingStyle2Component
{
    /**
     * Constructor
     */
    constructor()
    {

    }

}
