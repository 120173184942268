import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { Contacto } from 'app/models/contacto.model';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {

  constructor(
    private http: HttpClient
  ) { }

  sendForm(contacto: Contacto): Observable<any>{
    return this.http.post(`${environment.urlApi}contacto/sendPublicForm`, contacto);
  }   
    
}
