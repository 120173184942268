import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SolicitarRolProveedorService {

  @Output() msjRolProveedor: EventEmitter<any> = new EventEmitter();
  @Output() msjRolProveedor2: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) { }

  validarGaci(form: FormData): Observable<any>{
    return this.http.post(`${environment.urlApi}roles/validarGaci`, form);
  }

  crearSoliciturRolProveedor(form: FormData): Observable<any>{
    return this.http.post(`${environment.urlApi}roles/crearSoliciturRolProveedor`, form);
  }   
}
