import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import * as shape from 'd3-shape';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { AltaMonotributista } from 'app/models/altamonotributista.model';
import { AltaMonotributistaService } from 'app/services/altamonotributista.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { FileValidator, FileInput } from 'ngx-material-file-input';
import { MatDialog } from '@angular/material/dialog';
import { DialogImgFormComponent } from './dialog-img-form/dialog-img-form.component';
import { Router } from '@angular/router';
import { cuitValidator } from 'app/shared/cuit-validator.directive';
import { DateAdapter } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-monotributistas',
  templateUrl: './monotributistas.component.html',
  styleUrls: ['./monotributistas.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class MonotributistasComponent implements OnInit {
  
  pdfDocValidator(control: FormControl) {
    if(!control.value)
      return;
    const type = control.value.files[0].type;
    if (type && 
      type != 'application/pdf' &&
      type != 'application/msword'&&
      type != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return {
          mimeType: {
            type: type
        }
      }
    }
    return null;
  }
  
  readonly maxSize = 3145728;
  showMe:number = 0;
  showForm = false;
  showTooltip = false;
  hideWhatsapp= false;
  

  formAlta: FormGroup;
  altaMonotributista: AltaMonotributista = {} as AltaMonotributista;
  nombre = new FormControl('', [Validators.required]);
  apellido = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  CUIT = new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(11), cuitValidator()]);
  codigoarea = new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]);
  telefono = new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]);
  numero_formulario = new FormControl('', [Validators.required]);
  adjunto1 = new FormControl('', [Validators.required, FileValidator.maxContentSize(this.maxSize), this.pdfDocValidator]);
  adjunto2 = new FormControl('', [Validators.required, FileValidator.maxContentSize(this.maxSize), this.pdfDocValidator]);
  adjunto3 = new FormControl('', [Validators.required, FileValidator.maxContentSize(this.maxSize), this.pdfDocValidator]);
  captchares: boolean = false;
  
  

  captchaResolved(captchaResponse: string){
    this.captchares = true;
    this.altaMonotributista.captcharesponse = captchaResponse; //Agregamos el response aca para ser validado en node y no recibir cualquier solicitud de contacto
  }

  reload(): void{
    setTimeout(function(){
      window.location.reload();
    },3000);
  }

  sendForm(): void {
    
    this.formAlta.markAllAsTouched();
    if (!this.formAlta.valid){
      this._snackBar.open('Recordá que debes completar todos los campos correctamente ', 'Cerrar', {
       duration: undefined,
       horizontalPosition: 'center',
       verticalPosition: 'top',
     });
     return; 
   } 
  
    /*
    POR SI QUIERO IMPLEMENTAR EL MODELO
    this.altaMonotributista.id = '';
    this.altaMonotributista.nombre = this.nombre.value;
    this.altaMonotributista.apellido = this.apellido.value;
    this.altaMonotributista.email = this.email.value;
    this.altaMonotributista.CUIT = this.CUIT.value;
    this.altaMonotributista.codigoarea = this.codigoarea.value;
    this.altaMonotributista.telefono = this.telefono.value;
    this.altaMonotributista.numero_formulario = this.numero_formulario.value;
    this.altaMonotributista.adjunto1 = this.adjunto1.value.files[0];
    this.altaMonotributista.adjunto2 = this.adjunto2.value.files[0];
    this.altaMonotributista.adjunto3 = this.adjunto3.value.files[0];
  */
 
    console.log(this.adjunto1.value);
    console.log(this.adjunto2.value);
    console.log(this.adjunto3.value);
    const formData = new FormData();
    formData.append('nombre', this.nombre.value);
    formData.append('apellido', this.apellido.value);
    formData.append('email', this.email.value);
    formData.append('CUIT', this.CUIT.value);
    formData.append('codigoarea', this.codigoarea.value);
    formData.append('telefono', this.telefono.value);
    formData.append('numero_formulario', this.numero_formulario.value);
    formData.append('captcharesponse', this.altaMonotributista.captcharesponse);

    const adj1_form: FileInput = this.adjunto1.value;
    const adj1 = adj1_form.files[0];
    formData.append('adjunto1', adj1);

    const adj2_form: FileInput = this.adjunto2.value;
    const adj2 = adj2_form.files[0];
    formData.append('adjunto2', adj2);

    const adj3_form: FileInput = this.adjunto3.value;
    const adj3 = adj3_form.files[0];
    formData.append('adjunto3', adj3);
    
    this._altaMonotributistaService.sendForm(formData)
      .subscribe(resp => {
        
      this.formAlta.markAllAsTouched();
        this._snackBar.open('El formulario se ha enviado con exito.', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.formAlta.reset();
        this.showForm = false;
        this.reload();
    },
    err => {
      this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });    
  }

  toggleView(show : number): void {
    if(show == this.showMe)
      this.showMe = 0;
    else
      this.showMe = show;
      this.showForm = false;
    }

    toggleTooltip(){
      this.showTooltip = !this.showTooltip;
    }

    newTab(url, internal): void {
      if(internal)
      {
        
        var newUrl = this.router.serializeUrl(this.router.createUrlTree(['/web' + url]));
        window.open(newUrl, '_blank');
      }
      window.open(url, '_blank');
    }

    toggleForm(): void{
      this.showForm = !this.showForm;
      this.hideWhatsapp= !this.hideWhatsapp;
    }

    constructor(private _snackBar: MatSnackBar,
      private _altaMonotributistaService: AltaMonotributistaService,
      private formBuilder: FormBuilder,
      private dialog: MatDialog,
      private router: Router,
      private meta: Meta) { 
        const description = this.meta.getTag('name=description');
        this.meta.removeTagElement(description);
        const keywords = this.meta.getTag('name=keywords');
        this.meta.removeTagElement(keywords);
      }

  ngOnInit(): void {
    this.formAlta = new FormGroup({
    nombre: this.nombre,
    apellido: this.apellido,
    email: this.email,
    CUIT: this.CUIT,
    codigoarea: this.codigoarea,
    telefono: this.telefono,
    numero_formulario: this.numero_formulario,
    adjunto1: this.adjunto1,
    adjunto2: this.adjunto2,
    adjunto3: this.adjunto3,
    });
  }

  openDialog(){
    this.dialog.open(DialogImgFormComponent, {
      width: '500px',
      
    });    
  }
}
