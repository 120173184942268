import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultaMisPagosComponent } from './consulta-mis-pagos.component';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

const routes = [
  {
      path     : 'consulta-mis-pagos',
      component: ConsultaMisPagosComponent
  }
];


@NgModule({
  declarations: [ConsultaMisPagosComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatButtonModule
  ]
})
export class ConsultaMisPagosModule { }
