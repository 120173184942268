<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
               
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_altausuarios.svg" class="icon">
        </div> 

        <div class="menu-aside" >
            <mat-nav-list >
                <mat-list-item routerLink="/misdatos/solicitar-rol">Solicitar nuevo Rol</mat-list-item>
                <mat-list-item routerLink="/misdatos/actualizar-datos">Actualizar datos</mat-list-item>
                <mat-list-item routerLink="/misdatos/cambiar-contraseña">Cambiar contraseña</mat-list-item>
                <mat-list-item routerLink="/misdatos/cambiar-email">Cambiar e-mail</mat-list-item>
              </mat-nav-list>
        </div>

    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <span>Mis Datos</span>
        </div>

        <h2 class="page-subtitle">Mis Datos</h2>
        
      
        <button id="btnMenuAside" fxFlex="10" fxShow.lt-md fxHide.gt-sm mat-button [matMenuTriggerFor]="menu">Menú</button>
        <mat-menu #menu="matMenu" id="menuAsideMobile">
            <button mat-menu-item >             
                <span id="solicitarRol" routerLink="/misdatos/solicitar-rol">Solicitar nuevo Rol</span>
            </button>
            <button mat-menu-item  >               
                <span id="actualizarDatos" routerLink="/misdatos/actualizar-datos">Actualizar datos</span>
            </button>
            <button mat-menu-item  >                
            <span id="cambiarContrasenia" routerLink="/misdatos/cambiar-contraseña">Cambiar contraseña</span>
                </button>
            <button mat-menu-item  >                  
                <span id="cambiarEmail" routerLink="/misdatos/cambiar-email">Cambiar e-mail</span>
            </button>
        </mat-menu>
    

        <p>Edite y actualice sus datos de usuario.</p>
        
        <table id="tablaMisdatos">
          
            <tr class="odd">
                <th>Nombre</th>
                <td>{{ misdatos ? misdatos.nombre + ' ' + misdatos.apellido: '' }}</td>
            </tr>
            <tr class="even">
                <th>Nombre Usuario</th>
                <td>{{misdatos ? misdatos.nombre_usuario : ''}}</td>
            </tr>
            <tr class="odd">
                <th>Cuil</th>
                <td>{{ cuil }}</td>
            </tr>
            <tr class="even">
                <th>Documento</th>
                <td>{{misdatos ? misdatos.documento_numero : ''}}</td>
            </tr>
            <tr class="odd">
                <th>Email</th>
                <td>{{misdatos ? misdatos.email : ''}}</td>
            </tr>
            <tr class="even">
                <th>Cargo</th>
                <td>{{misdatos ? misdatos.cargo : ''}}</td>
            </tr>
            <tr class="odd">
                <th>Teléfono</th>
                <td>{{misdatos ? misdatos.codigo_area + misdatos.telefono : ''}}</td>
            </tr>
            <tr class="even">
                <th>Calle</th>
                <td>{{misdatos ? misdatos.calle : ''}}</td>
            </tr>
            <tr class="odd">
                <th>Número</th>
                <td>{{misdatos ? misdatos.numero : ''}}</td>
            </tr>
            <tr class="even">
                <th>Piso</th>
                <td>{{misdatos ? misdatos.piso : ''}}</td>
            </tr>
            <tr class="odd">
                <th>Cod Postal</th>
                <td>{{misdatos ? misdatos.cod_postal : ''}}</td>
            </tr>
            <tr class="even">
                <th>Provincia</th>
                <td>{{ provDsc ? provDsc : (misdatos ? misdatos.provincia : '')}}</td>
            </tr>
            <tr class="odd">
                <th>Observaciones</th>
                <td>{{misdatos ? misdatos.observaciones : ''}}</td>
            </tr>
            <tr class="even">
                <th>Ultimo Login</th>
                <td>{{ lastLogin }}</td>
            </tr>

            
          </table>
        
    </div>


</div>

