<!-- <div id="register" fxLayout="column">

    <div id="register-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="register-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/fuse.svg">
            </div>

            <div class="title">CREATE AN ACCOUNT</div>

            <form name="registerForm" [formGroup]="registerForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error>
                        Name is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="registerForm.get('email').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('email').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Password is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password (Confirm)</mat-label>
                    <input matInput type="password" formControlName="passwordConfirm">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="registerForm.get('passwordConfirm').hasError('required')">
                        Password confirmation is required
                    </mat-error>
                    <mat-error *ngIf="!registerForm.get('passwordConfirm').hasError('required') &&
                                       registerForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        Passwords must match
                    </mat-error>
                </mat-form-field>

                <div class="terms" fxLayout="row" fxLayoutAlign="center center">
                    <mat-checkbox name="terms" aria-label="I read and accept" required>
                        <span>I read and accept</span>
                    </mat-checkbox>
                    <a href="#">terms and conditions</a>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="CREATE AN ACCOUNT"
                        [disabled]="registerForm.invalid">
                    CREATE AN ACCOUNT
                </button>

            </form>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Already have an account?</span>
                <a class="link" [routerLink]="'/pages/auth/login'">Login</a>
            </div>

        </div>

    </div>

</div> -->

<!-- <div class="page-layout blank p-24" fusePerfectScrollbar>

    <div fxLayout="row" fxLayoutAlign="space-around">
        <div fxFlex="20" fxHide.xs class="aside-list">
            <div>
                <img src="assets/images/iconos/icono_altausuarios.svg">
            </div>
        </div>
        <div fxFlex="70" fxFlex.xs="100">
            <div class="breadcrumbs">
                <a [routerLink]="['/home']">Inicio</a> » <span>Registro de Usuario</span>
            </div>

            <div class="row">

                <h1 class="h1-registro">Registros</h1>
                <p class="p-option">Seleccione si usted es Empresa, Beneficiario o Red Prestadora</p>
                <div>
                    <section class="container">
                        <mat-radio-group style = "display: flex;justify-content: space-around;" [(ngModel)]="checked">
                            <mat-radio-button   >Beneficiario</mat-radio-button>
                            <mat-radio-button   >Empresa</mat-radio-button>
                            <mat-radio-button   >Red Prestadora</mat-radio-button>
                          </mat-radio-group>
                        <button class="button" click="checkSignIn()" mat-raised-button>Continuar</button>
                        <mat-checkbox
                            [(ngModel)]="checked"
                            [(indeterminate)]="indeterminate"
                            [labelPosition]="labelPosition"
                            [disabled]="disabled">
                            I'm a checkbox
                        </mat-checkbox>
                        <button *ngIf ="[(ngModel)]= checked"  class="button" click="checkSignIn()" mat-raised-button>Continuar</button> 

                      </section>
                </div>
            </div>

        </div>
    </div>

 

</div> -->

<div class="page-layout blank p-24" fusePerfectScrollbar>

   
    <div fxFlex="20" fxHide.xs class="aside-list">        
            <img src="assets/images/iconos/icono_altausuarios.svg">        
    </div>

    <div fxFlex="80" fxFlex.xs="100" fxLayout.xs="column"> 

        <div class="breadcrumbs" fxFlex.xs="100"> 
            <a href="/">Inicio</a> » <span>Registro de Usuario</span>
        </div>

        <div class="registro" fxFlex.xs="100">
            
            <div class="form-registro">
                <h1>Registros</h1>
                <p>Seleccione si usted es Beneficiario, Empresa, Red Prestadora o Proveedor</p>
                <form action="" method="post">
                    <div id="tipo" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="left"> 
                        
                        <div class="box-inputs"><input [formControl]="tipo" value="0" id="tipo_0" checked="checked" type="radio" name="tipo"> <label for="tipo_0" class="label">Beneficiario</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div class="box-inputs"><input [formControl]="tipo" value="1" id="tipo_1" type="radio" name="tipo"> <label for="tipo_1" class="label">Empresa</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div class="box-inputs"><input [formControl]="tipo" value="2" id="tipo_2" type="radio" name="tipo"> <label for="tipo_2" class="label">Red Prestadora</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div class="box-inputs"><input [formControl]="tipo" value="3" id="tipo_3" type="radio" name="tipo"> <label for="tipo_3" class="label">Proveedor</label></div>
                    </div><br>
                </form>
                <button (click)="checkSignIn()" class="btn-continuar submit-button ng-tns-c392-13 mat-raised-button mat-button-base">
                    <span class="mat-button-wrapper"> Continuar </span>
                </button>
            </div>
        </div>
    </div>
    

</div>



