import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PrestacionesindexComponent } from "./prestacionesindex/prestacionesindex.component";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTab, MatTabsModule } from "@angular/material/tabs";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from "@angular/material/expansion";
import { FuseSharedModule } from "@fuse/shared.module";
import { FuseDemoModule } from "@fuse/components/demo/demo.module";
import { FuseHighlightModule } from "@fuse/components";
import { FuseProgressBarModule } from "@fuse/components";
import { MatCardModule } from "@angular/material/card";
import { LayoutModule } from "app/layout/layout.module";
import { NuevaPrestacionComponent } from "./nueva-prestacion/nueva-prestacion.component";
import { HttpClientModule } from "@angular/common/http";
import { LoginService } from "app/services/login.service";
import { MisdatosService } from "app/services/misdatos.service";
import { ModalDocumentacionBasicaComponent } from "../modal-documentacion-basica/modal-documentacion-basica.component";
import { MatDialogModule } from "@angular/material/dialog";
import { DocumentacionService } from "app/services/documentacion.service";
import { ModalUserInformationComponent } from "../modal-user-information/modal-user-information.component";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormPrestacionComponent } from './components/form-prestacion/form-prestacion.component';
import { FormsModule } from "@angular/forms";
import { PrestacionesAEnviarComponent } from './components/prestaciones-aenviar/prestaciones-aenviar.component';
import { DragDirective } from "./directives/dragDrop.directive";
import { SolicitudEnviadaComponent } from './solicitud-enviada/solicitud-enviada.component';
import { PrestacionObservadaComponent } from './components/prestacion-observada/prestacion-observada.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertaObervacionesDocumentacionComponent } from "./components/alerta-observacion-documentacion/alerta-observacion-documentacion.component";
import { DetallesPrestacionComponent } from "./detalles-prestacion/detalles-prestacion.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { FlexLayoutModule } from '@angular/flex-layout';

import { GrillaPrestacionesAprobadasRechazadasComponent } from "../grilla-prestaciones-aprobadas-rechazadas/grilla-prestaciones-aprobadas-rechazadas.component";
import { GrillaPrestacionesComponent } from "../grilla-prestaciones/grilla-prestaciones.component";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from "@angular/material/snack-bar";

const routes = [
  {
    path: "discapacidad/prestaciones",
    component: PrestacionesindexComponent,
  },
  {
    path: "discapacidad/prestaciones/nueva-prestacion",
    component: NuevaPrestacionComponent,
  },
  {
    path: "discapacidad/prestaciones/solicitud-enviada",
    component: SolicitudEnviadaComponent,
  },
  {
    path: "discapacidad/prestaciones/detalles-prestacion",
    component: DetallesPrestacionComponent,
  }
];

@NgModule({
  declarations: [
    PrestacionesindexComponent,
    NuevaPrestacionComponent,
    ModalDocumentacionBasicaComponent,
    ModalUserInformationComponent,
    FormPrestacionComponent,
    PrestacionesAEnviarComponent,
    DragDirective,
    SolicitudEnviadaComponent,
    PrestacionObservadaComponent,
    AlertaObervacionesDocumentacionComponent,
    DetallesPrestacionComponent,
    GrillaPrestacionesAprobadasRechazadasComponent,
    GrillaPrestacionesComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatDatepickerModule,
    FuseSharedModule,
    FuseDemoModule,
    FuseHighlightModule,
    FuseProgressBarModule,
    MatCardModule,
    LayoutModule,
    HttpClientModule,
    FormsModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    FlexLayoutModule,
    MatSnackBarModule
  ],
  providers: [MisdatosService, LoginService, DocumentacionService, 
    { 
        provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, 
        useValue: {
            duration: undefined,
            horizontalPosition: "center",
            verticalPosition: "top",
        } 
    }
],
})
export class PrestacionesModule {}
