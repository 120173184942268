<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_aportes.svg" class="icon">
        </div>
    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">

        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a routerLink="/proveedores/comprobantes">Comprobantes</a> »
            <a routerLink="/proveedores/comprobantes/carga">Carga</a> » <span>Formulario</span>
        </div>

        <h2 class="page-subtitle">Carga de Comprobantes</h2>
        <div fxFlex="100" fxLayout="row" fxLayout.lt-md="column">

            <div fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlexOrder.lt-md="2" id="boxForm">
                <p>Por favor completa el siguiente formulario del beneficiario/a {{ BenTNomApe }}</p>
                <p>Los campos con * son obligatorios</p>

                <form [formGroup]="formCarga" enctype="multipart/form-data">

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth">
                            <mat-label>Obra social</mat-label>
                            <mat-select #os formControlName="obraSocial" id="obraSocial" required
                                (selectionChange)="os.value == 'OSEN' && showSnackNoSeraVisualizado()">
                                <mat-option>Seleccione Obra Social</mat-option>
                                <mat-option value="ASE">ASE</mat-option>
                                <mat-option value="OSEN">OSEN</mat-option>
                                <mat-option value="OSPM">OSPM</mat-option>
                            </mat-select>
                            <mat-error *ngIf="formCarga.get('obraSocial').invalid">Debe seleccionar Obra Social
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth">
                            <mat-label>Tipo de trámite</mat-label>
                            <mat-select formControlName="tipoTramite" required>
                                <mat-option>Seleccione Tipo de trámite</mat-option>
                                <mat-option value="FA">Facturación</mat-option>
                                <mat-option value="RF">Re-Facturación</mat-option>
                                <mat-option value="NC">Nota de Crédito</mat-option>
                            </mat-select>
                            <mat-error *ngIf="formCarga.get('tipoTramite').invalid">Debe seleccionar Tipo de trámite
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth">
                            <mat-label>Tipo de comprobante</mat-label>
                            <mat-select #tipoComprobante formControlName="tipoComprobante" required
                                (selectionChange)="tipoComprobante.value == 'NC' && showSnackNoSeraVisualizado()">
                                <mat-option>Seleccione Tipo de comprobante</mat-option>
                                <mat-option value="FC">Factura</mat-option>
                                <mat-option value="FE">Factura de Crédito MiPyme</mat-option>
                                <mat-option value="RC">Recibo</mat-option>
                                <mat-option value="NC">Nota de Crédito</mat-option>
                            </mat-select>
                            <mat-error *ngIf="formCarga.get('tipoComprobante').invalid">Debe seleccionar Tipo de
                                comprobante</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth">
                            <mat-label>Serie</mat-label>
                            <mat-select formControlName="tipoFactura" required>
                                <mat-option>Seleccione Serie</mat-option>
                                <mat-option *ngFor="let serie of tipoSeries" [value]="serie.serie">
                                    {{serie.serie}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formCarga.get('tipoFactura').invalid">Debe seleccionar la
                                serie</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth" fxFlex="40">
                            <mat-label>Punto de venta</mat-label>
                            <input matInput formControlName="puntoVenta" (focusout)="puntoVentaAddCharacter();"
                                (keypress)="keyPressNumbers($event)" maxlength="5" required type="text"
                                placeholder="12345">
                            <!-- onKeyPress="if(this.value.length==4) return false;" (keypress)="keyPressNumbers($event)"-->
                            <mat-error
                                *ngIf="formCarga.get('puntoVenta').invalid && formCarga.get('puntoVenta').hasError('pattern')">Debe
                                ingresar los 5 dígitos del
                                Punto de venta.</mat-error>
                        </mat-form-field>

                        <mat-form-field class="mat-fullwidth" fxFlex="60">
                            <mat-label>Comprobante</mat-label>
                            <input matInput formControlName="comprobante" required type="text" maxlength="8"
                                placeholder="12345678" (focusout)="comprobanteAddCharacter();"
                                (keypress)="keyPressNumbers($event)">
                            <!-- onKeyPress="if(this.value.length==8) return false;" -->
                            <mat-error
                                *ngIf="formCarga.get('comprobante').invalid  && formCarga.get('comprobante').hasError('pattern')">Debe
                                ingresar los 8 dígitos del
                                comprobante.</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth" fxFlex="100">
                            <mat-label>Fecha de emisión del comprobante</mat-label>
                            <input matInput [matDatepicker]="fechaEmisionComprobante"
                                formControlName="fechaEmisionComprobante" required readonly="readonly">
                            <mat-datepicker-toggle matSuffix [for]="fechaEmisionComprobante"></mat-datepicker-toggle>
                            <mat-datepicker #fechaEmisionComprobante></mat-datepicker>
                            <mat-error *ngIf="formCarga.get('fechaEmisionComprobante').invalid">Debe seleccionar la
                                fecha emisión del comprobante.</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth" fxFlex="100">
                            <mat-label>Importe total</mat-label>
                            <input matInput formControlName="importeTotal" required type="number" min="0"
                                onKeyPress="if(this.value.length==13) return false;">
                            <mat-error *ngIf="formCarga.get('importeTotal').hasError('pattern')">Debe ingresar un
                                importe válido. Máximo 2 decimales.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <p id="textoImporte">No se aceptan comprobantes con IVA</p>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth">
                            <mat-label>Mes</mat-label>
                            <mat-select formControlName="mes" required>
                                <mat-option>Seleccione mes</mat-option>
                                <mat-option *ngFor="let option of meses" [value]="option.fullValue">
                                    {{ option.year + ' ' + option.month}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formCarga.get('mes').invalid">Debe seleccionar Mes</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth" fxFlex="100">
                            <mat-label>Kilómetros/Sesiones</mat-label>
                            <input matInput formControlName="kmSesiones" required type="number" min="1"
                                (input)="superaPrestacion($event.target.value)">
                            <mat-error *ngIf="formCarga.get('kmSesiones').invalid ">Debe ingresar Kilometros / Sesiones.
                            </mat-error>

                        </mat-form-field>
                    </div>

                    <h4>DATOS FISCALES</h4>
                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth" fxFlex="100">
                            <mat-label>Código de autorización (CAE)</mat-label>
                            <input matInput formControlName="codAutCAE" required type="number"
                                onKeyPress="if(this.value.length==14) return false;">
                            <mat-error
                                *ngIf="formCarga.get('codAutCAE').invalid || formCarga.get('codAutCAE').hasError('pattern')">Debe
                                ingresar los 14 dígitos del
                                Código de autorización CAE.</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth">
                            <mat-label>Tipo CAE</mat-label>
                            <mat-select formControlName="tipoCAE" [(ngModel)]="tipoCAEselected">

                                <mat-option value="CAE">CAE</mat-option>
                                <mat-option value="CAEA">CAEA</mat-option>
                                <mat-option value="CAI">CAI</mat-option>
                                <mat-option value="CF">CF</mat-option>

                            </mat-select>

                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth" fxFlex="100">
                            <mat-label>Fecha de vencimiento de CAE</mat-label>
                            <input matInput [matDatepicker]="fechaVencimientoCAE" formControlName="fechaVencimientoCAE"
                                required [min]="minFactCAE" [max]="maxFactCAE" readonly="readonly"
                                [disabled]="vencimientoCAEDisabled">
                            <mat-datepicker-toggle matSuffix [for]="fechaVencimientoCAE"></mat-datepicker-toggle>
                            <mat-datepicker #fechaVencimientoCAE></mat-datepicker>
                            <mat-error *ngIf="formCarga.get('fechaVencimientoCAE').invalid">Debe seleccionar la fecha de
                                vencimiento CAE.</mat-error>
                        </mat-form-field>
                    </div>
                </form>
            </div>
            <div fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlexOrder.lt-md="1" id="datosContacto">
                <mat-card>
                    <h4>Datos de contacto</h4>
                    <p>Es importante que los datos de contacto se encuentren actualizados para responder a todas sus
                        consultas.</p>

                    <table id="misDatos">
                        <tr class="odd">
                            <th>Dirección</th>
                            <td>{{ Ddomicilio }} </td>
                        </tr>
                        <tr class="even">
                            <th>Teléfono</th>
                            <td>{{ Telefono }}</td>
                        </tr>
                        <tr class="odd">
                            <th>Email</th>
                            <td>{{ Email }}</td>
                        </tr>
                        <tr class="even">
                            <th>CBU </th>
                            <td>{{ cbu }}</td>
                        </tr>
                    </table>
                    <div id="boxButton">
                        <button mat-button (click)="btnActualizarData()" id="btnActualizarDatos">Actualizar
                            datos</button>
                    </div>
                </mat-card>
            </div>
        </div>

        <div id="adjuntos">
            <form [formGroup]="formCarga" enctype="multipart/form-data">
                <mat-accordion multi="true" id="accordion">
                    <mat-expansion-panel disabled expanded>
                        <mat-expansion-panel-header class="fixed-header">
                            <mat-panel-title>
                                <p>
                                    <mat-icon matSuffix>folder</mat-icon><strong>Adjuntar Documentación</strong>
                                    3MB máximo. Formatos válidos: Comprobantes PDF. Planilla de Asistencia DOC, PDF,
                                    JPG, PNG.
                                </p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="data">
                            <div class="tw">
                                <mat-form-field appearance="standard" class="mat-fullwidth">
                                    <mat-label>Comprobante *</mat-label>
                                    <ngx-mat-file-input formControlName="adjComprobante" #adjComprobante
                                        [accept]="'.pdf'"
                                        (click)="reminder()">
                                    </ngx-mat-file-input>
                                    <mat-icon matSuffix *ngIf="adjComprobante.empty">add</mat-icon>
                                    <button mat-icon-button matSuffix *ngIf="!adjComprobante.empty"
                                        (click)="adjComprobante.clear($event)">
                                        <mat-icon>clear</mat-icon>
                                    </button>
                                    <mat-error *ngIf="formCarga.get('adjComprobante').hasError('maxContentSize')  
                                || formCarga.get('adjComprobante').hasError('required')                                  
                                || formCarga.get('adjComprobante').hasError('mimeType')">Debe adjuntar un archivo (
                                        PDF, 3MB máximo).
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="data">
                            <div class="tw">
                                <mat-form-field appearance="standard" class="mat-fullwidth">
                                    <mat-label>Planilla de asistencia</mat-label>
                                    <ngx-mat-file-input formControlName="adjPlanilla" #adjPlanilla
                                        [accept]="'.jpg, .png, .pdf, .doc, .docx'">
                                    </ngx-mat-file-input>
                                    <mat-icon matSuffix *ngIf="adjPlanilla.empty">add</mat-icon>
                                    <button mat-icon-button matSuffix *ngIf="!adjPlanilla.empty"
                                        (click)="adjPlanilla.clear($event)">
                                        <mat-icon>clear</mat-icon>
                                    </button>
                                    <mat-error *ngIf="formCarga.get('adjPlanilla').hasError('maxContentSize')                                  
                                || formCarga.get('adjPlanilla').hasError('mimeType')">Debe adjuntar un archivo (DOC,
                                        PDF, JPG ó PNG, 3MB máximo).
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <div fxLayout="row">
                    <div *ngIf="validAfip===false; else elseBtnAfip"><button type="button" mat-raised-button
                            id="btnEnviar" (click)="validateAfip()">Validar AFIP</button></div>
                    <ng-template #elseBtnAfip><button type="button" mat-raised-button id="btnDisabled"
                            (click)="validateAfip()" class="btnDisabled" [disabled]="true">Validar
                            AFIP</button></ng-template>
                    <div *ngIf="validAfip===true; else elseBtnEnviar"><button type="button" mat-raised-button
                            id="btnEnviar" (click)="btnEnviar()">Enviar</button></div>
                    <ng-template #elseBtnEnviar><button type="button" mat-raised-button id="btnDisabled"
                            (click)="btnEnviar()" class="btnDisabled" [disabled]="true">Enviar</button></ng-template>
                </div>
            </form>
        </div>
    </div>
</div>