import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
import { MisdatosService } from '../../../../../services/misdatos.service';
import { LoginService } from 'app/services/login.service';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FileInput, FileValidator } from 'ngx-material-file-input';
import { CargaFacturaService } from 'app/services/carga-factura.service';
import * as dayjs from 'dayjs';
import { max } from 'lodash';
import { formatDate } from '@angular/common';
import { cuitValidator } from 'app/shared/cuit-validator.directive';


@Component({
    selector: 'app-carga-factura-admin',
    templateUrl: './carga-factura-admin.component.html',
    styleUrls: ['./carga-factura-admin.component.scss']
})
export class CargaFacturaAdminComponent implements OnInit {

    constructor(private router: Router,
        private _misdatos: MisdatosService,
        private _cargaFactura: CargaFacturaService,
        private getSessionData: LoginService,
        private _snackBar: MatSnackBar,
        private dateAdapter: DateAdapter<Date>,
        private activatedRoute: ActivatedRoute) {
        this.dateAdapter.setLocale('en-GB');
    }

    misdatos: any;


    tipoCAEselected = "CAE";
    minFactCAE: any;
    maxFactCAE: any;
    btnEnviarDisabled: boolean = false;
    vencimientoCAEDisabled = true;
    cuatroMeses: any;
    mesAnterior: any;
    isAse: Boolean = false;
    tipoSeries = [];
    serie1 = [{ serie: "A" }, { serie: "C" }, { serie: "M" }];
    serie2 = [{ serie: "B" }, { serie: "C" }];

    readonly maxSize = 3145728;

    formCarga: FormGroup;
    cuit = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{11,11}$'), Validators.maxLength(11), this.validateCuit])
    obraSocial = new FormControl('', [Validators.required]);
    tipoTramite = new FormControl('', [Validators.required]);
    tipoComprobante = new FormControl('', [Validators.required]);
    tipoFactura = new FormControl('', [Validators.required]);
    fechaEmisionComprobante = new FormControl('', [Validators.required]);
    puntoVenta = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{1,4}$')]);
    comprobante = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{1,8}$')]);
    importeTotal = new FormControl('', [Validators.required, Validators.min(0), Validators.pattern('^[0-9]*\.[0-9]{1,2}$')]);
    codAutCAE = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{14,14}$')]);
    tipoCAE = new FormControl('');
    validAfip = false;
    encoder = new TextEncoder();
    decoder = new TextDecoder();

    pdfDocValidator(control: FormControl) {
        if (!control.value)
            return;
        const type = control.value.files[0].type;
        if (type &&
            type != 'application/pdf' &&
            type != 'application/msword' &&
            type != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            return {
                mimeType: {
                    type: type
                }
            }
        }
        return null;
    }

    redirect(): void {
        setTimeout(function () {
            window.location.href = environment.urlWebsite + 'proveedores/comprobantes/carga/inicio';
        }, 3000);
    }

    btnActualizarData(): void {
        this.router.navigate(['proveedores/mis-datos']);
    }

    formatToDot(x) {
        return Number.parseFloat(x).toFixed(2);
    }

    //Función para solo permitir numeros
    keyPressNumbers(event) {
        let charCode = (event.which) ? event.which : event.keyCode;
        if ((charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        } else {
            return true;
        }
    }

    //Funcion para agregar 0
    puntoVentaAddCharacter() {
        let valor = this.puntoVenta.value;

        //Si no es un numero, se vaciará el contenido del input (previene copy-paste de letras)
        if (isNaN(valor)) {
            valor = '';
            this.formCarga.controls['puntoVenta'].setValue(valor);
        }

        switch (this.puntoVenta.value.length) {
            case 0:
                valor = '0000';
                this.formCarga.controls['puntoVenta'].setValue(valor);
                break;
            case 1:
                valor = '000' + this.puntoVenta.value;
                this.formCarga.controls['puntoVenta'].setValue(valor);
                break;
            case 2:
                valor = '00' + this.puntoVenta.value;
                this.formCarga.controls['puntoVenta'].setValue(valor);
                break;
            case 3:
                valor = '0' + this.puntoVenta.value;
                this.formCarga.controls['puntoVenta'].setValue(valor);
                break;
            case 4:
                break;
            default:
                break;
        }
    }

    //Funcion para agregar 0
    comprobanteAddCharacter() {
        let valor = this.comprobante.value;

        //Si no es un numero, se vaciará el contenido del input (previene copy-paste de letras)
        if (isNaN(valor)) {
            valor = '';
            this.formCarga.controls['comprobante'].setValue(valor);
        }

        switch (this.comprobante.value.length) {
            case 0:
                valor = '00000000';
                this.formCarga.controls['comprobante'].setValue(valor);
                break;
            case 1:
                valor = '0000000' + this.comprobante.value;
                this.formCarga.controls['comprobante'].setValue(valor);
                break;
            case 2:
                valor = '000000' + this.comprobante.value;
                this.formCarga.controls['comprobante'].setValue(valor);
                break;
            case 3:
                valor = '00000' + this.comprobante.value;
                this.formCarga.controls['comprobante'].setValue(valor);
                break;
            case 4:
                valor = '0000' + this.comprobante.value;
                this.formCarga.controls['comprobante'].setValue(valor);
                break;
            case 5:
                valor = '000' + this.comprobante.value;
                this.formCarga.controls['comprobante'].setValue(valor);
                break;
            case 6:
                valor = '00' + this.comprobante.value;
                this.formCarga.controls['comprobante'].setValue(valor);
                break;
            case 7:
                valor = '0' + this.comprobante.value;
                this.formCarga.controls['comprobante'].setValue(valor);
                break;
            case 8:
                break;
            default:
                break;
        }
    }

    ngOnInit(): void {
        this.formCarga = new FormGroup({
            cuit: this.cuit,
            obraSocial: this.obraSocial,
            tipoTramite: this.tipoTramite,
            tipoComprobante: this.tipoComprobante,
            tipoFactura: this.tipoFactura,
            puntoVenta: this.puntoVenta,
            comprobante: this.comprobante,
            fechaEmisionComprobante: this.fechaEmisionComprobante,
            importeTotal: this.importeTotal,
            codAutCAE: this.codAutCAE,
            tipoCAE: this.tipoCAE,
        });

        this.getSessionData.getAll().subscribe(resp => {
            if (!resp['isLogged'])
                this.router.navigate(['/login']);

            this.getSessionData.getSessionData().subscribe(result => {
                if (!result['rol'] || result['rol'] != 'ASEADM')
                    this.router.navigate(['/login']);

                this.tipoSeries = this.serie1;
                this.formCarga.get('obraSocial').valueChanges.subscribe((event) => {
                    if (event !== undefined) {
                        if (this.formCarga.get('obraSocial').value == "ASE")
                            this.tipoSeries = this.serie1;
                        else
                            this.tipoSeries = this.serie2;
                    }
                });

                //min fechaEmisionComprobante
                var d = new Date();
                d.setMonth(d.getMonth() - 4);
                this.cuatroMeses = new Date(d.getFullYear(), d.getMonth(), 1);
                //max fechaEmisionComprobante
                var fechaCarga = new Date();
                this.mesAnterior = new Date(fechaCarga.getFullYear(), fechaCarga.getMonth(), 0);

                //me suscribo a fechaEmisionComprobante para definir en fact CAE los min y max + 10dias
                this.formCarga.get('fechaEmisionComprobante').valueChanges.subscribe((evento) => {
                    if (evento instanceof Date) {
                        this.vencimientoCAEDisabled = false;
                        this.minFactCAE = evento;
                        var dateCopy = new Date(evento.getTime());
                        dateCopy.setDate(dateCopy.getDate() + 10);
                        this.maxFactCAE = dateCopy;
                    }
                    else
                        this.vencimientoCAEDisabled = true;
                });
            });
        });
    }

    reload(): void {
        setTimeout(function () {
            this.linkCargaComprobante();
        }, 6000);
    }

    validateAfip() {
        this.formCarga.markAllAsTouched();
        if (!this.formCarga.valid) {
            this._snackBar.open('Recordá que debes completar todos los campos correctamente ', 'Cerrar', {
                duration: undefined,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
            return;
        }
        var date = this.fechaEmisionComprobante.value;
        var year = date.getFullYear();
        var month = date.getMonth();
        month += 1;
        if (month < 10)
            month = '0' + month.toString();

        var day = date.getDate();
        if (day < 10)
            day = '0' + day.toString();

        var fechaEmisionComprobanteFormateada = year.toString() + month.toString() + day.toString();

        const formData = new FormData();
        formData.append('tipoCAE', this.tipoCAE.value);
        formData.append('cuit', this.cuit.value);
        formData.append('puntoVenta', this.puntoVenta.value);
        formData.append('tipoComprobante', this.tipoComprobante.value);
        formData.append('tipoFactura', this.tipoFactura.value);
        formData.append('comprobante', this.comprobante.value);
        formData.append('fechaEmisionComprobante', fechaEmisionComprobanteFormateada);
        formData.append('importeTotal', this.importeTotal.value);
        formData.append('codAutCAE', this.codAutCAE.value);
        formData.append('obraSocial', this.obraSocial.value);

        formData.forEach((value, key) => {
            console.log(JSON.stringify(key) + JSON.stringify(value))
        });

        this._cargaFactura.validateAfip(formData)
            .subscribe(resp => {
                if (resp.info == 'Solicitud Aprobada') {
                    this._snackBar.open('Validado correctamente', 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                }
            },
                err => {
                    var msje = err['error']['message'];
                    this.transform(msje);
                    this._snackBar.open(msje, 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                });
    }

    transform(value: string): string {
        value = value.replace("v�lida", "válida");
        value = value.replace("emisi�n", "emisión");
        value = value.replace("n�mero", "número");
        value = value.replace("N�", "N°");

        return value;
    }

    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }


    validateCuit(control: AbstractControl): { [key: string]: any } | null {
        let cuit = String(control.value);
        if (!control.value) {
            return null;
        }
        if (cuit.length !== 11) {
            return { cuit: { value: 'El campo CUIT debe tener 11 caracteres.' } };
        }

        let acumulado = 0;
        let digitos = cuit.split('');
        let digito = digitos.pop();

        for (let i = 0; i < digitos.length; i++) {
            acumulado += Number(digitos[9 - i]) * (2 + (i % 6));
        }

        let verif = 11 - (acumulado % 11);
        if (verif === 11) {
            verif = 0;
        } else if (verif === 10) {
            verif = 9;
        }

        return (Number(digito) == Number(verif)) ? null : { cuit: { value: 'El CUIT ingresado es inválido. ' } };
    }

    clear() {
        this.formCarga.setValue({
            cuit: '',
            obraSocial: '',
            tipoTramite: '',
            tipoComprobante: '',
            tipoFactura: '',
            fechaEmisionComprobante: '',
            puntoVenta: '',
            comprobante: '',
            importeTotal: '',
            codAutCAE: '',
            tipoCAE: '',
        })
      }
}