import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ComprobantesComponent } from './comprobantes.component';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseHighlightModule } from '@fuse/components';

// angular material
import {MatExpansionModule} from '@angular/material/expansion';


const routes = [
  {
      path     : 'proveedores/comprobantes',
      component: ComprobantesComponent
  }
];

@NgModule({
  declarations: [
    ComprobantesComponent 
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    MatExpansionModule,
    FuseSharedModule,
    FuseHighlightModule
  ]
})
export class ComprobantesModule { }
