import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CambiarEmailProveedorComponent } from './cambiar-email-proveedor.component';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

const routes = [
  {
      path     : 'cambiar-email-proveedor',
      component: CambiarEmailProveedorComponent
  }
];

@NgModule({
  declarations: [CambiarEmailProveedorComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule
  ]
})
export class CambiarEmailProveedorModule { }
