import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'app/services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MisPagosService } from 'app/services/mis-pagos.service';
import { environment } from 'environments/environment';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { format } from 'date-fns'
import { Subscription } from 'rxjs';
const FileSaver = require('file-saver');

@Component({
    selector: 'app-mis-pagos',
    templateUrl: './mis-pagos.component.html',
    styleUrls: ['./mis-pagos.component.scss']
})
export class MisPagosComponent implements OnInit {
    mispagos = [];
    empty = true;
    filter = '';
    isDataLoaded= true;
    displayedColumns: string[] = ['Mes', 'Tipo de Factura', 'Punto de Venta', 'Número de Factura', 'Importe de la Factura', 'Estado', 'Número de Trámite', 'Número de Liquidación', 'Fecha de Pago', 'Adjuntos', 'Fecha de Carga'];
    dataSource = new MatTableDataSource<any>();
    isFactura: Boolean = false;
    isNotaDebito: Boolean = false;
    noData = false;
    consultaPagos= true;
    esSABPRV= false;
    SABCUIT; 
    subscriptions = new Subscription();


    @ViewChild(MatPaginator) paginator: MatPaginator;

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        var newSource = [];

        this.mispagos.forEach(function (pago) {
            if (filterValue != '' &&
            !pago['periodoFactura'].toString().toLowerCase().includes(filterValue) &&
            !pago['TipoFactura'].toString().toLowerCase().includes(filterValue) &&
            !pago['SucurFactura'].toString().toLowerCase().includes(filterValue) &&
            !pago['NroFactura'].toString().toLowerCase().includes(filterValue) &&
            !pago['ImpFactura'].toString().toLowerCase().includes(filterValue) &&
            !pago['Estado'].toString().toLowerCase().includes(filterValue) &&
            !pago['NroReintegro'].toString().toLowerCase().includes(filterValue) &&
            !pago['FechaPago'].toString().toLowerCase().includes(filterValue) &&
            !pago['InciWeb'].toString().toLowerCase().includes(filterValue) &&
            !pago['FchReintegro'].toString().toLowerCase().includes(filterValue) &&
            !this.getMonthNameSpanish(pago['periodoFactura']).toString().toLowerCase().includes(filterValue)
        )
            return;
            newSource.push(pago);
        });

        this.dataSource = new MatTableDataSource(newSource);
        this.dataSource.paginator = this.paginator;
    }

    constructor(private _cargaMisPagos: MisPagosService,
        private router: Router,
        private getSessionData: LoginService,
        private _snackBar: MatSnackBar,
        private _activatedRoute: ActivatedRoute
    ) {
        // recibo el cuit desde consulta-mis-pagos para rol SAB Proveedor (ASEPRV) 
        this.SABCUIT = history.state.consultaCUIT;
        
        if(this.SABCUIT) 
            this.esSABPRV = true;
        if (!this.SABCUIT) {
          const aRsubscription = this._activatedRoute.queryParams.subscribe(
            (params) => {
              this.SABCUIT = params["cuit"];
            }
          );
          this.subscriptions.add(aRsubscription);
          if (this.SABCUIT) this.subscriptions.remove(aRsubscription);
        }

        this.getSessionData.getSessionData().subscribe(result => {
            
            if (result['rol'] || result['rol'] == 'PRVGRL' || result['rol'] == 'ASEPRV'){
            
                if (result['rol'] != 'ASEPRV' && result['rol'] == 'PRVGRL')
                      result.cuit;
                else  result.cuit = this.SABCUIT; 
            
                this._cargaMisPagos.getAllPagos(result.cuit).subscribe(data => {
                    this.mispagos = data;
                    if (this.mispagos[0]){
                        this.empty = false;
                    this.dataSource = new MatTableDataSource<any>(this._parseData(data));
                    this.isDataLoaded = true;
                    this.dataSource.paginator = this.paginator;
                    }else{
                    this.noData= true;
                    }
                })
            } else {                
                this.router.navigate(['/']);  
            }
        });
    }

    //traigo las propiedades que me interesan mostrar en la tabla
    private _parseData(data: any) {
        return data.map(
            ({periodoFactura, TipoFactura, SucurFactura, NroFactura, ImpFactura,Estado, InciWeb,NroReintegro, FechaPago, FchReintegro}) => ({
                periodoFactura: this._formatPeriodoFactura(periodoFactura), TipoFactura, SucurFactura, NroFactura, ImpFactura,Estado, InciWeb, NroReintegro, FechaPago, FchReintegro
        }))
    }

    private _formatPeriodoFactura(periodoFactura: string) {
        return periodoFactura ? periodoFactura.substring(0, 4).toString() + ' ' + this.getMonthNameSpanish(periodoFactura.substring(4, 6).toString()) : '';
    }

    exportarMisPagosPDF() {
        if (this.empty)
            return;

        var content = [];
        for (var i in this.mispagos) {

            if (this.filter != '' &&
                !this.mispagos[i]['periodoFactura'].toString().toLowerCase().includes(this.filter) &&
                !this.mispagos[i]['TipoFactura'].toString().toLowerCase().includes(this.filter) &&
                !this.mispagos[i]['SucurFactura'].toString().toLowerCase().includes(this.filter) &&
                !this.mispagos[i]['NroFactura'].toString().toLowerCase().includes(this.filter) &&
                !this.mispagos[i]['ImpFactura'].toString().toLowerCase().includes(this.filter) &&
                !this.mispagos[i]['Estado'].toString().toLowerCase().includes(this.filter) &&
                !this.mispagos[i]['NroReintegro'].toString().toLowerCase().includes(this.filter) &&
                !this.mispagos[i]['FechaPago'].toString().toLowerCase().includes(this.filter) &&
                !this.mispagos[i]['InciWeb'].toString().toLowerCase().includes(this.filter) &&
                !this.mispagos[i]['FchReintegro'].toString().toLowerCase().includes(this.filter)
            )
                continue;

            let periodo = this.mispagos[i].periodoFactura ? this.mispagos[i].periodoFactura.substring(0, 4).toString() + ' ' + this.getMonthNameSpanish(this.mispagos[i].periodoFactura.substring(4, 6).toString()) : ''
            var reg = [
                periodo,
                this.mispagos[i]['TipoFactura'],
                this.mispagos[i]['SucurFactura'],
                this.mispagos[i]['NroFactura'],
                this.mispagos[i]['ImpFactura'],
                this.mispagos[i]['Estado'],
                this.mispagos[i]['InciWeb'],
                this.mispagos[i]['NroReintegro'],
                this.mispagos[i]['FechaPago'],
                this.mispagos[i]['FchReintegro'],
            ];

            content.push(reg);
        }

        const doc = new jsPDF('landscape');
        var rowIndex = 0;
        var pdfContent = [];
        for (var j in content) {
            pdfContent.push(content[j]);
            rowIndex++;
            if (rowIndex % 10 == 0 || rowIndex == content.length) {
                var img = new Image()
                img.src = 'assets/images/logos/logo_ase.png'
                doc.addImage(img, 'png', 10, 10, 44, 15);

                autoTable(doc, {
                    headStyles: { halign: 'center', fillColor: '#e75300', textColor: '#ffffff' },
                    startY: 30,
                    head: [["CONSULTA DE PAGOS"]],
                    body: [],
                })

                autoTable(doc, {
                    theme: 'grid',
                    startY: 40,
                    headStyles: { halign: 'center', fillColor: '#e75300', textColor: '#ffffff' },
                    head: [['Mes', 'Tipo Comprobante', 'Pto Venta', 'Comprobante', 'Importe Total', 'Estado', 'Nro Trámite', 'Nro Liquidación', 'Fecha Pago', 'Fecha Carga']],
                    body: pdfContent,
                    columnStyles: {
                        [0]: { halign: 'left' },
                        [2]: { halign: 'right' },
                        [3]: { halign: 'right' },
                        [4]: { halign: 'right' },
                        [6]: { halign: 'right' },
                        [7]: { halign: 'right' },
                        [8]: { halign: 'right' },
                        [9]: { halign: 'right' }
                    }
                })

                if (rowIndex == content.length) {
                    var date = new Date()
                    autoTable(doc, {
                        headStyles: { halign: 'right', fillColor: '#ffffff', textColor: '#000000' },
                        head: [["Fecha: " + format(date, 'dd/MM/yyyy')]],
                        body: [],
                    })
                }

                doc.setFontSize(10);
                var str = "Se deja constancia que la información de la presenta plantilla se encuentra actualizada a la última\n fecha de cruce de datos entre la entidad sanitaria y la Administración General de Ingresos Públicos, S.E.U.O.";
                str = str + "\nAcción Social de Empresarios | Lima 87 8° piso - Tel 4124 2800 | Buenos Aires - (C1073AAA)";
                str = str + "\n0810 3333 273 | www.ase.com.ar"
                str = str + "\nSuperindentendencia de Servicios de Salud - Órgano de Control de Obras Sociales y Entidades de Medicinas\n Prepagas 0800 222 SALUD (72583) www.sssalud.gov.ar | R.N.O.S. 400909"
                str = str + "\nPágina " + (doc as any).internal.getNumberOfPages();

                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height
                    ? pageSize.height
                    : pageSize.getHeight();
                doc.text(str, 150, pageHeight - 26, { align: 'center' });

                pdfContent = [];
                if (rowIndex < content.length)
                    doc.addPage();
            }
        }
        doc.save('Pagos Proveedor.pdf');
    }

    verAdjuntos(NroFactura, NroReintegro, InciWeb, TipoFactura) {
        console.log(TipoFactura);
        this.getSessionData.getSessionData().subscribe(result => {
            if (TipoFactura === 'NOTA DE DEBITO') {
                this.router.navigate(['/proveedores/mis-pagos/adjunto'], {
                    state: { NroFactura, NroReintegro, InciWeb }
                });
                return;
            }
            else {

                let cuitConsulta;
                if (this.esSABPRV)
                    cuitConsulta = this.SABCUIT;
                else
                    cuitConsulta = result.cuit;

                this._cargaMisPagos.getAdjuntos(cuitConsulta, NroFactura)
                    .subscribe(result2 => {
                        if (result2.length == 0) {
                            this._snackBar.open('No se encontraron arhivos cargados.', 'Cerrar', {
                                duration: undefined,
                                horizontalPosition: 'center',
                                verticalPosition: 'top',
                            });
                        }
                        else if (result2.length > 0) {
                            let numComprobante = NroFactura;
                            //Fecha para el documento
                            const date = new Date();
                            const year = date.getFullYear();
                            const month = date.getMonth() + 1;
                            const day = date.getDate();
                            const hour = date.getHours();
                            const minutes = date.getMinutes();
                            const seconds = date.getSeconds();

                            for (var i in result2) {
                                console.log('creacion de archivos:');
                                let stringNombreFactura = year + month + day + hour + minutes + seconds + '_' + result2[i][1];
                                var blob = new Blob([new Uint8Array(result2[i][0].data).buffer], { type: this.getMimeType(stringNombreFactura.substring(stringNombreFactura.lastIndexOf('.') +1)) });
                                FileSaver.saveAs(blob, stringNombreFactura);
                            }
                        };
                    });
            }
        });
    }

    getFiles(): void {

    }

    ngOnInit(): void {
        this.getSessionData.getAll().subscribe(resp => {
            if (!resp['isLogged'])
                this.router.navigate(['/login'])

            if (environment.sessionId === 'undefined') {
                window.location.href = environment.urlWebSitePhp + 'site/logout';
                return;
            }
        });
    }

    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }

    getMonthNameSpanish(month) {
        switch (month) {
            case '1':
            case '01':
                return 'Enero';
            case '2':
            case '02':
                return 'Febrero';
            case '3':
            case '03':
                return 'Marzo';
            case '4':
            case '04':
                return 'Abril';
            case '5':
            case '05':
                return 'Mayo';
            case '6':
            case '06':
                return 'Junio';
            case '7':
            case '07':
                return 'Julio';
            case '8':
            case '08':
                return 'Agosto';
            case '9':
            case '09':
                return 'Septiembre';
            case '10':
                return 'Octubre';
            case '11':
                return 'Noviembre';
            case '12':
                return 'Diciembre';
            default:
                return '';
        }
    }
    
    getMimeType(extension){
        switch(extension.toLowerCase()){
            case 'pdf':
                return 'application/pdf'
            case 'docx':
                return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            case 'doc':
                return 'application/msword';
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg'
            case 'png':
                return 'image/png'
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}


