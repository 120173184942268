import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { AltaMonotributista } from 'app/models/altamonotributista.model';

@Injectable({
  providedIn: 'root'
})
export class AltaMonotributistaService {

  constructor(
    private http: HttpClient
  ) { }

  sendForm(altaMonotributista: FormData): Observable<any>{
    return this.http.post(`${environment.urlApi}altaMonotributista/sendPublicForm`, altaMonotributista);
  }   
    
}
