import { Component, OnInit } from '@angular/core';
import { LoginService } from 'app/services/login.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
@Component({
    selector: 'app-formularios',
    templateUrl: './formularios.component.html',
    styleUrls: ['./formularios.component.scss']
})
export class FormulariosComponent implements OnInit {

    env = environment;
    extension : string = '.pdf';
    isOSPM : Boolean = false;
    isLogged : boolean;
    rol: string;
    
    constructor(private _loginService: LoginService, private router: Router) { }

    ngOnInit(): void {

        this._loginService.getAll().subscribe(resp => {
            this.isLogged = resp['isLogged'];
            if(this.isLogged)
            {
                 this._loginService.getSessionData().subscribe(resp => {
                    this.rol = resp['rol'];
                    if (this.rol == 'BENOST' || this.rol == 'BENOSF'){
                      this.extension = '_OSPM.pdf'
                      this.isOSPM = true;
                    }
                });
             }
             else
                this.router.navigate(['/login'])
         });
      }

      linkPanel(){
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
      }
      linkMedicamentos (){
        window.location.href = environment.urlWebsite + 'medicamentos';
      }
}