import { Injectable } from "@angular/core";
import { interval } from "rxjs";
import { startWith, switchMap } from "rxjs/operators";
import { LoginService } from "../login.service";

@Injectable({
  providedIn: "root",
})
export class AuthSessionCheckService {
  constructor(private _loginService: LoginService) {}

  checkSessionStatus() {
    interval(20 * 60 * 1000)
      .pipe(
        startWith(0),
        switchMap(() => {
          return this._loginService.getAll();
        })
      )
      .subscribe((res) => {
        if (!res["isLogged"]) window.location.href = "/index.php/site/logout";
      });
  }
}
