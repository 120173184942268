<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>               
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_aportes.svg" class="icon">
        </div>
    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <span>Comprobantes</span>
        </div>

        <h2 class="page-subtitle">Comprobantes</h2>

        <div fxFlex.xs="100" id="panel-one">
            <mat-accordion hideToggle>                
                
                <mat-expansion-panel disabled class="list-item" [routerLink]="['/proveedores/comprobantes/carga']">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p class="listed-link">Carga de comprobantes</p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
                <mat-expansion-panel disabled class="list-item">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p class="listed-link" (click)="instructivoFacturacion()">Instructivo de facturación</p>                            
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
                                       
            </mat-accordion>                    
        </div> 

            
    </div>
</div>




