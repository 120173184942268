import { ValidatorFn, AbstractControl } from '@angular/forms';

export function cuitValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let cuit = String(control.value);

      if (cuit.length !== 11) {
        return {cuit: {value: 'El campo CUIL/CUIT debe tener 11 dígitos.'}};
      }

      let acumulado   = 0;
      let digitos     = cuit.split('');
      let digito      = digitos.pop();

      for (let i = 0; i < digitos.length; i++) {
          acumulado += Number(digitos[9 - i]) * (2 + (i % 6));
      }

      let verif = 11 - (acumulado % 11);
      if (verif === 11) {
          verif = 0;
      } else if (verif === 10) {
          verif = 9;
      }
      
      return (Number(digito) == Number(verif)) ? null : {cuit: {value: 'El CUIL/CUIT ingresado es inválido. '}};
    };
  }
