import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OlvidoPasswordComponent } from './olvido-password.component';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';

const routes = [
  {
      path     : 'olvido-clave',
      component: OlvidoPasswordComponent
  }
];

@NgModule({
  declarations: [
    OlvidoPasswordComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatInputModule,  
    FuseSharedModule
  ]
})
export class OlvidoPasswordModule { }
