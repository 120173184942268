import { Component, OnInit, ViewChild } from '@angular/core'; 
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmComponent } from 'app/main/confirm/confirm.component';
import { LoginService } from 'app/services/login.service';
import { environment } from 'environments/environment';
import { AdmJudicializadosService } from '../../../../services/adm-judicializados.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-adm-judicializados',
  templateUrl: './adm-judicializados.component.html',
  styleUrls: ['./adm-judicializados.component.scss'],
})
export class AdmJudicializadosComponent implements OnInit {
  sessionId: any;
  altaDNI: FormGroup;
  data: any = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  dni = new FormControl('', [Validators.pattern('^[0-9]{7,8}$'), this.validateDni]);


  displayedColumns: string[] = ['dni', 'nomyape', 'acciones'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  allDni = [];

  constructor(
    private dateAdapter: DateAdapter<Date>,
    public dialog: MatDialog,
    private getSessionData: LoginService,
    private router: Router,
    private _AdmJudicializados: AdmJudicializadosService,
    private _snackBar: MatSnackBar
  ) {
    this.dateAdapter.setLocale('en-GB');

    this.getSessionData.getAll().subscribe((resp) => {
      if (!resp['isLogged']) {
        this.router.navigate(['/home']);
      } else {
        this.getAllDni();
        this.getSessionData.getSessionData().subscribe(resp => {
          this.sessionId = resp.session_id;
        });
      };
    });
  }

  validateDni(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null) return null;
    
    const dni = value.toString();
    const exRegularDni = /^\d{7,8}(?:[-\s]\d{4})?$/;
  
    if (exRegularDni.test(dni) && !(/[a-zA-Z]/g.test(dni)) && dni === dni.replace(/(\d)[\s.]+(?=\d)/g, '$1')) {
      return null; // DNI válido
    } else {
      return { dni: { value: 'El campo DNI debe tener 7 u 8 dígitos numéricos.' } };
    }
  }

  ngOnInit(): void {
    this.altaDNI = new FormGroup({
      dni: this.dni,
    });

    return this.getAllDni();
  }

  getAllDni() {

    this._AdmJudicializados.getAllDni().subscribe((response) => {
      const data = response['data'];
      if (data.length <= 0) {
        
        this.showSnackBar('No se encontraron DNI judicializados.');
      } else {
        this.allDni = response['data'];
        if (this.allDni[0]) {

          this.dataSource = new MatTableDataSource(this.allDni);

          this.dataSource.paginator = this.paginator;
          
        }
      }
    },
      err => {
        console.log(err);
        this._snackBar.open('Ha ocurrido un error: ' + err.message, 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });

  }
  createDNI() {
    this.altaDNI.markAllAsTouched();

    if (!this.altaDNI.valid) {
      this.showSnackBar('Ingresá un DNI válido');
      return;
    }
    console.log('consultando duplicacion..');
    this._AdmJudicializados.getAllDni().subscribe(res => {
      var resp = res['data'];
      var duplicado;

      for (let index = 0; index < res['data'].length; index++) {

        if (resp[index].dni == this.dni.value) {
          this.showSnackBar('El DNI que intentas agregar ya esta cargado.')
          return duplicado = true;
        }
      }

      if (!duplicado) {
        const formData = new FormData();
        formData.append('dni', this.dni.value);
        this._AdmJudicializados.createDni(formData).subscribe(res => {

          this.dataSource = new MatTableDataSource(res['data']);
          this.dataSource.paginator = this.paginator;
          this.allDni = res['data'];
          this._snackBar.open('DNI agregado.', 'Cerrar', {
            duration: undefined,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          this.altaDNI.reset();
        },
        error => {
         this.showSnackBar('Ha ocurrido un error: ' + error.error.message);
        }
        );
      }
    });
  } 




  showSnackBar(message) {
    this._snackBar.open(message, 'Cerrar', {
      duration: undefined,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  addNewDNI() {
    const formData = new FormData();
    formData.append('dni', this.dni.value);

    this._AdmJudicializados.createDni(formData).subscribe(async (res) => {
      this.dataSource = new MatTableDataSource(res['data']);
      this.dataSource.paginator = this.paginator;
      this.allDni = res['data'];

      this.showSnackBar('DNI agregado.');
      this.altaDNI.reset();
    });
  }

  deleteDNI(dni): void {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '350px',
      data: '¿ Eliminar el DNI: ' + dni + ' ?',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const formData = new FormData();
        formData.append('dni', dni);
        this._AdmJudicializados.deleteDni(formData).subscribe((resp) => {
          console.log(
            'llega resp de eliminar desde dentro de delete DNI: ' + resp['data']
          );

          this._snackBar.open('DNI eliminado.', 'Cerrar', {
            duration: undefined,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          this.dataSource = new MatTableDataSource(resp['data']);
          this.dataSource.paginator = this.paginator;
          this.allDni = resp['data'];
        });
      }
    });
  }

  linkPanel() {
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  }

  exportarPdf() {
    // Fecha para el documento
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const currentDate = this.getCurrentDateInFormat();
  
    // En todas las páginas se añadirán estos textos de footer
    const addFooters = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
  
      for (var i = 1; i <= pageCount; i++) {
        // Constructor texto página
        doc.setFont('helvetica', 'italic');
        doc.setFontSize(8);
        var pageSize = doc.internal.pageSize;
        doc.setPage(i);
        doc.text(
          'Página ' + String(i) + ' / ' + String(pageCount),
          pageSize.width / 2,
          287,
          {
            align: 'center',
          }
        );
  
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(9);
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        let textoFecha = 'Fecha: ' + day + '/' + month + '/' + year;
        doc.text(textoFecha, 190, pageHeight - 15, { align: 'right' });
      }
    };
  
    let dniAndNameContent = [];
  
    // Itera sobre la lista de DNI y Nombres
    for (let i = 0; i < this.allDni.length; i++) {
      dniAndNameContent.push([this.allDni[i]['dni'], this.allDni[i]['nomeyape']]);
    }
  
    // Combina los arrays de DNI y Nombres en un solo array
    let combinedData = [];
  
    for (let i = 0; i < dniAndNameContent.length; i++) {
      combinedData.push([dniAndNameContent[i][0], dniAndNameContent[i][1]]); // Agrega DNI y Nombre en un sub-array
    }
    
    console.log('combinedData', combinedData);
    const doc = new jsPDF('p', 'mm', [297, 210]);
    var img = new Image();
    img.src = 'assets/images/logos/logo-ase.png';
    doc.addImage(img, 'png', 10, 10, 45, 12);
  
    autoTable(doc, {
      headStyles: {
        halign: 'center',
        fillColor: '#e75300',
        textColor: '#ffffff',
        fontStyle: 'bold',
        fontSize: 12,
      },
      startY: 30,
      head: [['REPORTE DE JUDICIALIZADOS']],
      body: [],
    });
    autoTable(doc, {
      headStyles: {
        halign: 'center',
        fillColor: '#e75300',
        textColor: '#ffffff',
      },
      startY: 40,
      head: [['DNI', 'APELLIDO Y NOMBRE']],
      body: combinedData,
      styles: { halign: 'center' },
      margin: { bottom: 30 },
    });
  
    addFooters(doc);
    doc.save('rj_' + currentDate + '.pdf');
  }


  getCurrentDateInFormat() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;
    return formattedDate;
  }
  
}
