<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="column" fxLayoutAlign="center center">
  <div fxFlex="100" fxFlex.xs="100" fxFlex.lt-md="100" fxLayout="column" class="elements-page-container">
    <app-breadcrumb [title]="dataBreadcumbTitle" [description]="dataBreadcumbPages"></app-breadcrumb>

    <mat-card class="card-menu">

      <span class="card-header-text">¡Tu solicitud fue enviada exitosamente! </span>
      <br>
      <div class="card-menu-item">
        <span>Tu solicitud se encontrará en auditoría por un plazo de 96 horas hábiles, mientras nuestro equipo analiza tu caso.</span>
      </div>
      <br>
      <div class="card-menu-item footer-card">
        <a class="back-home-link" [routerLink]="['/discapacidad']">Volver al inicio</a>
        <div class="prestacion-enviada-buttons">
          
          <button-secundary-blue-light-component 
          label="Realizar una nueva solicitud" 
          (onClick)="goToNewRequest()">
        </button-secundary-blue-light-component>

          <button-primary-orange-component 
          label="Descargar comprobante" 
          (onClick)="downloadComprobante()">
          </button-primary-orange-component>
        </div>
      </div>
    </mat-card>
    <!--     <button (click)="openModal()">open modal</button> -->
  </div>
</div>