<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
        <div>
            <img src="assets/images/iconos/icono_protesis.svg" class="icon">
        </div>
    </div>

    <div fxFlex="81" fxFlex.xs="100" fxFlex.lt-md="100" fxLayout="column">
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <span>Prótesis, ortesis y otros</span>
        </div>

        <h2 class="page-subtitle">Prótesis, ortesis y otros</h2>

        <div fxFlex="50" fxFlex.xs="100" id="panel-one">
            <mat-accordion hideToggle>
                <!-- <mat-expansion-panel disabled class="list-item" (click)="toggleView(1)"> -->

                <mat-expansion-panel disabled class="list-item" [routerLink]="['/solicitud-protesis']">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p class="listed-link">Solicitud de prótesis, ortesis y otros</p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
                <mat-expansion-panel disabled class="list-item" (click)="btnEstado()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p class="listed-link">Estado de solicitudes de prótesis, ortesis y otros</p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
                <mat-expansion-panel disabled class="list-item" [routerLink]="['/formularios-protesis']">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p class="listed-link">Descarga de formularios e instructivos</p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
            </mat-accordion>
        </div>


    </div>

</div>