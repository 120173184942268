import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
//componentes
import { SolicitarRolComponent } from './solicitar-rol.component';

//modulos angular material
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';

// modulos del template
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseHighlightModule } from '@fuse/components';

import { ReactiveFormsModule } from '@angular/forms';
import { SolicitarRolProveedorComponent } from './solicitar-rol-proveedor/solicitar-rol-proveedor.component';


const routes = [
  {
      path     : 'misdatos/solicitar-rol',
      component: SolicitarRolComponent
  }
];

@NgModule({
  declarations: [
    SolicitarRolComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    FuseHighlightModule,
    MatButtonModule,
    MatButtonToggleModule,    
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatSelectModule

    
    
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SolicitarRolModule { }
