import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'app/services/login.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-preguntas-frecuentes',
  templateUrl: './preguntas-frecuentes.component.html',
  styleUrls: ['./preguntas-frecuentes.component.scss']
})
export class PreguntasFrecuentesComponent implements OnInit {

  constructor(
    private _loginService: LoginService,
    private router: Router) { }

  ngOnInit(): void {
    this._loginService.getAll().subscribe(resp => {
      if(!resp['isLogged'])
          this.router.navigate(['/login'])
  });
  }

  miPlan(){
    window.location.href = environment.urlWebSitePhp + 'planes/verPlanesB';
  }

  linkPanel(){
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  }

}
