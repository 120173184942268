import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { SolicitarRedPrestadoraService } from '../../../../../services/solicitar-red-prestadora.service';
import { LoginService } from 'app/services/login.service';

@Component({
    selector: 'app-solicitar-red-prestadora',
    templateUrl: './solicitar-red-prestadora.component.html',
    styleUrls: ['./solicitar-red-prestadora.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class SolicitarRedPrestadoraComponent implements OnInit {

    red = new FormControl('', Validators.required);
    msjRed: Boolean = true;
    redes: any;
    rolRedes: string;
    botonDeshabilitado: boolean = false;

    constructor(private _snackBar: MatSnackBar,
        private router: Router,
        private _solicitarRedPrestadora: SolicitarRedPrestadoraService,
        private _loginService: LoginService,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this._loginService.getAll().subscribe(resp => {
            if(!resp['isLogged'])
                this.router.navigate(['/login'])
        });
        
        this._solicitarRedPrestadora.getRedes()
            .subscribe(data => {
                this.redes = data;
                console.log(this.redes);
            },
                err => console.log(err)
            );
    }

    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }

    btnEnviarRed() {

        this.red.markAllAsTouched();
        if (!this.red.valid) {
            return;
        }
        this.activatedRoute.queryParams.subscribe((params) => {
            this.rolRedes = params["RolRedes"];
        });

        const formData = new FormData();
        formData.append('cuit', environment.cuit);
        formData.append('cuil', environment.cuil);
        formData.append('rol', this.red.value);
        formData.append('rolRedes',this.rolRedes);


        this._solicitarRedPrestadora.sendForm(formData)
            .subscribe(resp => {
                //emito msjRed hacia misdatos
                this._solicitarRedPrestadora.msjRed.emit({
                    data: this.msjRed
                })
                this.botonDeshabilitado = true;
                this.router.navigate(['misdatos']);
            },
                err => {
                    this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                });

    }
}
