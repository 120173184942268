import { Component, ElementRef, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, EventEmitter } from '@angular/core';
import { FormPrestacionService } from '../../services/form-prestacion.service';
import { LoginService } from 'app/services/login.service';
import { FormBuilder, FormGroup, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FileHandle, DragDirective } from '../../directives/dragDrop.directive';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { MatExpansionModule } from '@angular/material/expansion';
import { GetPrestacionesRequierenAtencionResponse } from "app/services/interfaces/prestaciones-requieren-atencion/get-prestaciones-atencion.interface";
import { MatSnackBar } from '@angular/material/snack-bar';
import { retry, delay} from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { PrestacionesRequierenAtencion as GetPrestacionesRequierenAtencionService } from "app/services/prestaciones-requieren-atencion.service";
import { MatDialog } from "@angular/material/dialog";
import { ModalNotificacionRedMedifeComponent } from '../../../modal-notificacion-red-medife/modal-notificacion-red-medife.component';
import { ModalDescartarCambiosPrestacionComponent } from '../../../modal-descartar-cambios-prestacion/modal-descartar-cambios-prestacion.component';
import { ModalEliminarPrestacionComponent } from '../../../modal-eliminar-prestacion/modal-eliminar-prestacion.component';
import { PrestacionesService } from '../../services/prestaciones.service';

@Component({
  selector: 'app-form-prestacion',
  templateUrl: './form-prestacion.component.html',
  styleUrls: ['./form-prestacion.component.scss']
})
export class FormPrestacionComponent implements OnInit {
  @ViewChild(DragDirective) dragDirective: DragDirective;
  @ViewChild('selectTipoPrestacion') selectTipoPrestacion: ElementRef;
  @ViewChild('selectPrestacion') selectPrestacion: ElementRef;
  
  @Input() sessionId: string = '';

  //Variables primera parte del form
  @Input() cuil: string = '';
  @Input() nroOrden: string = '';
  @Input() nroBeneficiario: string = '';
  @Input() beneficiario: string = '';
  
  cuit: string = '';
  periodoDesde: string = '';
  periodoHasta: string = '';
  prestador: string = '';
  
  periodoFechasValido: boolean = false;
  cuitInvalido: boolean = false;
  cuitActivo: boolean = false;
  cuitNoActivo: boolean = false;
  cuitTouched: boolean = false;

  @Input() numeroPrestacion: number = 0;
  @Input() cardTitle: string;
  @Input() prestacionType: string;
  @Input() cardAdvice: { codigo: number, mensaje: string };
  @Input() dataPrestacionRequiereAtencion: GetPrestacionesRequierenAtencionResponse;
  @Input() datosNomenclador: any[];
  @Input() isNewPrestacion: any[];
  @Output() loadPrestaciones = new EventEmitter<any>();
  
 
  formularioPrestacion: FormGroup;
  tiposYDescripcionesNomenclador: any[]; // Variable para almacenar tipos y descripciones sin repetir
  tipoSeleccionado: number | null = null;
  prestacionSeleccionada: any;
  opcionesSegundoSelect: any[] = []; // Variable para opciones del segundo select
  textoTipoNumeroPrestacion: string = 'Prestación';
  mostrarSelectEspecialidad: boolean = false;
  mostrarSelectEspecialidadBackup: boolean = false;
  checklistDocumentacionEspecialidad: any [] = [];
  opcionesSelectEspecialidad: any[] = [];
  opcionesSelectSubEspecialidad: any[] = [];
  opcionesSelectSubEspecialidadBackup: any[] = [];
  opcionSeleccionadaSelectSubEspecialidadBackup: any = '';
  opcionSeleccionadaSelectEspecialidadBackup: any = '';
  opcionesSelectEspecialidadBackup: any[] = [];
  documentacionEspecialidad: any[] = [];

  enviandoPrestacion: boolean = false;
  eliminandoPrestacion: boolean = false;


  @Input() scrollSection: string;
  @Input() cardPrestacionPosicion: number;

  //Variables adjuntar documentos form
  documents: Array<any> = [];
  documentsPrestador: Array<any> = [];
  documentsOpcionales: Array<any> = [];
  documentacion: any;
  activeElement: number | null;
  errorElement: number | null;
  textoCard1: string;
  segundoTextoCard1: string | null;
  textoCardDone: string;
  textoCardError: string;
  imgUrl: string;
  uploadIconUrl: string;
  doneIconUrl: string;
  errorIconUrl: string;
  private allowedFormats: string[];
  private maxFileSize: number;
  camposCompletados: boolean;
  formActivo: boolean;
  idPrestacion: string;
  prestacionBackup: any;
  prestacionActualizada: any;
  documentsBackup: any;
  documentsPrestadorBackup: any;
  documentsOpcionalBackup: any;

  loadingDocuments: boolean = false;
  loaded:boolean = false;
  loadingPrestaciones: boolean = false;
  openModalNewPrestacion:boolean;

  // Validación de rango específico
  year = new Date().getFullYear();
  firstDayOfYearBefore = new Date(this.year - 1, 0, 1);
  lastDayOfYearAfter = new Date(this.year + 1, 11, 31);
  formattedFirstDay = this.firstDayOfYearBefore.toISOString().slice(0, 10);
  formattedLastDay = this.lastDayOfYearAfter.toISOString().slice(0, 10);

  constructor(private _formPrestacionService: FormPrestacionService, private loginService: LoginService,
    private formBuilder: FormBuilder, private sanitizer: DomSanitizer, private router: Router,
    private activatedRoute: ActivatedRoute, private _snackBar: MatSnackBar, private prestacionesService: PrestacionesService, @Inject(DOCUMENT) private _document: HTMLDocument, private dialog: MatDialog,) {

    this.formularioPrestacion = this.formBuilder.group(
      {
        periodoDesde: ['', [Validators.required]],
        periodoHasta: ['', [Validators.required]],
        tipoPrestacionInputForm: ['', Validators.required],
        prestacionInputForm: ['', Validators.required],
        especialidadInputForm: [''],
        subEspecialidadInputForm: [''],
      }, { validator: this.fechaCoherenteValidator() });

    this.activeElement = null;
    this.errorElement = null;
    this.textoCard1 = 'Arrastra tu archivo o';
    this.segundoTextoCard1 = 'adjuntalo desde tu dispositivo';
    this.textoCardDone = '¡Documento cargado exitosamente!';
    this.textoCardError = 'Error. Reintenta cargar el archivo nuevamente';
    this.uploadIconUrl = './assets/icons/DA-icons/upload_icon.svg';
    this.doneIconUrl = './assets/icons/DA-icons/done_icon.svg';
    this.errorIconUrl = './assets/icons/DA-icons/error_icon.svg'
    this.allowedFormats = ['application/pdf'];
    this.maxFileSize = 5 * 1024 * 1024;
    
  }


  onOpened() {
    if(this.dataPrestacionRequiereAtencion && this.loadingDocuments == false && this.loaded == false){
      this.obtenerDocumentos(this.sessionId);
      this.getDocumentacionPrestador();
    }

  }

  ngOnInit(): void {
    this.getNomencladorGrupo();

    this.prestacionBackup = this.dataPrestacionRequiereAtencion
    this.prestacionActualizada = this.dataPrestacionRequiereAtencion

    this.activatedRoute.queryParams.subscribe((params) => {
      this.cuil = params["cuil"];
      this.beneficiario = params["nombreBenef"];
      this.openModalNewPrestacion = params["prestacion"] == 1 ? true : false;
    });

    this.formularioPrestacion.valueChanges.subscribe(() => {
      this.camposCompletosPrimeraParteForm() ? this.camposCompletados = true : this.camposCompletados = false;
    });
 
    if (this.dataPrestacionRequiereAtencion) {
      this.cuit = this.dataPrestacionRequiereAtencion.cuit;
      this.periodoDesde = this.dataPrestacionRequiereAtencion.periododesde;
      this.periodoHasta = this.dataPrestacionRequiereAtencion.periodohasta;
      this.prestador = this.dataPrestacionRequiereAtencion.razonsocial;

      const valorPrestacion = this.dataPrestacionRequiereAtencion.nomencladorid+','+this.dataPrestacionRequiereAtencion.capitulonomencladorid;
      this.formularioPrestacion.get('prestacionInputForm')?.setValue(valorPrestacion);
      this.formularioPrestacion.get('especialidadInputForm')?.setValue(this.dataPrestacionRequiereAtencion?.especialidadid);
      this.formularioPrestacion.get('subespecialidadInputForm')?.setValue(this.dataPrestacionRequiereAtencion?.subespecialidadid);
      //Agregar valores a select de Especialidad y Subespecialidad.

    }

  }

  onCuitChange() {
    this.cuitActivo = false;
    this.cuitNoActivo = false;
    this.prestador = '';
    this.formActivo = true;
    this.mostrarSelectEspecialidad = false;
    this.formularioPrestacion.get('especialidadInputForm')?.setValue('');
    this.formularioPrestacion.get('subEspecialidadInputForm')?.setValue('');
    if (this.cuit.length === 0 ){ //Condicional para que, en caso que se borre el contenido, aparezca la otra leyenda y no la de cuit invalido.
      this.cuitInvalido = false;
      return;
    }
    if (this._formPrestacionService.cuitValidator(this.cuit)) {
      this.cuitInvalido = false;
      let jsonData = {
        'cuit': this.cuit
      }
      jsonData['sessionId'] = this.sessionId;
      this.getPrestadorActivo(jsonData);

      
    } else {
      this.cuitInvalido = true;
      this.camposCompletados = false;
      this.mostrarSelectEspecialidad = false;
    }
  }

  onCuitKeydown(event: KeyboardEvent) {
    const key = event.key;
    const allowedCharacters = /^[0-9]+$/;
    if (key === 'Backspace' || key === 'Delete' || key === 'ArrowRight' || key === 'ArrowLeft' || (event.ctrlKey && key === 'v')) {
      return;
    }
    if (!allowedCharacters.test(key)) {
      event.preventDefault();
    }
  }

  onCuitPaste(event: ClipboardEvent) {
    const pasteData = event.clipboardData.getData('text/plain');
    const allowedCharacters = /^[0-9]+$/;
    if (!allowedCharacters.test(pasteData)) {
      event.preventDefault();
    }
  }

  limpiarCuit(){
    this.cuit = "";
    this.onCuitChange();
  }

 

  getPrestadorActivo(jsonData: any) {
    this._formPrestacionService.getPrestadorActivo(jsonData).subscribe(
      response => {
        if (response?.Prvsta === "A") {
          this.cuitActivo = true;
          this.cuitNoActivo = false;
          this.prestador = response?.Prvnomfisc || '';
          this.camposCompletosPrimeraParteForm() ? this.camposCompletados = true : this.camposCompletados = false;
          this.getDocumentacionPrestador();
        } else {
          this.cuitNoActivo = false;
          this.cuitActivo = false;
          this.prestador = response?.Prvnomfisc || '';
          this.camposCompletosPrimeraParteForm() ? this.camposCompletados = true : this.camposCompletados = false;
          this.getDocumentacionPrestador();
        }
      },
      error => {
        console.error('Error al obtener datos:', error);
      }
    );
  }

  getNomencladorGrupo() {
        this.opcionesSegundoSelect = this.dataPrestacionRequiereAtencion && this.datosNomenclador.filter(dato => dato.TipoGrupoDescripcion == this.dataPrestacionRequiereAtencion.tipogrupodescripcion);
        this.separarDatos();

  }

  separarDatos() {
    //Crear un conjunto para almacenar tipos y descripciones sin repetir
    const tiposYDescripcionesSet = new Set();
    this.tiposYDescripcionesNomenclador = [];

    //Iterar sobre los datos y separarlos en los conjuntos correspondientes
    this.datosNomenclador.forEach(item => {
      const tipoYDescripcion = {
        TipoGrupoId: item.TipoGrupoId,
        TipoGrupoDescripcion: item.TipoGrupoDescripcion,
      };

      if (!tiposYDescripcionesSet.has(JSON.stringify(tipoYDescripcion))) {
        tiposYDescripcionesSet.add(JSON.stringify(tipoYDescripcion));
        this.tiposYDescripcionesNomenclador.push(tipoYDescripcion);

        //Condicional para saber si es una prestación ya guardada, filtrar y cargar los datos.
        if (this.dataPrestacionRequiereAtencion) {
        const descripcionGuardada = this.dataPrestacionRequiereAtencion.tipogrupodescripcion;
        const tipoSeleccionadofiltro = this.tiposYDescripcionesNomenclador.find(tipo => tipo.TipoGrupoDescripcion === descripcionGuardada);
          if (tipoSeleccionadofiltro) {
            this.formularioPrestacion.get('tipoPrestacionInputForm')?.setValue(tipoSeleccionadofiltro.TipoGrupoId);
          }
        }
      }
    });
    // console.log('Tipos y Descripciones:', this.tiposYDescripcionesNomenclador);
    // console.log('Otros Datos:', this.datosNomenclador);
  }


  // Método para manejar el cambio en el primer select
  seleccionarTipo(event: any) {
    this.tipoSeleccionado = parseInt(event.target.value, 10);
    this.opcionesSegundoSelect = this.datosNomenclador.filter(dato => dato.TipoGrupoId == this.tipoSeleccionado);

    this.prestacionSeleccionada = [this.opcionesSegundoSelect[0]];
    this.formularioPrestacion.get('prestacionInputForm').patchValue('');
    //this.formularioPrestacion.get('prestacionInputForm').setErrors(null);
    this.documents = [];
    this.formActivo = true;
    // this.obtenerDocumentos(this.sessionId);
    
    if(this.cuit != ''){
      this.onCuitChange();
    }
    
    //this.camposCompletosPrimeraParteForm() ? this.camposCompletados = true : this.camposCompletados = false;

  }

  seleccionarTipoReset(tipoPrestacionNumero: any) {
    this.formActivo = true;
    this.tipoSeleccionado = parseInt(tipoPrestacionNumero);
    this.opcionesSegundoSelect = this.datosNomenclador.filter(dato => dato.TipoGrupoId == this.tipoSeleccionado);
    this.prestacionSeleccionada = [this.opcionesSegundoSelect[0]]
    const prestacionSelect = document.getElementById('prestacion-input-form') as HTMLSelectElement;
    prestacionSelect.value = '';
  }

  seleccionarPrestacion(event: any) {
    this.formActivo = true;
    const idsSeleccionados = event.target.value.split(',');
    const NomencladorId = idsSeleccionados[0];
    const CapituloNomencladorId = idsSeleccionados[1];
    this.prestacionSeleccionada = this.opcionesSegundoSelect.filter(opcion => opcion.NomencladorId == NomencladorId
      && opcion.CapituloNomencladorId == CapituloNomencladorId);

    this.camposCompletosPrimeraParteForm() ? this.camposCompletados = true : this.camposCompletados = false;

    // this.documentsBackup = []
    this.obtenerDocumentos(this.sessionId);

    if(this.cuit != ''){
      this.onCuitChange();
    }
  }

  fechaCoherenteValidator(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      // Validación fecha coherente
      const periodoDesde = formGroup.get('periodoDesde').value;
      const periodoHasta = formGroup.get('periodoHasta').value;
    
      if (periodoDesde && periodoHasta) {
        if (periodoDesde > periodoHasta) {
          this.periodoFechasValido = false;
          return { fechaIncoherente: true };
        }
      }

      // Validación de periodo mayor o igual a 12 meses
      const periodoDesdeDate = new Date(`${periodoDesde}T00:00:00`); 
      const periodoHastaDate = new Date(`${periodoHasta}T00:00:00`); 

      const diffMonths = 
      (periodoHastaDate.getFullYear() - periodoDesdeDate.getFullYear()) * 12 +
      periodoHastaDate.getMonth() - periodoDesdeDate.getMonth();
      

      if (diffMonths >= 12) {
        this.periodoFechasValido = false;
        return { periodoMayor12Meses: true };
      }

    // Validación de rango específico
    if (periodoDesdeDate < this.firstDayOfYearBefore) {
        this.periodoFechasValido = false;
        return { fechaLimiteMinimo: true };
    }
    if (periodoHastaDate > this.lastDayOfYearAfter) {
        this.periodoFechasValido = false;
        return { fechaLimiteMaximo: true };
    }

      this.periodoFechasValido = true;
      return null;
    };
  }

  fechaCoherente() {
      const periodoDesde = this.periodoDesde;
      const periodoHasta = this.periodoHasta;

      if (periodoDesde != '' && periodoHasta != '') {
        if (periodoDesde > periodoHasta) {
          return false;
        } else {
          return true;
        }
      }
      //Si las fechas no estan definidas se retorna true tambien, solo false en caso que no tengan sentido.
      return true;
  }



  camposCompletosPrimeraParteForm() {
    if (this.formularioPrestacion.valid && this.prestacionSeleccionada != null &&
      this.beneficiario != '' && this._formPrestacionService.cuitValidator(this.cuit)) {
      
      if (this.mostrarSelectEspecialidad == true) {
        if(this.formularioPrestacion.get('subEspecialidadInputForm').value != ''){
          return true;
        } else {
          return false;
        }
      }
      return true;
    }
    else if (this.dataPrestacionRequiereAtencion) {
      if (this.mostrarSelectEspecialidad == true) {
        if(this.formularioPrestacion.get('subEspecialidadInputForm').value != ''){
          return true;
        } else {
          return false;
        }
      }
      return true;
    }
    else {
      return false;
    }
  }
  
  //Documentacion especialidad prestador
  getDocumentacionPrestador() {
    this.formularioPrestacion.get('especialidadInputForm')?.setValue('');
    this.formularioPrestacion.get('subEspecialidadInputForm')?.setValue('');

    this.loadingDocuments = true;
    let jsonData = {};
    this.documentsPrestador = [];

    if (this.dataPrestacionRequiereAtencion && !this.prestacionSeleccionada) {
      jsonData = {
        'rubro': 'DS',
        'titulo': this.dataPrestacionRequiereAtencion.titulonomencladorid,
        'capitulo': this.dataPrestacionRequiereAtencion.capitulonomencladorid,
        'id': this.dataPrestacionRequiereAtencion.nomencladorid,
        'cuit': this.cuit,
        'cuil': this.cuil,
        'sessionId': this.sessionId,
      }
    }else{
      jsonData = {
        'rubro': 'DS',
        'titulo': this.prestacionSeleccionada[0].TituloNomencladorId,
        'capitulo': this.prestacionSeleccionada[0].CapituloNomencladorId,
        'id': this.prestacionSeleccionada[0].NomencladorId,
        'cuit': this.cuit,
        'cuil': this.cuil,
        'sessionId': this.sessionId,
      }
    }
    
    this.mostrarSelectEspecialidad = false;

    if (this.dataPrestacionRequiereAtencion && !this.prestacionSeleccionada) {
      let checkListPrestador = [];
      this._formPrestacionService.getDocumentacionPrestador(jsonData).subscribe(
        response => {
          if(response.docCompletaWs == true || response.docSended == true){
              this.mostrarSelectEspecialidad = false;
          }else if( response.documentacion.length > 0 ){
            checkListPrestador = response.documentacion;
          }else{
            if(response.checkListDocumentacion) {
              this.mostrarSelectEspecialidad = true;

              //Cargo checklist del prestador
              this.checklistDocumentacionEspecialidad = response.checkListDocumentacion;
              //Cargo las opciones del selector
              this.opcionesSelectEspecialidad = this.getKeys(this.checklistDocumentacionEspecialidad); 
              //Cargo en otras tres variables un backup con el checklist y las opciones del select. Tambien si el select esta visible.
              this.opcionesSelectEspecialidadBackup = this.opcionesSelectEspecialidad;
              this.mostrarSelectEspecialidadBackup = this.mostrarSelectEspecialidad;

              //Si hay especiliadadid y subespecialidadid se filtra la documentación
              let prestadorSubEspecialidadId = this.dataPrestacionRequiereAtencion?.subespecialidadid;
              let prestadorEspecialidadId = this.dataPrestacionRequiereAtencion?.especialidadid;
              let especialidadDescripcion = this.dataPrestacionRequiereAtencion?.especialidaddescripcion;

              if(prestadorEspecialidadId && prestadorSubEspecialidadId){
                checkListPrestador = this.checklistDocumentacionEspecialidad[especialidadDescripcion].filter(doc => doc.PrestadorEspecialidadId == prestadorEspecialidadId && doc.PrestadorSubEspecialidadId == prestadorSubEspecialidadId);
              }else{
                this.formularioPrestacion.get('especialidadInputForm')?.setValue('');
                this.formularioPrestacion.get('subEspecialidadInputForm')?.setValue('');
              }              
    
            }else{
              console.log("No checklist documentacion");
            }
          }

          //Cargar los valores de los select de Especialidad y SubEspecialidad
          if (this.mostrarSelectEspecialidad == true){
          
            if (this.dataPrestacionRequiereAtencion?.especialidadid){
              this.formularioPrestacion.get('especialidadInputForm')?.setValue(this.dataPrestacionRequiereAtencion?.especialidadid);
              const especialidadValue = this.formularioPrestacion.get('especialidadInputForm').value;
              const especialidad = this.opcionesSelectEspecialidad.find(option => option.value == especialidadValue);
              this.opcionesSelectSubEspecialidad = [];
              this.checklistDocumentacionEspecialidad[especialidad.description].forEach( checkList =>{
                if(!this.opcionesSelectSubEspecialidad.find(elem => elem.value == checkList.PrestadorSubEspecialidadId )){
                  this.opcionesSelectSubEspecialidad.push({description: checkList.SubEspecialidadDescripcion, value:checkList.PrestadorSubEspecialidadId });
                }
              });
              this.formularioPrestacion.get('subEspecialidadInputForm')?.setValue(this.dataPrestacionRequiereAtencion?.subespecialidadid);
              this.seleccionarSubEspecialidad();

              //Cargar la especialidad tambien en una variable de backup para el descartar cambios
              this.opcionesSelectSubEspecialidadBackup = [...this.opcionesSelectSubEspecialidad];
              this.opcionSeleccionadaSelectEspecialidadBackup = this.dataPrestacionRequiereAtencion?.especialidadid;
              this.opcionSeleccionadaSelectSubEspecialidadBackup = this.dataPrestacionRequiereAtencion?.subespecialidadid;
            
              this.formActivo = false;
            }
            }
          if(checkListPrestador.length > 0){
            let i = 1;
            //Se vacia el documentPrestador para evitar duplicados al momento de cargar los documentos
            this.documentsPrestador = [];
            checkListPrestador.forEach( checkListDoc => {
              let finded = this.dataPrestacionRequiereAtencion.filesPrestador.find( filePrestador => filePrestador.tipodocumentacionid == checkListDoc.TipoDocumentacionId);
              if (finded) {
                this.documentsPrestador.push({
                  id: checkListDoc.TipoDocumentacionId,
                  prestadorEspecialidadId: checkListDoc.PrestadorEspecialidadId,
                  prestadorSubEspecialidadId: checkListDoc.PrestadorSubEspecialidadId,
                  tipoDocumentacionDescripcion: checkListDoc.TipoDocumentacionDescripcion,
                  tipoDocumentacionId: checkListDoc.TipoDocumentacionId,
                  title: checkListDoc.TipoDocumentacionDescripcion,
                  file: finded.path,
                  urlImg: this.doneIconUrl,
                  textoCard: this.textoCardDone,
                  segundoTextoCard: null,
                  activeElement: i,
                  errorElement: this.errorElement
                });


              } else {
                this.documentsPrestador.push(
                  {
                    id: checkListDoc.TipoDocumentacionId,
                    prestadorEspecialidadId: checkListDoc.PrestadorEspecialidadId,
                    prestadorSubEspecialidadId: checkListDoc.PrestadorSubEspecialidadId,
                    tipoDocumentacionDescripcion: checkListDoc.TipoDocumentacionDescripcion,
                    tipoDocumentacionId: checkListDoc.TipoDocumentacionId,
                    file: [],
                    urlImg: this.uploadIconUrl,
                    textoCard: this.textoCard1,
                    segundoTextoCard: this.segundoTextoCard1,
                    activeElement: this.activeElement,
                    errorElement: this.errorElement,
                    deleted: false
                  }
                );
              }
              i++;
          });
         
          if (!this.documentsPrestadorBackup) {
            this.documentsPrestadorBackup = JSON.parse(JSON.stringify(this.documentsPrestador));
          }
          }

          this.loadingDocuments = false;
          this.camposCompletosPrimeraParteForm() ? this.camposCompletados = true : this.camposCompletados = false;
        },
        error => {
          console.log('error documentacion prestador', error);
        }
      )
    }else{
      this._formPrestacionService.getDocumentacionPrestador(jsonData).subscribe(
        response => {
          if(response.docCompletaWs == true || response.docSended == true){
            this.mostrarSelectEspecialidad = false;
          }else if( response.documentacion.length > 0 ){

          this.documentsPrestador = response.documentacion.map(
            (item: any) => ({
              id: item.PrestadorEspecialidadId,
              prestadorEspecialidadId: item.PrestadorEspecialidadId,
              prestadorSubEspecialidadId: item.PrestadorSubEspecialidadId,
              tipoDocumentacionDescripcion: item.TipoDocumentacionDescripcion,
              tipoDocumentacionId: item.TipoDocumentacionId,
              file: [],
              urlImg: this.uploadIconUrl,
              textoCard: this.textoCard1,
              segundoTextoCard: this.segundoTextoCard1,
              activeElement: this.activeElement,
              errorElement: this.errorElement,
              deleted: false
            })
          );
        }else{
          this.mostrarSelectEspecialidad = true;
         
          if(response.checkListDocumentacion) {
            this.checklistDocumentacionEspecialidad = response.checkListDocumentacion;
            this.opcionesSelectEspecialidad = this.getKeys(this.checklistDocumentacionEspecialidad);

          }else{
            console.log("No checklist documentacion");
          }
        }
        
        this.loadingDocuments = false;
        this.camposCompletosPrimeraParteForm() ? this.camposCompletados = true : this.camposCompletados = false;
        },
        error => {
          console.log('error documentacion prestador', error);
        }
      )
    }
    
  }

  getKeys(obj: any) {
    let objectEntries = Object.entries(obj);

    let especialidades = objectEntries.map(element => {
      return {
        value: element[1][0].PrestadorEspecialidadId,
        description: element[1][0].EspecialidadDescripcion,
      }
    });

    console.log('ESPECIALIDADES: ', especialidades);

    return especialidades;
    //return Object.keys(obj);
  }


  seleccionarEspecialidad() {
    const especialidadValue = this.formularioPrestacion.get('especialidadInputForm').value;
    const especialidad = this.opcionesSelectEspecialidad.find(option => option.value == especialidadValue);
    this.formularioPrestacion.get('subEspecialidadInputForm')?.setValue('');

    this.opcionesSelectSubEspecialidad = [];
    this.checklistDocumentacionEspecialidad[especialidad.description].forEach( checkList =>{
      if(!this.opcionesSelectSubEspecialidad.find(elem => elem.value == checkList.PrestadorSubEspecialidadId )){
        this.opcionesSelectSubEspecialidad.push({description: checkList.SubEspecialidadDescripcion, value:checkList.PrestadorSubEspecialidadId });
      }
    });
    this.formActivo = true;
    this.documentsPrestador = [];
    
  }

  seleccionarSubEspecialidad(){

    const especialidadValue = this.formularioPrestacion.get('especialidadInputForm').value;
    const especialidad = this.opcionesSelectEspecialidad.find(option => option.value == especialidadValue);
    const subEspecialidad =this.formularioPrestacion.get('subEspecialidadInputForm').value
    this.documentacionEspecialidad = this.checklistDocumentacionEspecialidad[especialidad.description].filter( document => document.PrestadorSubEspecialidadId == subEspecialidad);
    this.formActivo = true;
    this.documentsPrestador = [];
    this.documentsPrestador = this.documentacionEspecialidad.map(
      (item: any) => ({
        id: item.PrestadorEspecialidadId,
        prestadorEspecialidadId: item.PrestadorEspecialidadId,
        prestadorSubEspecialidadId: item.PrestadorSubEspecialidadId,
        tipoDocumentacionDescripcion: item.TipoDocumentacionDescripcion,
        tipoDocumentacionId: item.TipoDocumentacionId,
        file: [],
        urlImg: this.uploadIconUrl,
        textoCard: this.textoCard1,
        segundoTextoCard: this.segundoTextoCard1,
        activeElement: this.activeElement,
        errorElement: this.errorElement,
        deleted: false
      })
    );
    
  }

  //Metodos segunda parte Form (Adjuntar Archivos)

  private obtenerDocumentos(sessionId: string) {
    this.loadingDocuments = true;
    this.documents = [];
    if (this.dataPrestacionRequiereAtencion && !this.prestacionSeleccionada) {

      this._formPrestacionService
      .getNomencladorChecklist(
        sessionId,
        this.dataPrestacionRequiereAtencion.rubronomencladorid,
        this.dataPrestacionRequiereAtencion.titulonomencladorid,
        this.dataPrestacionRequiereAtencion.capitulonomencladorid,
        this.dataPrestacionRequiereAtencion.nomencladorid
      ).pipe(
        retry(3),     
        delay(1000))
      .subscribe((documentsData) => {
        let i = 1;
        let j = 1;
        for(const itemDocumentData of documentsData.documentosRequeridos){
          let finded = this.dataPrestacionRequiereAtencion.files.find(item => item.documentacionid == itemDocumentData.NomencladorCheckListTipoDocId);
          if (finded) {
            this.documents.push({
              id: itemDocumentData.NomencladorCheckListTipoDocId,
              title: finded.tipodocumentaciondescripcion,
              file: finded.path,
              urlImg: this.doneIconUrl,
              textoCard: this.textoCardDone,
              segundoTextoCard: null,
              activeElement: i,
              errorElement: this.errorElement
            });
          }
          else {
            this.documents.push(
              {
                id: itemDocumentData.NomencladorCheckListTipoDocId,
                title: itemDocumentData.TipoDocumentacionDescripcion,
                file: [],
                urlImg: this.uploadIconUrl,
                textoCard: this.textoCard1,
                segundoTextoCard: this.segundoTextoCard1,
                activeElement: this.activeElement,
                errorElement: this.errorElement
              }
            );
          }
          i++;
        }
        for(const itemDocumentData of documentsData.documentosNoRequeridos){
          let finded = this.dataPrestacionRequiereAtencion.files.find(item => item.documentacionid == itemDocumentData.NomencladorCheckListTipoDocId);
          if (finded) {
            this.documentsOpcionales.push({
              id: itemDocumentData.NomencladorCheckListTipoDocId,
              title: finded.tipodocumentaciondescripcion,
              file: finded.path,
              urlImg: this.doneIconUrl,
              textoCard: this.textoCardDone,
              segundoTextoCard: null,
              activeElement: j,
              errorElement: this.errorElement
            });
          }
          else {
            this.documentsOpcionales.push(
              {
                id: itemDocumentData.NomencladorCheckListTipoDocId,
                title: itemDocumentData.TipoDocumentacionDescripcion,
                file: [],
                urlImg: this.uploadIconUrl,
                textoCard: this.textoCard1,
                segundoTextoCard: this.segundoTextoCard1,
                activeElement: this.activeElement,
                errorElement: this.errorElement
              }
            );
          }
          j++;
        }
        if (!this.documentsBackup) {
          this.documentsBackup = JSON.parse(JSON.stringify(this.documents));
        }
        if (!this.documentsOpcionalBackup){
          this.documentsOpcionalBackup = JSON.parse(JSON.stringify(this.documentsOpcionales));
        }
        this.loaded = true;
        this.loadingDocuments = false;
      }, error => {

        console.log(error);
        this.loadingDocuments = false;
      })

    }
    else{
      this._formPrestacionService
      .getNomencladorChecklist(
        sessionId,
        this.prestacionSeleccionada[0].RubroNomencladorId,
        this.prestacionSeleccionada[0].TituloNomencladorId,
        this.prestacionSeleccionada[0].CapituloNomencladorId,
        this.prestacionSeleccionada[0].NomencladorId
      ).pipe(
        retry(3),     
        delay(1000))
      .subscribe((documentsData) => {
        this.documents = documentsData.documentosRequeridos.map(
          (item: any) => ({
            id: item.NomencladorCheckListTipoDocId,
            title: item.TipoDocumentacionDescripcion,
            file: [],
            urlImg: this.uploadIconUrl,
            textoCard: this.textoCard1,
            segundoTextoCard: this.segundoTextoCard1,
            activeElement: this.activeElement,
            errorElement: this.errorElement,
            deleted: false
          })
        );
        this.documentsOpcionales = documentsData.documentosNoRequeridos.map(
          (item: any) => ({
            id: item.NomencladorCheckListTipoDocId,
            title: item.TipoDocumentacionDescripcion,
            file: [],
            urlImg: this.uploadIconUrl,
            textoCard: this.textoCard1,
            segundoTextoCard: this.segundoTextoCard1,
            activeElement: this.activeElement,
            errorElement: this.errorElement,
            deleted: false
          })
        );
        if (!this.documentsBackup) {
          this.documentsBackup = JSON.parse(JSON.stringify(this.documents));
        }
        if (!this.documentsOpcionalBackup) {
          this.documentsOpcionalBackup = JSON.parse(JSON.stringify(this.documentsOpcionales));
        }
        this.loadingDocuments = false;
        this.loaded = true;
      },error => {
        console.log(error);
        this.loadingDocuments = false;
      });
    }

  }

  private isValidFile(file: File): boolean {
    if (!this.allowedFormats.includes(file.type)) {
      return false;
    }

    if (file.size > this.maxFileSize) {
      return false;
    }

    return true;
  }


  filesDropped(files: FileHandle[], inputIndex: number): void {
    if(files.length > 1){
      this.mensajeErrorDropMultiplesArchivos();
      return;
    }

    if (this.documents[inputIndex - 1].file.length || !this.camposCompletados || this.documents[inputIndex - 1].errorElement || this.documents[inputIndex - 1].activeElement) {
      return
    }

    if (!this.isValidFile(files[0].file)) {
      this.documents[inputIndex - 1].errorElement = inputIndex;
      this.documents[inputIndex - 1].urlImg = this.errorIconUrl;
      this.documents[inputIndex - 1].textoCard = this.textoCardError;
      this.documents[inputIndex - 1].segundoTextoCard = null;
      return;
    }

    const fileToAttach = [files[0].file];
    this.documents[inputIndex - 1].file = fileToAttach;
    this.documents[inputIndex - 1].urlImg = this.doneIconUrl;
    this.documents[inputIndex - 1].textoCard = this.textoCardDone;
    this.documents[inputIndex - 1].segundoTextoCard = null;
    this.documents[inputIndex - 1].textoCardDone = null;
    this.documents[inputIndex - 1].activeElement = inputIndex;

    this.documents[inputIndex - 1].deleted = false;
    this.formActivo = true;
  }

  filesDroppedPrestador(files: FileHandle[], inputIndex: number): void {
    if(files.length > 1){
      this.mensajeErrorDropMultiplesArchivos();
      return;
    }

    if (this.documentsPrestador[inputIndex - 1].file.length || !this.camposCompletados || this.documentsPrestador[inputIndex - 1].errorElement || this.documentsPrestador[inputIndex - 1].activeElement) {
      return
    }

    if (!this.isValidFile(files[0].file)) {
      this.documentsPrestador[inputIndex - 1].errorElement = inputIndex;
      this.documentsPrestador[inputIndex - 1].urlImg = this.errorIconUrl;
      this.documentsPrestador[inputIndex - 1].textoCard = this.textoCardError;
      this.documentsPrestador[inputIndex - 1].segundoTextoCard = null;
      return;
    }

    const fileToAttach = [files[0].file];
    this.documentsPrestador[inputIndex - 1].file = fileToAttach;
    this.documentsPrestador[inputIndex - 1].urlImg = this.doneIconUrl;
    this.documentsPrestador[inputIndex - 1].textoCard = this.textoCardDone;
    this.documentsPrestador[inputIndex - 1].segundoTextoCard = null;
    this.documentsPrestador[inputIndex - 1].textoCardDone = null;
    this.documentsPrestador[inputIndex - 1].activeElement = inputIndex;

    this.documentsPrestador[inputIndex - 1].deleted = false;
    this.formActivo = true;

  }

  filesDroppedOpcionales(files: FileHandle[], inputIndex: number): void {
    if(files.length > 1){
      this.mensajeErrorDropMultiplesArchivos();
      return;
    }

    if (this.documentsOpcionales[inputIndex - 1].file.length || !this.camposCompletados || this.documentsOpcionales[inputIndex - 1].errorElement || this.documentsOpcionales[inputIndex - 1].activeElement) {
      return
    }

    if (!this.isValidFile(files[0].file)) {
      this.documentsOpcionales[inputIndex - 1].errorElement = inputIndex;
      this.documentsOpcionales[inputIndex - 1].urlImg = this.errorIconUrl;
      this.documentsOpcionales[inputIndex - 1].textoCard = this.textoCardError;
      this.documentsOpcionales[inputIndex - 1].segundoTextoCard = null;
      return;
    }

    const fileToAttach = [files[0].file];
    this.documentsOpcionales[inputIndex - 1].file = fileToAttach;
    this.documentsOpcionales[inputIndex - 1].urlImg = this.doneIconUrl;
    this.documentsOpcionales[inputIndex - 1].textoCard = this.textoCardDone;
    this.documentsOpcionales[inputIndex - 1].segundoTextoCard = null;
    this.documentsOpcionales[inputIndex - 1].textoCardDone = null;
    this.documentsOpcionales[inputIndex - 1].activeElement = inputIndex;

    this.documentsOpcionales[inputIndex - 1].deleted = false;
    this.formActivo = true;

  }

  mensajeErrorDropMultiplesArchivos(){
    this._snackBar.open('No puede adjuntar múltiples documentos', 'Aceptar', {
      duration: undefined,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  removeFiles(inputIndex: number): void {
    
    this.documents[inputIndex - 1].deleted = true;

    this.documents[inputIndex - 1].file = [];
    this.documents[inputIndex - 1].activeElement = null;
    this.documents[inputIndex - 1].errorElement = null;
    this.documents[inputIndex - 1].textoCard = this.textoCard1;
    this.documents[inputIndex - 1].segundoTextoCard = this.segundoTextoCard1;
    this.documents[inputIndex - 1].urlImg = this.uploadIconUrl;

    this.formActivo = true;
  }

  removeFilesPrestador(inputIndex: number): void {
    
    this.documentsPrestador[inputIndex - 1].deleted = true;

    this.documentsPrestador[inputIndex - 1].file = [];
    this.documentsPrestador[inputIndex - 1].activeElement = null;
    this.documentsPrestador[inputIndex - 1].errorElement = null;
    this.documentsPrestador[inputIndex - 1].textoCard = this.textoCard1;
    this.documentsPrestador[inputIndex - 1].segundoTextoCard = this.segundoTextoCard1;
    this.documentsPrestador[inputIndex - 1].urlImg = this.uploadIconUrl;

    this.formActivo = true;

  }

  removeFilesOpcionales(inputIndex: number): void {
    
    this.documentsOpcionales[inputIndex - 1].deleted = true;

    this.documentsOpcionales[inputIndex - 1].file = [];
    this.documentsOpcionales[inputIndex - 1].activeElement = null;
    this.documentsOpcionales[inputIndex - 1].errorElement = null;
    this.documentsOpcionales[inputIndex - 1].textoCard = this.textoCard1;
    this.documentsOpcionales[inputIndex - 1].segundoTextoCard = this.segundoTextoCard1;
    this.documentsOpcionales[inputIndex - 1].urlImg = this.uploadIconUrl;

    this.formActivo = true;
  }


  handleFileSelection(event: Event, inputIndex: number): void {
    
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0 && !this.documents[inputIndex - 1].file.length) {
      const selectedFile = inputElement.files[0];

      if (!this.isValidFile(selectedFile)) {
        
        this.documents[inputIndex - 1].errorElement = inputIndex;
        this.documents[inputIndex - 1].urlImg = this.errorIconUrl;
        this.documents[inputIndex - 1].textoCard = this.textoCardError;
        this.documents[inputIndex - 1].segundoTextoCard = null;
        return;
      }
      const url: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(selectedFile)
      );

      this.documents[inputIndex - 1].file.push(selectedFile);
      this.documents[inputIndex - 1].urlImg = this.doneIconUrl
      this.documents[inputIndex - 1].textoCard = this.textoCardDone;
      this.documents[inputIndex - 1].segundoTextoCard = null;
      this.documents[inputIndex - 1].activeElement = inputIndex;

      this.documents[inputIndex - 1].deleted = false;

      this.formActivo = true;
    }
  }

  handleFileSelectionPrestador(event: Event, inputIndex: number): void {

    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0 && !this.documentsPrestador[inputIndex - 1].file.length) {
      const selectedFile = inputElement.files[0];

      if (!this.isValidFile(selectedFile)) {
        
        this.documentsPrestador[inputIndex - 1].errorElement = inputIndex;
        this.documentsPrestador[inputIndex - 1].urlImg = this.errorIconUrl;
        this.documentsPrestador[inputIndex - 1].textoCard = this.textoCardError;
        this.documentsPrestador[inputIndex - 1].segundoTextoCard = null;
        return;
      }
      const url: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(selectedFile)
      );

      this.documentsPrestador[inputIndex - 1].file.push(selectedFile);
      this.documentsPrestador[inputIndex - 1].urlImg = this.doneIconUrl
      this.documentsPrestador[inputIndex - 1].textoCard = this.textoCardDone;
      this.documentsPrestador[inputIndex - 1].segundoTextoCard = null;
      this.documentsPrestador[inputIndex - 1].activeElement = inputIndex;

      this.documentsPrestador[inputIndex - 1].deleted = false;
      this.formActivo = true;

    }
  }

  handleFileSelectionOpcional(event: Event, inputIndex: number): void {

    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0 && !this.documentsOpcionales[inputIndex - 1].file.length) {
      const selectedFile = inputElement.files[0];

      if (!this.isValidFile(selectedFile)) {
        
        this.documentsOpcionales[inputIndex - 1].errorElement = inputIndex;
        this.documentsOpcionales[inputIndex - 1].urlImg = this.errorIconUrl;
        this.documentsOpcionales[inputIndex - 1].textoCard = this.textoCardError;
        this.documentsOpcionales[inputIndex - 1].segundoTextoCard = null;
        return;
      }
      const url: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(selectedFile)
      );

      this.documentsOpcionales[inputIndex - 1].file.push(selectedFile);
      this.documentsOpcionales[inputIndex - 1].urlImg = this.doneIconUrl
      this.documentsOpcionales[inputIndex - 1].textoCard = this.textoCardDone;
      this.documentsOpcionales[inputIndex - 1].segundoTextoCard = null;
      this.documentsOpcionales[inputIndex - 1].activeElement = inputIndex;

      this.documentsOpcionales[inputIndex - 1].deleted = false;
      this.formActivo = true;

    }
  }

  checkFiles(documents: any[]): boolean {
    return documents.every(document => document.hasOwnProperty('file') && document['file'] !== undefined && document['file'] !== null);
  }

  sendData(): void {



    if (this.camposCompletosPrimeraParteForm()) {
      
      //Al finalizar prestacion hago scroll para que se visualice el form y el loader de carga
      //si index es -1 es una prestacion nueva y se hace scroll hasta esa seccion
      //sino se hace scroll hasta la card a actualizar
      let sectionClass = 'form-prestacion-header';
      let elements = document.getElementsByClassName(sectionClass);
      let index = this.cardPrestacionPosicion ? this.cardPrestacionPosicion  : - 1;
      if(index < 0 ){
        sectionClass = 'contenedor-titulo-nuevas-prestaciones';
        elements = document.getElementsByClassName(sectionClass);
        const element = elements[elements.length - 1];
        element.scrollIntoView();
      }
      
      if (elements.length > 0 && index >= 0) {
        index = this.cardPrestacionPosicion - 1 >= 0 ? this.cardPrestacionPosicion - 1 : elements.length - 1;
        const element = elements[index];
        element.scrollIntoView();
      }
      this.enviandoPrestacion = true;
      this.loadingPrestaciones = true;
      const formData = new FormData();
      
      formData.append('sessionId', this.sessionId);
      formData.append('id', this.dataPrestacionRequiereAtencion ? this.dataPrestacionRequiereAtencion.id : null)
      formData.append('cuil', this.cuil);
      formData.append('periodoDesde', this.formularioPrestacion.get('periodoDesde').value);
      formData.append('periodoHasta', this.formularioPrestacion.get('periodoHasta').value);
      formData.append('beneficiario', this.beneficiario);
      formData.append('razonsocial', this.prestador);
      formData.append('cuit', this.cuit);
      formData.append('tipogrupodescripcion', this.prestacionSeleccionada ? this.prestacionSeleccionada[0].TipoGrupoDescripcion : this.dataPrestacionRequiereAtencion.tipogrupodescripcion);
      formData.append('nomencladordescripcion', this.prestacionSeleccionada ? this.prestacionSeleccionada[0].NomencladorDescripcion : this.dataPrestacionRequiereAtencion.nomencladordescripcion);
      formData.append('rubronomencladorid', this.prestacionSeleccionada ? this.prestacionSeleccionada[0].RubroNomencladorId : this.dataPrestacionRequiereAtencion.rubronomencladorid);
      formData.append('titulonomencladorid', this.prestacionSeleccionada ? this.prestacionSeleccionada[0].TituloNomencladorId : this.dataPrestacionRequiereAtencion.titulonomencladorid);
      formData.append('capitulonomencladorid', this.prestacionSeleccionada ? this.prestacionSeleccionada[0].CapituloNomencladorId : this.dataPrestacionRequiereAtencion.capitulonomencladorid);
      formData.append('nomencladorid', this.prestacionSeleccionada ? this.prestacionSeleccionada[0].NomencladorId : this.dataPrestacionRequiereAtencion.nomencladorid);
      formData.append('nroBeneficiario', this.nroBeneficiario);
      formData.append('nroOrden', this.nroOrden);
      
      //En caso que el formulario no tenga los select de especialidad se enviara vacio
      if(this.mostrarSelectEspecialidad == true){
        formData.append('especialidadid', this.formularioPrestacion.get('especialidadInputForm').value);
        const especialidadValue = this.formularioPrestacion.get('especialidadInputForm').value;
        const especialidad = this.opcionesSelectEspecialidad.find(option => option.value == especialidadValue);
        formData.append('especialidaddescripcion', especialidad.description);
        formData.append('subespecialidadid', this.formularioPrestacion.get('subEspecialidadInputForm').value);
        const subeespecialidadValue = this.formularioPrestacion.get('subEspecialidadInputForm').value;
        const subespecialidad = this.opcionesSelectSubEspecialidad.find(option => option.value == subeespecialidadValue);
        formData.append('subespecialidaddescripcion', subespecialidad.description);
      } else {
        formData.append('especialidadid', '');
        formData.append('especialidaddescripcion', '');
        formData.append('subespecialidadid', '');
        formData.append('subespecialidaddescripcion', '');
      }

      const nombresArchivosArray = this.documents.map((document) => {
        const adjuntado = Array.isArray(document.file) && document.file.length > 0  ? true : false;
        return {
          title: document.title,
          adjuntado: adjuntado,
          id: document.id,
          deleted: document.deleted
        }
      });

      const nombresArchivosPrestadorArray = this.documentsPrestador.map((document) => {
        const adjuntado = Array.isArray(document.file) && document.file.length > 0 ? true : false;
        return { 
          adjuntado: adjuntado,
          id: document.id,
          deleted: document.deleted,
          prestadorEspecialidadId: document.prestadorEspecialidadId,
          prestadorSubEspecialidadId: document.prestadorSubEspecialidadId,
          tipoDocumentacionDescripcion: document.tipoDocumentacionDescripcion,
          tipoDocumentacionId: document.tipoDocumentacionId,
        }
      });

      const nombresArchivosOpcionalesArray = this.documentsOpcionales.map((document) => {
        const adjuntado = Array.isArray(document.file) && document.file.length > 0 ? true : false;
        return { 
          title: document.title,
          adjuntado: adjuntado,
          id: document.id,
          deleted: document.deleted
        }
      });

      const documentosInvalidos = this.documents.some(item => item.errorElement != null) || this.documentsPrestador.some(item => item.errorElement != null) 
                                  || this.documentsOpcionales.some(item => item.errorElement != null);
      if (documentosInvalidos) {
        this._snackBar.open('Los adjuntos no cumplen con  el formato tipo y/o peso', 'Aceptar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.enviandoPrestacion = false;
        this.loadingPrestaciones = false;
        return;
      }

      const hayAdjunto = this.documents.some(item => item.file.length > 0) || this.documentsPrestador.some(item => item.file.length > 0);
      if (!hayAdjunto) {
        this._snackBar.open('Debe adjuntar al menos un documento requerido para guardar la prestación', 'Aceptar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.enviandoPrestacion = false;
        this.loadingPrestaciones = false;
        return;
      }


      formData.append('nombresarchivos', JSON.stringify(nombresArchivosArray));
      formData.append('nombresArchivosPrestador', JSON.stringify(nombresArchivosPrestadorArray));
      formData.append('nombresArchivosOpcionales', JSON.stringify(nombresArchivosOpcionalesArray));

      for (const document of this.documents) {
        formData.append('archivo', document.file.length > 0 ? document.file[0] : document.file);
      }
      for (const document of this.documentsPrestador) {
        formData.append('archivo', document.file.length > 0 ? document.file[0] : document.file);
      }
      for (const document of this.documentsOpcionales) {
        formData.append('archivo', document.file.length > 0 ? document.file[0] : document.file);
      }


      this._formPrestacionService.onSubmit(formData).subscribe(
        (response) => {
            if (response.error == 0) {
              const envioPrestacion = true;
              const nombrePrestacion = this.prestacionSeleccionada ? this.prestacionSeleccionada[0].NomencladorDescripcion : this.dataPrestacionRequiereAtencion.nomencladordescripcion;
             
              this.enviandoPrestacion = false;
              this.formActivo = false;
              this.loadingPrestaciones = false;
              //Reinicio el form si envio una prestacion nueva
              if(!this.dataPrestacionRequiereAtencion){
                this.formularioPrestacion.reset();
                this.prestador = '';
                this.cuit = '';
                this.cuitActivo = false;
                this.cuitInvalido = false;
                this.cuitNoActivo = false;
                this.formularioPrestacion.get('especialidadInputForm')?.setValue('');
                this.mostrarSelectEspecialidad = false;
                this.formularioPrestacion.get('tipoPrestacionInputForm').patchValue('');
                this.formularioPrestacion.get('prestacionInputForm').patchValue('');
                this.formularioPrestacion.get('tipoPrestacionInputForm').setErrors(null);
                this.formularioPrestacion.get('prestacionInputForm').setErrors(null);
                this.periodoDesde = '';
                this.periodoHasta = '';
                this.opcionesSegundoSelect = null;
                this.cuitTouched = false;
                this.documents = [];
                this.documentsPrestador = [];
                this.documentsOpcionales = [];
              
              }

              this.loadPrestaciones.emit({ envioPrestacion, idPrestacionCreada: response.body, nombrePrestacion, update: 0})

                
              this._snackBar.open('Cambios realizados con éxito', 'Cerrar', {
                duration: undefined,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              

            }else{
              this._snackBar.open('Ha ocurrido un error', 'Cerrar', {
                duration: undefined,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            }
        },
        (error) => {
          if (error.error && error.error.error == 2) {
            this._snackBar.open('Ya existe la prestación', 'Aceptar', {
              duration: undefined,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          } else {
            this._snackBar.open('Error al enviar formulario', 'Aceptar', {
              duration: undefined,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
          this.enviandoPrestacion = false;
          this.loadingPrestaciones = false;
        }
      );

    } else {
      return
    }

  }

  resetDataForm(): void {
    const dialogRef = this.dialog.open(ModalDescartarCambiosPrestacionComponent, {
      width: "508px",
      height: "auto",
      panelClass: "custom-modalbox",
      disableClose: true,
      data: `Descartar cambios?` 
    })
    .updatePosition({top: '220px'})
    .afterClosed()
    .subscribe((confirmado: Boolean) => {
      if (confirmado) {
        if(this.dataPrestacionRequiereAtencion){
          this.formularioPrestacion.patchValue({
            periodoDesde: this.prestacionActualizada.periododesde,
            periodoHasta: this.prestacionActualizada.periodohasta
          });
          const descripcionGuardada = this.prestacionActualizada.tipogrupodescripcion;
          const tipoSeleccionadofiltro = this.tiposYDescripcionesNomenclador.find(tipo => tipo.TipoGrupoDescripcion === descripcionGuardada);
          if (tipoSeleccionadofiltro) {
            this.formularioPrestacion.get('tipoPrestacionInputForm')?.setValue(tipoSeleccionadofiltro.TipoGrupoId);
            this.seleccionarTipoReset(tipoSeleccionadofiltro.TipoGrupoId);
          }
    
          const valorPrestacion = this.prestacionActualizada.nomencladorid+','+this.prestacionActualizada.capitulonomencladorid;
          this.formularioPrestacion.get('prestacionInputForm')?.setValue(valorPrestacion);
    
          this.prestador = this.prestacionActualizada.razonsocial;
          this.cuit = this.prestacionActualizada.cuit;
          //this.onCuitChange();
          
          this.documents = JSON.parse(JSON.stringify(this.documentsBackup));
          if (this.documentsOpcionalBackup){
            this.documentsOpcionales = JSON.parse(JSON.stringify(this.documentsOpcionalBackup));
          }
    
          this.opcionesSelectSubEspecialidad = this.opcionesSelectSubEspecialidadBackup;
    
          this.formularioPrestacion.get('especialidadInputForm')?.setValue(this.opcionSeleccionadaSelectEspecialidadBackup);
          this.formularioPrestacion.get('subEspecialidadInputForm')?.setValue(this.opcionSeleccionadaSelectSubEspecialidadBackup);
          this.opcionesSelectEspecialidad = this.opcionesSelectEspecialidadBackup;
          if (this.documentsPrestadorBackup){
            this.documentsPrestador = JSON.parse(JSON.stringify(this.documentsPrestadorBackup));
          }
          this.mostrarSelectEspecialidad  = this.mostrarSelectEspecialidadBackup;
        }else{
          this.formularioPrestacion.reset();
          this.prestador = '';
          this.cuit = '';
          this.cuitActivo = false;
          this.cuitInvalido = false;
          this.cuitNoActivo = false;
          this.formularioPrestacion.get('especialidadInputForm')?.setValue('');
          this.mostrarSelectEspecialidad = false;
          this.formularioPrestacion.get('tipoPrestacionInputForm').patchValue('');
          this.formularioPrestacion.get('prestacionInputForm').patchValue('');
          this.formularioPrestacion.get('tipoPrestacionInputForm').setErrors(null);
          this.formularioPrestacion.get('prestacionInputForm').setErrors(null);
          this.opcionesSegundoSelect = null;
          this.cuitTouched = false;
          this.documents = [];
          this.documentsPrestador = [];
          this.documentsOpcionales = [];
          //Hacer scroll hasta el principio del form
          const sectionClass = 'contenedor-titulo-nuevas-prestaciones';
          const elements = document.getElementsByClassName(sectionClass);
          if (elements.length > 0) {
            const element = elements[elements.length - 1];
            element.scrollIntoView();
          }
        }
        this.formActivo = false;
      }
      else{
        return
      }
    })  

  }

  deletePrestacion(idPrestacion: any) {
    const dialogRef = this.dialog.open(ModalEliminarPrestacionComponent, {
      width: "508px",
      height: "auto",
      panelClass: "custom-modalbox",
      disableClose: true,
      data: `Eliminar prestacion?` 
    })
    .updatePosition({top: '220px'})
    .afterClosed()
    .subscribe((confirmado: Boolean) => {
      if (confirmado) {
        this.eliminandoPrestacion = true;
        this.loadingPrestaciones = true;
        const sectionClass = 'form-prestacion-header';
        const elements = document.getElementsByClassName(sectionClass);
        if (elements.length > 0) {
          const index = this.cardPrestacionPosicion - 1 >= 0 ? this.cardPrestacionPosicion - 1 : elements.length - 1;
          const element = elements[index];
          element.scrollIntoView();
        }
          let jsonData = {
            'id': idPrestacion,
            'sessionId': this.sessionId
          }
          
          this._formPrestacionService.deletePrestacion(jsonData).subscribe(
            response => {
              /* this.getPrestacionesRequierenAtencionService.getPrestacionesRequierenAtencion(this.sessionId, this.cuil)
              .subscribe((result) => { */
                
                  const envioPrestacion = false;
                  
                  this._snackBar.open('La prestación ha sido eliminada', 'Cerrar', {
                    duration: undefined,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                  });
                  this.loadPrestaciones.emit({ envioPrestacion, update: 0 })

            },
            error => { 
              this._snackBar.open('Error al eliminar la prestación', 'Cerrar', {
                duration: undefined,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              this.eliminandoPrestacion = false;
              this.loadingPrestaciones = false;
          });
      } else {
        return
      }
    });    

  }

  getValorSelectPrestacion() {
    return this.formularioPrestacion.get('prestacionInputForm').value;
  }


}
