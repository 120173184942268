<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm fxHide>
               
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_altausuarios.svg" class="icon">
        </div>         

    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a routerLink="/misdatos">Mis datos</a> » <span>Cambiar direccion de email</span>
        </div>

        <h2 class="page-subtitle">Cambiar dirección de email</h2>
       
        <form>
            <div fxLayout="row">
                <mat-form-field class="mat-fullwidth" fxFlex.xs="100%" fxFlex="50%">
                    <mat-label>Email</mat-label>
                    <input matInput [formControl]="email" required>
                    <mat-error *ngIf="email.hasError('required')">Debe ingresar su email.</mat-error> 
                    <mat-error *ngIf="email.invalid && email.hasError('pattern')">Ingrese un email válido.</mat-error>
                </mat-form-field>
            </div>
           
        
            <div fxLayout="row">
                <button mat-raised-button id="btnEnviar" (click)="cambiarEmail()">Enviar</button>
            </div>
        </form>

    </div>
</div>




