<div class="page-layout blank p-24" fusePerfectScrollbar>

    <div class="breadcrumbs">
        <a href="/">Inicio</a> » <a [routerLink]="['/planes']">PLANES</a> » <span>PLAN VISIÓN PLUS</span>
    </div>

    <h1 class="page-title">Plan VISIÓN PLUS</h1>

    <h4>Plan Médico Obligatorio (PMO) a cargo de la red prestadora, y servicios y beneficios PLUS brindados por ASE</h4>

    <ul class="listado2">
        <li>Obsequios por nacimiento y casamiento</li>
        <li>Beneficios</li>
        <li>Turismo</li>
        <li>Asesoramiento legal</li>
    </ul>

    <p><b>Servicios PLUS</b></p>

    <ul>
        <li>Descuentos en cursos, carreras de grado, maestrías, posgrados y programas ejecutivos</li>
        <li>Beneficios para tu bebé</li>
        <li>Descuentos en chequeos preventivos de salud en el Sanatorio Finochietto</li>
        <li>Asistencia al viajero y medicina pre-viaje</li>
        <li>Reintegros en gastos ortopédicos, ortodoncia y sesiones de kinesiología a domicilio</li>
        <li>Cobertura por pérdida o rotura de cristales o lentes</li>
        <li>Asistencia de personal especializado</li>       
        <li><a [routerLink]="['/planes/beneficios-planes-plus']">Conocé más</a></li>
    </ul>

</div>