import { Directive } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function cbuValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let cbu = String(control.value);
    
    if (!cbu) {
      return null;
    }

    function validarCodigoBanco(codigo) {
      if (codigo.length != 8) { return false; }
      var banco = codigo.substr(0,3);
      var digitoVerificador1 = codigo[3];
      var sucursal = codigo.substr(4,3);
      var digitoVerificador2 = codigo[7];
     
      var suma = banco[0] * 7 + banco[1] * 1 + banco[2] * 3 + digitoVerificador1 * 9 + sucursal[0] * 7 + sucursal[1] * 1 + sucursal[2] * 3;
     
      var diferencia = (10 - (suma % 10)) % 10;
     
      return diferencia == digitoVerificador2;
    }
     
    function validarCuenta(cuenta) {
      if (cuenta.length != 14) { return false; }
      var digitoVerificador = cuenta[13];
      var suma = cuenta[0] * 3 + cuenta[1] * 9 + cuenta[2] * 7 + cuenta[3] * 1 + cuenta[4] * 3 + cuenta[5] * 9 + cuenta[6] * 7 + cuenta[7] * 1 + cuenta[8] * 3 + cuenta[9] * 9 + cuenta[10] * 7 + cuenta[11] * 1 + cuenta[12] * 3;
      var diferencia = (10 - (suma % 10)) % 10;
      
     
      return diferencia == digitoVerificador;
    }
     
    function validarCBU(cbu) {     
      return validarCodigoBanco(cbu.substr(0,8)) && validarCuenta(cbu.substr(8,14));       
    }


    if(!validarCBU(cbu)) {
      return { validaCBU: { value: 'El CBU ingresado es inválido.' } };
    }else{
      return null;
    }
    
      
      

    
   
    
    // return (Number(digito) == Number(verif)) ? null : {cuit: {value: 'El CUIL/CUIT ingresado es inválido. '}};
  };
}
