import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';

import { LoginService } from 'app/services/login.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { Router } from '@angular/router';
import { MisdatosProveedorService } from 'app/services/misdatos-proveedor.service';

@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit
{
    isLogged: boolean = false;
    isPublic: boolean = true;
    env = environment;
    datos :any;
    haveData: boolean = false;
    hasRol : boolean = false;
    companyMember: boolean = true;
    activeRol: any = '';
    uniqueRol: boolean = false;
    rolName: String = '';
    proveedor: boolean = false;
    proveedorData: any;

    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _loginService: LoginService,
        private router: Router,
        private MisdatosProveedorService: MisdatosProveedorService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        _loginService.getAll().subscribe(resp => {
            
            this.isLogged = resp['isLogged'];   
           
            let pathname = window.location.pathname;

            if (this.isLogged) {
                _loginService.getSessionData().subscribe(resp => {
                  this.MisdatosProveedorService.getMisDatosProveedor(resp.cuit).subscribe( dataProveedor => {
                    this.proveedorData = dataProveedor;
                    this.datos = resp;
                    if( this.datos.rol.length == 0) 
                    this.hasRol = false;
                    else if (this.datos.multirol == false)
                    {
                      this.uniqueRol = true;
                      this.hasRol = true;
                      this.rolName = this.datos.nombre_rol;
                    }
                    else
                    {
                      this.hasRol = true;
                      this.rolName = this.datos.nombre_rol;
                    }

                    if (this.datos.rol.length != 0)
                    {
                        this.activeRol = this.datos.rol.trim().toUpperCase();
                        if (this.activeRol == 'BENMOT' || 
                            this.activeRol == 'BENMOF' ||
                            this.activeRol == 'BENOST' ||
                            this.activeRol == 'BENOSF')
                                this.companyMember = false;
                    }
                    
                    if (this.rolName && this.rolName == 'Proveedor')
                    this.proveedor = true;
                    
                    this._changeDetectorRef.detectChanges();
                    this.haveData = true;
                  })
                })
            }
            let i = this.env.urlPublic.indexOf(pathname);
                if (i >= 0) 
                    this.isPublic = true;
                else 
                    this.isPublic = false;
        });
    }

    btnMisDatos(){
        if (!this.proveedor)
            this.router.navigate(['misdatos']);
        else
            this.router.navigate(['proveedores/mis-datos']);
    }
    
    btnCambiarRol(){
        this.router.navigate(['seleccionar-rol']);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });
    }
}
