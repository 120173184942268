import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-descartar-cambios-prestacion',
  templateUrl: './modal-descartar-cambios-prestacion.component.html',
  styleUrls: ['./modal-descartar-cambios-prestacion.component.scss']
})
export class ModalDescartarCambiosPrestacionComponent implements OnInit {

  constructor(public dialog: MatDialogRef<ModalDescartarCambiosPrestacionComponent>,
    @Inject(MAT_DIALOG_DATA) public mensaje: string) { }
  
    cerrarDialogo(): void {
      this.dialog.close(false);
    }
    confirmado(): void {
      this.dialog.close(true);
    }    


  ngOnInit(): void {
  }

}
