<mat-accordion>
    <mat-expansion-panel class="form-prestacion-box">

        <mat-expansion-panel-header class="form-prestacion-header">
            <mat-panel-title>
                <div *ngIf="tienePrestacionCompletaPeroDocBasicaObservada() === true; else noTieneDocBasicaObservadaBlock"
                    class="card-tittle-message-container">
                    <h2 class="nueva-prestacion">{{ cardTitle }}</h2>
                    <h3 title="Documentación básica" class="aviso-prestacion-error">
                        Deberás presentar los documentos faltantes de la Documentación Básica</h3>
                </div>
                <ng-template #noTieneDocBasicaObservadaBlock>
                    <div *ngIf="dataPrestacionRequiereAtencion.estado == 'Complete'; else noEstaCompletaBlock" class="card-tittle-message-container">
                        <h2 class="nueva-prestacion">{{ cardTitle }}</h2>
                        <h3 title="Prestación Sin enviar / Lista para enviar" class="aviso-prestacion-completada">
                            Prestación Sin enviar / Lista para enviar </h3>
                    </div>
                    <ng-template #noEstaCompletaBlock>
                        <div class="card-tittle-message-container">
                            <h2 class="nueva-prestacion">{{ cardTitle }}</h2>
                            <!-- *ngIf="cardAdvice?.mensaje.length > 0" -->
                            <h3 *ngIf="cardAdvice?.mensaje.length > 0" title="{{ cardAdvice?.mensaje }}" [ngClass]="{'aviso-prestacion-error': cardAdvice?.codigo == 2, 'aviso-prestacion-completada': cardAdvice?.codigo == 1}">
                                {{ cardAdvice?.mensaje }}</h3>
                        </div>
                    </ng-template>
                </ng-template>
                <h3 class="tipo-prestacion" title="{{ prestacionType }}">{{ prestacionType }}</h3>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="!dataPrestacionRequiereAtencion">
            <div class="contenedor-tipo-numero-prestacion">
                <span class="texto-tipo-numero-prestacion">{{textoTipoNumeroPrestacion}}
                    {{textoNumeroPrestacion}}/{{numeroTotalDePrestaciones}}</span>
            </div>
        </ng-container>
        <!-- <h2 class="nueva-prestacion"><span><img class="flecha-nueva-prestacion" src="./assets/icons/DA-icons/down-arrow-linea.svg"></span>Nueva Prestación</h2> -->
        
        <div fxLayout="row" fxLayoutGap="30px" class="contenedor-icon-y-form">

            <div class="contenedor-icon-form">
                <img class="adress-book-icon" src="./assets/icons/DA-icons/book_observada.svg">
            </div>
            <div class="prestacion-info-container" fxLayout="row" fxLayoutGap="50px">
                <div>
                    <h3>Tipo de prestación</h3>
                    <span>{{dataPrestacionRequiereAtencion.tipogrupodescripcion}}</span>
                </div>
                <div>
                    <h3>Prestación</h3>
                    <span>{{dataPrestacionRequiereAtencion.nomencladordescripcion}}</span>
                </div>
                <div>
                    <h3>Beneficiario</h3>
                    <span>{{userData?.nombre}}</span>            
                </div>
                <div>
                    <h3>CUIT prestador</h3>
                    <span>{{dataPrestacionRequiereAtencion.cuit}}</span>            
                </div>    
                
            </div>


        </div>

        <div *ngIf="cardAdvice?.mensaje.length > 0 && dataPrestacionRequiereAtencion.observaciones.length > 0; else elseDivObsersavaciones" fxLayout="row" class="container-observaciones">
            <div class="observaciones-img">
                <img src="./assets/icons/DA-icons/lamp_observada.svg" alt="">
            </div>
            <div *ngIf="dataPrestacionRequiereAtencion.observaciones.length > 0" class="observaciones-info">
                <h3>Observaciones</h3>
                <p *ngIf="tieneDocBasicaObservada() === true">
                    <span>Documentación Básica</span>
                </p>
                <p>
                    <span *ngFor="let observacion of dataPrestacionRequiereAtencion.observaciones; let last = last">
                        {{ observacion.observacion }}{{ last ? '' : '. ' }}
                    </span>
                </p>
            </div>
        </div>

        <ng-template #elseDivObsersavaciones>
        <div *ngIf="tienePrestacionCompletaPeroDocBasicaObservada() === true" class="container-observaciones-docbasica">
            <div class="observaciones-img">
                <img src="./assets/icons/DA-icons/lamp_observada.svg" alt="">
            </div>
            <div class="observaciones-info">
                <h3>Observaciones</h3>
                <p>
                    <span>Documentación Básica</span>
                </p>
            </div>
        </div>
        </ng-template>
        <!--Adjuntar Documentos-->

        <div fxLayout="row" fxLayoutGap="40px" [ngClass]="{'header-adjuntar-documentos': dataPrestacionRequiereAtencion.observaciones.length > 0, 'header-adjuntar-documentos-sin-observacion': dataPrestacionRequiereAtencion.observaciones.length === 0}">
            <div class="img-adjuntar-documentos">
                <img src="./assets/icons/DA-icons/file_upload_observada.svg"
                    alt="file-icon">
            </div>
            <div fxLayout="column" class="documentacion-especifica">
                <h3>Documentación específica</h3>
                <div *ngFor="let document of documents" class="container-estado-documento" fxLayout="row" fxLayoutGap="18px" fxLayoutAlign="center center">
                    <h4 title="{{document.title}}">{{document.title}}</h4>
                    <span [ngClass]="{'aprobada': !document.observada, 'desaprobada': document.observada}">{{document.observada ? 'Volver a cargar' : 'APROBADA'}}</span>                    
                </div>
                <div *ngFor="let document of documentsOpcionales" class="container-estado-documento" fxLayout="row" fxLayoutGap="18px" fxLayoutAlign="center center">
                    <h4 title="{{document.title}}">{{document.title}}</h4>
                    <span [ngClass]="{'aprobada': !document.observada, 'desaprobada': document.observada}">{{document.observada ? 'Volver a cargar' : 'APROBADA'}}</span>                    
                </div>
                <div *ngFor="let document of documentsPrestador" class="container-estado-documento" fxLayout="row" fxLayoutGap="18px" fxLayoutAlign="center center">
                    <h4 title="{{document.title}}">{{document.tipoDocumentacionDescripcion}}</h4>
                    <span [ngClass]="{'aprobada': !document.observada, 'desaprobada': document.observada}">{{document.observada ? 'Volver a cargar' : 'APROBADA'}}</span>                    
                </div>
                <p>Formato permitido: PDF. Peso máximo permitido 5MB</p>
                      
            </div>
            <div fxLayout="row" class="seccion-certificados-container">
                <div *ngFor="let element of documentosObservados; let i = index" class="certificado-container">
                    <div class="titulo-certificado">
                        <h3>{{element.title}}</h3>
                    </div>
                    <div fxLayout="column" [id]="'box-archivo-' + (i + 1)"
                        [ngClass]="{'box-archivo': true, 'active': element.activeElement === i+1, 'error': element.errorElement === i+1,'box-campos-completos':true}"
                        appDrag (files)="filesDropped($event,i+1)">
                        <div *ngIf="element.file.length || element.errorElement === i+1" class="container-close-icon">
                            <button class="btn-close" type="button" (click)="removeFiles(i+1)">
                                <img src="./assets/icons/DA-icons/XCircle.svg" alt="close-icon">
                            </button>
                        </div>
                        <img class="img-campos-completos" [src]="element.urlImg" alt="upload-icon">
                        <div class="texto-adjuntar-documentos">
                            <span class="linea1">
                                {{element.textoCard}}
                            </span>
                            <label *ngIf="element.segundoTextoCard"
                                for="archivo" class="linea2 span-campos-completos">{{element.segundoTextoCard}}</label>
                        </div>
                        <input *ngIf="!element.file.length && element.errorElement !== i+1" type="file"
                            [id]="'archivo' + (i + 1)" [name]="'archivo' + (i + 1)" class="file-input" accept="application/pdf"
                            (change)="handleFileSelection($event, i + 1)">
                    </div>
                </div>
                <div *ngFor="let element of documentosPrestadorObservados; let i = index" class="certificado-container">
                    <div class="titulo-certificado">
                        <h3>{{element.tipoDocumentacionDescripcion}}</h3>
                    </div>
                    <div fxLayout="column" [id]="'box-archivo-' + (i + 1)"
                        [ngClass]="{'box-archivo': true, 'active': element.activeElement === i+1, 'error': element.errorElement === i+1,'box-campos-completos':true}"
                        appDrag (files)="filesDroppedPrestador($event,i+1)">
                        <div *ngIf="element.file.length || element.errorElement === i+1" class="container-close-icon">
                            <button class="btn-close" type="button" (click)="removeFilesPrestador(i+1)">
                                <img src="./assets/icons/DA-icons/XCircle.svg" alt="close-icon">
                            </button>
                        </div>
                        <img class="img-campos-completos" [src]="element.urlImg" alt="upload-icon">
                        <div class="texto-adjuntar-documentos">
                            <span class="linea1">
                                {{element.textoCard}}
                            </span>
                            <label *ngIf="element.segundoTextoCard"
                                for="archivo" class="linea2 span-campos-completos">{{element.segundoTextoCard}}</label>
                        </div>
                        <input *ngIf="!element.file.length && element.errorElement !== i+1" type="file"
                            [id]="'archivo' + (i + 1)" [name]="'archivo' + (i + 1)" class="file-input" accept="application/pdf"
                            (change)="handleFileSelectionPrestador($event, i + 1)">
                    </div>
                </div>
                <div *ngFor="let element of documentosOpcionalesObservados; let i = index" class="certificado-container">
                    <div class="titulo-certificado">
                        <h3>OPCIONAL - {{element.title}}</h3>
                    </div>
                    <div fxLayout="column" [id]="'box-archivo-' + (i + 1)"
                        [ngClass]="{'box-archivo': true, 'active': element.activeElement === i+1, 'error': element.errorElement === i+1,'box-campos-completos':true}"
                        appDrag (files)="filesDroppedOpcionales($event,i+1)">
                        <div *ngIf="element.file.length || element.errorElement === i+1" class="container-close-icon">
                            <button class="btn-close" type="button" (click)="removeFilesOpcionales(i+1)">
                                <img src="./assets/icons/DA-icons/XCircle.svg" alt="close-icon">
                            </button>
                        </div>
                        <img class="img-campos-completos" [src]="element.urlImg" alt="upload-icon">
                        <div class="texto-adjuntar-documentos">
                            <span class="linea1">
                                {{element.textoCard}}
                            </span>
                            <label *ngIf="element.segundoTextoCard"
                                for="archivo" class="linea2 span-campos-completos">{{element.segundoTextoCard}}</label>
                        </div>
                        <input *ngIf="!element.file.length && element.errorElement !== i+1" type="file"
                            [id]="'archivo' + (i + 1)" [name]="'archivo' + (i + 1)" class="file-input" accept="application/pdf"
                            (change)="handleFileSelectionOpcionales($event, i + 1)">
                    </div>
                </div>
            </div>                
        </div>
        <div class="container-btns">
            <div class="container-btn-descartar-guardar">
                <button class="descartar-cambios" *ngIf="formActivo" (click)="resetDocuments()">Descartar cambios </button>
                <button-primary-orange-component [label]="'Finalizar cambios'" [buttonClass]="'finalizar-cambios'"
                [disabled]="!isFilesLoaded || enviandoPrestacion" (onClick)="sendPrestacionObservada()"></button-primary-orange-component>
            </div>
        </div>


    </mat-expansion-panel>
</mat-accordion>
