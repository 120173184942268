<div class="page-layout page-layout-water-mark blank p-24" fusePerfectScrollbar>

    <div class="breadcrumbs">
        <a href="/">Inicio</a> » <span>EJECUTIVO DE CUENTA</span>
    </div>

    <h1 class="page-title">Solicitud de Ejecutivo de Cuenta</h1>
    
    <p>El ámbito laboral es el lugar donde más horas al día pasa el personal. Por ello, las empresas pueden realizar aportes valiosos en pos del bienestar y la calidad de vida de sus colaboradores.</p>
	<p>En ASE contamos con las herramientas para lograr ese objetivo tan preciado: mantener saludable a sus recursos humanos.</p>
	<p>Nuestra experiencia nos ha demostrado que la implementación de planes de salud repercute positivamente en el plantel de colaboradores, no sólo desde el punto de vista tangible:</p>
	<ul class="listado">
		<li>Aumento de la participación, la motivación y el sentido de pertenencia y compromiso.</li>
		<li>Mayor retención del personal.</li>
		<li>Reducción de los niveles de ausentismo.</li>
		<li>Mejora de la productividad.</li>
		<li>Mejora de la atención médica contratada.</li>
	</ul>
	<p>En un entorno muy competitivo como el actual, para poder retener a los mejores recursos humanos es necesario ofrecerles mucho más de lo esperado. </p>
	<h2>Garantizamos</h2>
	<p>Ya sea en forma directa o a través de nuestra redes de prestadoras, garantizamos el cumplimiento del Plan Médico Obligatorio (P.M.O) y la cobertura de prestaciones de alta complejidad: trasplantes, discapacidad, medicamentos HIV, medicamentos oncológicos, hemodiálisis, prestaciones especiales.</p>
	<p>Por favor, completá el siguiente formulario para solicitar un Ejecutivo de Cuenta.</p>

   
    <form #form name="" [formGroup]="formEjecutivoCuenta" action="" method="POST" novalidate enctype="multipart/form-data">
       
            <div class="data">
                <div class="tw">
                    <mat-form-field  class="mat-fullwidth">
                        <mat-label>Nombre</mat-label>
                        <input matInput [formControl]="nombre" required>
                        <mat-error *ngIf="nombre.invalid">Debe ingresar su nombre.</mat-error>
                    </mat-form-field>
                </div>
                <div class="tw">
                    <mat-form-field  class="mat-fullwidth">
                        <mat-label>Apellido</mat-label>
                        <input matInput [formControl]="apellido" required>
                        <mat-error *ngIf="apellido.invalid">Debe ingresar su apellido.</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="data">  
                <!-- <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Cod. Área</mat-label>
                        <input matInput [formControl]="codigoarea" maxlength="5" required>
                        <mat-error *ngIf="codigoarea.invalid">Debe ingresar el Cod. Área.</mat-error>
                    </mat-form-field>
                </div> -->

                <div class="tw">
                    <mat-form-field  class="mat-fullwidth">
                        <mat-label>Teléfono</mat-label>
                        <input matInput [formControl]="telefono" maxlength="20" required>
                        <mat-error *ngIf="telefono.invalid">Debe ingresar el teléfono sin guiones.</mat-error>
                    </mat-form-field>
                </div>
            </div>

            
            <div class="data">
                <div class="tw">
                    <mat-form-field  class="mat-fullwidth">
                        <mat-label>Email</mat-label>
                        <input matInput [formControl]="email" required>
                        <mat-error *ngIf="email.invalid && email.hasError('required')">Debe ingresar su email.</mat-error>
                        <mat-error *ngIf="email.invalid && email.hasError('email')">Ingrese un email válido.</mat-error>
                    </mat-form-field>
                </div>

                <div class="tw">
                    <mat-form-field  class="mat-fullwidth">
                        <mat-label>CUIT</mat-label>
                        <input placeholder="Ejemplo 37508887002" matInput [formControl]="cuit" type="number" maxlength="11" required oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)">
                        <mat-error *ngIf="cuit.invalid ">Debe ingresar el CUIT.</mat-error>                        
                        <mat-error *ngIf="cuit.hasError('cuit') ">{{ cuit.errors.cuit.value }}</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="data">  
                <div class="tw">
                    <mat-form-field  class="mat-fullwidth">
                        <mat-label>Compañía</mat-label>
                        <input matInput [formControl]="empresa" required>
                        <mat-error *ngIf="empresa.invalid">Debe ingresar el nombre de la Compañía.</mat-error>
                    </mat-form-field>
                </div>
            
                <div class="tw">
                    <mat-form-field  class="mat-fullwidth">
                        <mat-label>Código Postal</mat-label>
                        <input matInput [formControl]="codigopostal" required>
                        <mat-error *ngIf="codigopostal.invalid">Debe ingresar el Codigo Postal.</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="data">
                <div class="tw">
                    <mat-form-field  class="mat-fullwidth">
                        <mat-label>Ciudad</mat-label>
                        <input matInput [formControl]="ciudad" required>
                        <mat-error *ngIf="ciudad.invalid">Debe ingresar la Ciudad.</mat-error>
                    </mat-form-field>
                </div>

                <div class="tw">
                    <mat-form-field  class="mat-fullwidth">
                        <mat-label>CUIL</mat-label>
                        <input placeholder="Ejemplo 37508887002" matInput [formControl]="cuil" type="number" maxlength="11" required oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)">
                        <mat-error *ngIf="cuil.invalid ">Debe ingresar el CUIL.</mat-error>                        
                        <mat-error *ngIf="cuil.hasError('cuil') ">{{ cuil.errors.cuil.value }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            
            <div class="data">
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Observaciones</mat-label>
                        <textarea matInput [formControl]="mensaje" rows="6"></textarea>
                    </mat-form-field>
                </div>
            </div>

        
    </form>

    <div>
        <re-captcha (resolved)="captchaResolved($event)" 
                    siteKey="6Lci2cUZAAAAAKfhe3JqVTih6eDVsgbbSz_fT50X">
        </re-captcha>
    </div>
    <br>
    <div>
        <button mat-raised-button class="submit-button" aria-label="ENVIAR" name="yt0"
        (click)="sendForm()" [disabled]="!formEjecutivoCuenta.valid || !captchares">
            Enviar
        </button>
    </div>
</div> 