import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { io } from 'socket.io-client';
@Injectable({
    providedIn: 'root'
})

export class SocketioService {

    public socket;
    public message$: BehaviorSubject<string>;
    constructor() { }

    setupSocketConnection(id, cuil) {
        this.disconnect();
        this.message$ = new BehaviorSubject('');
        console.log('Connecting socket...');
        this.socket = io(environment.socket,{
            extraHeaders: {
                'Access-Control-Allow-Origin': '*'
            },
            auth: {
                id: id,
                cuil: cuil
            }
        });
    }

    public getNewMessage = () => {
        this.socket.on('message', (message) => {
            this.message$.next(message);
        });

        return this.message$.asObservable();
    };

    public clearMessages(){
        this.message$.complete();
        //  this.message$ = new BehaviorSubject('');
    }

    disconnect() {
        console.log('Disconnecting socket...');
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}