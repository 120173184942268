import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CargaComprobantesService } from 'app/services/carga-comprobantes.service';
import { LoginService } from 'app/services/login.service';
import { MisdatosProveedorService } from 'app/services/misdatos-proveedor.service';
import { cuitValidator } from 'app/shared/cuit-validator.directive';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-consulta-prestaciones',
  templateUrl: './consulta-prestaciones.component.html',
  styleUrls: ['./consulta-prestaciones.component.scss']
})
export class ConsultaPrestacionesComponent implements OnInit {

  consultaPrestaciones: FormGroup;  

  constructor(
    private router: Router, 
    private _MisdatosProveedorService: MisdatosProveedorService,    
    private getSessionData: LoginService, 
    private _snackBar: MatSnackBar) { 

    this.getSessionData.getAll().subscribe(resp => {
        if(!resp['isLogged'])
            this.router.navigate(['/login'])
    });

    if (environment.sessionId === 'undefined') {
        window.location.href = environment.urlWebSitePhp + 'site/logout';
        return;
    }
      this.getSessionData.getSessionData().subscribe(result => {        
        
        if (result['rol'] != 'ASEPRV'){
          console.log(result);
          this.router.navigate(['/']);
        }
        
      });
    }

  cuit = new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(11), cuitValidator()]);
  
  
  ngOnInit(): void {  

    this.consultaPrestaciones = new FormGroup({
      cuit: this.cuit
    });
  }

  keyPressNumbers(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
    event.preventDefault();
    return false;
    } else {
    return true;
    }
  }

  reload(): void{
    setTimeout(function(){
      window.location.reload();
    },3000);
  }


  consultarProveedor(){

    this.cuit = this.cuit.value;       

    this._MisdatosProveedorService.getMisDatosProveedor(this.cuit).subscribe(resultado => {        
        
        if(resultado == null || resultado == 'undefined' || resultado['CUIT'] != this.cuit){

          this._snackBar.open('CUIT no registrado.', 'Cerrar', {
            duration: undefined,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          this.reload();

        } else if(resultado['CUIT'] == this.cuit){  

          this.router.navigate(['/proveedores/comprobantes/carga'], { queryParams: {cuit: this.cuit} ,state: { consultaCUIT: this.cuit } });          
        } 

    });   

  }

  linkPanel() {
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  } 

}
