import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { GetDocumentacionBasicaResponse } from "./interfaces/documentacion-service/get-documentacion-basica.interface";
import { Observable } from "rxjs";
import { GetDocumentacionCargadaResponse } from "./interfaces/documentacion-service/get-documentacion-cargada.interface";
import { DownloadDocumentacionResponse } from "./interfaces/documentacion-service/download-documentacion.interface";

@Injectable({
  providedIn: "root",
})
export class DocumentacionService {
  constructor(private httpClient: HttpClient) {}

  getDocumentacionBasica(
    sessionId: string,
    cuil:string,
    orden:string,
    nroBenef:string
  ): Observable<GetDocumentacionBasicaResponse[]> {
    return this.httpClient.post<GetDocumentacionBasicaResponse[]>(
      `${environment.urlApi}discapacidad/getDocumentacionBasica`,
      {
        sessionId: sessionId,
        cuil: cuil,
        orden: orden,
        nroBenef: nroBenef
      }
    );
  }

/*  
  // NO SE ESTA UTILIZANDO - ELIMINAR 
getDocumentacionCargada(
    sessionId: string,
    cuil: string
  ): Observable<GetDocumentacionCargadaResponse[]> {
    return this.httpClient.post<GetDocumentacionCargadaResponse[]>(
      `${environment.urlApi}discapacidad/getDocumentacionCargada`,
      {
        sessionId: sessionId,
        cuil: cuil
      }
    );
  } */

  downloadDocumentacion(
    sessionId: string,
    documentId: string
  ): Observable<DownloadDocumentacionResponse> {
    return this.httpClient.post<DownloadDocumentacionResponse>(
      `${environment.urlApi}discapacidad/downloadDocumentacion`,
      {
        sessionId: sessionId,
        id: documentId,
      }
    );
  }

  sendDocumentacion(formFile: FormData) {
    return this.httpClient.post(`${environment.urlApi}discapacidad/sendDocumentacion`, formFile)
  }
}
