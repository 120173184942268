import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-vision-plus',
  templateUrl: './vision-plus.component.html',
  styleUrls: ['./vision-plus.component.scss']
})
export class VisionPlusComponent implements OnInit {

  constructor(private meta: Meta) { 
    const description = this.meta.getTag('name=description');
    this.meta.removeTagElement(description);
    const keywords = this.meta.getTag('name=keywords');
    this.meta.removeTagElement(keywords);
  }

  ngOnInit(): void {
  }

}
