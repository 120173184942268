import { group } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewEncapsulation, ɵflushModuleScopingQueueAsMuchAsPossible } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { FormControl } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector     : 'register',
    templateUrl  : './register.component.html',
    styleUrls    : ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class RegisterComponent implements OnInit, OnDestroy
{
    registerForm: FormGroup;
    tipo = new FormControl('', [Validators.required]);
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router:Router
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: false
                },
                toolbar  : {
                    hidden: false
                },
                footer   : {
                    hidden: false
                },
                sidepanel: {
                    hidden: false
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * Validate what kind of user will sign in
     */
     checkSignIn(): void {
   
        if ( this.tipo.value === "0") {
            var newUrl = this.router.serializeUrl(this.router.createUrlTree(['/web' + '/registro-beneficiario']));
            window.open(newUrl,'_self');
            return;
        }
        
        if ( this.tipo.value === "1"){
            window.location.href = environment.urlWebSitePhp + 'usuarios/registroDDwebE';
            return;
        }
           
        if (this.tipo.value === "2"){
            window.location.href = environment.urlWebSitePhp + 'usuarios/registroDDwebR';
            return;
        } 

        if (this.tipo.value === "3"){            
            this.router.navigate(['/registro-proveedor']);
        } 

        else 
            window.location.href = environment.urlWebsite + 'home';
        
    }
    /**
     * On init
     */
    ngOnInit(): void
    {
        this.registerForm = this._formBuilder.group({
            beneficiario : false,
            empresa : false,
            redPrestadora: false
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
