import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeleccionarDatosComponent } from './seleccionar-datos.component';

import { MatButtonModule } from '@angular/material/button';
// import { MatButtonToggleModule } from '@angular/material/button-toggle';
// import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
// import { MatMenuModule } from '@angular/material/menu';
// import { MatSelectModule } from '@angular/material/select';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDemoModule } from '@fuse/components/demo/demo.module';
import { FuseHighlightModule } from '@fuse/components';
import { FuseProgressBarModule } from '@fuse/components';
import { RouterModule } from '@angular/router';

const routes = [
  {
      path     : 'seleccionar-datos',
      component: SeleccionarDatosComponent
  }
];

@NgModule({
  declarations: [SeleccionarDatosComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatListModule,
    MatTabsModule,
    MatFormFieldModule, 
    MatExpansionModule,
    FuseSharedModule,
    FuseDemoModule,
    FuseHighlightModule,
    FuseProgressBarModule
  ]
})
export class SeleccionarDatosModule { }
