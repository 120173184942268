import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cbuValidator } from 'app/shared/cbu-validator.directive';
import { Router } from '@angular/router';
import { LoginService } from 'app/services/login.service';
import { AltaCbuService } from '../../../../../services/alta-cbu.service';


@Component({
  selector: 'app-alta-cbu',
  templateUrl: './alta-cbu.component.html',
  styleUrls: ['./alta-cbu.component.scss']
})
export class AltaCBUComponent implements OnInit {
 
  constructor(private _snackBar: MatSnackBar,
              private router: Router,
              private _loginService: LoginService,
              private _altaCBU: AltaCbuService) { }

  altaCBU: FormGroup;
  cbu = new FormControl('', [Validators.required , Validators.minLength(22), cbuValidator()]);

  ngOnInit(): void {

     this._loginService.getAll().subscribe(resp => {
        if(!resp['isLogged'])
            this.router.navigate(['/login'])
    });

    this.altaCBU = new FormGroup({
      cbu : this.cbu,
    });

  }

  btnConfirmar(){
    this.altaCBU.markAllAsTouched();
        if (!this.altaCBU.valid) {
            this._snackBar.open('Recordá que debes completar todos los campos obligatorios correctamente ', 'Cerrar', {
                duration: undefined,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
            return;
        }

        const formData = new FormData();        
        formData.append('cbu', this.altaCBU.value.cbu);
        this._loginService.getSessionData().subscribe(result => {
          this._altaCBU.sendCBU(result.cuit, formData)
              .subscribe(resp => {
                console.log(resp);
                this._snackBar.open('Recibimos tu solicitud de alta con éxito. Próximamente te enviaremos un correo electrónico informándote la resolución.', 'Cerrar', {
                    duration: undefined,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                });
                return;
              },
            err => {
              this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
                  duration: undefined,
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
              });
            });

        });

  }

  linkPanel(){
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  }

}
