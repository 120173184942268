import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormPrestacionService } from '../../services/form-prestacion.service';
@Component({
  selector: 'app-alerta-observacion-documentacion',
  templateUrl: './alerta-observacion-documentacion.component.html',
  styleUrls: ['./alerta-observacion-documentacion.component.scss']
})
export class AlertaObervacionesDocumentacionComponent implements OnInit, OnChanges {
  @Input() documentacionBasica: Array<any>;
  _documentacionObservacion: boolean = false;
  _documentacionListaParEnviar: boolean = false;
  resultDocumentObservacion: Array<any> = [];

  constructor(private _formPrestacionService: FormPrestacionService) {}

  ngOnInit(): void {
    this.detectObservaciones();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documentacionBasica) {
      this.detectObservaciones();
    }
  }

  private detectObservaciones(): void {
    // Obtenemos la documentacion observada 
    this.resultDocumentObservacion = this.documentacionBasica?.filter((document) => {
        return ((document?.observada || document?.observacion != "" ) && !document.enEvaluacion && document.HPBEnfermedadDocumentoRequerido == '1') && ( !document?.uploaded || (document?.uploaded && document.idprestacionenviada != null));
    });
    
    const docObservada = this.documentacionBasica?.filter( document => (document?.observada || document?.observacion != ""  ) && document.HPBEnfermedadDocumentoRequerido == '1' && !document.enEvaluacion );
    
    const resultDocumentListaParEnviar = docObservada?.length > 0 ? docObservada.every((document) => {
      return ((document?.observada || document?.observacion != "" ) && !document.enEvaluacion ) && (document?.uploaded && document.idprestacionenviada == null);
    }) : false;


    if (this.resultDocumentObservacion?.length > 0) {
      this._documentacionObservacion = true;
      this._documentacionListaParEnviar = false;
      this._formPrestacionService.documentacionListaParaEnviar = false;
    }else if (resultDocumentListaParEnviar) {
      this._documentacionObservacion = false;
      this._documentacionListaParEnviar = true;
      this._formPrestacionService.documentacionListaParaEnviar = true;
    }else{
      this._documentacionObservacion = false;
      this._documentacionListaParEnviar = false;
      this._formPrestacionService.documentacionListaParaEnviar = true;
    }
  }
}