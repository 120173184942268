<div class="menu-logged" *ngIf="isLogged">
    <div class="containerMenu">
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="center">

            <div fxFlex="20" fxLayout="row" fxHide.lt-lg fxShow.lg fxShow.xl>
                <span (click)="clickMenu('/')" class="box-logo">
                    <img  width="139" height="42" class="logo-ase" src="assets/images/logos/logo-ase.webp" alt="ASE">
                </span>
            </div>

            <div fxFlex="5" fxFlex.xs="10" fxFlex.sm="10" fxFlex.md="10" fxLayout="row" fxLayoutAlign="end center"
                fxLayoutAlign.sm="end" fxLayoutAlign.md="end">
                <a href="{{ urlPhp }}site/inicio" class="btn-panel"><img src="assets/images/logos/icono-home.png"
                        alt="Panel"></a>
            </div>

            <div fxFlex="5" fxFlex.xs="10" fxFlex.sm="8" fxFlex.md="10" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.sm="center" fxLayoutAlign="center">
                <mat-icon *ngIf="hasRol" (click)="toggleSubmenuPpal()" clickOutside (clickOutside)="closeSubMenu()"  [ngClass]="{'submenu-ppal-active': isSubmenuPpalOpen, 'btn-menu2': !isSubmenuPpalOpen}">menu</mat-icon>           
                <div class="box-submenu-ppal">
                    <div class="submenu-ppal" [ngClass]="{'submenu-ppal-open': isSubmenuPpalOpen }" [ngClass.xs]="[isSubmenuPpalOpen ? 'submenu-ppal-open' : '', 'submenu-ppal-mobile']" [ngClass.sm]="[isSubmenuPpalOpen ? 'submenu-ppal-open' : '', 'submenu-ppal-tablet']" [ngClass.md]="[isSubmenuPpalOpen ? 'submenu-ppal-open' : '', 'submenu-ppal-tablet']">
                    <ul *ngIf="hasRol">                        
                        <li fxShow.xs fxShow.sm fxShow.md fxHide.lg fxHide.xl><a routerLink="/novedadlista">Novedades</a></li>
                        <li *ngIf="hasPlan" fxShow.xs fxShow.sm fxShow.md fxHide.lg fxHide.xl><a href="{{ urlPhp }}planes/verPlanesB">Mi Plan</a></li>
                        <li *ngIf="hasAseBienestar" fxShow.xs fxShow.sm fxShow.md fxHide.lg fxHide.xl><a href="/index.php/site/asebien">ASE Bienestar</a></li>
                        <hr fxHide.sm fxHide.md fxHide.lg fxHide.xl>
                        <li *ngFor="let item of privateMenu">
                            <a *ngIf="!item.items && !item.phpLink " class="menu-item" [routerLink]="item.url" [routerLinkActiveOptions]="{ exact: true}">{{ item ? item.label: '' }}</a> <!-- routerLinkActive="active" -->
                            <a *ngIf="item.items && !item.phpLink " class="menu-item"  [routerLink]="urlSubMenu" [routerLinkActiveOptions]="{ exact: true}">{{ item ? item.label: '' }}</a>
                            <a *ngIf="!item.items && item.phpLink " class="menu-item" (click)="clickMenu(item.url)">{{ item ? item.label: '' }}</a>
                            <a *ngIf="item.items && item.phpLink " class="menu-item"  (click)="clickMenu(urlSubMenu)">{{ item ? item.label: '' }}</a>
                        </li>                                           
                    </ul>
                </div>
                </div>
            </div>

            <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center" fxShow.lg fxShow.xl fxHide.lt-lg>
                <span *ngIf="hasRol" class="barra"></span>

                <ul *ngIf="hasRol" class="menu1">                    
                    <li><a routerLink="/novedadlista">Novedades</a></li>
                    <li *ngIf="hasPlan"><a href="{{ urlPhp }}planes/verPlanesB">Mi Plan</a></li>
                    <li *ngIf="hasAseBienestar"><a href="/index.php/site/asebien">ASE Bienestar</a></li>
                </ul>
            </div>

            <div fxFlex="20" fxFlex.xs="50" fxFlex.sm="69" fxFlex.md="65" fxLayout="row" fxLayoutAlign.xs="center" fxLayoutAlign.sm="center" fxLayoutAlign.md="center" fxShow.xs fxHide.sm fxHide.md fxHide.lg fxHide.xl>
                <span class="box-logo" (click)="clickMenu('/')">
                    <img width="60px" class="logo-ase" src="assets/images/logos/logo-ase-naranja.webp" alt="ASE">
                </span>
            </div>

            <div fxFlex="20" fxFlex.xs="50" fxFlex.sm="69" fxFlex.md="65" fxLayout="row" fxLayoutAlign.xs="center" fxLayoutAlign.sm="center" fxLayoutAlign.md="center" fxHide.xs fxShow.sm fxShow.md fxHide.lg fxHide.xl>
                <span class="box-logo" (click)="clickMenu('/')">
                    <img width="139" height="42" class="logo-ase" src="assets/images/logos/logo-ase.webp" alt="ASE">
                </span>
            </div>

            <div fxFlex="20" fxFlex.xs="20" fxFlex.sm="15" fxFlex.md="15" fxLayout="row" fxLayoutAlign="flex-end" fxLayoutAlign.sm="center" fxLayoutAlign.md="center" ngC>
                <div class="box-avatar">
                    <div class="avatar">{{ iniciales }} <span *ngIf="hasNotificationAsebienestar" class="circulo-notificacion"></span></div>                    
                    <mat-icon class="btn-icon" (click)="toggleSubmenuAvatar()" clickOutside (clickOutside)="closeSubMenuAvatar()" >arrow_drop_down</mat-icon>   
                    <div class="submenu-avatar"  [ngClass]="{'submenuAvatar-open': isSubmenuAvatarOpen}">
                        <ul>
                            <li *ngIf="rol !== 'PRVGRL' || !hasRol" [title]="nombreApellido">{{ nombreApellidoTruncado
                                }}</li>
                            <li *ngIf="rol !== 'BENMOT' && rol !== 'BENMOF' && rol !== 'BENOST' && rol !== 'BENOSF' && hasRol"
                                [title]="nombreEmpresa">{{ nombreEmpresaTruncado }}</li>
                            <li
                                *ngIf="rol !== 'BENMOT' && rol !== 'BENMOF' && rol !== 'BENOST' && rol !== 'BENOSF' && hasRol">
                                CUIT {{ cuit }}</li>
                            <hr>
                            <li class="active mb">Autogestión</li>
                            <li *ngIf="hasRol" class="pl mb" routerLink="/misdatos">Mis Datos</li>
                            <li *ngIf="hasRol" class="pl mb" [ngClass]="{'excluyo-hover': !multirol }"><a
                                    *ngIf="multirol" [routerLink]="['seleccionar-rol']">Cambiar Rol:</a> <span
                                    *ngIf="multirol">&nbsp;</span> <span *ngIf="!multirol">Rol: </span><span
                                    [title]="nombreRol">{{ rolTruncado }}</span></li>
                            <li *ngIf="hasAseBienestar && hasRol" class="mb"><a href="/index.php/site/asebien">ASE
                                    Bienestar</a><span *ngIf="hasNotificationAsebienestar"
                                    class="cantidad-notificaciones">{{ hasNotificationAsebienestar }}</span></li>

                            <li *ngIf="hasRol" class="mb"><a href="https://www.ase.com.ar/web/home">Sitio
                                    Institucional</a></li>
                            <li class="mb"><a href="{{ urlPhp }}site/logout">Cerrar Sesión <img class="icono-sesion"
                                        src="assets/images/logos/cerrar-sesion.png" alt="Cerrar Sesión"></a></li>
                        </ul>
                    </div>
                </div>

            </div>


        </div> <!-- row -->
    </div> <!-- container -->
</div>

<div [ngClass]="{'topBar': !isLogged }"> <!--'topBarLogged': isLogged -->
    <div [ngClass]="{'top-container': !isLogged }"> <!-- 'top-container-logged': isLogged -->

        <div *ngIf="!isLogged" id="boton-iniciar-sesion">
            <img src="assets    /icons/flechita_down.png">
            <a class="topLinkWhite" (click)='clickIniciarSesion()'>INICIAR SESIÓN</a>
        </div>

    </div>
</div>


<!-- #docregion multi -->
<mat-accordion multi>
    <!-- #enddocregion multi -->
    <mat-expansion-panel class="panel-login">
        <form #form name="loginForm" action="{{ env.urlLogin }}" method="POST" (ngSubmit)="onSubmit($event)"
            [formGroup]="loginForm" novalidate>
            <input type="hidden" name="returnUrl" value="{{ returnUrl }}">

            <div id="login-content">
                <div>
                    <mat-form-field>
                        <mat-label class='white'>CUIL o CUIT*</mat-label>
                        <input matInput formControlName="AuthLoginForm[username]" name="AuthLoginForm[username]">
                        <mat-error style="color:white"
                            *ngIf="loginForm.get('AuthLoginForm[username]').hasError('required')" class='white'>
                            Debe completar Usuario (CUIL o CUIT sin guiones).
                        </mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label class="white">Contraseña *</mat-label>
                        <input matInput type="password" formControlName="AuthLoginForm[contraseña]"
                            name="AuthLoginForm[contraseña]">
                        <mat-error style="color:white" class="white">
                            Debe completar Contraseña.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div id="login-button">
                    <button mat-raised-button class="submit-button" aria-label="LOG IN" name="yt0"
                        (click)="form.submit()">
                        Ingresar
                    </button>
                </div>
                <div id="links">
                    <a href="/web/pages/auth/register">
                        <p>Registrarse</p>
                    </a>
                    <!-- <a href="/index.php/site/blanquearContraseña"><p>¿Olvidó su contraseña?</p></a> -->
                    <a href="/web/olvido-clave">
                        <p>¿Olvidó su contraseña?</p>
                    </a>
                </div>
            </div>
        </form>

    </mat-expansion-panel>
</mat-accordion>