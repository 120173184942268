import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { Novedad } from 'app/models/novedad.model';

@Injectable({
    providedIn: 'root'
})
export class NovedadesService {

    novedades: Novedad[] = [];

    constructor(
        private http: HttpClient
    ) { }

    getCarousel(): Observable<Novedad[]> {
        return this.http.get<Novedad[]>(environment.urlApi + 'novedades/getCarousel');
    }

    getAll(limite: number, offset: number): Observable<Novedad[]> {
        return this.http.get<Novedad[]>(`${environment.urlApi}novedades/getFrontend?limit=${limite}&offset=${offset}`);
    }

    getById(id): Observable<Novedad[]> {
        return this.http.get<Novedad[]>(`${environment.urlApi}novedades/getById?id=${id}`);
    }

    getAllLogged(limite: number, offset: number, rol: String): Observable<Novedad[]> {
        return this.http.get<Novedad[]>(`${environment.urlApi}novedades/getLoggedFrontend?limit=${limite}&offset=${offset}&rol=${rol}`);
    }

}
