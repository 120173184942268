<div class="page-layout blank p-24" fusePerfectScrollbar>

    <div class="breadcrumbs">
        <a *ngIf="!breadcrumbLogged" href="/">Inicio</a> <span *ngIf="!breadcrumbLogged"> » </span> <span *ngIf="!breadcrumbLogged">Novedades</span>
        <a *ngIf="breadcrumbLogged" (click)="linkPanel()">Panel de Inicio</a> <span *ngIf="breadcrumbLogged"> » </span> <span *ngIf="breadcrumbLogged">Novedades</span>
    </div>

    <h1 class="page-title">Novedades</h1>


    <div id="cards" class="page-layout simple fullwidth">
            
        <div id="novedades" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="2%">                  

            <mat-card fxFlex="48%" fxFlex.xs="100%" *ngFor="let novedad of novedades | paginador:pageSize:pageNumber">            
                <img mat-card-image [src]="env.externalImagesNovedadesUrl + novedad.imagen_destacada">
                
                <mat-card-content>
                    <h2>{{ novedad.titulo | tildes }}</h2>
                    <p>{{ novedad.texto | tildes }}</p>                    
                </mat-card-content>

                <mat-card-actions>                    
                    <button mat-raised-button class="vermas" (click)="viewNovedad(novedad.id)">Ver más</button>              
                </mat-card-actions>
            </mat-card>
    
            <div id="paginador">
                <mat-paginator [length]="novedades.length"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    [pageIndex]="pageNumber-1"
                    (page)="handlePage($event)">
                </mat-paginator>    
            </div>              
    
        </div>        
    
           
    </div>
</div>