import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild,ChangeDetectorRef, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { environment } from 'environments/environment';
import {MatAccordion} from '@angular/material/expansion';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'app/services/login.service';
import { Router } from '@angular/router';
import { AsebienestarService } from 'app/services/asebienestar.service';


@Component({
    selector     : 'navbar-horizontal-style-1',
    templateUrl  : './style-1.component.html',
    styleUrls    : ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarHorizontalStyle1Component implements OnInit, OnDestroy
{
    @ViewChild(MatAccordion) accordion: MatAccordion;

    fuseConfig: any;
    navigation: any;
    iniciarSesion: boolean = false;
    loginForm: FormGroup;
    env = environment;
    returnUrl = this.env.urlWebsite + 'home?er=';
    urlPhp = this.env.urlWebSitePhp;
    isLogged: boolean = false;
    isPublic: boolean = true;
    privateMenu : any;
    urlSubMenu: any;
    haveData : boolean = false;
    nombreRol : string; // ej: Beneficiario
    rol:string; // ej: BENTIT
    cuil : string;
    hasAseBienestar: boolean;    
    session_id: string;
    multirol: boolean;
    cuit: number;
    razonSocial: string;
    nombreApellido: string;
    nombreEmpresa: string;
    nombreApellidoTruncado: string;
    nombreEmpresaTruncado: string;
    iniciales: string;
    rolTruncado: string;
    hasRol = false;
    hasBeneficios = false;
    hasPlan = false;
    hasNotificationAsebienestar: string; // notificaciones 

    // Private
    private _unsubscribeAll: Subject<any>;

    isSubmenuAvatarOpen : boolean = false; // submenu avatar

    toggleSubmenuAvatar() {
        this.isSubmenuAvatarOpen = !this.isSubmenuAvatarOpen;
    }
    closeSubMenuAvatar(){
        this.isSubmenuAvatarOpen = false;
    }
    closeSubMenu(){
        this.isSubmenuPpalOpen = false;
    }

    isSubmenuPpalOpen : boolean = false; // submenu Principal
    eliminarBtnMenu2 : boolean = true;

    toggleSubmenuPpal() {
        this.isSubmenuPpalOpen = !this.isSubmenuPpalOpen;
        this.eliminarBtnMenu2 = this.eliminarBtnMenu2;
    }  
  
    truncateText(text: string, limit: number): string {
        if (text.length > limit) {
          return text.substring(0, limit) + '...';
        }
        return text;
    }

    getIniciales(nombreApellido: string): string {
        const palabras = nombreApellido.split(' ');
        let iniciales = '';
      
        if (palabras.length >= 2) {
          const primeraPalabra = palabras[0];
          const segundaPalabra = palabras[1];
      
          if (primeraPalabra.length > 0) {
            iniciales += primeraPalabra[0];
          }
      
          if (segundaPalabra.length > 0) {
            iniciales += segundaPalabra[0];
          }
        }
      
        return iniciales.toUpperCase();
      }


    clickIniciarSesion(){
        if (!this.iniciarSesion)
        {
            this.accordion.openAll();
            this.iniciarSesion = true;
        }
        else
        {
            this.accordion.closeAll();
            this.iniciarSesion = false;
        }

    }
    clickSubMenu(urlSubMenu){
        window.location.href = urlSubMenu;
    }
    clickMenu(url) {
        window.location.href =  url;  
    }

    goToPage(url){
        this.router.navigateByUrl(url);
    }

    onSubmit(customerData) {
    }

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _formBuilder: FormBuilder,
        private _loginService: LoginService,
        private _changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private _asebienestar: AsebienestarService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        _loginService.getAll().subscribe(resp => {
           this.isLogged = resp['isLogged'];  
           //validar si esta logueado // si esta loguado ejecutar getSeesionData() //obetner los datos del menu privado y guardarlo //guardar la infomacion en un json del sessiondata//si esta logueado, y la url del win.loc no está en mi array de url publica, this.urlpublic = falseEventName//si esta loguedo y no es publica la pagina mostrar el nuevo menu 
           if(this.isLogged)
           {
                _loginService.getSessionData().subscribe(sesion =>{
                  
                    this.cuil = sesion.username;
                    this.session_id = sesion.session_id;
                    this.nombreApellido = sesion.nombre;
                    this.nombreEmpresa = sesion.razon_social;
                    this.nombreRol = sesion.nombre_rol;
                    this.multirol= sesion.multirol;                    
                    this.cuit = sesion.cuit;   
                    this.rol = sesion.rol;                 
                    if (sesion.rol){
                        this.hasRol = true;
                        _loginService.menuPerms().subscribe(perm=> {                            
                            this.hasPlan = perm.body.mi_plan;
                        });
                    }                   
                    
                    if(this.rol == 'PRVGRL'){
                        this.iniciales = this.getIniciales(this.nombreEmpresa);
                    }else {                                           
                        this.iniciales = this.getIniciales(this.nombreApellido);
                    }
                    
                    if(this.multirol){
                        this.rolTruncado = this.truncateText(this.nombreRol, 8);
                    }else {
                        this.rolTruncado = this.truncateText(this.nombreRol, 18);
                    }   
                    
                    this.nombreApellidoTruncado = this.truncateText(this.nombreApellido, 18);
                    this.nombreEmpresaTruncado = this.truncateText(this.nombreEmpresa, 18);

                    _asebienestar.hasAsebienestar(this.cuil).subscribe(data =>{ 
                        this.hasAseBienestar = data['aseBienValid'];                          
                                                
                        if(this.hasAseBienestar){

                           _asebienestar.hasNotification(this.cuil).subscribe( resp => {
                           
                               if (resp && resp.hasOwnProperty('newNotifications')) {
                                    if (resp['newNotifications'] != '0')
                                        this.hasNotificationAsebienestar= resp['newNotifications'];
                                    else
                                        this.hasNotificationAsebienestar = null; 
                               } else {
                                   // Si no se encuentra 'newNotifications' lo establesco en null
                                   this.hasNotificationAsebienestar = null;
                               }
                           });
                        }
                   });                  
               });

                _loginService.getMenu().subscribe(resp => {
                    if (resp.error == 0) 
                    {
                        this.privateMenu = resp.body.menu;

                        if (this.privateMenu.length > 10 )
                            this.urlSubMenu = this.privateMenu[2].items[0].url;
                        
                        for(let item of this.privateMenu)
                        {
                            if (item.phpLink || !item.items)
                                continue;
                            
                            if (item.url)
                            {
                                item.url = item.url.split("/");
                                item.url.unshift("/")
                            }

                            if (item.url)
                            {
                                item.urlSubMenu = item.urlSubMenu.split("/");
                                item.urlSubMenu.unshift("/")
                            }
                        }
                        
                        this._changeDetectorRef.detectChanges();
                    }     
               });
            }
        });
    
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
            });

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        this.loginForm = this._formBuilder.group({
            /*email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]*/
            'AuthLoginForm[username]' : ['', [Validators.required]],
            'AuthLoginForm[contraseña]' : ['', [Validators.required]]
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
