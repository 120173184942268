import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from 'app/services/login.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-seleccionar-datos',
  templateUrl: './seleccionar-datos.component.html',
  styleUrls: ['./seleccionar-datos.component.scss']
})
export class SeleccionarDatosComponent implements OnInit {

  SABCUIT = window.history.state.consultaCUIT; 

  constructor(private router: Router,
              private getSessionData: LoginService,
              private activatedRoute: ActivatedRoute,) {
                if(! this.SABCUIT ) 
                  this.activatedRoute.queryParams.subscribe((params) => {
                    this.SABCUIT = params["cuit"];
                    if (!this.SABCUIT)
                        this.router.navigate(['/adm-usuarios-proveedor']);
                  });
              }

  ngOnInit(): void {
    this.getSessionData.getAll().subscribe(resp => {
      if(!resp['isLogged'])
          this.router.navigate(['/login']);
    });

    if (environment.sessionId === 'undefined') {
        window.location.href = environment.urlWebSitePhp + 'site/logout';
        return;
    }
  }

  goToMisDatosProveedores(){
    this.router.navigate(['proveedores/mis-datos'] , {  queryParams: {cuit: this.SABCUIT}, state: { consultaCUIT: this.SABCUIT } }); // pasar cuit

  }

  goToCambiarEmailProveedor(){
    this.router.navigate(['cambiar-email-proveedor'] ,{  queryParams: {cuit: this.SABCUIT}, state: { consultaCUIT: this.SABCUIT } });

  }

  linkPanel() {
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  }

}
