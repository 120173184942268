<div class="prestaciones-a-enviar-box">

    <div class="contenido-prestaciones-a-enviar">
        <span class="texto-prestaciones-enviar">Prestaciones a enviar: {{cantidadDocBasica === docBasicaRequerida && getEstadoDocumentacionListaEnviar() === true ? prestacionesAEnviar : 0}} ({{convertirNumeroAPalabras(cantidadDocBasica === docBasicaRequerida && getEstadoDocumentacionListaEnviar() === true ? prestacionesAEnviar : 0)}})</span> 


        <div class="contenedor-botones">
            <button-primary-orange-component [label]="enviandoPrestaciones ? 'Enviando...' : 'Confirmar y enviar'" (click)="sendPrestacionesCompletas()" [disabled]="prestacionesAEnviar < 1 || enviandoPrestaciones || cantidadDocBasica !== docBasicaRequerida || getEstadoDocumentacionListaEnviar() === false"></button-primary-orange-component>
            
        </div>
    </div>

</div>
