import { EventEmitter, Injectable, Output } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";

import { GetMisDatosResponse } from "./interfaces/mis-datos-service/get-mis-datos.interface";

@Injectable({
  providedIn: "root",
})
export class MisdatosService {
  @Output() msjActualizarDatos = new EventEmitter();
  @Output() msjActualizarDatosProveedor: EventEmitter<any> = new EventEmitter(); // emite mensaje hacia proveedor/mis-datos

  constructor(private http: HttpClient) {}

  getMisDatos(): Observable<GetMisDatosResponse> {
    return this.http.get<GetMisDatosResponse>(
      `${environment.urlApi}misdatos/getMisDatos?userId=${environment.userId}&sessionId=${environment.sessionId}`
    );
  }

  getProvincia(provincia): Observable<any> {
    return this.http.get(
      `${environment.urlApi}misdatos/getProvincia?provincia=${provincia}`
    );
  }

  getProvincias(cod_postal): Observable<any> {
    return this.http.get(
      `${environment.urlApi}misdatos/getProvincias?cod_postal=${cod_postal}`
    );
  }

  getLocalidades(cod_postal, provincia): Observable<any> {
    return this.http.get(
      `${environment.urlApi}misdatos/getLocalidades?cod_postal=${cod_postal}&provincia=${provincia}`
    );
  }

  sendForm(formMisDatos: FormData): Observable<any> {
    return this.http.post(
      `${environment.urlApi}misdatos/updateMisDatos`,
      formMisDatos
    );
  }

  sendFormProveedor(formMisDatosProveedor: FormData): Observable<any> {
    return this.http.post(
      `${environment.urlApi}misdatos/updateMisDatos`,
      formMisDatosProveedor
    );
  }

  updateContactData(formContactData: any): Observable<any> {
    return this.http.post(
      `${environment.urlApi}misdatos/updateAll`,
      formContactData
    );
  }

    sendPasswordOld(formPasswordOld: FormData): Observable<any> {
        return this.http.post(`${environment.urlApi}misdatos/validatePassword`, formPasswordOld);
    }
}
