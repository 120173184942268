import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class DescargarComprobantePrestacionService {
    constructor(private http: HttpClient) { }

    getBinarioDescargaComprobante(nroIncidente, sessionData): Observable<any> {
        return this.http.get(`${environment.urlApi}discapacidad/comprobante/${nroIncidente}?session=${sessionData}`);
    }
}