<div class="page-layout page-layout-water-mark blank p-24" fusePerfectScrollbar>

    <div class="breadcrumbs">
        <a href="/">Inicio</a> » <span>Filiales</span>
    </div>

    <h1 class="page-title">Filiales</h1>

    <p>Garantizamos cobertura nacional a través de nuestras filiales distribuidas en puntos estratégicos, para brindarles a nuestros beneficiarios el mejor asesoramiento y la atención que requieran.</p>
    
    <div fxLayout="row wrap" class="filial" *ngFor="let filial of listFiliales$ | async" >
        <div fxFlex="100%"><h4>{{ filial.nombre | uppercase }}</h4></div>
        <div class="filial-data"  fxFlex="50%" fxFlex.xs="100%">
            <div>Domicilio: {{ filial.domicilio | uppercase }}</div>
            <div>Localidad: {{ filial.localidad | uppercase }}</div>
            <div>Horario de Atención: {{ filial.horario_atencion }}</div>
        </div>
        <div class="filial-data"  fxFlex="50%" fxFlex.xs="100%">
            <div>Email: <a href="mailto:{{ filial.email }}">{{ filial.email }}</a></div>
            <!--<div>Teléfono: {{ filial.telefono | uppercase }}</div>-->
        </div>
    </div>
  
    <div class="banners">
        <div><img src="assets/images/filiales/filiales1.jpg"></div>
        <div><img src="assets/images/filiales/filiales2.jpg"></div>
        <div><img src="assets/images/filiales/filiales3.jpg"></div>
    </div>

</div>