import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { environment } from 'environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DateAdapter } from '@angular/material/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LiquidadorService } from 'app/services/liquidador.service';
import { LoginService } from 'app/services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
//import { Workbook } from 'exceljs';
const FileSaver = require('file-saver');
import { cuitValidator } from 'app/shared/cuit-validator.directive';
import { MatSort } from '@angular/material/sort';

import { MatDialog } from '@angular/material/dialog';
import { ModalFiltrosExcelComponent } from './modal-filtros-excel/modal-filtros-excel.component';
import { ILiquidacion } from './interfaces/liquidacion.interface';
@Component({
    selector: 'app-liquidador',
    templateUrl: './liquidador.component.html',
    styleUrls: ['./liquidador.component.scss']
})
export class LiquidadorComponent implements OnInit {
    valuesToFilter: any = []

    filtros: FormGroup;
    obraSocial = new FormControl('');
    estado = new FormControl('');
    razonSocial = new FormControl('', Validators.maxLength(100));
    CUIT = new FormControl('', [Validators.pattern('^[0-9]*$'), Validators.maxLength(11), this.validateCuit])
    fechaTramiteDesde = new FormControl('');
    fechaTramiteHasta = new FormControl('');
    periodoDesde = new FormControl('');
    periodoHasta = new FormControl('');
    judicializado = new FormControl('');

    minFechaTramite = new Date('Jan 1 2022');
    periodo = [];
    periodoObj = {};
    liquidaciones = []
    dataSourcePrincipal: any;
    src = [];
    customCollapsedHeight = '50px';
    customExpandedHeight = '50px';
    tabIndex = window.history.state.tabIndex;
    sentFilters = window.history.state.sentFilters;

    fcTramiteError = false;
    periodoError = false;

    // PRINCIPAL
    displayedColumnsPrincipal: string[] = ['id', 'a', 'b', 'Periodo', 'Comprobante', 'g', 'h', 'Archivos Adjuntos', 'Marca de Liquidación', 'Observaciones'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private dateAdapter: DateAdapter<Date>,
        private router: Router,
        private _liquidadorService: LiquidadorService,
        private getSessionData: LoginService,
        private activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog
    ) {
        this.dataSourcePrincipal = new MatTableDataSource(this.liquidaciones);
        this.dateAdapter.setLocale('en-GB');

        this.getSessionData.getSessionData().subscribe(result => {
            this._liquidadorService.getLiquidaciones()
                .subscribe(data => {
                    if (!this.liquidaciones.length) {
                        this.liquidaciones = data.sort((a: ILiquidacion, b: ILiquidacion) => this.compareDates(a, b))

                        if (this.liquidaciones[0]) {
                            var newSource = [];
                            this.liquidaciones.forEach(function (liq) {
                                if (liq.i != '' && liq.i != null)
                                    return;
                                newSource.push(liq);
                            });
                            this.dataSourcePrincipal = new MatTableDataSource(newSource);
                            this.src = newSource;
                        }
                        this.dataSourcePrincipal.paginator = this.paginator;
                        this.dataSourcePrincipal.sort = this.sort;

                        if (this.tabIndex && this.tabIndex != 0) {
                            this.setTabFilter();
                        }

                        if (this.sentFilters) {
                            this.filtros.patchValue(this.sentFilters);
                            this.applyFilter(this.filtros.value);
                        }
                    }
                })
        });
    }

    compareDates(a: ILiquidacion, b: ILiquidacion) {
        const fechaA = this.convertToDates(a.h);
        const fechaB = this.convertToDates(b.h);
        return fechaB.getTime() - fechaA.getTime();
    }

    convertToDates(dateString: string) {
        const partes = dateString.split('/');
        const day = parseInt(partes[0], 10);
        const month = parseInt(partes[1], 10) - 1;
        const year = parseInt(partes[2], 10);
        return new Date(year, month, day);
    }


    openModal() {
        const dialogRef = this.dialog.open(ModalFiltrosExcelComponent, {
            width: "700px",
            height: "auto",
            autoFocus: false,
            data: {
                periodos: this.periodoObj,
                liquidaciones: this.liquidaciones,
            }
        });
        dialogRef.componentInstance.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.dialog.closeAll();
    }


    applyFilter(filtersValues) {
        this.filtros.markAllAsTouched();
        if (!this.filtros.valid) {
            this._snackBar.open('Recordá que debes completar los campos correctamente ', 'Cerrar', {
                duration: undefined,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });

            return;
        }

        var newSource = [];
        var filtersJson = {
            obraSocial: this.filtros.get('obraSocial').value,
            razonSocial: filtersValues.razonSocial?.toUpperCase(),
            CUIT: filtersValues.CUIT,
            estado: this.filtros.get('estado').value,
            fechaTramiteDesde: filtersValues.fechaTramiteDesde,
            fechaTramiteHasta: filtersValues.fechaTramiteHasta,
            periodoDesde: filtersValues.periodoDesde,
            periodoHasta: filtersValues.periodoHasta,
            judicializado: this.filtros.get('judicializado').value,
        }

        this.liquidaciones.forEach(function (liq) {

            if (filtersJson.estado == '' && liq.i != '' && liq.i != null)
                return;

            if (filtersJson.obraSocial && filtersJson.obraSocial != '' && filtersJson.obraSocial != liq.a)
                return;

            if (filtersJson.razonSocial && filtersJson.razonSocial != '' && liq.b.search(filtersJson.razonSocial) == -1)
                return;

            if (filtersJson.CUIT && filtersJson.CUIT != '' && filtersJson.CUIT != liq.k && liq.k.search(filtersJson.CUIT) == -1)
                return;

            if (filtersJson.estado && filtersJson.estado != '' && filtersJson.estado != liq.i)
                return;

            if (filtersJson.judicializado && filtersJson.judicializado != '' && filtersJson.judicializado != liq.l)
                return;

            if (filtersJson.fechaTramiteDesde && filtersJson.fechaTramiteDesde != '' && liq.h != '') {
                var fchTramite = liq.h;
                var day = '';
                var month = '';
                var year = '';

                if (fchTramite.substring(1, 2) == '/') {
                    day = '0' + fchTramite.substring(0, 1);
                    fchTramite = fchTramite.substring(2, fchTramite.length);
                }
                else {
                    day = fchTramite.substring(0, 2);
                    fchTramite = fchTramite.substring(3, fchTramite.length);
                }

                if (fchTramite.substring(1, 2) == '/') {
                    month = '0' + fchTramite.substring(0, 1);
                    fchTramite = fchTramite.substring(2, fchTramite.length);
                }
                else {
                    month = fchTramite.substring(0, 2);
                    fchTramite = fchTramite.substring(3, fchTramite.length);
                }

                year = fchTramite;

                var d = filtersJson.fechaTramiteDesde;
                var month2 = d.getMonth();
                month2 += 1;
                if (month2 < 10)
                    month2 = '0' + month2.toString();

                var day2 = d.getDate();
                if (day2 < 10)
                    day2 = '0' + day2.toString();

                var fchFiltro = parseInt(d.getFullYear().toString() + month2.toString() + day2.toString());
                var fchLiq = parseInt(year.toString() + month.toString() + day.toString());

                console.log(this.filtros)
                if (fchLiq < fchFiltro)
                    return;
            }

            if (filtersJson.fechaTramiteHasta && filtersJson.fechaTramiteHasta != '' && liq.h != '') {
                var fchTramite = liq.h;
                var day = '';
                var month = '';
                var year = '';

                if (fchTramite.substring(1, 2) == '/') {
                    day = '0' + fchTramite.substring(0, 1);
                    fchTramite = fchTramite.substring(2, fchTramite.length);
                }
                else {
                    day = fchTramite.substring(0, 2);
                    fchTramite = fchTramite.substring(3, fchTramite.length);
                }

                if (fchTramite.substring(1, 2) == '/') {
                    month = '0' + fchTramite.substring(0, 1);
                    fchTramite = fchTramite.substring(2, fchTramite.length);
                }
                else {
                    month = fchTramite.substring(0, 2);
                    fchTramite = fchTramite.substring(3, fchTramite.length);
                }

                year = fchTramite;

                var d = filtersJson.fechaTramiteHasta;
                var month2 = d.getMonth();
                month2 += 1;
                if (month2 < 10)
                    month2 = '0' + month2.toString();

                var day2 = d.getDate();
                if (day2 < 10)
                    day2 = '0' + day2.toString();

                var fchFiltro = parseInt(d.getFullYear().toString() + month2.toString() + day2.toString());
                var fchLiq = parseInt(year.toString() + month.toString() + day.toString());

                if (fchLiq > fchFiltro)
                    return;
            }

            if (filtersJson.periodoDesde && filtersJson.periodoDesde != '' && liq.c != '') {
                let fechaLiq = liq.c;
                if (fechaLiq.length == 5)
                    fechaLiq = fechaLiq.substring(0, 4) + '0' + fechaLiq.substring(4, 5)

                let fechaFilter = parseInt(filtersJson.periodoDesde);
                if (fechaLiq < fechaFilter)
                    return;
            }

            if (filtersJson.periodoHasta && filtersJson.periodoHasta != '' && liq.c != '') {
                let fechaLiq = liq.c;
                if (fechaLiq.length == 5)
                    fechaLiq = fechaLiq.substring(0, 4) + '0' + fechaLiq.substring(4, 5)

                let fechaFilter = parseInt(filtersJson.periodoHasta);
                if (fechaLiq > fechaFilter)
                    return;
            }

            newSource.push(liq);
        })

        this.dataSourcePrincipal = new MatTableDataSource(newSource);
        this.src = newSource;
        this.dataSourcePrincipal.paginator = this.paginator;
        this.dataSourcePrincipal.sort = this.sort;
    }

    ngAfterViewInit() {
        this.dataSourcePrincipal.paginator = this.paginator;
        this.dataSourcePrincipal.sort = this.sort;
    }


    clear() {
        this.filtros.setValue({
            obraSocial: '',
            estado: '',
            razonSocial: '',
            CUIT: '',
            fechaTramiteDesde: '',
            fechaTramiteHasta: '',
            periodoDesde: '',
            periodoHasta: '',
            judicializado: 0
        })
        this.setTabFilter();
    }

    setOSandStatusValue(event: any): void {
        let value = event.tab.textLabel
        if (value === 'Principal') {
            this.filtros.patchValue({
                obraSocial: '',
                estado: '',
                razonSocial: '',
                CUIT: '',
                fechaTramiteDesde: '',
                fechaTramiteHasta: '',
                mesPeriodoDesde: '',
                anioPeriodoDesde: '',
                mesPeriodoHasta: '',
                anioPeriodoHasta: '',
                judicializado: 0
            });
            this.tabIndex = 0;
        } else if (value === 'ASE' || value === 'OSPM' || value === 'OSEN') {
            this.filtros.patchValue({
                obraSocial: value,
                estado: '',
                razonSocial: '',
                CUIT: '',
                fechaTramiteDesde: '',
                fechaTramiteHasta: '',
                mesPeriodoDesde: '',
                anioPeriodoDesde: '',
                mesPeriodoHasta: '',
                anioPeriodoHasta: '',
                judicializado: 0
            })

            if (value === 'ASE')
                this.tabIndex = 1;
            else if (value === 'OSEN')
                this.tabIndex = 2;
            else if (value === 'OSPM')
                this.tabIndex = 3;

        } else if ((value === 'Liquidado' || value === 'Ver/Mod Autorización' || value === 'Pendiente')) {
            this.filtros.patchValue({
                obraSocial: '',
                estado: value,
                razonSocial: '',
                CUIT: '',
                fechaTramiteDesde: '',
                fechaTramiteHasta: '',
                mesPeriodoDesde: '',
                anioPeriodoDesde: '',
                mesPeriodoHasta: '',
                anioPeriodoHasta: '',
                judicializado: 0
            })
        } else if ((value === 'Judicializados')) {
            this.filtros.patchValue({
                obraSocial: '',
                estado: '',
                razonSocial: '',
                CUIT: '',
                fechaTramiteDesde: '',
                fechaTramiteHasta: '',
                mesPeriodoDesde: '',
                anioPeriodoDesde: '',
                mesPeriodoHasta: '',
                anioPeriodoHasta: '',
                judicializado: 1
            })
        }

        if (value === 'Pendiente')
            this.tabIndex = 4;
        else if (value === 'Ver/Mod Autorización')
            this.tabIndex = 5;
        else if (value === 'Judicializados')
            this.tabIndex = 6;
        else if (value === 'Liquidado')
            this.tabIndex = 7;
        this.applyFilter(this.filtros.value)
    }

    validarPeriodo(control: AbstractControl): ValidationErrors | null {

        const periodoDesdeControl = control.get("periodoDesde");
        const periodoHastaControl = control.get("periodoHasta");

        if (periodoDesdeControl.value && periodoHastaControl.value) {

            const anioDesde = periodoDesdeControl.value.substring(0, 4);
            const mesDesde = periodoDesdeControl.value.substring(4, 6);

            const anioHasta = periodoHastaControl.value.substring(0, 4);
            const mesHasta = periodoHastaControl.value.substring(4, 6);

            if (anioDesde && mesDesde && anioHasta && mesHasta) {
                let desde = new Date(anioDesde, mesDesde)
                let hasta = new Date(anioHasta, mesHasta)

                if (desde.getTime() > hasta.getTime()) {
                    console.log(desde, hasta)
                    return { periodo: true };
                }

                return null
            }
        }

        return null;
    }

    validarFechaTramite(control: AbstractControl): ValidationErrors | null {
        const desde = control.get('fechaTramiteDesde').value;
        const hasta = control.get('fechaTramiteHasta').value;
        if (desde & hasta) {
            if (desde > hasta) {
                return { fechaTramite: true };
            }
        }

        return null;
    }

    validateCuit(control: AbstractControl): { [key: string]: any } | null {
        let cuit = String(control.value);
        if (!control.value) {
            return null;
        }
        if (cuit.length !== 11) {
            return { cuit: { value: 'El campo CUIT debe tener 11 caracteres.' } };
        }

        let acumulado = 0;
        let digitos = cuit.split('');
        let digito = digitos.pop();

        for (let i = 0; i < digitos.length; i++) {
            acumulado += Number(digitos[9 - i]) * (2 + (i % 6));
        }

        let verif = 11 - (acumulado % 11);
        if (verif === 11) {
            verif = 0;
        } else if (verif === 10) {
            verif = 9;
        }

        return (Number(digito) == Number(verif)) ? null : { cuit: { value: 'El CUIT ingresado es inválido. ' } };
    }



    ngOnInit(): void {

        this.getSessionData.getAll().subscribe(resp => {
            if (!resp['isLogged'])
                this.router.navigate(['/login']);

            this.getSessionData.getSessionData().subscribe(result => {
                if (!result['rol'] || result['rol'] != 'ASELIQ')
                    this.router.navigate(['/login']);
            });
        });

        this.activatedRoute.queryParams.subscribe((params) => {
            let cuil = '';
            cuil = params["inUse"];
            if (cuil != '' && cuil != undefined) {
                this._snackBar.open('El registro al que intenta acceder está siendo editado por ' + cuil, 'Cerrar', {
                    duration: undefined,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                });
            }
        });

        //meses y años para periodos
        const anioActual = new Date().getFullYear();
        const anioDesde = 2022;
        const periodo = [];
        const mesActual = new Date().getMonth() + 1;

        for (let i = anioDesde; i <= anioActual; i++) {

            if (anioActual != i) {
                periodo.push({ mesAnio: 'Enero ' + i + '', value: i.toString() + '01'.toString() });
                periodo.push({ mesAnio: 'Febrero ' + i + '', value: i.toString() + '02'.toString() });
                periodo.push({ mesAnio: 'Marzo ' + i + '', value: i.toString() + '03'.toString() });
                periodo.push({ mesAnio: 'Abril ' + i + '', value: i.toString() + '04'.toString() });
                periodo.push({ mesAnio: 'Mayo ' + i + '', value: i.toString() + '05'.toString() });
                periodo.push({ mesAnio: 'Junio ' + i + '', value: i.toString() + '06'.toString() });
                periodo.push({ mesAnio: 'Julio ' + i + '', value: i.toString() + '07'.toString() });
                periodo.push({ mesAnio: 'Agosto ' + i + '', value: i.toString() + '08'.toString() });
                periodo.push({ mesAnio: 'Septiembre ' + i + '', value: i.toString() + '09'.toString() });
                periodo.push({ mesAnio: 'Octubre ' + i + '', value: i.toString() + '10'.toString() });
                periodo.push({ mesAnio: 'Noviembre ' + i + '', value: i.toString() + '11'.toString() });
                periodo.push({ mesAnio: 'Diciembre ' + i + '', value: i.toString() + '12'.toString() });

            } else {

                if (mesActual >= 1) { periodo.push({ mesAnio: 'Enero ' + i + '', value: i.toString() + '01'.toString() }) };
                if (mesActual >= 2) { periodo.push({ mesAnio: 'Febrero ' + i + '', value: i.toString() + '02'.toString() }) };
                if (mesActual >= 3) { periodo.push({ mesAnio: 'Marzo ' + i + '', value: i.toString() + '03'.toString() }) };
                if (mesActual >= 4) { periodo.push({ mesAnio: 'Abril ' + i + '', value: i.toString() + '04'.toString() }) };
                if (mesActual >= 5) { periodo.push({ mesAnio: 'Mayo ' + i + '', value: i.toString() + '05'.toString() }) };
                if (mesActual >= 6) { periodo.push({ mesAnio: 'Junio ' + i + '', value: i.toString() + '06'.toString() }) };
                if (mesActual >= 7) { periodo.push({ mesAnio: 'Julio ' + i + '', value: i.toString() + '07'.toString() }) };
                if (mesActual >= 8) { periodo.push({ mesAnio: 'Agosto ' + i + '', value: i.toString() + '08'.toString() }) };
                if (mesActual >= 9) { periodo.push({ mesAnio: 'Septiembre ' + i + '', value: i.toString() + '09'.toString() }) };
                if (mesActual >= 10) { periodo.push({ mesAnio: 'Octubre ' + i + '', value: i.toString() + '10'.toString() }) };
                if (mesActual >= 11) { periodo.push({ mesAnio: 'Noviembre ' + i + '', value: i.toString() + '11'.toString() }) };
                if (mesActual >= 12) { periodo.push({ mesAnio: 'Diciembre ' + i + '', value: i.toString() + '12'.toString() }) };
            }
        }
        this.periodoObj = JSON.parse(JSON.stringify(periodo.reverse()));

        this.filtros = new FormGroup({
            razonSocial: this.razonSocial,
            CUIT: this.CUIT,
            obraSocial: this.obraSocial,
            estado: this.estado,
            periodoDesde: this.periodoDesde,
            periodoHasta: this.periodoHasta,
            fechaTramiteDesde: this.fechaTramiteDesde,
            fechaTramiteHasta: this.fechaTramiteHasta,
            judicializado: this.judicializado
        }, { validators: [this.validarPeriodo, this.validarFechaTramite] });


        this.dataSourcePrincipal.filterPredicate = (data: any, filters: string) => {
            const matchFilter: any = [];
            let isDatesComprobanteMatch = true;
            let isDatesTramiteMatch = true;
            const filterArray = filters.split('+');
            const arrayInfoClient = filterArray.map(el => el.trim()).slice(0, 4)
            const arrayDatesComprobante = filterArray.slice(4, 8).filter((val: string) => val !== '')
            const arrayDatesTramite = filterArray.slice(8, 10).filter((val: string) => val !== '')
            const columns = (<any>Object).values(data);

            const dateComprobante = new Date(`${data.Periodo_anio}-${data.Periodo_mes_numero}`)
            const dateComprobanteFormDesde = new Date(`${arrayDatesComprobante[1]}-${arrayDatesComprobante[0]}`)
            const dateComprobanteFormHasta = new Date(`${arrayDatesComprobante[3]}-${arrayDatesComprobante[2]}`)
            var dateTramiteParts = data.Fecha_Tramite.split("/")
            const dateTramite = new Date(+dateTramiteParts[2], dateTramiteParts[1] - 1, +dateTramiteParts[0])
            const dateTramiteFormDesde = new Date(`${arrayDatesTramite[0]}`)
            const dateTramiteFormHasta = new Date(`${arrayDatesTramite[1]}`)

            arrayInfoClient.forEach(filter => {
                const customFilter = [];
                columns.forEach(column => customFilter.push(column.toLowerCase().includes(filter)));
                matchFilter.push(customFilter.some(Boolean));
            });

            if (arrayDatesComprobante.length === 4) {
                isDatesComprobanteMatch = dateComprobante >= dateComprobanteFormDesde && dateComprobante <= dateComprobanteFormHasta
            }

            if (arrayDatesTramite.length === 2) {
                isDatesTramiteMatch = dateTramite >= dateTramiteFormDesde && dateTramite <= dateTramiteFormHasta
            }

            return matchFilter.every(Boolean) && isDatesComprobanteMatch && isDatesTramiteMatch
        }

        // En algún lugar de tu componente
        this.dataSourcePrincipal = new MatTableDataSource(this.liquidaciones);
        this.dataSourcePrincipal.sort = this.sort;

        // Cuando tus datos cambien, puedes actualizar la vista ordenada
        this.dataSourcePrincipal.sortData(this.dataSourcePrincipal.data, this.dataSourcePrincipal.sort);

    }

    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }

    marcarComprobante(id) {
        let tabIndex = this.tabIndex;
        let sentFilters = this.filtros.value;
        this.router.navigate(['marcar-comprobante'], {
            queryParams: { id: id },
            state: { tabIndex, sentFilters }
        });
    }

    /*   exportExcel() {
          if (this.src.length == 0)
              return;
  
          const date = new Date();
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          const hour = date.getHours();
          const minutes = date.getMinutes();
          const seconds = date.getSeconds();
          const workBook = new Workbook();
          const workSheet = workBook.addWorksheet('test');
          const newRow = workSheet.addRow(['CONSULTA DE LIQUIDACIONES']);
          const currentRowIdx = workSheet.rowCount; // Find out how many rows are there currently
          const endColumnIdx = workSheet.columnCount; // Find out how many columns are in the worksheet
          // merge by start row, start column, end row, end column
          workSheet.mergeCells(currentRowIdx, 1, 1, 9);
  
          var cel = newRow.getCell(1);
          cel.alignment = { horizontal: 'center' },
              cel.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'e75300' },
              };
          cel.font = { color: { argb: 'ffffff' }, bold: true }
  
  
          const titles = workSheet.addRow(['Obra Social', 'Razón Social', 'Periodo', 'Comprobante', 'Importe', 'Número de Trámite', 'Fecha de Trámite', 'Marca de Liquidación', 'Observaciones']);
          for (var i = 1; i < 10; i++) {
              cel = titles.getCell(i);
              cel.alignment = { horizontal: 'center' },
                  cel.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'e75300' },
                  };
              cel.font = { color: { argb: 'ffffff' }, bold: true }
          }
  
          this.src.forEach((item) => {
              let comprobante = item.d + ' - ' + item.e.toString() + ' - ' + item.f.toString();
              workSheet.addRow([item.a, item.b, item.c, comprobante, item.g, item.id, item.h, item.i, item.j]);
          });
  
          workSheet.columns.forEach(function (column, i) {
              if (i !== 0) {
                  var maxLength = 0;
                  column["eachCell"]({ includeEmpty: true }, function (cell) {
                      var columnLength = cell.value ? cell.value.toString().length : 10;
                      if (columnLength > maxLength) {
                          maxLength = columnLength;
                      }
                  });
                  column.width = maxLength < 10 ? 10 : maxLength;
              }
          });
  
          let tabName = '';
          switch (this.tabIndex) {
              case 0:
                  tabName = 'principal'
                  break;
              case 1:
                  tabName = 'ase'
                  break;
              case 2:
                  tabName = 'osen'
                  break;
              case 3:
                  tabName = 'ospm'
                  break;
              case 4:
                  tabName = 'pendiente'
                  break;
              case 5:
                  tabName = 'ver mod autorizacion'
                  break;
              case 6:
                  tabName = 'judicializado'
                  break;
              case 7:
                  tabName = 'liquidado'
                  break;
              default:
                  tabName = 'principal'
                  break;
          }
  
          workBook.xlsx.writeBuffer().then((data) => {
              let blob = new Blob([data], {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              FileSaver(blob, 'rl_' + tabName + '_' + year + month + day + hour + minutes + seconds + '.xlsx');
          });
      } */

    setTabFilter() {
        if (this.tabIndex == 1) {
            this.filtros.patchValue({
                obraSocial: 'ASE',
                estado: '',
                razonSocial: '',
                CUIT: '',
                fechaTramiteDesde: '',
                fechaTramiteHasta: '',
                mesPeriodoDesde: '',
                anioPeriodoDesde: '',
                mesPeriodoHasta: '',
                anioPeriodoHasta: '',
                judicializado: 0
            });
        } else if (this.tabIndex == 2) {
            this.filtros.patchValue({
                obraSocial: 'OSEN',
                estado: '',
                razonSocial: '',
                CUIT: '',
                fechaTramiteDesde: '',
                fechaTramiteHasta: '',
                mesPeriodoDesde: '',
                anioPeriodoDesde: '',
                mesPeriodoHasta: '',
                anioPeriodoHasta: '',
                judicializado: 0
            })
        } else if (this.tabIndex == 3) {
            this.filtros.patchValue({
                obraSocial: 'OSPM',
                estado: '',
                razonSocial: '',
                CUIT: '',
                fechaTramiteDesde: '',
                fechaTramiteHasta: '',
                mesPeriodoDesde: '',
                anioPeriodoDesde: '',
                mesPeriodoHasta: '',
                anioPeriodoHasta: '',
                judicializado: 0
            })
        } else if (this.tabIndex == 4) { //((value === 'Liquidado' || value === 'Ver/Mod Autorización' || value === 'Pendiente')) {
            this.filtros.patchValue({
                obraSocial: '',
                estado: 'Pendiente',
                razonSocial: '',
                CUIT: '',
                fechaTramiteDesde: '',
                fechaTramiteHasta: '',
                mesPeriodoDesde: '',
                anioPeriodoDesde: '',
                mesPeriodoHasta: '',
                anioPeriodoHasta: '',
                judicializado: 0
            })
        } else if (this.tabIndex === 5) {
            this.filtros.patchValue({
                obraSocial: '',
                estado: 'Ver/Mod Autorización',
                razonSocial: '',
                CUIT: '',
                fechaTramiteDesde: '',
                fechaTramiteHasta: '',
                mesPeriodoDesde: '',
                anioPeriodoDesde: '',
                mesPeriodoHasta: '',
                anioPeriodoHasta: '',
                judicializado: 0
            })
        } else if (this.tabIndex === 6) {
            this.filtros.patchValue({
                obraSocial: '',
                estado: '',
                razonSocial: '',
                CUIT: '',
                fechaTramiteDesde: '',
                fechaTramiteHasta: '',
                mesPeriodoDesde: '',
                anioPeriodoDesde: '',
                mesPeriodoHasta: '',
                anioPeriodoHasta: '',
                judicializado: 1
            })
        } else if (this.tabIndex === 7) {
            this.filtros.patchValue({
                obraSocial: '',
                estado: 'Liquidado',
                razonSocial: '',
                CUIT: '',
                fechaTramiteDesde: '',
                fechaTramiteHasta: '',
                mesPeriodoDesde: '',
                anioPeriodoDesde: '',
                mesPeriodoHasta: '',
                anioPeriodoHasta: '',
                judicializado: 0
            })
        }

        this.applyFilter(this.filtros.value)
    }
}