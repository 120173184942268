<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_planes.svg" class="icon">
        </div>

        <div class="menu-aside">
            <a fxHide.lt-md (click)="exportarPdf()"><mat-icon matTooltip="EXPORTAR A PDF">picture_as_pdf</mat-icon></a>
        </div>
    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">

        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <span>Adm. Judicializados</span>
        </div>

        <h2 class="page-subtitle">Administrar Judicializados</h2>

        <div fxFlex="100" fxLayout="row" fxLayout.xs="column">

            <div fxFlex="50" fxFlex.xs="100" fxFlexOrder.xs="2">

                <!-- tabla -->
                <div class="mat-elevation-z0">
                    <table mat-table [dataSource]="dataSource">

                        <!-- DNI Column -->
                        <ng-container matColumnDef="dni">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> DNI </th>
                            <td mat-cell *matCellDef="let row"> {{row.dni}} </td>
                        </ng-container>

                        <!-- Nombre y Apellido Column -->
                        <ng-container matColumnDef="nomyape">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellido y Nombre </th>
                            <td mat-cell *matCellDef="let row"> {{row ? row.nomeyape : ''}} </td>
                        </ng-container>


                        <!-- Acciones Column -->
                        <ng-container matColumnDef="acciones">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                            <td mat-cell *matCellDef="let row"><a
                                    (click)="deleteDNI(row.dni, row.id)"><mat-icon>delete</mat-icon></a> </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>

                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"
                        aria-label="Select page of users"></mat-paginator>
                </div>

                <!-- end tabla -->
            </div>

            <div fxFlex="50" fxFlex.xs="100" fxFlexOrder.xs="1" fxLayout="column">
                <form [formGroup]="altaDNI">
                    <mat-card fxLayout="column">
                        <h4>Agregar DNI judicializado</h4>
                        <mat-form-field class="mat-fullwidth" fxFlex.xs="100" fxFlex="100" fxLayout="column">
                            <input matInput placeholder="DNI" formControlName="dni" type="number"
                                onKeyPress="if(this.value.length === 8) return false;" />
                            <mat-error *ngIf="altaDNI.get('dni').hasError('dni')">{{ altaDNI.get('dni').errors.dni.value
                                }}</mat-error>
                        </mat-form-field>
                        <div fxFlex="100" fxLayout="column">
                            <button type="button" mat-raised-button class="btnAgregar"
                                (click)="createDNI()"><mat-icon>check_circle</mat-icon> Guardar marca
                                judicializado</button>
                        </div>
                    </mat-card>
                </form>
            </div>

        </div>
    </div>

</div>