import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import * as shape from 'd3-shape';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-clubase',
  templateUrl: './clubase.component.html',
  styleUrls: ['./clubase.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ClubaseComponent implements OnInit {

  env = environment;

  constructor() { 
    window.location.href='http://somosase.com.ar/';
  }

  ngOnInit(): void {
  }

}
