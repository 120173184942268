<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
        <div>
            <img src="assets/images/iconos/icono_protesis.svg" class="icon">
        </div>
    </div>

    <div fxFlex="81" fxFlex.xs="100" fxFlex.lt-md="100" fxLayout="column" id="content">
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a [routerLink]="['/protesis']">PRÓTESIS, ORTESIS Y OTROS</a>
            » <span>FORMULARIOS</span>
        </div>

        <h2 class="page-subtitle">Formularios</h2>

        <p>Descargue el Formulario según la gestión que deba realizar:</p>


        <table class="forms">
            <thead>
                <tr>
                    <th>
                        Formulario
                    </th>
                    <th>
                        Descargar
                    </th>
                    <th>
                        Descripción
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        Consentimiento informado
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/Consentimiento%20Ortesis{{ extension }}" target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                    <td>
                        Órtesis
                    </td>
                </tr>
                <tr>
                    <td>
                        Consentimiento informado
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/Consentimiento%20Protesis{{ extension }}" target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                    <td>
                        Prótesis
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>