<!-- mensaje en carga factura que se muestra si el proveedor no tiene cargado un CBU -->
<span *ngIf="data === 'faltaCBU'">No es posible continuar con la carga de comprobantes porque se detecta que no poseés CBU cargado bajo tu CUIT. Por favor ingresá a <a class="link" (click)="dismiss()" routerLink="proveedores/mis-datos">Mis Datos</a> y agregalo.</span>

<!-- Mensaje al enviar/cargar factura luego de validar AFIP -->
<div *ngIf="data[0] === 'envioOKMisPagos'">
    
    <span> Tu requerimiento se cargó correctamente bajo el número de incidente {{data[1]}}
        , podrás visualizarlo dentro de las próximas 24 horas en la sección de <a class="link" (click)="dismiss()" routerLink="proveedores/mis-pagos">Mis Pagos</a>.     
    </span>
    <div class="btn-cerrar-snackbar">
        <a (click)="dismiss()" routerLink="proveedores/comprobantes/carga">Cerrar</a>
    </div>
</div>


<!-- Mensaje al enviar/cargar factura luego de validar AFIP -->
<div *ngIf="data[0] === 'envioOk'">
    
    <span> Tu requerimiento se cargó correctamente bajo el número de incidente
    {{ data[1] }}.
    </span>
    <div class="btn-cerrar-snackbar">
        <a (click)="dismiss()" routerLink="proveedores/comprobantes/carga">Cerrar</a>
    </div>
</div>

<!-- Mensaje de gestion de medicamentos -->
<div *ngIf="data[0] === 'medicamentos' || data[0] === 'protesis' || data[0] === 'reintegros'">
    
    <span style="letter-spacing: .5px;"> Tu número de gestión es {{ data[1] }} Podrás visualizarla en la opción Estado de solicitudes de medicamentos e insumos en el plazo de 72hs hábiles
    </span>
    <div style="margin-top: 10px">
        <div class="btn-copiar-snackbar">
            <a (click)="copyText(data[1])" style="cursor: pointer; color: #171616b5;font-weight: bold;letter-spacing: .5px;">Copiar número de gestión</a>
        </div>
        <div class="btn-cerrar-snackbar">
            <a (click)="dismiss()" style="cursor: pointer;color: #171616b5; left: 0%;font-weight: bold;letter-spacing: .5px;">Cerrar</a>
        </div>
    </div>
</div>




