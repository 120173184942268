import { Component, Inject, OnInit } from "@angular/core";

@Component({
  selector: "app-modal-informacion-documentacion-basica",
  templateUrl: "./modal-informacion-documentacion-basica.component.html",
  styleUrls: ["./modal-informacion-documentacion-basica.component.scss"],
})

export class ModalInformacionDocumentacionBasicaComponent implements OnInit {
  closeModal: () => void;

  constructor(
  ) {}

  onCloseModal() {
    if (this.closeModal) {
      this.closeModal();
    }
  }

  ngOnInit(): void {}
}