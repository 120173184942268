import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MarcarComprobanteService } from 'app/services/marcar-comprobante.service';
import { environment } from 'environments/environment';
import { borderTopRightRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LiquidadorService } from 'app/services/liquidador.service';
import { LoginService } from 'app/services/login.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { io } from 'socket.io-client';
import { SocketioService } from 'app/services/socketio.service';
const FileSaver = require('file-saver');

@Component({
    selector: 'app-marcar-comprobante',
    templateUrl: './marcar-comprobante.component.html',
    styleUrls: ['./marcar-comprobante.component.scss']
})
export class MarcarComprobanteComponent implements OnInit {

    marcas: FormGroup;
    marcaLiquidadores = new FormControl('');
    observaciones = new FormControl('');
    contrasenia = new FormControl({ value: '', disabled: true });
    liquidacion: any;
    id: any;
    cuil: any;
    nombre: any;
    nroFactura: any;
    cuitProveedor: any;
    tipo: any;
    puntoDeVenta: any;
    pdfFile: any;
    pdfFile2: any;
    oldMarca = '';
    marcaReseted = false;
    socket;
    tabIndex = window.history.state.tabIndex;
    sentFilters = window.history.state.sentFilters;
    screenWidth: any;
    extraDocExists = false;
    extraDocBlob = null;
    stringNombrePlantilla = '';
    title = 'socketio-angular';
    blobFile: any;
    sessionId: any;

    constructor(
        private _marcarComprobante: MarcarComprobanteService,
        private _snackBar: MatSnackBar,
        private router: Router,
        private _liquidadorService: LiquidadorService,
        private getSessionData: LoginService,
        private activatedRoute: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private socketService: SocketioService

    ) {
        this.getSessionData.getSessionData().subscribe(result => {
            this.cuil = result['username'];
            this.nombre = result['nombre'];
            this.activatedRoute.queryParams.subscribe((params) => {
                this.id = params["id"];
                this.socketService.setupSocketConnection(this.id, this.nombre);
                this.socketService.getNewMessage().subscribe((message: string) => {
                    if (message.includes('In use') != false) {
                        //this.socketService.clearMessages();
                        this.router.navigate(['liquidador'], { queryParams: { inUse: message.replace('In use by ', '') } });
                    }
                });
                this._liquidadorService.getLiquidacion(this.id)
                    .subscribe(data => {
                        this.liquidacion = data;
                        this.marcaLiquidadores.setValue(this.liquidacion[0].marcaliq);
                        this.observaciones.setValue(this.liquidacion[0].observaciones);

                        this.nroFactura = this.liquidacion[0].tmpfacnro;
                        this.cuitProveedor = this.liquidacion[0].tmpfacpro;
                        this.tipo = this.liquidacion[0].tmpfacser;
                        this.puntoDeVenta = this.liquidacion[0].tmpptovta;
                        this.oldMarca = this.liquidacion[0].marcaliq;
                        this._liquidadorService.getFactura(this.liquidacion[0].tmpfacpro, this.nroFactura)
                            .subscribe(result2 => {
                                if (result2.length == 0) {
                                    this._snackBar.open('No se encontraron arhivos cargados.', 'Cerrar', {
                                        duration: undefined,
                                        horizontalPosition: 'center',
                                        verticalPosition: 'top',
                                    });
                                }
                                else if (result2.length > 0) {
                                    for (var i in result2) {
                                        var blob = new Blob([new Uint8Array(result2[i][0].data).buffer], { type: "application/pdf" });
                                        this.blobFile = new Blob([new Uint8Array(result2[i][0].data).buffer], { type: "application/pdf" });
                                        var fileURL = URL.createObjectURL(blob)+"#toolbar=0&navpanes=0&statusbar=0";
                                        this.pdfFile = fileURL;
                                        this.pdfFile2 = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
                                        break;
                                    
                                    }
                                };
                            });

                        this._liquidadorService.getPlantilla(this.liquidacion[0].tmpfacpro, this.nroFactura)
                        .subscribe(result2 => {
                            if (result2.length > 0) {
                                for (var i in result2) {
                                    this.extraDocExists = true
                                    //Fecha para el documento
                                    const date = new Date();
                                    const year = date.getFullYear();
                                    const month = date.getMonth() + 1;
                                    const day = date.getDate();
                                    const hour = date.getHours();
                                    const minutes = date.getMinutes();
                                    const seconds = date.getSeconds();
                                    let stringNombrePlantilla = year + month + day + hour + minutes + seconds + '_' + result2[i][1];
                                    var blob = new Blob([new Uint8Array(result2[i][0].data).buffer], { type: this.getMimeType(stringNombrePlantilla.substring(stringNombrePlantilla.lastIndexOf('.') +1)) });
                                    this.stringNombrePlantilla = stringNombrePlantilla;
                                    this.extraDocBlob  = blob;
                                    break;
                                }
                            };
                        });
                    })
            });
        })
    };

    ngOnInit(): void {

        this.getSessionData.getAll().subscribe(resp => {
            if (!resp['isLogged'])
                this.router.navigate(['/login']);

            this.getSessionData.getSessionData().subscribe(result => {
                if (!result['rol'] || result['rol'] != 'ASELIQ')
                    this.router.navigate(['/login']);
                else 
                  this.sessionId = result['session_id'];
            });
        });
        this.screenWidth = window.innerWidth;
        this.marcas = new FormGroup({
            marcaLiquidadores: this.marcaLiquidadores,
            observaciones: this.observaciones,
            contrasenia: this.contrasenia
        });

        document.getElementById('contrasenia').setAttribute('disabled', '');
        this.marcas.get('marcaLiquidadores').valueChanges.subscribe((evento) => {
            if (this.oldMarca == 'Liquidado' && this.oldMarca != this.marcaLiquidadores.value && evento !== undefined)
                this.marcaReseted = true;
            else if (this.oldMarca == 'Liquidado' && this.oldMarca == this.marcaLiquidadores.value && evento !== undefined)
                this.marcaReseted = false;

            if (evento != 'Liquidado' && !this.marcaReseted) {

                document.getElementById('contrasenia').setAttribute('disabled', '');

            } else if (evento != 'Liquidado' && this.marcaReseted) {
                document.getElementById('contrasenia').removeAttribute('disabled');
            }

            if (evento === undefined) {
                document.getElementById('contrasenia').setAttribute('disabled', '');
            }
        });
    }

    guardarMarca() {

        const formData = new FormData();

        formData.append('id', this.id);
        formData.append('cuil', this.cuil);

        if (!this.marcaLiquidadores.value)
            formData.append('marcaLiquidadores', '');
        else
            formData.append('marcaLiquidadores', this.marcaLiquidadores.value);

        if (!this.observaciones.value)
            formData.append('observaciones', '');
        else
            formData.append('observaciones', this.observaciones.value);

        if (!this.contrasenia.value)
            formData.append('contrasenia', '');
        else
            formData.append('contrasenia', this.contrasenia.value);

        if (this.marcaReseted)
            formData.append('reset', '1');
        else
            formData.append('reset', '0');

        this._marcarComprobante.sendMark(formData,this.sessionId).subscribe(resp => {
            this._snackBar.open('Documento marcado con éxito', 'Cerrar', {
                duration: undefined,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
            this.oldMarca = this.marcaLiquidadores.value;
        },
            err => {
                this._snackBar.open(err['error']['message'], 'Cerrar', {
                    duration: undefined,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                });
            });
        
        this.contrasenia.setValue('');
    }

    deshacerMarca() {
        if (this.oldMarca != '' && this.oldMarca != null)
            this.marcaReseted = true;

            const formData = new FormData();

            formData.append('id', this.id);
            formData.append('cuil', this.cuil);
    
            if (!this.marcaLiquidadores.value)
                formData.append('marcaLiquidadores', '');
            else
                formData.append('marcaLiquidadores', this.marcaLiquidadores.value);
    
            if (!this.observaciones.value)
                formData.append('observaciones', '');
            else
                formData.append('observaciones', this.observaciones.value);
    
            if (!this.contrasenia.value)
                formData.append('contrasenia', '');
            else
                formData.append('contrasenia', this.contrasenia.value);
    
            if (this.marcaReseted)
                formData.append('reset', '1');
            else
                formData.append('reset', '0');
    
            this._marcarComprobante.sendMark(formData,this.sessionId).subscribe(resp => {
                this._snackBar.open('Documento desmarcado con éxito', 'Cerrar', {
                    duration: undefined,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                });
                this.oldMarca = this.marcaLiquidadores.value;
            },
                err => {
                    this._snackBar.open(err['error']['message'], 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                });
        this.marcas.reset();
    }

    descargarComprobante(){
        const puntoDeVenta = String(this.puntoDeVenta).padStart(4, '0')
        const nroFactura = String(this.nroFactura).padStart(8, '0')

        FileSaver.saveAs(this.blobFile,`${this.cuitProveedor}_${this.tipo}_${puntoDeVenta}_${nroFactura}.pdf`);
    }

    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }

    ngOnDestroy() {
        this.socketService.disconnect();
    }

    salir() {
        let tabIndex = this.tabIndex;
        let sentFilters = this.sentFilters;
        this.router.navigate(['liquidador'], {
            state: { tabIndex, sentFilters }
        });
    }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }

  getMimeType(extension){
    switch(extension.toLowerCase()){
        case 'pdf':
            return 'application/pdf'
        case 'docx':
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        case 'doc':
            return 'application/msword';
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg'
        case 'png':
            return 'image/png'
    }
}

    plantilla(){
        FileSaver.saveAs(this.extraDocBlob, this.stringNombrePlantilla);
    }
}