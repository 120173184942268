import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SolicitarRolEmpresaService {

  @Output() msjCUITEmpresa: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) { }

  sendForm(formRolEmpresa: FormData): Observable<any>{
    return this.http.post(`${environment.urlApi}roles/solicitarRolEmpresa`, formRolEmpresa);
  }   
}
