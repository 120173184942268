
<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
               
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_reintegros.svg" class="icon">
        </div> 

       

    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <span><a routerLink="/proveedores/mis-datos">Mis Datos</a></span> » <span>Alta CBU</span> 
        </div>

        <h2 class="page-subtitle">Formulario para la acreditación de pagos en cuenta Bancaria</h2>

        <p id="mensaje">El CBU debe ser bancario y el mismo debe estar a nombre de la razón social que factura. No debe declararse bancos o cuentas virtuales.</p>
        <p id="obligatorio">Los campos marcados con * son obligatorios.</p>
        
        <form [formGroup]="altaCBU">

        <div fxLayout="row">
            <mat-form-field class="mat-fullwidth" fxFlex="50" fxFlex.xs="100">
                <mat-label>CBU</mat-label>
                <input matInput formControlName="cbu" required type="text" minlength="22" onKeyPress="if (this.value.length==22) return false;" >
                <mat-error *ngIf="altaCBU.get('cbu').hasError('required') || altaCBU.get('cbu').hasError('minlength')">Debe ingresar los 22 dígitos del CBU.</mat-error>
                <mat-error *ngIf="altaCBU.get('cbu').hasError('validaCBU')">{{ cbu.errors.validaCBU.value }}</mat-error>                 
            </mat-form-field>           
        </div>

        <div fxLayout="row">
            <button type="button" mat-raised-button id="btnConfirmar" (click)="btnConfirmar()">Confirmar</button>
        </div>

    </form>
        
    </div>


</div>


