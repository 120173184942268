import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CargaFacturaComponent } from './carga-factura.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MensajesHtmlComponent } from 'app/main/private/mensajes-html/mensajes-html.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';


const routes = [
  {
      path     : 'proveedores/comprobantes/carga/carga-comprobante',
      component: CargaFacturaComponent
  }
];

@NgModule({
  declarations: [
    CargaFacturaComponent,
    MensajesHtmlComponent
    
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatCardModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatExpansionModule,
    MaterialFileInputModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatIconModule,
    MatSnackBarModule
  ],
  entryComponents: [
    MensajesHtmlComponent
  ]
  
})

export class CargaFacturaModule { }
