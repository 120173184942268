import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrestacionesAEnviarService {

  constructor(private http: HttpClient) { }

  sendPrestacionesCompletas(sessionId: string, cuil:string, nroBeneficiario, nroOrden):Observable<any>{
    const body = {
      sessionId: sessionId,
      cuil: cuil,
      nroBeneficiario: nroBeneficiario,
      nroOrden: nroOrden,
    };
    return this.http.post(`${environment.urlApi}discapacidad/sendPrestacionesCompletas`, body);
  }
}