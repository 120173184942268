import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdmJudicializadosService {

  constructor(private http: HttpClient) {
  }

  getAllDni(): Observable<any[]>{
    return this.http.get<any[]>(`${environment.urlApi}liquidador/getListadoDniJudicializados`);
  }

  createDni(form: FormData):Observable<any>{
    return this.http.post(`${environment.urlApi}liquidador/marcarDniJudicializado`, form);
  }

  deleteDni(form: FormData):Observable<any>{
    return this.http.post(`${environment.urlApi}liquidador/eliminarDniJudicializado`, form);
  }
  getbeneficiarioData(dni: any, sessionId : any): Observable<any>{
    return this.http.get<any[]>(environment.urlApi + '/aBeneficiarioData/getABeneficiarioDataByDni?sid=' + sessionId + '?dni=' + dni);
 }
}
