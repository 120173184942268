<form [formGroup]="filtros">
  <div class="title-container">
    <span class="filter-title">Filtros</span>
    <div class="title-container-buttons">

      <div class="tooltip-container">
        <span (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false">
          <mat-icon>live_help</mat-icon>
        </span>
        <div class="tooltip" *ngIf="showTooltip">
          <p>El liquidador debe completar al menos uno de los siguentes filtros:</p>
          <ul>
            <li>CUIT</li>
            <li>Solapa + CUIT</li>
            <li>Obra social + Solapa</li>
            <li>Obra social + Fecha (DESDE - HASTA)</li>
            <li>Obra social + Período (DESDE - HASTA)</li>
            <li>Fecha (DESDE - HASTA) + Solapa</li>
            <li>Fecha (DESDE - HASTA) + CUIT</li>
            <li>Período (DESDE - HASTA) + Solapa</li>
            <li>Período (DESDE - HASTA) + CUIT</li>
          </ul>
        </div>

      </div>
      <button mat-icon-button (click)="closeModal()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="filtros" fxLayout="column" class="mat-fullwidth" fxFlex.xs="100">

    <div fxLayout="row" class="mat-fullwidth" fxFlex.xs="100">
      <mat-form-field class="mat-fullwidth" fxFlex.xs="100" fxFlex="100">
        <input matInput placeholder="CUIT" #input formControlName="CUIT" type="number"
          onKeyPress="if(this.value.length === 11) return false;">
        <mat-error *ngIf="filtros.get('CUIT').hasError('cuit')">{{filtros.get('CUIT').errors.cuit.value}}</mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row" class="mat-fullwidth" fxFlex.xs="100">
      <mat-form-field class="mat-fullwidth" fxFlex.xs="100" fxFlex="50">
        <mat-label>Obra Social</mat-label>
        <mat-select formControlName="obra_social">
          <mat-option *ngFor="let obraSocial of obrasSociales" value="{{ obraSocial.tipo }}">{{
            obraSocial.tipo.toUpperCase() }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="mat-fullwidth" fxFlex.xs="100" fxFlex="50">
        <mat-label>Solapa</mat-label>
        <mat-select formControlName="solapa">
          <mat-option *ngFor="let solapa of solapas" value="{{ solapa.solapaRaw }}">{{
            solapa.solapaRaw.toUpperCase() }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="mat-fullwidth" fxLayout="column" class="mat-fullwidth" fxFlex.xs="100">

      <div fxFlex.lt-md="100" fxFlex="50" fxLayout="row">
        <mat-form-field class="mat-fullwidth" fxFlex.lt-md="50" fxLayout.xs="row" fxFlex="50">
          <mat-label>Período comprobante Desde</mat-label>
          <mat-select formControlName="periodoDesde">
            <mat-option *ngFor="let periodo of periodos" value="{{ periodo.value }}">{{
              periodo.mesAnio }}</mat-option>
          </mat-select>
          <mat-error *ngIf="filtros.get('periodoDesde').hasError('required')">Debe agregar el período desde</mat-error>
        </mat-form-field>
        <mat-form-field class="mat-fullwidth" fxFlex.lt-md="50" fxLayout.xs="row" fxFlex="50">
          <mat-label>Período comprobante Hasta</mat-label>
          <mat-select formControlName="periodoHasta">
            <mat-option *ngFor="let periodo of periodos" value="{{ periodo.value }}">{{
              periodo.mesAnio }}</mat-option>
          </mat-select>
          <mat-error *ngIf="filtros.get('periodoHasta').hasError('required')">Debe agregar el período hasta</mat-error>
        </mat-form-field>
      </div>

      <div fxFlex.lt-md="100" fxFlex="50" fxLayout="row">

        <mat-form-field class="mat-fullwidth" fxFlex.lt-md="50" fxLayout.xs="row" fxFlex="50">
          <mat-label>Fecha Trámite Desde</mat-label>
          <input matInput [matDatepicker]="fechaTramiteDesde" #input formControlName="fechaTramiteDesde"
            [max]="maxFechaLimite" readonly>
          <mat-datepicker-toggle matSuffix [for]="fechaTramiteDesde"></mat-datepicker-toggle>
          <mat-datepicker #fechaTramiteDesde></mat-datepicker>
          <mat-error *ngIf="filtros.get('fechaTramiteDesde').hasError('required')">Debe agregar la fecha
            desde</mat-error>
        </mat-form-field>

        <mat-form-field class="mat-fullwidth" fxFlex.lt-md="50" fxLayout.xs="row" fxFlex="50">
          <mat-label>Fecha Trámite Hasta</mat-label>
          <input matInput [matDatepicker]="fechaTramiteHasta" #input formControlName="fechaTramiteHasta"
            [max]="maxFechaLimite" readonly>
          <mat-datepicker-toggle matSuffix [for]="fechaTramiteHasta"></mat-datepicker-toggle>
          <mat-datepicker #fechaTramiteHasta></mat-datepicker>
          <mat-error *ngIf="filtros.get('fechaTramiteHasta').hasError('required')">Debe agregar la fecha
            hasta</mat-error>
        </mat-form-field>
      </div>

    </div>
    <mat-error *ngIf="filtros.hasError('periodo')">El período seleccionado es inválido.
    </mat-error>
    <mat-error *ngIf="filtros.hasError('fechaTramite')">El período seleccionado es inválido.
    </mat-error>

    <div style="justify-content: space-between" fxLayout="row" class="mat-fullwidth" fxFlex.xs="100">
      <button type="button" mat-raised-button [disabled]="isDisabled()" class="btnDescargar"
        (click)="onAccept()">Descargar</button>
      <button type="button" mat-raised-button class="btnLimpiar" (click)="clear()">Limpiar filtro</button>
    </div>

  </div>
</form>