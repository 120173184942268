<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm fxHide>

        <div fxHide.xs>
            <img src="assets/images/iconos/icono_altausuarios.svg" class="icon">
        </div>

    </div>

    <div fxFlex="81" class="content" fxFlex.lt-md="100">

        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a routerLink="/proveedores/mis-datos">Mis datos</a> » <span>Actualizar
                Mis Datos</span>
        </div>

        <h2 class="page-subtitle">Actualizar Mis Datos</h2>

        <form method="POST" novalidate [formGroup]="formActualizarDatos">

            <div class="data">   
                <div class="tw">            
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Email</mat-label>
                        <input type="email" matInput formControlName="email" >
                        <!-- <mat-error *ngIf="formActualizarDatos.get('email').hasError('required')">Debe ingresar su email.</mat-error> -->
                        <mat-error *ngIf="formActualizarDatos.get('email').hasError('pattern')">Ingrese un email válido.</mat-error>
                    </mat-form-field>
                </div> 
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Teléfono</mat-label>
                        <input type="tel" matInput formControlName="telefono" maxlength="20">
                        <!-- <mat-error *ngIf="formActualizarDatos.get('telefono').hasError('required')">Debe ingresar su teléfono.</mat-error> -->
                    </mat-form-field>
                </div>
            </div>
            

            <div class="data">  
                <div class="tw" fxFlex.xs="100">             
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Domicilio Profesional</mat-label>
                        <input type="text" matInput formControlName="domicilioProfesional" >
                        <!-- <mat-error *ngIf="formActualizarDatos.get('domicilioProfesional').hasError('required')">Debe ingresar su domicilio profesional.</mat-error> -->
                    </mat-form-field>
                </div> 
            
              
            </div>

            <div class="data">  
                <div class="tw" fxFlex.xs="100">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Elegí tu provincia</mat-label>
                            <mat-select name="filial" formControlName="filial" >
                                <mat-option *ngFor="let option of listFiliales" [value]="option">
                                    {{option}}
                                </mat-option>                            
                            </mat-select>
                        <!-- <mat-error *ngIf="formActualizarDatos.get('filial').hasError('required')">Elegí tu filial de acuerdo con tu domicilio fiscal.</mat-error>   -->
                    </mat-form-field>          
                </div>

                <div class="tw">            
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>CBU</mat-label>
                        <input type="text" matInput formControlName="cbu" readonly>
                    </mat-form-field>
                </div>
            </div>

            <div class="data">  
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Condición impositiva</mat-label>
                        <mat-select name="condicionImpositiva" formControlName="condicionImpositiva" >
                            <mat-option value="IVA Exento">IVA Exento</mat-option>
                            <mat-option value="RI">RI</mat-option>
                            <mat-option value="Monotributo">Monotributo</mat-option>
                        </mat-select>
                        <!-- <mat-error *ngIf="formActualizarDatos.get('condicionImpositiva').hasError('required')">Debe ingresar su condicion impositiva.</mat-error> -->
                    </mat-form-field>
                </div>      

                <div class="tw">            
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Especialidades</mat-label>
                        <input type="text" matInput formControlName="especialidades" >
                        <!-- <mat-error *ngIf="formActualizarDatos.get('especialidades').hasError('required')">Debe ingresar su especialidad.</mat-error> -->
                    </mat-form-field>
                </div>  
            </div>

            <div class="data">
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Matrícula</mat-label>
                        <input type="text" matInput formControlName="matricula" >
                        <!-- <mat-error *ngIf="formActualizarDatos.get('matricula').hasError('required')">Debe ingresar su matrícula.</mat-error> -->
                    </mat-form-field>
                </div>    
                
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Categoría</mat-label>
                        <input type="text" matInput formControlName="categoria" >
                        <!-- <mat-error *ngIf="formActualizarDatos.get('categoria').hasError('required')">Debe ingresar su categoría.</mat-error> -->
                    </mat-form-field>
                </div>  
            </div>

            <div fxLayout="row">
                <button mat-raised-button id="btnActualizar" (click)="sendForm()" >Actualizar</button>
                <button mat-raised-button id="btnCancelar" routerLink="/proveedores/mis-datos">Cancelar</button>
            </div> 
        </form>

    </div>
</div>
