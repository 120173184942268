<div id="login" fxLayout="column">

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                    <a href="/home"><img src="assets/images/logos/logo_ase.png" ></a>
            </div>

            <div class="title">INICIAR SESIÓN</div>

            <form #form name="loginForm" action="{{ env.urlLogin }}" method="POST" (ngSubmit)="onSubmit($event)" [formGroup]="loginForm" novalidate>

            <input type="hidden" name="returnUrl" value="{{ returnUrl }}">
                <mat-form-field appearance="outline">
                    <mat-label>CUIL o CUIT *</mat-label>
                    <input matInput formControlName="AuthLoginForm[username]" name="AuthLoginForm[username]">
                    <mat-icon matSuffix class="secondary-text">receipt</mat-icon>
                    <mat-error *ngIf="loginForm.get('AuthLoginForm[username]').hasError('required')">
                        Ingrese su CUIL o CUIT sin guiones
                    </mat-error>
                    <!--<mat-error
                        *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')">
                        Please enter a valid email address
                    </mat-error>-->
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Contraseña</mat-label>
                    <input matInput type="password" formControlName="AuthLoginForm[contraseña]" name="AuthLoginForm[contraseña]">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Ingrese su contraseña
                    </mat-error>
                </mat-form-field>

                <!--div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="space-between center">
                    <mat-checkbox class="remember-me" aria-label="Remember Me">
                        Remember Me
                    </mat-checkbox>-->

                    <a class="forgot-password" [routerLink]="'/olvido-clave'">
                        ¿Olvidó su contraseña?
                    </a>
                <!-- </div>-->

                <mat-error *ngIf="error">
                    {{ error }}
                </mat-error>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN" name="yt0"
                        [disabled]="loginForm.invalid" (click)="form.submit()">
                    LOGIN
                </button>

            </form>
<!--
            <div class="separator">
                <span class="text">OR</span>
            </div>

            <button mat-raised-button class="google">
                Log in with Google
            </button>

            <button mat-raised-button class="facebook">
                Log in with Facebook
            </button>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Don't have an account?</span>
                <a class="link" [routerLink]="'/pages/auth/register'">Create an account</a>
            </div>
-->
        </div>

    </div>

</div>