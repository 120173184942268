import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paginador',
  pure: false
})
export class PaginadorPipe implements PipeTransform {

  
  
  transform(array : any[], pageSize: any , pageNumber: number): any[] {
    if (!array) { return []; }   
    if (pageSize === 'all'){
      return array;
    }

    pageSize = pageSize || 4;
    pageNumber = pageNumber || 1;
   
    --pageNumber;
   
    return array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
  }
}


