import { Component, OnInit } from '@angular/core';
import { LoginService } from 'app/services/login.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-instructivos-discapacidad',
  templateUrl: './instructivos-discapacidad.component.html',
  styleUrls: ['./instructivos-discapacidad.component.scss']
})
export class InstructivosDiscapacidadComponent implements OnInit {

    env = environment;
    extension : string = '.pdf';
    isLogged : boolean;
    rol: string;
    
    constructor(private _loginService: LoginService, private router: Router,) { }

    ngOnInit(): void {

        this._loginService.getAll().subscribe(resp => {
            this.isLogged = resp['isLogged'];
            if(this.isLogged)
            {
                 this._loginService.getSessionData().subscribe(resp => {
                    this.rol = resp['rol'];
                    if (this.rol == 'BENOST' || this.rol == 'BENOSF')
                        this.router.navigate(['home']);
                });
             }
             else
                this.router.navigate(['/login'])
         });
      }
      
    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio'
    }

    linkDiscapacidad() {
        if (this.rol == 'PRVGRL' || this.rol == 'ASEPRV')
            this.router.navigate(['proveedores/discapacidad']);
        else
            this.router.navigate(['discapacidad']);
    }

}
