import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { cuitValidator } from 'app/shared/cuit-validator.directive';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SolicitarRolEmpresaService } from '../../../../../services/solicitar-rol-empresa.service';
import { Router } from '@angular/router';
import { LoginService } from 'app/services/login.service';

@Component({
    selector: 'app-solicitar-rol-empresa',
    templateUrl: './solicitar-rol-empresa.component.html',
    styleUrls: ['./solicitar-rol-empresa.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class SolicitarRolEmpresaComponent implements OnInit {

    CUIT = new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(11), cuitValidator()]);
    msjCUITEmpresa = true;

    constructor(private _snackBar: MatSnackBar,
        private _solicitarRolEmpresaService: SolicitarRolEmpresaService,
        private router: Router,
        private _loginService: LoginService) { }


    ngOnInit(): void {
        this._loginService.getAll().subscribe(resp => {
            if(!resp['isLogged'])
                this.router.navigate(['/login'])
        });
    }

    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }

    btnEnviarCuit() {

        const formData = new FormData();
        formData.append('cuit', this.CUIT.value);
        formData.append('cuil', environment.cuil);

        this._solicitarRolEmpresaService.sendForm(formData)
            .subscribe(resp => {
                //emito msjCUITEmpresa hacia misdatos
                this._solicitarRolEmpresaService.msjCUITEmpresa.emit({
                    data: this.msjCUITEmpresa
                })
                this.router.navigate(['misdatos']);
            },
                err => {
                    this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                });

    }

}
