import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { LoginService } from 'app/services/login.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-protesisindex',
    templateUrl: './protesisindex.component.html',
    styleUrls: ['./protesisindex.component.scss']
})
export class ProtesisIndexComponent implements OnInit {

    constructor(private _loginService: LoginService,
        private router: Router,) { }

    ngOnInit(): void {
        this._loginService.getAll().subscribe(resp => {
            if(!resp['isLogged'])
                this.router.navigate(['/login'])
        });
    }

    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }

    btnEstado() {
        window.location.href = environment.urlWebSitePhp + 'protesis/ctaSolicitudes';
    }

}
