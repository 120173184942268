import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import { LoginService } from 'app/services/login.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private _loginService: LoginService,
        private router: Router) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot):Promise<boolean>  {
        var isLogged;

        return this._loginService.getAll().toPromise().then(resp => {
            isLogged = resp['isLogged'];
            if (!isLogged) {
                this.router.navigate(['/'])
                return false;
            }
            return true;
        });
    }
}