import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormulariosProtesisComponent } from './formularios-protesis.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { FlexLayoutModule } from "@angular/flex-layout";

const routes = [
    {
        path     : 'formularios-protesis',
        component: FormulariosProtesisComponent
    }
  ];

@NgModule({
  declarations: [FormulariosProtesisComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatIconModule,
    MatDividerModule,
    FlexLayoutModule
  ]
})
export class FormularioProtesisModule { }
