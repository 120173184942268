import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CargaFacturaService {

  constructor(private http: HttpClient) { }

  getCBU(cuit){
    return this.http.post(`${environment.urlApi}proveedor/aloginProveedor`, cuit)
  }

  getPeriodosPrestaciones(BenNroAse : String, Bennroord: String, DiscaExAnio: String, DiscaTramSec: String): any{
    return this.http.get<any[]>(`${environment.urlApi}proveedor/getPeriodosPrestaciones?BenNroAse=${BenNroAse}&Bennroord=${Bennroord}&DiscaExAnio=${DiscaExAnio}&DiscaTramSec=${DiscaTramSec}`);
  }

  sendForm(form: FormData): Observable<any> {
    return this.http.post(`${environment.urlApi}proveedor/sendForm`, form);
  }
  
  validateAfip(form: FormData): Observable<any> {
    return this.http.post(`${environment.urlApi}proveedor/validateAfip`, form);
  }
}