import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DetallesPrestacionService {

  constructor(private http: HttpClient) { }

  getPrestadorActivo(cuit): Observable<any> {
    return this.http.post(`${environment.urlApi}discapacidad/aPrestadorActivo`, cuit);
  }

  descargarArchivo(data): Observable<any> {
    return this.http.post(`${environment.urlApi}discapacidad/downloadPrestacionFile`, data);
  }

  getDetallePrestacion(data): Observable<any> {
    return this.http.post(`${environment.urlApi}discapacidad/getDetalle/Prestacion`, data);
  }

}
