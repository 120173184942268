import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoginService } from 'app/services/login.service';
import { MisdatosProveedorService } from 'app/services/misdatos-proveedor.service';
import { cuitValidator } from 'app/shared/cuit-validator.directive';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-consulta-mis-pagos',
  templateUrl: './consulta-mis-pagos.component.html',
  styleUrls: ['./consulta-mis-pagos.component.scss']
})
export class ConsultaMisPagosComponent implements OnInit {

  consultaPagos: FormGroup;
  
  constructor(
              private router: Router,
              private getSessionData: LoginService,
              private _MisdatosProveedorService: MisdatosProveedorService,
              private _snackBar: MatSnackBar) { }

  cuit = new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(11), cuitValidator()]);

  ngOnInit(): void {

    this.getSessionData.getAll().subscribe(resp => {
      if(!resp['isLogged'])
          this.router.navigate(['/login'])
    });

    if (environment.sessionId === 'undefined') {
        window.location.href = environment.urlWebSitePhp + 'site/logout';
        return;
    }

  this.consultaPagos = new FormGroup({
    cuit: this.cuit
  });
  }


  keyPressNumbers(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
    event.preventDefault();
    return false;
    } else {
    return true;
    }
  }

  reload(): void{
    setTimeout(function(){
      window.location.reload();
    },3000);
  }

  consultarPagos(){    

    this.cuit = this.cuit.value;       

    this._MisdatosProveedorService.getMisDatosProveedor(this.cuit).subscribe(resultado => {
                
        if(resultado == null || resultado == 'undefined' || resultado['CUIT'] != this.cuit){

          this._snackBar.open('CUIT no registrado.', 'Cerrar', {
            duration: undefined,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          this.reload();

        } else if(resultado['CUIT'] == this.cuit){

          this.router.navigate(['/proveedores/mis-pagos'], { queryParams: {cuit: this.cuit}, state: { consultaCUIT: this.cuit } });
        } 

    });   

  }

  linkPanel() {
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  } 

}
