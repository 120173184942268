<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>               
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_planes.svg" class="icon">
        </div>
    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a routerLink="/liquidador">Liquidador </a> » <span>Marcar comprobante</span>
        </div>        

        <h2 class="page-subtitle">Marcar comprobante</h2>

        <form [formGroup]="marcas">
            <div fxLayout="row">                
                <mat-form-field class="mat-fullwidth" fxFlex="20" fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="50">
                    <mat-label>Marca liquidadores</mat-label>
                    <mat-select formControlName="marcaLiquidadores" required>
                        <mat-option value=""></mat-option>
                        <mat-option value="Pendiente">Pendiente</mat-option>
                        <mat-option value="Ver/Mod Autorización">Ver/Mod Autorización</mat-option>
                        <mat-option value="Liquidado">Liquidado</mat-option>
                    </mat-select>
                    <mat-error *ngIf="marcas.get('marcaLiquidadores').hasError('required')">Debe seleccionar marca de liquidadores
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row">
                <mat-form-field class="mat-fullwidth" fxFlex="100" fxFlex.xs="100">
                    <mat-label>Observaciones</mat-label>
                    <textarea rows="2" matInput [formControl]="observaciones" required></textarea>
                    <mat-error *ngIf="marcas.get('observaciones').hasError('required')">Debe ingresar sus observaciones.</mat-error>
                </mat-form-field>
            </div>               
        </form>

        
        <form [formGroup]="marcas">                 
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="space-between" fxLayout.lt-md="column">   
                <div fxFlex="31" fxFlex.lt-md="100">                    
                    <mat-form-field class="mat-fullwidth" fxFlex="100" id="box-contrasenia">
                        <mat-label>Contraseña</mat-label>
                        <input matInput formControlName="contrasenia" id="contrasenia" required type="password">
                        <mat-error *ngIf="marcas.get('contrasenia').hasError('required')">Debe introducir contraseña de inicio de sesión.</mat-error>
                    </mat-form-field>                    
                </div>
                <div fxFlex="18" fxFlex.lt-md="100">
                    <button type="button" mat-raised-button class="btnGuardarMarca" (click)="guardarMarca()"><mat-icon>check</mat-icon> Guardar marca</button>
                </div>
                <div fxFlex="19" fxFlex.lt-md="100">
                    <button type="button" mat-raised-button class="btnDeshacerMarca" (click)="deshacerMarca()"><mat-icon>refresh</mat-icon> Deshacer marca</button>
                </div>
                <div fxFlex="17" fxFlex.lt-md="100">
                    <button type="button" mat-raised-button class="btnDescargarComprobante" (click)="descargarComprobante()"><mat-icon>archive</mat-icon> Descargar</button>
                </div>
                <div *ngIf="extraDocExists" fxFlex="12" fxFlex.lt-md="100">
                    <button type="button" mat-raised-button class="btnPlantilla" (click)="plantilla()"><mat-icon>archive</mat-icon> Plantilla</button>
                </div>
                <div fxFlex="22" fxFlex.lt-md="100">
                    <button type="button" mat-raised-button class="btnSalir" (click)="salir()"><mat-icon>arrow_back</mat-icon> Salir del comprobante</button>
                </div>
            </div>
        </form>
       

        <div fxLayout="row" fxFlex="100" fxLayout.lt-md="column">
            <div *ngIf="screenWidth > 768" fxFlex="100" fxFlex.xs="100" fxFlex.lt-lg="100" id="iframeContainer">
                <iframe [src]="pdfFile2" frameborder="0" width="100%" heigth="100%" id="iframe" allowfullscreen></iframe>
            </div>

            <div *ngIf="screenWidth <= 768" fxFlex="100" fxFlex.lt-md="100" id="pdfViewerContainer">
                <pdf-viewer [src]="pdfFile"   [rotation]="0"
                [original-size]="false"
                [show-all]="true"
                [fit-to-page]="false"
                [zoom]="1"
                [zoom-scale]="'page-width'"
                [stick-to-page]="false"
                [render-text]="true"
                [external-link-target]="'blank'"
                [autoresize]="true"
                [show-borders]="false"
                style="width: 100%; height: 100%;"></pdf-viewer>
            </div>
        </div>
    </div>
    
</div>
