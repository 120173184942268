import { NgModule } from "@angular/core";
import { AuthDialogComponent } from "./auth-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  imports: [MatDialogModule],
  exports: [],
  declarations: [AuthDialogComponent],
  providers: [],
})
export class AuthDialogModule {}
