<div class="container-main">
  <div class="container-information">
    <span>
      Comunicado para beneficiarios
    </span>
    <h2>
      Atención
    </h2>
    <p>
      Te informamos que la cobertura de prestaciones especiales por Discapacidad se encuentra a cargo de tu red prestadora MEDIFE, por lo tanto deberás dirigirte a <a href="https://www.medife.com.ar" target="_blank">www.medife.com.ar</a>
    </p>
  </div>
  <div class="container-buttons">
    <button-secundary-blue-light-component label="Atrás" [disabled]="false"
      (onClick)="onRedirectToPanel()"></button-secundary-blue-light-component>
  </div>
</div>