<div class="container-main">
    <div class="container-information">
      <div class="container-icon-subtitle">
        <span>
          ELIMINAR PRESTACIÓN
        </span>
        <span class="close-icon" (click)="cerrarDialogo()">
          <img src="./assets/icons/DA-icons/close.svg" alt="">
        </span>
      </div>
      <h2>
        ¿Deseas eliminar la prestación?
      </h2>
      <p>
        Esta acción es irrevocable. Deberás comenzar desde el inicio. 
      </p>
    </div>

    <div class="container-buttons">
      <button class="blue-btn" (click)="cerrarDialogo()">Cancelar</button>
      <button class="orange-btn" (click)="confirmado()" >Confirmar</button>
    </div>
  </div>
