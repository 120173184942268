<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">
    
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
        <div>
            <img src="assets/images/iconos/icono_medicamentos.svg" class="icon">
        </div>
    </div>
    <div fxFlex="81" fxFlex.xs="100" fxFlex.lt-md="100" fxLayout="column">
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <span>Medicamentos e Insumos</span>
        </div>

        <h2 class="page-subtitle">Medicamentos e Insumos</h2>
        <p>Es importante que previamente a realizar tus pedidos, consultes en la sección <a (click)="miPlan()">MI PLAN</a> el alcance de tu cobertura en la sección "Medicamentos".
            De acuerdo a tu plan podría corresponder la cobertura a través de tu red prestadora.</p>

    <div fxFlex="50" fxFlex.xs="100" id="panel-one">
        <mat-accordion hideToggle>
                                    
            <mat-expansion-panel disabled class="list-item" [routerLink]="['/solicitud-medicamentos']">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p class="listed-link">Solicitud de medicamentos e insumos</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
            </mat-expansion-panel>
            <mat-expansion-panel disabled class="list-item" (click)="btnEstado()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p class="listed-link">Estado de las solicitudes de medicamentos e insumos</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
            </mat-expansion-panel>
            <mat-expansion-panel disabled class="list-item" [routerLink]="['/medicamentos-formularios-instructivos']">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p class="listed-link">Descarga de formularios e instructivos</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>   
            </mat-expansion-panel>
            <mat-expansion-panel disabled class="list-item" [routerLink]="['/medicamentos/preguntas-frecuentes']">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p class="listed-link">Preguntas frecuentes</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
            </mat-expansion-panel>         
        </mat-accordion>                    
    </div>

</div>

