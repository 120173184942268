<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>               
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_reintegros.svg" class="icon">
        </div>

    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a routerLink="/proveedores/mis-pagos">Mis pagos</a> » <span>Adjunto</span></div>        

        <h2 class="page-subtitle">Comprobante Adjunto</h2>

        <p><b>Detalle Nota de Débito</b></p>

        
       
        <p class="items">Número de comprobante: {{NroFactura ? NroFactura : ' '}}</p>
        <p class="items">Número de Liquidación: {{NroReintegro ? NroReintegro : ' '}}</p>
        <p class="items">Observaciones: <!-- {{Observaciones ? Observaciones : ' '}} --></p>
       
    </div>
</div>






