import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { FormulariosComponent } from '../formularios/formularios.component'
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { FlexLayoutModule } from "@angular/flex-layout";

const routes = [
    {
        path: 'medicamentos-formularios-instructivos',
        component: FormulariosComponent
    }
];

@NgModule({
    declarations: [
        FormulariosComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatIconModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        TranslateModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatExpansionModule,
        MaterialFileInputModule,
        FlexLayoutModule
    ]
})
export class FormulariosModule { }
