import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDemoModule } from '@fuse/components/demo/demo.module';
import { FuseHighlightModule } from '@fuse/components';

import { NovedadComponent } from '../novedad/novedad.component';



const routes = [    
    {   path     : 'novedad/:id',
        component: NovedadComponent
    }
];

@NgModule({
    declarations: [
      NovedadComponent,
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        FuseDemoModule,
        FuseHighlightModule,
    ],
    exports     : [
      NovedadComponent,
    ]
})

export class NovedadModule{
}
