import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';
import { LoginService } from 'app/services/login.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-solicitar-rol',
  templateUrl: './solicitar-rol.component.html',
  styleUrls: ['./solicitar-rol.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations  
})
export class SolicitarRolComponent implements OnInit {

  seleccionTipoRol= new FormControl();
  RolRedes = new FormControl();

  checkEmpresa= 'empresa';
  opcionSeleccionada= '';

  constructor( private _loginService: LoginService,private router: Router) { }

  ngOnInit(): void {
    this._loginService.getAll().subscribe(resp => {
        if(!resp['isLogged'])
            this.router.navigate(['/login'])
    });
}

  sendForm(){
    
    
    if(this.seleccionTipoRol.value == 'empresa'){
      this.router.navigate(['misdatos/solicitar-rol/solicitar-rol-empresa']);
    }else if (this.seleccionTipoRol.value== 'redPrestadora') {
      this.router.navigate(['misdatos/solicitar-rol/seleccionar-red-prestadora'],
        { queryParams: {RolRedes: this.RolRedes.value}});
    }else if (this.seleccionTipoRol.value== 'proveedor') {
        this.router.navigate(['misdatos/solicitar-rol/solicitar-rol-proveedor']);
    }

    
  }

  linkPanel(){
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  } 

}
