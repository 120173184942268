<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
        <div>
            <img src="assets/images/iconos/icono_discapacidad.svg" class="icon">
        </div>
    </div>

    <div fxFlex="81" fxFlex.xs="100" fxFlex.lt-md="100" fxLayout="column">
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a (click)="linkDiscapacidad()">DISCAPACIDAD</a> »
            <span>FORMULARIOS</span>
        </div>

        <h2 class="page-subtitle">Formularios</h2>

        <p>Seleccioná el formulario a descargar:</p>


        <table *ngIf="rol != 'BENOST' && rol != 'BENOSF'" class="forms">
            <tbody>
                <tr class="table-info">
                    <td>
                        1) RESUMEN HISTORIA CLÍNICA
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/Resumen%20de%20historia%20clínica%20{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        2) ORDEN MÉDICA
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/Orden%20médica%20{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        3) PLAN DE ABORDAJE CON INFORME INICIAL / EVOLUTIVO
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/Plan%20de%20abordaje%20con%20informe%20inicial%20-%20evolutivo{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        4) PLAN PEDAGÓGICO INDIVIDUAL CON INFORME INICIAL / EVOLUTIVO
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/Proyecto%20pedagógico%20individual%20con%20informe%20inicial%20-%20evolutivo{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                          </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        5) PRESUPUESTO DE PRESTACIONES DE REHABILITACIÓN
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/Presupuesto%20de%20prestaciones%20de%20rehabilitación{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        6) PRESUPUESTO DE PRESTACIONES EDUCATIVAS/ TERAPÉUTICO EDUCATIVAS
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/Presupuesto%20de%20prestaciones%20educativas%20-%20terapéutico%20educativas{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        7) PRESUPUESTO DE TRANSPORTE
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/Presupuesto%20de%20transporte{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        8) PRESUPUESTO DE PRESTACIONES ASISTENCIALES
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/Presupuesto%20de%20prestaciones%20asistenciales{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        9) ACTA ACUERDO DE INTEGRACIÓN
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/Acta%20acuerdo%20de%20integración{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        10) ORDEN MÉDICA POR TRANSPORTE
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/Orden%20médica%20por%20transporte{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        11) FORMULARIO FIM
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/Formulario%20FIM{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        12) NOTA DE SOLICITUD CAMBIO DE PRESTADOR (Anexo 3)
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/Nota%20de%20solicitud%20de%20cambio%20de%20prestador%20(Anexo%203){{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        
        <table class="forms" *ngIf="rol == 'BENOST' || rol == 'BENOSF'">
            <tbody>
                <tr class="table-info">
                    <td>
                        1) RESUMEN HISTORIA CLÍNICA
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/1.-%20Resumen%20de%20historia%20Clínica{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        2) ORDEN MÉDICA
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/2.-%20Orden%20médica{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        3) PLAN DE ABORDAJE
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/3.-%20Plan%20de%20abordaje{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        4) PLAN PEDAGÓGICO INDIVIDUAL
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/4.-%20Proyecto%20pedagógico%20individual{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                          </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        5) PRESUPUESTO DE PRESTACIONES DE REHABILITACIÓN
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/5.-%20Presupuesto%20de%20prestaciones%20de%20rehabilitación{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        6) PRESUPUESTO DE PRESTACIONES EDUCATIVAS/ TERAPÉUTICO EDUCATIVAS
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/6.-%20Presupuesto%20de%20prestaciones%20educativas%20-%20Terapéutico%20educaticas{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        7) PRESUPUESTO DE TRANSPORTE
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/7.-%20Presupuesto%20transporte{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        8) PRESUPUESTO DE PRESTACIONES ASISTENCIALES
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/8.-%20Presupuesto%20prestaciones%20asistenciales{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        9) ACTA ACUERDO DE INTEGRACIÓN
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/9.-%20Acta%20acuerdo%20de%20integración{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        10) JUSTIFICACIÓN MÉDICA POR TRANSPORTE
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/10.-%20Justificación%20médica%20por%20transporte{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        11) CRONOGRAMA DE ASISTENCIA
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/11.-%20Cronograma%20de%20asistencia{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        12) INFORME DE EVALUACIÓN INICIAL/ EVOLUTIVO
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/12.-%20Informe%20de%20evaluación%20inicial%20%20evolutivo{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        13) DDJJ FRANQUICIA LEY 19279
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/13.-%20DDJJ%20FRANQUICIA%20LEY%2019279{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        14) FORMULARIO FIM
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/14.- Formulario FIM{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        15) CONFORMIDAD DE LAS PRESTACIONES
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/15.-%20Conformidad%20de%20las%20prestaciones{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
                <tr class="table-info">
                    <td>
                        16) NOTA DE SOLICITUD DE CAMBIO DE PRESTADOR
                    </td>
                    <td>
                        <a href="{{ env.urlDownloads }}formularios/discapacidad/16.-%20Nota%20de%20solicitud%20de%20cambio%20de%20prestador{{ extension }}"
                            target="_blank">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>