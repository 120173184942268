import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CargaComprobantesService {

  constructor(private http: HttpClient) { } 

  getAllPrestaciones(cuit): Observable<any[]>{
    return this.http.get<any[]>(environment.urlApi + 'prestaciones/getPrestaciones?cuit='+ cuit);
  }

  consultarPrestacionesxCUIT(cuitProveedor){
    return this.http.get<any[]>(environment.urlApi + '' + cuitProveedor)
  }
}
