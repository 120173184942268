import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { LoginService } from 'app/services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CargaComprobantesService } from '../../../../../services/carga-comprobantes.service';

@Component({
    selector: 'app-detalle-prestacion',
    templateUrl: './detalle-prestacion.component.html',
    styleUrls: ['./detalle-prestacion.component.scss'],
})
export class DetallePrestacionComponent implements OnInit {

    BenTNomApe = window.history.state.BenTNomApe;
    SABCUIT = window.history.state.cuit;
    DiscaExAnio = window.history.state.DiscaExAnio;
    Prestacion = window.history.state.Prestacion;
    Prestador = window.history.state.Prestador;
    Estado = window.history.state.Estado;
    Observaciones = window.history.state.Observaciones;
    Documentos = window.history.state.Documentos;
    hasDocument: Boolean = false;
    isProveedorSAB: Boolean = false;
    constructor(
        private _loginService: LoginService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private _cargaComprobantes: CargaComprobantesService
    ) {

    }

    ngOnInit(): void {
        let cuit;

        this._loginService.getAll().subscribe((resp) => {
            if (!resp['isLogged']) this.router.navigate(['/login']);

            this._loginService.getSessionData().subscribe((result) => {
                if (!result['rol'] || (result['rol'] != 'PRVGRL' && result['rol'] != 'ASEPRV')) {
                    this.router.navigate(['/login']);
                    
                } else if (result['rol'] == 'ASEPRV') {
                    if (!this.SABCUIT)
                        this.activatedRoute.queryParams.subscribe((params) => {
                            this.SABCUIT = params['cuit'];
                        });

                    cuit = this.SABCUIT;
                    this.isProveedorSAB = true;
                }

                else if (result['rol'] == 'PRVGRL') {
                    cuit = result.cuit;
                }

                if (
                    !this.BenTNomApe ||
                    !this.DiscaExAnio ||
                    !this.Prestacion ||
                    !this.DiscaExAnio ||
                    !this.Prestador
                ) {
                    this.activatedRoute.queryParams.subscribe((params) => {
                        const Index = params['index'];
                        if (Index) {
                            this._cargaComprobantes
                                .getAllPrestaciones(cuit)
                                .subscribe((data) => {
                                    var prestaciones = data;
                                    if (!prestaciones[Index])
                                        this.router.navigate(['/proveedores/comprobantes/carga']);
                                    this.BenTNomApe = prestaciones[Index]['BenTNomApe'];
                                    this.DiscaExAnio = prestaciones[Index]['DiscaExAnio'];
                                    this.Prestacion = prestaciones[Index]['Prestacion'];
                                    this.Prestador = prestaciones[Index]['Prestador'];
                                    this.Estado = prestaciones[Index]['Estado'];
                                    this.Observaciones = prestaciones[Index]['Observaciones'];
                                    this.Documentos = prestaciones[Index]['Documentos'];
                                });
                        }
                    });
                }

                if (
                    this.Documentos != undefined &&
                    this.Documentos != null &&
                    this.Documentos[0]
                )
                    this.hasDocument = true;
                else this.hasDocument = false;
            });
        });
    }

    instructivoFacturacion() {
        window.open(
            'assets/files/instructivos/INSTRUCTIVO_PROVEEDORES-FACTURACIÓN.pdf',
            '_blank'
        );
    }

    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }
    linkCargaComprobante() {
        this.router.navigate(['/proveedores/comprobantes/carga'], {
            queryParams: { cuit: this.SABCUIT },
        });
    }
    linkConsultaPrestaciones() {
        this.router.navigate(['/consulta-prestaciones'], {
            queryParams: { cuit: this.SABCUIT },
        });
    }
}
