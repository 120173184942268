<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
        <div>
            <img src="assets/images/iconos/icono_discapacidad.svg" class="icon">
        </div>
    </div>

    <div fxFlex="81" fxFlex.xs="100" fxFlex.lt-md="100" fxLayout="column">
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a (click)="linkDiscapacidad()">DISCAPACIDAD</a> »
            <span>INSTRUCTIVOS</span>
        </div>

        <h2 class="page-subtitle">Instructivos</h2>

        <p>Seleccioná el instructivo que quieras conocer:</p>

        <table class="forms">
            <thead>
                <tr>
                    <th>
                        Instructivo
                    </th>
                    <th>
                        Descargar
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-info">
                    <td>
                        Instructivos - Prestaciones especiales
                    </td>
                    <td>
                        <a target="_blank"
                            href="{{ env.urlDownloads }}instructivos/discapacidad/ASE_Instructivo%20de%20Discapacidad{{ extension }}">
                            <mat-icon class="icon_download">archive</mat-icon>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>