<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>               
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_altausuarios.svg" class="icon">
        </div>

       

    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a routerLink="/adm-usuarios-proveedor"><span>Adm. Usuarios</span></a> » <span>Seleccionar datos</span>
        </div>        

        <!-- <h2 class="page-subtitle">Administración de Usuarios</h2> -->

        <div fxFlex="50" fxFlex.xs="100" id="panel-one">
            <mat-accordion hideToggle>
                <mat-expansion-panel disabled class="list-item" (click)="goToMisDatosProveedores()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p class="listed-link">Mis datos Proveedor</p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
                <mat-expansion-panel disabled class="list-item" (click)="goToCambiarEmailProveedor()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p class="listed-link">Cambiar email Proveedor</p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>        

            </mat-accordion>
        </div>
        
    </div>
       
</div>

