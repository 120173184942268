import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NacimientoService } from '../../../../services/nacimiento.service';
import { environment } from 'environments/environment';
import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);
import { LoginService } from 'app/services/login.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-nacimiento',
  templateUrl: './nacimiento.component.html',
  styleUrls: ['./nacimiento.component.scss']
})
export class NacimientoComponent implements OnInit {

  sendChild     = false; // envia hijo junto con el formulario
  addChild      = true; // si no hubiese hijo accede a alta familiar. Seteado true por default
  selectChild   = false; //elegir hijo si hubiese
  dataServ      : JSON;
  cuil          : Number;
  cuit          : Number; 
  sessionId     : any;
  familiar      : any;
  sons          = [];
 
  //obtengo la fecha de hoy menos 90 dias hacia atras formateado como viene en el servicio
  ninety        = dayjs().subtract(90, 'day').format('YYYYMMDD');

  formSolicitud : FormGroup;
  observaciones = new FormControl('', );
  //child         = new FormControl('', [Validators.required]);
  arr = [];
  selectSons    = new FormControl(this.arr, );
  formSent : boolean = false;
  

    viewBeneficiarioTemplete()
    {
        this._nacimientoService.sendData(this.cuil,this.sessionId)
        .subscribe(data => {
            this.familiar = data; //Esto tiene los datos del grupo familiar!
        
            if (this.familiar.length > 0) //Validar si tiene grupo familiar
            {
                //Recorrer el array con los datos del grupo familiar

                let fechaNacimiento: any;
                let s:any;
                for(s in this.familiar)
                {
                    //formateo fecha
                    fechaNacimiento= dayjs(this.familiar[s].FchNacimiento).format('YYYYMMDD');
                    // comparo que el hijo no tenga mas de 90 dias de vida       
                    let comparacion= dayjs(this.ninety).isSameOrBefore(fechaNacimiento);
                    
                    if(comparacion && this.familiar[s].Categoria == '2' || this.familiar[s].Categoria == '7' || this.familiar[s].Categoria == '8')           
                        this.sons.push(this.familiar[s].NombreyApellido);          
                }
                
                // si tiene hijos muestro selectChild para que elija, sino addChild para que lo agregue
                if(this.sons.length > 0)
                {
                    this.addChild= false;
                    this.selectChild= true;
                }else
                {
                    this.selectChild= false;
                    this.addChild= true;
                }         
            }
        });
    }
    // Seteo en un array los valores elegidos en el checkbox, y los elimino si se destildan
    onCheckboxChange(e) {
  
       const checkArray = new FormArray([]);
        
        if (e.checked)
        {
            checkArray.push(new FormControl(e.source.value));
            this.arr.push(checkArray.controls[0].value);
        } 
        else 
        {
            let i: number = 0;
            this.arr.forEach((element) => {
            if (element == e.source.value) {
                checkArray.removeAt(i);
                this.arr.shift();
                return;
            }
            i++;
            });
        }
      }

    sendBaby()
    {
        this.selectChild= false;
        this.sendChild= true;
    }

    reload(): void{
    setTimeout(function(){
        window.location.reload();
    },3000);
    }

    sendForm(): void {

    this.formSolicitud.markAllAsTouched();
    if (!this.formSolicitud.valid){
        this._snackBar.open('Recordá que debés completar todos los campos correctamente ', 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        });
        return; 
    } 

    this.formSent = true;
    const formData = new FormData();
    formData.append('observaciones', this.observaciones.value);

    formData.append('sons', this.selectSons.value);
    
    this._nacimientoService.sendForm(formData)
      .subscribe(resp => {
      this.formSolicitud.markAllAsTouched();
        this._snackBar.open('Recibimos tu solicitud. Nos pondremos en contacto vía mail en el plazo de 72hs hábiles.', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.reload();
    },
    err => {
    this.formSent = false;
      this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });    
  }

  constructor(
    private _snackBar: MatSnackBar,    
    private _nacimientoService: NacimientoService,
    private fb: FormBuilder,
    private _loginService: LoginService,
    private router: Router
    
  ){  
    this.formSolicitud= this.fb.group({
    checkArray: this.fb.array([])
  })}


  ngOnInit(): void {
    this._loginService.getAll().subscribe(resp => {
        if(!resp['isLogged'])
            this.router.navigate(['/login'])
    });
    
    this._nacimientoService.getSessionData()
        .subscribe(data =>
        {
            this.dataServ = data; 
            this.sessionId = data.session_id
            this.cuil = data.username;
        this.viewBeneficiarioTemplete();
    });
    this.formSolicitud = new FormGroup({
      observaciones: this.observaciones, 
      sons:this.selectSons,
      
    });
  }

  linkPanel(){
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  }
  linkSolicitudObsequios(){
    window.location.href = environment.urlWebSitePhp + 'obsequios/page?view=index';
  }
  linkAltaFamiliar(){
    window.location.href = environment.urlWebSitePhp + 'abm/gestionesAltaFamiliarB?cuil=' + this.cuil;
  }

}


