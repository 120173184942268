import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdmUsuariosProveedorComponent } from './adm-usuarios-proveedor.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

const routes = [
  {
      path     : 'adm-usuarios-proveedor',
      component: AdmUsuariosProveedorComponent
  }
];

@NgModule({
  declarations: [AdmUsuariosProveedorComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatButtonModule
  ]
})
export class AdmUsuariosProveedorModule { }
