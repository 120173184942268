import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { DetallesPrestacionService } from '../services/detalles-prestacion.service';
import { LoginService } from 'app/services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from "@angular/material/dialog";
import { ModalAltaContableComponent } from '../../modal-alta-contable/modal-alta-contable.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthDialogComponent } from 'app/layout/components/auth-dialog/auth-dialog.component';

const FileSaver = require('file-saver');

@Component({
  selector: 'app-detalles-prestacion',
  templateUrl: './detalles-prestacion.component.html',
  styleUrls: ['./detalles-prestacion.component.scss']
})



export class DetallesPrestacionComponent implements OnInit, AfterViewChecked {

  dataBreadcumbPages: Array<any> = [];
  dataBreadcumbTitle: any;
  estado: string = '';
  pageSize = 3;
  altaContable: boolean= true;
  cuit = '';
  prestacion = '';
  prestador = '';
  sesionesOKM = '';
  fechaDesde = '';
  fechaHasta = '';
  prestacionObjeto: any = {};
  idPrestacion: any = '';
  arrayObservaciones: Array<any> = [];
  loading: boolean = true;
  descargasEnProgreso: { [key: number]: boolean } = {};
  
 

  // ELEMENT_DATA: any [] = [
  //   { fechaCarga: '12/12/2023', nroGestion: 19870079, nombreDocumento: 'Orden médica Terapia'},
  //   { fechaCarga: '12/12/2023', nroGestion: 40098826, nombreDocumento: 'Orden médica Terapia'},
  //   { fechaCarga: '12/12/2023', nroGestion: 69879841, nombreDocumento: 'Orden médica Terapia' },
  //    { fechaCarga: '12/12/2023', nroGestion: 69879841, nombreDocumento: 'Orden médica Terapia' },
  //    { fechaCarga: '12/12/2023', nroGestion: 69879841, nombreDocumento: 'Orden médica Terapia' },
  //    { fechaCarga: '12/12/2023', nroGestion: 69879841, nombreDocumento: 'Orden médica Terapia' }
  // ];

  arrayArchivos: any [] = [];

  displayedColumns: string[] = [ 'icono', 'fechaCarga', 'nroGestion', 'nombreDocumento', 'descargar'];
  //dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  dataSource: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _detallePrestacionService: DetallesPrestacionService, private loginService: LoginService,
    private activatedRoute: ActivatedRoute, private dialog: MatDialog, private router: Router,
    private _snackBar: MatSnackBar) { 
    this.dataBreadcumbPages = [{ page: 'Detalle de la prestación', route: '' }];
    this.dataBreadcumbTitle = [{ page: 'Discapacidad', route: '/discapacidad' }, { page: 'Mis Prestaciones', route: '/discapacidad/prestaciones' }];
  }

  ngAfterViewChecked() {
    // Verificar si la variable loading ha cambiado y si el paginator esta disponible.
    if (!this.dataSource.paginator && !this.loading && this.paginator) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.paginator.pageSize = this.pageSize;
    }
  }
  

  ngOnInit(): void {
    
    this.activatedRoute.queryParams.subscribe((params) => {
      this.idPrestacion = params["id"];

      // if (!this.idPrestacion) {
      //   this.router.navigate(["/discapacidad"]);
      // }
      this.getDetallePrestacion();
    });

      
  }

  getDetallePrestacion() {
    

    let jsonData = {
      'prestacionId': this.idPrestacion
    }
    this.loginService.getSessionData().subscribe(result => {
      if (result['session_id'] == null){
        this.dialog
        .open(AuthDialogComponent, {
          disableClose: true,
          panelClass: "custom-modalbox"
        })
        .afterClosed()
        .subscribe(() => (window.location.href = '/index.php/site/logout'));
      }
      let sessionId = result['session_id'];
      jsonData['sessionId'] = sessionId;

      this._detallePrestacionService.getDetallePrestacion(jsonData).subscribe(
        response => {
          console.log('Datos recibidos getDetallePrestacion:', response);
          this.prestacionObjeto = response;
          this.cuit = this.prestacionObjeto?.cuit;
          this.estado = this.prestacionObjeto.estadosgi;
          this.arrayArchivos = this.prestacionObjeto.files;
          this.arrayObservaciones = this.prestacionObjeto?.observaciones;
          // Copia el array original para evitar modificaciones no deseadas
          let arrayCompleto = this.arrayArchivos.slice();
          arrayCompleto = arrayCompleto.concat(this.prestacionObjeto?.filesPrestador);
          this.arrayArchivos = arrayCompleto;
          this.getPrestadorActivo();
          this.loading = false;

          let sortArray = this.arrayArchivos.sort((a, b) => b.idprestacionenviada - a.idprestacionenviada );
          this.dataSource = new MatTableDataSource<any>(sortArray);
        },
        error => {
          console.error('Error en getDetallePrestacion:', error);
        }
      );
    });
  }

  getPrestadorActivo() {

    let jsonData = {
      'cuit': this.cuit
    }
    this.loginService.getSessionData().subscribe(result => {
      let sessionId = result['session_id'];
      jsonData['sessionId'] = sessionId;

      this._detallePrestacionService.getPrestadorActivo(jsonData).subscribe(
        response => {
          console.log('Datos recibidos:', response);
          if (response?.Prvid != "") {
            this.altaContable = true;
          } else {
            this.altaContable = false;
          }
        },
        error => {
          console.error('Error al obtener datos:', error);
        }
      );
    });

  }

  convertirFecha(fechaOriginal: string): string {
    const fecha = new Date(fechaOriginal);
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1; // Se suma 1 porque los meses empiezan desde 0
    const anio = fecha.getFullYear();

    const diaStr = dia < 10 ? `0${dia}` : `${dia}`;
    const mesStr = mes < 10 ? `0${mes}` : `${mes}`;

    return `${diaStr}/${mesStr}/${anio}`;
  }

  descargarArchivo(idArchivo: any, prestadorEspecialidadId: any){
    //console.log('DESCARGAR ARCHIVO CON ID', idArchivo);
    this.descargasEnProgreso[idArchivo] = true;
    //En caso de que sea un archivo de prestador se le da valor 1 a prestadorFlag, sino 0
    const prestadorFlag = prestadorEspecialidadId !== undefined ? 1 : 0;
    let jsonData = {
      'id': idArchivo,
      'prestadorFlag': prestadorFlag
    }
    this.loginService.getSessionData().subscribe(result => {
      
      let sessionId = result['session_id'];
      jsonData['sessionId'] = sessionId;

      this._detallePrestacionService.descargarArchivo(jsonData).subscribe(
        response => {
          const stringNombreArchivo = response?.title || 'archivoAdjunto';
          const blob = new Blob([new Uint8Array(response.file.data).buffer], { type: response.mime });
          FileSaver.saveAs(blob, stringNombreArchivo);
          this.descargasEnProgreso[idArchivo] = false;
        },
        error => {
          console.error('Error al obtener datos de descarga:', error);
        }
      );
    });

  }

  openModalAltaContable() {
      // open modal ModalAltaContableComponent
      const dialogRef =  this.dialog.open(ModalAltaContableComponent, {
        width: "1089px",
        height: "auto",
        panelClass: "custom-modalbox",
        position: {
          top: '102px',
        }
      });
      dialogRef.componentInstance.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.dialog.closeAll();
  }




  
  

}
