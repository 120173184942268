import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "auth-dialog",
  templateUrl: "auth-dialog.component.html",
  styleUrls: ["auth-dialog.component.scss"],
})
export class AuthDialogComponent {
  constructor(public dialogRef: MatDialogRef<AuthDialogComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
