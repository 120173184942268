import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OlvidoPasswordService {

  constructor(private http: HttpClient) { }  

  sendUser(cuit): Observable<any>{
    return this.http.get(`${environment.urlApi}olvidoPassword/reset-password?usuario=${cuit}`);
  }
}
