import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Rol } from 'app/models/rol.model';
import { Observable } from 'rxjs';
import { RolesService } from 'app/services/roles.service';
import { LoginService } from 'app/services/login.service';
import { environment } from 'environments/environment';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-rolSelect',
    templateUrl: './rolSelect.component.html',
    styleUrls: ['./rolSelect.component.scss']
})

export class RolSelectComponent implements OnInit {

    env = environment;
    listRoles = [];
    formRoles: FormGroup;
    isLogged: boolean;
    rolSelected: number;
    asebien = false;
    urlRoleSelect = environment.urlRoleSelect;

    constructor(private _rolesService: RolesService,
        private _loginService: LoginService,
        private _formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,) {

    }

    ngOnInit(): void {

        this.formRoles = this._formBuilder.group({
            rol: new FormControl('', [Validators.required])
        });

        this._loginService.getAll().subscribe(resp => {
            this.isLogged = resp['isLogged'];
            //validar si esta logueado // si esta loguado ejecutar getSeesionData() //obetner los datos del menu privado y guardarlo //guardar la infomacion en un json del sessiondata//si esta logueado, y la url del win.loc no está en mi array de url publica, this.urlpublic = falseEventName//si esta loguedo y no es publica la pagina mostrar el nuevo menu 
            if (this.isLogged) {
                this._loginService.getSessionData().subscribe(resp => {
                    let cuil = resp.username;
                    this._rolesService.getAll(cuil)
                        .subscribe(data => {
                            this.listRoles = data;
                            this.activatedRoute.queryParams.subscribe((params) => {
                                if (params["asebien"] == true)
                                    this.urlRoleSelect = this.urlRoleSelect + '?asebien=1';
                            });
                        },
                            err => console.log(err)
                        );
                });
            }
        });
    }


    onSubmit(event) {

    }

}
