import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { environment } from 'environments/environment';
import { FormControl, FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { CambiarContraseniaService } from '../../../../services/cambiar-contrasenia.service';
import { LoginService } from 'app/services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { passwordsValidator } from 'app/shared/passwords-validator.directive';
import { MisdatosService } from 'app/services/misdatos.service';

@Component({
    selector: 'app-cambiar-contrasenia',
    templateUrl: './cambiar-contrasenia.component.html',
    styleUrls: ['./cambiar-contrasenia.component.scss']
})
export class CambiarContraseniaComponent implements OnInit {
  formPW: FormGroup;  
  mjse= ['Su contraseña ha sido modificada con éxito.'];
  cuil: any;
  env = environment;
  succesMsje :any;
  errorMsje : any;
  isLogged : boolean;
  rol: string;
  contrasenia: string;
  sessionId: string;
  cuilSession: string;
  contraseniaAnteriorValida: boolean = false;
  showPasswordOld = false;
  showPasswordNew = false;
  showPasswordRepeat = false;

    constructor(private _cambiarContrasenia: CambiarContraseniaService,
        private router: Router,
        private fb: FormBuilder,
        private _snackBar: MatSnackBar,
        private _loginService: LoginService,
        private _misdatosService: MisdatosService,
        private route: ActivatedRoute) {
        this.route.queryParams.subscribe(params => {
            this.succesMsje = params['status'];
            this.errorMsje = params['error'];
        });
    }


    ngOnInit(): void {

        this._loginService.getAll().subscribe(resp => {
            this.isLogged = resp['isLogged'];
            if (this.isLogged) {
                this._loginService.getSessionData().subscribe(resp => {
                    this.rol = resp['rol'];
                    this.sessionId = resp['session_id'];
                    this.cuilSession = resp['username'];
                });
            }
            else
                this.router.navigate(['/home'])
            if (this.errorMsje != undefined && this.errorMsje != '') {
                this._snackBar.open('Error: ' + this.errorMsje, 'Cerrar', {
                    duration: undefined,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                });
            }
        });

        this.formPW = this.fb.group({
            cuil: new FormControl(''),
            contraseniaAnterior: new FormControl('', Validators.required),
            contrasenia: new FormControl('', [Validators.required, Validators.minLength(6)]),
            confirmarContrasenia: new FormControl('', [Validators.required, Validators.minLength(6)])
        });
        if (this.succesMsje === 'ok') {
            this._snackBar.open(this.mjse[0], 'Cerrar', {
                duration: undefined,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
        }
        // else if (this.errorMsje){
        //     this._snackBar.open(this.errorMsje, 'Cerrar', {
        //     duration: undefined,
        //     horizontalPosition: 'center',
        //     verticalPosition: 'top',
        //     });
        // }
        // return;   
    }

    onPasswordBlur(){
        const formData = new FormData();
        formData.append('cuil',this.cuilSession);
        formData.append('contrasenia',this.formPW.get('contraseniaAnterior').value);
        formData.append('sessionId',this.sessionId);

        this._misdatosService.sendPasswordOld(formData).subscribe(resp => {
            console.log(resp);
            this.contraseniaAnteriorValida = true;
        },
            err => {
                this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
                    duration: undefined,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                });
                this.contraseniaAnteriorValida = false;
            });
    } 

    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }
    //valido si ambas contraseñas son iguales
    chequearSiSonIguales(): boolean {
        return this.formPW.hasError('noSonIguales') &&
            this.formPW.get('contrasenia').dirty &&
            this.formPW.get('confirmarContrasenia').dirty;
    }

    linkDdatos() {
        if (this.rol != 'PRVGRL')
            this.router.navigate(['misdatos']);
        else
            this.router.navigate(['proveedores/mis-datos']);
    }

    onSubmit(event): boolean {
        this.formPW.markAllAsTouched();
        if (!this.formPW.valid) {
            this._snackBar.open('Recordá que debes completar todos los campos correctamente ', 'Cerrar', {
                duration: undefined,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });

            return false;
        } else {
            return true;
        }
  }

  togglePasswordVisibility(show) {
    switch(show){
        case('old'):
        this.showPasswordOld = !this.showPasswordOld;
        break;

        case('new'):
        this.showPasswordNew = !this.showPasswordNew;
        break;

        case('repeat'):
        this.showPasswordRepeat = !this.showPasswordRepeat;
        break;
    };
}
}

