import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import * as shape from 'd3-shape';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Contacto } from 'app/models/contacto.model';
import { ContactoService } from 'app/services/contacto.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cuitValidator } from 'app/shared/cuit-validator.directive';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ejecutivocuenta',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ContactoComponent implements OnInit {
  
  showCUIT = false;

  formContacto: FormGroup;
  contacto: Contacto = {} as Contacto;
  nombre = new FormControl('', [Validators.required]);
  apellido = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  asunto = new FormControl('', [Validators.required]);
  codigoarea = new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]);
  telefono = new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]);
  mensaje = new FormControl('', [Validators.required]);
  CUIT = new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(11), cuitValidator()]);
  captchares: boolean = false;
  
  captchaResolved(captchaResponse: string){
    this.captchares = true;
    this.contacto.captcharesponse = captchaResponse; //Agregamos el response aca para ser validado en node y no recibir cualquier solicitud de contacto
  }

  changeAsunto(asunto){
    if(asunto.value == 'otras'){
      this.showCUIT = false;
      this.CUIT.clearValidators();
      this.CUIT.updateValueAndValidity();
    }
    else if(asunto.value == 'monotributista'){
      this.showCUIT = true;
      this.CUIT.setValidators([Validators.required,Validators.pattern('^[0-9]*$'),Validators.minLength(11), cuitValidator()]);
      this.CUIT.updateValueAndValidity();
    }
  }

  reload(): void{
    setTimeout(function(){
      window.location.reload();
    },3000);
  }
  
  sendForm(): void {

    this.formContacto.markAllAsTouched();
    if (!this.formContacto.valid){
      return;
    }

    this.contacto.id = '';
    this.contacto.nombre = this.nombre.value;
    this.contacto.apellido = this.apellido.value;
    this.contacto.email = this.email.value;
    this.contacto.asunto = this.asunto.value;
    this.contacto.codigoarea = this.codigoarea.value;
    this.contacto.telefono = this.telefono.value;
    this.contacto.mensaje = this.mensaje.value;

    if(this.showCUIT && this.CUIT.value && this.asunto.value == 'monotributista')
      this.contacto.CUIT = this.CUIT.value;

    this._contactoService.sendForm(this.contacto)
    .subscribe(resp => {
      if(this.asunto.value == 'monotributista'){

        this._snackBar.open('Tu número de gestión es '+ resp.PSPRE_INCIDENTE, 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else{
        this._snackBar.open('El formulario se ha enviado con exito.', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
      
      this.showCUIT = false;
      this.formContacto.reset();
      this.reload();
    },
    err => {
      this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });    
  }

  constructor(private _snackBar: MatSnackBar,
              private _contactoService: ContactoService,
              private meta: Meta,
              private router: Router) { 
                const description = this.meta.getTag('name=description');
                this.meta.removeTagElement(description);
                const keywords = this.meta.getTag('name=keywords');
                this.meta.removeTagElement(keywords);
              }

  ngOnInit(): void {
    this.router.navigate(['/home'])
    this.formContacto = new FormGroup({
      nombre: this.nombre,
      apellido: this.apellido,
      email: this.email,
      asunto: this.asunto,
      codigoarea: this.codigoarea,
      telefono: this.telefono,
      mensaje: this.mensaje,
      CUIT: this.CUIT
    });
  }


}
