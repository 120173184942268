import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'app/services/login.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-discapacidad',
  templateUrl: './discapacidad.component.html',
  styleUrls: ['./discapacidad.component.scss']
})
export class DiscapacidadComponent implements OnInit {

  constructor(
    private _loginService: LoginService,
    private router: Router) { }

  ngOnInit(): void {
    this._loginService.getAll().subscribe(resp => {
      if(!resp['isLogged'])
          this.router.navigate(['/login'])
  });
  }

  linkPanel(){   
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  }

}
