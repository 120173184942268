import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() breadcrumbs: Array<any> = [];
  @Input() title: Array<any> = [];
  @Input() description: Array<any> = [];
  @Input() isDashboard: boolean = true;

  constructor() {
    console.log(this.title);
  }

  linkPanel() {    
    if (this.isDashboard) {
      return window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }

    return window.location.href = '/';
  } 

  hasDescription(): boolean {
    return !this.description; 
  }
}
