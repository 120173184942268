<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm fxHide>
               
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_altausuarios.svg" class="icon">
        </div>         

    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a routerLink="/misdatos">Mis datos</a> » <span>Solicitar Rol</span>
        </div>

        <h2 class="page-subtitle">Solicitud de Rol</h2>
       
        <p>Seleccione si solicita un rol para Empresa, Red Prestadora o Proveedor.</p>

       
            <div fxLayout="row">
                <mat-radio-group [formControl]="seleccionTipoRol"  [(ngModel)]="checkEmpresa">
                    <mat-radio-button [checked]="true" value="empresa">Empresa</mat-radio-button>
                    <mat-radio-button style="margin-left: 24px;" value="redPrestadora">Red Prestadora</mat-radio-button>
                    <mat-radio-button style="margin-left: 24px;" value="proveedor">Proveedor</mat-radio-button>
                </mat-radio-group>
                </div>
                <div  fxLayout="row" *ngIf="checkEmpresa === 'redPrestadora'">
                    <mat-form-field style="width: 250px;">
                        <mat-label>Seleccione Rol</mat-label>
                        <mat-select required [formControl]="RolRedes">
                            <mat-option value="2">Liquidación</mat-option>
                            <mat-option value="1">Informes</mat-option>
                            <mat-option value="3">Liquidación e Informes</mat-option>
                            <mat-option value="4">Aportes Derivados</mat-option>
                        </mat-select>
                        <mat-error *ngIf="RolRedes.invalid ">Debe seleccionar un rol.</mat-error>                        
                    </mat-form-field>
                </div>
            <div fxLayout="row">
                <button mat-raised-button id="btnContinuar" (click)="sendForm()" [disabled]="!RolRedes.valid">Continuar</button>
            </div>
       
       <!-- valido: {{ formNR.valid | json }} -->
    </div>
</div>

