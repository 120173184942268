<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm fxHide>
               
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_altausuarios.svg" class="icon">
        </div>         

    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a (click)="linkDdatos()">Mis datos</a> » <span>Cambio de contraseña</span>
        </div>

        <h2 class="page-subtitle">Cambio de contraseña</h2>
       
        <p>Por favor, completar los siguientes datos para generar una nueva contraseña.</p>

        <form [formGroup]="formPW" #form name="AuthLoginForm" action="{{ env.urlChangePassword }}" method="POST" novalidate>
            <div fxLayout="row">
                <mat-form-field class="mat-fullwidth" fxFlex.xs="100%" fxFlex="50%">
                    <mat-label>Contraseña anterior</mat-label>
                    <input  name="AuthLoginForm[contraseña]" type="{{ showPasswordOld ? 'text' : 'password' }}" matInput formControlName="contraseniaAnterior" (blur)="onPasswordBlur()" required>
                    <button mat-icon-button matSuffix (click)="togglePasswordVisibility('old')">
                        <mat-icon>{{ showPasswordOld ? 'visibility_off' : 'visibility' }}</mat-icon>
                        <mat-icon *ngIf="contraseniaAnteriorValida" style="color: green;">done</mat-icon>
                      </button>
                    <mat-error *ngIf="formPW.get('contraseniaAnterior').invalid">Debe completar Contraseña anterior.</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="mat-fullwidth" fxFlex.xs="100%" fxFlex="50%">
                    <mat-label>Contraseña</mat-label>
                    <input name="AuthLoginForm[nueva_contraseña]" type="{{ showPasswordNew ? 'text' : 'password' }}" matInput formControlName="contrasenia" required>
                    <button mat-icon-button matSuffix (click)="togglePasswordVisibility('new')">
                        <mat-icon>{{ showPasswordNew ? 'visibility_off' : 'visibility' }}</mat-icon>
                      </button>
                    <mat-error *ngIf="formPW.get('contrasenia').invalid">Debe completar Contraseña.</mat-error>
                    <mat-error *ngIf="formPW.get('confirmarContrasenia').hasError('minlength')">La contraseña debe tener al menos 6 caracteres.</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="mat-fullwidth" fxFlex.xs="100%" fxFlex="50%">
                    <mat-label>Confirmar contraseña</mat-label>
                    <input name="AuthLoginForm[confirmacion_nueva_contraseña]" type="{{ showPasswordRepeat ? 'text' : 'password' }}" matInput formControlName="confirmarContrasenia" required>
                    <button mat-icon-button matSuffix (click)="togglePasswordVisibility('repeat')">
                        <mat-icon>{{ showPasswordRepeat ? 'visibility_off' : 'visibility' }}</mat-icon>
                      </button>
                    <mat-error *ngIf="formPW.get('confirmarContrasenia').invalid">Debe completar Confirmar contraseña.</mat-error>
                    <mat-error *ngIf="formPW.get('confirmarContrasenia').hasError('minlength')">La contraseña debe tener al menos 6 caracteres.</mat-error>
                    <mat-error *ngIf="chequearSiSonIguales()">Las contraseñas no coinciden</mat-error>
                </mat-form-field>                
            </div>
        
            <div fxLayout="row">
                <button mat-raised-button id="btnCambiar" (click)="onSubmit($event) ? form.submit() : false">Cambiar</button>
            </div>
        </form>
       
    </div>
</div>


