export const medifeUserData = [
    {
        efeNroAse: 393,
        efeCuit:30682737650,
        efeRazSoc: "MEDIFE ASOC.CIVIL (DIV.INTERIOR - OSEN)",
    },
    {
        efeNroAse: 395,
        efeCuit:30682737650,
        efeRazSoc: "MEDIFE ASOC.CIVIL (DIV.METRO - OSEN)",
    },
    {
        efeNroAse: 800,
        efeCuit:30682737650,
        efeRazSoc: "MEDIFE ASOCIACION CIVIL - PLANES",
    },
    {
        efeNroAse: 801,
        efeCuit:30682737650,
        efeRazSoc: "MEDIFE ASOCIACIO CIVIL",
    },
    {
        efeNroAse: 803,
        efeCuit:30682737650,
        efeRazSoc: "MEDIFE ASOC.CIVIL - MTB",
    },
    {
        efeNroAse: 356,
        efeCuit:30682737650,
        efeRazSoc: "MEDIFE ASOCIACION CIVIL (DIV.INTERIOR)",
    },
    {
        efeNroAse: 418,
        efeCuit:30682737650,
        efeRazSoc: "MEDIFE ASOCIACION CIVIL (DIV.MEDICOS)",
    },
    {
        efeNroAse: 456,
        efeCuit:30682737650,
        efeRazSoc: "MEDIFE INT.- PARRA CARLOS ADRIAN",
    },
    {
        efeNroAse: 991,
        efeCuit:30682737650,
        efeRazSoc: "MEDIFE BRONCE EX SANCOR PM",
    },
    {
        efeNroAse: 464,
        efeCuit:30682737650,
        efeRazSoc: "MEDIFE INT. - OPENIX SRL",
    },
    {
        efeNroAse: 992,
        efeCuit:30682737650,
        efeRazSoc: "MEDIFE BRONCE EX SANCOR",
    },
    {
        efeNroAse: 486,
        efeCuit:30682737650,
        efeRazSoc: "MEDIFE INT.-EXCEPCION APAROS JUDICIALES",
    },
    {
        efeNroAse: 451,
        efeCuit:30682737650,
        efeRazSoc: "MEDIFE AC - COOP-CREANDO CONCIENCIA",
    },
    {
        efeNroAse: 422,
        efeCuit:30682737650,
        efeRazSoc: "MEDIFE ASOC.CIVIL (DIV.INT.PROG.MEDICOS)",
    },
    {
        efeNroAse: 432,
        efeCuit:30682737650,
        efeRazSoc: "RETAIL - MEDIFE INTERIOR",
    },
    {
        efeNroAse: 433,
        efeCuit:30682737650,
        efeRazSoc: "RETAIL - MEDIFE METROPOLITANA",
    }
  ]