import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActualizarMisDatosComponent } from './actualizar-mis-datos.component';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

//modulos angular material
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

// modulos del template
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseHighlightModule } from '@fuse/components';

const routes = [
  {
      path     : 'proveedores/mis-datos/actualizar-datos',
      component: ActualizarMisDatosComponent
  }
];

@NgModule({
  declarations: [
    ActualizarMisDatosComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FuseSharedModule,
    FuseHighlightModule,
    FlexLayoutModule

  ]
  
})
export class ActualizarMisDatosModule { }
