import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild } from "@angular/core";
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
import { LoginService } from "app/services/login.service";
import { environment } from "environments/environment";
import { MisdatosService } from "app/services/misdatos.service";
import { MatDialog } from "@angular/material/dialog";
import { ModalDiscapacidadUserData } from "app/models/modal-documentacion-basica-data.model";
import { DocumentacionService } from "app/services/documentacion.service";
import { ModalDocumentacionBasicaComponent } from "../../modal-documentacion-basica/modal-documentacion-basica.component";
import { GetDocumentacionBasicaResponse } from "app/services/interfaces/documentacion-service/get-documentacion-basica.interface";
import { Subscription } from "rxjs";
import { GetMisDatosResponse } from "app/services/interfaces/mis-datos-service/get-mis-datos.interface";
import { GetPrestacionesRequierenAtencionResponse } from "app/services/interfaces/prestaciones-requieren-atencion/get-prestaciones-atencion.interface";
import { ModalInformacionDocumentacionBasicaComponent } from "../../modal-informacion-documentacion-basica/modal-informacion-documentacion-basica.component";
import { ModalUserInformationComponent } from "../../modal-user-information/modal-user-information.component";
import { FormPrestacionService } from "../services/form-prestacion.service";
import { retry, delay } from "rxjs/operators";
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrestacionesService } from "../services/prestaciones.service";
import { AuthDialogComponent } from "app/layout/components/auth-dialog/auth-dialog.component";

@Component({
  selector: "app-nueva-prestacion",
  templateUrl: "./nueva-prestacion.component.html",
  styleUrls: ["./nueva-prestacion.component.scss"],
})
export class NuevaPrestacionComponent implements OnInit {
  prestacionesQueRequierenAtencion: Array<GetPrestacionesRequierenAtencionResponse>;
  numeroDePrestaciones = 0;
  prestacionesCompletadas: Array<any> = [];
  userData: GetMisDatosResponse;
  documentationBasica: GetDocumentacionBasicaResponse[];
  modalData: ModalDiscapacidadUserData = {
    user_data: {
      user_id: 0,
      sessionId: "",
      cuil: "",
      email: "",
      phone_number: "",
      area_code: "",
      address: "",
      number: "",
      floor: "",
      department: "",
    },
    documentation: [],
  };
  misDatosServiceSuscription: Subscription;
  getSessionDataGetAllSuscription: Subscription;
  getSessionDataGetSessionDataSuscription: Subscription;
  documentacionServiceGetDocumentacionBasica: Subscription;
  getPrestacionesRequierenAtencion: Subscription;

  data: any;
  dataBreadcumbPages: Array<any> = [];
  dataBreadcumbTitle: any;
  cuil: string;
  orden: string;
  nroBenef: string;
  numberDocsUp: number = 0;
  numberDocsRequiredUp: number = 0;
  totalDocuments: number = 0;
  numberDocsRequired: number = 0;
  isLoading: boolean = true;
  datosNomenclador: Array<any> = [];
  isLoadingDatosNomenclador: boolean = true;
  destacarPrestacionCreada: boolean = false;
  idPrestacionCreada: number;
  envioPrestacion: boolean = false;
  documentacionGuardado: boolean;
  isNewPrestacion:boolean;
  isLoadingPrestaciones:boolean = true;

  constructor(
    private misDatosService: MisdatosService,
    private getSessionData: LoginService,
    private documentacionService: DocumentacionService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private prestacionesService: PrestacionesService,
    private _formPrestacionService: FormPrestacionService,
    private _snackBar: MatSnackBar
  ) {
    this.dataBreadcumbPages = [{ page: 'Formulario De Solicitud', route: '' }];
    this.dataBreadcumbTitle = [{ page: 'Discapacidad', route: '/discapacidad' }, { page: 'Mis Prestaciones', route: '/discapacidad/prestaciones' }];
  }

  ngOnInit(): void {
    this.userData = {
      id: '',
      cuit: '',
      cuil: '',
      nombre_usuario: '',
      rol: '',
      nombre: '',
      apellido: '',
      documento_tipo: '',
      documento_numero: '',
      email: '',
      cargo: '',
      telefono: '',
      calle: '',
      numero: '',
      piso: '',
      cod_postal: '',
      localidad: '',
      provincia: '',
      observaciones: '',
      estado: '',
      ultimo_login: '',
      razon_social: '',
      verifyCode: '',
      codigo_area: '',
    };

    this.activatedRoute.queryParams.subscribe((params) => {
      this.cuil = params["cuil"];
      this.orden = params["orden"];
      this.nroBenef = params["nroBenef"];
      this.isNewPrestacion = params["prestacion"] == 1 ? true : false;

      if (!this.cuil || !this.orden || !this.nroBenef) {
        this.router.navigate(["/discapacidad"]);
      }
    });

    this.getSessionDataGetAllSuscription = this.getSessionData
      .getAll()
      .subscribe((resp) => {
        if (!resp["isLogged"]) this.router.navigate(["/home"]);
      });

    this.getSessionDataGetSessionDataSuscription = this.getSessionData
      .getSessionData()
      .subscribe((result) => {
        if (result.session_id == null){
            this.dialog
            .open(AuthDialogComponent, {
              disableClose: true,
              panelClass: "custom-modalbox"
            })
            .afterClosed()
            .subscribe(() => (window.location.href = '/index.php/site/logout'));
        }
        environment.sessionId = result.session_id;
        environment.cuit = result.cuit;
        environment.cuil = result.username;
        environment.userId = result.id;

        this.misDatosServiceSuscription = this.misDatosService
          .getMisDatos()
          .subscribe((result) => {
            this.userData = result;

            this.loadDocumentacionBasica();
            /* setTimeout(() => {
              this.isLoading = false;
            }, 1000); */
          });

        this._formPrestacionService.getANomencladorGrupo({
          'sessionId': environment.sessionId
        }).pipe(
          retry(3), // you retry 3 times     
          delay(1000) // each retry will start after 1 second,  
        ).subscribe(
          response => {
            this.datosNomenclador = response
            this.isLoadingDatosNomenclador = false;
            setTimeout(()=>{
              this.onScrollChanged();
            }, 20)
          },
          error => {
            console.log(error);
            this.isLoadingDatosNomenclador = false;
          }
        );


        this.getPrestacionesRequierenAtencion = this.prestacionesService
          .getAllPrestaciones(environment.sessionId, this.cuil, this.nroBenef, this.orden)
          .subscribe((result) => {
            if (result.length > 0) {
              this.prestacionesQueRequierenAtencion = result;
              this.numeroDePrestaciones = this.prestacionesQueRequierenAtencion.length;
              this.filtrarPrestacionesCompletadas(this.prestacionesQueRequierenAtencion);
              setTimeout(()=>{
                this.onScrollChanged();
              }, 20)
            }

            this.isLoadingPrestaciones = false;
          });
      });
  }


  loadDocumentacionBasica(){
    this.isLoading = true;
    return this.documentacionServiceGetDocumentacionBasica =
              this.documentacionService
                .getDocumentacionBasica(environment.sessionId, this.cuil, this.orden, this.nroBenef)
                .subscribe((result) => {
                  this.documentationBasica = result;
                  this.numberDocsRequired = result.length;
                  this.modalData = {
                    user_data: {
                      user_id: environment.userId || "",
                      sessionId: environment.sessionId || "",
                      cuil: this.userData.cuil || "",
                      email: this.userData.email || "",
                      phone_number: this.userData.telefono || "",
                      area_code: this.userData.codigo_area || "",
                      address: this.userData.calle || "",
                      number: this.userData.numero || "",
                      floor: this.userData.piso || "",
                      department: "",
                    },
                    documentation: result.map((doc) => {
                      return {
                        id: doc.id_doc,
                        documentation_id: doc.HPBEnfermedadTipoDocId,
                        document_required: doc.HPBEnfermedadDocumentoRequerido == '1' ? true : false,
                        document_title: doc.DocumentoDescripcion,
                        document_name: doc.fileName || "",
                        document_path: "",
                        document_file_type: doc.mime || "",
                        document_observacion: doc.observacion || false,
                        document_observada: doc.observada || false,
                        document_puedeCargarDocumento: doc?.puedeCargarDocumento || false,
                        document_enEvaluacion: doc?.enEvaluacion || false,
                        document_HPBEnfermedadDocumentoVinculado: doc?.HPBEnfermedadDocumentoVinculado || '0',
                        document_idprestacionenviada: doc?.idprestacionenviada

                      }
                    }).sort((a, b) => Number(b.document_required) - Number(a.document_required)),
                    cuilDoc: this.cuil,
                    nroOrden: this.orden,
                    nroBeneficiario: this.nroBenef,
                  };

                  let required = result.filter((doc) => doc.HPBEnfermedadDocumentoRequerido == '1');
                  let loaded = result.filter((doc) => (doc.HPBEnfermedadDocumentoRequerido == '1' &&  ((doc.puedeCargarDocumento && doc.uploaded == true) || doc.puedeCargarDocumento == false)));
                  let uploaded = result.filter((doc) => (doc.uploaded == true || doc.enEvaluacion == true || (doc.HPBEnfermedadDocumentoVinculado == "1" && doc.puedeCargarDocumento == false) ));

                  this.numberDocsRequired = required.length;
                  this.numberDocsUp = uploaded.length;     
                  this.numberDocsRequiredUp = loaded.length;     
                  this.totalDocuments = result.length;             
                  
                  this.isLoading = false;
                },
                (error) => {
                  this._snackBar.open('Ha ocurrido un error', 'Aceptar', {
                    duration: undefined,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                  });
                  this.isLoading = false;
                }
                );

  }

  getDocumentacionCargadaByDocumentacionId(id: number): GetDocumentacionBasicaResponse {
    const doc = this.documentationBasica.find(
      (doc) => doc.HPBEnfermedadTipoDocId === id
    );
    return doc;
  }


  getFileNameById(id: number): string {
    const doc = this.documentationBasica.find(
      (doc) => doc.HPBEnfermedadTipoDocId === id
    );
    const fileName = doc.fileName
    return fileName || "";
  }

  getDocumentacionBasicaTitleById(id: number): string {
    const doc = this.documentationBasica.find(
      (doc) => doc.HPBEnfermedadTipoDocId === id
    );
    return doc.DocumentoDescripcion || "";
  }

  documentacionIsLoaded(id: number): boolean {
    if (!this.documentationBasica) return false;
    const doc = this.documentationBasica.find(
      (doc) => doc.HPBEnfermedadTipoDocId === id
    );
    return !!doc;
  }

  private filtrarPrestacionesCompletadas(arrayPrestacionesCargadas: any) {
    this.prestacionesCompletadas = arrayPrestacionesCargadas.filter(prestacion => prestacion.estado == 'Complete');
  }

  getCardAdvice(estado: any, observaciones?: any): object {
    let mensajeObservacion: string;
    if (observaciones) mensajeObservacion = observaciones[0]?.observacion || ''
    switch (estado) {
      case 'Complete':
        return { codigo: 1, mensaje: 'Cambios guardados exitosamente. Lista para enviar.' };
      case 'Pending':
        return { codigo: 2, mensaje: 'Deberás finalizarla para solicitar la prestación' };
      case 'Observed':
        return { codigo: 2, mensaje: mensajeObservacion };
      /* case 'Pre Approved Observed':
        return { codigo: 2, mensaje: mensajeObservacion }; */
      default:
        return { codigo: 2, mensaje: '' };
    }

  }

  openModal() {
    const dialogRef = this.dialog.open(ModalInformacionDocumentacionBasicaComponent, {
      width: "950px",
      height: "auto",
      panelClass: "custom-modalbox",
    });

    dialogRef.componentInstance.closeModal = this.closeModalInfo.bind(this);
  }

  closeModalInfo() {
    this.dialog.closeAll();
  }

  openModalBasicDoc() {
    console.log(this.modalData);
      const dialogRef = this.dialog.open(ModalDocumentacionBasicaComponent, {
      width: "950px",
      height: "auto",
      data: this.modalData,
      panelClass: "custom-modalbox",
    })

    dialogRef.componentInstance.closeModal = this.closeModal.bind(this);
    // dialogRef.afterClosed().subscribe((returnValue) => {
    //   this.documentacionGuardado = returnValue;
    // });
  }

  closeModal() {
    this.isLoading = true;

    this.loadDocumentacionBasica();
   /*  this.documentacionService
      .getDocumentacionBasica(environment.sessionId, this.cuil, this.orden, this.nroBenef)
      .subscribe((result) => {
        this.documentationBasica = result;
        this.numberDocsRequired = result.length;
        this.modalData = {
          user_data: { ...this.modalData.user_data },
          documentation: result.map((doc) => {
            return {
              id: doc.id_doc,
              documentation_id: doc.HPBEnfermedadTipoDocId,
              document_title: doc.DocumentoDescripcion,
              document_name: doc.fileName || "",
              document_path: "",
              document_file_type: doc.mime || "",
            }
          }),
          cuilDoc: this.cuil,
          nroBeneficiario: this.nroBenef,
          nroOrden: this.orden,
        };
        this.numberDocsRequired = result.length;
        this.numberDocsUp = result.filter((doc) => doc.uploaded === true).length;
      }); */

   /*  setTimeout(() => {
      this.isLoading = false;
    }, 900); */
    this.dialog.closeAll();
  }

  closeModalUserInformation() {
    this.isLoading = true;
    this.misDatosServiceSuscription = this.misDatosService
      .getMisDatos()
      .subscribe((result) => {
        this.userData = result;
        this.modalData = {
          user_data: {
            ...this.modalData.user_data,
            email: this.userData.email || "",
            phone_number: this.userData.telefono || "",
            area_code: this.userData.codigo_area || "",
            address: this.userData.calle || "",
            number: this.userData.numero || "",
            floor: this.userData.piso || "",
          },
          documentation: this.modalData.documentation,
          cuilDoc: this.cuil,
          nroBeneficiario: this.nroBenef,
          nroOrden: this.orden,
        };
      })
    this.isLoading = false;

    this.dialog.closeAll();
  }

  openModalUserInformation() {
    const dialogRef = this.dialog.open(ModalUserInformationComponent, {
      width: "950px",
      height: "auto",
      data: this.modalData.user_data,
      panelClass: "custom-modalbox",
    });
    dialogRef.componentInstance.closeModal = this.closeModalUserInformation.bind(this);

  }

  recibirPrestacionesDesdeFormNuevaPrestacion(prestaciones: any) {

    this.prestacionesQueRequierenAtencion = prestaciones.result
    this.numeroDePrestaciones = this.prestacionesQueRequierenAtencion.length;
    this.filtrarPrestacionesCompletadas(this.prestacionesQueRequierenAtencion);
    this.prestacionesQueRequierenAtencion.forEach((prestacion, i)=>
    {
      if(prestacion.nomencladordescripcion === prestaciones.nombrePrestacion){
        this.idPrestacionCreada = i;
      }
    
    })
    this.destacarPrestacionCreada = true;
    this.envioPrestacion = prestaciones.envioPrestacion;
    const sectionClass = 'card-nueva-prestacion';
    const elements = document.getElementsByClassName(sectionClass);
    if (elements.length > 0) {
      const element = elements[0];
      element.scrollIntoView();
    }
  }


  loadPrestaciones({nombrePrestacion, idPrestacionCreada, envioPrestacion, update}) {
    const sectionTitle ='titulo-nuevas-prestaciones';
    const title = document.getElementsByClassName(sectionTitle);
    title[0].scrollIntoView();

    this.isLoadingPrestaciones = true;
    this.prestacionesQueRequierenAtencion = [];
    this.prestacionesService.getAllPrestaciones(environment.sessionId, this.cuil, this.nroBenef, this.orden, update)
          .subscribe((result) => {
            
            this.prestacionesQueRequierenAtencion = result

            this.isLoading = false;

            
            this.numeroDePrestaciones = this.prestacionesQueRequierenAtencion.length;
            this.filtrarPrestacionesCompletadas(this.prestacionesQueRequierenAtencion);
            this.prestacionesQueRequierenAtencion.forEach((prestacion, i)=>
            {
              if(prestacion.id == idPrestacionCreada){
                this.idPrestacionCreada = i;
              }
            })
            this.destacarPrestacionCreada = true;
            this.envioPrestacion = envioPrestacion;

            this.isLoadingPrestaciones = false
            const sectionClass = 'card-nueva-prestacion';
            const elements = document.getElementsByClassName(sectionClass);
            if (elements.length > 0) {
              const element = elements[0];
              element.scrollIntoView();
            }

          });

  }


  onScrollChanged(){
    if(!this.isLoadingPrestaciones && !this.isLoadingDatosNomenclador && this.isNewPrestacion){
      const element = document.getElementById('form-nueva-prestacion-unico');
      if (element) {
        
        element.scrollIntoView();
      } else {
        console.error("Elemento 'form-nueva-prestacion-unico' no encontrado");
      }

    }
  }

  onPrestacionClick(){
    this.destacarPrestacionCreada && (this.destacarPrestacionCreada = false);
  }



    // console.log('Tipos y Descripciones:', this.tiposYDescripcionesNomenclador);
    // console.log('Otros Datos:', this.datosNomenclador);
}
