import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { LoginService } from 'app/services/login.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-comprobantes',
  templateUrl: './comprobantes.component.html',
  styleUrls: ['./comprobantes.component.scss']
})
export class ComprobantesComponent implements OnInit {

  constructor(private _loginService: LoginService,
            private router: Router) { }

  ngOnInit(): void {
    this._loginService.getAll().subscribe(resp => {
        if(!resp['isLogged'])
            this.router.navigate(['/login'])
    });
  }

  instructivoFacturacion(){   ;
    window.open('assets/files/instructivos/INSTRUCTIVO_PROVEEDORES-FACTURACIÓN.pdf'); 
  }

  linkPanel()
  {
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  }

}
