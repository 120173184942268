<div class="container-main">
  <div class="container-close-button">
    <button (click)="onCloseModal()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="container-information">
    <h2>
      DOCUMENTACIÓN BÁSICA
    </h2>

    <p>
      Son aquellos certificados, historias clínicas, o documentos en general que se solicitan independientemente de la
      prestación solicitada por el beneficiario.
    </p>

    <span>
      Su presentación es obligatoria para poder solicitar cualquier prestación.
    </span>
  </div>
  <div class="container-buttons">
    <button-primary-orange-component label="Aceptar" [disabled]="false"
      (onClick)="onCloseModal()"></button-primary-orange-component>
  </div>
</div>