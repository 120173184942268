import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

interface IGetReporte {
  cuit: string;
  obra_social: string;
  solapa: string;
  periodo_desde: string;
  periodo_hasta: string;
  fecha_tramite_desde: string;
  fecha_tramite_hasta: string;
}

@Injectable({
  providedIn: 'root'
})
export class LiquidadorService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any[]>{
    return this.http.get<any[]>(environment.urlApi + '');
  }

  getLiquidaciones(): Observable<any[]>{
    return this.http.get<any[]>(environment.urlApi + 'liquidador/getLiquidaciones');
  }

  getLiquidacion(id): Observable<any[]>{
    return this.http.get<any[]>(environment.urlApi + 'liquidador/getLiquidacion?id=' + id);
  }

  getFactura(cuit,NroFactura): Observable<any[]>{
    return this.http.get<any[]>(environment.urlApi + 'liquidador/getFactura?cuit='+ cuit+'&nroFactura='+NroFactura);
  }

  getReporte(filtros: IGetReporte): Observable<any> {
    const body = {
      cuit: filtros.cuit, 
      obra_social: filtros.obra_social,
      solapa: filtros.solapa, 
      periodo_desde: filtros.periodo_desde, 
      periodo_hasta: filtros.periodo_hasta, 
      fecha_tramite_desde: filtros.fecha_tramite_desde, 
      fecha_tramite_hasta: filtros.fecha_tramite_hasta
    }

    return this.http.post<any>(environment.urlApi + 'liquidador/getReporte', body);
  }
  getPlantilla(cuit,NroFactura): Observable<any[]>{
    return this.http.get<any[]>(environment.urlApi + 'liquidador/getPlantilla?cuit='+ cuit+'&nroFactura='+NroFactura);
  }
}
