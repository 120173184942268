<div class="modal-alta-container">
  <h3 class="title-header">Alta contable: NO</h3>
  <button type="button" class="close" aria-label="Close" (click)="closeModalAltaContable()" class="btn-close-modal">
    <mat-icon>clear</mat-icon>
  </button>
  <p>
    El proveedor asignado para la prestación aún no completó su alta contable.
    Para realizar la misma, el prestador deberá presentar la documentación de
    alta detallada en el <a href="{{ env.urlDownloads }}instructivos/discapacidad/INSTRUCTIVO%20DISCAPACIDAD%202023{{ extension }} " target="_blank">instructivo de discapacidad</a>, generando
    un usuario en el siguiente <a href="/registro">enlace</a>.
  </p>
  <h3>¿Qué puedes hacer?</h3>
  <p>
    En el caso de disponer el mail de tu prestador, puedes ingresarlo a continuación y enviarle el instructivo y enlaces que necesitará para gestionar su alta contable. Este procedimiento es opcional.
  </p>

  <p></p>

  <div class="wrapper-1_input-div">
    <label>email del prestador</label>
    <div class="input-button-container">
      <form [formGroup]="form">
        <input placeholder="Email" type="email" formControlName="email" [ngClass]="{'error': form.controls.email.invalid && form.controls.email.touched}" />
        <button [disabled]="form.controls.email.invalid || isLoading" (click)="sendEmail()" class="btn-submit-email-alta-contable">Enviar instructivo</button>
        <br>
        <span class="error-message" *ngIf="form.controls.email.errors?.required && form.controls.email.touched"></span>
        <span class="error-message" *ngIf="form.controls.email.invalid && form.controls.email.touched">Email inválido.</span>
      </form>
    </div>
  </div>
</div>
