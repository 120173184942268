import { EventEmitter, Injectable, Output } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class PrestacionesService {
    constructor(private http: HttpClient) { }
    
    getAllPrestaciones(sessionId, cuil, nroBeneficiario, nroOrden, update = 1): Observable<any>{
        const data = {
            sessionId: sessionId,
            cuil: cuil,
            nroBeneficiario:nroBeneficiario,
            nroOrden: nroOrden,
            update: update
          };
        return this.http.post(`${environment.urlApi}discapacidad/getAllPrestaciones`,data);
    }
    

    getPrestaciones(sessionId, cuil, nroBeneficiario, nroOrden): Observable<any>{
        const data = {
            sessionId: sessionId,
            cuil: cuil,
            nroBeneficiario:nroBeneficiario,
            nroOrden: nroOrden
          };
        return this.http.post(`${environment.urlApi}discapacidad/getPrestaciones`,data);
    }

    getPrestacionesEnTransito(sessionId, nroBeneficiario, nroOrden): Observable<any> {
        const data = {
            sessionId: sessionId,
            nroBeneficiario:nroBeneficiario,
            nroOrden: nroOrden
          };
        return this.http.post(`${environment.urlApi}discapacidad/getPrestacionesEnTransito`,data);
    }

    // Temporal para realizar pruebas en el flujo.

    getAllPrestacionesCuil(sessionId, cuil): Observable<any>{
        const data = {
            sessionId: sessionId,
            cuil:cuil,
          };
        return this.http.post(`${environment.urlApi}discapacidad/getAllPrestaciones`,data);
    }

    getPrestacionesCuil(sessionId,cuil): Observable<any>{
        const data = {
            sessionId: sessionId,
            cuil: cuil
          };
        return this.http.post(`${environment.urlApi}discapacidad/getPrestaciones`,data);
    }

}