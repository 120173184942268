<div class="page-layout blank p-24" fusePerfectScrollbar>

    <div fxLayout="row" fxLayoutAlign="space-around">
        <div fxFlex="20" fxHide.xs class="aside-list">
            <div>
                <img src="assets/images/iconos/icono_abm.svg">
            </div>
        </div>
        <div fxFlex="70" fxFlex.xs="100">
            <div class="breadcrumbs">
                <a href="/">Inicio</a> » <span>Portal del Monotributista</span>
            </div>

            <h2 class="page-subtitle">Portal del Monotributista</h2>

            <p>Bienvenido. Aquí podrás conocer la cobertura que te ofrecemos y cómo ingresar a la Obra Social.</p>
        </div>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around">
        <!-- button misalud xs -->
        <div fxLayout="column" fxFlexOrder.xs="3" fxShow.xs fxHide.gt-xs fxLayoutAlign="space-between"
            class="mi-sssalud" *ngIf="!showForm">
            <div>
                <img src="assets/images/logos/mi_sssalud_portal_logo.png" [ngClass.xs]="'misalud_logo'">
            </div>
            <div>
                <a mat-raised-button class="link-button" href="https://www.sssalud.gob.ar/misssalud"
                    target="_blank">Acceder</a>
            </div>
        </div>
        <!-- end button mi salud xs -->
        <div fxFlex="20" fxFlexOrder.xs="4" class="aside-list" [fxHide.lt-sm]="hideWhatsapp">
            <div class="menu">
                <p>Resolvé tus consultas por WhatsApp</p>
                <a href="https://wa.me/5491128391323" target="_blank">

                    <img class="fit-image-50" [ngClass.xs]="'fit-image-30'" src="assets/images/logos/whatsapp_logo.svg">
                </a>
            </div>
        </div>
        <div fxFlex="70" fxFlex.xs="100" fxFlexOrder.xs="1">
            <div fxLayout="row" fxLayoutAlign="space-between" class="links-column">
                <div fxFlex="42" fxFlex.xs="100" id="panel-one">
                    <mat-accordion hideToggle>
                        <mat-expansion-panel disabled class="list-item" (click)="toggleView(1)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p class="listed-link">Cartilla</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                        <mat-expansion-panel disabled class="list-item" (click)="toggleView(2)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p class="listed-link">Gestioná la opción de cambio de Obra Social</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                        <mat-expansion-panel disabled class="list-item" (click)="toggleView(3)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p class="listed-link">Preguntas frecuentes</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>

                    </mat-accordion>

                    <div fxLayout="column" fxHide.xs fxFlexOrder.xs="3" fxLayoutAlign="space-between" class="mi-sssalud"
                        *ngIf="!showForm">
                        <div>
                            <!-- <img src="assets/images/logos/mi_sssalud_portal_logo.png" [ngClass.xs]="'misalud_logo'"> -->
                            <img src="assets/images/logos/misssalud_logo.svg" class="misalud_logo">
                        </div>
                        <div>
                            <a mat-raised-button class="link-button" href="https://www.sssalud.gob.ar/misssalud"
                                target="_blank">Acceder</a>
                        </div>
                    </div>
                </div>

                <div fxFlex="55" fxFlex.xs="100" fxFlexOrder.xs="2" *ngIf="showMe==1" id="panel-two">
                    <mat-accordion multi="true" hideToggle>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p class="listed-link">Descargar y visualizar</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="circled-list">
                                <li><a href="assets/files/cartilla_plan_png.pdf" target="_blank">Cartilla</a></li>
                            </ul>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>

                <div fxFlex="55" fxFlex.xs="100" *ngIf="showMe==2" id="panel-three">
                    <mat-accordion hideToggle>
                        <mat-expansion-panel #mep1="matExpansionPanel">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p class="listed-link">1. Conocé cómo gestionar la opción de cambio</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="circled-list">
                                <li><a href="https://www.youtube.com/watch?v=J2buGt87Ds0" target="_blank">Video
                                        Instructivo de la Superintendencia de Servicios de Salud</a></li>
                                <li><a href="assets/files/instructivo_opcion_cambio.pdf" target="_blank">PDF Instructivo
                                        de la Superintendencia de Servicios de Salud</a></li>
                            </ul>
                        </mat-expansion-panel>
                        <mat-expansion-panel disabled class="list-item"
                            (click)="newTab('https://www.sssalud.gob.ar/misssalud')">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p class="listed-link">2. Accedé a Mi SSSalud para gestionar el cambio</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>

                        <mat-expansion-panel disabled class="list-item" (click)="newTab('formulario-003')">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p class="listed-link">3. Completá el siguiente formulario y adjuntalo en el paso 4</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                        <mat-expansion-panel disabled class="list-item" (click)="toggleForm()"
                        (click)="mep1.expanded = false;">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p class="listed-link">4. Finalizá el ingreso</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div fxFlex="55" fxFlex.xs="100" *ngIf="showMe==3" id="panel-four">
                    <mat-accordion hideToggle>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p class="listed-link">1. ¿Qué es la opción de cambio?</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>Es un trámite digital que se realiza en la Superintendencia de Servicios de
                                Salud para que puedas cambiar de Obra Social.
                            </p>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p class="listed-link">2. ¿Qué cobertura te ofrecemos?</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>ASE te brindará, sin costo adicional, la cobertura del PMO según tu
                                lugar de residencia, solicitándola en el <a class="ase-link"
                                    href="assets/files/formulario_003.pdf" target="_blank"> Formulario 003</a>
                                bajo el concepto PMO en el campo "Cobertura".
                            </p>
                            <p>Al realizar tu inscripción, también te ofrecemos la posibilidad de elegir
                                entre las entidades de Medicina Prepaga más importantes del país, el
                                plan que mejor se ajuste a tus necesidades tanto en cobertura como
                                en costos. Recordá que luego tendrás que contactarte con la Prepaga
                                elegida para acordar los detalles.
                            </p>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p class="listed-link">3. ¿Cómo gestionar tu alta?</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>Primero gestionás la opción de cambio en la web de la
                                <a class="ase-link" target="_blank" href='https://www.sssalud.gob.ar/misssalud'>
                                    SSSalud
                                </a>. Luego,
                                con el comprobante emitido, finalizás el ingreso en nuestra obra social
                                en la página que te encontrás del sitio web de ASE, en la sección
                                <em>"Gestioná la opción de cambio de Obra Social"</em>.
                            </p>
                            <p>Dentro de un plazo estimado de 10 días hábiles recibirás una notificación
                                con la resolución de tu trámite.
                            </p>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p class="listed-link">4. ¿A partir de cuándo está vigente el cambio de obra social?
                                    </p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>La opción de cambio se hará efectiva a partir del primer día del tercer
                                mes posterior a la presentación de la solicitud, como se indica en la
                                constancia de opción de cambio de la Superintendencia de Servicios de
                                Salud.
                            </p>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p class="listed-link">5. ¿Cómo comunicar el cambio de prepaga o ingreso de un
                                        familiar a la obra social?</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>Podrás comunicarlo a través de
                                <a class="ase-link" href='mailto:serviciosalbeneficiario@ase.com.ar'>
                                    serviciosalbeneficiario@ase.com.ar
                                </a>
                            </p>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>
    </div>
    
    <div id="form-container" *ngIf="showForm">
        <p><strong>Por favor, completá el siguiente formulario para gestionar la opción de cambio.</strong></p>
        <form #form name="" [formGroup]="formAlta" action="" method="POST" novalidate enctype="multipart/form-data">
            <div class="data">
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Nombre</mat-label>
                        <input matInput [formControl]="nombre" required>
                        <mat-error *ngIf="nombre.invalid">Debe ingresar su nombre.</mat-error>
                    </mat-form-field>
                </div>
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Apellido</mat-label>
                        <input matInput [formControl]="apellido" required>
                        <mat-error *ngIf="apellido.invalid">Debe ingresar su apellido.</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="data">
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Email</mat-label>
                        <input matInput [formControl]="email" required>
                        <mat-error *ngIf="email.invalid && email.hasError('required')">Debe ingresar su email.
                        </mat-error>
                        <mat-error *ngIf="email.invalid && email.hasError('email')">Ingrese un email válido.</mat-error>
                    </mat-form-field>
                </div>

                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>CUIT</mat-label>
                        <input matInput type="number" placeholder="Ejemplo 37508887002" [formControl]="CUIT" maxlength="11" required oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)">
                        <mat-error *ngIf="CUIT.invalid ">Debe ingresar el CUIT.</mat-error>                        
                        <mat-error *ngIf="CUIT.hasError('cuit') ">{{ CUIT.errors.cuit.value }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="data">
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Cod. Área</mat-label>
                        <input matInput [formControl]="codigoarea" maxlength="5" required>
                        <mat-error *ngIf="codigoarea.invalid">Debe ingresar el Cod. Área.</mat-error>
                    </mat-form-field>
                </div>

                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Teléfono</mat-label>
                        <input matInput [formControl]="telefono" maxlength="20" required>
                        <mat-error *ngIf="telefono.invalid">Debe ingresar el teléfono sin guiones.</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="data">
                <!-- mouseover y mouseleave for desktop -->
                <div class="tw" fxHide.lt-md (mouseenter)="toggleTooltip()" (mouseleave)="toggleTooltip()">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Número de formulario emitido por la Superintendencia <mat-icon aria-hidden="false"
                                aria-label="Example home icon">info</mat-icon>
                        </mat-label>
                        <input matInput [formControl]="numero_formulario" required>
                        <mat-error *ngIf="numero_formulario.invalid">Debe ingresar el número de formulario.</mat-error>
                    </mat-form-field>
                </div>
                <!-- end mouseover/mouseleave-->
                <!-- dialog for mobile -->
                <div class="tw" fxShow.lt-md fxHide.gt-sm>
                    <mat-form-field class="mat-fullwidth" (click)="openDialog()">
                        <mat-label>Número de formulario emitido por la Superintendencia <mat-icon aria-hidden="false"
                                aria-label="Donde encuentro el número de formulario?">info</mat-icon>
                        </mat-label>
                        <input matInput [formControl]="numero_formulario" required>
                        <mat-error *ngIf="numero_formulario.invalid">Debe ingresar el número de formulario.</mat-error>
                    </mat-form-field>
                </div>
                <!-- end dialog -->

                <div class="tw" *ngIf="showTooltip">
                    <img class="fit-image-50 floating" src="assets/images/iconos/hover_numero_formulario.png">
                </div>
            </div>

            <mat-accordion multi="true" hideToggle>
                <mat-expansion-panel disabled expanded>
                    <mat-expansion-panel-header class="fixed-header">
                        <mat-panel-title>
                            <p>
                                <mat-icon matSuffix>folder</mat-icon><strong>Adjuntar Documentación</strong> (formatos
                                válidos: DOC y PDF)
                            </p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="data">
                        <div class="tw">
                            <mat-form-field appearance="standard" class="mat-fullwidth">
                                <mat-label>Formulario de Declaración Jurada del Monotributista - F003</mat-label>
                                <ngx-mat-file-input formControlName="adjunto1" #adjunto1 required
                                    [accept]="'.pdf, .doc, .docx'">
                                </ngx-mat-file-input>
                                <mat-icon matSuffix *ngIf="adjunto1.empty">add</mat-icon>
                                <button mat-icon-button matSuffix *ngIf="!adjunto1.empty"
                                    (click)="adjunto1.clear($event)">
                                    <mat-icon>clear</mat-icon>
                                </button>
                                <mat-error *ngIf="formAlta.get('adjunto1').hasError('maxContentSize')
                                || formAlta.get('adjunto1').hasError('required')
                                || formAlta.get('adjunto1').hasError('mimeType')">Debe adjuntar un archivo (DOC
                                    o
                                    PDF, 3MB máximo).
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="data">
                        <div class="tw">
                            <mat-form-field appearance="standard" class="mat-fullwidth">
                                <mat-label>F184 de AFIP o Comprobante de Adhesión al Régimen Nacional de Efectores</mat-label>
                                <ngx-mat-file-input formControlName="adjunto2" #adjunto2 required
                                    [accept]="'.pdf, .doc, .docx'">
                                </ngx-mat-file-input>
                                <mat-icon matSuffix *ngIf="adjunto2.empty">add</mat-icon>
                                <button mat-icon-button matSuffix *ngIf="!adjunto2.empty"
                                    (click)="adjunto2.clear($event)">
                                    <mat-icon>clear</mat-icon>
                                </button>
                                <mat-error *ngIf="formAlta.get('adjunto2').hasError('maxContentSize')
                                || formAlta.get('adjunto2').hasError('required')
                                || formAlta.get('adjunto2').hasError('mimeType')">Debe adjuntar un archivo (DOC
                                    o
                                    PDF, 3MB máximo).
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="data">
                        <div class="tw">
                            <mat-form-field appearance="standard" class="mat-fullwidth">
                                <mat-label>Constancia de Confirmación de Asignación de la Obra Social elegida
                                </mat-label>
                                <ngx-mat-file-input formControlName="adjunto3" #adjunto3 required
                                    [accept]="'.pdf, .doc, .docx'">
                                </ngx-mat-file-input>
                                <mat-icon matSuffix *ngIf="adjunto3.empty">add</mat-icon>
                                <button mat-icon-button matSuffix *ngIf="!adjunto3.empty"
                                    (click)="adjunto3.clear($event)">
                                    <mat-icon>clear</mat-icon>
                                </button>
                                <mat-error *ngIf="formAlta.get('adjunto3').hasError('maxContentSize')
                                || formAlta.get('adjunto3').hasError('required')
                                || formAlta.get('adjunto3').hasError('mimeType')">Debe adjuntar un archivo (DOC
                                    o
                                    PDF, 3MB máximo).
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </form>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" class="submit-section">

            <div>
                <re-captcha (resolved)="captchaResolved($event)" siteKey="6Lci2cUZAAAAAKfhe3JqVTih6eDVsgbbSz_fT50X">
                </re-captcha> 
            </div>
            <div>
                <button mat-raised-button class="submit-button" aria-label="Confirmar" (click)="sendForm()"
                    
                     [disabled]="!captchares && !formAlta.invalid">Confirmar</button>
            </div>
        </div>


    </div>



</div>