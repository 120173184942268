import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { cuitValidator } from 'app/shared/cuit-validator.directive';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn, FormBuilder, FormGroupDirective, NgForm, ValidationErrors } from '@angular/forms';
import { FilialesService } from 'app/services/filiales.service';
import { Filial } from 'app/models/filial.model';
import { Observable } from 'rxjs';
import { RegistroProveedorService } from '../../../../services/registro-proveedor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

        return (invalidCtrl || invalidParent);
    }
}

@Component({
    selector: 'app-registro-proveedor',
    templateUrl: './registro-proveedor.component.html',
    styleUrls: ['./registro-proveedor.component.scss']
})
export class RegistroProveedorComponent implements OnInit {

    captchares: boolean = false;
    provincias = [];
    provinciaElegida: any;
    CUIT: any;
    email: any;
    formRegistro: FormGroup;
    matcher = new MyErrorStateMatcher();
    captcharesponse: any;

    captchaResolved(captchaResponse: string) {
        this.captchares = true;
        this.captcharesponse = captchaResponse; //Agregamos el response aca para ser validado en node y no recibir cualquier solicitud de contacto
    }

    constructor(private _registroProveedorService: RegistroProveedorService,
        private _snackBar: MatSnackBar,
        private fb: FormBuilder,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.provincias = ["BS.AS.", "CAPITAL", "CATAMARCA", "CHACO", "CHUBUT",
            "CORDOBA", "CORRIENTES", "ENTRE RIOS", "FORMOSA", "JUJUY", "LA PAMPA",
            "LA RIOJA", "MENDOZA", "MISIONES", "NEUQUEN", "RIO NEGRO", "SALTA",
            "SAN JUAN", "SAN LUIS", "SANTA CRUZ", "SANTA FE", "SGO. DEL ESTERO",
            "T. DEL FUEGO A.I.A.S.", "TUCUMAN"];

        this.formRegistro = this.fb.group({
            CUIT: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(11), this.validateCuit]],
            provincia: ['', Validators.required],
            email: [
                '',
                [
                    Validators.required,
                    Validators.pattern('^[^@]+@[^@]+.[a-zA-Z]{2,}$'),
                ],
            ],
            email2: [
                '',
                [
                    Validators.required,
                    Validators.pattern('^[^@]+@[^@]+.[a-zA-Z]{2,}$'),
                ],
            ],
            terminos: [false, [Validators.required]],
        },
            { validator: this.emailsIguales }
        );
    }

    reload(): void {
        setTimeout(function () {
            window.location.replace(environment.urlWebsite);
        }, 6000);
    }

    emailsIguales(control: AbstractControl): ValidationErrors | null {
        const email = control.get('email');
        const email2 = control.get('email2');

        if (email && email2 && email.value !== email2.value) {
            email2.setErrors({ emailsNoCoinciden: true });
            return { emailsNoCoinciden: true };
        } else {
            email2.setErrors(null);
            return null;
        }
    }

    btnCrear() {
        this.formRegistro.markAllAsTouched();
        if (!this.formRegistro.valid) {
            this._snackBar.open('Recordá que debes completar todos los campos correctamente', 'Cerrar', {
                duration: undefined,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
            return;
        }

        this.provinciaElegida = this.formRegistro.value.provincia;
        const formData = new FormData();
        formData.append('CUIT', this.formRegistro.value.CUIT);
        formData.append('filial', this.provinciaElegida);
        formData.append('email', this.formRegistro.value.email);
        formData.append('captcharesponse', this.captcharesponse);

        this._registroProveedorService.registroProveedor(formData)
            .subscribe(resp => {

                if (resp && resp['error'] != 2) { // si el proveedor esta registrado
                    this._snackBar.open('Muchas gracias por enviarnos su solicitud. Revise su correo para finalizar el registro', 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                    this.reload();

                } else
                    if (resp && resp['error'] == 2) {
                        //navego a alta-proveedor y envio filial, email, cuit      
                        this.router.navigate(['proveedores/alta-proveedor'], { state: { provincia: this.provinciaElegida, email: this.formRegistro.value.email, cuit: this.formRegistro.value.CUIT } });
                    }

            },
                err => {
                    this._snackBar.open(err.error['message'], 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                });
    }

    btnCancelar() {
      window.location.href="/registro";
    }

    validateCuit(control: AbstractControl): { [key: string]: any } | null {
        const cuit = String(control.value).replace(/[-\s]/g, '');

        if (!cuit) return null; // Permite campos vacíos

        if (cuit.length !== 11 || isNaN(Number(cuit))) {
            return {
                cuit: { value: 'El CUIL/CUIT debe tener 11 dígitos numéricos.' },
            };
        }

        // Extraer dígito verificador y números
        const digitoVerificador = Number(cuit[10]);
        const digitos = cuit.slice(0, 10).split('').map(Number);

        // Calcular suma de productos y dígito calculado
        const weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
        const sum = digitos.reduce(
            (acc, digit, index) => acc + digit * weights[index],
            0
        );
        let calculatedDigit = 11 - (sum % 11);

        if (calculatedDigit === 11) calculatedDigit = 0; // Si el cálculo da 11, se reemplaza por 0

        if (calculatedDigit !== digitoVerificador) {
            return { cuit: { value: 'El CUIL/CUIT ingresado es inválido.' } };
        }

    }
}
