<table mat-table [dataSource]="listPrestacionesRechasadasAprobadas">
    <ng-container matColumnDef="Prestacion">
      <th mat-header-cell *matHeaderCellDef>Prestación</th>
      <td mat-cell *matCellDef="let element" class="truncate-cell" title="{{element?.nomencladordescripcion}}"> {{element?.nomencladordescripcion}} </td>
    </ng-container>
    <ng-container matColumnDef="Prestador">
      <th mat-header-cell *matHeaderCellDef>Prestador</th>
      <td mat-cell *matCellDef="let element" class="truncate-cell" title="{{element?.razonsocial}}"> {{element?.razonsocial }} </td>
    </ng-container>
    <ng-container matColumnDef="Desde">
      <th mat-header-cell *matHeaderCellDef>Desde</th>
      <td mat-cell *matCellDef="let element"> {{element.periododesde | date: 'dd/MM/yyyy'}} </td>
    </ng-container>
    <ng-container matColumnDef="Hasta">
      <th mat-header-cell *matHeaderCellDef>Hasta</th>
      <td mat-cell *matCellDef="let element"> {{element.periodohasta | date: 'dd/MM/yyyy'}} </td>
    </ng-container>
    <ng-container matColumnDef="Sesiones">
      <th mat-header-cell *matHeaderCellDef>Sesiones o Km</th>
      <td mat-cell *matCellDef="let element" class="truncate-cell texto-centrado" title="{{element?.cantidad}} ({{element.tipocantidad}})"> {{element.cantidad}} ({{element.tipocantidad}})</td>
    </ng-container>
    <ng-container matColumnDef="Estado">
      <th mat-header-cell *matHeaderCellDef>Estado</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.estado == 'Rejected'" class="solicitud-rechazada">
          <img src="assets/icons/solicitudes-aprobadas-rechazadas/solicitud-rechazada.svg" />
          <span>RECHAZADA</span>
        </div>
        <div *ngIf="element.estadosgi == 'PRE APROBADA'" class="solicitud-aprobada">
          <img src="assets/icons/solicitudes-aprobadas-rechazadas/solicitud-aprobada.svg" />
          <span>PRE APROBADA</span>
        </div>
        <div *ngIf="element.estado == 'Approved'" class="solicitud-aprobada">
          <img src="assets/icons/solicitudes-aprobadas-rechazadas/solicitud-aprobada.svg" />
          <span>APROBADA</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="Link">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <a (click)="irADetalle(element.id)" class="link-grilla-solicitud">Más información</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columndefs"></tr>
    <tr mat-row *matRowDef="let row; columns: columndefs;"></tr>
  </table>
  