import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolSelectComponent } from './rolSelect.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDemoModule } from '@fuse/components/demo/demo.module';
import { FuseHighlightModule } from '@fuse/components';

const routes = [
    {
        path     : 'seleccionar-rol',
        component: RolSelectComponent
    },
];

@NgModule({
  declarations: [RolSelectComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
        FuseSharedModule,
        FuseDemoModule,
        FuseHighlightModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule
  ]
})
export class RolSelectModule { } 
        