<div class="container-main">
  <div class="container-information">
    <div class="container-icon-subtitle">
      <span>
        DESCARTAR CAMBIOS
      </span>
      <span class="close-icon" (click)="cerrarDialogo()">
        <img src="./assets/icons/DA-icons/close.svg" alt="">
      </span>
    </div>
    <h2>
      ¿Descartar cambios?
    </h2>
    <p>
      Se descartarán los últimos avances ingresados hasta el momento. 
    </p>
  </div>

  <div class="container-buttons">
    <button class="blue-btn" (click)="cerrarDialogo()">Cancelar</button>
    <button class="orange-btn" (click)="confirmado()" >Confirmar</button>
  </div>
</div>

