import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import * as shape from 'd3-shape';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';

import { NovedadesService } from 'app/services/novedades.service';
import { SeccionesService } from 'app/services/secciones.service';

import { switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Novedad } from 'app/models/novedad.model';
import { Seccion } from 'app/models/seccion.model';
import { lowerCase } from 'lodash';
import { Meta } from '@angular/platform-browser';
import { PopupComponent } from '../popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector   : 'home',
    templateUrl: './home.component.html',
    styleUrls  : ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class HomeComponent implements OnInit
{
    env = environment;
    view = 'preview';
    secciones: Seccion[] = null;
    carousel: Novedad[] = null;
    error: string;
    sec: string;

    loadNovedadesCarousel(): void{
        this._novedadesService.getCarousel()
        .subscribe(novedades => {
                        
            this.carousel = novedades; 
            console.log('carousel: '+ JSON.stringify(this.carousel));
        });
    }    

    loadSecciones(): void{
        this._seccionesService.getHome()
        .subscribe(secciones => {
            this.secciones = secciones;
        });
    }    

    seccionDetails(seccion: Seccion): void{
        
       if( seccion.link_externo === '' || seccion.link_externo === null){            
            this.router.navigate(['/seccion', seccion.id]);           
        }else{
            window.open(seccion.link_externo, '_blank');  
        } 
    }

    getNovedad(id, link_externo): void{
        if (link_externo === '' || link_externo === null || link_externo === undefined){
            this.router.navigateByUrl(`/novedad/${id}`);
        }else if (link_externo != '' || link_externo != null || link_externo != undefined) {
            window.open(link_externo, 'blank');            
        }        
    }

    clearError(): void
    {
        this.error = '';
    }

    constructor(
        private _seccionesService: SeccionesService,
        private _novedadesService: NovedadesService,
        private route: ActivatedRoute,
        private router: Router,
        private meta: Meta,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar
    )
    {
        this.meta.addTag({ name:'description', content: 'Organización prestadora de servicios de salud, que brinda cobertura médica en cualquier parte del país a través de las mejores redes de prestadores'});
        this.meta.addTag({ name: 'keywords', content: 'ase nacional, obra social ase, eco plus, beneficios, filiales, ase rosario, ase neuquen, lima 87, acción social de empresarios, prestadores, dirección, portal proveedores, turismo, discapacidad, formularios,  sanatorio finochietto, somos ase, novedades, contacto'});
        
    }

    ngOnInit(): void {
        //this.dialog.open(PopupComponent); // popup
        this.loadSecciones();
        this.loadNovedadesCarousel();
        this.error = this.route.snapshot.queryParamMap.get('er');
        var msg = this.route.snapshot.queryParamMap.get('msg');
        if (msg){
            this._snackBar.open(msg, 'Cerrar', {
                duration: undefined,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
        }
    }
}
