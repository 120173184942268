import { Component, Inject, OnInit } from "@angular/core";
import {  AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserData } from "app/models/modal-documentacion-basica-data.model";
import { MisdatosService } from "app/services/misdatos.service";

@Component({
  selector: "modal-user-information-component",
  templateUrl: "./modal-user-information.component.html",
  styleUrls: ["./modal-user-information.component.scss"],
})

export class ModalUserInformationComponent implements OnInit {
  form: FormGroup;
  fileForm: FormGroup;
  user_information: UserData;
  emailTouched: boolean = false;
  closeModal: () => void;
  isLoading: boolean = false;
  email: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserData,
    private _fb: FormBuilder,
    private _misDatosService: MisdatosService,
    private _snackBar: MatSnackBar
  ) {
    this.form = this._fb.group({
      email: ["", [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      phone_number: ["", [Validators.required, Validators.pattern(/^(?!0)[0-9]{6,9}$/), ]],
      area_code: ["", [Validators.required, Validators.pattern(/^\+?[0-9]{2,5}$/), this.areaCodeValidator]],
      address: ["", [Validators.required]],
      number: ["", [Validators.required, Validators.pattern(/^(?!0)[0-9]{1,5}$/)]],
      floor: [""],
    });
  }

  onAccept() {
    this.isLoading = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.isLoading = false;
      return this._snackBar.open("Complete los campos obligatorios", "Cerrar", {
        duration: undefined,
        horizontalPosition: "center",
        verticalPosition: "top",
      })
    }

    const dataToSend = {
      userId: this.user_information.user_id,
      sessionId: this.user_information.sessionId,
      email: this.form.value.email,
      codigo_area: this.form.value.area_code,
      telefono: this.form.value.phone_number,
      calle: this.form.value.address,
      numero: this.form.value.number,
      piso: this.form.value.floor,
    }
    
    this._misDatosService.updateContactData(dataToSend).subscribe((res) => {
      if (res) {
        this.isLoading = false;
        if (this.form.value.email != this.email) {
          this._snackBar.open('Si haz modificado el email recibirás una comunicación en la casilla de correos para confirmar el nuevo email. Si modificaste otros datos se reflejarán dentro de 48h hábiles en la sección de ABM >> Consultas. Gracias.', "Cerrar", {
            duration: undefined,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
        else {
          this.isLoading = false;
          this._snackBar.open('Si haz modificado el email recibirás una comunicación en la casilla de correos para confirmar el nuevo email. Si modificaste otros datos se reflejarán dentro de 48h hábiles en la sección de ABM >> Consultas. Gracias.', "Cerrar", {
            duration: undefined,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }

        this.onCloseModal();
      }
    });
  }

  onCloseModal() {
    if (this.closeModal) {
      this.closeModal();
    }
  }

  onEmailInput() {
    this.form.get('email').updateValueAndValidity();
  }

  onEmailBlur() {
    this.emailTouched = true;
  }

  showEmailErrorMessages() {
    const emailControl = this.form.get('email');
    return this.emailTouched && emailControl.invalid;
  }

  ngOnInit(): void {
    this.user_information = { ...this.data };
    
    this.email = this.user_information?.email;
    let codArea = this.user_information?.area_code;
    let telefono = this.user_information?.phone_number;

    if (codArea.includes('(')) {
      var cadenaTemp = codArea.split('(');
      codArea = cadenaTemp[1];
      if (codArea.includes(')')) {
        var cadenaTemp = codArea.split(')');
        codArea = cadenaTemp[0].replace('0', '');
        telefono = cadenaTemp[1];
      }
    } else {
      codArea = '';
      telefono = this.user_information?.phone_number;
    }

    this.form.patchValue({
      email: this.user_information?.email ?? "",
      area_code: Number(codArea) ?? "",
      phone_number: this.user_information?.phone_number ?? "",
      address: this.user_information?.address ?? "",
      number: this.user_information?.number ?? "",
      floor: this.user_information?.floor ?? "",
    });
  }


  private areaCodeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    

    let areaCode =Number(control.value);
    
    if(areaCode == 0 && control.value != '' ){
      return { areaCode: { value: "El codigo de area es invalido"}};
    }
  };
}