import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { LoginService } from 'app/services/login.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

    constructor(private _loginService: LoginService,
        private router: Router,) { }

    ngOnInit(): void {
        this._loginService.getAll().subscribe(resp => {
            if(!resp['isLogged'])
                this.router.navigate(['/login'])
        });
    }

    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }

    btnEstado() {
        window.location.href = environment.urlWebSitePhp + 'abm/verReintegros';
    }

    btnAltaCBU() {
        window.location.href = environment.urlWebSitePhp + 'abm/altaCbu';
    }
}
