import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import * as shape from 'd3-shape';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';
import { Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-turismo',
  templateUrl: './turismo.component.html',
  styleUrls: ['./turismo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class TurismoComponent implements OnInit {

  env = environment;

  constructor(private meta: Meta) { 
    window.location.href='https://geisatravel.com.ar/';
    const description = this.meta.getTag('name=description');
    this.meta.removeTagElement(description);
    const keywords = this.meta.getTag('name=keywords');
    this.meta.removeTagElement(keywords);
   }

  ngOnInit(): void {
  }

}
