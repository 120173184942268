<mat-card
  class="
  {{ bannerConfig.class }} 
  {{ animate ? 'fading' : '' }} 
  {{ hasBorder ? 'has-border' : '' }}
  {{ padding8 ? 'padding-8' : ''}}
  "
  *ngIf="show"
>
  <div class="banner-container-div">
    <div class="banner-content">
      <div class="banner-icon-div" id="banner-icon">
        <img src="{{ bannerConfig.svgPath }}" alt="" />
      </div>
      <div class="banner-body" id="banner-body">
        <mat-card-header *ngIf="title">{{ title }}</mat-card-header>
        <mat-card-content>
          <div>
            <div class="inner-content" [innerHTML]="innerHtmlString"></div>
            <ng-content></ng-content>
          </div>
        </mat-card-content>
      </div>
    </div>
    <div id="closing-icon" *ngIf="canClose" class="{{centerCloseIcon ? 'center-close' : ''}}">
      <div (click)="closeBanner()" class="close-icon">
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.781 13.7198C14.8507 13.7895 14.906 13.8722 14.9437 13.9632C14.9814 14.0543 15.0008 14.1519 15.0008 14.2504C15.0008 14.349 14.9814 14.4465 14.9437 14.5376C14.906 14.6286 14.8507 14.7114 14.781 14.781C14.7114 14.8507 14.6286 14.906 14.5376 14.9437C14.4465 14.9814 14.349 15.0008 14.2504 15.0008C14.1519 15.0008 14.0543 14.9814 13.9632 14.9437C13.8722 14.906 13.7895 14.8507 13.7198 14.781L7.50042 8.56073L1.28104 14.781C1.14031 14.9218 0.94944 15.0008 0.750417 15.0008C0.551394 15.0008 0.360523 14.9218 0.219792 14.781C0.0790615 14.6403 3.92322e-09 14.4494 0 14.2504C-3.92322e-09 14.0514 0.0790615 13.8605 0.219792 13.7198L6.4401 7.50042L0.219792 1.28104C0.0790615 1.14031 0 0.94944 0 0.750417C0 0.551394 0.0790615 0.360523 0.219792 0.219792C0.360523 0.0790615 0.551394 0 0.750417 0C0.94944 0 1.14031 0.0790615 1.28104 0.219792L7.50042 6.4401L13.7198 0.219792C13.8605 0.0790615 14.0514 -3.92322e-09 14.2504 0C14.4494 3.92322e-09 14.6403 0.0790615 14.781 0.219792C14.9218 0.360523 15.0008 0.551394 15.0008 0.750417C15.0008 0.94944 14.9218 1.14031 14.781 1.28104L8.56073 7.50042L14.781 13.7198Z"
            fill=""
          />
        </svg>
      </div>
    </div>
  </div>
</mat-card>
