<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>               
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_aportes.svg" class="icon">
        </div>
    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">        
        
        <div *ngIf="!isProveedorSAB" class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a routerLink="/proveedores/comprobantes">Comprobantes</a> » <a (click) = "linkCargaComprobante()">Carga</a> »<span>Detalle prestación</span>
        </div>  

        <div *ngIf="isProveedorSAB" class="breadcrumbs">
          <a (click)="linkPanel()">Panel de Inicio</a> » <a (click)="linkConsultaPrestaciones()">Consulta Prestaciones</a> » <a (click) = "linkCargaComprobante()">Carga</a> »<span>Detalle prestación</span>
        </div>       

        <h2 class="page-subtitle">Detalle de prestación</h2>

              
        <table id="datosBeneficiario">
            <tr class="odd">
                <th>Nombre del beneficiario</th>
                <td>{{ BenTNomApe }}</td>
            </tr>
            <tr class="even">
                <th>Estado</th>
                <td>{{ Estado }}</td>
            </tr>
            <tr class="odd">
                <th>Prestación</th>
                <td>{{ Prestacion }}</td>
            </tr>
            <tr class="even">
                <th>Prestador  </th>
                <td>{{ Prestador }}</td>
            </tr>
            <tr class="odd">
                <th>Período</th>
                <td>{{ DiscaExAnio }}</td>
            </tr>           
        </table>

        <div id="observaciones" fxFlex="100">
            <h4>Observaciones</h4>

            <div class="observacion">
              <p *ngFor="let obs of Observaciones">{{obs ? obs.Observacion  : ''}}<br></p>
            </div>
        </div>

        <div id="documentaciones" fxFlex="100">
            <h4>Documentación</h4>

            <p>Recordá que esta documentación es común a todas las prestaciones solicitadas, debe presentarse una sola vez por el corriente año. Para más información visualizá el <a (click)="instructivoFacturacion()">instructivo.</a></p> 
            
            <div class="documentacion">

                <table id="tablaDocumentacion" fxFlex="100">
                    
                    <tr id="titulo">
                        <th>Documentación del beneficiario a presentar</th>
                        <th>Presentado / No presentado</th>
                    </tr>              
                
                    <tr *ngFor="let documento of Documentos">
                        <td>{{documento ? documento.DocumentoDescripcion  : ''}}</td>
                        <td>{{documento && documento.Presentado == true ? 'Presentado'  : 'No Presentado'}}</td>
                    </tr>
                    <tr *ngIf="!hasDocument" id="sinResultados">
                        <td>No se encontraron resultados.</td>
                        <td></td>
                    </tr>

                </table>

            </div>
        </div>
        

        
    </div>
</div>






