import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'app/services/login.service';
import { cuitValidator } from 'app/shared/cuit-validator.directive';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MisdatosProveedorService } from 'app/services/misdatos-proveedor.service';

@Component({
  selector: 'app-adm-usuarios-proveedor',
  templateUrl: './adm-usuarios-proveedor.component.html',
  styleUrls: ['./adm-usuarios-proveedor.component.scss']
})
export class AdmUsuariosProveedorComponent implements OnInit {

  admUsuarios: FormGroup;
  consultaCUIT;

  constructor(private router: Router,
              private getSessionData: LoginService,              
              private _snackBar: MatSnackBar,
              private _MisdatosProveedorService: MisdatosProveedorService  ) { }

  cuit = new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(11), cuitValidator()]);  
  
  ngOnInit(): void {
    this.getSessionData.getAll().subscribe(resp => {
      if(!resp['isLogged'])
          this.router.navigate(['/login'])
    });

    if (environment.sessionId === 'undefined') {
        window.location.href = environment.urlWebSitePhp + 'site/logout';
        return;
    }

    this.admUsuarios = new FormGroup({
      cuit: this.cuit
    });
  }

  reload(): void{
    setTimeout(function(){
      window.location.reload();
    },3000);
  }

  consultarUsuario() {
    const cuitValue = this.cuit.value;
  
    this._MisdatosProveedorService.getMisDatosProveedor(cuitValue).subscribe(resultado => {
      if (resultado == null || resultado == 'undefined' || resultado['CUIT'] != cuitValue) {
        this._snackBar.open('CUIT no registrado.', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.admUsuarios.reset();
      } else if (resultado['CUIT'] == cuitValue) {
        this.router.navigate(['seleccionar-datos'], {
          queryParams: { cuit: cuitValue },
          state: { consultaCUIT: cuitValue }
        });
      }
    });
  }


  keyPressNumbers(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
    event.preventDefault();
    return false;
    } else {
    return true;
    }
  }

  linkPanel() {
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  } 

}
