<div class="page-layout blank p-24" fusePerfectScrollbar>
    <div class="alert alert-error in fade" *ngIf="error"><button class="close" (click)='clearError()'>×</button>{{ error }}</div>

    <mat-carousel
        timings="250ms ease-in"
        [autoplay]="true"
        interval="5000"
        color="accent"
        maxWidth="auto"
        proportion="25"
        slides="5"
        [loop]="true"
        [hideArrows]="false"
        [hideIndicators]="false"
        [useKeyboard]="true"
        [useMouseWheel]="false"
        orientation="ltr">
        <mat-carousel-slide
            #matCarouselSlide
            *ngFor="let o of carousel; let i = index"
            [image]="env.externalImagesNovedadesUrl + o.imagen_destacada"
            overlayColor="#00000040"
            [hideOverlay]="false"
        ><a class="slider-vermas" (click)="getNovedad(o.id, o.link_externo)"></a></mat-carousel-slide>
    </mat-carousel>
   

    <div id="cards" class="page-layout simple fullwidth">
        <div class="content" [ngClass]="this.view">
            <div class="cards">
                <div class="card" *ngFor="let seccion of secciones; let i= index;">
                    <div class="card-preview">
                        <div class="fuse-card">
                            <div align="center">
                            <img src="{{ env.externalImagesSeccionesUrl + seccion.imagen }}">
                            </div>
                            <div class="p-16">
                                <div class="h1">{{ seccion.titulo }}</div>
                            </div>
                            <div class="p-16 pt-0 line-height-1.75 description">
                                {{ seccion.descripcion }}
                            </div>
                            <div class="p-8 pt-0" fxLayout="column" fxLayoutAlign="button">
                                <button [ngStyle]="{ 'margin-top': i == 3 ? '4px' : '' }" mat-raised-button class="vermas" (click)="seccionDetails(seccion)">Leer más</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>