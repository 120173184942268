import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "button-secundary-blue-light-component",
  template: `
    <button
      type="button"
      [disabled]="disabled"
      (click)="onClick.emit($event)"
    >
      {{ label }}
    </button>
  `,
  styleUrls: ["./button-secundary-blue-light.component.scss"],
})
export class ButtonSecundaryBlueLightComponent {
  @Input() label: string = "Click me";
  @Input() buttonClass: string = "";
  @Input() disabled: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
}
