import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-grilla-prestaciones",
  templateUrl: "./grilla-prestaciones.component.html",
  styleUrls: ["./grilla-prestaciones.component.scss"],
})
export class GrillaPrestacionesComponent implements OnInit {
  columndefs: string[] = [
    "Prestacion",
    "Prestador",
    "Desde",
    "Hasta",
    "Sesiones",
    "Accion",
  ];

  @Input() prestaciones: any[];
  @Input() isEnviadas?: boolean;

  showTable: boolean = false;

  ngOnInit(): void {
    if (this.isEnviadas) {
      this.columndefs.splice(-2);
      this.columndefs.push(...["fechaSolicitud", "nroTramite"]);
    }

    this.showTable = true;
  }

  getDataOnStatus(p) {
    const statusData = {
      Pending: {
        textNode: "PENDIENTE DE CARGA",
        src: "assets/icons/pendingIcon.svg",
        classText: "pending",
      },
      Complete: {
        textNode: "LISTA PARA ENVIAR",
        src: "assets/icons/solicitudes-aprobadas-rechazadas/solicitud-aprobada.svg",
        classText: "complete",
      },
      Observed: {
        textNode: p.estadosgi == "PRE APROBADA" ? "PREAPROBADA" : "OBSERVADA",
        src: "assets/icons/observedIcon.svg",
        classText: "observed",
      },
    };

    return statusData[p.estado];
  }
}
