import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
})
export class BannerComponent implements OnInit {
  /**
   * designa el tipo de banner (orientados a determinar colores e iconos posibles)
   */
  @Input() type!:
    | "Info"
    | "NewFeature" //avisa sobre nuvas secciones
    | "Warning"
    | "Success"
    | "Danger" // alerta de peligro
    | "PayAttentionForm"; // aviso de la importancia de llenar un detemrinado form
  /**
   * recibe string de texto plano como string de HTML.
   * adicionalmente se puede pasar HTML como hijo del componente en la invocacion
   */
  @Input() innerHtmlString: string;
  /**
   * puede recibir un title
   */
  @Input() title: string;
  /**
   * puede mostrar boton para cerrar el banner
   */
  @Input() canClose: boolean;

  @Input() hasBorder: boolean = true;

  @Input() padding8: boolean = true;

  @Input() centerCloseIcon: boolean = true;

  //objeto de config
  bannerConfig: {
    class: string;
    svgPath: string;
  };

  show: boolean = true;
  animate: boolean = false;

  ngOnInit() {
    const data = {
      Info: {
        class: "info",
        svgPath: "", //a determinar
      },
      PayAttentionForm: {
        class: "pay-attention-form",
        svgPath: "assets/icons/banner-icons/pluma-escribir.svg",
      },
      NewFeature: {
        class: "new-feature",

        svgPath: "assets/icons/banner-icons/herramienta.svg",
      },
      Warning: {
        class: "warning",
        svgPath: "", //a determinar
      },
      Success: {
        class: "success",
        svgPath: "", // a determinar
      },
      Danger: {
        class: "danger",
        svgPath: "assets/icons/banner-icons/rombo-exclamacion.svg",
      },
    };

    this.bannerConfig = data[this.type];
  }

  closeBanner() {
    this.animate = true;
    setTimeout(() => {
      this.show = false;
    }, 700);
  }
}
