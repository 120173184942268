import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { LoginService } from "app/services/login.service";
import { BeneficiarioService } from "app/services/beneficiario.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrestacionesService } from "../services/prestaciones.service";
import { Subscription, of, throwError } from "rxjs";
import { catchError, concatMap } from "rxjs/operators";
import { AuthDialogComponent } from "app/layout/components/auth-dialog/auth-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-prestacionesindex",
  templateUrl: "./prestacionesindex.component.html",
  styleUrls: ["./prestacionesindex.component.scss"],
})
export class PrestacionesindexComponent implements OnInit {
  dataBreadcumbPages: Array<any> = [];
  dataBreadcumbTitle: any;
  getSessionDataGetSessionDataSuscription: Subscription;
  sessionId: any;
  data: any;
  cuilBenefSeleccionado: any;
  ordenBenefSeleccionado: any;
  nroBenefSeleccionado: any;
  nombreBenefSeleccionado: any;
  isDisabled: boolean = true;
  hasBenefit: boolean = true;
  prestaciones: any;
  columndefs: any = ['Prestacion', 'Prestador', 'Desde', 'Hasta', 'Sesiones', 'Accion'];
  loading: boolean = true;

  hasPrestacionesRechazadasAprobadas: boolean = false;
  hasPrestacionesEnviadas: boolean = false;
  listPrestacionesRechasadasAprobadas: any = [];
  constructor(
    private router: Router,
    private getSessionData: LoginService,
    private beneficiarioService: BeneficiarioService,
    private prestacionesService: PrestacionesService,
    private dialog: MatDialog
  ) {
    this.dataBreadcumbPages = [{ page: 'Mis Prestaciones', route: '' }];
    this.dataBreadcumbTitle = [{ page: 'Discapacidad', route: '/discapacidad' }];

    this.prestaciones = [];

  }

  prestacionesEnTransito 
//   = 
//   {
//     id: 1,
//     cuil: "27316242966",
//     rubronomencladorid: "DS",
//     titulonomencladorid: "4",
//     capitulonomencladorid: "14",
//     nomencladorid: "2",
//     cuit: "27311463425",
//     periododesde: "2023-01-01",
//     periodohasta: "2023-12-31",
//     tipogrupodescripcion: "",
//     nomencladordescripcion: "FONOAUDIOLOGÍA",
//     razonsocial: "PRADO MICALEA",
//     estado: "Approved",
//     idprestacionenviada: null,
//     cantidad: "8",
//     tipocantidad: "SESIONES",
//     created_by: "27364020185",
//     created_at: "2024-05-09T00:20:02.336-03:00",
//     secuencia: "1",
//     especialidadid: "",
//     especialidaddescripcion: "",
//     subespecialidadid: "",
//     subespecialidaddescripcion: "",
//     anio: "2023",
//     sgi: true,
//     nrobeneficiario: "380821",
//     nroorden: "0",
//     showbystatus: false,
//     estadosgi: "APROBADA",
//     observaciones: [],
//     nrotramite: "677",
//     fechasolicitud: "2024-03-24"
//   }

  ngOnInit(): void {

    const formData = new FormData();

    this.getSessionData
      .getSessionData()
      .subscribe(
      (result) => {

        if (result.session_id == null){
            this.dialog
            .open(AuthDialogComponent, {
              disableClose: true,
              panelClass: "custom-modalbox"
            })
            .afterClosed()
            .subscribe(() => (window.location.href = '/index.php/site/logout'));
        }

        this.sessionId = result.session_id;
        //console.log('Data Result:' + this.sessionId);

        formData.append('sessionId', this.sessionId);
        //console.log('Data Session:' + formData.get('sessionId'));

        this.beneficiarioService.sendForm(formData).subscribe(
          (result) => {
          this.data = result;
          },
          (error) => {
            console.log(error);   
          }
          );
      });
  }

  setBenefData(event: any) {
    this.loading = true;
    this.cuilBenefSeleccionado = this.data[event.target.value].Cuil;
    this.ordenBenefSeleccionado = this.data[event.target.value].Orden;
    this.nroBenefSeleccionado = this.data[event.target.value].NroBeneficiario;
    this.nombreBenefSeleccionado = this.data[event.target.value].NombreyApellido;
    this.isDisabled = false;
    this.isDisabled = false;


    this.prestacionesService.getAllPrestaciones(this.sessionId, this.cuilBenefSeleccionado,  this.nroBenefSeleccionado, this.ordenBenefSeleccionado).pipe(
        catchError(error => throwError(error)),
        concatMap(
            (getAllResult) => { 
                if (getAllResult.length > 0) {
                    this.hasBenefit = false;
                } else {
                    this.hasBenefit = true;
                }
                
                this.prestaciones = getAllResult;
                
                return this.prestacionesService.getPrestaciones(this.sessionId, this.cuilBenefSeleccionado, this.nroBenefSeleccionado, this.ordenBenefSeleccionado).pipe(
                    catchError(error => {
                        return of(null); 
                    })
                );
            }
        ),
        concatMap(
            (getAprobRechResult) => { 
                if (getAprobRechResult.length > 0) {
                    this.hasPrestacionesRechazadasAprobadas = true;
                    this.listPrestacionesRechasadasAprobadas = getAprobRechResult;
                } else {
                    this.hasPrestacionesRechazadasAprobadas = false;
                }
                return this.prestacionesService.getPrestacionesEnTransito(this.sessionId, this.nroBenefSeleccionado, this.ordenBenefSeleccionado).pipe(
                    catchError(error => {
                        return of(null);
                    })
                );
            }
        )
    ).subscribe((getEnTransitoResult: any) => {
        if (getEnTransitoResult.length > 0) {
            this.hasPrestacionesEnviadas = true;
            this.prestacionesEnTransito = getEnTransitoResult;
        } else {
            this.hasPrestacionesEnviadas = false;
        }

        this.loading = false;
    });
  }

  onLoad() {
    this.loading = false;
  }

  goToPage() {
    if (this.cuilBenefSeleccionado && this.ordenBenefSeleccionado) {
      this.router.navigate([`discapacidad/prestaciones/nueva-prestacion`], { queryParams: { cuil: this.cuilBenefSeleccionado, orden: this.ordenBenefSeleccionado, nroBenef: this.nroBenefSeleccionado, nombreBenef: this.nombreBenefSeleccionado, prestacion:0 } });
    }
  }

  goToPageNewBenefit() {
    if (this.cuilBenefSeleccionado && this.ordenBenefSeleccionado) {
      this.router.navigate([`discapacidad/prestaciones/nueva-prestacion`], { queryParams: { cuil: this.cuilBenefSeleccionado, orden: this.ordenBenefSeleccionado, nroBenef: this.nroBenefSeleccionado, nombreBenef: this.nombreBenefSeleccionado, prestacion:1 } });
    }
  }
}
