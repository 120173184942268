import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';

import * as shape from 'd3-shape';
import { fuseAnimations } from '@fuse/animations';
import { EjecutivoCuenta } from 'app/models/ejecutivocuenta.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { EjecutivocuentaService } from 'app/services/ejecutivocuenta.service';
import { environment } from 'environments/environment';
import { cuitValidator } from 'app/shared/cuit-validator.directive';
import { Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-ejecutivocuenta',
  templateUrl: './ejecutivocuenta.component.html',
  styleUrls: ['./ejecutivocuenta.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class EjecutivocuentaComponent implements OnInit {

  env = environment;
  formEjecutivoCuenta: FormGroup;
  ejecutivoCuenta: EjecutivoCuenta = {} as EjecutivoCuenta;

  nombre = new FormControl('', [Validators.required]);
  apellido = new FormControl('', [Validators.required]);
  // codigoarea = new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]);
  telefono = new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]);  
  email = new FormControl('', [Validators.required, Validators.email]);
  cuit = new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(11), cuitValidator()]);
  empresa = new FormControl('', [Validators.required]);
  codigopostal = new FormControl('', [Validators.required]);
  ciudad = new FormControl('', [Validators.required]);
  cuil = new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(11), cuitValidator()]);
  mensaje = new FormControl('', [Validators.required]);
  captchares: boolean = false;
  nroDocumentoMaxLength = 8;

  captchaResolved(captchaResponse: string){
    this.captchares = true;
    this.ejecutivoCuenta.captcharesponse = captchaResponse; //Agregamos el response aca para ser validado en node y no recibir cualquier solicitud de contacto
  }
  
  sendForm(): void {

    this.formEjecutivoCuenta.markAllAsTouched();
    if (!this.formEjecutivoCuenta.valid){
      this._snackBar.open('Recordá que debes completar todos los campos correctamente ', 'Cerrar', {
       duration: undefined,
       horizontalPosition: 'center',
       verticalPosition: 'top',
     });
     return; 
   }

    const formData = new FormData();
    formData.append('nombre', this.nombre.value);
    formData.append('apellido', this.apellido.value);
    // formData.append('codigoarea', this.codigoarea.value);
    formData.append('telefono', this.telefono.value);
    formData.append('email', this.email.value);
    formData.append('cuit', this.cuit.value);
    formData.append('empresa', this.empresa.value);
    formData.append('codigopostal', this.codigopostal.value);
    formData.append('ciudad', this.ciudad.value);
    formData.append('cuil', this.cuil.value);
    formData.append('mensaje', this.mensaje.value);
    formData.append('captcharesponse', this.ejecutivoCuenta.captcharesponse);

    this._ejecutivoCuentaService.sendForm(formData)
      .subscribe(resp => {
        this._snackBar.open('El formulario se ha enviado con exito.', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        
        this.formEjecutivoCuenta.reset();
    },
    err => {
      this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });
  }


  constructor(private _snackBar: MatSnackBar,
    private _ejecutivoCuentaService: EjecutivocuentaService,
    private meta: Meta) { 
      const description = this.meta.getTag('name=description');
      this.meta.removeTagElement(description);
      const keywords = this.meta.getTag('name=keywords');
      this.meta.removeTagElement(keywords);
    }

  ngOnInit(): void {
    this.formEjecutivoCuenta = new FormGroup({
      nombre: this.nombre,
      apellido: this.apellido,
      // codigoarea: this.codigoarea,
      telefono: this.telefono,
      email: this.email,
      cuit: this.cuit,
      empresa: this.empresa,
      codigopostal: this.codigopostal,
      ciudad: this.ciudad,
      cuil: this.cuil,
      mensaje: this.mensaje,
    });
  }

}