import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetallePrestacionComponent } from './detalle-prestacion.component';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';


const routes = [
  {
      path     : 'proveedores/comprobantes/carga/detalle',
      component: DetallePrestacionComponent
  }
];

@NgModule({
  declarations: [
    DetallePrestacionComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FlexLayoutModule
    
  ]
})
export class DetallePrestacionModule { }
