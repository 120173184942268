import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import * as shape from 'd3-shape';
import { fuseAnimations } from '@fuse/animations';

import { FilialesService } from 'app/services/filiales.service';
import { Observable } from 'rxjs';
import { Filial } from 'app/models/filial.model';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-filiales',
  templateUrl: './filiales.component.html',
  styleUrls: ['./filiales.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class FilialesComponent implements OnInit {

  listFiliales$: Observable<Filial[]>;

  constructor(private _filialesService: FilialesService,
              private meta: Meta) { 
                const description = this.meta.getTag('name=description');
                this.meta.removeTagElement(description);
                const keywords = this.meta.getTag('name=keywords');
                this.meta.removeTagElement(keywords);
              }

  ngOnInit(): void {
    this.listFiliales$ = this._filialesService.getAll();
  }
}
