<div class="page-layout blank p-24" fusePerfectScrollbar>

    <div class="breadcrumbs">
        <a href="/">Inicio</a> » <a [routerLink]="['/planes']">PLANES</a> » <span>PLAN ECO PLUS</span>
    </div>

    <h1 class="page-title">Plan ECO PLUS</h1>
    <h4>Junto a las redes prestadoras más importantes del país, ofrecemos una propuesta adaptada a tus necesidades, asegurando la calidad de las prestaciones médico asistenciales.</h4>

    <ul>
        <li>Alta complejidad</li>
        <li>Trasplantes</li>
        <li>Prótesis</li>
        <li>Diabetes</li>
        <li>Medicamentos oncológicos y especiales</li>
        <li>Salud mental</li>
        <li>Plan Materno Infantil (PMI)</li>
        <li><a href="https://storageasemarketing01.blob.core.windows.net/asenacional/SAB/Red-de-opticas-Ase-Nacional.pdf" target="_blank">Óptica</a></li>
        <li>Obsequios por nacimiento y casamiento</li>
        <li>Beneficios y turismo</li>
    </ul>

    <p><b>Servicios PLUS</b></p>

    <ul>
        <li>Descuentos en cursos, carreras de grado, maestrías, posgrados y programas ejecutivos</li>
        <li>Beneficios para tu bebé</li>
        <li>Descuentos en chequeos preventivos de salud en el Sanatorio Finochietto</li>
        <li>Asistencia al viajero y medicina pre-viaje</li>
        <li>Reintegros en gastos ortopédicos, ortodoncia y sesiones de kinesiología a domicilio</li>
        <li>Cobertura por pérdida o rotura de cristales o lentes</li>
        <li>Asistencia de personal especializado</li>       
        <li><a [routerLink]="['/planes/beneficios-planes-plus']">Conocé más</a></li>
    </ul>

</div>    
