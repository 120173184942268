<div class="breadcrumb">
  <h1 *ngIf="isDashboard" class="title"><a (click)="linkPanel()"> Panel De Inicio</a></h1>
  <h1 *ngIf="!isDashboard" class="title"><a (click)="linkPanel()">Inicio</a></h1>
  <span class="caret">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
      <path
        d="M13.2806 12.531L5.78055 20.031C5.63982 20.1718 5.44895 20.2508 5.24993 20.2508C5.05091 20.2508 4.86003 20.1718 4.7193 20.031C4.57857 19.8903 4.49951 19.6994 4.49951 19.5004C4.49951 19.3014 4.57857 19.1105 4.7193 18.9698L11.6896 12.0004L4.7193 5.03104C4.57857 4.89031 4.49951 4.69944 4.49951 4.50042C4.49951 4.30139 4.57857 4.11052 4.7193 3.96979C4.86003 3.82906 5.05091 3.75 5.24993 3.75C5.44895 3.75 5.63982 3.82906 5.78055 3.96979L13.2806 11.4698C13.3503 11.5394 13.4056 11.6222 13.4433 11.7132C13.4811 11.8043 13.5005 11.9019 13.5005 12.0004C13.5005 12.099 13.4811 12.1966 13.4433 12.2876C13.4056 12.3787 13.3503 12.4614 13.2806 12.531ZM20.7806 11.4698L13.2806 3.96979C13.1398 3.82906 12.949 3.75 12.7499 3.75C12.5509 3.75 12.36 3.82906 12.2193 3.96979C12.0786 4.11052 11.9995 4.30139 11.9995 4.50042C11.9995 4.69944 12.0786 4.89031 12.2193 5.03104L19.1896 12.0004L12.2193 18.9698C12.0786 19.1105 11.9995 19.3014 11.9995 19.5004C11.9995 19.6994 12.0786 19.8903 12.2193 20.031C12.36 20.1718 12.5509 20.2508 12.7499 20.2508C12.949 20.2508 13.1398 20.1718 13.2806 20.031L20.7806 12.531C20.8503 12.4614 20.9056 12.3787 20.9433 12.2876C20.9811 12.1966 21.0005 12.099 21.0005 12.0004C21.0005 11.9019 20.9811 11.8043 20.9433 11.7132C20.9056 11.6222 20.8503 11.5394 20.7806 11.4698Z"
        fill="black" />
    </svg>
  </span>
  <ng-container *ngFor="let t of title;">
    <h1 class="title"><a [routerLink]="[t.route]"> {{ t.page }}</a></h1>
    <span class="caret" *ngIf="t.page">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
        <path
          d="M13.2806 12.531L5.78055 20.031C5.63982 20.1718 5.44895 20.2508 5.24993 20.2508C5.05091 20.2508 4.86003 20.1718 4.7193 20.031C4.57857 19.8903 4.49951 19.6994 4.49951 19.5004C4.49951 19.3014 4.57857 19.1105 4.7193 18.9698L11.6896 12.0004L4.7193 5.03104C4.57857 4.89031 4.49951 4.69944 4.49951 4.50042C4.49951 4.30139 4.57857 4.11052 4.7193 3.96979C4.86003 3.82906 5.05091 3.75 5.24993 3.75C5.44895 3.75 5.63982 3.82906 5.78055 3.96979L13.2806 11.4698C13.3503 11.5394 13.4056 11.6222 13.4433 11.7132C13.4811 11.8043 13.5005 11.9019 13.5005 12.0004C13.5005 12.099 13.4811 12.1966 13.4433 12.2876C13.4056 12.3787 13.3503 12.4614 13.2806 12.531ZM20.7806 11.4698L13.2806 3.96979C13.1398 3.82906 12.949 3.75 12.7499 3.75C12.5509 3.75 12.36 3.82906 12.2193 3.96979C12.0786 4.11052 11.9995 4.30139 11.9995 4.50042C11.9995 4.69944 12.0786 4.89031 12.2193 5.03104L19.1896 12.0004L12.2193 18.9698C12.0786 19.1105 11.9995 19.3014 11.9995 19.5004C11.9995 19.6994 12.0786 19.8903 12.2193 20.031C12.36 20.1718 12.5509 20.2508 12.7499 20.2508C12.949 20.2508 13.1398 20.1718 13.2806 20.031L20.7806 12.531C20.8503 12.4614 20.9056 12.3787 20.9433 12.2876C20.9811 12.1966 21.0005 12.099 21.0005 12.0004C21.0005 11.9019 20.9811 11.8043 20.9433 11.7132C20.9056 11.6222 20.8503 11.5394 20.7806 11.4698Z"
          fill="black" />
      </svg>
    </span>
  </ng-container>
  <ng-container *ngFor="let d of description;">
    <span class="description">{{ d.page }}</span>
  </ng-container>
</div>