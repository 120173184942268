import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MarcarComprobanteService {

  constructor(private http: HttpClient) { }

  //API Y BACK PARA GUARDAR
  sendMark(form: FormData,session:any):Observable<any>{
    return this.http.post(`${environment.urlApi}liquidador/sendMark?sessionId=${session}`, form);
  }

}
