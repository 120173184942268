<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_aportes.svg" class="icon">
        </div>
    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">

        <div class="breadcrumbs">
            <a routerLink="/proveedores/comprobantes/carga-admin">Carga</a> » <span>Formulario</span>
        </div>

        <h2 class="page-subtitle">Carga de Comprobantes</h2>
        <div fxFlex="100" fxLayout="row" fxLayout.lt-md="column">

            <div fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlexOrder.lt-md="2" id="boxForm">
                <p>Por favor completa el siguiente formulario</p>
                <p>Los campos con * son obligatorios</p>

                <form [formGroup]="formCarga" enctype="multipart/form-data">

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth" fxFlex="100">
                            <input matInput placeholder="CUIT" formControlName="cuit" required type="number" maxlength="11"
                            onKeyPress="if(this.value.length==11) return false;">
                            <mat-error *ngIf="formCarga.get('cuit').invalid || formCarga.get('cuit').hasError('pattern')">Debe ingresar los 11 dígitos del CUIT
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth">
                            <mat-label>Obra social</mat-label>
                            <mat-select #os formControlName="obraSocial" id="obraSocial" required
                                (selectionChange)="os.value == 'OSEN'">
                                <mat-option>Seleccione Obra Social</mat-option>
                                <mat-option value="ASE">ASE</mat-option>
                                <mat-option value="OSEN">OSEN</mat-option>
                                <mat-option value="OSPM">OSPM</mat-option>
                            </mat-select>
                            <mat-error *ngIf="formCarga.get('obraSocial').invalid">Debe seleccionar Obra Social
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth">
                            <mat-label>Tipo de trámite</mat-label>
                            <mat-select formControlName="tipoTramite" required>
                                <mat-option>Seleccione Tipo de trámite</mat-option>
                                <mat-option value="FA">Facturación</mat-option>
                                <mat-option value="RF">Re-Facturación</mat-option>
                                <mat-option value="NC">Nota de Crédito</mat-option>
                            </mat-select>
                            <mat-error *ngIf="formCarga.get('tipoTramite').invalid">Debe seleccionar Tipo de trámite
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth">
                            <mat-label>Tipo de comprobante</mat-label>
                            <mat-select #tipoComprobante formControlName="tipoComprobante" required
                                (selectionChange)="tipoComprobante.value == 'NC'">
                                <mat-option>Seleccione Tipo de comprobante</mat-option>
                                <mat-option value="FC">Factura</mat-option>
                                <mat-option value="FE">Factura de Crédito MiPyme</mat-option>
                                <mat-option value="RC">Recibo</mat-option>
                                <mat-option value="NC">Nota de Crédito</mat-option>
                            </mat-select>
                            <mat-error *ngIf="formCarga.get('tipoComprobante').invalid">Debe seleccionar Tipo de
                                comprobante</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth">
                            <mat-label>Serie</mat-label>
                            <mat-select formControlName="tipoFactura" required>
                                <mat-option>Seleccione Serie</mat-option>
                                <mat-option *ngFor="let serie of tipoSeries" [value]="serie.serie">
                                    {{serie.serie}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formCarga.get('tipoFactura').invalid">Debe seleccionar la
                                serie</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth" fxFlex="40">
                            <mat-label>Punto de venta</mat-label>
                            <input matInput formControlName="puntoVenta" (focusout)="puntoVentaAddCharacter();"
                                (keypress)="keyPressNumbers($event)" maxlength="4" required type="text"
                                placeholder="1234">
                            <!-- onKeyPress="if(this.value.length==4) return false;" (keypress)="keyPressNumbers($event)"-->
                            <mat-error
                                *ngIf="formCarga.get('puntoVenta').invalid && formCarga.get('puntoVenta').hasError('pattern')">Debe
                                ingresar los 4 dígitos del
                                Punto de venta.</mat-error>
                        </mat-form-field>

                        <mat-form-field class="mat-fullwidth" fxFlex="60">
                            <mat-label>Comprobante</mat-label>
                            <input matInput formControlName="comprobante" required type="text" maxlength="8"
                                placeholder="12345678" (focusout)="comprobanteAddCharacter();"
                                (keypress)="keyPressNumbers($event)">
                            <!-- onKeyPress="if(this.value.length==8) return false;" -->
                            <mat-error
                                *ngIf="formCarga.get('comprobante').invalid  && formCarga.get('comprobante').hasError('pattern')">Debe
                                ingresar los 8 dígitos del
                                comprobante.</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth" fxFlex="100">
                            <mat-label>Fecha de emisión del comprobante</mat-label>
                            <input matInput [matDatepicker]="fechaEmisionComprobante"
                                formControlName="fechaEmisionComprobante" required readonly="readonly"
                                [min]="cuatroMeses" [max]="mesAnterior">
                            <mat-datepicker-toggle matSuffix [for]="fechaEmisionComprobante"></mat-datepicker-toggle>
                            <mat-datepicker #fechaEmisionComprobante></mat-datepicker>
                            <mat-error *ngIf="formCarga.get('fechaEmisionComprobante').invalid">Debe seleccionar la
                                fecha emisión del comprobante.</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth" fxFlex="100">
                            <mat-label>Importe total</mat-label>
                            <input matInput formControlName="importeTotal" required type="number" min="0"
                                onKeyPress="if(this.value.length==13) return false;">
                            <mat-error *ngIf="formCarga.get('importeTotal').hasError('pattern')">Debe ingresar un
                                importe válido. Máximo 2 decimales.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <p id="textoImporte">No se aceptan comprobantes con IVA</p>

                    <h4>DATOS FISCALES</h4>
                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth" fxFlex="100">
                            <mat-label>Código de autorización (CAE)</mat-label>
                            <input matInput formControlName="codAutCAE" required type="number"
                                onKeyPress="if(this.value.length==14) return false;">
                            <mat-error
                                *ngIf="formCarga.get('codAutCAE').invalid || formCarga.get('codAutCAE').hasError('pattern')">Debe
                                ingresar los 14 dígitos del
                                Código de autorización CAE.</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field class="mat-fullwidth">
                            <mat-label>Tipo CAE</mat-label>
                            <mat-select formControlName="tipoCAE" [(ngModel)]="tipoCAEselected">

                                <mat-option value="CAE">CAE</mat-option>
                                <mat-option value="CAEA">CAEA</mat-option>
                                <mat-option value="CAI">CAI</mat-option>
                                <mat-option value="CF">CF</mat-option>

                            </mat-select>

                        </mat-form-field>
                    </div>

                </form>
            </div>
        </div>
        <div fxLayout="row">
            <div>
                <button type="button" mat-raised-button id="btnEnviar" (click)="validateAfip()">Validar AFIP</button>
            </div>
            <div>
                <button type="button" mat-raised-button id="btnEnviar" (click)="clear()">Limpiar formulario</button>
            </div>
        </div>
    </div>
</div>