import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
//modulos angular material
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

//recaptcha
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

//componente
import { RegistroProveedorComponent } from './registro-proveedor.component';
// modulos del template
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseHighlightModule } from '@fuse/components';

const routes = [
  {
      path     : 'registro-proveedor',
      component: RegistroProveedorComponent
  }
];

@NgModule({
  declarations: [
    RegistroProveedorComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    FuseHighlightModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatSelectModule
  ]
})
export class RegistroProveedorModule { }
