import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'tildes',
  pure: false
})
export class TildesPipe implements PipeTransform {

  transform(value: string): string {
    value = value.replace("Ã¡", "á");
    value = value.replace("Ã©", "é");    
    value = value.replace("Ã­", "í");   
    value = value.replace("Ã³", "ó");
    value = value.replace("Ãº", "ú");
    value = value.replace("Ã±", "ñ");
    
    return value;
  }

}
