import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FilialesService } from 'app/services/filiales.service';
import { MisdatosService } from 'app/services/misdatos.service';
import { SolicitarRolProveedorService } from 'app/services/solicitar-rol-proveedor.service';

@Component({
    selector: 'app-solicitar-rol-proveedor',
    templateUrl: './solicitar-rol-proveedor.component.html',
    styleUrls: ['./solicitar-rol-proveedor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class SolicitarRolProveedorComponent implements OnInit {

    filial = new FormControl('', Validators.required);
    filialesList: any;
    email: any;
    msjRolProveedor = true

    constructor(private _snackBar: MatSnackBar,
        private router: Router,
        private _solicitarRolProveedorService: SolicitarRolProveedorService,
        private _misdatos: MisdatosService,) { }

    ngOnInit(): void {
        this.filialesList = ["BS.AS.", "CAPITAL", "CATAMARCA", "CHACO", "CHUBUT",
            "CORDOBA", "CORRIENTES", "ENTRE RIOS", "FORMOSA", "JUJUY", "LA PAMPA",
            "LA RIOJA", "MENDOZA", "MISIONES", "NEUQUEN", "RIO NEGRO", "SALTA",
            "SAN JUAN", "SAN LUIS", "SANTA CRUZ", "SANTA FE", "SGO. DEL ESTERO",
            "T. DEL FUEGO A.I.A.S.", "TUCUMAN"];
    }

    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }

    btnEnviar() {

        this.filial.markAllAsTouched();
        if (!this.filial.valid) {
            return;
        }

        const formGaci = new FormData();
        formGaci.append('cuit', environment.cuil);

        this._misdatos.getMisDatos().subscribe(resultado => {
            this.email = resultado.email
        });

        // VALIDAR GACI
        this._solicitarRolProveedorService.validarGaci(formGaci).subscribe(response => {
            if (response.info == 'Miembro GACI') {
                // GENERAR REGISTRO
                const formSolicitudRol = new FormData();
                formSolicitudRol.append('cuit', environment.cuil);
                formSolicitudRol.append('email', this.email);
                formSolicitudRol.append('filial', this.filial.value);

                this._solicitarRolProveedorService.crearSoliciturRolProveedor(formSolicitudRol).subscribe(response => {
                    if (response.error == 0)
                        this._solicitarRolProveedorService.msjRolProveedor.emit({
                            data: this.msjRolProveedor
                        })
                    this.router.navigate(['misdatos']);
                }, err => {
                    this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                });
            }
        }, err => {
            if (err.error['message'] == 'Debe registrar proveedor en GACI')
                this.router.navigate(['proveedores/alta-proveedor'], { state: { filial: this.filial.value, email: this.email, cuit: environment.cuil } });
            else
                this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
                    duration: undefined,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                });
        });
    }
}
