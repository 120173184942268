<div class="container-main">
  <div class="container-information">
    <div class="container-icon-subtitle">
      <span> </span>
    </div>
    <h2>La sesión ha caducado</h2>
    <p>
      El tiempo de la sesión actual ha finalizado. Por favor inicie sesión
      nuevamente.
    </p>
  </div>

  <div class="container-buttons">
    <button class="orange-btn" (click)="closeDialog()">Aceptar</button>
  </div>
</div>
