import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileValidator, FileInput } from 'ngx-material-file-input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MedicamentosService } from '../../../../services/medicamentos.service';
import { Router } from '@angular/router';
import { LoginService } from 'app/services/login.service';
import { MensajesHtmlComponent } from '../../mensajes-html/mensajes-html.component';

@Component({
  selector: 'app-solicitudMedicamentos',
  templateUrl: './solicitudmedicamentos.component.html',
  styleUrls: ['./solicitudmedicamentos.component.scss']
})
export class SolicitudMedicamentosComponent implements OnInit {

  linkPanel(){
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  }

  pdfDocValidator(control: FormControl) {
    if(!control.value)
      return;
    const type = control.value.files[0].type;
    if (type && 
      type != 'image/jpeg' &&
      type != 'image/png' &&
      type != 'image/gif' &&
      type != 'image/x-ms-bmp' &&      
      type != 'image/bmp' &&
      type != 'application/pdf' &&
      type != 'application/msword'&&
      type != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
      type != 'application/vnd.ms-excel' &&
      type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
        return {
          mimeType: {
            type: type
        }
      }
    }
    return null;
  }
  
  readonly maxSize = 3145728;

  formSolicitud: FormGroup;
  observaciones = new FormControl('', );
  adjunto1 = new FormControl('', [Validators.required, FileValidator.maxContentSize(this.maxSize), this.pdfDocValidator]);
  adjunto2 = new FormControl('', [ FileValidator.maxContentSize(this.maxSize), this.pdfDocValidator]);
  
  formSent : boolean = false;
  reload(): void{
    setTimeout(function(){
      window.location.reload();
    },3000);
  }

  sendForm(): void {

    this.formSolicitud.markAllAsTouched();
    if (!this.formSolicitud.valid){
      this._snackBar.open('Recordá que debés completar todos los campos correctamente ', 'Cerrar', {
       duration: undefined,
       horizontalPosition: 'center',
       verticalPosition: 'top',
     });
     return; 
   } 

    this.formSent = true;
    const formData = new FormData();
    formData.append('observaciones', this.observaciones.value);
    
    const adj1_form: FileInput = this.adjunto1.value;
    if(adj1_form){
    const adj1 = adj1_form.files[0];
    formData.append('adjunto1', adj1);
    }


    const adj2_form: FileInput = this.adjunto2.value;
    if(adj2_form){
    const adj2 = adj2_form.files[0];   
    formData.append('adjunto2', adj2);
    }

    this._medicamentosService.sendForm(formData)
      .subscribe(resp => {
      this.formSolicitud.markAllAsTouched();
        // this._snackBar.open('Tu número de gestión es ' + resp.body + '. Podrás visualizarla en la opción Estado de solicitudes de medicamentos e insumos en el plazo de 72hs hábiles', 'Cerrar', {
        //   duration: undefined,
        //   horizontalPosition: 'center',
        //   verticalPosition: 'top',
        //   panelClass: 'customStyle'
        // });

        this._snackBar.openFromComponent(MensajesHtmlComponent, {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: 'customStyle',
          data: ['medicamentos',resp.body]
      });
        // this.reload();
    },
    err => {
    this.formSent = false;
      this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });    
  }

  constructor(
    private _snackBar: MatSnackBar,    
    private _medicamentosService: MedicamentosService,
    private _loginService: LoginService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this._loginService.getAll().subscribe(resp => {
        if(!resp['isLogged'])
             this.router.navigate(['/login'])
     });

    this.formSolicitud = new FormGroup({
      observaciones: this.observaciones,      
      adjunto1: this.adjunto1,
      adjunto2: this.adjunto2,
      
      })
  }
}
