import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-eliminar-prestacion',
  templateUrl: './modal-eliminar-prestacion.component.html',
  styleUrls: ['./modal-eliminar-prestacion.component.scss']
})
export class ModalEliminarPrestacionComponent implements OnInit {

  constructor(public dialog: MatDialogRef<ModalEliminarPrestacionComponent>,
    @Inject(MAT_DIALOG_DATA) public mensaje: string) { }
    
    isDisabled: boolean = false;

    cerrarDialogo(): void {
      this.dialog.close(false);
    }
    confirmado(): void {
      this.dialog.close(true);
    }    

  ngOnInit(): void {
  }

}
