import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-mensajes-html',
  templateUrl: './mensajes-html.component.html',
  styleUrls: ['./mensajes-html.component.scss']
})
export class MensajesHtmlComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
  public snackBar: MatSnackBar,
  private _snackRef: MatSnackBarRef<any>,
  private clipboard: Clipboard) { }

  ngOnInit(): void {
  }

  dismiss(){
    this._snackRef.dismiss();
  }

  copyText(value: string): void {
    console.log(value);
    const successful = this.clipboard.copy(value);
}

}
