import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Rol } from 'app/models/rol.model';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(
    private http: HttpClient
  ) { }

  getAll(cuil): Observable<Rol[]>{
    return this.http.get<Rol[]>(environment.urlApi + 'rolSelect/getUserRoles?cuil=' + cuil);
  }
}
