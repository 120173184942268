<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>               
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_altausuarios.svg" class="icon">
        </div>
    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">        
        
        <div class="breadcrumbs">
            <a href="/">Inicio</a> » <span>Registro Proveedor</span>
        </div>

        <h2 class="page-subtitle">Registro Proveedor</h2>

        <p id="page-subtitle2">Por favor, completá el siguiente formulario.</p>  
        <p id="obligatoriedad">Los campos con * son obligatorios</p>      

            <form action="" method="post" novalidate [formGroup]="formRegistro">

                <div fxLayout="row">
                    <mat-form-field class="mat-fullwidth" fxFlex="50" fxFlex.lt-md="100">
                        <mat-label>CUIT</mat-label>
                        <input matInput type="number" placeholder="Ejemplo 37508887002" formControlName="CUIT" maxlength="11" min="0" required oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)">
                        <mat-error *ngIf="formRegistro.get('CUIT').invalid ">Debe ingresar el CUIT.</mat-error>                        
                        <mat-error *ngIf="formRegistro.get('CUIT').invalid && formRegistro.get('CUIT').hasError('pattern')">Ingrese un CUIT válido.</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row">
                    <mat-form-field class="mat-fullwidth" fxFlex="50" fxFlex.lt-md="100">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" required>
                        <mat-error *ngIf="formRegistro.get('email').invalid && formRegistro.get('email').hasError('required')">Debe ingresar su email.
                        </mat-error>
                        <mat-error *ngIf="formRegistro.get('email').invalid && formRegistro.get('email').hasError('pattern')">Ingrese un email válido.</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row">
                    <mat-form-field class="mat-fullwidth" fxFlex="50" fxFlex.lt-md="100">
                        <mat-label>Reingresar Email</mat-label>
                        <input matInput formControlName="email2" required>
                        <mat-error *ngIf="formRegistro.get('email2').invalid && formRegistro.get('email2').hasError('required')">Debe reingresar su email.</mat-error>
                        <mat-error *ngIf="formRegistro.get('email2').invalid && formRegistro.get('email2').hasError('pattern')">Ingrese un email válido.</mat-error>
                        <mat-error *ngIf="formRegistro.hasError('emailsNoCoinciden')">Los emails no coinciden.</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" style="margin-top:10px">
                    <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                        <mat-label>Elegí tu provincia de acuerdo con tu domicilio fiscal</mat-label>
                            <mat-select name="provincia" formControlName="provincia" required>
                            <mat-option>Seleccione una provincia</mat-option>
                            <mat-option *ngFor="let option of provincias" [value]="option">
                                {{option | tildes}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formRegistro.get('provincia').invalid && formRegistro.get('provincia').hasError('required') ">Selecciona provincia.</mat-error>  
                    </mat-form-field>
                </div>
                                                           
                <div>
                    <re-captcha (resolved)="captchaResolved($event)" siteKey="6Lci2cUZAAAAAKfhe3JqVTih6eDVsgbbSz_fT50X">
                    </re-captcha> 
                </div>
                <div>
                    <button mat-raised-button type="button" id="btnCrear" (click)="btnCrear()">Crear</button>
                    <button mat-raised-button type="button" id="btnCancelar"  (click)="btnCancelar()">Cancelar</button>
                </div>
                
            </form>
    </div>
</div>


