import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { MisdatosService } from 'app/services/misdatos.service';
import { FilialesService } from 'app/services/filiales.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoginService } from 'app/services/login.service';
import { MisdatosProveedorService } from 'app/services/misdatos-proveedor.service';


@Component({
    selector: 'app-actualizar-mis-datos',
    templateUrl: './actualizar-mis-datos.component.html',
    styleUrls: ['./actualizar-mis-datos.component.scss']
})
export class ActualizarMisDatosComponent implements OnInit {

    formActualizarDatos: FormGroup;
    email = new FormControl('', [Validators.pattern('^[^@]+@[^@]+\.[a-zA-Z]{2,}$')]);
    telefono = new FormControl('');
    domicilioProfesional = new FormControl('');
    filial = new FormControl('');
    cbu = new FormControl('');
    condicionImpositiva = new FormControl('');
    especialidades = new FormControl('');
    matricula = new FormControl('');
    categoria = new FormControl('');
    listFiliales = [];
    cbuCargado;
    msjActualizarDatosProveedor;
    prevData
    constructor(private _misdatosService: MisdatosService,
        private router: Router,
        private _snackBar: MatSnackBar,
        private _loginService: LoginService,
        private getSessionData: LoginService,
        private MisdatosProveedorService: MisdatosProveedorService) { }

    ngOnInit(): void {

        this.getSessionData.getAll().subscribe(resp => {
            if (!resp['isLogged'])
                this.router.navigate(['/login'])
        });

        // consulto todas las filiales
        this.listFiliales = ["BS.AS.", "CAPITAL", "CATAMARCA", "CHACO", "CHUBUT",
            "CORDOBA", "CORRIENTES", "ENTRE RIOS", "FORMOSA", "JUJUY", "LA PAMPA",
            "LA RIOJA", "MENDOZA", "MISIONES", "NEUQUEN", "RIO NEGRO", "SALTA",
            "SAN JUAN", "SAN LUIS", "SANTA CRUZ", "SANTA FE", "SGO. DEL ESTERO",
            "T. DEL FUEGO A.I.A.S.", "TUCUMAN"];


        this.getSessionData.getSessionData().subscribe(result => {
            this.MisdatosProveedorService.getMisDatosProveedor(result.cuit).subscribe(rsp => {
                this.prevData = {
                    Email: rsp.Email,
                    Telefono: rsp.Telefono,
                    DomicilioProfesional: rsp.Ddomicilio,
                    CondicionImpositiva: rsp.CondicionImpositiva,
                    Categoria: rsp.Categoria,
                    Filial: rsp.Filial
                };

                let copyCBU = rsp.CBU;
                let ultimosCuatro = copyCBU.slice(-4);
                let CBU4 = '******************' + ultimosCuatro;
                
                var esp = ''
                if (rsp.Especialidad && rsp.Especialidad.EspecialidadDescripcion)
                    esp = rsp.Especialidad.EspecialidadDescripcion
                this.prevData.Especialidad = esp;

                var matr = '';
                if (rsp.Matricula && rsp.Matricula.Matricula)
                    matr = rsp.Matricula.Matricula;

                this.email.setValue(rsp.Email);
                this.telefono.setValue(rsp.Telefono);
                this.cbu.setValue(CBU4);
                this.domicilioProfesional.setValue(rsp.Ddomicilio);
                this.condicionImpositiva.setValue(rsp.CondicionImpositiva);
                this.especialidades.setValue(esp);
                this.matricula.setValue(matr);
                this.categoria.setValue(rsp.Categoria);
                this.filial.setValue(rsp.Filial)
                if (rsp.CBU == '' || rsp.CBU == undefined) {
                    this._snackBar.open('El CBU que declares debe ser bancario y tiene que estar a nombre del profesional que factura. No debe ser de terceros.', 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                };
            });
            err => console.log(err);
        });


        this.formActualizarDatos = new FormGroup({
            email: this.email,
            telefono: this.telefono,
            domicilioProfesional: this.domicilioProfesional,
            filial: this.filial,
            cbu: this.cbu,
            condicionImpositiva: this.condicionImpositiva,
            especialidades: this.especialidades,
            matricula: this.matricula,
            categoria: this.categoria
        });

    }

    sendForm() {

        //si filial esta vacia pero el resto no
        if (this.filial.value == '' &&
            this.email.value != '' ||
            this.telefono.value != '' ||
            this.domicilioProfesional.value != '' ||
            this.condicionImpositiva.value != '' ||
            this.matricula.value != '' ||
            this.categoria.value != '') {
            this.msjActualizarDatosProveedor = 'resto';
        }

        // si filial no esta vacio y el resto si
        if (this.filial.value != '' || this.email.value != '' ||
            this.telefono.value != '' ||
            this.domicilioProfesional.value != '' ||
            this.condicionImpositiva.value != '' ||
            this.matricula.value != '' ||
            this.categoria.value != '') {
            this.msjActualizarDatosProveedor = 'filial';
        }

        //si filial no esta vacia y algun campo del resto tampoco
        if (this.filial.value != '' && this.email.value != '' ||
            this.telefono.value != '' ||
            this.domicilioProfesional.value != '' ||
            this.condicionImpositiva.value != '' ||
            this.matricula.value != '' ||
            this.categoria.value != '') {
            this.msjActualizarDatosProveedor = 'todos';
        }

        const formData = new FormData();

        // formData.append('sessionId', environment.sessionId);
        // formData.append('userId', environment.userId);
        if ((this.prevData.Email == this.email.value || (!this.prevData.email && !this.email.value)) &&          
            (this.prevData.Telefono == this.telefono.value || (!this.prevData.Telefono && !this.telefono.value)) &&
            (this.prevData.DomicilioProfesional == this.domicilioProfesional.value || (!this.prevData.DomicilioProfesional && !this.prevData.DomicilioProfesional)) &&
            (this.prevData.Filial == this.filial.value || (!this.prevData.Filial && !this.filial.value)) &&
            (this.prevData.CondicionImpositiva == this.condicionImpositiva.value || (!this.prevData.CondicionImpositiva && !this.condicionImpositiva.value)) &&
            (this.prevData.Matricula == this.matricula.value || (!this.prevData.Matricula && !this.matricula.value)) &&
            (this.prevData.Categoria == this.categoria.value || (!this.prevData.Categoria && !this.categoria.value)) &&
            (this.prevData.Especialidad == this.especialidades.value || (!this.prevData.Especialidad && !this.especialidades.value))
            ){
                this._snackBar.open('Ningún dato fue modificado.', 'Cerrar', {
                    duration: undefined,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                });
                return;
            }
        
        var email = this.email.value;
        formData.append('email', email);
        formData.append('telefono', this.telefono.value);
        formData.append('domicilioProfesional', this.domicilioProfesional.value);
        formData.append('filial', this.filial.value);
        formData.append('condicionImpositiva', this.condicionImpositiva.value);
        formData.append('matricula', this.matricula.value);
        formData.append('categoria', this.categoria.value);
        formData.append('especialidades', this.especialidades.value);

        const formData2 = new FormData();
        formData2.append('email', email);
        this._loginService.getSessionData().subscribe(result => {

            if (this.prevData.Email != email) {
                console.log(this.prevData.Email + ' ? ' + email);
                this.MisdatosProveedorService.updateEmailProveedor(result['cuit'], formData2).subscribe(rsp => {
                    this._snackBar.open('Su solicitud está siendo procesada. En el transcurso de las 72 hs podrá ver sus datos actualizados.', 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                });
            }

            this.MisdatosProveedorService.updateDatosProveedor(result['cuit'], formData)
                .subscribe(resp => {
                    this._misdatosService.msjActualizarDatosProveedor.emit({
                        data: this.msjActualizarDatosProveedor
                    })
                    this._snackBar.open('Su solicitud está siendo procesada. En el transcurso de las 72 hs podrá ver sus datos actualizados.', 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                },
                    err => {
                        this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
                            duration: undefined,
                            horizontalPosition: 'center',
                            verticalPosition: 'top',
                        });
                    });
            this.router.navigate(['proveedores/mis-datos']);
        });

    }


    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }
}
