import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule} from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDemoModule } from '@fuse/components/demo/demo.module';
import { FuseHighlightModule } from '@fuse/components';

import { PlanesComponent } from './planes.component';
import { EcoComponent } from './eco/eco.component';
import { EcoPlusComponent } from './eco-plus/eco-plus.component';
import { VisionComponent } from './vision/vision.component';
import { VisionPlusComponent } from './vision-plus/vision-plus.component';
import { EcoPlusMasComponent } from './eco-plus-mas/eco-plus-mas.component';
import { VisionPlusMasComponent } from './vision-plus-mas/vision-plus-mas.component';

const routes = [
    {
        path     : 'planes',
        component: PlanesComponent
    },
    {   path     : 'planes/eco',
        component: EcoComponent
    },    
    {   path     : 'planes/eco-plus',
        component: EcoPlusComponent
    },
    {   path     : 'planes/vision',
        component: VisionComponent
    },
    {   path     : 'planes/vision-plus',
        component: VisionPlusComponent
    },
    /*{   path     : 'planes/eco-plus-mas', 
        component: EcoPlusMasComponent
    },*/
    {   path     : 'planes/beneficios-planes-plus',
        component: VisionPlusMasComponent 
    },
];

@NgModule({
    declarations: [
      PlanesComponent,
      EcoComponent,
      EcoPlusComponent,
      VisionComponent,
      VisionPlusComponent,
      EcoPlusMasComponent,
      VisionPlusMasComponent,
     
      
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        FuseDemoModule,
        FuseHighlightModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,        
        MatCardModule,        
    ],
    exports     : [
      PlanesComponent,     
      
    ]
})

export class PlanesModule
{
}
