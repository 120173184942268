import { NgModule } from '@angular/core';

import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';
import { VerticalLayout2Module } from 'app/layout/vertical/layout-2/layout-2.module';
import { VerticalLayout3Module } from 'app/layout/vertical/layout-3/layout-3.module';
import { RouterModule } from '@angular/router';
import { HorizontalLayout1Module } from 'app/layout/horizontal/layout-1/layout-1.module';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonPrimaryOrangeComponent } from './components/button-primary-orange/button-primary-orange.component';
import { ButtonSecundaryBlueLightComponent } from './components/button-secundary-blue-light/button-secundary-blue-light.component';
import { BannerModule } from './components/banner/banner.module';
import { AuthDialogModule } from './components/auth-dialog/auth-dialog.module';
import { CommonModule } from '@angular/common';


@NgModule({
    declarations: [
        BreadcrumbComponent,
        ButtonPrimaryOrangeComponent,
        ButtonSecundaryBlueLightComponent
    ],
    imports: [
        VerticalLayout1Module,
        VerticalLayout2Module,
        VerticalLayout3Module,
        HorizontalLayout1Module,
        RouterModule,
        AuthDialogModule,
        CommonModule
    ],
    exports: [
        VerticalLayout1Module,
        VerticalLayout2Module,
        VerticalLayout3Module,
        HorizontalLayout1Module,
        BreadcrumbComponent,
        ButtonPrimaryOrangeComponent,
        ButtonSecundaryBlueLightComponent,
        BannerModule,
        AuthDialogModule,
    ],
})
export class LayoutModule {}
