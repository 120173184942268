<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">


    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
        <div>
            <img src="assets/images/iconos/icono_obsequio-de-nacimiento.svg" class="icon">
        </div>
    </div>

    <div fxFlex="81" fxFlex.xs="100" fxFlex.lt-md="100" fxLayout="column">
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a (click)="linkSolicitudObsequios()">Solicitud de
                Obsequios</a> » <span>Nacimiento</span>
        </div>

        <h2 class="page-subtitle">Solicitud de obsequios - Nacimiento</h2>


        <div class="msj" *ngIf="sendChild">
            <p>Seleccionamos una gran variedad de marcas y te regalamos una gift card para elegirle un regalo a tu bebé.
            </p>
            <p id="remember">** Recorda que contás con 90 días desde la fecha de nacimiento para acceder al beneficio.
            </p>
        </div>

        <div class="msj" *ngIf="selectChild">
            <p>Seleccionamos una gran variedad de marcas y te regalamos una gift card para elegirle un regalo a tu bebé.
            </p>
            <p>Seleccioná para quien realizarás la solicitud. Recordá que contás con 90 días desde la fecha de
                nacimiento para acceder al beneficio.</p>
        </div>

        <div class="msj" *ngIf="addChild">
            <p>Para acceder al beneficio, la solicitud debe hacerse dentro de los 90 días posteriores al nacimiento del
                bebé y deberá estar ingresado en la obra social.</p>
            <p>Ingresá en ABM > GESTIONES EN LINEA <a (click)="linkAltaFamiliar()">Alta de familiar</a> para gestionar
                el alta.</p>
            <p>Una vez que tu bebé esté dado de alta, podrás solicitar el obsequio en esta misma sección.</p>
        </div>


        <div *ngIf="sendChild" fxFlex="100" fxFlex.xs="100" fxFlexOrder.xs="1">

            <div fxLayout="row" fxLayoutAlign="space-between" class="links-column">

                <form #form [formGroup]="formSolicitud" method="POST" novalidate>
                    <div class="tw">
                        <mat-form-field class="mat-fullwidth">
                            <mat-label>Observaciones</mat-label>
                            <textarea rows="3" matInput [formControl]="observaciones"></textarea>
                            <mat-error *ngIf="observaciones.invalid">Debe ingresar sus observaciones.</mat-error>
                        </mat-form-field>
                    </div>


                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" class="submit-section">

                        <div>
                            <button mat-raised-button class="submit-button" aria-label="Enviar" (click)="sendForm()"
                                [disabled]="formSent">
                                Enviar
                            </button>
                        </div>
                    </div>

                </form>
            </div>

        </div>

        <div *ngIf="selectChild" fxFlex="100" fxFlex.xs="100" fxFlex.lt-md="100" fxLayout="column">

            <h3 *ngFor="let son of sons; let i = index">
                <mat-checkbox value="{{ son }}" (change)="onCheckboxChange($event)">{{ son }}</mat-checkbox>
            </h3>
            <!-- <mat-error *ngIf="child.invalid">Debe seleccionar un hijo.</mat-error> -->

            <div>
                <button mat-raised-button class="submit-button confirmar" aria-label="Confirmar" (click)="sendBaby()">
                    Confirmar
                </button>
            </div>
        </div>       

    </div>
</div>