<div id="price-tables" class="page-layout simple fullwidth">

    <!-- HEADER -->
    <div class="header" fxLayout="row" fxLayoutAlign="start start">

        <div class="hero-text" fxLayout="column" fxLayoutAlign="center center" fxFlex>

            <div class="h1">Simple Pricing!</div>
            <div class="h3">
                The most advanced customer support tools with a simple and affordable pricing. And you can always try
                for 30 days, free!
            </div>

        </div>

    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content" fxLayout="column">

        <!-- PRICE TABLES -->
        <div class="price-tables" fxLayout="row wrap" fxLayoutAlign="center center">

            <div class="price-table style-2 mat-elevation-z4" fxLayout="column">

                <div class="package-type mat-accent-fg">SILVER PACKAGE</div>

                <div class="price" fxLayout="row" fxLayoutAlign="center start">
                    <div class="currency">$</div>
                    <div class="value">99</div>
                </div>

                <div class="period">PER MONTH</div>

                <div class="terms" fxLayout="column" fxLayoutAlign="start center">
                    <div class="term">
                        <span class="text-bold">10</span>
                        Projects
                    </div>
                    <div class="term">
                        <span class="text-bold">10</span>
                        Pages
                    </div>
                    <div class="term">
                        <span class="text-bold">100</span>
                        Mb Disk Space
                    </div>
                </div>

                <button mat-raised-button class="cta-button" color="accent">GET STARTED</button>

            </div>

            <div class="price-table style-2 mat-elevation-z12" fxLayout="column">

                <div class="badge warn">BEST VALUE</div>

                <div class="package-type mat-accent-fg">GOLD PACKAGE</div>

                <div class="price" fxLayout="row" fxLayoutAlign="center start">
                    <div class="currency">$</div>
                    <div class="value">299</div>
                </div>

                <div class="period">PER MONTH</div>

                <div class="terms" fxLayout="column" fxLayoutAlign="start center">
                    <div class="term">
                        <span class="text-bold">20</span>
                        Projects
                    </div>
                    <div class="term">
                        <span class="text-bold">20</span>
                        Pages
                    </div>
                    <div class="term">
                        <span class="text-bold">200</span>
                        Mb Disk Space
                    </div>
                </div>

                <button mat-raised-button class="cta-button" color="accent">GET STARTED</button>

            </div>

            <div class="price-table style-2 mat-elevation-z4" fxLayout="column">

                <div class="package-type mat-accent-fg">PLATINUM PACKAGE</div>

                <div class="price" fxLayout="row" fxLayoutAlign="center start">
                    <div class="currency">$</div>
                    <div class="value">499</div>
                </div>

                <div class="period">PER MONTH</div>

                <div class="terms" fxLayout="column" fxLayoutAlign="start center">
                    <div class="term">
                        <span class="text-bold">40</span>
                        Projects
                    </div>
                    <div class="term">
                        <span class="text-bold">40</span>
                        Pages
                    </div>
                    <div class="term">
                        <span class="text-bold">500</span>
                        Mb Disk Space
                    </div>
                </div>

                <button mat-raised-button class="cta-button" color="accent">GET STARTED</button>

            </div>

        </div>
        <!-- / PRICE TABLES -->

        <div class="faq" fxLayout="row wrap">

            <div class="title" fxFlex="100">Frequently Asked Questions</div>

            <div class="item" fxFlex="100" fxFlex.gt-xs="50">
                <div class="question">How does free trial work?</div>
                <div class="answer">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur a diam nec augue tincidunt
                    accumsan. In dignissim laoreet ipsum eu interdum.
                </div>
            </div>

            <div class="item" fxFlex="100" fxFlex.gt-xs="50">
                <div class="question">Can I cancel any time?</div>
                <div class="answer">
                    Aliquam erat volutpat. Etiam luctus massa ex, at tempus tellus blandit quis. Sed quis neque tellus.
                    Donec maximus ipsum in malesuada hendrerit.
                </div>
            </div>

            <div class="item" fxFlex="100" fxFlex.gt-xs="50">
                <div class="question">What happens after my trial ended?</div>
                <div class="answer">
                    Aliquam erat volutpat. Etiam luctus massa ex, at tempus tellus blandit quis. Sed quis neque tellus.
                    Donec maximus ipsum in malesuada hendrerit.
                </div>
            </div>

            <div class="item" fxFlex="100" fxFlex.gt-xs="50">
                <div class="question">Can I have a discount?</div>
                <div class="answer">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur a diam nec augue tincidunt
                    accumsan. In dignissim laoreet ipsum eu interdum.
                </div>
            </div>

        </div>

    </div>

</div>

