<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

  <!-- ASIDE -->
  <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm fxHide>

    <div fxHide.xs>
      <img src="assets/images/iconos/icono_altausuarios.svg" class="icon">
    </div>

  </div>
  <!-- END ASIDE -->

  <!-- CONTENT -->
  <div fxFlex="81" class="content" fxFlex.lt-md="100">

    <div class="breadcrumbs">
      <a href="/">Inicio</a> » <span>Olvido de contraseña</span>
    </div>

    <h2 class="page-subtitle">Olvido de contraseña</h2>

    <p>Por favor, ingrese su usuario y le enviaremos un correo para que complete el proceso.</p>

    <form [formGroup]="formPW">
      <div fxLayout="row">
        <mat-form-field class="mat-fullwidth" fxFlex.xs="100%" fxFlex="50%">
          <mat-label>Usuario (CUIL o CUIT)</mat-label>
          <input matInput formControlName="usuario" required maxlength="11" type="number" min="0"
            onKeyPress="if(this.value.length==11) return false;">
          <mat-error *ngIf=" formPW.get('usuario').invalid">El CUIT es incorrecto.</mat-error>
          <mat-error *ngIf=" formPW.get('usuario').hasError('usuario')">{{ cuil.errors.cuit.value }}</mat-error>
        </mat-form-field>
      </div>


      <div fxLayout="row">
        <button mat-raised-button id="btnEnviar" (click)="EnviarUsuario()">Enviar</button>
        <button mat-raised-button id="volverLogin"><a href="/login">Volver al login</a></button>
      </div>
    </form>

  </div>
</div>
