<div class="page-layout blank p-10" fxLayout="column" fxLayoutAlign="">
    <div class="breadcrumb">
        <app-breadcrumb [title]="dataBreadcumbTitle" [description]="dataBreadcumbPages"></app-breadcrumb>
    </div>

    
    <div class="banner-container">
        <app-banner 
        type="NewFeature" 
        canClose="true"
        hasBorder="true"
        class="banner-wrapper"
        >
            <b>¡Nuevo portal de autogestión en proceso! Queremos ofrecerte la mejor experiencia posible.</b><br>    
            Sino encuentras tus gestiones anteriores en las siguientes grillas, tal vez se encuentren <a target="_blank" href="/index.php/discapacidad/ctaPrestaciones"><b>haciendo click acá.</b></a>
     </app-banner>
    </div>


    <div class="container-cards-prestaciones" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">
        <div class="select-beneficiario-container" fxLayoutAlign="center center" fxLayout.sm="column"
            >
            <select name="select-beneficiario" id="select-beneficiario" (change)="setBenefData($event)">
                <option selected disabled>Seleccionar beneficiario</option>
                <ng-container *ngFor="let d of data;let i = index">
                    <option value="{{ i }}">{{ d.NombreyApellido }}</option>
                </ng-container>
            </select>
            <p>Es obligatorio seleccionar beneficiario</p>
        </div>

        <button class="solicitar-prestacion-btn" fxFlexAlign.sm="start" fxFlexAlign.xs="start" [disabled]="isDisabled"
            (click)="goToPageNewBenefit()">Solicitar nueva prestación</button>
    </div>

    <!--REQUIEREN ATENCION-->
    <div fxFlex="100" fxLayout="column" class="prestaciones-atencion-container card-menu info-card mt-24"
        *ngIf="!isDisabled">
        <div id="spinner" *ngIf="loading">
            <mat-spinner diameter="60"></mat-spinner>
        </div>
        <ng-container *ngIf="!loading">
            <div fxLayoutAlign="space-between">
                <div fxLayoutAlign="start" class="benefitsAttentionTitle">
                    <img src="assets/icons/DA-icons/prestaciones-requieren-atencion.svg" alt="Prestaciones"
                        class="prestaciones-atencion-icon mr-4" />
                    <h1 class="prestaciones-atencion-title">PRESTACIONES QUE REQUIEREN TU ATENCIÓN</h1>
                </div>
                <button class="reanudar-prestacion-btn" fxFlexAlign.sm="start"
                    fxFlexAlign.xs="start" [disabled]="hasBenefit" (click)="goToPage()">Reanudar prestaciones</button>
            </div>
        </ng-container>

        <ng-container *ngIf="!loading">
            <ng-container *ngIf="hasBenefit;else benefitTable">
                <div fxLayout="column" class="hasNoBenefitContainer">
                    <img src="assets/icons/circleCheckIcon.svg" alt="Prestaciones"
                        class="prestaciones-atencion-icon mr-4" width="90px" height="90px" />
                    <h2>Actualmente no tenés prestaciones que requieran tu atención.</h2>
                    <p>En esta sección aparecerán aquellas que posean observaciones que atender y solicitudes que no
                        finalizaste.</p>
                </div>
            </ng-container>
            <ng-template #benefitTable>
              <app-grilla-prestaciones [prestaciones]="prestaciones"></app-grilla-prestaciones>
            </ng-template>
        </ng-container>
    
    </div>

    <!--ENVIADAS - EN TRANSITO-->
    <div fxFlex="100" fxLayout="column" class="prestaciones-atencion-container card-menu info-card mt-24"
    *ngIf="!isDisabled">
        <div id="spinner" *ngIf="loading">
            <mat-spinner diameter="60"></mat-spinner>
        </div>
        <ng-container *ngIf="!loading">
            <div fxLayoutAlign="space-between">
                <div fxLayoutAlign="start" class="benefitsAttentionTitle">
                    <img src="assets/icons/DA-icons/prestaciones-enviadas.svg" alt="Prestaciones"
                        class="prestaciones-atencion-icon mr-4" />
                    <h1 class="prestaciones-atencion-title">PRESTACIONES ENVIADAS (EN TRÁNSITO)</h1>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!loading">
            <ng-container *ngIf="!hasPrestacionesEnviadas;else prestacionesEnviadasTable">
                <div fxLayout="column" class="hasNoBenefitContainer">
                    <img src="assets/icons/circleCheckIcon.svg" alt="Prestaciones"
                        class="prestaciones-atencion-icon mr-4" width="90px" height="90px" />
                    <h2>Actualmente no tenés prestaciones enviadas o en tránsito.</h2>
                    <p>En esta sección aparecerán aquellas prestaciones enviadas que están pendientes de revisión.</p>
                </div>
            </ng-container>
            <ng-template #prestacionesEnviadasTable>
                <app-grilla-prestaciones [prestaciones]="prestacionesEnTransito" [isEnviadas]="true"></app-grilla-prestaciones>
            </ng-template>
        </ng-container>
    </div>

 
    <div fxFlex="100" fxLayout="column" class="prestaciones-atencion-container card-menu info-card mt-24" *ngIf="!isDisabled">
        <div id="spinner" *ngIf="loading">
            <mat-spinner diameter="60"></mat-spinner>
        </div>
        <ng-container *ngIf="!loading">
            <div fxLayoutAlign="space-between">
                <div fxLayoutAlign="start" class="benefitsAttentionTitle">
                    <img src="assets/icons/DA-icons/prestaciones-aprobadas-rechazadas.svg" alt="Prestaciones"
                        class="prestaciones-atencion-icon mr-4" />
                    <h1 class="prestaciones-atencion-title">PRESTACIONES APROBADAS</h1>
                </div>
            </div>
        </ng-container>
        <ng-container fxFlex="100" fxFlex.xs="100" class="card-menu info-card" fxLayout="column">
            <!-- <div fxLayoutAlign="start center" fxFlex="100" fxFlex.xs="100">
                <img src="assets/icons/solicitudes-aprobadas-rechazadas/solicitudes-aprobadas-rechazadas-grilla.svg" alt="Prestaciones" class="prestaciones-atencion-icon" /><h1 class="prestaciones-atencion-title">PRESTACIONES APROBADAS/RECHAZADAS</h1>
            </div> -->
            <ng-container *ngIf="!loading">
                <ng-container *ngIf="!hasPrestacionesRechazadasAprobadas;else prestacionesRechazadasAprobadasTable">
                    <div fxLayout="column" class="hasNotPrestacionesRechazadasAprobadas">
                        <img src="assets/icons/solicitudes-aprobadas-rechazadas/no-solicitudes.svg" alt="Prestaciones"
                            class="prestaciones-atencion-icon mr-4" width="90px" height="90px" />
                        <h2>Actualmente no tenés prestaciones aprobadas</h2>
                        <p>En la sección "más información" tendrás acceso a información más detallada de tu prestación</p>
                    </div>
                </ng-container>
                <ng-template #prestacionesRechazadasAprobadasTable>
                    <app-grilla-prestaciones-aprobadas-rechazadas style="width: 100%;" [listPrestacionesRechasadasAprobadas]="listPrestacionesRechasadasAprobadas"></app-grilla-prestaciones-aprobadas-rechazadas>
                </ng-template>
            </ng-container>
        </ng-container>
    </div>