<div class="page-layout blank p-24" fusePerfectScrollbar>

    <div class="breadcrumbs">
        <a href="/">Inicio</a> » <a [routerLink]="['/politicas']">POLITICAS DE PRIVACIDAD</a> » <span>TERMINOS DE POLITICA GENERAL DE PRIVACIDAD Y
            PROTECCION DE DATOS PERSONALES</span>
    </div>

    <h4>1. DEFINICION DE LOS TERMINOS DE POLITICA GENERAL DE PRIVACIDAD Y
        PROTECCION DE DATOS PERSONALES</h4>
        <p>Para la interpretación de los términos que serán utilizados en la Política como así
        también en la normativa se entenderán, según el Art. 2 de La Ley 25.326:</p>
    <ul>
        <li><p><b>Datos personales:</b> Información de cualquier tipo referida a personas físicas o de
        existencia ideal determinadas o determinables.</p></li>
        <li><p><b>Datos sensibles:</b> Datos personales que revelan origen racial y étnico, opiniones
        políticas, convicciones religiosas, filosóficas o morales, afiliación sindical e
        información referente a la salud o a la vida sexual.</p></li>
        <li><p><b>Archivo, registro, base o banco de datos:</b> Indistintamente, designan al conjunto
        organizado de datos personales que sean objeto de tratamiento o procesamiento,
        electrónico o no, cualquiera que fuere la modalidad de su formación,
        almacenamiento, organización o acceso.</p></li>
        <li><p><b>Tratamiento de datos:</b> Operaciones y procedimientos sistemáticos, electrónicos o
        no, que permitan la recolección, conservación, ordenación, almacenamiento,
        modificación, relacionamiento, evaluación, bloqueo, destrucción, y en general el
        procesamiento de datos personales, así como también su cesión a terceros a través
        de comunicaciones, consultas, interconexiones o transferencias.</p></li>
        <li><p><b>Responsable de archivo, registro, base o banco de datos:</b> Persona física o de
        existencia ideal pública o privada, que es titular de un archivo, registro, base o banco
        de datos.</p></li>
        <li><p><b>Datos informatizados:</b> Los datos personales sometidos al tratamiento o
        procesamiento electrónico o automatizado.</p></li>
        <li><p><b>Titular de los datos:</b> Toda persona física o persona de existencia ideal con domicilio
        legal o delegaciones o sucursales en el país, cuyos datos sean objeto del tratamiento
        al que se refiere la presente ley.</p></li>
        <li><p><b>Usuario de datos:</b> Toda persona, pública o privada que realice a su arbitrio el
        tratamiento de datos, ya sea en archivos, registros o bancos de datos propios o a
        través de conexión con los mismos.</p></li>
        <li><p><b>Disociación de datos:</b> Todo tratamiento de datos personales de manera que la
        información obtenida no pueda asociarse a persona determinada o determinable.</p></li>
        <li><p><b>Registro de datos con fines publicitarios:</b> En el caso de recopilación de domicilios,
        reparto de documentos, publicidad o venta directa y otras actividades análogas, por
        medio de la cual se traten datos que sean aptos para establecer perfiles
        determinados con fines promocionales, comerciales o publicitarios; o permitan
        establecer hábitos de consumo, y siempre que se trate de documentos accesibles al
        público o hayan sido facilitados por los propios titulares u obtenidos con su
        consentimiento.</p></li>
    </ul>
