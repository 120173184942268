<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm fxHide>

        <div fxHide.xs>
            <img src="assets/images/iconos/icono_altausuarios.svg" class="icon">
        </div>

    </div>

    <div fxFlex="81" class="content" fxFlex.lt-md="100">

        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a routerLink="/misdatos">Mis datos</a> » <span>Actualizar
                Mis Datos</span>
        </div>

        <h2 class="page-subtitle">Actualizar Mis Datos</h2>

        <form #form name="" action="" method="POST" novalidate
            enctype="multipart/form-data" [formGroup]="formActualizarDatos">
            <div class="data" fxLayout="row">
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Código de área</mat-label>
                        <input type="number" matInput [formControl]="codigo_area" maxlength="7">
                        <mat-error *ngIf="codigo_area.invalid">Debe ingresar su código de área.</mat-error>
                    </mat-form-field>
                </div>
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Teléfono</mat-label>
                        <input type="tel" matInput [formControl]="telefono" required maxlength="40">
                        <mat-error *ngIf="telefono.invalid">Debe ingresar su teléfono.</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="data" fxLayout="row">
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Calle</mat-label>
                        <input type="text" matInput [formControl]="calle" required maxlength="80">
                        <mat-error *ngIf="calle.invalid">Debe ingresar su calle.</mat-error>
                    </mat-form-field>
                </div>
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Número</mat-label>
                        <input type="number" matInput [formControl]="numero" required maxlength="5">
                        <mat-error *ngIf="numero.invalid">Debe ingresar su número.</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="data" fxLayout="row">
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Piso/Dpto</mat-label>
                        <input type="text" matInput [formControl]="piso" maxlength="4">
                        <mat-error *ngIf="piso.invalid">Debe ingresar su piso.</mat-error>
                    </mat-form-field>
                </div>
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Código postal</mat-label>
                        <input type="number" matInput [formControl]="cod_postal" required maxlength="4"
                            (change)="cpChanged()">
                        <mat-error *ngIf="cod_postal.invalid">Debe ingresar su código postal.</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="data" fxLayout="row">
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Seleccione una provincia</mat-label>
                        <mat-select [formControl]="provincia" name="provincia" (selectionChange)="provinciaChanged()"
                            [disabled]="provinciasDisabled">
                            <mat-option *ngFor="let option of provincias" [value]="option.LocCodProv">
                                {{option.ProvDsc}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="provincia.invalid">Debe seleccionar la provincia</mat-error>
                    </mat-form-field>
                </div>
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Seleccione una localidad</mat-label>
                        <mat-select [formControl]="localidad" name="localidad" [disabled]="localidadesDisabled">
                            <mat-option *ngFor="let option of localidades" [value]="option.LocDsc">
                                {{option.LocDsc}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="localidad.invalid">Debe seleccionar la localidad</mat-error>
                </div>
            </div>

            <div class="data">
                <div class="tw">
                    <mat-form-field class="mat-fullwidth">
                        <mat-label>Observaciones</mat-label>
                        <textarea matInput [formControl]="observaciones" rows="6" maxlength="80"></textarea>
                        <mat-error *ngIf="observaciones.invalid">Debe ingresar observaciones.</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row">
                <button mat-raised-button id="btnActualizar" (click)="sendForm()" >Actualizar</button>
                <button mat-raised-button id="btnCancelar" routerLink="/misdatos">Cancelar</button>
            </div>
        </form>

    </div>
</div>