<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">
    <div fxFlex="19" fxHide.xs class="aside" fxHide.lt-md fxShow.gt-sm>
        <div>
            <img src="assets/images/iconos/icono_altausuarios.svg" class="icon">
        </div>
    </div>
    <div fxFlex="81" class="content" fxFlex.lt-md="100">
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a routerLink="/misdatos">Mis datos</a> » <a
                routerLink="/misdatos/solicitar-rol">Solicitar Rol</a> » <span>Seleccionar Provincia</span>
        </div>
        <h2 class="page-subtitle">Provincia</h2>

        <p>Por favor completá tu provincia de acuerdo con tu domicilio fiscal</p>

        <form #form name="" action="" method="POST" novalidate enctype="multipart/form-data">
            <div fxLayout="row">
                <mat-form-field fxFlex="37" fxFlex.xs="100">
                    <mat-label>Seleccione Provincia...</mat-label>
                    <mat-select name="filial" [formControl]="filial" disableOptionCentering>
                        <mat-option *ngFor="let option of filialesList" [value]="option">
                            {{option | tildes}}
                        </mat-option>

                    </mat-select>
                    <mat-error *ngIf="filial.invalid ">Debe seleccionar una provincia.</mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <button mat-raised-button id="btnEnviarRed" (click)="btnEnviar()">Enviar</button>
                <button mat-raised-button id="btnCancelar" routerLink="/misdatos">Cancelar</button>
            </div>
        </form>
    </div>
</div>