<h2 class="page-subtitle">Cambiar Email</h2>

<p>Por favor completá con la nueva casilla de correo.</p>

<form [formGroup]="cambiarEmail">
    <div fxLayout="column">

        <div fxLayout="row">
            <mat-form-field class="mat-fullwidth" fxFlex="100">
                <mat-label>Nuevo Email</mat-label>
                <input matInput formControlName="correoElectronico" required >
                <mat-error *ngIf="cambiarEmail.get('correoElectronico').invalid && cambiarEmail.get('correoElectronico').hasError('required')">Debe ingresar su email.
                </mat-error>
                <mat-error *ngIf="cambiarEmail.get('correoElectronico').invalid && cambiarEmail.get('correoElectronico').hasError('pattern')">Ingrese un email válido.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field class="mat-fullwidth" fxFlex="100">
                <mat-label>Confirmar Email</mat-label>
                <input matInput formControlName="correoElectronico2" required [errorStateMatcher]="matcher">
                <mat-error *ngIf="cambiarEmail.get('correoElectronico2').invalid && cambiarEmail.get('correoElectronico2').hasError('required')">Debe reingresar su email.
                </mat-error>
                <mat-error *ngIf="cambiarEmail.get('correoElectronico2').invalid && cambiarEmail.get('correoElectronico2').hasError('pattern')">Ingrese un email válido.</mat-error>
                <mat-error *ngIf="cambiarEmail.hasError('notSame')">Los emails no coinciden.</mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100" fxLayout="row">                    
            <button type="button" mat-raised-button class="submit-button" aria-label="Confirmar" (click)="sendFormulario()">
                Cambiar
            </button>
            <button mat-raised-button type="button" id="btnCancelar" (click)="btnCancelar()">Cancelar</button>
        </div>
    </div>
</form>
