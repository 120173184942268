import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MedicamentosService {

  constructor(
    private http: HttpClient) { }

  sendForm(enviarForm: FormData): Observable<any>{
    return this.http.post(`${environment.urlApi}medicamentos/sendMedicationRequest?sid=` + environment.sessionId, enviarForm);
    
  }   
}
