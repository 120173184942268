import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDemoModule } from '@fuse/components/demo/demo.module';
import { FuseHighlightModule } from '@fuse/components';

import { MonotributistasComponent } from './monotributistas.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { MatExpansionModule } from '@angular/material/expansion';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { DialogImgFormComponent } from './dialog-img-form/dialog-img-form.component';
import { FormularioPDFComponent } from './formulario-pdf/formulario-pdf.component';






const routes = [
    {
        path     : 'monotributistas',
        component: MonotributistasComponent
    },
    {
      path     : 'formulario-003',
      component: FormularioPDFComponent
  }
];

@NgModule({
    declarations: [
      MonotributistasComponent,
      DialogImgFormComponent
      
    ],
    entryComponents:[ DialogImgFormComponent ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        FuseDemoModule,
        FuseHighlightModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        MatExpansionModule,
        MaterialFileInputModule,
        MatDialogModule
        
        
    ],
    exports     : [
      MonotributistasComponent,
    ]
})

export class MonotributistasModule
{
}
