import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ModalDiscapacidadUserData } from "app/models/modal-documentacion-basica-data.model";
import { LoginService } from "app/services/login.service";
import { environment } from "environments/environment";
import { ModalInformacionDocumentacionBasicaComponent } from "../modal-informacion-documentacion-basica/modal-informacion-documentacion-basica.component";
import { ModalNotificacionRedMedifeComponent } from "../modal-notificacion-red-medife/modal-notificacion-red-medife.component";
import { medifeUserData } from "./dataRedMedife";

@Component({
    selector: "app-discapacidadindex",
    templateUrl: "./discapacidadnewindex.component.html",
    styleUrls: ["./discapacidadindex.component.scss"],
})
export class DiscapacidadindexComponent implements OnInit {
  rol = "";
  mockupData: ModalDiscapacidadUserData;
  dataBreadcumbPages: Array<any> = [];
  dataBreadcumbTitle: any;
  loading = true;
 
  constructor(
    private _loginService: LoginService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.dataBreadcumbPages = [{ page: 'Discapacidad', route: '/discapacidad' }];
    this.dataBreadcumbTitle = [];
  }

    ngOnInit(): void {
        this._loginService.getAll().subscribe((resp) => {
            if (!resp["isLogged"])
                this.router.navigate(['/login'])
            else {
                this._loginService.getSessionData().subscribe((resp) => {
                    this.rol = resp["rol"];
                    if(this.verifyEfeNroAse(resp["beneficios_data"]["Red"])){
                        const dialogRef = this.dialog.open(ModalNotificacionRedMedifeComponent, {
                            width: "508px",
                            height: "auto",
                            panelClass: "custom-modalbox",
                            disableClose: true, 
                        });
                        dialogRef.updatePosition({top: '220px'})
                    }
                    this.loading = false;
                });
            }
        });
    }

    linkPanel() {
        window.location.href = environment.urlWebSitePhp + "site/inicio";
    }
    btnConsulta() {
        window.location.href =
            environment.urlWebSitePhp + "discapacidad/ctaPrestaciones";
    }
    btnCartilla() {
        window.location.href =
            environment.urlWebSitePhp + "discapacidad/VerCartilla";
    }
    btnContacto() {
        window.location.href = environment.urlWebSitePhp + "site/contactoB";
    }

    openModal() {
        const dialogRef = this.dialog.open(ModalInformacionDocumentacionBasicaComponent, {
            width: "900px",
            height: "auto",
            panelClass: "custom-modalbox",
        });
        dialogRef.componentInstance.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.dialog.closeAll();
    }

    verifyEfeNroAse(numeroAse) {
        return medifeUserData.find((item) => item.efeNroAse == numeroAse);
    }

}
