// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    urlWebsite: '/web/',
    urlWebSitePhp: '/index.php/',
    urlLogin: '/index.php/site/index',
    urlAseBienestar: 'https://asebienestar.com.ar/',
    urlDownloads: '/',
    urlRoleSelect: '/index.php/site/cambiarRol',
    urlChangePassword: '/index.php/site/cambiarContraseña',
    externalImagesSeccionesUrl: '/images/secciones/',
    externalImagesNovedadesUrl: '/images/novedades/',
    urlApi: '/api/',
    socket: '/',
    urlPublic: [
        '/web/home',
        '/web/contacto',
        '/web/institucional',
        '/web/filiales',
        '/web/novedades',
        '/web/monotributistas',
    ],
    sessionId: null,
    cuit: null,
    cuil:null,
    userId: null,
    telephoneNumber: '5491128391323',
};

/* export const environment = {
    production: false,
    hmr       : false,
    urlWebsite: 'http://10.3.0.51/web/',
    urlWebSitePhp: 'http://10.3.0.51/index.php/',
    urlLogin: '/index.php/site/index',
    urlRoleSelect: '/index.php/site/cambiarRol',
    urlChangePassword: '/index.php/site/cambiarContraseña',
    externalImagesSeccionesUrl: '/images/secciones/',
    externalImagesNovedadesUrl: '/images/novedades/',
    urlApi: 'http://asedev:82/api/',
    urlPublic: [
        '/web/home',
        '/web/contacto',
        '/web/institucional',
        '/web/filiales',
        '/web/novedades',
        '/web/monotributistas',
    ],
    sessionId: null,
    cuit: null,
    cuil:null,
    userId: null
};/*
export const environment = {
    production: false,
    hmr       : false,
    urlWebsite: 'http://asedev:82/web/',
    urlWebSitePhp: 'http://asedev:82/index.php/',
    urlLogin: '/index.php/site/index',
    urlDownloads: 'http://asedev:82/',
    urlRoleSelect: '/index.php/site/cambiarRol',
    urlChangePassword: '/index.php/site/cambiarContraseña',
    externalImagesSeccionesUrl: '/images/secciones/',
    externalImagesNovedadesUrl: '/images/novedades/',
    urlApi: 'http://asedev:82/api/',
    urlPublic: [
        '/web/home',
        '/web/contacto',
        '/web/institucional',
        '/web/filiales',
        '/web/novedades',
        '/web/monotributistas',
    ],
    sessionId: null,
    cuit: null,
    cuil:null,
    userId: null
};
        
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
