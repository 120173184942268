<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>               
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_aportes.svg" class="icon">
        </div>

        <div class="menu-aside">
            <a fxHide.lt-md (click)="exportarPrestacionesPDF()"><mat-icon matTooltip="EXPORTAR PRESTACIONES A PDF">picture_as_pdf</mat-icon></a>              
        </div>

    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a *ngIf="esSABPRV" routerLink="/consulta-prestaciones">Consultar Prestaciones</a> <a *ngIf="!esSABPRV" routerLink="/proveedores/comprobantes">Comprobantes</a> » <span>Carga</span>
        </div>        

        <h2 class="page-subtitle">Prestaciones</h2>

        <div fxShow.lt-md fxHide.gt-sm>
            <button id="btn-exportarPDFMobile" [disabled]="empty" mat-raised-button (click)="exportarPrestacionesPDF()">Exportar prestaciones<mat-icon>picture_as_pdf</mat-icon></button>
        </div>        

        <mat-form-field id="filtro" fxFlex.gt-xs="50" fxFlex.xs="100" fxFlex="50" *ngIf="isDataLoaded">
          <input (keyup)="applyFilter($event)" matInput placeholder="Buscar">
        </mat-form-field>

        <div *ngIf="!isDataLoaded" id="spinner">
        <mat-spinner diameter="60"></mat-spinner>
        </div>

        <div class="mat-elevation-z0 data-table" *ngIf="isDataLoaded">
            <div id="export" #export >

                <table mat-table [dataSource]="dataSource">

                    <ng-container matColumnDef="Beneficiario">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Beneficiario                
                    </th>
                    <td mat-cell *matCellDef="let row" style="max-width: 100px; padding: 0px 0px 0px 7px; overflow: hidden; text-overflow: ellipsis;">
                    {{row.BenTNomApe}}
                    </td>
                    </ng-container>          
                    
                    <ng-container matColumnDef="Prestación">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Prestación                 
                    </th>
                    <td mat-cell *matCellDef="let row" title="{{row.Prestacion}}" style="max-width: 100px; overflow: hidden; text-overflow: ellipsis;"> {{ row.Prestacion.length > 20 ? row.Prestacion.slice(0, 20) + '...' : row.Prestacion }}</td>
                    </ng-container>          
                    
                    <ng-container matColumnDef="Periodo desde">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Desde </th>
                    <td mat-cell *matCellDef="let row"> {{row.FechaDesde}} </td>
                    </ng-container>          
                    
                    <ng-container matColumnDef="Periodo hasta">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Hasta </th>
                    <td mat-cell *matCellDef="let row"> {{row.FechaHasta}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Cantidad de sesiones/Km">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sesiones/Km </th>
                    <td mat-cell *matCellDef="let row"> {{ row.CantidadYTipo }} </td>
                    </ng-container>

                    <ng-container matColumnDef="Estado">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                    <td mat-cell *matCellDef="let row"> {{row.Estado}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Exportar">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header > Exportar </th>
                    <td mat-cell *matCellDef="let row"> <a (click)="exportarPrestacion(row.Index)"><mat-icon>picture_as_pdf</mat-icon></a> </td>
                    </ng-container>

                    <ng-container matColumnDef="Carga de factura">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cargar </th>
                    <td mat-cell *matCellDef="let row"> 
                        <a (click)="cargaFactura(row.Prestacion,row.Index, row.BenTNomApe, row.BenNroAse, row.BenNroOrd, row.DiscaExAnio, row.DiscaTramSec, row.FechaDesde, row.FechaHasta, row.Cantidad, row.TipoCantidad, row.BenTNroDoc)" *ngIf="!esSABPRV && row.Estado != 'INCOMPLETA' && puedeCargar(row.FechaDesde, row.FechaHasta)">
                        <mat-icon>arrow_upward</mat-icon>
                        </a> 
                    </td>
                    </ng-container>

                    <ng-container matColumnDef="Ver detalle">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ver Detalle </th>
                    <td mat-cell *matCellDef="let row"><a (click)="verDetalle(row.Index, row.BenTNomApe, row.DiscaExAnio, row.Prestacion, row.Prestador,row.Estado, row.Observaciones,row.Documentos)"><mat-icon>remove_red_eye</mat-icon></a>  </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                    <!-- Si no se encontraron resultados -->
                    <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No se encontraron resultados para "{{input.value}}"</td>
                    </tr>
                </table>
            </div>
        
          <mat-paginator [pageSizeOptions]="[5, 10, 25]" aria-label="Selecciona"></mat-paginator>
      </div>
    </div>
</div>





