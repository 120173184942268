import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormPrestacionService {

  documentacionListaParaEnviar: boolean = false;

  constructor(private http: HttpClient) { }


  getPrestadorActivo(cuit): Observable<any> {
    return this.http.post(`${environment.urlApi}discapacidad/aPrestadorActivo`, cuit);
  }

  getANomencladorGrupo(data): Observable<any> {
    return this.http.post(`${environment.urlApi}discapacidad/getNomencladorGrupo`, data);
  }

  getNomencladorChecklist(sessionId:string, rubro:string, titulo:string, capitulo:string, id:any): Observable<any> {

    const body = {
      sessionId: sessionId,
      rubro: rubro, 
      titulo: titulo,
      capitulo: capitulo,
      id: id
    };
    
    return this.http.post(`${environment.urlApi}discapacidad/getNomencladorChecklist`, body);
  }

  onSubmit(formData: FormData):Observable<any> {
    // Realiza una solicitud HTTP POST para enviar el formulario con datos multipart.
    return this.http.post(`${environment.urlApi}prestacionesDiscapacidad/sendPrestacion`, formData);
  }

  cuitValidator(cuit: any) {
    if (cuit.length !== 11) {
      return false;
      } else {
          // Comprueba si todos los dígitos son iguales a 0
          if (/^0+$/.test(cuit)) {
            return false;
          }
          
          let acumulado   = 0;
          let digitos     = cuit.split('');
          let digito      = digitos.pop();
  
          for (let i = 0; i < digitos.length; i++) {
              acumulado += Number(digitos[9 - i]) * (2 + (i % 6));
          }
  
          let verif = 11 - (acumulado % 11);
          if (verif === 11) {
              verif = 0;
          } else if (verif === 10) {
              verif = 9;
          } 
  
          if (digito == verif){
            return true;
          } else{
            return false;
          }
    }
  
  }

/* 
  // 
 getPrestacionesPendientes(sessionId: string, cuil:string):Observable<any>{
    const body = {
      sessionId: sessionId,
      cuil: cuil
    };
    
    return this.http.post(`${environment.urlApi}discapacidad/getPrestacionesPendientes`, body);
  } */

  deletePrestacion(data):Observable<any>{
    return this.http.post(`${environment.urlApi}discapacidad/deletePrestacion`, data);
  }

  getDocumentacionPrestador(cuit): Observable<any> {
    return this.http.post(`${environment.urlApi}discapacidad/getDocumentacionPrestador`, cuit);
  }



}
