import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SolicitarRedPrestadoraService {

    @Output() msjRed: EventEmitter<any> = new EventEmitter();

    constructor(private http: HttpClient) { }

    getRedes() {
        return this.http.get(`${environment.urlApi}redes/getAllRedes`);
    }

    sendForm(formRolRed: FormData): Observable<any> {
        return this.http.post(`${environment.urlApi}roles/solicitarRolRed`, formRolRed);
    }
}
