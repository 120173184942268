import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { SolicitarRolEmpresaService } from 'app/services/solicitar-rol-empresa.service';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MisdatosService } from '../../../services/misdatos.service';
import { LoginService } from '../../../services/login.service';
import { SolicitarRedPrestadoraService } from '../../../services/solicitar-red-prestadora.service';
import { CambiarContraseniaService } from '../../../services/cambiar-contrasenia.service';
import { CambiarEmailService } from '../../../services/cambiar-email.service';
import { Router } from '@angular/router';
import { SolicitarRolProveedorService } from 'app/services/solicitar-rol-proveedor.service';

@Component({
  selector: 'app-misdatos',
  templateUrl: './misdatos.component.html',
  styleUrls: ['./misdatos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations  
})
export class MisdatosComponent implements OnInit {
  
  misdatos: any;  
  lastLogin:any;
  cuil: any;
  provDsc : any;

  constructor(private _solicitarRolEmpresaService: SolicitarRolEmpresaService, 
              private _solicitarRedPrestadora: SolicitarRedPrestadoraService,
              private _cambiarContrasenia: CambiarContraseniaService,
              private _cambiarEmail: CambiarEmailService,
              private _snackBar: MatSnackBar,
              private _misdatos: MisdatosService,
              private getSessionData: LoginService,
              private router: Router,
              private _solicitarRolProveedorService: SolicitarRolProveedorService) { }

  ngOnInit(): void {
    this.getSessionData.getAll().subscribe(resp => {
       if(!resp['isLogged'])
            this.router.navigate(['/login'])
    });
    
    this.getSessionData.getSessionData().subscribe(result => {
        environment.sessionId = result['session_id'];
        environment.cuit      = result['cuit'];
        environment.cuil      = result['username'];
        environment.userId    = result['id'];
        if (result['rol'] == 'PRVGRL')
            this.router.navigate(['proveedores/mis-datos']);

        this._misdatos.getMisDatos().subscribe(resultado => {
            let year  = resultado.ultimo_login.slice(0,4);
            let month = resultado.ultimo_login.slice(4,6);
            let day   = resultado.ultimo_login.slice(6,8);
            let hh    = resultado.ultimo_login.slice(8,10);
            let mm    = resultado.ultimo_login.slice(10,12);
            let ss    = resultado.ultimo_login.slice(12,14);
            this.lastLogin      = day + '/' + month + '/' + year + ' ' + hh + ':' + mm + ':' + ss;
            let dig             = resultado.cuil.slice(0,2);
            let cuil            = resultado.cuil.slice(2,10);
            let digVerficidador = resultado.cuil.slice(10,11);
            this.cuil           = dig + '-' + cuil + '-' + digVerficidador;         
            this.misdatos       = resultado;

            this._misdatos.getProvincia(this.misdatos.provincia).subscribe(resultado2 => {
                this.provDsc = resultado2.ProvDsc;
            });
        });
    });
    
    // escucho msjCUITE que se emite desde solicitar-rol-empresa
    this._solicitarRolEmpresaService.msjCUITEmpresa.subscribe(msjCUITEmpresa =>{
      if(msjCUITEmpresa.data == true){
               
        this._snackBar.open('Su solicitud será procesada por un representante. Será notificado a su correo electrónico. Muchas gracias.', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });  
      }
    })

    // escucho msjRed que se emite desde solicitar-red-prestadora
    this._solicitarRedPrestadora.msjRed.subscribe(msjRed =>{
      if(msjRed.data == true){
               
        this._snackBar.open('Su solicitud será procesada por un representante. Será notificado a su correo electrónico. Muchas gracias.', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });  
      }
    })

    this._solicitarRolProveedorService.msjRolProveedor.subscribe(msjSolicitudRol =>{
        if(msjSolicitudRol.data == true){
                 
          this._snackBar.open('Su solicitud será procesada por un representante. Será notificado a su correo electrónico. Muchas gracias.', 'Cerrar', {
            duration: undefined,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });  
        }
      })

      this._solicitarRolProveedorService.msjRolProveedor2.subscribe(msjSolicitudRol =>{
        if(msjSolicitudRol.data == true){
                 
          this._snackBar.open('Su solicitud será procesada por un representante. Puede intentar nuevamente dentro de 72h. Muchas gracias.', 'Cerrar', {
            duration: undefined,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });  
        }
      })
    // escucho msjContrasenia que se emite desde cambiar-contrasenia
    this._cambiarContrasenia.msjContrasenia.subscribe(msjContrasenia =>{
      if(msjContrasenia.data == true){
               
        this._snackBar.open('Su contraseña ha sido modificada con éxito.', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });  
      }
    })

    // escucho msjCambiarEmail que se emite desde cambiar-email
    this._cambiarEmail.msjCambiarEmail.subscribe(msjCambiarEmail =>{
      if(msjCambiarEmail.data == true){
               
        this._snackBar.open('Le hemos enviado un mail a su casilla de correo electrónico con un link para validar que la casilla es suya. Muchas gracias.', 'Cerrar', {
          duration: undefined,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });  
      }
    })

    // escucho msjCambiarEmail que se emite desde cambiar-email
    this._misdatos.msjActualizarDatos.subscribe(msjActualizarDatos =>{
        if(msjActualizarDatos.data == true){
                 
          this._snackBar.open('Hemos recibido su actualización de datos. Por favor, en 48hs hábiles corrobore las modificaciones en ABM -> Consultas. Muchas gracias', 'Cerrar', {
            duration: undefined,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });  
        }
      })
  }
  

  linkPanel(){
    window.location.href = environment.urlWebSitePhp + 'site/inicio';
  }

}
