<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_planes.svg" class="icon">
        </div>

        <div class="menu-aside">
            <a fxHide.lt-md (click)="openModal()"><mat-icon matTooltip="EXPORTAR A EXCEL">cloud_download</mat-icon></a>
        </div>
    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">

        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <span>Liquidador</span>
        </div>

        <h2 class="page-subtitle">Liquidador</h2>

        <mat-tab-group mat-align-tabs="start" (selectedTabChange)="setOSandStatusValue($event)"
            [selectedIndex]='[tabIndex]'>
            <!-- PRINCIPAL -->
            <mat-tab label="Principal">
                <ng-template mat-tab-label>
                    <mat-icon>home</mat-icon> Principal
                </ng-template>

            </mat-tab>
            <!-- FIN PRINCIPAL -->

            <!-- ASE -->
            <mat-tab label="ASE">
                <ng-template mat-tab-label>
                    <img src="assets/images/logos/logo_ase.png" alt="ASE" id="iconoASE">
                </ng-template>

            </mat-tab>
            <!-- FIN ASE -->

            <!-- OSEN -->
            <mat-tab label="OSEN">
                <ng-template mat-tab-label>
                    <img src="assets/images/logos/logo_osen.png" alt="OSEN" id="iconoOSEN">
                </ng-template>

            </mat-tab>
            <!-- FIN OSEN -->

            <!-- OSPM -->
            <mat-tab label="OSPM">
                <ng-template mat-tab-label>
                    <img src="assets/images/logos/OSPM.png" alt="OSPM" id="iconoOSPM">
                </ng-template>

            </mat-tab>
            <!-- FIN OSPM -->

            <!-- PENDIENTE -->
            <mat-tab label="Pendiente">
                <ng-template mat-tab-label>
                    <mat-icon>list</mat-icon> &nbsp; Pendiente
                </ng-template>

            </mat-tab>
            <!-- FIN PENDIENTE -->

            <!-- MODF. SGI -->
            <mat-tab label="Ver/Mod Autorización">
                <ng-template mat-tab-label>
                    <img src="assets/images/iconos/icono_modfSGI.png" alt="Modf. SGI" id="iconoSGI"> &nbsp; Modf. SGI
                </ng-template>
            </mat-tab>
            <!-- FIN MODF. SGI -->

            <!-- JUDICIALIZADOS -->
            <mat-tab label="Judicializados">
                <ng-template mat-tab-label>
                    <img src="assets/images/iconos/icono_judicializados.png" alt="Judicializados"
                        id="iconoJudicializado"> &nbsp; Judicializados
                </ng-template>
            </mat-tab>
            <!-- FIN JUDICIALIZADOS -->

            <!-- MODIFICADOS -->
            <mat-tab label="Liquidado">
                <ng-template mat-tab-label>
                    <mat-icon> monetization_on</mat-icon> &nbsp;Liquidados
                </ng-template>


            </mat-tab>
            <!-- FIN MODIFICADOS -->
        </mat-tab-group>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight"
                [expandedHeight]="customExpandedHeight">
                <mat-panel-title>
                    Filtros
                </mat-panel-title>
            </mat-expansion-panel-header>

            <form [formGroup]="filtros">
                <div class="filtros">

                    <div fxLayout="row" class="mat-fullwidth" fxFlex.xs="100">

                        <mat-form-field
                            *ngIf="this.filtros.controls['estado'].value!=='' || !this.tabIndex  || this.tabIndex === 0 || this.tabIndex === 4 || this.tabIndex === 5 || this.tabIndex === 6 || this.tabIndex === 7"
                            class="mat-fullwidth" fxFlex.xs="100" fxFlex="25">
                            <mat-label>Obra Social</mat-label>
                            <mat-select formControlName="obraSocial">
                                <mat-option></mat-option>
                                <mat-option value="ASE">ASE</mat-option>
                                <mat-option value="OSEN">OSEN</mat-option>
                                <mat-option value="OSPM">OSPM</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="mat-fullwidth" fxFlex.xs="100" fxFlex="50">
                            <input matInput placeholder="Razón Social" #input formControlName="razonSocial"
                                maxlength="100">
                            <mat-error *ngIf="filtros.get('razonSocial').hasError('maxlength')">Se ha ingresado el
                                maximo de caracteres.</mat-error>
                        </mat-form-field>

                        <mat-form-field class="mat-fullwidth" fxFlex.xs="100" fxFlex="50">
                            <input matInput placeholder="CUIT" #input formControlName="CUIT" type="number"
                                onKeyPress="if(this.value.length == 11) return false;">
                            <mat-error
                                *ngIf="filtros.get('CUIT').hasError('cuit')">{{filtros.get('CUIT').errors.cuit.value}}</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="mat-fullwidth" fxFlex="100" fxLayout.xs="column">
                        <div fxFlex.lt-md="100" fxFlex="50" fxLayout="row">

                            <mat-form-field class="mat-fullwidth" fxFlex.lt-md="50" fxLayout.xs="row" fxFlex="50">
                                <mat-label>Período comprobante Desde</mat-label>
                                <mat-select formControlName="periodoDesde">
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let periodo of periodoObj" value="{{ periodo.value }}">{{
                                        periodo.mesAnio }}</mat-option>
                                </mat-select>
                            </mat-form-field>


                            <mat-form-field class="mat-fullwidth" fxFlex.lt-md="50" fxLayout.xs="row" fxFlex="50">
                                <mat-label>Período comprobante Hasta</mat-label>
                                <mat-select formControlName="periodoHasta">
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let periodo of periodoObj" value="{{ periodo.value }}">{{
                                        periodo.mesAnio }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex.lt-md="100" fxFlex="50" fxLayout="row">
                            <mat-form-field class="mat-fullwidth" fxFlex.lt-md="50" fxLayout.xs="row" fxFlex="50">
                                <mat-label>Fecha Trámite Desde</mat-label>
                                <input matInput [matDatepicker]="fechaTramiteDesde" #input
                                    formControlName="fechaTramiteDesde" [min]="minFechaTramite" readonly>
                                <mat-datepicker-toggle matSuffix [for]="fechaTramiteDesde"></mat-datepicker-toggle>
                                <mat-datepicker #fechaTramiteDesde></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field class="mat-fullwidth" fxFlex.lt-md="50" fxLayout.xs="row" fxFlex="50">
                                <mat-label>Fecha Trámite Hasta</mat-label>
                                <input matInput [matDatepicker]="fechaTramiteHasta" #input
                                    formControlName="fechaTramiteHasta" [min]="minFechaTramite" readonly>
                                <mat-datepicker-toggle matSuffix [for]="fechaTramiteHasta"></mat-datepicker-toggle>
                                <mat-datepicker #fechaTramiteHasta></mat-datepicker>
                            </mat-form-field>
                        </div>

                    </div>
                    <mat-error *ngIf="filtros.hasError('periodo')">El período seleccionado es inválido.
                    </mat-error>
                    <mat-error *ngIf="filtros.hasError('fechaTramite')">El período seleccionado es inválido.
                    </mat-error>

                    <div style="justify-content: space-between" fxLayout="row" class="mat-fullwidth" fxFlex.xs="100">
                        <button type="button" [disabled]="periodoError || fcTramiteError " mat-raised-button
                            class="btnBuscar" (click)="applyFilter(filtros.value)">Buscar</button>
                        <button type="button" mat-raised-button class="btnLimpiar" (click)="clear()">Limpiar
                            Búsqueda</button>
                    </div>

                </div>
            </form>
        </mat-expansion-panel>
        <div class="mat-elevation-z0 data-table">

            <mat-table [dataSource]="dataSourcePrincipal" matSort class="my-table">

                <ng-container matColumnDef="a">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> OS
                    </th>
                    <td mat-cell *matCellDef="let row"> {{row.a}}</td>
                </ng-container>

                <ng-container matColumnDef="b">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Razón Social
                    </th>
                    <td mat-cell *matCellDef="let row"> {{row.b}}</td>
                </ng-container>

                <ng-container matColumnDef="Periodo">
                    <th mat-header-cell *matHeaderCellDef> Periodo</th>
                    <td mat-cell *matCellDef="let row"> {{row.c ? row.c.substring(0, 4).toString() + ' - ' +
                        row.c.substring(4, 6).toString() : '' }} </td>
                </ng-container>

                <ng-container matColumnDef="Comprobante">
                    <th mat-header-cell *matHeaderCellDef> Nº de comprobante </th>
                    <td mat-cell *matCellDef="let row"> {{row.d}} {{ row.e.padStart(4, '0') }} {{row.f.padStart(8, '0')
                        }}</td>
                </ng-container>

                <ng-container matColumnDef="g">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                    <td mat-cell *matCellDef="let row"> $ {{row.g}} </td>
                </ng-container>

                <!-- <ng-container matColumnDef="Estado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
            <td mat-cell *matCellDef="let row"></td>
            </ng-container>   -->

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Trámite </th>
                    <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                </ng-container>

                <ng-container matColumnDef="h">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha</th>
                    <td mat-cell *matCellDef="let row"> {{row.h}} </td>
                </ng-container>

                <ng-container matColumnDef="Archivos Adjuntos">
                    <th mat-header-cell *matHeaderCellDef> Acciones </th>
                    <td mat-cell *matCellDef="let row"><a (click)="marcarComprobante(row.id)"><mat-icon
                                #tooltip="matTooltip" matTooltip="Archivos adjuntos"
                                matTooltipPosition="above">remove_red_eye</mat-icon></a></td>
                </ng-container>

                <!-- <ng-container matColumnDef="Número de Liquidación">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Número de<br>Liquidación </th>
            <td mat-cell *matCellDef="let row"> {{row.numeroliq}} </td>
            </ng-container>  -->

                <ng-container matColumnDef="Marca de Liquidación">
                    <th mat-header-cell *matHeaderCellDef> Marca </th>
                    <td mat-cell *matCellDef="let row"> {{row.i}} </td>
                </ng-container>

                <ng-container matColumnDef="Observaciones">
                    <th mat-header-cell *matHeaderCellDef> Observaciones </th>
                    <td mat-cell *matCellDef="let row"><mat-icon *ngIf="row.j" #tooltip="matTooltip"
                            matTooltip="{{row.j}}" matTooltipPosition="above">announcement</mat-icon></td>
                    <!--<mat-icon>announcement</mat-icon> <mat-icon> message</mat-icon> <mat-icon>priority_high</mat-icon> <mat-icon>feedback</mat-icon>-->
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsPrincipal"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsPrincipal;"></tr>


                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No se encontraron resultados para "{{input.value}}"</td>
                </tr>
            </mat-table>


            <div *ngIf="dataSourcePrincipal.filteredData.length===0" class="sinPagos">
                <b>No se encontraron registros</b>
            </div>

            <mat-paginator [pageSizeOptions]="[10, 25, 50]" aria-label="Selecciona"></mat-paginator>
        </div>


    </div>
</div>