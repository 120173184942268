<!-- formulario 003-->
<div class="page-layout blank p-24" fusePerfectScrollbar>

<form #form [formGroup]="formPDF" method="POST" novalidate>
    <h1 id="titulo">Declaración Jurada del beneficiario titular monotributista</h1>

    <div fxLayout="row" >
        
        <mat-form-field class="mat-fullwidth">
            <mat-label>CUIL</mat-label>
            <input placeholder="Ejemplo 37508887002" matInput  [formControl]="cuil" required maxlength="11" oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)">
            <mat-error *ngIf="cuil.invalid ">Debe ingresar el CUIL.</mat-error>                        
            <mat-error *ngIf="cuil.hasError('cuit') ">{{ cuil.errors.cuit.value }}</mat-error>
        </mat-form-field>
        <div class="mat-fullwidth" fxHide.xs></div>
        
    </div>

    <div fxLayout="row" fxLayout.xs="column">            
        <mat-form-field class="mat-fullwidth" fxFlex.xs="100%" fxFlex.sm="50%">
            <mat-label>APELLIDO</mat-label>
            <input matInput [formControl]="apellido" required>
            <mat-error *ngIf="apellido.invalid">Debe ingresar su apellido.</mat-error>
        </mat-form-field>
             
        <mat-form-field class="mat-fullwidth" fxFlex.xs="100%" fxFlex.sm="50%">
            <mat-label>NOMBRE</mat-label>
            <input matInput [formControl]="nombre" required>
            <mat-error *ngIf="nombre.invalid">Debe ingresar su nombre.</mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column"> 
        <mat-form-field class="mat-fullwidth" fxFlex.xs="40%">
            <mat-label>TIPO DOCUMENTO</mat-label>
            <mat-select name="tipoDoc" [formControl]="tipoDocumento" required>
                <mat-option>SELECCIONE</mat-option>
                <mat-option value="01">DNI</mat-option>
                <mat-option value="02">LC</mat-option>
                <mat-option value="03">LE</mat-option>
                <mat-option value="04">CI (EXCLUSIVO EXTRANJEROS)</mat-option>
                <mat-option value="05">PASAPORTE (EXCLUSIVO EXTRANJEROS)</mat-option>                
            </mat-select>     
            <mat-error *ngIf="tipoDocumento.invalid">Debe seleccionar el tipo de documento.</mat-error>  
        </mat-form-field>

        <mat-form-field class="mat-fullwidth" fxFlex.xs="60%">
            <mat-label>NUMERO</mat-label>
            <input matInput [formControl]="numero" required maxlength="9">
            <mat-error *ngIf="numero.invalid">Debe ingresar el número de documento.</mat-error>
        </mat-form-field>

        <mat-form-field style="margin-top: 5px" fxFlex.xs="100%">
            <mat-label>FECHA DE ALTA EN ASE</mat-label>
            <input matInput [matDatepicker]="fechaAlta" [formControl]="fcFechaAltaAse" required  readonly="readonly">
            <mat-datepicker-toggle matSuffix [for]="fechaAlta"></mat-datepicker-toggle>
            <mat-datepicker #fechaAlta></mat-datepicker>
            <mat-error *ngIf="fcFechaAltaAse.invalid">Debe seleccionar la fecha de alta en ASE.</mat-error>
        </mat-form-field>  

    </div>

    <div fxLayout="row" fxLayout.lt-md="column"> 
        <mat-form-field style="margin-top: 5px;" fxFlex.xs="100%" fxFlex.sm="49%">
            <mat-label>FECHA DE NACIMIENTO</mat-label>
            <input matInput [matDatepicker]="fechaNacimiento" [max]="maxDate" [formControl]="fcFechaNacimiento" required readonly="readonly">
            <mat-datepicker-toggle matSuffix [for]="fechaNacimiento"></mat-datepicker-toggle>
            <mat-datepicker #fechaNacimiento></mat-datepicker>
            <mat-error *ngIf="fcFechaNacimiento.invalid">Debe seleccionar la fecha de alta en ASE.</mat-error>
        </mat-form-field>  

        <mat-form-field class="mat-fullwidth" fxFlex.xs="100%" fxFlex.sm="49%">
            <mat-label>GENERO</mat-label>
            <mat-select name="genero" [formControl]="genero" required>
                <mat-option>SELECCIONE</mat-option>
                <mat-option value="F">FEMENINO</mat-option>
                <mat-option value="M">MASCULINO</mat-option>  
                <mat-option value="X">NO BINARIO</mat-option>                
            </mat-select>   
            <mat-error *ngIf="genero.invalid">Debe seleccionar su genero.</mat-error>    
        </mat-form-field>

        <mat-form-field class="mat-fullwidth" fxFlex.xs="100%" fxFlex.sm="50%">                
            <mat-label>NACIONALIDAD</mat-label>
            <mat-select name="nacionalidad" [formControl]="nacionalidad" required>
                <mat-option>SELECCIONE</mat-option>
                <mat-option value="01">ARGENTINA</mat-option>
                <mat-option value="02">EXTRANJERO</mat-option>
            </mat-select> 
            <mat-error *ngIf="nacionalidad.invalid">Debe seleccionar su nacionalidad.</mat-error>                
        </mat-form-field>

        <label id="estadoCivil-label" fxFlex="8%" style="margin-top: 25px;">ESTADO CIVIL</label>
        <mat-radio-group fxFlex.xs="100%" fxFlex.sm="50%" style="width: 100%; margin-top: 21px;" aria-label="Selecciona una opción" aria-labelledby="estadoCivil-label" [formControl]="estadoCivil" required [(ngModel)]="checkedECivil"> 
            
            <mat-radio-button [checked]="true"  value="SOLTERO/A">SOL</mat-radio-button>
            <mat-radio-button value="CASADO/A">CAS</mat-radio-button>
            <mat-radio-button value="VIUDO/A">VDO</mat-radio-button>
            <mat-radio-button value="DIVORCIADO/A">DIV</mat-radio-button>            
        </mat-radio-group>       
    </div>

    <div fxLayout="row" fxLayout.xs="column">
        <mat-form-field class="mat-fullwidth" fxFlex="50%" fxFlex.xs="100%">
            <mat-label>DOMICILIO</mat-label>
            <input matInput [formControl]="domicilio" required>
            <mat-error *ngIf="domicilio.invalid">Debe ingresar su domicilio.</mat-error> 
        </mat-form-field>

        <mat-form-field class="mat-fullwidth" fxFlex="30%" fxFlex.xs="100%">
            <mat-label>Nº</mat-label>
            <input matInput [formControl]="nroDomicilio" required>
            <mat-error *ngIf="nroDomicilio.invalid">Debe ingresar la numeración.</mat-error>
        </mat-form-field>

        <mat-form-field class="mat-fullwidth" fxFlex="10%" fxFlex.xs="50%">
            <mat-label>PISO</mat-label>
            <input matInput [formControl]="piso">
        </mat-form-field>

        <mat-form-field class="mat-fullwidth" fxFlex="10%" fxFlex.xs="50%">
            <mat-label>DTO</mat-label>
            <input matInput [formControl]="dto">
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column">
        <mat-form-field class="mat-fullwidth">
            <mat-label>TELEFONO</mat-label>
            <input matInput [formControl]="telefono" maxlength="20" required>
            <mat-error *ngIf="telefono.invalid">Debe ingresar el teléfono sin guiones</mat-error> 
        </mat-form-field>
    

        <mat-form-field class="mat-fullwidth">
            <mat-label>LOCALIDAD</mat-label>
            <input matInput [formControl]="localidad" required>
            <mat-error *ngIf="localidad.invalid">Debe ingresar su localidad.</mat-error> 
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column">
        <mat-form-field class="mat-fullwidth">
            <mat-label>PROVINCIA</mat-label>
            <input matInput [formControl]="provincia" required>
            <mat-error *ngIf="provincia.invalid">Debe ingresar su provincia.</mat-error>
        </mat-form-field>
    

        <mat-form-field class="mat-fullwidth">
            <mat-label>C.P.</mat-label>
            <input matInput [formControl]="cp" required>
            <mat-error *ngIf="cp.invalid">Debe ingresar su Código Postal.</mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column">
        <mat-form-field class="mat-fullwidth">
            <mat-label>CORREO ELECTRONICO</mat-label>
            <input matInput [formControl]="correoElectronico" required>
            <mat-error *ngIf="correoElectronico.invalid && correoElectronico.hasError('required')">Debe ingresar su email.</mat-error>
            <mat-error *ngIf="correoElectronico.invalid && correoElectronico.hasError('pattern')">Ingrese un email válido.</mat-error>
        </mat-form-field>           
    </div>

    <div fxLayout="row" fxLayout.xs="column">
        <label id="coberturaPMO" fxFlex="12%" fxFlex.xs="30%" style="margin: 5px 0 0 5px;">SOLICITA COBERTURA PMO</label>
        <mat-radio-group fxFlex.xs="40%" style="width: 100%" aria-label="Selecciona una opción" aria-labelledby="coberturaPMO" [formControl]="coberturaPMO" required [(ngModel)]="checkedPMO"> 
            
            <mat-radio-button [checked]="true" value="NO">NO</mat-radio-button>
            <mat-radio-button value="SI">SI</mat-radio-button>
            
        </mat-radio-group>
    </div>

    <div fxLayout="row" fxLayout.xs="column">
        <mat-form-field class="mat-fullwidth">
            <mat-label>MEDICINA PREPAGA SELECCIONADA (Completar en caso de no optar por Cobertura PMO)</mat-label>
            <input matInput [formControl]="medicinaPrepagaSeleccionada" >           
        </mat-form-field>           
    </div>

    <p>DECLARO BAJO JURAMENTO QUE LAS PERSONAS Y QUE LOS DATOS QUE DETALLO A CONTINUACIÓN SON FIDEDIGNOS</p>
    <div fxLayout="row">
        
        <table id="familiares" class="table">
            <thead>
                <tr>
                    <th>PARENTESCO</th>
                    <th>NACIONALIDAD</th>
                    <th width="30%">APELLIDO Y NOMBRE DEL FAMILIAR</th>
                    <th width="5%">TIPO DOC.</th>
                    <th width="20%">NUMERO</th>
                    <th width="10%">GENERO</th>
                    <th>FECHA DE NACIMIENTO</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td data-label="PARENTESCO">                      
                        
                        <mat-select name="parentesco1" [formControl]="Opcion1">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">ESPOSA</mat-option>
                            <mat-option value="02">HIJOS</mat-option>
                            <mat-option value="03">HIJOS MAYORES DE 21 AÑOS ESTUDIANTE</mat-option>
                            <mat-option value="04">HIJOS DISCAPACITADOS</mat-option>
                            <mat-option value="05">CONCUBINA</mat-option>
                            <mat-option value="06">OTROS FAMILIARES A CARGO</mat-option>
                            <mat-option value="07">HIJOS EN GUARDA O TENENCIA</mat-option>
                            <mat-option value="08">HIJOS DE CONCUBINA/O</mat-option>
                            <mat-option value="09">HIJOS DE CONCUBINA/O MAYOR DE 21 ESTUD.</mat-option>
                        </mat-select>    
                    </td>
                    <td data-label="NACIONALIDAD">                                                   
                        <mat-select [formControl]="Nacionalidad1">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">ARGENTINA</mat-option>
                            <mat-option value="02">EXTRANJERO</mat-option>
                        </mat-select>                           
                    </td>
                    <td data-label="APELLIDO Y NOMBRE">                                                   
                        <input matInput [formControl]="NameSurname1">                        
                    </td>
                    <td data-label="TIPO DOC"> 
                        <mat-select [formControl]="TipoDoc1" [(ngModel)]="tipoDocSelected1">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">DNI</mat-option>
                            <mat-option value="02">LC</mat-option>
                            <mat-option value="03">LE</mat-option>
                            <mat-option value="04">CI (EXCLUSIVO EXTRANJEROS)</mat-option>
                            <mat-option value="05">PASAPORTE (EXCLUSIVO EXTRANJEROS)</mat-option>
                            <mat-option value="06">CUIL</mat-option>
                        </mat-select>                             
                    </td>
                    <td data-label="NUMERO">                                                
                        <input matInput [formControl]="Cuil1">                        
                    </td>
                    <td data-label="GENERO"> 
                        <mat-select [formControl]="Genero1">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="F">FEMENINO</mat-option>
                            <mat-option value="M">MASCULINO</mat-option>
                            <mat-option value="X">NO BINARIO</mat-option>
                        </mat-select>                           
                    </td>
                    <td data-label="FECHA NACIMIENTO"> 
                        <mat-form-field class="datepickerFam" appearance="none">                            
                            <input matInput class="pb-5" [matDatepicker]="fechaNacimientoFam1" [formControl]="fcFechaNacimientoFam1" readonly="readonly">
                            <mat-datepicker-toggle matSuffix [for]="fechaNacimientoFam1"></mat-datepicker-toggle>
                            <mat-datepicker #fechaNacimientoFam1></mat-datepicker>
                        </mat-form-field>                                               
                    </td>
                </tr>

                <tr>
                    <td data-label="PARENTESCO">                      
                        <mat-select name="parentesco2" [formControl]="Opcion2">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">ESPOSA</mat-option>
                            <mat-option value="02">HIJOS</mat-option>
                            <mat-option value="03">HIJOS MAYORES DE 21 AÑOS ESTUDIANTE</mat-option>
                            <mat-option value="04">HIJOS DISCAPACITADOS</mat-option>
                            <mat-option value="05">CONCUBINA</mat-option>
                            <mat-option value="06">OTROS FAMILIARES A CARGO</mat-option>
                            <mat-option value="07">HIJOS EN GUARDA O TENENCIA</mat-option>
                            <mat-option value="08">HIJOS DE CONCUBINA/O</mat-option>
                            <mat-option value="09">HIJOS DE CONCUBINA/O MAYOR DE 21 ESTUD.</mat-option>
                        </mat-select>
                    </td>
                    <td data-label="NACIONALIDAD">                                                   
                        <mat-select [formControl]="Nacionalidad2">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">ARGENTINA</mat-option>
                            <mat-option value="02">EXTRANJERO</mat-option>
                        </mat-select>                           
                    </td>
                    <td data-label="APELLIDO Y NOMBRE">                                                   
                        <input matInput [formControl]="NameSurname2">                        
                    </td>
                    <td data-label="TIPO DOC">                                                  
                        <mat-select [formControl]="TipoDoc2" [(ngModel)]="tipoDocSelected2">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">DNI</mat-option>
                            <mat-option value="02">LC</mat-option>
                            <mat-option value="03">LE</mat-option>
                            <mat-option value="04">CI (EXCLUSIVO EXTRANJEROS)</mat-option>
                            <mat-option value="05">PASAPORTE (EXCLUSIVO EXTRANJEROS)</mat-option>
                            <mat-option value="06">CUIL</mat-option>
                        </mat-select>  
                    <td data-label="NUMERO">                                                
                        <input matInput [formControl]="Cuil2">                        
                    </td>
                    <td data-label="GENERO"> 
                        <mat-select [formControl]="Genero2">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="F">FEMENINO</mat-option>
                            <mat-option value="M">MASCULINO</mat-option>
                            <mat-option value="X">NO BINARIO</mat-option>
                        </mat-select>                          
                    </td>
                    <td data-label="FECHA NACIMIENTO"> 
                        <mat-form-field class="datepickerFam" appearance="none">                            
                            <input matInput class="pb-5" [matDatepicker]="fechaNacimientoFam2" [formControl]="fcFechaNacimientoFam2" readonly="readonly">
                            <mat-datepicker-toggle matSuffix [for]="fechaNacimientoFam2"></mat-datepicker-toggle>
                            <mat-datepicker #fechaNacimientoFam2></mat-datepicker>
                        </mat-form-field>                                               
                    </td>
                </tr>

                <tr>
                    <td data-label="PARENTESCO">                      
                        <mat-select [formControl]="Opcion3">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">ESPOSA</mat-option>
                            <mat-option value="02">HIJOS</mat-option>
                            <mat-option value="03">HIJOS MAYORES DE 21 AÑOS ESTUDIANTE</mat-option>
                            <mat-option value="04">HIJOS DISCAPACITADOS</mat-option>
                            <mat-option value="05">CONCUBINA</mat-option>
                            <mat-option value="06">OTROS FAMILIARES A CARGO</mat-option>
                            <mat-option value="07">HIJOS EN GUARDA O TENENCIA</mat-option>
                            <mat-option value="08">HIJOS DE CONCUBINA/O</mat-option>
                            <mat-option value="09">HIJOS DE CONCUBINA/O MAYOR DE 21 ESTUD.</mat-option>
                        </mat-select>
                    </td>
                    <td data-label="NACIONALIDAD">                                                   
                        <mat-select [formControl]="Nacionalidad3">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">ARGENTINA</mat-option>
                            <mat-option value="02">EXTRANJERO</mat-option>
                        </mat-select>                          
                    </td>
                    <td data-label="APELLIDO Y NOMBRE">                                                   
                        <input matInput [formControl]="NameSurname3">                        
                    </td>
                    <td data-label="TIPO DOC">                                                  
                        <mat-select [formControl]="TipoDoc3" [(ngModel)]="tipoDocSelected3">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">DNI</mat-option>
                            <mat-option value="02">LC</mat-option>
                            <mat-option value="03">LE</mat-option>
                            <mat-option value="04">CI (EXCLUSIVO EXTRANJEROS)</mat-option>
                            <mat-option value="05">PASAPORTE (EXCLUSIVO EXTRANJEROS)</mat-option>
                            <mat-option value="06">CUIL</mat-option>
                        </mat-select>                         
                    </td>
                    <td data-label="NUMERO">                                                
                        <input matInput [formControl]="Cuil3">                        
                    </td>
                    <td data-label="GENERO">
                        <mat-select [formControl]="Genero3">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="F">FEMENINO</mat-option>
                            <mat-option value="M">MASCULINO</mat-option>
                            <mat-option value="X">NO BINARIO</mat-option>
                        </mat-select>                           
                    </td>
                    <td data-label="FECHA NACIMIENTO"> 
                        <mat-form-field class="datepickerFam" appearance="none">                            
                            <input matInput class="pb-5" [matDatepicker]="fechaNacimientoFam3" [formControl]="fcFechaNacimientoFam3" readonly="readonly">
                            <mat-datepicker-toggle matSuffix [for]="fechaNacimientoFam3"></mat-datepicker-toggle>
                            <mat-datepicker #fechaNacimientoFam3></mat-datepicker>
                        </mat-form-field>                                               
                    </td>
                </tr>

                <tr>
                    <td data-label="PARENTESCO">                      
                        <mat-select [formControl]="Opcion4">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">ESPOSA</mat-option>
                            <mat-option value="02">HIJOS</mat-option>
                            <mat-option value="03">HIJOS MAYORES DE 21 AÑOS ESTUDIANTE</mat-option>
                            <mat-option value="04">HIJOS DISCAPACITADOS</mat-option>
                            <mat-option value="05">CONCUBINA</mat-option>
                            <mat-option value="06">OTROS FAMILIARES A CARGO</mat-option>
                            <mat-option value="07">HIJOS EN GUARDA O TENENCIA</mat-option>
                            <mat-option value="08">HIJOS DE CONCUBINA/O</mat-option>
                            <mat-option value="09">HIJOS DE CONCUBINA/O MAYOR DE 21 ESTUD.</mat-option>
                        </mat-select>
                    </td>
                    <td data-label="NACIONALIDAD">                                                   
                        <mat-select [formControl]="Nacionalidad4">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">ARGENTINA</mat-option>
                            <mat-option value="02">EXTRANJERO</mat-option>
                        </mat-select>                           
                    </td>
                    <td data-label="APELLIDO Y NOMBRE">                                                   
                        <input matInput [formControl]="NameSurname4">                        
                    </td>
                    <td data-label="TIPO DOC">                                                  
                        <mat-select [formControl]="TipoDoc4" [(ngModel)]="tipoDocSelected4">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">DNI</mat-option>
                            <mat-option value="02">LC</mat-option>
                            <mat-option value="03">LE</mat-option>
                            <mat-option value="04">CI (EXCLUSIVO EXTRANJEROS)</mat-option>
                            <mat-option value="05">PASAPORTE (EXCLUSIVO EXTRANJEROS)</mat-option>
                            <mat-option value="06">CUIL</mat-option>
                        </mat-select>                       
                    </td>
                    <td data-label="NUMERO">                                                
                        <input matInput [formControl]="Cuil4">                        
                    </td>
                    <td data-label="GENERO">
                        <mat-select [formControl]="Genero4">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="F">FEMENINO</mat-option>
                            <mat-option value="M">MASCULINO</mat-option>
                            <mat-option value="X">NO BINARIO</mat-option>
                        </mat-select>                           
                    </td>
                    <td data-label="FECHA NACIMIENTO"> 
                        <mat-form-field class="datepickerFam" appearance="none">                            
                            <input matInput class="pb-5" [matDatepicker]="fechaNacimientoFam4" [formControl]="fcFechaNacimientoFam4"readonly="readonly">
                            <mat-datepicker-toggle matSuffix [for]="fechaNacimientoFam4"></mat-datepicker-toggle>
                            <mat-datepicker #fechaNacimientoFam4></mat-datepicker>
                        </mat-form-field>                                               
                    </td>
                </tr>

                <tr>
                    <td data-label="PARENTESCO">                      
                        <mat-select [formControl]="Opcion5">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">ESPOSA</mat-option>
                            <mat-option value="02">HIJOS</mat-option>
                            <mat-option value="03">HIJOS MAYORES DE 21 AÑOS ESTUDIANTE</mat-option>
                            <mat-option value="04">HIJOS DISCAPACITADOS</mat-option>
                            <mat-option value="05">CONCUBINA</mat-option>
                            <mat-option value="06">OTROS FAMILIARES A CARGO</mat-option>
                            <mat-option value="07">HIJOS EN GUARDA O TENENCIA</mat-option>
                            <mat-option value="08">HIJOS DE CONCUBINA/O</mat-option>
                            <mat-option value="09">HIJOS DE CONCUBINA/O MAYOR DE 21 ESTUD.</mat-option>
                        </mat-select>
                    </td>
                    <td data-label="NACIONALIDAD">                                                   
                        <mat-select [formControl]="Nacionalidad5">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">ARGENTINA</mat-option>
                            <mat-option value="02">EXTRANJERO</mat-option>
                        </mat-select>                           
                    </td>
                    <td data-label="APELLIDO Y NOMBRE">                                                   
                        <input matInput [formControl]="NameSurname5">                        
                    </td>
                    <td data-label="TIPO DOC">                                                  
                        <mat-select [formControl]="TipoDoc5" [(ngModel)]="tipoDocSelected5">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">DNI</mat-option>
                            <mat-option value="02">LC</mat-option>
                            <mat-option value="03">LE</mat-option>
                            <mat-option value="04">CI (EXCLUSIVO EXTRANJEROS)</mat-option>
                            <mat-option value="05">PASAPORTE (EXCLUSIVO EXTRANJEROS)</mat-option>
                            <mat-option value="06">CUIL</mat-option>
                        </mat-select>                      
                    </td>
                    <td data-label="NUMERO">                                                
                        <input matInput [formControl]="Cuil5">                        
                    </td>
                    <td data-label="GENERO"> 
                        <mat-select [formControl]="Genero5">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="F">FEMENINO</mat-option>
                            <mat-option value="M">MASCULINO</mat-option>
                            <mat-option value="X">NO BINARIO</mat-option>
                        </mat-select>                           
                    </td>
                    <td data-label="FECHA NACIMIENTO"> 
                        <mat-form-field class="datepickerFam" appearance="none">                            
                            <input matInput class="pb-5" [matDatepicker]="fechaNacimientoFam5" [formControl]="fcFechaNacimientoFam5"readonly="readonly">
                            <mat-datepicker-toggle matSuffix [for]="fechaNacimientoFam5"></mat-datepicker-toggle>
                            <mat-datepicker #fechaNacimientoFam5></mat-datepicker>
                        </mat-form-field>                                               
                    </td>
                </tr>

                <tr>
                    <td data-label="PARENTESCO">                      
                        <mat-select [formControl]="Opcion6">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">ESPOSA</mat-option>
                            <mat-option value="02">HIJOS</mat-option>
                            <mat-option value="03">HIJOS MAYORES DE 21 AÑOS ESTUDIANTE</mat-option>
                            <mat-option value="04">HIJOS DISCAPACITADOS</mat-option>
                            <mat-option value="05">CONCUBINA</mat-option>
                            <mat-option value="06">OTROS FAMILIARES A CARGO</mat-option>
                            <mat-option value="07">HIJOS EN GUARDA O TENENCIA</mat-option>
                            <mat-option value="08">HIJOS DE CONCUBINA/O</mat-option>
                            <mat-option value="09">HIJOS DE CONCUBINA/O MAYOR DE 21 ESTUD.</mat-option>
                        </mat-select>
                    </td>
                    <td data-label="NACIONALIDAD">                                                   
                        <mat-select [formControl]="Nacionalidad6">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">ARGENTINA</mat-option>
                            <mat-option value="02">EXTRANJERO</mat-option>
                        </mat-select>                          
                    </td>
                    <td data-label="APELLIDO Y NOMBRE">                                                   
                        <input matInput [formControl]="NameSurname6">                        
                    </td>
                    <td data-label="TIPO DOC">                                                  
                        <mat-select [formControl]="TipoDoc6" [(ngModel)]="tipoDocSelected6">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="01">DNI</mat-option>
                            <mat-option value="02">LC</mat-option>
                            <mat-option value="03">LE</mat-option>
                            <mat-option value="04">CI (EXCLUSIVO EXTRANJEROS)</mat-option>
                            <mat-option value="05">PASAPORTE (EXCLUSIVO EXTRANJEROS)</mat-option>
                            <mat-option selected="true" value="06">CUIL</mat-option>
                        </mat-select>                       
                    </td>
                    <td data-label="NUMERO">                                                
                        <input matInput [formControl]="Cuil6">                        
                    </td>
                    <td data-label="GENERO"> 
                        <mat-select [formControl]="Genero6">
                            <mat-option>SELECCIONE</mat-option>
                            <mat-option value="F">FEMENINO</mat-option>
                            <mat-option value="M">MASCULINO</mat-option>
                            <mat-option value="X">NO BINARIO</mat-option>
                        </mat-select>                          
                    </td>
                    <td data-label="FECHA NACIMIENTO"> 
                        <mat-form-field class="datepickerFam" appearance="none">                            
                            <input matInput class="pb-5" [matDatepicker]="fechaNacimientoFam6" [formControl]="fcFechaNacimientoFam6"readonly="readonly">
                            <mat-datepicker-toggle matSuffix [for]="fechaNacimientoFam6"></mat-datepicker-toggle>
                            <mat-datepicker #fechaNacimientoFam6></mat-datepicker>
                        </mat-form-field>                                               
                    </td>
                </tr>
            </tbody>
        </table>            
    </div>


    <div fxLayout="row">
        <p class="legal">Los datos de carácter personal aquí declarados integrarán la base de datos de OBRA SOCIAL ACCIÓN SOCIAL DE EMPRESARIOS con el registro Nº 963 en cumplimiento de lo  dispuesto
            por la Ley Nº 25.326, su decreto reglamentario y la disposición 10/2008 de la DNPDP. La declaración se toma con el propósito de prestar servicios de salud. Asimismo el contenido
            puede ser utilizado para mejorar nuestros productos y servicios, obtener estadísticas y establecer un canal de comunicación con el cliente, y así responder sus preguntas y satisfacer
            sus solicitudes. Para obtener más información sobre la Política de Privacidad y como ejercer sus derechos, por favor consulte nuestra Política de Privacidad: www.ase.com.ar</p>
    
    </div>

    <div fxLayout="row">
        <mat-form-field class="mat-mediumwidth">
            <mat-label>LUGAR</mat-label>
            <input matInput [formControl]="lugar" required>
            <mat-error *ngIf="lugar.invalid">Debe ingresar el lugar.</mat-error>
        </mat-form-field> 

         <mat-form-field style="margin-top: 5px;">
            <mat-label>FECHA</mat-label>
            <input matInput [matDatepicker]="fecha" [formControl]="fcFecha" type="datetime" readonly="readonly" required >
            <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
            <mat-datepicker #fecha></mat-datepicker>
            <mat-error *ngIf="fcFecha.invalid">Debe seleccionar la fecha de hoy.</mat-error>
        </mat-form-field> 
             
    </div>
    <div fxLayout="row">
       
        <button mat-raised-button class="submit-button" aria-label="Guardar" (click)="sendForm()" >
                Generar PDF
        </button>
    </div>
</form>

</div>
