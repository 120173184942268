import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDemoModule } from '@fuse/components/demo/demo.module';
import { FuseHighlightModule } from '@fuse/components';

import { SeccionComponent } from '../seccion/seccion.component';



const routes = [    
    {   path     : 'seccion/:id',
        component: SeccionComponent
    }
];

@NgModule({
    declarations: [
      SeccionComponent,
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        FuseDemoModule,
        FuseHighlightModule,
    ],
    exports     : [
      SeccionComponent,
    ]
})

export class SeccionModule{
}