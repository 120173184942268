import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Filial } from 'app/models/filial.model';

@Injectable({
  providedIn: 'root'
})
export class FilialesService {

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<Filial[]>{
    return this.http.get<Filial[]>(environment.urlApi + 'filiales/getAll');
  }
}
