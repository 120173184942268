import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'home',
        title    : 'Inicio',
        translate: 'Inicio',
        type     : 'item',
        url      : '/',
        externalUrl : true
    },
    {
        id       : 'institucional',
        title    : 'Información Institucional',
        translate: 'Información Institucional',
        type     : 'item',
        url      : '/informacion-institucional',
        externalUrl : true
    },
    {
        id       : 'sanatorio',
        title    : 'Sanatorio Finochietto',
        translate: 'Sanatorio Finochietto',
        type     : 'item',
        url      : 'http://www.sanatoriofinochietto.com/',
        externalUrl : true
    },
    {
        id       : 'filiales',
        title    : 'Filiales',
        translate: 'Filiales',
        type     : 'item',
        url      : '/filiales',
        externalUrl : true
    },
    {
        id       : 'beneficios',
        title    : 'Beneficios',
        translate: 'Beneficios',
        type     : 'item',
        url      : '/index.php/site/beneficios',
        externalUrl : true
    },
    {
        id       : 'novedades',
        title    : 'Novedades',
        translate: 'Novedades',
        type     : 'item',
        url      : '/novedadlista',
        externalUrl : true
    }/*,
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'sample',
                title    : 'Sample',
                translate: 'NAV.SAMPLE.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/sample',
                badge    : {
                    title    : '25',
                    translate: 'NAV.SAMPLE.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            }
        ]
    }*/
];
