<div id="profile" class="page-layout simple tabbed">

    <!-- HEADER -->
    <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
         fxLayoutAlign.gt-sm="space-between end">

        <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
             fxLayoutAlign.gt-sm="start center">
            <img class="profile-image avatar huge" src="assets/images/avatars/Velazquez.jpg"
                 [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
            <div class="name" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                Charlie Adams
            </div>
        </div>

        <div class="actions" fxLayout="row" fxLayoutAlign="end center" [@animate]="{value:'*',params:{delay:'200ms'}}">
            <button mat-raised-button color="accent" aria-label="Follow">Follow</button>
            <button mat-raised-button color="primary" aria-label="Send Message">Send Message</button>
        </div>

    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content">

        <mat-tab-group dynamicHeight="true">

            <mat-tab label="Timeline">
                <profile-timeline></profile-timeline>
            </mat-tab>

            <mat-tab label="About">
                <profile-about></profile-about>
            </mat-tab>

            <mat-tab label="Photos & Videos">
                <profile-photos-videos></profile-photos-videos>
            </mat-tab>

        </mat-tab-group>

    </div>
    <!-- / CONTENT -->

</div>
