import { FormularioPassword } from '../../app/models/formularioPassword.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { FormularioPDF } from '../models/formularioPDF.model';
import { FormularioRegistro } from '../models/registro-formulario.model';

@Injectable({
  providedIn: 'root'
})
export class FormularioPDFService {

  constructor(private http: HttpClient) { }

  sendForm(FormularioPDF: FormularioPDF): Observable<Blob>{
    return this.http.post(`${environment.urlApi}altaMonotributista/generateForm003`, FormularioPDF, { responseType: 'blob' } );  
  }
  sendFormulario(FormularioRegistro: FormularioRegistro): Observable<any>{
    
    return this.http.post(`${environment.urlApi}altasBeneficiarios/registroBeneficiario`,FormularioRegistro);  
  }
  sendFormularioPassword(FormularioPassword: FormularioPassword): Observable<any>{

    return this.http.post(`${environment.urlApi}blanqueoPassword/blanqueoPassword`,FormularioPassword);  
  }
}
