<div *ngIf="!isLoading" class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">
    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>

        <div fxHide.xs>
            <img src="assets/images/iconos/icono_altausuarios.svg" class="icon">
        </div>

        <div class="menu-aside" *ngIf="!isRolSAB">
            <mat-nav-list>
                <mat-list-item routerLink="/proveedores/mis-datos/actualizar-datos">Actualizar datos</mat-list-item>
                <mat-list-item routerLink="/misdatos/cambiar-contraseña">Cambiar contraseña</mat-list-item>
                <mat-list-item routerLink="/proveedores/mis-datos/alta-CBU">Alta CBU</mat-list-item>
            </mat-nav-list>
        </div>

    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">

        <div *ngIf="!isRolSAB"class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <span>Mis Datos</span>
        </div>
        <div *ngIf="isRolSAB"class="breadcrumbs">
          <a (click)="linkPanel()">Panel de Inicio</a>»<a (click)="linkSeleccionarDatos()">Seleccionar Datos</a>»<span>Datos del Proveedor</span>
        </div>
      
        <h2 *ngIf="!isRolSAB"class="page-subtitle">Mis Datos</h2>
        <h2 *ngIf="isRolSAB" class="page-subtitle">Datos del Proveedor</h2>


        <button id="btnMenuAside" fxFlex="10" fxShow.lt-md fxHide.gt-sm mat-button
            [matMenuTriggerFor]="menu">Menu</button>
        <mat-menu #menu="matMenu" id="menuAsideMobile">
            <button mat-menu-item>
                <span id="actualizarDatos" routerLink="/proveedores/mis-datos/actualizar-datos">Actualizar datos</span>
            </button>
            <button mat-menu-item>
                <span id="cambiarContrasenia" routerLink="/misdatos/cambiar-contraseña">Cambiar contraseña</span>
            </button>
            <button mat-menu-item>
                <span id="solicitarRol" routerLink="/proveedores/mis-datos/alta-CBU">Alta CBU</span>
            </button>
        </mat-menu>


        <p *ngIf="!isRolSAB">Editá y actualizá tus datos.</p>

        <table id="tablaMisdatos">

            <tr class="odd">
                <th>Nombre</th>
                <td>{{ misdatos ? misdatos.RazonSocial : '' }}</td>
            </tr>
            <tr class="even">
                <th>Nombre Usuario</th>
                <td>{{misdatos ? misdatos.CUIT : ''}}</td>
            </tr>
            <tr class="odd">
                <th>CUIT/CUIL</th>
                <td>{{ misdatos ? misdatos.CUIT : '' }}</td>
            </tr>
            <tr class="even">
                <th>Email</th>
                <td>{{misdatos ? misdatos.Email : ''}}</td>
            </tr>
            <tr class="odd">
                <th>Teléfono</th>
                <td>{{misdatos ? misdatos.Telefono : ''}}</td>
            </tr>
            <tr class="even">
                <th>Domicilio Profesional</th>
                <td>{{misdatos ? misdatos.Ddomicilio : ''}}</td>
            </tr>
            <tr class="odd">
                <th>Filial</th>
                <td>{{misdatos ? misdatos.Filial : ''}}</td>
            </tr>
            <tr class="even">
                <th>CBU</th>
                <td>{{CBU ? CBU : ''}}</td>
            </tr>
            <tr class="odd">
                <th>Condición Impositiva</th>
                <td>{{misdatos ? misdatos.CondicionImpositiva : ''}}</td>
            </tr>
            <tr class="even">
                <th>Especialidades</th>
                <td>{{ especialidades }}</td>
            </tr>
            <tr class="odd" *ngIf="isMatriculaArray">
                <th>Matrícula N</th>
                <td>{{misdatos && misdatos.Matricula && misdatos.Matricula.Matricula && misdatos.Matricula.Matricula[0] ?
                    misdatos.Matricula.Matricula[0] : ''}}</td>
            </tr>
            <tr class="odd" *ngIf="isMatriculaArray">
                <th>Matrícula P</th>
                <td>{{misdatos && misdatos.Matricula && misdatos.Matricula.Matricula && misdatos.Matricula.Matricula[1] ?
                    misdatos.Matricula.Matricula[1] : ''}}</td>
            </tr>
            <tr class="odd" *ngIf="!isMatriculaArray">
                <th>Matrícula</th>
                <td>{{misdatos && misdatos.Matricula && misdatos.Matricula.Matricula ?
                    misdatos.Matricula.Matricula : ''}}</td>
            </tr>
            <tr class="even">
                <th>Categoría</th>
                <td>{{ misdatos ? misdatos.Categoria : '' }}</td>
            </tr>
            <tr class="odd">
                <th>Último Login</th>
                <td>{{ misdatos && misdatos.lastLogin ? misdatos.lastLogin : ''}}</td>
            </tr>

        </table>
    </div>
</div>