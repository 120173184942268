import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from 'app/services/login.service';
import { SendEmailAltaContable } from 'app/services/send-email-alta-contable.service';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-modal-alta-contable',
  templateUrl: './modal-alta-contable.component.html',
  styleUrls: ['./modal-alta-contable.component.scss']
})
export class ModalAltaContableComponent implements OnInit {
  form: FormGroup;
  isLoading: boolean = false;
  env = environment;
  extension : string = '.pdf';
  sessionId: any;
  closeModal: () => void;

  constructor(
    private sendEmailAltaContable: SendEmailAltaContable,
    private _snackBar: MatSnackBar,
    private getSessionData: LoginService,
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]),
    });
  }

  onCloseModal() {
    if (this.closeModal) {
      this.closeModal();
    }
  }

  ngOnInit(): void {

    this.getSessionData
      .getSessionData()
      .subscribe((result) => {
        this.sessionId = result.session_id;
      });
  }

  closeModalAltaContable() {
    console.log('closeModalAltaContable');
    this.onCloseModal();
  }

  sendEmail() {
    this.isLoading = true;
    const data  ={
      email: this.form.value.email,
      sessionId: this.sessionId
    }
    this.sendEmailAltaContable.sendEmail(data).subscribe((result) => {
      console.log('sendEmail');
      this.isLoading = false;
      this.onCloseModal();
      this._snackBar.open('Se envió el email correctamente', 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }, (error) => {
      this.isLoading = false;
      console.log('error Alta Contable');
      this._snackBar.open('Error al enviar el email', 'Cerrar', {
        duration: undefined,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      // this.onCloseModal();
    }
    );
  }
}
