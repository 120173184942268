<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>               
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_reintegros.svg" class="icon">
        </div>

       

    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <span>Consultar Pagos</span>
        </div>        

        <h2 class="page-subtitle">Consultar Pagos</h2>

        <p>Por favor indique el CUIT del proveedor.</p>

        <form [formGroup]="consultaPagos" method="post" novalidate >

            <div fxLayout="row">
            <mat-form-field class="mat-fullwidth" fxFlex="30" fxFlex.xs="100">
                <mat-label>CUIT</mat-label>
                <input (keypress)="keyPressNumbers($event)" placeholder="Ejemplo 30260001116" matInput  formControlName="cuit" maxlength="11" oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)">
                <mat-error *ngIf="consultaPagos.get('cuit').hasError('required') ">Debe ingresar un CUIT.</mat-error>                        
                <mat-error *ngIf="consultaPagos.get('cuit').hasError('cuit') ">{{ cuit.errors.cuit.value }}</mat-error>
            </mat-form-field> 
            </div>

            <div fxLayout="row">
                <button mat-raised-button type="button" id="btnConsultar" class="mat-raised-button" (click)="consultarPagos()" [disabled]="consultaPagos.invalid">Consultar</button>
            </div>
        </form>
    </div>
       
</div>
