import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MisPagosService {

  constructor(private http: HttpClient) { }

  getAllPagos(cuit): Observable<any[]>{
    return this.http.get<any[]>(environment.urlApi + 'proveedor/getMisPagos?cuit='+ cuit);
  }

  getAdjuntos(cuit,NroFactura): Observable<any[]>{
    return this.http.get<any[]>(environment.urlApi + 'proveedor/getAdjuntos?cuit='+ cuit+'&nroFactura='+NroFactura);
  }
}
