import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';
import * as shape from 'd3-shape';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cuitValidator } from 'app/shared/cuit-validator.directive';
import { Meta } from '@angular/platform-browser';
import { MisdatosService } from 'app/services/misdatos.service';
import { LoginService } from 'app/services/login.service';
import { Router } from '@angular/router';
import { timeStamp } from 'console';

@Component({
    selector: 'app-actualizar-datos',
    templateUrl: './actualizar-datos.component.html',
    styleUrls: ['./actualizar-datos.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ActualizarDatosComponent implements OnInit {
    formActualizarDatos: FormGroup;
    misDatos: any;
    id: any;
    cuit: any;
    cuil: any;
    nombre_usuario: any;
    rol: any;
    nombre: any;
    apellido: any;
    documento_tipo: any;
    documento_numero: any;
    email: any;
    cargo: any;
    codigo_area = new FormControl('', [Validators.maxLength(7)]);
    telefono = new FormControl('', [Validators.required, Validators.maxLength(40)]);
    calle = new FormControl('', [Validators.required, Validators.maxLength(80)]);
    numero = new FormControl('', [Validators.required, Validators.maxLength(5)]);
    piso = new FormControl('', [Validators.maxLength(4)]);
    cod_postal = new FormControl('', [Validators.required, Validators.maxLength(4)]);
    provincia = new FormControl('', [Validators.required]);
    localidad = new FormControl('', [Validators.required]);
    observaciones = new FormControl('', [Validators.maxLength(80)]);
    estado: any;
    ultimo_login: any;
    razon_social: any;
    provincias = [];
    localidades = [];
    provinciasDisabled = true;
    localidadesDisabled = true;
    msjActualizarDatos = true;

    constructor(private _misdatosService: MisdatosService,
        private _loginService: LoginService,
        private router: Router,
        private _snackBar: MatSnackBar) { }

    ngOnInit(): void {
        
        this._loginService.getAll().subscribe(resp => {
            if(!resp['isLogged'])
                this.router.navigate(['/login'])
        });
        
        this._loginService.getSessionData().subscribe(result => {
            this._misdatosService.getMisDatos().subscribe(rsp => {
                this.misDatos = rsp;
                this.nombre = rsp.nombre;
                this.cuit = rsp.cuit;
                this.cuil = rsp.cuil;
                this.nombre_usuario = rsp.nombre_usuario;
                this.rol = rsp.rol;
                this.nombre = rsp.nombre;
                this.apellido = rsp.apellido;
                this.documento_tipo = rsp.documento_tipo;
                this.documento_numero = rsp.documento_numero;
                this.email = rsp.email;
                this.cargo = rsp.cargo;
                
                var cod_area = rsp.codigo_area;
                cod_area = cod_area.replace(' ', '');
                cod_area = cod_area.replace('(', '');
                cod_area = cod_area.replace(')', '');
                
                this.codigo_area.setValue(parseInt(cod_area));
                this.telefono.setValue(rsp.telefono);
                this.calle.setValue(rsp.calle);
                this.numero.setValue(parseInt(rsp.numero));
                this.piso.setValue(rsp.piso);
                this.cod_postal.setValue(rsp.cod_postal);
                this.provincia.setValue(rsp.provincia);
                this.localidad.setValue(rsp.localidad);
                this.observaciones.setValue(rsp.observaciones);
                this.estado = rsp.estado;
                this.ultimo_login = rsp.ultimo_login;
                this.razon_social = rsp.razon_social;

                if (rsp.cod_postal != '' && rsp.cod_postal !== 'undefined') {
                    this._misdatosService.getProvincias(rsp.cod_postal).subscribe(rsp2 => {
                        this.provincias = rsp2;
                        if (rsp.provincia != '' && rsp.provincia !== 'undefined' && rsp2.length > 0) {
                            this.provinciasDisabled = false;
                            this._misdatosService.getLocalidades(rsp.cod_postal, rsp.provincia).subscribe(rsp3 => {
                                this.localidades = rsp3;
                                if (rsp3.length > 0)
                                    this.localidadesDisabled = false;
                            });
                        }
                    });
                }

                this.formActualizarDatos = new FormGroup({
                    codigo_area: this.nombre,
                    telefono: this.apellido,
                    calle: this.email,
                    numero: this.numero,
                    piso: this.piso,
                    cod_postal: this.cod_postal,
                    provincia: this.provincia,
                    observaciones: this.observaciones
                })
            });
        });
    }

    cpChanged() {
        this.provincia.setValue('');
        this.localidad.setValue('');
        this.provinciasDisabled = true;
        this.localidadesDisabled = true;

        if (this.cod_postal.value < 1000)
            return;

        this._misdatosService.getProvincias(this.cod_postal.value).subscribe(rsp => {
            this.provincias = rsp;

            if (rsp.length > 0)
                this.provinciasDisabled = false;
        });
    }

    provinciaChanged() {
        this.localidad.setValue('');
        console.log(this.provincia.value);
        this._misdatosService.getLocalidades(this.cod_postal.value, this.provincia.value).subscribe(rsp => {
            this.localidades = rsp;

            if (rsp.length > 0)
                this.localidadesDisabled = false;
        });
    }

    sendForm() {
        if (this.telefono.value == '' ||
            this.calle.value == '' ||
            this.numero.value == '' ||
            this.cod_postal.value == '' ||
            this.provincia.value == '' ||
            this.localidad.value == '' )
            return;

        const formData = new FormData();
        formData.append('sessionId', environment.sessionId);
        formData.append('userId', environment.userId);
        formData.append('id', this.id);
        formData.append('cuit', this.cuit);
        formData.append('cuil', this.cuil);
        formData.append('nombre_usuario', this.nombre_usuario);
        formData.append('rol', this.rol);
        formData.append('nombre', this.nombre);
        formData.append('apellido', this.apellido);
        formData.append('documento_tipo', this.documento_tipo);
        formData.append('documento_numero', this.documento_numero);
        formData.append('email', this.email);
        formData.append('cargo', this.cargo);
        formData.append('codigo_area', this.codigo_area.value);
        formData.append('telefono', this.telefono.value);
        formData.append('calle', this.calle.value);
        formData.append('numero', this.numero.value);
        formData.append('piso', this.piso.value);
        formData.append('cod_postal', this.cod_postal.value);
        formData.append('localidad', this.localidad.value);
        formData.append('provincia', this.provincia.value);
        formData.append('observaciones', this.observaciones.value);
        formData.append('estado', this.estado);
        formData.append('ultimo_login', this.ultimo_login);
        formData.append('razon_social', this.razon_social);

        this._misdatosService.sendForm(formData)
            .subscribe(resp => {
                this._misdatosService.msjActualizarDatos.emit({
                    data: this.msjActualizarDatos
                })
                this.router.navigate(['misdatos']);
            },
                err => {
                    this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                });
    }


    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }

}
