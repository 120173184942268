<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="row">

    <!-- ASIDE -->
    <div fxFlex="19" fxFlex.xs="100" class="aside" fxHide.lt-md fxShow.gt-sm>               
        <div fxHide.xs>
            <img src="assets/images/iconos/icono_altausuarios.svg" class="icon">
        </div>

       

    </div>
    <!-- END ASIDE -->

    <!-- CONTENT -->
    <div fxFlex="81" class="content" fxFlex.lt-md="100" fxLayout="column">        
        
        <div class="breadcrumbs">
            <a (click)="linkPanel()">Panel de Inicio</a> » <a routerLink="/adm-usuarios-proveedor"><span>Adm. Usuarios</span></a> » <a (click)="linkSeleccionDatos()"><span>seleccionar datos</span></a> » <span>Cambiar Email</span>
        </div>        

        <h1><b>{{ datosProveedor.razonSocial }}</b></h1>

        <table id="tablaDatos">
          
            <tr class="odd">
                <th>CUIT</th>
                <td>{{ datosProveedor ? datosProveedor.CUIT : '' }}</td>
            </tr>
            <tr class="even">
                <th>Email</th>
                <td>{{ datosProveedor ? datosProveedor.email : '' }}</td>
            </tr>
            <tr class="odd">
                <th>Último login</th>
                <td>{{ datosProveedor ? datosProveedor.ultimoLogin: '' }}</td>
            </tr>
        </table>

            
        <div fxLayout="row">
            <button mat-raised-button type="button" id="btnCambiarEmail" class="mat-raised-button" (click)="cambiarEmail()">Cambiar Email</button>
        </div>
        
    </div>
       
</div>

