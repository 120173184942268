import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AltaCBUComponent } from '../alta-cbu/alta-cbu.component';
import { RouterModule } from '@angular/router';


import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';

const routes = [
  {
      path     : 'proveedores/mis-datos/alta-CBU',
      component: AltaCBUComponent
  }
];

@NgModule({
  declarations: [AltaCBUComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule

  ]
})
export class AltaCBUModule { }
