import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';
import { LoginService } from 'app/services/login.service';
import { CambiarEmailService } from '../../../../services/cambiar-email.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-cambiar-email',
    templateUrl: './cambiar-email.component.html',
    styleUrls: ['./cambiar-email.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class CambiarEmailComponent implements OnInit {

    msjCambiarEmail = true;

    email = new FormControl('', [Validators.required, Validators.pattern('^[^@]+@[^@]+\.[a-zA-Z]{2,}$')]);

    constructor(private _loginService: LoginService,
        private _cambiarEmail: CambiarEmailService,
        private router: Router,
        private _snackBar: MatSnackBar) { }

    ngOnInit(): void {
        this._loginService.getAll().subscribe(resp => {
            if(!resp['isLogged'])
                this.router.navigate(['/login'])
        });
    }


    linkPanel() {
        window.location.href = environment.urlWebSitePhp + 'site/inicio';
    }

    cambiarEmail() {

        this.email.markAllAsTouched();
        if (!this.email.valid) {
            return;
        }
        const formData = new FormData();
        formData.append('email', this.email.value);
        formData.append('sessionId', environment.sessionId);
        formData.append('userId', environment.userId);
        this._cambiarEmail.sendEmail(formData)
            .subscribe(resp => {

                //emito msjRed hacia misdatos
                this._cambiarEmail.msjCambiarEmail.emit({
                    data: this.msjCambiarEmail
                })

                this.router.navigate(['misdatos']);
            },
                err => {
                    this._snackBar.open('Ha ocurrido un error: ' + err.error['message'], 'Cerrar', {
                        duration: undefined,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                });

    }

}
