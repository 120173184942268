import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DiscapacidadComponent } from './discapacidad.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FlexLayoutModule } from '@angular/flex-layout';

const routes = [
  {
      path     : 'proveedores/discapacidad',
      component: DiscapacidadComponent
  }
];

@NgModule({
  declarations: [
    DiscapacidadComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatExpansionModule,
    FlexLayoutModule
  ]
})
export class DiscapacidadModule { }
