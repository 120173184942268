import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule} from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDemoModule } from '@fuse/components/demo/demo.module';
import { FuseHighlightModule } from '@fuse/components';

import { NovedadesComponent } from './novedades.component';
import { CustomMatPaginatorIntl } from '@fuse/pipes/paginador-es';


const routes = [
    {
        path     : 'novedades',
        component: NovedadesComponent
    }
];

@NgModule({
    declarations: [
      NovedadesComponent,
     
      
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        FuseDemoModule,
        FuseHighlightModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatPaginatorModule,
        MatCardModule,  

        
    ],
    exports     : [
      NovedadesComponent,     
      
    ], providers: [
      {
        provide: MatPaginatorIntl,
        useClass: CustomMatPaginatorIntl
      }
        
    ]
})

export class NovedadesModule
{
}
